<ul class="flex p-bl-1" data-pgr-id="claimHeaderData">
  <cui-claim-header-item>
    <span cui-claim-header-item-label> Claim # </span>
    <ng-container *ngIf="!isLoading">
      <cui-copy-button
        [ariaLabel]="
          'Copy claim number ' + headerData?.claimNumber + ' to clipboard'
        "
        [dataToCopy]="headerData?.claimNumber"
      >
        <span cui-claim-header-item-value class="pr-bl-2 text-white">
          {{ headerData?.claimNumber }}
        </span>
      </cui-copy-button>
    </ng-container>
    <span *ngIf="isLoading" class="cui-ghost-line inline-block w-10">
      <span class="screen-reader-only">Loading </span>
    </span>
  </cui-claim-header-item>
  <cui-claim-header-item>
    <span cui-claim-header-item-label> Insured </span>
    <span
      *ngIf="!isLoading"
      cui-claim-header-item-value
      class="line-clamp-2 text-white"
    >
      {{ headerData?.insured }}
    </span>
    <span *ngIf="isLoading" class="cui-ghost-line inline-block w-20">
      <span class="screen-reader-only"> Loading </span>
    </span>
  </cui-claim-header-item>
  <cui-claim-header-item>
    <span cui-claim-header-item-label> Loss Date </span>
    <span *ngIf="!isLoading" cui-claim-header-item-value class="text-white">
      {{ headerData?.dateOfLoss }}
    </span>
    <span *ngIf="isLoading" class="cui-ghost-line inline-block w-10">
      <span class="screen-reader-only"> Loading </span>
    </span>
  </cui-claim-header-item>
  <cui-claim-header-item>
    <span cui-claim-header-item-label> Since Report </span>
    <span
      *ngIf="!isLoading"
      cui-claim-header-item-value
      class="line-clamp-1 text-white"
    >
      {{ headerData?.timeSinceReport }}
    </span>
    <span *ngIf="isLoading" class="cui-ghost-line inline-block w-10">
      <span class="screen-reader-only"> Loading </span>
    </span>
  </cui-claim-header-item>
  <cui-claim-header-item>
    <span cui-claim-header-item-label> File Owner </span>
    <ng-container *ngIf="!isFileOwnerLoading && ownerRepSummary">
      <cui-popover align="bottom">
        <button
          cui-popover-trigger
          class="cui-popover-link header-link"
          data-pgr-id="btnHeaderContactCard"
        >
          {{ ownerRepSummary.name }} ({{ ownerRepSummary.code }})
        </button>
        <div cui-popover-content>
          <cla-claim-rep-contact-card-redacted
            [rep]="ownerRepSummary"
            cui-popover-content
          ></cla-claim-rep-contact-card-redacted>
        </div>
      </cui-popover>
    </ng-container>
    <span
      *ngIf="!isFileOwnerLoading && !ownerRepSummary"
      cui-claim-header-item-value
      class="text-white"
    >
      {{ ownerRepCode }}
    </span>
    <span
      *ngIf="!isFileOwnerLoading && !ownerRepCode"
      cui-claim-header-item-value
      class="text-white"
    >
      Unassigned
    </span>
    <span *ngIf="isFileOwnerLoading" class="cui-ghost-line inline-block w-20">
      <span class="screen-reader-only"> Loading </span>
    </span>
  </cui-claim-header-item>
</ul>
