import { Component, OnInit } from "@angular/core";
import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { EFFConstants } from "../../../constants";

@UntilDestroy()
@Component({
  selector: "cla-content-summary-primary-actions",
  templateUrl: "./primary-actions.component.html",
  styleUrls: ["./primary-actions.component.scss"],
})
export class PrimaryActionsComponent implements OnInit {
  public actionNames = EFFConstants.clickActionNames;
  private claimNumber: string;

  constructor(
    private contentSummaryViewService: ContentSummaryViewService,
    public contentSummaryEventService: ContentSummaryEventService,
    public contentSummaryStoreService: ContentSummaryStoreService
  ) {}

  ngOnInit(): void {
    this.contentSummaryViewService.claimNumber$
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        this.claimNumber = x;
      });
  }

  public refreshContentItems(): void {
    this.contentSummaryViewService.claimNumber$
      .subscribe(() => {
        this.contentSummaryStoreService.onContentDetailsRefreshRequested();
      })
      .unsubscribe();
  }
}
