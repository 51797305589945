import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { formatDisplayClaimNumber } from "../../../../helpers";
import {
  IContentSummaryContext,
  IContentSummaryState,
  IAppState,
} from "../../../../models";

@UntilDestroy()
@Injectable()
export class ContentSummaryContextService {
  public contentSummary$: Observable<IContentSummaryState> =
    this.store$.select("contentSummary");
  public context$: Observable<IContentSummaryContext>;

  constructor(private store$: Store<IAppState>) {
    this.context$ = this.contentSummary$.pipe(
      map((state) => this.mapState(state)),
      untilDestroyed(this)
    );
  }

  private mapAppliedFilters(
    filters: { [key: string]: any },
    columns: any[]
  ): string {
    let filterNames: string[] = [];
    Object.entries(filters).forEach(([key, value]) => {
      if (key === "originDateTime") {
        if (Object.keys(value).length) {
          filterNames = [...filterNames, key];
        }
      } else {
        if (value.appliedFilters && value.appliedFilters.length > 0) {
          filterNames = [...filterNames, key];
        }
      }
    });
    return filterNames
      .map((filterName: string) => {
        const column = columns.find((x) => x.id === filterName);
        return column ? (column.title as string) : filterName;
      })
      .join(", ");
  }

  private mapState(state: IContentSummaryState): IContentSummaryContext {
    const result: IContentSummaryContext = {
      claimNbr: state.contentSummaryGridFilter.claimNumber
        ? formatDisplayClaimNumber(state.contentSummaryGridFilter.claimNumber)
        : "",
      contentSummary: {
        appliedFilters: this.mapAppliedFilters(state.filters, state.columns),
        claimNumber: state.contentSummaryGridFilter.claimNumber || "",
        docSetId: state.previewedItem?.id || "",
        filteredItemCount: state.filteredContentItems
          ? state.filteredContentItems.length
          : 0,
        isPartyPropertyView:
          state.contentSummaryGridFilter.isPartyPropertyView || false,
        isSearchApplied: !!state.searchTerm,
        itemCount: state.contentDetails.response
          ? state.contentDetails.response.contentItems.length
          : 0,
        searchContentCount: state.searchResults.length,
        // Logging the term is commented out while our PO investigates whether or not
        // we are permitted to log this.
        // searchContentTerm: state.searchContentTerm,
        selectedItemCount: state.selectedItems.length,
        selectedItems: state.selectedItems.join(", ") || "",
        visibleColumns:
          state.columns
            .filter((x: any) => x.visible && x.id !== "isSelected")
            .map((x: any) => x.title)
            .join(", ") || "",
      },
    };
    return result;
  }
}
