import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PolicyEndorsement } from "../../models/policy-endorsement.interface";

interface EndorsementTableRow {
  formNumber: string;
  name: string;
}

@Component({
  selector: "cla-policy-endorsements",
  templateUrl: "./policy-endorsements.component.html",
  styleUrls: ["./policy-endorsements.component.scss"],
})
export class PolicyEndorsementsComponent implements OnChanges, AfterViewInit {
  @Input() policyEndorsements: PolicyEndorsement[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ["formNumber", "name"];
  dataSource = new MatTableDataSource<EndorsementTableRow>();

  ngOnChanges() {
    this.dataSource.data = this.mapEndorsementsToTableRows();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  mapEndorsementsToTableRows(): EndorsementTableRow[] {
    return this.policyEndorsements.map((endorsement) => {
      // dateString will be format: "1008" - First two digits are month, last two digits are year
      const dateString = endorsement.attachmentFormDate
        .toString()
        .padStart(4, "0");
      const formMonth = dateString.slice(0, 2);
      const formYear = dateString.slice(2);
      return {
        formNumber: `${endorsement.coAttachmentFormNumber} (${formMonth}/${formYear})`,
        name:
          endorsement.description ||
          "Description not found, check policy contract.",
      };
    });
  }
}
