import { TitleCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { PolicyDetail } from "@modules/policy/models/policy-detail";

@Component({
  selector: "cla-policy-additional-name-insured",
  templateUrl: "./policy-additional-name-insured.component.html",
  styleUrls: ["./policy-additional-name-insured.component.scss"],
})
export class PolicyAdditionalNameInsuredComponent {
  @Input() set policyDetail(policyDetail: PolicyDetail | null) {
    if (policyDetail) {
      this.setPartyName(policyDetail);
      this.aniPopulated = this.isAniPopulated(policyDetail);
      this.cityStateZip = this.cityStateZipFormat(policyDetail);
      this.streetName = this.streetNameFormat(policyDetail);
      this.secondStreetName = this.secondStreetNameFormat(policyDetail);
    }
  }

  aniFirstName = "";
  aniMiddleInitial = "";
  aniLastName = "";
  aniSuffix = "";
  cityStateZip = "";
  streetName = "";
  secondStreetName = "";
  aniPopulated = false;

  constructor(private titleCasePipe: TitleCasePipe) {}

  private setPartyName(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.aniName !== null &&
      policyDetail!.partyInfo.aniName !== ""
    ) {
      this.aniFirstName = policyDetail!.partyInfo.aniName
        .substring(20, 35)
        .trim();
      this.aniMiddleInitial = policyDetail!.partyInfo.aniName
        .substring(35, 36)
        .trim();
      this.aniLastName = policyDetail!.partyInfo.aniName
        .substring(0, 20)
        .trim();
      this.aniSuffix = policyDetail!.partyInfo.aniName.substring(36, 39).trim();
    } else {
      this.aniFirstName = "";
      this.aniMiddleInitial = "";
      this.aniLastName = "";
      this.aniSuffix = "";
    }
  }

  cityStateZipFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.streetName !== null &&
      policyDetail.partyInfo.streetName !== ""
    ) {
      const cityName = this.titleCasePipe.transform(
        policyDetail.partyInfo.cityName
      );
      return `${cityName}, ${policyDetail.partyInfo.stateCode} ${policyDetail.partyInfo.zipCode}`;
    }
    return "";
  }

  streetNameFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.streetName !== null &&
      policyDetail.partyInfo.streetName !== ""
    ) {
      const streetName = this.titleCasePipe.transform(
        policyDetail.partyInfo.streetName
      );
      const regexp = /[0-9]+Th/;
      const formattedStreetName = regexp.test(streetName)
        ? streetName.replace("Th", "th")
        : streetName;
      return `${formattedStreetName}<br/>`;
    }
    return "";
  }

  secondStreetNameFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.secondStreetName !== null &&
      policyDetail.partyInfo.secondStreetName !== ""
    ) {
      const secondStreetName = this.titleCasePipe.transform(
        policyDetail.partyInfo.secondStreetName
      );
      const regexp = /[0-9]+Th/;
      const formattedSecondStreetName = regexp.test(secondStreetName)
        ? secondStreetName.replace("Th", "th")
        : secondStreetName;
      return `${formattedSecondStreetName}<br/>`;
    }
    return "";
  }

  isAniPopulated(policyDetail: PolicyDetail) {
    return (
      policyDetail.partyInfo.aniName !== null &&
      policyDetail.partyInfo.aniName !== ""
    );
  }
}
