<div
  *ngIf="(subroFeatures$ | async)?.length > 0; else nofeatures"
  class="relative"
>
  <div class="fcd-table-scroller overflow-x-auto relative pt-xxl">
    <div
      class="
        feature-collection-details
        table-container
        mat-elevation-z2
        p-0
        table
      "
      data-pgr-id="featureComponentLoaded"
    >
      <table
        class="cui-table"
        data-pgr-id="subroFeaturesTable"
        [dataSource]="dataSource"
        [trackBy]="trackBySubroFeatureId"
        mat-table
      >
        <ng-container matColumnDef="feaSelect" sticky>
          <th
            mat-header-cell
            class="cui-cell-checkbox cui-cell-border-right"
            *matHeaderCellDef
          ></th>
          <td
            mat-cell
            class="cui-cell-checkbox cui-cell-border-right"
            *matCellDef="let row; let i = index"
          >
            <mat-checkbox
              tabindex="0"
              *ngIf="
                !(
                  row.subroFeatureCloseReasonCode
                  | claBulkEditAbilityPipe: row.subroFeaStatCode
                )
              "
              (change)="$event ? toggle(row) : null"
              [checked]="isSelected(row)"
              [aria-label]="checkboxLabel(row, i)"
              data-pgr-id="row-checkbox"
            >
            </mat-checkbox>
            <mat-icon
              tabindex="0"
              aria-label="hint"
              *ngIf="
                row.subroFeatureCloseReasonCode
                  | claBulkEditAbilityPipe: row.subroFeaStatCode
              "
              matTooltip="This feature is not available for bulk edit."
              class="text-mediumGray hint-icon"
              data-pgr-id="row-tooltip"
            >
              info
            </mat-icon>
          </td>
          <td mat-footer-cell *matFooterCellDef>Total</td>
        </ng-container>
        <ng-container matColumnDef="subroStatus" sticky>
          <th mat-header-cell *matHeaderCellDef class="subro-status">
            Subro Status
          </th>
          <td mat-cell *matCellDef="let element">
            <cui-status-indicator
              data-pgr-id="statusIndicator"
              status="{{
                element.subroFeaStatCode
                  | claStatusIndicatorClassPipe
                    : element.subroFeatureCloseReasonCode
              }}"
            >
              {{
                element.subroFeaStatCode
                  | claStatusIndicatorTextPipe
                    : element.subroFeatureCloseReasonCode
              }}
            </cui-status-indicator>
            <span class="screen-reader-only" tabindex="-1">Subro Status</span>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="featureSequenceNumber" sticky>
          <th mat-header-cell *matHeaderCellDef class="seq-num">#</th>
          <td
            mat-cell
            *matCellDef="let element"
            data-pgr-id="fea-seq-num"
            class="break-normal"
          >
            {{
              element.featureSequenceNumber.toString() | claTwoOrMoreDigitsPipe
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="partyProperty" sticky>
          <th mat-header-cell *matHeaderCellDef class="party-property">
            Party/Property
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              getPartyPropertyInfo(element.featureId)
                | async
                | claPartyPropertyDisplayPipe
                | claEmptyDashPipe
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="lineCoverage" sticky>
          <th mat-header-cell *matHeaderCellDef class="line-coverage">
            Line Coverage
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lineCoverage | claEmptyDashPipe }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="collectingOrgId">
          <th mat-header-cell *matHeaderCellDef class="collecting-org">
            Collecting ORG
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.collectingOrgId | claEmptyDashPipe }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="collectingRepId">
          <th mat-header-cell *matHeaderCellDef class="collector">Collector</th>
          <td mat-cell *matCellDef="let element">
            <cla-contact-card
              [repInfo]="
                (coreUiStateService.repSummaries$ | async)[
                  element.collectingRepId
                ]
              "
              [tpxId]="element.collectingRepId | claEmptyDashPipe"
              [isLoadingRepInfo]="
                coreUiStateService.isLoadingRepSummaries$ | async
              "
            ></cla-contact-card>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="statuteOfLimitations">
          <th mat-header-cell *matHeaderCellDef class="sol">
            Statute of Limitations
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.statuteOfLimitations | claDateFormatPipe }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
        <ng-container matColumnDef="featurePaymentAmount">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Feature Payment
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.featurePaymentAmount
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totals.featurePaymentAmount | currency: "USD":"symbol":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="deductibleWithheldAmount">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngClass]="
              hasDeductibleInfo ? 'deductible-amount' : 'dollar-values'
            "
          >
            Deductible Withheld
          </th>
          <td mat-cell *matCellDef="let element" #deductibleCell>
            {{
              element.deductibleWithheldAmount
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
            <cui-popover
              *ngIf="
                element.deductibleWithheldAmount > 0 &&
                element.subroFeaStatCode !== SubroFeaStatCodes.OnHold
              "
              [align]="left"
              class="align-middle"
            >
              <button cui-popover-trigger class="text-mediumGray">
                <mat-icon>info</mat-icon>
              </button>
              <div cui-popover-content class="w-30">
                <h2 class="cui-h3 mb-3" cuiPopoverTitle>Deductible Amounts</h2>
                <cui-data-list>
                  <cui-data-list-row labelSpan="70" label="Withheld">
                    {{
                      element.deductibleWithheldAmount
                        | currency: "USD":"symbol":"1.2-2"
                    }}
                  </cui-data-list-row>
                  <cui-data-list-row labelSpan="70" label="Collected">
                    {{
                      element.deductibleCollectedAmount
                        | currency: "USD":"symbol":"1.2-2"
                    }}
                  </cui-data-list-row>
                  <cui-data-list-row labelSpan="70" label="Returned">
                    {{
                      element.deductibleReturnedAmount
                        | currency: "USD":"symbol":"1.2-2"
                    }}
                  </cui-data-list-row>
                  <cui-data-list-row
                    labelSpan="70"
                    label="Advanced/Waived Applied"
                  >
                    {{
                      element.deductibleAdvancedWaivedApplied
                        | currency: "USD":"symbol":"1.2-2"
                    }}
                  </cui-data-list-row>
                  <cui-data-list-row
                    labelSpan="70"
                    label="Advanced/Waived Recovered"
                  >
                    {{
                      element.deductibleAdvancedWaivedRecovered
                        | currency: "USD":"symbol":"1.2-2"
                    }}
                  </cui-data-list-row>
                </cui-data-list>
              </div>
            </cui-popover>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{
              totals.deductibleWithheldAmount | currency: "USD":"symbol":"1.2-2"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="salvageAmount">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Salvage
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.salvageAmount
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totals.salvageAmount | currency: "USD":"symbol":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="calculatedFeatureCollectibleForParty">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Total Collectible
          </th>
          <td mat-cell *matCellDef="let element" data-pgr-id="totalCollectible">
            {{
              element.calculatedFeatureCollectibleForParty
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{
              totals.calculatedFeatureCollectibleForParty
                | currency: "USD":"symbol":"1.2-2"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="featureCollectibleAmountForParty">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Sub Collectible
          </th>
          <td mat-cell *matCellDef="let element" data-pgr-id="subCollectible">
            {{
              element.featureCollectibleAmountForParty
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{
              totals.featureCollectibleAmountForParty
                | currency: "USD":"symbol":"1.2-2"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="grossRecoveryAmount">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Gross Recovery
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.grossRecoveryAmount
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totals.grossRecoveryAmount | currency: "USD":"symbol":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lossCashRecovery">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Loss Cash Recovery
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.lossCashRecovery
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totals.lossCashRecovery | currency: "USD":"symbol":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="balanceDue">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Balance Due
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              element.balanceDue
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{ totals.balanceDue | currency: "USD":"symbol":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="featureWriteOffAmount">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Write-off Amount
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-pgr-id="featureWriteOffAmount"
          >
            {{
              element.featureWriteOffAmount
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{
              totals.featureWriteOffAmount | currency: "USD":"symbol":"1.2-2"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="featureNetSubroRecovery">
          <th mat-header-cell *matHeaderCellDef class="dollar-values">
            Net Subro Recovery
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            data-pgr-id="featureNetSubroRecovery"
          >
            {{
              element.featureNetSubroRecovery
                | currency: "USD":"symbol":"1.2-2"
                | claOnHoldDashPipe: element.subroFeaStatCode
            }}
          </td>
          <td mat-footer-cell *matFooterCellDef>
            {{
              totals.featureNetSubroRecovery | currency: "USD":"symbol":"1.2-2"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="empty">
          <td
            mat-footer-cell
            *matFooterCellDef
            [attr.colspan]="displayedColumns.length"
          >
            No Features associated
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          tabindex="0"
        ></tr>
        <tr
          data-pgr-id="totalsRow"
          mat-footer-row
          *matFooterRowDef="displayedColumns"
        ></tr>
        <!-- Select Column -->
        <ng-container matColumnDef="select" sticky>
          <th
            mat-header-cell
            class="hidden-sticky-header py-0 cui-cell-border-right"
            *matHeaderCellDef
          ></th>
          <td
            mat-cell
            #focusManager="cuiTableActionsFocusManager"
            cuiTableActionsFocusManager
            class="
              hidden-sticky-cell
              py-0
              cui-cell-actions cui-cell-border-left
            "
            *matCellDef="let element"
          ></td>
          <td mat-footer-cell *matFooterCellDef>
            <!--Intentionally Empty-->
          </td>
        </ng-container>
      </table>
    </div>
  </div>
</div>
<ng-template #nofeatures>
  <div class="mt-xs flex flex-row items-center justify-between">
    <p data-pgr-id="featureComponentLoaded">No Features associated</p>
  </div>
</ng-template>
