/* eslint-disable functional/immutable-data */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EstimatePdf } from "@modules/claims-summary-shared";
import { ClaimDetails, ClaimManagementApiService } from "@modules/core";
import {
  ClaimDetailsStateService,
  RouterStateService,
} from "@modules/core/state";
import {
  IDocSetItemContentUrlResponse,
  IDocSetThumbnailResponse,
} from "@modules/electronic-file-folder/models";
import { IDocSetThumbnail } from "@modules/electronic-file-folder/models/doc-set/doc-set-thumbnail.interface";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  Observable,
  catchError,
  first,
  forkJoin,
  map,
  mergeMap,
  of,
  take,
} from "rxjs";
import { EFFConstants } from "../../../constants";
import { OpenValidationErrorMessaging } from "../../../constants/content-summary";
import {
  IContentSummaryItem,
  IContentSummaryItemBase,
} from "../../../models/content-summary";
import { IOpenResult, IOpenValidationResult } from "../../../models/core";
import { ActionsValidationUtility } from "../../../utilities/core";
import { DocSetService } from "../../doc-set/doc-set/doc-set.service";

@UntilDestroy()
@Injectable()
export class ClaimsContentViewerService {
  private claimNumber: string;
  private claimDetails: ClaimDetails;

  constructor(
    private actionsValidationUtility: ActionsValidationUtility,
    private routerStateService: RouterStateService,
    private _docSetService: DocSetService,
    private claimDetailStateService: ClaimDetailsStateService,
    private claimManagementApiService: ClaimManagementApiService
  ) {
    this.routerStateService.routeParams$
      .pipe(take(1))
      .subscribe((routeParams) => {
        if (routeParams && routeParams.claimNumber) {
          this.claimNumber = routeParams.claimNumber;
        }
      });

    this.claimDetailStateService.claimDetails$.subscribe(
      (observedClaimDetails: ClaimDetails) => {
        this.claimDetails = observedClaimDetails;
      }
    );
  }
  public openViewer(contentItems: IContentSummaryItem[]): void {
    if (contentItems.length > 0) {
      contentItems.map((contentItem: IContentSummaryItem) =>
        this.openViewerForVersion(
          contentItem,
          contentItem.versions[contentItem.versions.length - 1].versionNbr
        )
      );
    }
  }

  public async openViewerForVersion(
    contentSummaryItem: IContentSummaryItemBase,
    versionNbr: number
  ): Promise<void> {
    if (!this.isForbiddenEstimate(contentSummaryItem)) {
      if (
        contentSummaryItem.contentTypeDesc.includes("Committed") &&
        contentSummaryItem.contentCategoryDesc == "Estimate" &&
        contentSummaryItem.propertySequenceNumber
      ) {
        const estimate: EstimatePdf[] | undefined = await this.getEstimateData(
          this.claimNumber,
          contentSummaryItem.propertySequenceNumber
        )
          .pipe(first())
          .toPromise();

        if (estimate && estimate[0]) {
          const newTab: Window | null = window.open();
          const estimateData = estimate[0];
          if (estimateData.fileData) {
            let obj = newTab?.document.createElement("object");
            if (obj) {
              obj.style.width = "100%";
              obj.style.height = "100%";
              obj.type = "application/pdf";
              obj.data = "data:application/pdf;base64," + estimateData.fileData;
              newTab?.document.body.appendChild(obj);
            }
          } else {
            newTab?.document.write("<h1>No estimate data to display</h1>");
          }
        } else {
          alert("No estimate data");
        }
      } else {
        this.openContent(contentSummaryItem.id, versionNbr);
      }
    }
  }

  public processOpenViewer(contentItems: IContentSummaryItem[]): IOpenResult {
    let openResult: IOpenResult = {} as IOpenResult;
    const openValidationResult: IOpenValidationResult =
      this.validateItemsForOpening(contentItems);
    if (openValidationResult.error) {
      openResult = { error: openValidationResult.error };
    }
    if (openValidationResult.contentItems.length > 0) {
      this.openViewer(openValidationResult.contentItems);
    }

    return openResult;
  }

  public openContent(contentItemId: string, version: number): void {
    const loadingHtml: string = `<head><title>EFF Document</title></head>
    <body><h1>Document Loading... Please Wait...</h1></body>`;
    const newTab: Window | null = window.open();
    newTab?.document.write(loadingHtml);
    let mimeType: string = "";
    this._docSetService
      .getThumbnail(contentItemId ?? "", version)
      .pipe(
        map((thumbnailResponse: IDocSetThumbnailResponse) => {
          const items: IDocSetThumbnail[] = thumbnailResponse.items;
          return items;
        }),
        mergeMap((docItems: IDocSetThumbnail[]) => {
          const contentUrls: Observable<IDocSetItemContentUrlResponse>[] = [];
          docItems.forEach((item: IDocSetThumbnail) => {
            mimeType = item.mimeType;
            // eslint-disable-next-line functional/immutable-data
            contentUrls.push(
              this._docSetService.getContentUrl(
                item.docSetItemId,
                item.docSetItemVersionNbr ?? 0
              )
            );
          });
          return forkJoin(contentUrls);
        }),
        catchError((error: HttpErrorResponse) => {
          mimeType = "error";
          return of([
            {
              contentUrl: error
                ? error.message || error.error
                : "Unknown Error Occurred",
            },
          ]);
        })
      )
      .subscribe((contentUrlResponses: IDocSetItemContentUrlResponse[]) => {
        let contentHtml: string = `<head><title>EFF Document</title></head>`;

        contentUrlResponses.forEach(
          (contentUrlResponse: IDocSetItemContentUrlResponse) => {
            // GA TODO: If we could make an HTTP HEAD or GET call here we could get the ACTUAL content type and prevent the download ones from being weird
            if (mimeType.startsWith("image")) {
              contentHtml += `<body><img src="${contentUrlResponse.contentUrl}" alt="Uploaded image"></body>`;
            } else if (mimeType.startsWith("application/pdf")) {
              newTab?.close();
              window.open(contentUrlResponse.contentUrl, "_blank");
            } else if (mimeType.startsWith("audio")) {
              contentHtml += `<body><audio controls><source src="${contentUrlResponse.contentUrl}"type="${mimeType}"></audio></body>`;
            } else if (mimeType.startsWith("video")) {
              contentHtml += `<body><video controls><source src="${contentUrlResponse.contentUrl}" type="${mimeType}"></video></body>`;
            } else if (mimeType.startsWith("error")) {
              contentHtml += `<body>
                <h1>There was an issue opening this file.</h1>
                <h3>If the problem persists, please reach out to your Progressive contact.</h3>
              </body>`;
            } else {
              contentHtml += `<body>
                <h1>File Type not supported.</h1>
                <p>Download the file by clicking <a href="${contentUrlResponse.contentUrl}">here</a></p>
              </body>`;
            }
          }
        );
        if (!mimeType.startsWith("application/pdf")) {
          newTab?.document.close();
          newTab?.document.open();
          newTab?.document.write(contentHtml);
        }
      });
  }

  public validateItemsForOpening(
    contentItems: IContentSummaryItem[]
  ): IOpenValidationResult {
    let result: IOpenValidationResult = {
      contentItems: [],
      error: null,
    };

    if (contentItems.length > 10) {
      result = {
        ...result,
        error: OpenValidationErrorMessaging.tooManySelectionsMessage,
      };
    } else {
      const validItemsToOpen: IContentSummaryItem[] =
        this.actionsValidationUtility.validateItemsToOpen(contentItems);
      if (contentItems.length !== validItemsToOpen.length) {
        result = {
          ...result,
          error: OpenValidationErrorMessaging.invalidTypeAndStatusMessage,
        };
      }

      const validItemsToOpenWithNoEstimates: IContentSummaryItem[] =
        validItemsToOpen.filter(
          (contentItem: IContentSummaryItem) =>
            !this.isForbiddenEstimate(contentItem)
        );
      if (validItemsToOpen.length !== validItemsToOpenWithNoEstimates.length) {
        result = {
          ...result,
          // check for multiple errors
          error: result.error
            ? {
                title: "There is no viewable content on the following items:",
                message: `${result.error.message} <br>
                  - Estimate:&nbsp;&nbsp;${OpenValidationErrorMessaging.noEstimateAvailable.message}`,
              }
            : OpenValidationErrorMessaging.noEstimateAvailable,
        };
      }

      result = {
        ...result,
        contentItems: validItemsToOpen,
      };
    }

    return result;
  }

  private isPreliminaryEstimate(contentSummaryItem: IContentSummaryItemBase) {
    return contentSummaryItem.id.startsWith(
      EFFConstants.viewer.preliminaryEstimatePrefix
    );
  }

  // returns true if it's an uber committed Estimate
  // returns false if it's a lyft committed estimate
  private isForbiddenEstimate(contentSummaryItem: IContentSummaryItemBase) {
    const companyName: string = this.claimDetails?.companyName
      ? this.claimDetails?.companyName
      : "None";
    const isCommittedEstimate: boolean = contentSummaryItem.id.startsWith(
      EFFConstants.viewer.committedEstimatePrefix
    );

    if (isCommittedEstimate) {
      return false;
    } else {
      return (
        isCommittedEstimate || this.isPreliminaryEstimate(contentSummaryItem)
      );
    }
  }

  getEstimateData(
    claimNumber: string,
    propertySequenceNumber: string | number | undefined
  ): Observable<EstimatePdf[]> {
    return this.claimManagementApiService.get<EstimatePdf[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/estimate?committedStatus=committed&propertyNumber=${propertySequenceNumber}`
    );
  }
}
