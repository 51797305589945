import { Injectable } from "@angular/core";
import { CodeTableApiService } from "@modules/core/services/code-table-api/code-table-api.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CodeTable, CodeTableValue } from "@claimssummaryshared/models";
import { HttpErrorResponse } from "@angular/common/http";
import { MakeCodeTableValue } from "@modules/core/models/make-code-table-value.interface";

@Injectable()
export class CodesService {
  constructor(private codeTableApiService: CodeTableApiService) {}

  getCodeTables(tableNames: string[]): Observable<CodeTable[]> {
    const baseRoute = "/v1/codeTables";
    const tablesStr = tableNames.join(",");
    const activeValues$: Observable<CodeTable[]> = this.codeTableApiService
      .get(`${baseRoute}?tableNames=${tablesStr}`)
      .pipe(
        map((tables: CodeTable[]) => {
          const filteredTables = tables.map((table: CodeTable) => {
            if (table.name === "make") {
              return {
                ...table,
                values: (
                  table as unknown as CodeTable<MakeCodeTableValue>
                ).values
                  .filter((item: MakeCodeTableValue) => item.isRetired !== "Y")
                  .map(
                    (item: MakeCodeTableValue) =>
                      ({
                        code: item.make?.toUpperCase(),
                        description: item.make,
                        isRetired: item.isRetired,
                      } as CodeTableValue)
                  ),
              };
            }

            return {
              ...table,
              values: table.values.filter(
                (item: CodeTableValue) => item.isRetired !== "Y"
              ),
            };
          });
          return filteredTables;
        }),
        catchError((error: HttpErrorResponse) => {
          return this.errorMasking(
            error,
            "Code tables failed to load properly"
          );
        })
      );
    return activeValues$;
  }

  private errorMasking(error: HttpErrorResponse, message: string) {
    if (error.status === 404) {
      return throwError(
        () => ({ ...error, statusText: message } as HttpErrorResponse)
      );
    }
    return throwError(() => error);
  }
}
