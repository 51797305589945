import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { Observable } from "rxjs";
import { first, map, mergeMap } from "rxjs/operators";

import { ApiService } from "../api";

@Injectable()
export class FeatureManagementApiService {
  constructor(private api: ApiService, private config: ConfigurationService) {}

  get<T>(
    path: string,
    queryStringParams?: { [key: string]: string | object }
  ): Observable<T> {
    let paramString = "";

    if (queryStringParams) {
      const params = Object.getOwnPropertyNames(queryStringParams).filter(
        (param) => queryStringParams[param] !== undefined
      );

      params.forEach((param, index) => {
        const paramValue = queryStringParams[param];

        paramString += `${index ? "&" : "?"}${param}=${
          typeof paramValue === "string"
            ? paramValue
            : JSON.stringify(paramValue)
        }`;
      });
    }

    return this.buildHeaders().pipe(
      first(),
      mergeMap((hdrs: HttpHeaders) =>
        this.api.get<T>(this.buildUrl(`${path}${paramString}`), hdrs)
      )
    );
  }

  private buildUrl(path: string): Observable<string> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      map((config) => {
        return `${config.featureManagementApiUrl}${path}`;
      })
    );
  }

  private buildHeaders(
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<HttpHeaders> {
    let header = headers;
    return this.config.configuration$.pipe(
      map((configuration: ClaimsSummaryConfigInfo) => {
        header = header
          .append("Pragma", "no-cache")
          .append("Cache-Control", "no-cache")
          .append("Expires", "0")
          .append("X-PGR-APPNAME", configuration.applicationName)
          .append("X-PGR-APPVERSION", configuration.applicationVersion);
        return header;
      })
    );
  }
}
