/* eslint-disable functional/immutable-data */
import { _264MimeTypes } from "./264-mime-types";
import { _3g2MimeTypes } from "./3g2-mime-types";
import { _3gpMimeTypes } from "./3gp-mime-types";
import { _3gp2MimeTypes } from "./3gp2-mime-types";
import { _aiMimeTypes } from "./ai-mime-types";
import { _amrMimeTypes } from "./amr-mime-types";
import { _apeMimeTypes } from "./ape-mime-types";
import { _asfMimeTypes } from "./asf-mime-types";
import { aviMimeTypes } from "./avi-mime-types";
import { _bmpMimeTypes } from "./bmp-mime-types";
import { csvMimeTypes } from "./csv-mime-types";
import { _davMimeTypes } from "./dav-mime-types";
import { docMimeTypes } from "./doc-mime-types";
import { docxMimeTypes } from "./docx-mime-types";
import { _emlMimeTypes } from "./eml-mime-types";
import { gifMimeTypes } from "./gif-mime-types";
import { _h264MimeTypes } from "./h264-mime-types";
import { _heicHeifMimeTypes } from "./heic-heif-mime-types";
import { _htmlMimeTypes } from "./html-mime-types";
import { _jp2MimeTypes } from "./jp2-mime-types";
import { jpgJpegMimeTypes } from "./jpg-jpeg-mime-types";
import { m2vMimeTypes } from "./m2v-mime-types";
import { _m4aMimeTypes } from "./m4a-mime-types";
import { m4vMimeTypes } from "./m4v-mime-types";
import { movMimeTypes } from "./mov-mime-types";
import { mp3MimeTypes } from "./mp3-mime-types";
import { mp4MimeTypes } from "./mp4-mime-types";
import { mpgMpegMimeTypes } from "./mpg-mpeg-mime-types";
import { _msgMimeTypes } from "./msg-mime-types";
import { pdfMimeTypes } from "./pdf-mime-types";
import { pngMimeTypes } from "./png-mime-types";
import { _pptMimeTypes } from "./ppt-mime-types";
import { _rtfMimeTypes } from "./rtf-mime-types";
import { tifMimeTypes } from "./tif-mime-types";
import { _tsMimeTypes } from "./ts-mime-types";
import { txtMimeTypes } from "./txt-mime-types";
import { _visioMimeTypes } from "./visio-mime-types";
import { wavMimeTypes } from "./wav-mime-types";
import { _webpMimeTypes } from "./webp-mime-types";
import { wmaMimeTypes } from "./wma-mime-types";
import { wmvMimeTypes } from "./wmv-mime-types";
import { xlsMimeTypes } from "./xls-mime-types";
import { xlsxMimeTypes } from "./xlsx-mime-types";

const _264Icons = _264MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const _3gpIcons = _3gpMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const _3gp2Icons = _3gp2MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const _3g2Icons = _3g2MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const aiIcons = _aiMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const amrIcons = _amrMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const apeIcons = _apeMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const asfIcons = _asfMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const aviIcons = aviMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const bmpIcons = _bmpMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const csvIcons = csvMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const davIcons = _davMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const docIcons = docMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const docxIcons = docxMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const emlIcons = _emlMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const gifIcons = gifMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const h264Icons = _h264MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const heicIcons = _heicHeifMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const htmlIcons = _htmlMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const jp2Icons = _jp2MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const jpgJpegIcons = jpgJpegMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const m2vIcons = m2vMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const m4aIcons = _m4aMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const m4vIcons = m4vMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const movIcons = movMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const mp3Icons = mp3MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const mp4Icons = mp4MimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const mpgMpegIcons = mpgMpegMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const msgIcons = _msgMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const pdfIcons = pdfMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const pngIcons = pngMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const pptIcons = _pptMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const rtfIcons = _rtfMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const tifIcons = tifMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const tsIcons = _tsMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const txtIcons = txtMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const vsdIcons = _visioMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const wavIcons = wavMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const webpIcons = _webpMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "insert_photo";

    return accumulator;
  },
  {}
);

const wmaIcons = wmaMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "audiotrack";

    return accumulator;
  },
  {}
);

const wmvIcons = wmvMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "videocam";

    return accumulator;
  },
  {}
);

const xlsIcons = xlsMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

const xlsxIcons = xlsxMimeTypes.reduce(
  (accumulator: { [key: string]: string }, current: string) => {
    accumulator[current] = "picture_as_pdf";

    return accumulator;
  },
  {}
);

export const mimeTypeIcons: { [key: string]: string } = {
  ..._264Icons,
  ..._3gpIcons,
  ..._3gp2Icons,
  ..._3g2Icons,
  ...aiIcons,
  ...amrIcons,
  ...apeIcons,
  ...asfIcons,
  ...aviIcons,
  ...bmpIcons,
  ...csvIcons,
  ...davIcons,
  ...docIcons,
  ...docxIcons,
  ...emlIcons,
  ...gifIcons,
  ...h264Icons,
  ...heicIcons,
  ...htmlIcons,
  ...jp2Icons,
  ...jpgJpegIcons,
  ...m2vIcons,
  ...m4aIcons,
  ...m4vIcons,
  ...movIcons,
  ...mp3Icons,
  ...mp4Icons,
  ...mpgMpegIcons,
  ...msgIcons,
  ...pdfIcons,
  ...pngIcons,
  ...pptIcons,
  ...rtfIcons,
  ...tifIcons,
  ...tsIcons,
  ...txtIcons,
  ...vsdIcons,
  ...wavIcons,
  ...webpIcons,
  ...wmaIcons,
  ...wmvIcons,
  ...xlsIcons,
  ...xlsxIcons,
};
