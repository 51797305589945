import { CoverageState } from "../models/coverage-state.interface";

export const initialState: CoverageState = {
  cvqs: [],
  isRequestInFlight: false,
  isMemoSubmitted: false,
  cvqRequestError: false,
  isPolicyAttached: undefined,
  policyNumber: undefined,
  policyProductCategoryCode: undefined,
  policyStateCode: undefined,
  policyNormalizedProductCategoryCode: undefined,
  lossDateTime: undefined,
  policyVehicles: undefined,
  claimVehicles: undefined,
  lienholders: undefined,
  policyDrivers: undefined,
  excludedDrivers: undefined,
  unlistedDrivers: undefined,
  uwMemoCategory: undefined,
  uwMemoType: undefined,
  rep: undefined,
  cvqAdd: {
    requestInFlight: false,
    isSuccess: undefined,
    errorMessage: undefined,
  },
  cvqResolve: {
    requestInFlight: false,
    isSuccess: undefined,
    errorMessage: undefined,
  },
  cvqAddNote: {
    requestInFlight: false,
    isSuccess: undefined,
    errorMessage: undefined,
  },
  claimLevel: undefined,
};
