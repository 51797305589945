import { Pipe, PipeTransform } from "@angular/core";
import { AppPathService } from "@modules/core/services/app-path";
import { EmbeddedParameter } from "@modules/shared";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const embeddedParameter = [
  { key: "hideClaimProHeader", value: "true" },
] as EmbeddedParameter[];
@Pipe({
  name: "claHideClaimProHeader",
})
export class ClaimProEmbeddedHeaderPipe implements PipeTransform {
  constructor(private appPathService: AppPathService) {}
  public transform(path$: Observable<string>): Observable<string> {
    return path$.pipe(map((path: string) => this.appendFlag(path)));
  }

  private appendFlag(path: string): string {
    return this.appPathService.appendSearchParams(path, embeddedParameter);
  }
}
