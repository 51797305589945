<div class="h-full">
  <mat-accordion class="p-0 h-full">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header
        [collapsedHeight]="'60px'"
        [expandedHeight]="'60px'"
      >
        <mat-panel-title>
          <div class="liability-header-container">
            <div class="liability-title-container">
              <h2 class="cui-h2 p-0" data-pgr-id="lblLiabilityTileHeader">
                <span class="align-middle"> Liability</span>
                <span class="pl-bl-2">
                  <!-- <button
                    mat-icon-button
                    aria-label="navigate to liability"
                    class="align-middle"
                    color="primary"
                    (click)="navigateLiability()"
                    (keydown.enter)="navigateLiability()"
                    (keydown.space)="navigateLiability()"
                  >
                    <mat-icon>arrow_forward</mat-icon>
                  </button> -->
                </span>
              </h2>
            </div>
            <div
              class="liability-status-container"
              [attr.data-pgr-id]="'tleLiabilityStatus'"
            >
              <span class="mr-bl-1">
                <cui-status-indicator
                  [background]="true"
                  [status]="
                    !isLoading && liabilityStatus && liabilityStatus != ''
                      ? liabilityStatus == 'Complete'
                        ? 'active'
                        : 'warn'
                      : undefined
                  "
                >
                  {{ !isLoading ? liabilityStatus : "" }}
                  <div
                    *ngIf="isLoading"
                    class="cui-ghost-line w-8"
                    style="border-radius: 2rem"
                  ></div>
                </cui-status-indicator>
              </span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider class="w-full"></mat-divider>
      <ng-container *ngIf="!isLoading">
        <div class="overflow-x-auto">
          <cui-notification-banner
            *ngIf="liabilityError"
            [actionable]="true"
            (action)="fetchLiabilityData.emit()"
            actionButtonText="Try Again"
            type="error"
          >
            An unexpected error occurred. Please try again.
          </cui-notification-banner>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <div class="flex flex-col m-xs" style="gap: 2.4rem">
          <div class="flex w-full" style="gap: 1.2rem">
            <div class="w-8 mr-xs">
              <div class="cui-ghost-line" style="border-radius: 2rem"></div>
            </div>
            <div class="w-2/3 flex-col">
              <div class="w-1/2 cui-ghost-line"></div>
              <div class="cui-ghost-line mt-xxs"></div>
            </div>
          </div>
          <div class="flex w-full" style="gap: 1.2rem">
            <div class="w-8 mr-xs">
              <div class="cui-ghost-line" style="border-radius: 2rem"></div>
            </div>
            <div class="w-3/4 flex-col">
              <div class="w-1/2 cui-ghost-line"></div>
              <div class="cui-ghost-line mt-xxs"></div>
            </div>
          </div>
          <div class="flex w-full" style="gap: 1.2rem">
            <div class="w-8 mr-xs">
              <div class="cui-ghost-line" style="border-radius: 2rem"></div>
            </div>
            <div class="w-1/2 flex-col">
              <div class="w-1/2 cui-ghost-line"></div>
              <div class="cui-ghost-line mt-xxs"></div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isLoading && liability">
        <div
          class="overflow-x-hidden h-full"
          style="min-height: 20rem; padding-bottom: 2.4rem"
        >
          <div #tableContainer class="table-container">
            <table
              mat-table
              [dataSource]="dataSource"
              class="cui-table"
              multiTemplateDataRows
            >
              <ng-container matColumnDef="liabilityPercentage">
                <th
                  mat-header-cell
                  class="cui-cell-1"
                  *matHeaderCellDef
                  colspan="1"
                ></th>
                <td
                  mat-cell
                  class="cui-cell-1"
                  colspan="1"
                  *matCellDef="let element; let i = dataIndex"
                >
                  <mat-chip-set
                    role="list"
                    aria-label="Percentage Chips"
                    [attr.data-pgr-id]="'lblLiabilityPercentage.' + i"
                  >
                    <mat-chip role="listitem" [disableRipple]="true"
                      >{{ element.percent }}%</mat-chip
                    >
                  </mat-chip-set>
                </td>
              </ng-container>
              <!-- Feature Sequence # Column -->
              <ng-container matColumnDef="liabilityPartyDetails">
                <th
                  mat-header-cell
                  class="cui-cell-5"
                  *matHeaderCellDef
                  colspan="5"
                ></th>
                <td
                  mat-cell
                  class="cui-cell-5"
                  colspan="5"
                  [attr.data-pgr-id]="'lblLiability.' + i"
                  *matCellDef="let element; let i = dataIndex"
                >
                  <div style="width: 100%">
                    <span class="party-name">
                      <span
                        *ngIf="element.partyName && element.partyName != null"
                        >{{ element.partyName.last }},
                        {{ element.partyName.first }}
                        <span
                          *ngIf="
                            element.partyName.middleInitial &&
                            element.partyName.middleInitial.length > 0
                          "
                        >
                          {{ element.partyName.middleInitial }}
                        </span>
                        <span
                          *ngIf="
                            element.partyName.suffix &&
                            element.partyName.suffix.length > 0
                          "
                        >
                          {{ element.partyName.suffix }}.
                        </span></span
                      >
                      <span
                        *ngIf="
                          element.businessName &&
                          element.businessName != null &&
                          element.businessName.length > 0
                        "
                        >{{ element.businessName }}</span
                      >
                    </span>
                    | {{ element.partyRelations.join("") }}
                  </div>
                  <div class="insurance-providers">
                    <span
                      *ngIf="!element.fixedProperty"
                      [attr.data-pgr-id]="'lblLiabilityVehicle.' + i"
                    >
                      <span *ngIf="element.vehicleYear"
                        >{{ element.vehicleYear }}&nbsp;</span
                      >
                      <span *ngIf="element.vehicleMake"
                        >{{ element.vehicleMake }}&nbsp;</span
                      >
                      <span *ngIf="element.vehicleModel"
                        >{{ element.vehicleModel }}&nbsp;</span
                      >
                    </span>
                    <span
                      *ngIf="element.fixedProperty"
                      [attr.data-pgr-id]="'lblLiabilityFixedProperty.' + i"
                    >
                      {{ element.fixedProperty.description }}
                    </span>
                    <span
                      *ngIf="
                        element.insuranceInformation &&
                        element.insuranceInformation.length > 0
                      "
                      [attr.data-pgr-id]="'lblLiabilityInsurance.' + i"
                      >-&nbsp;{{
                        element.insuranceInformation.join(" | ")
                      }}</span
                    >
                  </div>
                </td>
              </ng-container>

              <!-- Empty State -->
              <ng-container matColumnDef="empty">
                <td mat-footer-cell *matFooterCellDef class="p-0" colspan="1">
                  <cui-empty-state
                    *ngIf="!liabilityError && !liability.length && !isLoading"
                    icon="search_off"
                    primaryText="No liability found."
                  >
                  </cui-empty-state>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="detail-row"
              ></tr>

              <tr
                mat-footer-row
                *matFooterRowDef="['empty']"
                [hidden]="liability.length"
              ></tr>
            </table>
          </div>
        </div>
        <div
          *ngIf="!isLoading && liability && liability.length > 5"
          class="p-xxs"
        >
          <mat-divider
            style="
              overflow: hidden;
              width: calc(100% + 4.8rem);
              margin-left: -2.4rem;
            "
          ></mat-divider>
          <div class="w-full flex justify-center mt-xxs">
            <button
              mat-button
              color="primary"
              data-pgr-id="btnViewAllLiablities"
              (click)="toggleExpanded()"
            >
              {{ expanded ? "View Less" : "View All" }}
              <mat-icon matSuffix>{{
                expanded ? "expand_less" : "expand_more"
              }}</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <mat-paginator
        class="hidden"
        [attr.aria-hidden]="true"
        [pageSize]="pageSize"
      ></mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
</div>
