import { Component, Input } from "@angular/core";
import { ClaimDetails } from "@modules/core";
import { DateTime } from "luxon";

@Component({
  selector: "cla-claim-status-indicator",
  templateUrl: "./claim-status-indicator.component.html",
  styleUrls: ["./claim-status-indicator.component.scss"],
})
export class ClaimStatusIndicatorComponent {
  @Input() isInitialLoad: boolean;
  @Input() set claimDetails(claimDetails: ClaimDetails) {
    this._claimDetails = claimDetails;
  }

  private _claimDetails: ClaimDetails;

  get claimStatus() {
    return this._claimDetails?.claimStatus;
  }

  get isClosedStatus() {
    return this.claimStatus?.name === "2" || this.claimStatus?.name === "6";
  }

  get isDateNull() {
    return DateTime.fromISO(this._claimDetails.claimCloseDate).hasSame(
      DateTime.fromISO("0001-01-01T00:00:00"),
      "millisecond"
    );
  }

  get statusLabel() {
    if (this.isClosedStatus) return "CLOSED";
    return this.claimStatus?.description;
  }

  get toolTip() {
    if (this.isClosedStatus) return this.closedDate;
  }

  get closedDate() {
    const luxonDate = DateTime.fromISO(this._claimDetails.claimCloseDate);
    if (!luxonDate.isValid) return "Closed Date: Invalid Date";
    if (this.isDateNull) return "Closed";
    return `Closed Date: ${luxonDate.toFormat("MM/dd/yyyy")}`;
  }

  get tabIndex() {
    if (this.isClosedStatus) return 0;
  }
}
