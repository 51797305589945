const adapterApi = "adapterApiRouteUrl";
const businessApi = "partyBusinessApiUrl";

export const urlConstants = Object.freeze({
  config: "api/config/application.config.json",
  associatedParties: {
    get: (partyId: string) =>
      `${adapterApi}/v1/associated-parties?partyId=${partyId}`,
    add: (partyId: string, associatedPartyId: string) =>
      `${businessApi}/v1/parties/${partyId}/associatedParties/${associatedPartyId}`,
    edit: (partyId: string, associatedPartyId: string) =>
      `${businessApi}/v1/parties/${partyId}/associatedParties/${associatedPartyId}`,
    delete: (partyId: string, associatedPartyId: string) =>
      `${businessApi}/v1/parties/${partyId}/associatedParties/${associatedPartyId}`,
  },
  attorneys: {
    get: (partyId: string) => `${adapterApi}/v1/attorneys?partyId=${partyId}`,
    getFeatureOptions: (partyId: string) =>
      `${adapterApi}/v1/attorneys/feature-options?partyId=${partyId}`,
    getCopyOptions: (claimNumber: string) =>
      `${adapterApi}/v1/attorneys?claimNumber=${claimNumber}`,
    getProtectedData: (partyId: string, attorneyId: string) =>
      `${businessApi}/v1/parties/${partyId}/attorneys/${attorneyId}/protectedData`,
    add: (partyId: string) => `${businessApi}/v1/parties/${partyId}/attorneys`,
    edit: (partyId: string, attorneyId: string) =>
      `${businessApi}/v1/parties/${partyId}/attorneys/${attorneyId}`,
    delete: (partyId: string, attorneyId: string) =>
      `${businessApi}/v1/parties/${partyId}/attorneys/${attorneyId}`,
    deleteFirst: (partyId: string) =>
      `${businessApi}/v1/parties/${partyId}/attorneys`,
  },
  employers: {
    get: (partyId: string) => `${adapterApi}/v1/employers?partyId=${partyId}`,
    add: (partyId: string) => `${businessApi}/v1/parties/${partyId}/employers`,
    edit: (partyId: string, employerId: string) =>
      `${businessApi}/v1/parties/${partyId}/employers/${employerId}`,
  },
  otherInsurances: {
    get: (partyId: string) =>
      `${adapterApi}/v1/other-insurances?partyId=${partyId}`,
    add: (partyId: string) =>
      `${businessApi}/v1/parties/${partyId}/InsuranceCarriers`,
    edit: (partyId: string, carrierId: string) =>
      `${businessApi}/v1/parties/${partyId}/InsuranceCarriers/${carrierId}`,
  },
  parties: {
    get: (partyId: string) => `${adapterApi}/v1/parties/${partyId}`,
    getParties: (claimNumber: string) =>
      `${adapterApi}/v1/parties?claimNumber=${claimNumber}`,
    getProtectedData: (partyId: string, type: string) =>
      `${businessApi}/v1/Parties/${partyId}/ProtectedData?type=${type}`,
    addIndividual: `${businessApi}/v2/Parties/Individual`,
    addBusiness: `${businessApi}/v2/Parties/Business`,
    upsertFirstContact: (partyId: string) =>
      `${businessApi}/v1/parties/${partyId}/firstcontact`,
    deleteFirstContact: (partyId: string) =>
      `${businessApi}/v1/parties/${partyId}/firstcontact`,
    putIndividualParty: (partyId: string) =>
      `${businessApi}/v2/parties/${partyId}/Individual`,
    putBusinessParty: (partyId: string) =>
      `${businessApi}/v2/parties/${partyId}/Business`,
  },
  validation: {
    stateZip: (stateCode: string, zipCode: string) =>
      `${businessApi}/v1/Validation/ValidateZipStateCombo?StateCode=${stateCode}&ZipCode=${zipCode}`,
  },
});
