import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { of } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";

@Injectable()
export class ClaimStatusGuardService implements CanActivate {
  constructor(
    private router: Router,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.claimDetailsStateService.onClaimDetailsRequest(
      route.params.claimNumber
    );

    return this.claimDetailsStateService.claimDetails$.pipe(
      filter((claimDetails) => !!claimDetails),
      map((claimDetails) => claimDetails?.claimStatus.description),
      tap((status) => this.handleStatus(status)),
      map((status) => status !== "CANCEL" && status !== "PRELIM"),
      catchError(() => of(this.router.parseUrl("/")))
    );
  }

  private handleStatus(claimStatusDescription: string | undefined) {
    switch (claimStatusDescription) {
      case "CANCEL":
        this.router.navigateByUrl("/cancelled-claim");
        break;
      case "PRELIM":
        this.router.navigateByUrl("/preliminary-claim");
        break;
    }
  }
}
