<div class="flex">
  <button
    mat-flat-button
    color="primary"
    class="mr-bl-2 open-viewer"
    data-pgr-id="btnOpenViewer"
    (click)="launchViewerWindow()"
    [disabled]="(contentSummaryViewService.actionIsOpenable$ | async) === false"
    [cuiInteractionEvent]="
      contentSummaryEventService.clickAction(actionNames.open)
    "
    [attr.aria-label]="
      selectedItemsCount > 1
        ? 'Open selected items in new window'
        : 'Open selected item in new window'
    "
  >
    OPEN
    <mat-icon iconPositionEnd> open_in_new </mat-icon>
  </button>
</div>
