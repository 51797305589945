import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "cla-google-maps-address",
  styleUrls: ["./google-maps-address.component.scss"],
  templateUrl: "google-maps-address.component.html",
})
export class GoogleMapsAddressComponent implements OnChanges {
  @Input() public address: string;

  public googleMapsUrl: string;
  public addressToCopy: string;

  public ngOnChanges(): void {
    this.googleMapsUrl = this.getGoogleMapsUrl();
    this.addressToCopy = this.getCopyAddress();
  }

  private getCopyAddress() {
    if (!this.address || this.address == "—") {
      return "";
    }

    const addressNoBreaks = this.address.replace(/<br *\/*>/g, ",");
    const addressFixedCommas = addressNoBreaks.replace(/ ?, ?/g, ", ");
    const strippedAddress = addressFixedCommas.replace(/\s{2,}/g, " ");
    return strippedAddress;
  }

  private getGoogleMapsUrl() {
    if (!this.address || this.address == "—") {
      return "";
    }

    const addressNoBreaks = this.address.replace(/<br *\/*>/g, " ");
    const strippedAddress = addressNoBreaks.replace(/\s{2,}/g, " ");

    const encodedAddress = strippedAddress
      .split(" ")
      .map((x) => encodeURIComponent(x))
      .join("+");
    const url = `https://www.google.com/maps/search/${encodedAddress}`;

    return url;
  }
}
