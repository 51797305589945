<cla-claim-summary-header-data
  [headerData]="headerData"
  [ownerRepCode]="ownerRepCode"
  [ownerRepSummary]="ownerRepSummary"
  [isLoading]="claimDetailsStateService.isClaimDetailsRequestInFlight$ | async"
  [isFileOwnerLoading]="
    (claimDetailsStateService.isClaimDetailsRequestInFlight$ | async) ||
    (coreUiStateService.isLoadingRepSummaries$ | async)
  "
></cla-claim-summary-header-data>
