import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PaymentDetailsLambdaInfo } from "../models";

export const retrieveCurrentClaimPayments = createAction(
  "[Payments API] Get Current Claim Payments",
  props<{ keepExistingPayments: boolean }>()
);

export const retrieveClaimPayments = createAction(
  "[Payments API] Get Claim Payments",
  props<{ claimNumber: string; keepExistingPayments: boolean }>()
);

export const retrieveClaimPaymentsSuccess = createAction(
  "[Payments API] Get Claim Payments Success",
  props<{ payments: PaymentDetailsLambdaInfo[] }>()
);

export const retrieveClaimPaymentsError = createAction(
  "[Payments API] Get Claim Payments Error",
  props<{ error: HttpErrorResponse }>()
);

export const setMappedTableData = createAction(
  "[Payments] Set Mapped Table Data",
  props<{ finalizedPaymentInfo: PaymentDetailsLambdaInfo[] }>()
);

export const filterPaymentsSelect = createAction(
  "[Payments] Filter Payments - Select",
  props<{ columnId: string; filterText: string }>()
);

export const filterPaymentsNumberRange = createAction(
  "[Payments] Filter Payments - Number Range",
  props<{
    columnId: string;
    minText: string | undefined;
    maxText: string | undefined;
  }>()
);

export const applyFilters = createAction(
  "[Payments] Apply Filter Payments",
  props<{
    filteredPayments: PaymentDetailsLambdaInfo[];
    filters: { [key: string]: any };
  }>()
);

export const closePaymentsFilter = createAction(
  "[Payments] Close Payments Filter"
);

export const resetPaymentsFilters = createAction(
  "[Payments] Reset Payments Filter"
);

export const resetColumnFilters = createAction(
  "[Payments] Reset Column Filters",
  props<{ columnId: string }>()
);

export const doNothing = createAction("[Payments] Do Nothing");
