/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { PolicyHelperConstant } from "../../helpers";
import * as PolicySearchActions from "../policy-search/policy-search.actions";
import * as PolicyActions from "../policy/policy.actions";

import * as SpinnerActions from "./spinner.actions";

@Injectable()
export class SpinnerEffects {
  spinnerHideActions = new Map<string, any>();

  showSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PolicyActions.getClaimPolicyWithPreReq,
        PolicyActions.getClaimLossVehiclesWithPreReq,
        PolicyActions.policyConfirm,
        PolicyActions.getPolicyAttachVehicles,
        PolicySearchActions.searchPolicyByPolicyNumberRequest
      ),
      map((action: any) =>
        SpinnerActions.showSpinner({ spinnerId: "", actionType: action.type })
      )
    )
  );

  hideSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PolicyActions.unsupportedVehicleType,
        PolicyActions.getAgentInfoSuccess,
        PolicyActions.getClaimPolicyWithPreReqSuccess,
        PolicyActions.getClaimLossVehiclesWithPreReqSuccess,
        PolicyActions.policyConfirmSuccess,
        PolicyActions.policyErrorOccurred,
        PolicyActions.policyConfirmError,
        PolicyActions.getPolicyAttachVehiclesSuccess,
        PolicySearchActions.searchPolicyByPolicyNumberResponse,
        PolicySearchActions.searchPolicyErrorResponse
      ),
      map((action: any) =>
        SpinnerActions.hideSpinner(this.spinnerHideActions.get(action.type))
      )
    )
  );

  constructor(private actions$: Actions) {
    this.init();
  }

  private init() {
    this.spinnerHideActions.set(PolicyActions.unsupportedVehicleType.type, {
      spinnerId: "",
      actionType: PolicyActions.getClaimPolicyVehicles.type,
    });
    this.spinnerHideActions.set(PolicyActions.getAgentInfoSuccess.type, {
      spinnerId: "",
      actionType: PolicyActions.getAgentInfo.type,
    });
    this.spinnerHideActions.set(
      PolicyActions.getClaimPolicyWithPreReqSuccess.type,
      { spinnerId: "", actionType: PolicyActions.getClaimPolicyWithPreReq.type }
    );
    this.spinnerHideActions.set(
      PolicyActions.getClaimLossVehiclesWithPreReqSuccess.type,
      {
        spinnerId: "",
        actionType: PolicyActions.getClaimLossVehiclesWithPreReq.type,
      }
    );
    this.spinnerHideActions.set(PolicyActions.policyConfirmSuccess.type, {
      spinnerId: "",
      actionType: PolicyActions.policyConfirm.type,
    });
    this.spinnerHideActions.set(
      PolicyActions.getPolicyAttachVehiclesSuccess.type,
      { spinnerId: "", actionType: PolicyActions.getPolicyAttachVehicles.type }
    );
    this.spinnerHideActions.set(PolicyActions.policyErrorOccurred.type, {
      spinnerId: PolicyHelperConstant.SPINNERID.ERROR,
      actionType: "",
    });
    this.spinnerHideActions.set(PolicyActions.policyConfirmError.type, {
      spinnerId: PolicyHelperConstant.SPINNERID.ERROR,
      actionType: "",
    });
    this.spinnerHideActions.set(
      PolicySearchActions.searchPolicyByPolicyNumberResponse.type,
      {
        spinnerId: "",
        actionType: PolicySearchActions.searchPolicyByPolicyNumberRequest.type,
      }
    );
    this.spinnerHideActions.set(
      PolicySearchActions.searchPolicyErrorResponse.type,
      { spinnerId: PolicyHelperConstant.SPINNERID.ERROR, actionType: "" }
    );
  }
}
