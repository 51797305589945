import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ISimpleDialog } from "../../../models/shared";

@Component({
  selector: "cla-simple-dialog",
  templateUrl: "./simple-dialog.component.html",
})
export class SimpleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SimpleDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ISimpleDialog
  ) {
    this.setClose();
    this.setDefaultButton();
  }

  public hasMessage(): boolean {
    return this.data.message ? true : false;
  }

  private setDefaultButton() {
    if (!this.data.confirm && !this.data.cancel) {
      this.data.confirm = "Okay";
    }
  }

  private setClose() {
    this.dialogRef.disableClose = true;
  }
}
