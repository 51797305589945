import { ISimpleDialog } from "../../../models";

export class ActionsValidationUtilityConstants {
  public static get invalidChangeDocSetTypes(): string[] {
    return ["preliminary ultramate", "committed ultramate"];
  }

  public static get validChangeDocSetStatuses(): string[] {
    return ["in process", "received"];
  }

  public static get changeDocSetStatusValidationMessaging(): {
    [key: string]: string;
  } {
    return {
      invalidTypesMessage:
        "The following types of content cannot be changed: Committed UltraMate and Preliminary UltraMate",
      invalidStatusMessage:
        "Only content with a status of In Process or Received can be changed.",
      invalidContentPackageStatusMessage:
        "Only content with a status of Received can be changed.",
    };
  }

  public static get viewContentSharingSummaryValidationMessaging(): ISimpleDialog {
    return {
      title: "Cannot view sharing summary.",
      message:
        "Please select a Content Sharing Invitation to view sharing summary.",
    };
  }

  public static get viewEnvelopeContentsValidationMessaging(): ISimpleDialog {
    return {
      title: "Cannot view envelope contents for the following:",
      message: `Outbound Content<br />
      Imported Content<br />
      eSign Documents`,
    };
  }

  public static get viewEnvelopeContentsNoControlValidationMessaging(): ISimpleDialog {
    return {
      title: "Cannot view envelope contents.",
      message:
        "There is no envelope control number associated with this content",
    };
  }

  public static get invalidChangeRecipientDocSetStatuses(): string[] {
    return ["Cancelled", "Returned", "In Process"];
  }

  public static get validChangeRecipientStatuses(): string[] {
    return ["Complete", "In Error"];
  }

  public static get invalidChangeRecipientDocSetDescriptions(): string[] {
    return [
      "Photo Estimate Invitation",
      "Photo Estimate Reminder",
      "Content Submission Invitation",
    ];
  }

  public static get deliveryMethod(): { [key: string]: string } {
    return {
      esign: "06",
    };
  }

  public static get recipientStatuses(): { [key: string]: string } {
    return {
      failed: "Failed",
      inError: "In Error",
    };
  }

  public static get tooManyItemsMessaging(): ISimpleDialog {
    return {
      title: "Cannot view this many items.",
      message: "Please select only 1 item to view.",
    };
  }
}
