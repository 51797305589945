<cui-popover align="rightTop" (open)="onPopoverOpen()">
  <button
    cui-popover-trigger
    mat-icon-button
    [attr.aria-label]="buttonAriaText"
    data-pgr-id="openPartyContactCardButton"
  >
    <mat-icon>more_horiz</mat-icon>
  </button>

  <cui-party-contact-card
    cui-popover-content
    [isLoading]="
      coreUiExtensionsStateService.selectPartyIsLoadingById(partyId) | async
    "
    [party]="
      coreUiExtensionsStateService.selectPartyContactCardById(partyId) | async
    "
    [error]="coreUiExtensionsStateService.selectPartyErrorById(partyId) | async"
  ></cui-party-contact-card>
</cui-popover>
