import { RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RouterStateUrl } from "./router-state-url.interface";

export const selectRouter = createFeatureSelector<RouterReducerState>("router");

export const selectRouterState = createSelector(
  selectRouter,
  (state) => state?.state as unknown as RouterStateUrl
);

export const selectRouterParameters = createSelector(
  selectRouterState,
  (state: RouterStateUrl) => state?.params
);

export const selectRouterQueryParams = createSelector(
  selectRouterState,
  (state: RouterStateUrl) => state.queryParams
);

export const selectRouterData = createSelector(
  selectRouterState,
  (state: RouterStateUrl) => state.data
);

export const selectRouterUrl = createSelector(
  selectRouterState,
  (state: RouterStateUrl) => state.url
);

// TODO: inspect this since Angular recommends NavigationCancel instead of selectNavigationId: https://v13.angular.io/guide/update-to-latest-version#:~:text=PR%C2%A0%2343496-,The,-router%20no%20longer
export const selectNavigationId = createSelector(
  selectRouter,
  (state) => state.navigationId
);
