import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { selectRequestinFlight } from "@modules/policy/state/uw-memo-landing/uw-memo-landing.selectors";
import { Store } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { Observable, Subject } from "rxjs";

import { UwMemo } from "../../models/uw-memo";
import { UwMemoStatus } from "../../models/uw-memo-status";

@Component({
  selector: "cla-uw-memo-landing-overview",
  templateUrl: "./uw-memo-landing-overview.component.html",
  styleUrls: ["./uw-memo-landing-overview.component.scss"],
})
export class UwMemoLandingOverviewComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() underwritingMemos: UwMemo[] = [];
  @Input() userId: string;

  dataSource: MatTableDataSource<UwMemo>;
  dialogRef: any;
  displayedColumns: string[] = [
    "statusDesc",
    "categoryDesc",
    "typeDesc",
    "createdByUserId",
    "createdUtcDateTime",
  ];
  selectedIndex: number;
  uwMemoStatusCodes: any[];
  uwMemoQuestionXref: any[];

  uwMemoStatus: UwMemoStatus = {
    "Missing Information": "alert",
    Completed: "active",
    "Completed - No Change": "active",
    "In Progress": "warn",
    Sent: "warn",
  };

  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  requestInFlight$: Observable<boolean>;

  constructor(public store$: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.requestInFlight$ = this.store$.select(selectRequestinFlight);
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource<UwMemo>(
      this.underwritingMemos.map((memo: UwMemo) => {
        return {
          ...memo,
          createdByUserId:
            memo.createdByUserId?.split("(")[1] == null
              ? ""
              : memo.createdByUserId?.split("(")[1].replace(")", "") == "null"
              ? "-"
              : memo.createdByUserId?.split("(")[1].replace(")", ""),
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  selectRow(index: number): void {
    this.selectedIndex = index;

    event?.stopImmediatePropagation();
  }
}
