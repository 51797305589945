import { Injectable } from "@angular/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { AdapterApiService } from "../adapter-api";
import {
  Rep,
  RepSummary,
  CoverageDetailInfo,
} from "@claimssummaryshared/models";

@Injectable()
export class ClaimsSummaryAdapterApiService {
  private readonly repRoute = "/lossviewerapi/v1/reps";
  private readonly claimsLandingRoute = "/lossviewerapi/v1/claims";

  constructor(
    private api: AdapterApiService,
    private config: ConfigurationService
  ) {}

  public getRep(): Observable<Rep> {
    return this.get<Rep>(this.repRoute);
  }

  public getCoverages(
    claimNumber: string,
    policyNumber: string,
    policyRenewalSuffixNumber: string,
    productCategoryCode: string,
    dateOfLoss: string,
    isClaimVerified: string
  ): Observable<CoverageDetailInfo> {
    return this.get<CoverageDetailInfo>(
      `${this.claimsLandingRoute}/${claimNumber}/coverage?policyNumber=${policyNumber}&policyRenewalSuffixNumber=${policyRenewalSuffixNumber}&productCategoryCode=${productCategoryCode}&dateOfLoss=${dateOfLoss}&isClaimVerified=${isClaimVerified}`
    );
  }

  public getRepWithRepCode(repCode: string): Observable<Rep> {
    return this.get<Rep>(`${this.repRoute}/${repCode}`);
  }

  public getRepSearch(repSearch: string): Observable<RepSummary[]> {
    return this.get<RepSummary[]>(
      `${this.repRoute}/search?searchTerm=${encodeURIComponent(repSearch)}`
    );
  }

  public get<T>(path: string): Observable<T> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.api.get<T>(config.adapterApiRouteUrl, path);
      })
    );
  }
}
