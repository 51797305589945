import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PartyDetail } from "../../models/party-detail";

export const getPartyDetails = createAction(
  "[Party] Get Party Details",
  props<{ claimNumber: string }>()
);

export const getPartyDetailsSuccess = createAction(
  "[Party] Get Party Details Success",
  props<{ partyDetails: PartyDetail[] }>()
);

export const partyErrorOccurred = createAction(
  "[Policy] Error Occurred",
  props<{ error: Error | HttpErrorResponse }>()
);
