import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { ClaimPartyContactCardInfo } from "@pgr-cla/core-ui-components";
import { actions } from "../constants";
import {
  ClaimRepContactCardInfoRedacted,
  RepairShopSummariesResponse,
} from "../models";

export abstract class CoreUiExtensionsActions {
  public static onGetRepSummariesRequest = createAction(
    actions.getRepSummaries,
    props<{ codes: string[] }>()
  );

  public static onGetNewRepSummariesOnlyRequest = createAction(
    actions.onGetNewRepSummariesOnlyRequest,
    props<{ codes: string[] }>()
  );

  public static onGetRepSummariesRequestSuccess = createAction(
    actions.getRepSummariesSuccess,
    props<{ repSummaries: ClaimRepContactCardInfoRedacted[] }>()
  );
  public static onGetRepSummariesRequestFailure = createAction(
    actions.getRepSummariesFailure
  );
  public static onRetryGetRepSummariesRequest = createAction(
    actions.retryGetRepSummaries
  );

  public static onGetPartySummariesRequest = createAction(
    actions.getPartySummaries,
    props<{ partyId: string }>()
  );
  public static onGetPartySummariesRequestSuccess = createAction(
    actions.getPartySummariesSuccess,
    props<{ ClaimPartyContactCardInfo: ClaimPartyContactCardInfo }>()
  );
  public static onGetPartySummariesRequestFailure = createAction(
    actions.getPartySummariesFailure,
    props<{ error: HttpErrorResponse; partyId: string }>()
  );
  public static onGetRepairShopSummaryRequest = createAction(
    actions.getRepairShopSummary,
    props<{ code: string }>()
  );
  public static onGetRepairShopSummariesRequest = createAction(
    actions.getRepairShopSummaries,
    props<{ codes: string[] }>()
  );
  public static onGetRepairShopSummariesRequestCanceled = createAction(
    actions.getRepairShopSummariesCanceled
  );
  public static onGetRepairShopSummariesRequestSuccess = createAction(
    actions.getRepairShopSummariesSuccess,
    props<{ response: RepairShopSummariesResponse }>()
  );
  public static onGetRepairShopSummariesRequestFailure = createAction(
    actions.getRepairShopSummariesFailure,
    props<{ codes: string[] }>()
  );
  public static onRetryGetRepairShopSummariesRequest = createAction(
    actions.retryGetRepairShopSummaries
  );
}
