import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import {
  IContentSetVersion,
  IContentSummaryItem,
  IVersionSelectorData,
} from "@modules/electronic-file-folder/models";
import { TitleRepCodePipe } from "@modules/electronic-file-folder/pipes";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ClaimsContentViewerService } from "@modules/electronic-file-folder/services/core/claims-content-viewer/claims-content-viewer.service";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { SimpleDialogComponent } from "../../shared";

@Component({
  selector: "cla-version-selector",
  templateUrl: "version-selector.component.html",
  styleUrls: ["./version-selector.component.scss"],
  providers: [TitleRepCodePipe],
})
export class VersionSelectorComponent {
  public contentItemToCopy: IContentSummaryItem;
  public contentVersions: IContentSetVersion[];
  public displayedColumns: string[] = [
    "versionNumber",
    "dateModified",
    "modifiedBy",
  ];
  public docSetId: string;
  public sourceClaimNumber: string;
  public versionNbr: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVersionSelectorData,
    public dialogRef: MatDialogRef<VersionSelectorComponent>,
    public coreUiStateService: CoreUiExtensionsStateService,
    private contentSummaryStoreService: ContentSummaryStoreService,
    private claimsContentViewerService: ClaimsContentViewerService,
    private dialog: MatDialog
  ) {
    this.contentVersions = data.versionData.slice().reverse();
  }

  public displayVersion(versionIndex: number): string {
    if (versionIndex === this.contentVersions.length - 1) {
      return "-";
    }
    return (this.contentVersions.length - 1 - versionIndex).toString();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public openVersion(versionNbr: number): void {
    this.contentSummaryStoreService
      .getPreviewedItem()
      .subscribe((contentItem: IContentSummaryItem) => {
        const openValidationResult =
          this.claimsContentViewerService.validateItemsForOpening([
            contentItem,
          ]);

        if (openValidationResult.contentItems.length > 0) {
          this.claimsContentViewerService.openViewerForVersion(
            contentItem,
            versionNbr
          );
        }

        if (openValidationResult.error) {
          this.dialog.open(SimpleDialogComponent, {
            data: openValidationResult.error,
            width: CUI_DIALOG_WIDTH.SMALL,
          });
        }
      })
      .unsubscribe();
  }
}
