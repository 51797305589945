<section class="rep-contact-card">
  <ng-container *ngIf="isLoading">
    <span class="screen-reader-only">Loading...</span>
    <div class="ghost-lines ghost-row ghost-header"></div>
    <div class="ghost-lines ghost-row ghost-label"></div>
    <div class="ghost-lines ghost-row ghost-label"></div>
    <div class="ghost-row flex">
      <div class="ghost-lines ghost-icon"></div>
      <div class="ghost-flex-column">
        <div class="ghost-lines ghost-text"></div>
        <div class="ghost-lines ghost-label"></div>
      </div>
    </div>
    <div class="ghost-row flex">
      <div class="ghost-lines ghost-icon"></div>
      <div class="ghost-flex-column">
        <div class="ghost-lines ghost-text"></div>
        <div class="ghost-lines ghost-label"></div>
      </div>
    </div>
    <div class="ghost-row flex">
      <div class="ghost-lines ghost-icon"></div>
      <div class="ghost-flex-column">
        <div class="ghost-lines ghost-text"></div>
        <div class="ghost-lines ghost-label"></div>
      </div>
    </div>
    <div class="ghost-lines ghost-row ghost-header"></div>
    <div class="ghost-lines ghost-row ghost-label"></div>
    <div class="ghost-lines ghost-row ghost-label"></div>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <cui-notification-banner
      *ngIf="isError"
      [hasBottomBorder]="!!primaryRep"
      [class.card-banner]="!!primaryRep"
      [class.rounded-bottom]="!primaryRep"
      [class.close-btn-padding]="isInPopover"
      type="error"
    >
      {{
        errorMessage
          ? errorMessage
          : "Contact info could not be loaded. Please try again later."
      }}
    </cui-notification-banner>
    <ng-container *ngIf="primaryRep">
      <div class="card-header">
        <ng-container [ngSwitch]="headerTag">
          <h2 *ngSwitchCase="'H2'" cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h2>
          <h3 *ngSwitchCase="'H3'" cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h3>
          <h4 *ngSwitchCase="'H4'" cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h4>
          <h5 *ngSwitchCase="'H5'" cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h5>
          <h6 *ngSwitchCase="'H6'" cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h6>
          <h3 *ngSwitchDefault cuiPopoverTitle class="cui-h3 rep-name-code">
            <ng-container *ngTemplateOutlet="primaryRepNameCode"></ng-container>
          </h3>
          <ng-template #primaryRepNameCode
            >{{ primaryRep.name }} ({{ primaryRep.code }})</ng-template
          >
        </ng-container>
      </div>
      <ng-container *ngIf="managerRep">
        <mat-divider class="manager-rep-divider"></mat-divider>
        <div class="rep-contact-card-footer">
          <ng-container [ngSwitch]="headerTag">
            <h3
              *ngSwitchCase="'H2'"
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h3>
            <h4
              *ngSwitchCase="'H3'"
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h4>
            <h5
              *ngSwitchCase="'H4'"
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h5>
            <h6
              *ngSwitchCase="'H5'"
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h6>
            <h6
              *ngSwitchCase="'H6'"
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h6>
            <h4
              *ngSwitchDefault
              cuiPopoverTitle
              class="cui-h4 manager-header-tag"
            >
              <ng-container *ngTemplateOutlet="reportsTo"></ng-container>
            </h4>
            <ng-template #reportsTo>Reports to</ng-template>
          </ng-container>
        </div>
        <div class="expand-toggle rep-toggle" [class.no-pointer-events]="true">
          <div class="flex-column">
            <div class="manager-rep-title">
              {{ managerRep.name }} ({{ managerRep.code }})
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
