import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiErrorResponse } from "@modules/core";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { PolicyService } from "../../services";

import * as policySearchActions from "./policy-search.actions";

@Injectable()
export class PolicySearchEffects {
  detectPolicySearchByPolicyNumberRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policySearchActions.searchPolicyByPolicyNumberRequest),
      switchMap((request) =>
        this.policyService
          .getPolicySearchByPolicyNumber(
            request.policyNumber,
            request.renewalSuffix,
            request.dateOfLoss
          )
          .pipe(
            switchMap((response) => {
              return of(
                policySearchActions.searchPolicyByPolicyNumberResponse({
                  policySearchResults: response,
                })
              );
            }),
            catchError((error) => {
              return of(
                policySearchActions.searchPolicyErrorResponse({
                  errorMessage: this.mapErrorMessage(error),
                })
              );
            })
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private policyService: PolicyService
  ) {}

  private mapErrorMessage = (
    error: any,
    responseMessageMap: { [key: string]: string } = {}
  ): string => {
    if (error.status === 400 && error.error !== null) {
      const apiError = error.error as ApiErrorResponse;

      if (apiError.errorCode && responseMessageMap[apiError.errorCode]) {
        return responseMessageMap[apiError.errorCode];
      }

      return apiError.attributeErrors.length
        ? apiError.attributeErrors[0].attributeDisplayMessage
        : apiError.developerMessage;
    }

    return "An unknown error occurred. Please try again later.";
  };
}
