import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { CoreUiExtensionsStateService } from "@core-ui-extensions/services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FilterCriteria } from "@notes/models";
import { NotesStateService } from "@notes/state/notes.state.service";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";
import { expansionAnimations } from "@shared/animations/expansion.animations";
import { RepNamePipe } from "@shared/pipes";
import { BrowserUtil } from "@shared/utilities";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { combineLatest } from "rxjs";
import { filter, pairwise } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "cla-note-list-filter",
  templateUrl: "./note-list-filter.component.html",
  styleUrls: ["./note-list-filter.component.scss"],
  animations: [expansionAnimations.panelExpansion],
})
export class NoteListFilterComponent implements OnInit {
  get maxStartDate(): DateTime | null {
    return this.form.controls.endDate && this.form.controls.endDate.value
      ? this.form.controls.endDate.value
      : this.createdDateRangeOptions.max;
  }

  get minEndDate(): DateTime | null {
    return this.form.controls.startDate && this.form.controls.startDate.value
      ? this.form.controls.startDate.value
      : this.createdDateRangeOptions.min;
  }

  public readonly isIE = BrowserUtil.isIE();
  public isClaimProEmbedded = false;
  public isEmbedded = false;
  public isExpanded = true;
  public form: FormGroup;
  public categories: CodeDescriptionPair[] = [];
  public authors: CodeDescriptionPair[] = [];
  public createdDateRangeOptions: {
    min: DateTime | null;
    max: DateTime | null;
  } = { min: null, max: DateTime.now() };

  private repNamePipe = new RepNamePipe();

  constructor(
    private coreUiStateService: CoreUiExtensionsStateService,
    private formBuilder: FormBuilder,
    public notesStateService: NotesStateService,
    private announcer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.notesStateService.isSearchedOrFiltered$
      .pipe(
        pairwise(),
        filter((vals) => vals[0] && !vals[1]),
        untilDestroyed(this)
      )
      .subscribe(() => this.form.reset());

    combineLatest([
      this.notesStateService.notesAndJournals$.pipe(filter((x) => !!x.length)),
      this.coreUiStateService.repSummaries$,
      this.coreUiStateService.repairShopSummaries$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([notes, repSummaries, repairShopSummaries]) => {
        const oldestNote = notes.reduce((a, b) =>
          a.createdDate > b.createdDate ? b : a
        );
        this.createdDateRangeOptions.min = DateTime.fromISO(
          oldestNote.createdDate
        );
        this.categories = this.getUniqueSortedOptions(
          notes.map((note) => note.category)
        );
        this.authors = this.getUniqueSortedOptions(
          notes.map((note) => ({
            code: note.authoredUserId,
            description:
              repairShopSummaries[note.authoredUserId]?.name ??
              this.repNamePipe.transform(
                note.authoredUserId,
                repSummaries[note.authoredUserId],
                true
              ),
          }))
        );
      });

    this.notesStateService.isPopout$
      .pipe(untilDestroyed(this))
      .subscribe((isPopout) => {
        this.isEmbedded = !isPopout;
        if (this.isEmbedded) {
          this.isExpanded = false;
        }
      });
  }

  public resetFilters(): void {
    this.notesStateService.resetFilters();
  }

  public clearSearch(): void {
    this.form.controls.searchTerm.reset();
  }

  public applyFilters(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    this.notesStateService.applyFilters({
      categoryCode: this.form.controls.categoryCode.value,
      authoredRepCode: this.form.controls.authoredRepCode.value,
      createdDateRange: {
        start: this.form.controls.startDate.value,
        end: this.form.controls.endDate.value,
      },
      searchTerm: this.form.controls.searchTerm.value,
    } as FilterCriteria);
  }

  public toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;

    this.announcer.announce(
      `Search and Filters ${this.isExpanded ? "expanded" : "collapsed"}`
    );
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      categoryCode: new FormControl(null),
      authoredRepCode: new FormControl(null),
      startDate: new FormControl<DateTime | null>(null),
      endDate: new FormControl<DateTime | null>(null),
      searchTerm: new FormControl(null),
    });
  }

  private getUniqueSortedOptions(
    dropdownOptions: CodeDescriptionPair[]
  ): CodeDescriptionPair[] {
    return [..._.uniqBy(dropdownOptions, "code")].sort((a, b) =>
      a.description.localeCompare(b.description)
    );
  }
}
