import { Component, Input } from "@angular/core";
import { PolicyHelperFunctions } from "@modules/policy/helpers";
import { AgreementLookup } from "@modules/policy/models/agreement-lookup-response/agreement-lookup";
import { PolicyDetail } from "@modules/policy/models/policy-detail";

@Component({
  selector: "cla-policy-info",
  templateUrl: "./policy-info.component.html",
  styleUrls: ["./policy-info.component.scss"],
})
export class PolicyInfoComponent {
  policyDetail: PolicyDetail | null;
  agreementLookup: AgreementLookup | null;
  customerSinceDate: Date | null;
  edition = "";
  isPIPOptOutCount = false;
  policyFillIndicator = "";

  @Input() set policyDetailInput(policyDetail: PolicyDetail | null) {
    this.policyDetail = policyDetail;
    if (policyDetail) {
      this.edition = this.formatEdition(policyDetail);
      this.isPIPOptOutCount = policyDetail.pipOptOutIndicator;
      this.policyFillIndicator = PolicyHelperFunctions.booleanToYesNo(
        policyDetail.policyFillIndicator
      );
    }
  }

  @Input() set agreementLookupInput(agreementLookup: AgreementLookup | null) {
    this.agreementLookup = agreementLookup;
    if (agreementLookup && agreementLookup.customerList) {
      const customer = agreementLookup.customerList.find(
        (c) => c.relationshipToNamedInsured === "Self"
      );
      if (customer) {
        this.customerSinceDate = customer.tenureDateWithLapse;
      }
    }
  }

  formatEdition(policyDetail: PolicyDetail) {
    let formattedEdition = "";
    if (policyDetail !== null) {
      const editionStr = policyDetail.policyFormDate.toString();
      switch (editionStr.length) {
        case 1:
          formattedEdition = `(00/${PolicyHelperFunctions.pad(editionStr, 2)})`;
          break;
        case 2:
          formattedEdition = `(${editionStr})`;
          break;
        case 3:
          formattedEdition = `(${PolicyHelperFunctions.pad(
            editionStr.substring(0, 1),
            2
          )}/${PolicyHelperFunctions.pad(editionStr.substring(1), 2)})`;
          break;
        case 4:
          formattedEdition = `(${PolicyHelperFunctions.pad(
            editionStr.substring(0, 2),
            2
          )}/${PolicyHelperFunctions.pad(editionStr.substring(2), 2)})`;
          break;
      }
    }
    return formattedEdition;
  }
}
