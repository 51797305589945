import { SelectionChange, SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import {
  IContentDetailsGetResponse,
  IContentSummaryItem,
  IStoreEntity,
} from "@modules/electronic-file-folder/models";
import { TitleRepCodePipe } from "@modules/electronic-file-folder/pipes";
import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";
import { PaginatorService } from "@modules/electronic-file-folder/services/content-summary/paginator/paginator.service";
import { ClaimsContentViewerService } from "@modules/electronic-file-folder/services/core/claims-content-viewer/claims-content-viewer.service";
import { TableRowSelectHandlerComponent } from "@modules/shared/components";
import {
  CUI_DIALOG_WIDTH,
  SideSheetService,
} from "@pgr-cla/core-ui-components";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { map, takeUntil, withLatestFrom } from "rxjs/operators";
import { EFFConstants } from "../../../constants";
import { SimpleDialogComponent } from "../../shared";

const sortHeaderAccessorMap: { [key: string]: string } = {
  "Date and Time Received": "originDateTime",
  "Party/Property": "partyOrPropertyName",
  Category: "contentCategoryDesc",
  Type: "contentTypeDesc",
  "Delivery Method": "deliveryMethodDesc",
  Tags: "tags",
  "Created By": "createdByDisplayName",
  "Number of Items": "numberOfItems",
  "Medical Bill Number": "medicalBillNumber",
  Origin: "originDesc",
  Status: "status",
};

@Component({
  selector: "cla-all-content",
  styleUrls: ["./all-content.component.scss"],
  templateUrl: "all-content.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleRepCodePipe],
  //providers: [TableActionService, TitleRepCodePipe],
})
export class AllContentComponent
  extends TableRowSelectHandlerComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  public actionNames = EFFConstants.clickActionNames;
  public currentPageData: IContentSummaryItem[] = [];
  public displayedColumns: string[] = [];
  public dataSource = new MatTableDataSource<IContentSummaryItem>([]);
  public resultsTotal = 0;
  public isAllSelected$: Observable<boolean>;
  public isSelectAllIndeterminate$: Observable<boolean>;
  public isSelectAllIndeterminate: boolean;
  public previewedItem: IContentSummaryItem;
  public selectedIndex: number;
  public selection = new SelectionModel<string>(true, []);
  public filters: { [key: string]: any };

  private _currentPageDataSubject$: BehaviorSubject<IContentSummaryItem[]> =
    new BehaviorSubject([]);
  public isContentDetailsRequestInFlight$: Observable<boolean>;
  private _isAllSelectedBehaviorSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  private _isSelectAllIndeterminateBehaviorSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private _destroySubject$: Subject<void> = new Subject<void>();

  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public readonly contentSummaryEventService: ContentSummaryEventService,
    public readonly contentSummaryStoreService: ContentSummaryStoreService,
    public readonly contentSummaryViewService: ContentSummaryViewService,
    public readonly paginatorService: PaginatorService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _sideSheetService: SideSheetService,
    private _route: ActivatedRoute,
    public coreUiStateService: CoreUiExtensionsStateService,
    private claimsContentViewerService: ClaimsContentViewerService,
    private dialog: MatDialog
  ) {
    super();
    this.sortingAccessor = this.sortingAccessor.bind(this);
    this.isAllSelected$ = this._isAllSelectedBehaviorSubject$.asObservable();
    this.isSelectAllIndeterminate$ =
      this._isSelectAllIndeterminateBehaviorSubject$.asObservable();

    this.contentSummaryViewService.deselectAllSelectedItems$
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(() => {
        this.selection.clear();
      });

    this.isContentDetailsRequestInFlight$ = this.contentSummaryStoreService
      .getContentDetails()
      .pipe(map((x: IStoreEntity<IContentDetailsGetResponse>) => x.inFlight));
  }

  ngOnInit(): void {
    this.contentSummaryStoreService
      .getFilters()
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((filters) => (this.filters = filters));
  }

  public ngAfterViewInit(): void {
    // Paginator must be set before data is bound or else performance slows. Refer to
    // https://stackoverflow.com/questions/50283659/angular-6-mattable-performance-in-1000-rows
    // for additional information.

    this.paginatorService.matPaginator$.subscribe((paginator: MatPaginator) => {
      this.dataSource.paginator = paginator;
    });
    this.dataSource.sort = this.sort;
    this.setSortingAccessorMap(sortHeaderAccessorMap);
    this.dataSource.sortingDataAccessor = this.sortingAccessor;
    combineLatest([
      this.paginatorService.matPaginator$,
      this.contentSummaryStoreService.getFilteredContentItems(),
    ])
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(
        ([matPaginator, contentItems]: [
          MatPaginator,
          IContentSummaryItem[]
        ]) => {
          if (contentItems) {
            matPaginator.firstPage();
            this.dataSource.data = contentItems;
            this.resultsTotal = contentItems.length;
            this._changeDetectorRef.detectChanges();
          }
        }
      );

    // checking whether all items are selected when a search/filter occurs or when the table page changes
    combineLatest([
      this._currentPageDataSubject$,
      this.paginatorService.matPaginator$,
    ])
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(
        ([currentPageItems]: [IContentSummaryItem[], MatPaginator]) => {
          const isAllSelected = this._isSelectAllSelected(currentPageItems);
          this.isSelectAllIndeterminate =
            this._isSelectAllIndeterminate(currentPageItems);

          this._isAllSelectedBehaviorSubject$.next(isAllSelected);
          this._isSelectAllIndeterminateBehaviorSubject$.next(
            this.isSelectAllIndeterminate
          );
        }
      );

    // checking whether all items are selected when the selection model changes
    this.selection.changed
      .pipe(
        withLatestFrom(this._currentPageDataSubject$),
        takeUntil(this._destroySubject$)
      )
      .subscribe(
        ([, currentPageItems]: [
          SelectionChange<string>,
          IContentSummaryItem[]
        ]) => {
          const isAllSelected = this._isSelectAllSelected(currentPageItems);
          const isSelectAllIndeterminate =
            this._isSelectAllIndeterminate(currentPageItems);

          this._isAllSelectedBehaviorSubject$.next(isAllSelected);
          this._isSelectAllIndeterminateBehaviorSubject$.next(
            isSelectAllIndeterminate
          );
        }
      );

    this.dataSource
      .connect()
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((data: IContentSummaryItem[]) => {
        this.currentPageData = data;
        this._currentPageDataSubject$.next(data);
        this._changeDetectorRef.detectChanges();
      });

    this.contentSummaryStoreService
      .getDisplayedColumns()
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((columns) => {
        this.setDisplayedColumns(columns);
        this._changeDetectorRef.detectChanges();
      });

    this.contentSummaryStoreService
      .getPreviewedItem()
      .pipe(takeUntil(this._destroySubject$))
      .subscribe((contentItem) => {
        this.previewedItem = contentItem as IContentSummaryItem;
        this._changeDetectorRef.detectChanges();
      });

    this.contentSummaryStoreService
      .getRefreshInProgress()
      .pipe(takeUntil(this._destroySubject$))
      .subscribe(() => this.selection.clear());
  }

  ngOnDestroy(): void {
    this._destroySubject$.next();
    this._destroySubject$.complete();
  }

  public handleChange(row: IContentSummaryItem): void {
    this.selection.toggle(row.id);
    this.contentSummaryStoreService.onContentSummaryToggleSelectedItem({
      contentItem: row,
    });
  }

  /**
   * Selects all rows on current page if they are not all selected,
   * otherwise clears selections on current page.
   */
  public masterToggle(): void {
    const isAnySelected = !!this.currentPageData.find(
      (item) => !this.selection.isSelected(item.id)
    );

    if (isAnySelected) {
      const ids = this.currentPageData.map((x) => x.id);
      this.selection.select(...ids);
      this.contentSummaryStoreService.onContentSummaryToggleAllSelectedItemsPaginated(
        {
          contentItemIds: ids,
          selectAllContentItems: true,
        }
      );
    } else {
      const ids = this.currentPageData.map((x) => x.id);
      this.selection.deselect(...ids);
      this.contentSummaryStoreService.onContentSummaryToggleAllSelectedItemsPaginated(
        {
          contentItemIds: ids,
          selectAllContentItems: false,
        }
      );
    }
  }

  public onCheckboxEnter(event: Event): void {
    const composedPath = (event as any).propagationPath();
    const matCheckbox = composedPath.find((x: Node) => {
      return (x as Node).nodeName === "INPUT";
    }) as Node;

    matCheckbox.dispatchEvent(new Event("click"));
  }

  public rowKeyboardHandler(
    contentItem: IContentSummaryItem,
    event: KeyboardEvent
  ): void {
    //this.tableActionService.openPreview(contentItem);
    const composedPath = (event as any).propagationPath();
    const isChildEvent = !!composedPath.find((x: Node) => {
      return (
        x.nodeName === "MAT-CHECKBOX" ||
        x.nodeName === "CLA-EXPANDING-LINK" ||
        x.nodeName === "CUI-POPOVER"
      );
    });

    if (!isChildEvent) {
      if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
      }
      this.openPreview(contentItem);
    }
  }

  public selectRow(index: number): void {
    this.selectedIndex = index;
    this._changeDetectorRef.detectChanges();
  }

  public openPreview(item: IContentSummaryItem): void {
    this.contentSummaryStoreService.onContentSummaryPreviewItem({
      contentItem: item,
    });

    this._router.navigate([{ outlets: { sideSheet: ["preview"] } }], {
      queryParamsHandling: "preserve",
      relativeTo: this._route.parent,
      skipLocationChange: true,
    });

    const sideSheet = this._sideSheetService.get("EFFMain");

    if (sideSheet) {
      sideSheet.open();
    }
  }

  public launchViewer(contentItem: IContentSummaryItem): void {
    const openResult = this.claimsContentViewerService.processOpenViewer([
      contentItem,
    ]);

    if (openResult.error) {
      this.dialog.open(SimpleDialogComponent, {
        data: openResult.error,
        width: CUI_DIALOG_WIDTH.SMALL,
      });
    }

    this.contentSummaryEventService.clickAction(
      EFFConstants.clickActionNames.open
    );
  }

  /** Whether all of the elements on the current page are selected. */
  private _isSelectAllSelected(contentItems: IContentSummaryItem[]): boolean {
    let result = false;

    if (this.selection.selected.length > 0) {
      result = !contentItems.find(
        (item) => !this.selection.isSelected(item.id)
      );
    }

    return result;
  }

  /** Whether there are both selected AND unselected rows on the same page. */
  private _isSelectAllIndeterminate(
    contentItems: IContentSummaryItem[]
  ): boolean {
    let result = false;

    if (this.selection.selected.length > 0) {
      result =
        !!contentItems.find((item) => !this.selection.isSelected(item.id)) &&
        !!contentItems.find((item) => this.selection.isSelected(item.id));
    }

    return result;
  }
}
