import { NgModule } from "@angular/core";
import { NoteCategoryPipe } from "@notes/modules/note-shared/pipes";

@NgModule({
  imports: [],
  providers: [NoteCategoryPipe],
  declarations: [NoteCategoryPipe],
  exports: [NoteCategoryPipe],
})
export class NoteSharedModule {}
