import { createSelector } from "@ngrx/store";

import { selectPolicyState } from "..";

export const selectSpinner = createSelector(
  selectPolicyState,
  (state) => state.spinner
);

export const selectIsSpinnerActive = createSelector(
  selectSpinner,
  (state) => state.pageActive > 0
);
