import { createAction, props } from "@ngrx/store";

import { AdverseParty } from "@subrogation-summary/models";

export const loadAdverseParties = createAction(
  "[adverse-parties] load adverse parties"
);

export const loadAdversePartiesSucceeded = createAction(
  "[adverse-parties] successfully loaded adverse parties",
  props<{ adverseParties: AdverseParty[] }>()
);

export const loadAdversePartiesFailed = createAction(
  "[adverse-parties] failed to load adverse parties"
);
