/* eslint-disable functional/immutable-data */
import { DatePipe } from "@angular/common";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";
import { pickBy } from "lodash";
import { DateTime } from "luxon";
import { EFFConstants, SearchTypeValues } from "../../../constants";
import { compare, deepCopy } from "../../../helpers";
import {
  IContentSetVersion,
  IContentSummaryItem,
  IContentSummarySearchResponse,
  IContentSummaryState,
  IContentTag,
} from "../../../models";

const searchableProperties = [
  "originDateTimeFormatted",
  "partyOrPropertyName",
  "contentCategoryDesc",
  "contentTypeDesc",
  "tags",
  "createdByDisplayName",
  "deliveryMethodDesc",
  "numberOfItems",
  "medicalBillNumber",
  "originDesc",
  "status",
  "versions",
];

const filterableProperties = [
  "originDateTime",
  "partyOrPropertyName",
  "contentCategoryDesc",
  "contentTypeDesc",
  "createdByDisplayName",
  "deliveryMethodDesc",
  "tags",
  "numberOfItems",
  "medicalBillNumber",
  "originDesc",
  "status",
  "versions",
];
export class ContentSummaryMappers {
  public static buildFilterOption(
    filterText: string,
    columnId: string,
    count: number = 1
  ): { [key: string]: any } {
    if (columnId !== "numberOfItems") {
      const filterDisplayText = filterText || "(Blank)";
      return { filterDisplayText, filterText, count };
    }

    return { count, filterText, filterDisplayText: filterText };
  }

  public static filterItems(state: IContentSummaryState): any {
    const filters = deepCopy(state.filters);
    let contentItems = [] as IContentSummaryItem[];
    if (state.contentDetails.response) {
      contentItems = deepCopy(state.contentDetails.response.contentItems);
    }
    const newFilters = this.getFilters({ ...state, filters }, contentItems);
    const filteredContentItems = this.getFilteredContentItems({
      ...state,
      filters,
    });

    return {
      filteredContentItems,
      filters: newFilters,
    };
  }

  public static formatContentSummaryResponse(
    response: IContentSummarySearchResponse,
    repSummaries: Record<string, ClaimRepContactCardInfoRedacted> | null
  ): IContentSummaryItem[] {
    // formatting date here so that formatted dates are searchable
    const datePipe = new DatePipe("en-US");
    const payload = response.contentItems.map((contentItem, index: number) => {
      // Tags are sorted by create date
      contentItem.tags.sort((currentTag: IContentTag, nextTag: IContentTag) => {
        if (
          currentTag.tagCreatedUTCDateTime === undefined ||
          nextTag.tagCreatedUTCDateTime === undefined
        ) {
          return 0;
        }
        return (
          +new Date(currentTag.tagCreatedUTCDateTime) -
          +new Date(nextTag.tagCreatedUTCDateTime)
        );
      });
      const { createdByUserId } = contentItem;
      const createdByName =
        repSummaries && repSummaries[createdByUserId]?.shortName;
      if (!contentItem.versions) {
        contentItem.versions = [];
      }
      // Add a pseudo version for the initial version if there is not an actual version 0.
      if (contentItem.versions.every((v) => v.versionNbr !== 0)) {
        contentItem.versions.push({
          contentItemId: contentItem.id,
          versionNbr: 0,
          createdUTCDateTime: contentItem.originDateTime,
          createdByUserId: contentItem.createdByUserId,
          createdByRepName: contentItem.createdByRepName,
          createdByEmail: contentItem.createdByEmail,
          createdByExternalPhoneNumber:
            contentItem.createdByExternalPhoneNumber,
          createdByTitle: contentItem.createdByTitle,
        });
      }
      contentItem.versions.sort(
        (
          currentVersion: IContentSetVersion,
          nextVersion: IContentSetVersion
        ) => {
          return currentVersion.versionNbr - nextVersion.versionNbr;
        }
      );
      contentItem.versions.forEach((v) => {
        v.createdByDisplayName = this.getDisplayName(
          v.createdByUserId,
          repSummaries && repSummaries[v.createdByUserId]?.shortName
        );
        v.createdDateTimeFormatted =
          v.versionNbr === 0
            ? this.getDateTimeFormatted(v.createdUTCDateTime, datePipe)
            : this.getUTCDateTimeFormattedToTimeZone(
                v.createdUTCDateTime,
                datePipe,
                EFFConstants.display.timeZone
              );
      });
      return {
        ...contentItem,
        partyOrPropertyName: contentItem.partyOrPropertyName || "",
        createdByDisplayName: this.getDisplayName(
          createdByUserId,
          createdByName
        ),
        originDateTimeFormatted: this.getDateTimeFormatted(
          contentItem.originDateTime,
          datePipe
        ),
        isSelected: false,
        order: index,
      } as IContentSummaryItem;
    });

    // natural order is not always preserved by IE11, so have to manually compare if values are equal
    // https://stackoverflow.com/questions/36018350/differences-in-js-array-sort-ie-11-vs-chrome/36018679#36018679
    payload.sort(
      (currentItem: IContentSummaryItem, nextItem: IContentSummaryItem) => {
        return (
          +new Date(nextItem.originDateTime) -
            +new Date(currentItem.originDateTime) ||
          (currentItem.order || 0) - (nextItem.order || 0)
        );
      }
    );

    return payload;
  }

  public static getDateTimeFormatted(
    dateToFormat: Date | string,
    datePipe: DatePipe
  ): string | null {
    return datePipe.transform(dateToFormat, "M/d/yyyy, h:mm a");
  }

  public static getDisplayName(
    userId: string | null,
    repName: string | null
  ): string {
    return `${userId || ""}${userId && repName ? " - " : ""}${repName || ""}`;
  }

  public static getFilteredContentItems({
    searchTerm,
    searchType,
    searchResults,
    contentDetails,
    filters,
  }: IContentSummaryState): IContentSummaryItem[] | undefined {
    const propertySearchTerms = searchTerm.trim().split(/\s+/);
    const contentSearchResults = new Set(searchResults);

    let contentItems = [] as IContentSummaryItem[];
    if (contentDetails.response) {
      contentItems = deepCopy(contentDetails.response.contentItems);
    }
    return (deepCopy(contentItems) || []).filter(
      (item: IContentSummaryItem) => {
        let allPropertySearchTermsFound = true;
        let allFiltersMatch = true;
        let contentSearchMatch = true;

        if (
          searchType === SearchTypeValues.Content &&
          !contentSearchResults.has(item.id)
        ) {
          contentSearchMatch = false;
        }

        if (searchType === SearchTypeValues.Grid) {
          propertySearchTerms.forEach((term) => {
            let searchTermFound = false;
            const formattedTerm = term.toLowerCase();

            searchableProperties.forEach((columnKey) => {
              if (item[columnKey]) {
                let cellValues: any[] = [
                  item[columnKey].toString().toLowerCase(),
                ];

                if (columnKey === "tags") {
                  cellValues = item[columnKey].map((i) =>
                    i.tagName.toLowerCase()
                  );
                } else if (columnKey === "versions") {
                  cellValues = [
                    (!!item[columnKey].map((x) => x.versionNbr)[0] &&
                      item[columnKey].map((x) => x.versionNbr)[0].toString()) ||
                      "",
                  ];
                }

                if (cellValues.some((val) => val.includes(formattedTerm))) {
                  searchTermFound = true;
                }
              }
            });

            if (!searchTermFound) {
              allPropertySearchTermsFound = false;
            }
          });
        }

        filterableProperties.forEach((columnKey) => {
          if (filters[columnKey]) {
            const { appliedFilters, minDate, maxDate } = filters[columnKey];
            let columnContents: any[] = [item[columnKey]];
            let newAppliedFilters = appliedFilters;

            if (columnKey === "tags") {
              columnContents = item[columnKey].map((i) =>
                i.tagName.toLowerCase()
              );
              newAppliedFilters = appliedFilters.map((x: string) =>
                x.toLowerCase()
              );
            } else if (columnKey === "versions") {
              columnContents = [
                item[columnKey].map((x) => x.versionNbr)[
                  item[columnKey].length - 1
                ] || "",
              ];
            }

            if (
              (newAppliedFilters &&
                newAppliedFilters.length &&
                columnContents.every(
                  (cellItem) => !newAppliedFilters.includes(cellItem)
                )) ||
              (minDate &&
                DateTime.fromISO(item[columnKey]).toUTC().endOf("day") <
                  DateTime.fromISO(minDate)) ||
              (maxDate &&
                DateTime.fromISO(item[columnKey]).toUTC().startOf("day") >
                  DateTime.fromISO(maxDate))
            ) {
              allFiltersMatch = false;
            }
          }
        });

        return (
          allPropertySearchTermsFound && allFiltersMatch && contentSearchMatch
        );
      }
    );
  }

  public static getFilterOptions(
    contentItems: IContentSummaryItem[],
    columnId: string,
    appliedFilters: any[]
  ): object[] {
    const filterOptions: any = {};
    contentItems.forEach((contentItem) => {
      let filterableContent = [contentItem[columnId]];

      if (columnId === "tags") {
        filterableContent = contentItem[columnId].map((i) => i.tagName);
      } else if (columnId === "versions") {
        filterableContent = [
          contentItem[columnId].map((x) => x.versionNbr)[
            contentItem[columnId].length - 1
          ] || "",
        ];
      }

      filterableContent.forEach((filterText) => {
        if (filterOptions[filterText]) {
          filterOptions[filterText].count++;
        } else {
          filterOptions[filterText] = this.buildFilterOption(
            filterText,
            columnId
          );
        }
      });
    });
    appliedFilters.forEach((filter) => {
      if (!filterOptions[filter]) {
        filterOptions[filter] = this.buildFilterOption(filter, columnId, 0);
      }
    });
    return Object.keys(filterOptions)
      .map((key: string) => filterOptions[key])
      .sort((a: any, b: any) => {
        const result = compare(
          typeof a.filterText === "string"
            ? a.filterText.toUpperCase()
            : a.filterText,
          typeof b.filterText === "string"
            ? b.filterText.toUpperCase()
            : b.filterText,
          true
        );
        return result;
      });
  }

  public static getFilters(
    {
      searchTerm,
      searchType,
      searchResults,
      columns,
      filters,
    }: IContentSummaryState,
    payload?: IContentSummaryItem[]
  ): any {
    const newFilters = deepCopy(filters);
    if (payload) {
      columns.forEach((column) => {
        if (!column.isFilterable) {
          return;
        }
        const scopedFilters = pickBy(
          filters,
          (value: any, key: string) => key !== column.id
        );

        const scopedContentItems =
          this.getFilteredContentItems({
            searchTerm,
            searchType,
            searchResults,
            contentDetails: {
              response: {
                contentItems: payload as IContentSummaryItem[],
              },
              error: null,
              inFlight: false,
              meta: {
                responseCode: null,
                method: null,
              },
            },
            filters: scopedFilters,
          } as IContentSummaryState) || [];
        if (newFilters[column.id].filterOptions) {
          newFilters[column.id].scopedTotal = scopedContentItems.length;
          newFilters[column.id].filterOptions = this.getFilterOptions(
            scopedContentItems,
            column.id,
            filters[column.id].appliedFilters
          );
        }
      });
    }
    return newFilters;
  }

  public static getUTCDateTimeFormattedToTimeZone(
    dateToFormat: Date | string,
    datePipe: DatePipe,
    timeZone: string
  ): string | null {
    if (!dateToFormat) {
      return "";
    }
    const timeZoneDate =
      typeof dateToFormat === "string"
        ? DateTime.fromISO(dateToFormat).setZone(timeZone)
        : DateTime.fromJSDate(dateToFormat).setZone(timeZone);
    return datePipe.transform(
      timeZoneDate.toLocaleString(),
      "M/d/yyyy, h:mm a",
      timeZoneDate.locale ?? undefined
    );
  }
}
