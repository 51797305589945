import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { IChildWindow, IChildWindowLaunchConfig } from "../../../models";

import { AuthenticationConstants } from "@modules/core/constants";

@Injectable()
export class WindowManagerService {
  private get accessToken(): string {
    return this.cookieService.get(AuthenticationConstants.tokenKey);
  }

  public set childrenWindows(windows: IChildWindow[]) {
    this._childrenWindows = windows;
  }

  public get childrenWindows(): IChildWindow[] {
    return this._childrenWindows.filter((w: IChildWindow) => {
      // eslint-disable-next-line functional/no-try-statements
      try {
        return !w.window.closed;
      } catch (e) {
        return false;
      }
    });
  }
  get parentWindow(): any {
    return window.opener;
  }

  private _childrenWindows: IChildWindow[] = [];

  constructor(private cookieService: CookieService) {
    this.getWindow().addEventListener("beforeunload", () => {
      this.childrenWindows.forEach((child) => {
        child.window.close();
      });
    });
  }

  public getWindow(): Window {
    return window;
  }

  public openChild(config: IChildWindowLaunchConfig): void {
    let urlQuerySeparator = "?";
    if (config.url.includes("?")) {
      urlQuerySeparator = "&";
    }

    let url = config.url;
    let options: string | undefined;

    if (config.width && config.height) {
      url += `${urlQuerySeparator}width=${config.width}&height=${config.height}`;
      options += `, width=${config.width}`;
      options += `, height=${config.height}`;
    }

    const finalUrl = `${url}#access_token=${this.accessToken}`;
    const newWindow = window.open(finalUrl, "_blank", options);

    if (config.claData) {
      // eslint-disable-next-line functional/immutable-data
      (window as any).claData = config.claData;
    }

    if (newWindow) {
      this.childrenWindows = [
        ...this.childrenWindows,
        {
          type: config.type,
          window: newWindow,
        },
      ];
    }
  }
}
