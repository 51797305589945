import { Component, Input } from "@angular/core";
import { CodeTable, CodeTableValue } from "@claimssummaryshared/models";
import { CodeTableStateService } from "@modules/core/state";
import { map, Observable } from "rxjs";

@Component({
  selector: "cla-surcharge-indicator",
  templateUrl: "./surcharge-indicator.component.html",
  styleUrls: ["./surcharge-indicator.component.scss"],
})
export class SurchargeIndicatorComponent {
  constructor(private codeTableStateService: CodeTableStateService) {}

  public surchargeDescription$: Observable<string>;

  @Input() public set surchargeCode(surchargeCode: string) {
    this.surchargeDescription$ =
      this.codeTableStateService.codeTableForSurcharge$.pipe(
        map((table) => this.mapCodeDescription(surchargeCode, table))
      );
  }

  public mapCodeDescription(
    surchargeCode: string,
    surchargeCodeTable: CodeTable<CodeTableValue> | undefined
  ): string {
    if (!surchargeCode) return "Pending";
    const code = surchargeCodeTable?.values.find(
      (v) => v.code === surchargeCode
    );
    return code?.code + " - " + code?.description;
  }
}
