import { Injectable } from "@angular/core";
import { AppPathService } from "@modules/core";
import { WindowManagementService, WindowOptions } from "@pgr-cla/cla-window";
import { lastValueFrom, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class IpcWindowService {
  constructor(
    private _windowManagementService: WindowManagementService,
    private appPathService: AppPathService
  ) {}

  public async open(partySequence: number, featureSequence: number) {
    const xmlPartyId = `X0000070000000${partySequence
      .toString()
      .padStart(3, "0")}`;

    const path = await lastValueFrom(
      this.appPathService.ipcInjuryPath$.pipe(take(1))
    );

    const pathWithPartyAndFeatureSequence = path
      .replace("{1}", xmlPartyId)
      .replace("{2}", `${featureSequence}`);

    this._windowManagementService.openChildWindow(
      `ipcedit-${partySequence}-${featureSequence}`,
      pathWithPartyAndFeatureSequence,
      {
        height: 499,
        width: 460,
        top: 156,
        resizable: false,
      } as WindowOptions
    );
  }
}
