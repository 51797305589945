import { createAction, props } from "@ngrx/store";

import { OutOfPocket } from "../../models";

export const loadOutOfPockets = createAction(
  "[out-of-pockets] load out of pockets",
  props<{ claimNumber: string }>()
);

export const loadOutOfPocketsSucceeded = createAction(
  "[out-of-pockets] successfully loaded out of pockets",
  props<{ outOfPockets: OutOfPocket[] }>()
);

export const loadOutOfPocketsFailed = createAction(
  "[out-of-pockets] failed to load out of pockets",
  props<{ errorMsg: string }>()
);
