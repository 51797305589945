import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@modules/shared";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { DialogService, SideSheetService } from "@pgr-cla/core-ui-components";

import {
  PaymentsComponent,
  PaymentsFilterComponent,
  PaymentsTableComponent,
} from "./components";
import { PaymentsEffects } from "./effects";
import { PaymentsRoutingModule } from "./payments.routing.module";
import * as paymentsReducers from "./reducers";
import { PaymentsApiService, PaymentsStateService } from "./services";

@NgModule({
  declarations: [
    PaymentsComponent,
    PaymentsFilterComponent,
    PaymentsTableComponent,
  ],
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    SharedModule,
    StoreModule.forFeature("payments", paymentsReducers.reducer),
    EffectsModule.forFeature([PaymentsEffects]),
  ],
  providers: [
    PaymentsApiService,
    PaymentsStateService,
    DialogService,
    SideSheetService,
  ],
})
export class PaymentsModule {}
