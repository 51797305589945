import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const diaryRoutes: Routes = [
  // {
  //   path: '',
  //   component: DiaryComponent,
  //   data: { title: 'Claim Diaries' },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(diaryRoutes)],
  exports: [RouterModule],
})
export class DiaryRoutingModule {}
