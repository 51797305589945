import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";

import {
  IContentDetailsGetRequest,
  IContentDetailsGetResponse,
  IContentSummaryConfigInfo,
  ISearchContentDocumentInfo,
  ISearchContentGetRequest,
} from "../../../models";

import { AdapterApiService } from "@modules/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";

import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { buildQueryParams } from "../../../helpers";
import { ContentManagementApiService } from "../../core/content-management-api/content-management-api.service";

@Injectable()
export class ContentSummaryService {
  constructor(
    private contentManagementApiService: ContentManagementApiService,
    private api: AdapterApiService,
    private config: ConfigurationService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  public getContentDetails(
    request: IContentDetailsGetRequest
  ): Observable<IContentDetailsGetResponse> {
    const formattedClaimNumber =
      this.claimNumberPipe.transform(request.claimNumber, "full") || "";

    let paramString = "";
    const queryStringParams = {
      partyId: request.partyId,
      propertyId: request.propertyId,
    };

    if (queryStringParams) {
      paramString = buildQueryParams(queryStringParams);
    }

    return this.get<IContentDetailsGetResponse>(
      `/lossviewerapi/v1/claims/${formattedClaimNumber}/contentDetails${paramString}`
    );
  }

  public getSearchContent(
    request: ISearchContentGetRequest
  ): Observable<ISearchContentDocumentInfo[]> {
    const formattedClaimNumber =
      this.claimNumberPipe.transform(request.claimNumber, "full") || "";

    const queryStringParams = { searchText: request.searchText };
    return this.contentManagementApiService.get<ISearchContentDocumentInfo[]>(
      `/lossviewerapi/v1/claims/${formattedClaimNumber}/searchContent`,
      queryStringParams
    );
  }

  public get<T>(path: string): Observable<T> {
    return this.config.configuration$.pipe(
      first(
        (config: IContentSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.api.get<T>(config.contentSummaryAdapterApiRouteUrl, path);
      })
    );
  }
}
