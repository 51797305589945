/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable rxjs/prefer-observer */
/* eslint-disable functional/immutable-data */
/* eslint-disable rxjs/finnish */
/* eslint-disable rxjs/suffix-subjects */
import { Injectable } from "@angular/core";
import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  RedirectRequestHandler,
  Requestor,
  RevokeTokenRequest,
  StringMap,
  TokenRequest,
  TokenResponse,
} from "@openid/appauth";

import {
  AuthenticationConstants,
  LocalStorageConstants,
} from "@core/constants";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { WindowService } from "@pgr-cla/cla-window";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  from,
} from "rxjs";
import { filter, first, map, take } from "rxjs/operators";
import { fixUrl, useLocalHost } from "./auth.utilities";

@Injectable()
export class PingTokenService {
  private notifier = new AuthorizationNotifier();
  private authorizationHandler = new RedirectRequestHandler();

  private readonly tokenSubject$ = new ReplaySubject<string | null>(1);
  public readonly token$ = this.tokenSubject$.asObservable();
  private token: string | null = null;
  private readonly tokenErrorSubject$ = new ReplaySubject<string | null>(1);
  private pingServiceConfig: AuthorizationServiceConfiguration;

  private _tokenResponses: BehaviorSubject<TokenResponse | null>;
  private _serviceConfigs: BehaviorSubject<AuthorizationServiceConfiguration | null>;

  private _tokenHandler: BaseTokenRequestHandler;

  private configuration: ClaimsSummaryConfigInfo;

  constructor(
    private configurationService: ConfigurationService,
    private requestor: Requestor,
    private readonly windowService: WindowService
  ) {
    let tokenResponse: TokenResponse | null = null;
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    this._serviceConfigs = new BehaviorSubject(this.pingServiceConfig);
    this._tokenResponses = new BehaviorSubject(tokenResponse);

    this.configurationService.configuration$
      .pipe(
        first((x) => !!x),
        map((x: ClaimsSummaryConfigInfo) => x)
      )
      .subscribe((config) => {
        this.configuration = config;

        if (
          this.configuration.pingIssuerUri ===
          window.localStorage.getItem(LocalStorageConstants.LS_ISSUER_URI)
        ) {
          const serviceConfigJSON = JSON.parse(
            useLocalHost(
              window.localStorage.getItem(
                LocalStorageConstants.LS_OPENID_CONFIG
              )!
            )
          );
          this.pingServiceConfig =
            serviceConfigJSON &&
            new AuthorizationServiceConfiguration(serviceConfigJSON);

          const tokenResponseJSON = JSON.parse(
            window.localStorage.getItem(
              LocalStorageConstants.LS_TOKEN_RESPONSE
            )!
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          tokenResponse =
            tokenResponseJSON && new TokenResponse(tokenResponseJSON);
          if (tokenResponse) {
            this.tokenSubject$.next(tokenResponse.accessToken);
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          // userInfo = JSON.parse(window.localStorage.getItem(LocalStorageConstants.LS_USER_INFO)!);
        } else {
          // store the issuer, and eliminate other state
          window.localStorage.setItem(
            LocalStorageConstants.LS_ISSUER_URI,
            this.configuration.pingIssuerUri
          );
          window.localStorage.removeItem(
            LocalStorageConstants.LS_OPENID_CONFIG
          );
          window.localStorage.removeItem(
            LocalStorageConstants.LS_TOKEN_RESPONSE
          );
        }

        // start fetching metadata
        if (this.pingServiceConfig == null) {
          this.fetchServiceConfiguration(this.configuration.pingIssuerUri);
        }
      });

    this._serviceConfigs.subscribe(
      (config: AuthorizationServiceConfiguration | null) => {
        if (config) {
          window.localStorage.setItem(
            LocalStorageConstants.LS_OPENID_CONFIG,
            useLocalHost(JSON.stringify(config.toJson()))
          );
          this.pingServiceConfig = config;
        } else {
          window.localStorage.removeItem(
            LocalStorageConstants.LS_OPENID_CONFIG
          );
        }
      }
    );

    this._tokenResponses.subscribe((token: TokenResponse | null) => {
      if (token) {
        window.localStorage.setItem(
          LocalStorageConstants.LS_TOKEN_RESPONSE,
          JSON.stringify(token?.toJson())
        );
        this.tokenSubject$.next(token.accessToken);
      } else {
        window.localStorage.removeItem(LocalStorageConstants.LS_TOKEN_RESPONSE);
      }
    });

    // if the service config is cleared, invalidate any TokenResponses
    combineLatest([this._serviceConfigs, this._tokenResponses]).subscribe(
      ([openIdConfig, token]: [
        AuthorizationServiceConfiguration | null,
        TokenResponse | null
      ]) => {
        if (openIdConfig == null) {
          if (token != null) {
            this._tokenResponses.next(null);
          }
          return;
        }
      }
    );

    this._tokenHandler = new BaseTokenRequestHandler(this.requestor);

    this.token$.subscribe((token) => {
      this.token = token;
    });
  }

  get remoteTokenError$(): Observable<string | null> {
    return this.tokenErrorSubject$.asObservable();
  }

  clearToken(): void {
    this.tokenSubject$.next(null);
    this.clearPingTokenSubjectError();
  }

  clearPingTokenSubjectError(): void {
    this.tokenErrorSubject$.next(null);
  }

  setMessageTokenSubjectError(message: string): void {
    this.tokenErrorSubject$.next(message);
  }

  async signOut(): Promise<void> {
    // eslint-disable-next-line functional/no-try-statements
    try {
      await this.makeRevokeTokenRequest(
        this.pingServiceConfig,
        this.token ?? ""
      );
    } catch (e) {
      // catching error that is likely caused by missing token in service on refresh
    }
    this.clearToken();
  }

  requestToken(): void {
    this.signIn();
  }

  getRefreshToken = (
    authenticationTimeout: number,
    req: any,
    res: any
  ): Observable<string | null> =>
    this.RefreshToken(authenticationTimeout, req, res);

  findCode(url: Location): string | null {
    const tokenRegEx = new RegExp(
      `#${AuthenticationConstants.codeKey}=([^&]+)(&|$)`
    );
    const matches = url.hash.match(tokenRegEx);
    if (matches === null || matches.length < 2) {
      return null;
    }
    return encodeURIComponent(decodeURIComponent(matches[1]));
  }

  public authorize = (): void => this.signIn();

  private signIn(): void {
    const extras = {
      idp: this.configuration.pingIdpUri,
    };
    this._serviceConfigs
      .pipe(filter((value: any) => value != null))
      .pipe(take(1))
      .subscribe((configuration: AuthorizationServiceConfiguration) => {
        const scope = "openid";

        // create a request
        const request = new AuthorizationRequest({
          client_id: this.configuration.pingClientId,
          redirect_uri: this.getRedirectUrl(),
          scope: scope,
          response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
          state: undefined,
          extras: extras,
        });
        this.authorizationHandler.performAuthorizationRequest(
          configuration,
          request
        );
      });
  }

  public completeAuthorizationRequest(): Promise<TokenResponse> {
    return new Promise((resolve, reject) => {
      this._serviceConfigs
        .pipe(filter((value: any) => value != null))
        .pipe(take(1))
        .subscribe((configuration: AuthorizationServiceConfiguration) => {
          this.notifier.setAuthorizationListener((request, response, error) => {
            if (response && response.code) {
              // use the code to make the token request.
              const extras: StringMap = {};
              if (this.configuration.pingClientSecret) {
                extras["client_secret"] = this.configuration.pingClientSecret;
              }
              if (request.internal) {
                extras["code_verifier"] = request.internal["code_verifier"];
              }
              const tokenRequest = new TokenRequest({
                client_id: this.configuration.pingClientId,
                redirect_uri: this.getRedirectUrl(),
                grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
                code: response.code,
                extras: extras,
              });
              this._tokenHandler
                .performTokenRequest(configuration, tokenRequest)
                .then((tokenResponse: TokenResponse) => {
                  this._tokenResponses.next(tokenResponse);
                  resolve(tokenResponse);
                });
            } else {
              reject(error);
            }
          });
          this.authorizationHandler.completeAuthorizationRequestIfPossible();
        }, reject);
    });
  }

  private makeRevokeTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    accessToken: string
  ) {
    const request = new RevokeTokenRequest({
      token: accessToken,
      token_type_hint: "access_token",
      client_id: this.configuration.pingClientId,
      client_secret: this.configuration.pingClientSecret,
    });
    return this._tokenHandler
      .performRevokeTokenRequest(configuration, request)
      .then((response) => {
        return response;
      });
  }

  RefreshToken = (
    timeout: number,
    request: any,
    response: any
  ): Observable<string> => {
    return from(
      this.makeRefreshTokenRequest(request, response)
        .then((value: TokenResponse) => {
          if (value.accessToken) {
            return value.accessToken;
          }
          // if (value.idToken) {
          //   return value.idToken;
          // }
          return "";
        })
        .catch(() => {
          return "";
        })
    );
  };

  private async makeRefreshTokenRequest(
    request: any,
    response: any
  ): Promise<TokenResponse | null> {
    if (this.pingServiceConfig) {
      let extras: any = undefined;

      if (request && request.internal) {
        extras = {};
        extras["code_verifier"] = request.internal["code_verifier"];
      }

      if (this.configuration?.pingClientSecret) {
        extras = extras || {};
        extras["client_secret"] = this.configuration.pingClientSecret;
      }

      const tokenRequest = new TokenRequest({
        client_id: this.configuration.pingClientId,
        redirect_uri: this.getRedirectUrl(),
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code: undefined,
        refresh_token: undefined,
        extras: extras,
      });
      return await this._tokenHandler
        .performTokenRequest(this.pingServiceConfig, tokenRequest)
        .then((response) => {
          return response;
        });
    }
    return null;
  }

  private readonly extractAuthCode = (event: Event): string | null => {
    // code is returned in the url of the redirect from Ping along with the state
    // https://[hostname]/auth/callback#code=OzAXnIBeHBPNSCvbmamIFinf7AfiScwQV6kAAAAD&state=4rt8RyP19x
    const window = event.target as Window;
    return this.isHashPresentOnContentWindow(window)
      ? // if the url has hash (#), return the value of code
        this.findTokenInHash(window.document.location)
      : null;
  };

  private findTokenInHash(url: Location): string | null {
    const tokenRegEx = new RegExp(
      `#${AuthenticationConstants.codeKey}=([^&]+)(&|$)`
    );
    const matches = url.hash.match(tokenRegEx);
    if (matches === null || matches.length < 2) {
      return null;
    }
    return encodeURIComponent(decodeURIComponent(matches[1]));
  }

  private isHashPresentOnContentWindow(
    window: Window | null
  ): window is Window {
    // check location.hash exists in order to retrieve the token.
    return window !== null && window.location.hash !== undefined;
  }

  makeAccessTokenRequest(refreshToken: any): Promise<TokenResponse> {
    let extras: any = undefined;

    if (this.configuration?.pingClientSecret) {
      extras = extras || {};
      extras["client_secret"] = this.configuration.pingClientSecret;
    }

    const request = new TokenRequest({
      client_id: this.configuration.pingClientId,
      redirect_uri: this.getRedirectUrl(),
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: undefined,
      refresh_token: refreshToken,
      extras: extras,
    });

    return this._tokenHandler
      .performTokenRequest(this.pingServiceConfig, request)
      .then((response) => {
        return response;
      });
  }

  private getRedirectUrl(): string {
    return fixUrl(
      `${window?.location?.origin}/${AuthenticationConstants.redirectRoute}`
    );
  }

  private fetchServiceConfiguration(
    issuer_uri: string
  ): Promise<AuthorizationServiceConfiguration | null> {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      issuer_uri,
      this.requestor
    )
      .then((config) => {
        this._serviceConfigs.next(config);
        return config;
      })
      .catch(() => {
        return null;
      });
  }
}
