import { Injectable } from "@angular/core";
import { ClaimDetailsStateService } from "@core/state";
import { Store } from "@ngrx/store";
import {
  FilterCriteria,
  Journal,
  Note,
  NotesState,
  SortDirection,
} from "@notes/models";
import { NotesActions } from "@notes/state/notes.actions";
import {
  selectAllowedCategoryCodes,
  selectCategories,
  selectEmptyState,
  selectFilterCriteria,
  selectFilteredNotes,
  selectIsErroredJournals,
  selectIsLoading,
  selectIsPopout,
  selectIsSearchedOrFiltered,
  selectIsToDoNotesTabSelected,
  selectJournals,
  selectNote,
  selectNoteId,
  selectNoteListSelectedTabIndex,
  selectNotes,
  selectNotesAndJournals,
  selectShouldCloseNoteWindow,
  selectSortDirection,
  selectToDoNotes,
} from "@notes/state/notes.selectors";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";
import { EmptyState } from "@shared/models";
import { combineLatest, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable()
export class NotesStateService {
  public categories$: Observable<CodeDescriptionPair[]> =
    this.store$.select(selectCategories);
  public allowedCategoryCodes$: Observable<string> = this.store$.select(
    selectAllowedCategoryCodes
  );
  public noteId$: Observable<string> = this.store$.select(selectNoteId);
  public note$: Observable<Note | null> = this.store$.select(selectNote);
  public notes$: Observable<Note[]> = this.store$.select(selectNotes);
  public journals$: Observable<Journal[]> = this.store$.select(selectJournals);
  public filteredNotes$: Observable<Note[]> =
    this.store$.select(selectFilteredNotes);
  public toDoNotes$: Observable<Note[]> = this.store$.select(selectToDoNotes);
  public notesAndJournals$: Observable<Array<Note | Journal>> =
    this.store$.select(selectNotesAndJournals);
  public filterCriteria$: Observable<FilterCriteria> =
    this.store$.select(selectFilterCriteria);
  public sortDirection$: Observable<SortDirection> =
    this.store$.select(selectSortDirection);
  public noteListSelectedTabIndex$: Observable<number> = this.store$.select(
    selectNoteListSelectedTabIndex
  );
  public isToDoNotesTabSelected$: Observable<boolean> = this.store$.select(
    selectIsToDoNotesTabSelected
  );
  public emptyState$: Observable<EmptyState | null> =
    this.store$.select(selectEmptyState);
  public isLoading$: Observable<boolean> = this.store$.select(selectIsLoading);
  public isSearchedOrFiltered$: Observable<boolean> = this.store$.select(
    selectIsSearchedOrFiltered
  );
  public shouldCloseNoteWindow$: Observable<boolean> = this.store$.select(
    selectShouldCloseNoteWindow
  );
  public isErroredJournals$: Observable<boolean> = this.store$.select(
    selectIsErroredJournals
  );

  public isPopout$: Observable<boolean> = this.store$.select(selectIsPopout);

  public isJournalsEnabled$: Observable<boolean> = combineLatest([
    this.claimDetailsStateService.isClaimStationEmbedded$,
    this.claimDetailsStateService.isInitialized$.pipe(filter((x) => !!x)),
  ]).pipe(map(([isClaimStationEmbedded]) => !isClaimStationEmbedded));

  constructor(
    private store$: Store<NotesState>,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {}

  public getCategories(): void {
    this.store$.dispatch(NotesActions.onGetCategoriesRequest());
  }

  public getNotes(): void {
    this.store$.dispatch(NotesActions.onGetNotesRequest());
  }

  public getNote(noteId: string): void {
    this.store$.dispatch(NotesActions.onGetNoteRequest({ noteId }));
  }

  public getJournals(): void {
    this.store$.dispatch(NotesActions.onGetJournalsRequest());
  }

  public applyFilters(filterCriteria: FilterCriteria): void {
    this.store$.dispatch(NotesActions.onSetFilterCriteria({ filterCriteria }));
  }
  public resetFilters(): void {
    this.store$.dispatch(NotesActions.onSetFilterCriteria({}));
  }

  public toggleSortDirection(): void {
    this.store$.dispatch(NotesActions.onToggleSortDirectionRequest());
  }

  public setNoteId(noteId: string): void {
    this.store$.dispatch(NotesActions.onSetNoteId({ noteId }));
  }

  public setIsPopout(): void {
    this.store$.dispatch(NotesActions.onSetIsPopout());
  }

  public setNoteListSelectedTabIndex(selectedTabIndex: number): void {
    this.store$.dispatch(
      NotesActions.onSetNoteListSelectedTabIndex({ selectedTabIndex })
    );
  }

  public setAllowedCategoryCodes(allowedCategoryCodes: string): void {
    this.store$.dispatch(
      NotesActions.onSetAllowedCategoryCodes({ allowedCategoryCodes })
    );
  }

  public handleExternalWindowAction(externalAction: string): void {
    this.store$.dispatch(
      NotesActions.onHandleExternalWindowAction({ externalAction })
    );
  }
}
