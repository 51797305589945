export const _aiMimeTypes = [
  "application/illustrator",
  'application/postscript; version="1.0 / 1.1"',
  'application/postscript; version="1.0"',
  'application/postscript; version="1.2"',
  'application/postscript; version="10.0"',
  'application/postscript; version="11.0"',
  'application/postscript; version="12.0"',
  'application/postscript; version="13.0"',
  'application/postscript; version="14.0"',
  'application/postscript; version="15.0"',
  'application/postscript; version="16.0"',
  'application/postscript; version="2.0"',
  'application/postscript; version="2.1"',
  'application/postscript; version="3"',
  'application/postscript; version="3.0 / 3.2"',
  'application/postscript; version="3.0"',
  'application/postscript; version="3.1"',
  'application/postscript; version="4.0"',
  'application/postscript; version="5.0 / 5.5"',
  'application/postscript; version="6.0"',
  'application/postscript; version="7.0"',
  'application/postscript; version="8.0"',
  'application/postscript; version="88"',
  'application/postscript; version="9.0"',
  'application/postscript; version="japan"',
];
