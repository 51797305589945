<h2 mat-dialog-title>Potential CHS Data Detected</h2>
<div mat-dialog-content>
  <p>
    Check the following fields and remove any confidential highly sensitive
    data, e.g. Social Security numbers, driver’s license numbers, financial
    account numbers, etc. before saving.
  </p>
  <ul class="cui-bulleted-list px-xxs mt-xxs">
    <li *ngFor="let warning of data.chsWarnings">{{ warning }}</li>
  </ul>
</div>
<div mat-dialog-actions align="end" class="mt-s">
  <button
    mat-flat-button
    color="primary"
    class="mr-xxs"
    data-pgr-id="btn-dialog-continue"
    (click)="continuePressed.emit()"
  >
    Save Anyway
  </button>
  <button
    mat-stroked-button
    color="primary"
    data-pgr-id="btn-dialog-cancel"
    (click)="cancelPressed.emit()"
  >
    Go Back
  </button>
</div>
