import { ActionReducerMap } from "@ngrx/store";
import * as fromList from "./list.reducers";
import * as fromSpinner from "./spinner.reducers";

export interface FeatureState {
  list: fromList.State;
  spinner: fromSpinner.State;
}

export const reducers: ActionReducerMap<FeatureState, any> = {
  list: fromList.reducer,
  spinner: fromSpinner.reducer,
};
