import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  SplunkBuilder,
  UserContextApiService,
  UserContextService,
  WindowResizerService,
} from "@modules/core";
import { ClaimDetailsStateService } from "@modules/core/state";
import { SharedModule } from "@modules/shared";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import {
  AnalyticsLibraryModule,
  InteractionEventDirective,
} from "@pgr-cla/cla-analytics";
import {
  ClaimsUiModule,
  CoreUiModule,
  SideSheetService,
} from "@pgr-cla/core-ui-components";
import {
  AllContentComponent,
  ColumnsPaneComponent,
  ContentSummaryViewComponent,
  FiltersPaneComponent,
  PreviewItemDetailsComponent,
  PreviewPaneComponent,
  PreviewThumbnailComponent,
  PrimaryActionsComponent,
  RetiredComponent,
  RowCheckboxComponent,
  SearchComponent,
  SecondaryActionsComponent,
  SimpleDialogComponent,
  TagsCellComponent,
  TagsSectionComponent,
  VersionSelectorComponent,
} from "./components";
import { ContentSummaryRoutingModule } from "./content-summary.routing.module";
import { ClickHandlerDirective } from "./directives";
import {
  A11yDragDirective,
  A11yDropListDirective,
} from "./directives/a11y-drop-list/a11y-drop-list.directive";
import { ContentSummaryEffects } from "./effects/content-summary";
import {
  CheckboxContentItemAllAriaLabelPipe,
  CheckboxContentItemAriaLabelPipe,
  CheckboxContentItemIsSelectedPipe,
  ContentSummaryCheckboxAriaLabelPipe,
  IsCheckedPipe,
  RowClassPipe,
  TitleRepCodePipe,
} from "./pipes";
import * as contentSummaryReducer from "./reducers/content-summary";
import * as docSetReducer from "./reducers/doc-set";
import { ContentSummaryContextService } from "./services/content-summary/analytics/content-summary-context/content-summary-context.service";
import { ContentSummaryEventService } from "./services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "./services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "./services/content-summary/content-summary-view/content-summary-view.service";
import { ContentSummaryService } from "./services/content-summary/content-summary/content-summary.service";
import { TableActionService } from "./services/content-summary/table-action/table-action.service";
import { AnalyticsWrapperService } from "./services/core/analytics/analytics-wrapper.service";
import { AppContextService } from "./services/core/analytics/app-context.service";
import { DownloadPerformanceService } from "./services/core/analytics/download-performance.service";
import { AppMessageBus } from "./services/core/app-message-bus/app-message-bus.service";
import { BlobApiService } from "./services/core/blob-api/blob-api.service";
import { ClaimsContentViewerService } from "./services/core/claims-content-viewer/claims-content-viewer.service";
import { ContentManagementApiService } from "./services/core/content-management-api/content-management-api.service";
import { ContentSummaryErrorHandler } from "./services/core/error-handler/error-handler";
import { WindowManagerService } from "./services/core/window-manager/window-manager.service";
import { DocSetDetailsStoreService } from "./services/doc-set/doc-set-details-store/doc-set-details-store.service";
import { DocSetStoreFacadeService } from "./services/doc-set/doc-set-store-facade/doc-set-store-facade.service";
import { DocSetService } from "./services/doc-set/doc-set/doc-set.service";
import { NotificationService } from "./services/shared/notification/notification.service";
import { PerformanceEventService } from "./services/shared/performance-event/performance-event.service";
import { ActionsValidationUtility } from "./utilities/core";

@NgModule({
  declarations: [
    RetiredComponent,
    SimpleDialogComponent,
    ContentSummaryViewComponent,
    AllContentComponent,
    ColumnsPaneComponent,
    ContentSummaryCheckboxAriaLabelPipe,
    FiltersPaneComponent,
    PreviewPaneComponent,
    PrimaryActionsComponent,
    PreviewItemDetailsComponent,
    PreviewThumbnailComponent,
    VersionSelectorComponent,
    RowCheckboxComponent,
    SecondaryActionsComponent,
    SearchComponent,
    CheckboxContentItemAllAriaLabelPipe,
    CheckboxContentItemAriaLabelPipe,
    CheckboxContentItemIsSelectedPipe,
    IsCheckedPipe,
    RowClassPipe,
    ClickHandlerDirective,
    TagsCellComponent,
    TagsSectionComponent,
    TitleRepCodePipe,
    A11yDropListDirective,
    A11yDragDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AnalyticsLibraryModule,
    ClaimsUiModule,
    CoreUiModule,
    StoreModule.forFeature("contentSummary", contentSummaryReducer.reducers),
    StoreModule.forFeature("docSet", docSetReducer.reducers),
    EffectsModule.forFeature([ContentSummaryEffects]),
    ContentSummaryRoutingModule,
  ],
  exports: [
    InteractionEventDirective,
    AllContentComponent,
    ColumnsPaneComponent,
    ContentSummaryCheckboxAriaLabelPipe,
    FiltersPaneComponent,
    PreviewPaneComponent,
    PrimaryActionsComponent,
    PreviewItemDetailsComponent,
    PreviewThumbnailComponent,
    VersionSelectorComponent,
    RowCheckboxComponent,
    SecondaryActionsComponent,
    SearchComponent,
    CheckboxContentItemAllAriaLabelPipe,
    CheckboxContentItemIsSelectedPipe,
    RowClassPipe,
    ClickHandlerDirective,
    TagsCellComponent,
    TagsSectionComponent,
    TitleRepCodePipe,
    A11yDropListDirective,
    A11yDragDirective,
  ],
  providers: [
    SideSheetService,
    ContentSummaryStoreService,
    ContentSummaryService,
    ContentSummaryContextService,
    ContentSummaryEventService,
    ContentSummaryViewService,
    AnalyticsWrapperService,
    AppContextService,
    PerformanceEventService,
    DocSetStoreFacadeService,
    DocSetDetailsStoreService,
    BlobApiService,
    ContentManagementApiService,
    UserContextService,
    WindowManagerService,
    DocSetService,
    NotificationService,
    AppMessageBus,
    ClaimsContentViewerService,
    ClaimDetailsStateService,
    DownloadPerformanceService,
    ContentSummaryErrorHandler,
    SplunkBuilder,
    UserContextApiService,
    WindowResizerService,
    ActionsValidationUtility,
    TableActionService,
  ],
})
export class ContentSummaryModule {}
