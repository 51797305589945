import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { VehicleLimitsAndCoverages } from "@modules/policy/models/policy-vehicle";
import { AppState } from "@store";
import { Observable } from "rxjs";
import * as SpinnerSelectors from "../../../state/spinner/spinner.selectors";

@Component({
  selector: "cla-coverage-descriptions",
  templateUrl: "./coverage-descriptions.component.html",
  styleUrls: ["./coverage-descriptions.component.scss"],
})
export class CoverageDescriptionsComponent implements OnInit {
  @Input() vehicleLimitsAndCoverages: VehicleLimitsAndCoverages[];
  @Input() noBackground = false;
  @Input() multiColumn = false;
  isLoading$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(
      SpinnerSelectors.selectIsSpinnerActive
    );
  }
}
