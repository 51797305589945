import { Component, Input } from "@angular/core";
import { VehicleCoverageInfo } from "@claimssummaryshared/models";

@Component({
  selector: "cla-coverages-vehicle-descriptions",
  templateUrl: "coverages-vehicle-descriptions.component.html",
})
export class CoveragesVehicleDescriptionsComponent {
  @Input() vehicleCoverages: VehicleCoverageInfo[] | undefined;
  @Input() statedAmount: number | undefined;
  @Input() set viewAll(viewAll: boolean) {
    if (viewAll) {
      this.displayVehicles = this.vehicleCoverages;
    }
    if (!viewAll) {
      this.displayVehicles = this.vehicleCoverages?.slice(0, 1);
    }
  }
  displayVehicles: VehicleCoverageInfo[] | undefined;
}
