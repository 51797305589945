export const wavMimeTypes = [
  "audio/vnd.wave",
  "audio/wav",
  "audio/wave",
  "audio/x-pn-wav",
  "audio/x-wav",
  'audio/x-wav; version="0 generic"',
  'audio/x-wav; version="0 mpeg encoding"',
  'audio/x-wav; version="0 pcm encoding"',
  'audio/x-wav; version="0 waveformatextensible encoding"',
  'audio/x-wav; version="1 generic"',
  'audio/x-wav; version="1 mpeg encoding"',
  'audio/x-wav; version="1 pcm encoding"',
  'audio/x-wav; version="1 waveformatextensible encoding"',
  'audio/x-wav; version="2 generic"',
  'audio/x-wav; version="2 mpeg encoding"',
  'audio/x-wav; version="2 pcm encoding"',
  'audio/x-wav; version="2 waveformatextensible encoding"',
  'audio/x-wav; version="2.0"',
  'audio/x-wav; version="2.1"',
  'audio/x-wav; version="2.2"',
];
