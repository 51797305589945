import { Injectable } from "@angular/core";
import { ActivityAdapterApiService } from "@core/services";
import { Journal, Note } from "@notes/models";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";
import { Observable } from "rxjs";
// import { Cacheable } from 'ts-cacheable';

@Injectable()
export class NotesService {
  constructor(private activityAdapterApi: ActivityAdapterApiService) {}

  public getNotes(
    claimNumber: string,
    allowedCategoryCodes: string
  ): Observable<Note[]> {
    const allowedCategoryCodesSuffix = allowedCategoryCodes
      ? `?categoryCodes=${allowedCategoryCodes}`
      : "";
    return this.activityAdapterApi.get<Note[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/notes${allowedCategoryCodesSuffix}`
    );
  }

  public getNote(claimNumber: string, noteId: string): Observable<Note> {
    return this.activityAdapterApi.get<Note>(
      `/lossviewerapi/v1/claims/${claimNumber}/notes/${noteId}`
    );
  }

  public getJournals(claimNumber: string): Observable<Journal[]> {
    return this.activityAdapterApi.get<Journal[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/journals`
    );
  }

  // @Cacheable()
  public getCategories(): Observable<CodeDescriptionPair[]> {
    return this.activityAdapterApi.get<CodeDescriptionPair[]>(
      `/lossviewerapi/v1/codeTables?tableName=ClaimNoteCategory2`
    );
  }
}
