import { ISimpleDialog } from "../../models";

export class OpenValidationErrorMessaging {
  public static get invalidTypeAndStatusMessage(): ISimpleDialog {
    return {
      title: "Cannot open the following content:",
      message: `- Content Package <br>
        - Content Submission Invitation <br>
        - Content Sharing Invitation <br>
        - Photo Estimate Invitation <br>
        - Documents with a cancelled, draft, or failed status`,
    };
  }

  public static get tooManySelectionsMessage(): ISimpleDialog {
    return {
      title: "Cannot open this many items.",
      message: "Please reduce selection to 10 items or less.",
    };
  }

  public static get removedStatusMessage(): ISimpleDialog {
    return {
      title: "Cannot open removed content.",
      message: "Refresh electronic file folder to get the latest.",
    };
  }

  public static get noEstimateAvailable(): ISimpleDialog {
    return {
      title: "Estimate - pending or complete",
      message:
        "To locate vehicle documentation, filter by party/property and select the desired vehicle.",
    };
  }
}
