import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromPartyDetails from "./party-details.reducer";

export interface PartyDetailsState {
  party: fromPartyDetails.PartyDetailsState;
}

export const partyDetailsReducers: ActionReducerMap<PartyDetailsState, any> = {
  party: fromPartyDetails.reducer,
};

export const selectPartyDetailsState =
  createFeatureSelector<PartyDetailsState>("partyDetailsState");
