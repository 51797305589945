import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ClaimDetails,
  ClaimSummary,
  PartyDisplayOrder,
  PartyPropertyDetail,
  PolicyDriversRequest,
} from "@modules/core/models";
import { PolicyDriver } from "@modules/core/models/policy-driver.type";
import { partyConstants } from "@modules/shared/constants/party.constants";
import { Store } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { Observable, map } from "rxjs";
import * as claimDetailsActions from "./claim-details.actions";
import { setPageScrollingEnabled } from "./claim-details.actions";
import {
  selectAppIntegrationType,
  selectClaimDetails,
  selectClaimDetailsError,
  selectClaimNumber,
  selectClaimSummary,
  selectClaimSummaryError,
  selectErrorBannerMessages,
  selectIncidentState,
  selectIndividualPolicyDrivers,
  selectIsClaimDetailsRequestInFlight,
  selectIsClaimProChild,
  selectIsClaimProEmbedded,
  selectIsClaimStationChild,
  selectIsClaimStationEmbedded,
  selectIsInitialized,
  selectIsPageScrollingEnabled,
  selectIsPartyPropertyDetailsRequestInFlight,
  selectIsSiuInvolved,
  selectIsStandalone,
  selectIsStandaloneChild,
  selectLossDateTime,
  selectPartyPropertyDetails,
  selectPartyTreeDisplayOrder,
  selectPolicyNumber,
  selectPolicyState,
  selectReportDateTime,
  selectSuccessBannerMessage,
  selectWarningBannerIsDismissable,
  selectWarningBannerMessage,
} from "./claim-details.selectors";
import { AppIntegrationType } from "./models";

@Injectable()
export class ClaimDetailsStateService {
  public appIntegrationType$: Observable<AppIntegrationType | null> =
    this.store$.select(selectAppIntegrationType);
  public isClaimProEmbedded$: Observable<boolean> = this.store$.select(
    selectIsClaimProEmbedded
  );
  public isClaimProChild$: Observable<boolean> = this.store$.select(
    selectIsClaimProChild
  );
  public isClaimStationEmbedded$: Observable<boolean> = this.store$.select(
    selectIsClaimStationEmbedded
  );
  public isClaimStationChild$: Observable<boolean> = this.store$.select(
    selectIsClaimStationChild
  );
  public isStandalone$: Observable<boolean> =
    this.store$.select(selectIsStandalone);
  public isStandaloneChild$: Observable<boolean> = this.store$.select(
    selectIsStandaloneChild
  );
  public isInitialized$: Observable<boolean> =
    this.store$.select(selectIsInitialized);
  claimNumber$: Observable<string> = this.store$.select(selectClaimNumber);
  claimDetails$: Observable<ClaimDetails | undefined> =
    this.store$.select(selectClaimDetails);
  claimSummary$: Observable<ClaimSummary | undefined> =
    this.store$.select(selectClaimSummary);
  claimDetailsHasError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectClaimDetailsError);
  claimSummaryHasError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectClaimSummaryError);
  lossDateTime$: Observable<string | undefined> =
    this.store$.select(selectLossDateTime);
  individualPolicyDrivers$: Observable<PolicyDriver[] | undefined> =
    this.store$.select(selectIndividualPolicyDrivers);
  reportDateTime$: Observable<string | undefined> =
    this.store$.select(selectReportDateTime);
  isSiuInvolved$: Observable<boolean | undefined> =
    this.store$.select(selectIsSiuInvolved);
  isClaimDetailsRequestInFlight$: Observable<boolean> = this.store$.select(
    selectIsClaimDetailsRequestInFlight
  );
  partyPropertyDetails$: Observable<PartyPropertyDetail[] | undefined> =
    this.store$.select(selectPartyPropertyDetails).pipe(
      map((details: any) => {
        return details?.map((detail: any) => {
          return {
            ...detail,
            associatedPartyProperties: detail?.associatedPartyProperties.filter(
              (party: any) =>
                party.partyTypeCode !==
                partyConstants.relationToClaimAssociatedParty
            ),
          };
        });
      })
    );

  partyTreeDisplayOrder$: Observable<PartyDisplayOrder[]> = this.store$.select(
    selectPartyTreeDisplayOrder
  );
  isPartyPropertyDetailsRequestInFlight$: Observable<boolean> =
    this.store$.select(selectIsPartyPropertyDetailsRequestInFlight);

  successBannerMessage$: Observable<string> = this.store$.select(
    selectSuccessBannerMessage
  );
  errorBannerMessages$: Observable<string[]> = this.store$.select(
    selectErrorBannerMessages
  );
  warningBannerMessage$: Observable<string> = this.store$.select(
    selectWarningBannerMessage
  );
  warningBannerIsDismissable$: Observable<boolean> = this.store$.select(
    selectWarningBannerIsDismissable
  );
  policyState$: Observable<string | undefined> =
    this.store$.select(selectPolicyState);
  incidentState$: Observable<string | undefined> =
    this.store$.select(selectIncidentState);
  policyNumber$: Observable<string | undefined> =
    this.store$.select(selectPolicyNumber);
  isPageScrollingEnabled$: Observable<boolean> = this.store$.select(
    selectIsPageScrollingEnabled
  );
  constructor(private store$: Store<AppState>) {}

  onSetAppIntegrationType = (appIntegrationType: AppIntegrationType): void => {
    this.store$.dispatch(
      claimDetailsActions.onSetAppIntegrationType({ appIntegrationType })
    );
  };

  onClaimDetailsRequest = (
    claimNumber: string,
    redirectToClaim?: boolean,
    fromClaimSearch?: boolean
  ): void => {
    this.store$.dispatch(
      claimDetailsActions.onClaimDetailsRequest({
        claimNumber,
        redirectToClaim,
        fromClaimSearch,
      })
    );
  };

  onClaimSummaryRequest = (claimNumber: string): void => {
    this.store$.dispatch(
      claimDetailsActions.onClaimSummaryRequest({
        claimNumber,
      })
    );
  };

  onGetPolicyDriversRequest = (payload: PolicyDriversRequest): void => {
    this.store$.dispatch(
      claimDetailsActions.onGetPolicyDriversRequest({ payload })
    );
  };

  onClaimDetailsReceived = (payload: ClaimDetails): void => {
    this.store$.dispatch(
      claimDetailsActions.onClaimDetailsReceipt({ payload })
    );
  };

  onClaimSummaryReceived = (payload: ClaimSummary): void => {
    this.store$.dispatch(
      claimDetailsActions.onClaimSummaryReceipt({ payload })
    );
  };

  onPartyPropertyDetailsReceived = (payload: PartyPropertyDetail[]): void => {
    this.store$.dispatch(
      claimDetailsActions.onPartyPropertyDetailsReceipt({ payload })
    );
  };

  onPartyPropertyDetailsRequest = (claimNumber: string): void => {
    this.store$.dispatch(
      claimDetailsActions.onPartyPropertyDetailsRequest({ claimNumber })
    );
  };

  setClaimNumber = (claimNumber: string): void => {
    this.store$.dispatch(claimDetailsActions.setClaimNumber({ claimNumber }));
  };

  onSetSuccessBannerMessage(message: string): void {
    this.store$.dispatch(
      claimDetailsActions.setSuccessBannerMessage({ message })
    );
  }

  onSetErrorBannerMessages(messages: string[]): void {
    this.store$.dispatch(
      claimDetailsActions.setErrorBannerMessages({ messages })
    );
  }

  onSetWarningBannerMessage(message: string, isDismissable: boolean): void {
    this.store$.dispatch(
      claimDetailsActions.setWarningBannerMessage({ message, isDismissable })
    );
  }

  onSetPageScrollingEnabled(isEnabled: boolean): void {
    this.store$.dispatch(setPageScrollingEnabled({ isEnabled }));
  }
}
