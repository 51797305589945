import { createFeatureSelector, createSelector } from "@ngrx/store";
import { toDoCategoryCode } from "@notes/constants";
import { NotesState } from "@notes/models";
import { EmptyState } from "@shared/models";

export const notesState = createFeatureSelector<NotesState>("notes");
export const selectNotes = createSelector(notesState, (state) => state.notes);
export const selectNoteId = createSelector(notesState, (state) => state.noteId);
export const selectNote = createSelector(notesState, (state) => state.note);
export const selectNoteListSelectedTabIndex = createSelector(
  notesState,
  (state) => state.noteListSelectedTabIndex
);
export const selectIsToDoNotesTabSelected = createSelector(
  notesState,
  (state) => state.noteListSelectedTabIndex === 1
);

export const selectFilteredNotes = createSelector(notesState, (state) => {
  return [...state.notes, ...state.journals]
    .filter((k) => state.filteredNoteIds.includes(k.id))
    .sort((a, b) =>
      state.sortDirection === "desc"
        ? a.createdDate > b.createdDate
          ? -1
          : 1
        : a.createdDate > b.createdDate
        ? 1
        : -1
    );
});

export const selectToDoNotes = createSelector(notesState, (state) => {
  return [...state.notes, ...state.journals]
    .filter((k) => k.category.code === toDoCategoryCode)
    .sort((a, b) =>
      state.sortDirection === "desc"
        ? a.createdDate > b.createdDate
          ? -1
          : 1
        : a.createdDate > b.createdDate
        ? 1
        : -1
    );
});

export const selectNotesAndJournals = createSelector(notesState, (state) => [
  ...state.notes,
  ...state.journals,
]);

export const selectJournals = createSelector(
  notesState,
  (state) => state.journals
);
export const selectCategories = createSelector(
  notesState,
  (state) => state.categories
);
export const selectAllowedCategoryCodes = createSelector(
  notesState,
  (state) => state.allowedCategoryCodes
);
export const selectShouldCloseNoteWindow = createSelector(
  notesState,
  (state) => state.shouldCloseNoteWindow
);
export const selectFilterCriteria = createSelector(
  notesState,
  (state) => state.filterCriteria
);
export const selectSortDirection = createSelector(
  notesState,
  (state) => state.sortDirection
);
export const selectIsLoading = createSelector(
  notesState,
  (state) =>
    state.isLoadingNotes || state.isLoadingJournals || state.isLoadingCategories
);
export const selectIsErroredJournals = createSelector(
  notesState,
  (state) => state.isErroredJournals
);
export const selectIsSearchedOrFiltered = createSelector(
  notesState,
  (state) =>
    !!(
      state.filterCriteria.authoredRepCode ||
      state.filterCriteria.categoryCode ||
      (state.filterCriteria.createdDateRange &&
        (state.filterCriteria.createdDateRange.start ||
          state.filterCriteria.createdDateRange.end)) ||
      state.filterCriteria.searchTerm
    )
);

export const selectIsPopout = createSelector(
  notesState,
  (state) => state.isPopout
);

export const selectEmptyState = createSelector(
  notesState,
  selectIsErroredJournals,
  selectIsSearchedOrFiltered,
  selectFilteredNotes,
  selectToDoNotes,
  selectIsToDoNotesTabSelected,
  (
    state,
    isErroredJournals,
    isSearchedOrFiltered,
    filteredNotes,
    toDoNotes,
    isToDoNotesTabSelected
  ) => {
    if (!isToDoNotesTabSelected) {
      const hasError = state.error.message.length > 0;
      const isVisible =
        (hasError || (isSearchedOrFiltered && !filteredNotes.length)) &&
        !isErroredJournals;
      if (isVisible) {
        const isSearched = !!state.filterCriteria.searchTerm;
        const isFiltered = !!(
          state.filterCriteria.authoredRepCode ||
          state.filterCriteria.categoryCode ||
          state.filterCriteria.createdDateRange.start ||
          state.filterCriteria.createdDateRange.end
        );
        const emptyState: EmptyState = {
          icon: hasError ? "error" : isSearched ? "search" : "filter_list",
          primaryText: hasError
            ? state.error.message
            : `No matches exist for the applied ${
                isSearched && isFiltered
                  ? "search and filters"
                  : isSearched
                  ? "search term"
                  : "filters"
              }`,
          secondaryText: hasError
            ? ""
            : `Try a different ${
                isSearched && isFiltered
                  ? "filter or search combination"
                  : isSearched
                  ? "search term"
                  : "filter"
              }, or:`,
          actionText: hasError
            ? "try again"
            : `reset ${
                isSearched && isFiltered
                  ? "search and filters"
                  : isSearched
                  ? "search term"
                  : "filters"
              }`,
        };
        return emptyState;
      }
    } else {
      if (!toDoNotes.length) {
        return {
          icon: "search",
          primaryText: "No To Do notes were found.",
          secondaryText: "",
          actionText: "view all notes",
        } as EmptyState;
      }
    }
    return null;
  }
);
