<div #cell class="mat-cell-content grid-tag-cell flex w-full relative">
  <mat-chip-set
    cuiMatChipListAccessibility
    class="grid-tag-cell-mat-chip-list"
    role="list"
  >
    <ng-container *ngFor="let tag of tags">
      <mat-chip
        cuiMatChipAccessibility
        [disableRipple]="true"
        [selectable]="false"
        [removable]="false"
        role="listitem"
        class="tag-cell-chip"
      >
        <span class="grid-tag-item-content">{{ tag.tagName }}</span>
      </mat-chip>
    </ng-container>
  </mat-chip-set>
  <cui-popover
    align="bottomRight"
    class="text-right absolute"
    *ngIf="additionalTags.length"
  >
    <button cui-popover-trigger class="cui-link">
      +{{ additionalTags.length }}
    </button>
    <section
      cui-popover-content
      aria-labelledby="additionalTagsHeader"
      class="flex flex-col w-20"
    >
      <h2 cuiPopoverTitle class="screen-reader-only">Additional Tags</h2>
      <p class="text-base font-bold absolute" id="additionalTagsHeader">
        {{
          additionalTags.length +
            " Additional Tag" +
            (additionalTags.length > 1 ? "s" : "")
        }}
      </p>
      <div
        class="
          grid-tag-cell
          flex flex-wrap
          overflow-y-auto overflow-x-hidden
          max-h-xs
          mt-bl-2
          pb-bl-1
          pr-0
        "
      >
        <mat-chip-set
          cuiMatChipListAccessibility
          [shouldAutoFocus]="false"
          aria-label="Tags"
          [selectable]="false"
          role="list"
        >
          <ng-container *ngFor="let additionalTag of additionalTags">
            <mat-chip
              cuiMatChipAccessibility
              [disableRipple]="true"
              [selectable]="false"
              [removable]="false"
              tabindex="0"
              role="listitem"
            >
              <span class="grid-tag-item-content">{{
                additionalTag.tagName
              }}</span>
            </mat-chip>
          </ng-container>
        </mat-chip-set>
      </div>
    </section>
  </cui-popover>
</div>
