import { Injectable } from "@angular/core";
import { CodeTable, CodeTableValue } from "@claimssummaryshared/models";
import {
  PaymentEventTypesCodeTableValue,
  RelationshipToVehicleCodeTableValue,
  StateCodeTableValue,
} from "@modules/core/models";
import { SortableCodeTableValue } from "@modules/core/models/sortable-code-table.interface";
import { selectCodeTable } from "@modules/core/state/codes/codes.selectors";
import { Store } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { CodesState } from "./codes.reducer";

@Injectable()
export class CodeTableStateService {
  codeTableForSurcharge$: Observable<CodeTable<CodeTableValue> | undefined> =
    this.store$.select(selectCodeTable("SurchargeTable"));
  codeTableForIncidentLocation$: Observable<
    CodeTable<CodeTableValue> | undefined
  > = this.store$.select(selectCodeTable("LocationCodeTable"));
  codeTableForMake$: Observable<CodeTable<CodeTableValue> | undefined> =
    this.store$.select(selectCodeTable("make"));
  codeTableForStates$: Observable<CodeTable<StateCodeTableValue> | undefined> =
    this.store$.select(
      selectCodeTable<CodeTable<StateCodeTableValue>>("states")
    );
  codeTableForCountries$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("countrycodes"));
  codeTableForInspectionLocation$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("inspectionlocation"));
  codeTableForTimeZones$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("timezones"));
  codeTableForOtherIndividualParties$: Observable<CodeTableValue[]> =
    this.store$
      .select(selectCodeTable<CodeTable>("otherindividualparties"))
      .pipe(
        map((codeTable) => {
          return this.mapCodeTableValuesSortingByDescription(codeTable);
        })
      );
  codeTableForOtherBusinessParties$: Observable<CodeTableValue[]> = this.store$
    .select(selectCodeTable<CodeTable>("otherbusinessparties"))
    .pipe(
      map((codeTable) => {
        return this.mapCodeTableValuesSortingByDescription(codeTable);
      })
    );
  codeTableForRelationshipToInsured$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable("relationshiptoinsured"));
  codeTableForPaymentEventTypes$: Observable<
    CodeTable<PaymentEventTypesCodeTableValue> | undefined
  > = this.store$.select(
    selectCodeTable<CodeTable<PaymentEventTypesCodeTableValue>>("Pay_Event_Typ")
  );

  codeTableForPartyRelationToClaimShortCode$: Observable<
    CodeTable | undefined
  > = this.store$.select(
    selectCodeTable<CodeTable>("PartyRelationToClaimShortCode")
  );

  codeTableForRelationToVehicle$: Observable<
    RelationshipToVehicleCodeTableValue[] | undefined
  > = this.store$
    .select(selectCodeTable<CodeTable>("relationshiptovehicle"))
    .pipe(
      filter((codeTable: CodeTable<RelationshipToVehicleCodeTableValue>) => {
        return codeTable !== undefined;
      }),
      map((codeTable: CodeTable<RelationshipToVehicleCodeTableValue>) => {
        if (codeTable) {
          return [...codeTable.values].sort(
            (
              a: RelationshipToVehicleCodeTableValue,
              b: RelationshipToVehicleCodeTableValue
            ) => {
              if (a.claimStationDescription && b.claimStationDescription) {
                if (a.claimStationDescription < b.claimStationDescription) {
                  return -1;
                }
                if (a.claimStationDescription > b.claimStationDescription) {
                  return 1;
                }
              }
              return 0;
            }
          );
        }

        return [];
      })
    );

  codeTableForFixedPropertyTypeFiltered$: Observable<
    CodeTableValue[] | undefined
  > = this.store$.select(selectCodeTable<CodeTable>("fixedpropertytype")).pipe(
    filter((codeTable) => {
      return codeTable !== undefined;
    }),
    map((codeTable) => {
      if (codeTable) {
        return [...codeTable.values].filter(
          (value: CodeTableValue) => value.code
        );
      }

      return [];
    })
  );

  codeTableForVehicleProductTypeSorted$: Observable<
    SortableCodeTableValue[] | undefined
  > = this.store$
    .select(
      selectCodeTable<CodeTable<SortableCodeTableValue>>("vehicleproducttype")
    )
    .pipe(
      filter((codeTable) => {
        return codeTable !== undefined;
      }),
      map((codeTable) => {
        return this.mapSortableCodeTableValues(codeTable);
      })
    );

  codeTableForGenders$: Observable<CodeTable | undefined> = this.store$.select(
    selectCodeTable<CodeTable>("PartySex")
  );

  codeTableForMaritalStatus$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("MaritalStatus"));

  codeTableForBusinessContactPreference$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("PreferredContactBusiness"));

  codeTableForContactMethod$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("ContactMethod"));

  codeTableForOtherPhoneTypes$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("OtherPhoneType"));

  codeTableForPartyRelationship$: Observable<CodeTableValue[]> = this.store$
    .select(selectCodeTable<CodeTable>("PartyRelationship"))
    .pipe(
      map((codeTable) => {
        return this.mapCodeTableValuesSortingByDescription(codeTable);
      })
    );

  codeTableForContactPreference$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("PreferredContact"));

  codeTableForPaperlessContactPreference$: Observable<CodeTable | undefined> =
    this.store$.select(
      selectCodeTable<CodeTable>("PartyPreferElectronicContactCode")
    );

  codeTableForLanguages$: Observable<CodeTable | undefined> =
    this.store$.select(selectCodeTable<CodeTable>("Languages"));

  codeTableValuesForTextPreference$: Observable<
    SortableCodeTableValue[] | undefined
  > = this.store$
    .select(
      selectCodeTable<CodeTable<SortableCodeTableValue>>(
        "PreferredTextPhoneNumber"
      )
    )
    .pipe(
      map((codeTable) => {
        return this.mapSortableCodeTableValues(codeTable);
      })
    );

  codeTableForInsuranceCarrier$: Observable<
    SortableCodeTableValue[] | undefined
  > = this.store$
    .select(
      selectCodeTable<CodeTable<SortableCodeTableValue>>("ClaimantCarrier")
    )
    .pipe(map((codeTable) => this.mapSortableCodeTableValues(codeTable)));

  constructor(private store$: Store<CodesState>) {}

  public searchCodeTable(
    searchText$: Observable<string>,
    codeTableObservable$: Observable<CodeTable<CodeTableValue> | undefined>
  ): Observable<CodeTable<CodeTableValue> | undefined> {
    return combineLatest([searchText$, codeTableObservable$]).pipe(
      map(
        ([searchText, codeTable]) =>
          ({
            ...codeTable,
            values: codeTable?.values
              .filter((value) =>
                value.description
                  .toUpperCase()
                  .includes(searchText.toUpperCase())
              )
              .sort((a, b) => a.description.localeCompare(b.description)),
          } as CodeTable<CodeTableValue>)
      )
    );
  }

  public getVehicleMakeSearchResults(
    searchText$: Observable<string>
  ): Observable<CodeTable<CodeTableValue> | undefined> {
    return this.searchCodeTable(searchText$, this.codeTableForMake$);
  }

  public getIncidentLocationSearchResults(
    searchText$: Observable<string>
  ): Observable<CodeTable<CodeTableValue> | undefined> {
    return this.searchCodeTable(
      searchText$,
      this.codeTableForIncidentLocation$
    );
  }

  private mapCodeTableValuesSortingByDescription = (
    codeTable: CodeTable<CodeTableValue> | undefined
  ): CodeTableValue[] => {
    if (codeTable) {
      return [...codeTable.values].sort((a, b) =>
        a.description < b.description ? -1 : 1
      );
    }

    return [];
  };

  private mapSortableCodeTableValues = (
    codeTable: CodeTable<SortableCodeTableValue> | undefined
  ): SortableCodeTableValue[] => {
    if (codeTable) {
      return [...codeTable.values].sort(
        (a: SortableCodeTableValue, b: SortableCodeTableValue) =>
          (a.sortOrder as number) - (b.sortOrder as number)
      );
    }

    return [];
  };
}
