import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ScreenSizeService {
  public isSmallScreen$: Observable<boolean>;
  public isMediumScreen$: Observable<boolean>;
  public isMediumLargeScreen$: Observable<boolean>;
  public isLargeScreen$: Observable<boolean>;
  public isExtraLargeScreen$: Observable<boolean>;
  public is2ExtraLargeScreen$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    // https://tailwindcss.com/docs/responsive-design
    this.isSmallScreen$ = this.breakpointObserver
      .observe(`(min-width: 640px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
    this.isMediumScreen$ = this.breakpointObserver
      .observe(`(min-width: 768px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
    this.isMediumLargeScreen$ = this.breakpointObserver
      .observe(`(min-width: 900px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
    this.isLargeScreen$ = this.breakpointObserver
      .observe(`(min-width: 1024px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
    this.isExtraLargeScreen$ = this.breakpointObserver
      .observe(`(min-width: 1280px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
    this.is2ExtraLargeScreen$ = this.breakpointObserver
      .observe(`(min-width: 1536px)`)
      .pipe(map((observer: BreakpointState) => observer.matches));
  }
}
