import { HttpErrorResponse } from "@angular/common/http";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { CodeTableCode } from "@modules/shared/models/code-table-code.interface";
import { CodeTable } from "@modules/shared/models/code-table.interface";
import { createAction, props, union } from "@ngrx/store";

import { AgentInfo } from "../../models/agent-info";
import { ClaimPolicy } from "../../models/claim-policy";
import { PolicyLoss, VehicleLoss } from "../../models/loss-history";
import { LossVehicle } from "../../models/loss-vehicle";
import { PolicyConfirm } from "../../models/policy-confirm";
import { PolicyDetail } from "../../models/policy-detail";
import { PolicyDriver } from "../../models/policy-driver";
import { PolicyEndorsement } from "../../models/policy-endorsement.interface";
import { PolicyUrlResponse } from "../../models/policy-url";
import { PolicyVehicle } from "../../models/policy-vehicle";
import { ProductCode } from "../../models/productcode-description";
import { StateCode } from "../../models/statecode-description";

export const getPolicyUrlResponse = createAction(
  "[Policy] Get Policy Url",
  props<{ policyNumber: string; policyRenewalNumber: number }>()
);

export const getPolicyUrlResponseSuccess = createAction(
  "[Policy] Get Policy Url Success",
  props<{ policyUrlResponse: PolicyUrlResponse | null }>()
);

export const getAgentInfo = createAction(
  "[Policy] Get Agent Info",
  props<{ agentNumber: string }>()
);

export const getAgentInfoSuccess = createAction(
  "[Policy] Get Agent Info Success",
  props<{ agentInfo: AgentInfo | null }>()
);

export const getClaimPolicyWithPreReq = createAction(
  "[Policy] Get Claim Policy With Prerequesties",
  props<{ claimNumber: string }>()
);
export const getClaimPolicyWithPreReqSuccess = createAction(
  "[Policy] Get Claim Policy With Prerequesties Success",
  props<{
    claimInfo: ClaimInfo;
    claimPolicy: ClaimPolicy;
    stateCodeTableInfo: CodeTable<StateCode>[] | null;
    productCodeTableInfo: CodeTable<ProductCode>[] | null;
    endorsementCodeTable: CodeTable<CodeTableCode>[] | null;
  }>()
);
export const getPolicyDetails = createAction(
  "[Policy] Get Policy Details",
  props<{
    isPolicyAttached: boolean;
    policyNumber: string;
    renewalSuffixNumber: string;
    dateOfLoss: Date | string;
  }>()
);
export const getPolicyDetailsSuccess = createAction(
  "[Policy] Get Policy Details Success",
  props<{
    policyDetail: PolicyDetail | null;
  }>()
);

export const getClaimLossVehiclesWithPreReq = createAction(
  "[Policy] Get Claim Loss Vehicles With Prerequesties",
  props<{ claimNumber: string }>()
);

export const getClaimLossVehiclesWithPreReqSuccess = createAction(
  "[Policy] Get Claim Loss Vehicles With Prerequesties Success",
  props<{ vehicles: LossVehicle[] }>()
);

export const getClaimPolicyVehicles = createAction(
  "[Policy] Get Claim Policy Vehicles",
  props<{
    policyNumber: string;
    renewalSuffixNumber: string;
    dateOfLoss: Date | null;
    positions: string[];
    productCode: string;
  }>()
);

export const getClaimPolicyVehiclesSuccess = createAction(
  "[Policy] Get Claim Policy Vehicles Success",
  props<{ policyVehicles: PolicyVehicle[] }>()
);

export const getPolicyDrivers = createAction(
  "[Policy] Get Policy Drivers",
  props<{
    policyNumber: string;
    renewalSuffixNumber: number;
    dateOfLoss: Date | string | null;
    productCode: string;
  }>()
);

export const getPolicyDriversSuccess = createAction(
  "[Policy] Get Policy Drivers Success",
  props<{ policyDrivers: PolicyDriver[] }>()
);

export const getPolicyDriversLicenseNumber = createAction(
  "[Policy] Get Policy Drivers License Number",
  props<{ policyPosition: string }>()
);

export const getPolicyDriversLicenseNumberSuccess = createAction(
  "[Policy] Get Policy Drivers License Number Success",
  props<{
    driverLicense: string | null;
    protectedDataAccessLevel: string | null;
    policyPosition: string | null;
  }>()
);

export const getPolicyLosses = createAction(
  "[Policy] Get Policy Losses",
  props<{ policyNumber: string }>()
);

export const getPolicyLossesSuccess = createAction(
  "[Policy] Get Policy Losses Success",
  props<{ policyLosses: PolicyLoss[] | null }>()
);

export const getPolicyLossesFail = createAction(
  "[Policy] Get Policy Losses Fail",
  props<{ policyLosses: null }>()
);

//TODO: remove policyNumber
export const getVehicleLosses = createAction(
  "[Policy] Get Vehicle Losses",
  props<{
    policyNumber: string;
    vins: string[];
  }>()
);

export const getVehicleLossesSuccess = createAction(
  "[Policy] Get Vehicle Losses Success",
  props<{ vehicleLosses: VehicleLoss[] | null }>()
);

export const getVehicleLossesFail = createAction(
  "[Policy] Get Vehicle Losses Fail",
  props<{ vehicleLosses: null }>()
);

export const policyConfirm = createAction(
  "[Policy] Get Confirm Policy Info",
  props<{
    policyNumber: string;
    policyNumberSuffix: string;
    dateOfLoss: Date;
  }>()
);

export const policyConfirmSuccess = createAction(
  "[Policy] Get Confirm Policy Info Success",
  props<{ policyConfirm: PolicyConfirm | null }>()
);

export const getPolicyAttachVehicles = createAction(
  "[Policy] Get Policy Attach Vehicles",
  props<{
    policyNumber: string;
    policyNumberSuffix: string;
    dateOfLoss: Date;
    policyStateCode: string;
  }>()
);

export const getPolicyAttachVehiclesSuccess = createAction(
  "[Policy] Get Policy Attach Vehicles Success",
  props<{ policyAttachVehicles: PolicyVehicle[] }>()
);

export const clearPolicy = createAction("[Policy] Clear Policy");

export const unsupportedVehicleType = createAction(
  "[Policy] UnsupportedVehicle Type"
);

export const policyErrorOccurred = createAction(
  "[Policy] Error Occurred",
  props<{ error: Error | HttpErrorResponse }>()
);

export const policyConfirmError = createAction(
  "[Policy] Confirm Error",
  props<{ errorMessage: string }>()
);

export const confirmationClear = createAction("[Policy] Confirmation Clear");

export const getVehicleInfo = createAction(
  "[Policy] Get Vehicle Info",
  props<{
    policyNumber: string;
    renewalSuffixNumber: string;
    dateOfLoss: Date | null;
    positions: string[];
    productCode: string;
  }>()
);

export const getVehicleInfoSuccess = createAction(
  "[Policy] Get Vehicle Info Success",
  props<{ policyVehicles: PolicyVehicle[] }>()
);

export const clearVehicleInfo = createAction("[Policy] Clear Vehicle Info");

export const getPolicyEndorsements = createAction(
  "[Policy] Get Policy Endorsements",
  props<{ policyNumber: string; dateOfLoss: Date | string }>()
);

export const getPolicyEndorsementsSuccess = createAction(
  "[Policy] Get Policy Endorsements Success",
  props<{ policyEndorsements: PolicyEndorsement[] }>()
);

const all = union({
  clearPolicy,
  unsupportedVehicleType,
  policyErrorOccurred,
});

export type PolicyActionsUnion = typeof all;
