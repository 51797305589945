<mat-card appearance="outlined" class="p-0">
  <mat-card-content
    class="overflow-auto"
    [ngClass]="
      !!(screenSizeService.is2ExtraLargeScreen$ | async)
        ? 'desktop-payments-table'
        : !!(screenSizeService.isMediumScreen$ | async)
        ? 'tablet-payments-table'
        : 'mobile-payments-table'
    "
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
      class="cui-table payments-table"
      data-pgr-id="tblpayments"
    >
      <!-- Header row first group -->
      <ng-container
        matColumnDef="header-row-id-group"
        [sticky]="!!(screenSizeService.isLargeScreen$ | async)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center cui-cell-border-right"
          colspan="5"
        >
          <span class="screen-reader-only">No Value</span>
        </th>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-invoice-group">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="text-center cui-cell-border-right"
          colspan="8"
        >
          Invoice
        </th>
      </ng-container>

      <!-- Header row third group -->
      <ng-container matColumnDef="header-row-payment-group">
        <th mat-header-cell *matHeaderCellDef class="text-center" colspan="10">
          Payment
        </th>
      </ng-container>

      <ng-container
        matColumnDef="name"
        [sticky]="!!(screenSizeService.isLargeScreen$ | async)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Pay to the Order of"
          colspan="3"
        >
          Pay to the Order of
        </th>
        <td mat-cell *matCellDef="let element" colspan="3">
          {{ element.name | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="exposure"
        [sticky]="!!(screenSizeService.isLargeScreen$ | async)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Exposure"
          class="cui-cell-border-right"
          colspan="2"
        >
          Exposure
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-border-right"
          colspan="2"
        >
          {{ element.exposure | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Invoice Amount"
          class="text-right"
          colspan="2"
        >
          Amount
        </th>
        <td mat-cell *matCellDef="let element" class="text-right" colspan="2">
          {{ element.invoiceAmount | currency: "USD":"symbol" | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Invoice Number"
          colspan="2"
        >
          Number
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{ element.invoiceNumber | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Invoice Type"
          colspan="2"
        >
          Type
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{ element.invoiceType | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="invoiceStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Invoice Status"
          class="cui-cell-border-right"
          colspan="2"
        >
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-border-right"
          colspan="2"
        >
          {{ element.invoiceStatus | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentAmount">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Payment Amount"
          class="text-right"
          colspan="2"
        >
          Amount
        </th>
        <td mat-cell *matCellDef="let element" class="text-right" colspan="2">
          {{ element.paymentAmount | currency: "USD":"symbol" | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentNumber">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Payment Number"
          colspan="2"
        >
          Number
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{ element.paymentNumber | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentType">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Payment Type"
          colspan="2"
        >
          Type
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{ element.paymentType | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentStatus">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Payment Status"
          colspan="2"
        >
          Status
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{ element.paymentStatus | cuiEmptyDash }}
        </td>
      </ng-container>

      <ng-container matColumnDef="paymentIssuedDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by Payment Issued Date"
          colspan="2"
        >
          Issued
        </th>
        <td mat-cell *matCellDef="let element" colspan="2">
          {{
            element.paymentIssuedDate
              | date: "MM/dd/yyyy"
              | nullDefault
              | cuiEmptyDash
          }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="headerGroupColumns; sticky: true"
        class="cui-table-multi-header"
      ></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        class="cui-row-selectable payments-row"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-footer-row" *matNoDataRow>
        <td class="mat-footer-cell p-0" colspan="23">
          <cui-empty-state
            *ngIf="
              !!!(paymentsStateService.paymentsRequestInFlight$ | async) &&
              !!!(paymentsStateService.paymentsError$ | async)
            "
            [announceOnAppear]="true"
            primaryText="No Payment(s) found."
          >
          </cui-empty-state>
          <cui-empty-state
            *ngIf="
              !!(paymentsStateService.paymentsRequestInFlight$ | async) &&
              !!!(paymentsStateService.paymentsError$ | async)
            "
            icon="spacer"
            primaryText="Loading..."
          >
          </cui-empty-state>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <cui-spinner
    *ngIf="!!(paymentsStateService.paymentsRequestInFlight$ | async)"
    class="payments-spinner"
  ></cui-spinner>
</mat-card>
