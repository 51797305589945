import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "cla-claim-header-search-dialog",
  templateUrl: "claim-header-search-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimHeaderSearchDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ClaimHeaderSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { claimNumber: string }
  ) {}

  onConfirm(): void {
    this.dialogRef.close();
  }
}
