<div class="container-div">
  <div>
    <mat-expansion-panel
      role="region"
      aria-labelledby="underwritingMemoLanding"
      [hideToggle]="(underwritingMemos$ | async)?.length === 0"
      data-pgr-id="uwMemoLandingExpansionPanel"
    >
      <mat-expansion-panel-header class="h64">
        <cla-uw-memo-landing-header
          [underwritingMemos]="underwritingMemos$ | async"
          [claimNumber]="claimNumber"
          class="w-full"
        ></cla-uw-memo-landing-header>
      </mat-expansion-panel-header>
      <cla-uw-memo-landing-overview
        [underwritingMemos]="underwritingMemos$ | async"
        [userId]="userId"
      >
      </cla-uw-memo-landing-overview>
    </mat-expansion-panel>
  </div>
</div>
