import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { NotificationService } from "@pgr-cla/core-ui-components";
import { tap } from "rxjs/operators";

import * as coreSharedActions from "./core-shared.actions";

@Injectable()
export class SharedEffects {
  detectNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(coreSharedActions.onNotificationReq),
        tap((action) => {
          if (!(action.message instanceof Array)) {
            this.notificationService.notificationEvent(
              action.message,
              action.notificationType
            );
            return;
          }
          action.message.forEach((element) =>
            this.notificationService.notificationEvent(
              element,
              action.notificationType
            )
          );
        })
      ),
    { dispatch: false }
  );

  serverError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(coreSharedActions.onServerError),
        tap((error) => {
          this.logging.log(error.error, LoggedMessageLevel.Error);
          this.router.navigate(["techdiff"]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private router: Router,
    private logging: LoggingService
  ) {}
}
