import { Injectable } from "@angular/core";
import { CanActivate, CanLoad } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, first, switchMap, timeout } from "rxjs/operators";

import { AuthenticationService } from "@core/services/authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuardService implements CanActivate, CanLoad {
  constructor(private readonly authService: AuthenticationService) {}

  public canActivate(): Observable<boolean> {
    return this.shouldPass();
  }

  public canLoad(): Observable<boolean> {
    return this.shouldPass();
  }
  private shouldPass(): Observable<boolean> {
    return this.authService.token$.pipe(
      first((token) => !!token),
      timeout(2000),
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        this.authService.authorize();
        return of(false);
      })
    );
  }

  // canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
  //   return this.shouldPass();
  // }

  // private shouldPass(): Observable<boolean> {
  //   return this.authService.tokenResponse().pipe(
  //     first((token: TokenResponse ) => token !== null && token.accessToken !== ''),
  //     timeout(2000),
  //     switchMap(() => of(true)),
  //     catchError(() => of(false)),
  //   );
  // }

  // ngOnDestroy(): void {
  //   this.unsubscribeSubject$.next();
  //   this.unsubscribeSubject$.complete();
  // }
}
