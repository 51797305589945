import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChange,
  ViewChild,
} from "@angular/core";
import { IContentTag } from "@modules/electronic-file-folder/models";

@Component({
  selector: "cla-tags-cell",
  templateUrl: "./tags-cell.component.html",
  styleUrls: ["./tags-cell.component.scss"],
})
export class TagsCellComponent implements AfterViewInit, OnChanges {
  public additionalTags: IContentTag[] = [];
  @ViewChild("cell", { read: ElementRef })
  public cell: ElementRef;
  @Input() public staticSize = true;
  @Input() public tags: IContentTag[];

  public ngAfterViewInit(): void {
    if (!this.staticSize) {
      window.addEventListener("resize", () => this._setAdditionalTags());
    }

    this._setAdditionalTags();
  }
  public ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    // eslint-disable-next-line no-loops/no-loops
    for (const propName in changes) {
      if (propName === "tags" && !changes[propName].isFirstChange()) {
        this._setAdditionalTags();
      }
    }
  }

  private _getAdditionalTags(): IContentTag[] {
    if (this.tags.length) {
      const cellOffsetTop =
        this.cell.nativeElement.children[0].children[0].children[0].offsetTop;
      const tagEls = Array.from(
        this.cell.nativeElement.children[0].children[0].children
      ) as HTMLElement[];
      // eslint-disable-next-line no-loops/no-loops
      for (const tagEl of tagEls) {
        if ((tagEl as HTMLElement).offsetTop > cellOffsetTop) {
          return this.tags.slice(tagEls.indexOf(tagEl));
        }
      }
    }
    return [];
  }

  private _setAdditionalTags() {
    setTimeout(() => {
      this.additionalTags = this._getAdditionalTags();
    });
  }
}
