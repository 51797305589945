import { ITagsState } from "./tags-state.interface";

export const tagsInitialState: ITagsState = {
  loading: false,
  tags: {
    recommended: [],
    restricted: [],
    createdUTCDateTime: "",
    expirationUTCDateTime: "",
  },
};
