<mat-card appearance="outlined" *ngIf="!authorized">
  <mat-card-header>
    <mat-card-title>User information</mat-card-title>
    <mat-icon mat-card-avatar style="font-size: 40px">info</mat-icon>
    <!-- <i mat-card-avatar style='font-size: 40px' class="material-icons">info</i> -->
  </mat-card-header>
  <mat-card-content>
    <h4>Not Authorized</h4>
    <p>There is no currently authenticated user. Please sign in.</p>
  </mat-card-content>
  <button
    *ngIf="!authorized"
    id="handle-sign-in"
    mat-button
    (click)="authService.authorize()"
  >
    SIGN IN
  </button>
</mat-card>
<mat-card appearance="outlined" *ngIf="authorized">
  <mat-card-header>
    <mat-card-title>User Authenticated</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <h4>Authorized</h4>
    <p>The user has been authorized by the Identity Provider</p>
  </mat-card-content>
  <button
    *ngIf="authorized"
    id="handle-sign-out"
    mat-button
    (click)="authService.signOut()"
  >
    SIGN OUT
  </button>
</mat-card>
