import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ClaimHeaderItem, ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { SearchTypeValues } from "../../../constants";
import { getNormalizedParams } from "../../../helpers";
import {
  IContentDetailsGetResponse,
  IContentSummaryItem,
  IStoreEntity,
} from "../../../models";
import { ContentSummaryStoreService } from "../content-summary-store/content-summary-store.service";

@UntilDestroy()
@Injectable()
export class ContentSummaryViewService {
  public get isPartyPropertyView$(): Observable<boolean> {
    return combineLatest([this.isPropertyView$, this.isPartyView$]).pipe(
      map(
        ([isPropertyView, isPartyView]: [boolean, boolean]) =>
          isPropertyView || isPartyView
      )
    );
  }

  public get isPartyView$(): Observable<boolean> {
    return this._partyIdSubject$.pipe(map((partyId) => !!partyId));
  }

  public get isPropertyView$(): Observable<boolean> {
    return this._propertyIdSubject$.pipe(map((propertyId) => !!propertyId));
  }

  public get partyKey$(): Observable<string> {
    return this._partyIdSubject$.pipe();
  }

  public actionIsChangeable$: Observable<boolean>;

  public actionIsEditable$: Observable<boolean>;

  public actionIsOpenable$: Observable<boolean>;

  public claimNumber$: Observable<string>;
  public clearSearchAndFilter$: Observable<void>;
  public deselectAllSelectedItems$: Observable<void>;
  public filteredTotal$: Observable<number>;

  public partyOrPropertyName$: Observable<string>;

  public resultsTotal$: Observable<number>;

  public selectedItem$: Observable<IContentSummaryItem | undefined>;

  public selectedTotal$: Observable<number>;
  public headerItems$: Observable<ClaimHeaderItem[]>;

  private _claimNumberSubject$: BehaviorSubject<string> = new BehaviorSubject(
    ""
  );
  private _clearSearchAndFilterSubject$: Subject<void> = new Subject();
  private _deselectAllSelectedItemsSubject$: Subject<void> = new Subject();
  private _partyIdSubject$: BehaviorSubject<string> = new BehaviorSubject("");
  private _partyOrPropertyNameSubject$: BehaviorSubject<string> =
    new BehaviorSubject("");
  private _propertyIdSubject$: BehaviorSubject<string> = new BehaviorSubject(
    ""
  );
  private _selectedItemSubject$: BehaviorSubject<
    IContentSummaryItem | undefined
  > = new BehaviorSubject(undefined);

  constructor(
    private activatedRoute: ActivatedRoute,
    private contentSummaryStoreService: ContentSummaryStoreService,
    private claimNumberPipe: ClaimNumberPipe
  ) {
    this.filteredTotal$ = this.contentSummaryStoreService
      .getFilteredContentItems()
      .pipe(map((x: IContentSummaryItem[]) => x.length));
    this.resultsTotal$ = this.contentSummaryStoreService
      .getContentDetails()
      .pipe(
        filter((x: IStoreEntity<IContentDetailsGetResponse>) => !!x.response),
        map(
          (x) => (x.response as IContentDetailsGetResponse).contentItems.length
        )
      );
    this.selectedTotal$ = this.contentSummaryStoreService.selectedItems$.pipe(
      map((items: string[]) => items.length)
    );
    this.claimNumber$ = this._claimNumberSubject$.asObservable();
    this.deselectAllSelectedItems$ =
      this._deselectAllSelectedItemsSubject$.asObservable();
    this.partyOrPropertyName$ =
      this._partyOrPropertyNameSubject$.asObservable();
    this.selectedItem$ = this._selectedItemSubject$.asObservable();
    this.actionIsChangeable$ =
      this.contentSummaryStoreService.selectedItems$.pipe(
        withLatestFrom(this._selectedItemSubject$),
        map(
          ([selectedItems, selectedItem]: [string[], IContentSummaryItem]) => {
            const enabled =
              selectedItem !== undefined &&
              selectedItem !== null &&
              selectedItems.length > 0;

            return enabled;
          }
        )
      );

    this.headerItems$ = combineLatest([
      this.partyOrPropertyName$,
      this.claimNumber$,
      this.isPartyView$,
      this.isPropertyView$,
    ]).pipe(
      map(([partyOrPropertyName, claimNumber, isPartyView, isPropertyView]) => {
        let headerItems: ClaimHeaderItem[] = [
          {
            label: "Claim Number",
            value: this.claimNumberPipe.transform(claimNumber),
          },
        ];

        if (isPartyView || isPropertyView) {
          headerItems = [
            ...headerItems,
            {
              label: isPartyView ? "Party Name" : "Property Name",
              value: partyOrPropertyName,
            },
          ];
        }

        return headerItems;
      })
    );

    this.actionIsEditable$ =
      this.contentSummaryStoreService.selectedItems$.pipe(
        withLatestFrom(this._selectedItemSubject$),
        map(
          ([selectedItems, selectedItem]: [string[], IContentSummaryItem]) => {
            if (selectedItem && selectedItems.length === 1) {
              return selectedItem.status === "Draft";
            }
            return false;
          }
        )
      );
    this.actionIsOpenable$ =
      this.contentSummaryStoreService.selectedItems$.pipe(
        map((selectedItems: string[]) => {
          let result = true;
          if (selectedItems.length === 0) {
            result = false;
          }
          return result;
        })
      );

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => {
        const normalizedParams = getNormalizedParams(params);

        this._claimNumberSubject$.next(normalizedParams["claimNumber"]);
        this._partyIdSubject$.next(normalizedParams["partyId"]);
        this._propertyIdSubject$.next(normalizedParams["propertyId"]);

        this.contentSummaryStoreService.onSetGridFilter({
          claimNumber: normalizedParams["claimNumber"],
          partyId: normalizedParams["partyId"],
          propertyId: normalizedParams["propertyId"],
        });
      });

    this.contentSummaryStoreService
      .getContentDetails()
      .pipe(filter((x) => !!x && !!x.response))
      .subscribe((x: IStoreEntity<IContentDetailsGetResponse>) => {
        const response = x.response as IContentDetailsGetResponse;
        if (
          response.partyPropertyInfo &&
          response.partyPropertyInfo.partyOrPropertyName
        ) {
          this._partyOrPropertyNameSubject$.next(
            response.partyPropertyInfo.partyOrPropertyName
          );
        }
      });

    this.contentSummaryStoreService.selectedItems$
      .pipe(
        withLatestFrom(this.contentSummaryStoreService.getContentDetails()),
        filter(
          ([, details]: [string[], IStoreEntity<IContentDetailsGetResponse>]) =>
            !!details.response
        )
      )
      .subscribe(
        ([selectedItems, contentDetails]: [
          string[],
          IStoreEntity<IContentDetailsGetResponse>
        ]) => {
          const selectedItem = (
            contentDetails.response as IContentDetailsGetResponse
          ).contentItems.find((item: IContentSummaryItem) => {
            return item.id === selectedItems[0];
          });
          this._selectedItemSubject$.next(selectedItem);
        }
      ),
      (this.clearSearchAndFilter$ = this
        ._clearSearchAndFilterSubject$ as Observable<void>);
  }

  public deselectAllSelectedItems(): void {
    this._deselectAllSelectedItemsSubject$.next();
    this.contentSummaryStoreService.onContentSummaryDeselectAllSelectedItems();
  }

  public clearSearchAndFilters(): void {
    this.contentSummaryStoreService.onResetFilters();
    this.contentSummaryStoreService.onContentSummarySearch({
      searchTerm: "",
      searchType: SearchTypeValues.None,
    });
    this._clearSearchAndFilterSubject$.next();
  }
}
