import { Injectable } from "@angular/core";
import { AppPathService } from "@modules/core";
import { ClaimDetailsStateService } from "@modules/core/state";
import { WindowManagementService } from "@pgr-cla/cla-window";
import { MessageData } from "@pgr-cla/cla-window/lib/services/window-management/models";
import {
  filter,
  first,
  map,
  Subject,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";

@Injectable()
export class ActivityWebEventService {
  private _notesListWindowOpenedSubject$: Subject<string>;

  private readonly claimNotesListChildWindowId = "claim-notes-list-child";

  constructor(
    private windowManagementService: WindowManagementService,
    private appPathService: AppPathService,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {
    this._notesListWindowOpenedSubject$ = new Subject();

    this._notesListWindowOpenedSubject$.subscribe((windowId: string) => {
      this.handleContentViewerClickedMessages(windowId);
      this.handleBannerMessages(windowId);
    });
  }

  public notesListWindowOpened = (windowId: string): void =>
    this._notesListWindowOpenedSubject$.next(windowId);

  private handleBannerMessages = (windowId: string): void => {
    const stream$ = this.windowManagementService.getFilteredStream(
      windowId,
      "SET_BANNER_MESSAGE"
    );

    if (stream$) {
      stream$.subscribe((message) => {
        this.claimDetailsStateService.onSetSuccessBannerMessage(message.value);
      });
    }
  };

  private handleContentViewerClickedMessages = (windowId: string): void => {
    const stream$ = this.windowManagementService.getFilteredStream(
      windowId,
      "CONTENT_VIEWER_OPEN_CLICKED"
    );

    if (stream$) {
      stream$
        .pipe(
          map((message: MessageData) => message.path),
          withLatestFrom(this.appPathService.contentViewerPath$),
          tap(([contentPath, basePath]: [string, string]) => {
            const childWindowId = `content-viewer-${contentPath}`;

            if (this.windowManagementService.isChildWindowOpen(childWindowId)) {
              if (
                this.windowManagementService.isChildWindowOpen(
                  this.claimNotesListChildWindowId
                )
              ) {
                this.windowManagementService.postMessage(
                  this.claimNotesListChildWindowId,
                  { type: "CONTENT_VIEWER_ALREADY_OPEN" }
                );
              } else {
                this.windowManagementService.focusChildWindow(childWindowId);
              }
            } else {
              this.windowManagementService.openChildWindow(
                childWindowId,
                basePath.replace("{0}", contentPath),
                {
                  tab: !this.windowManagementService.isChildWindowOpen(
                    this.claimNotesListChildWindowId
                  ),
                }
              );
            }
          })
        )
        .subscribe();
    }
  };
}
