import { Action, createReducer, on } from "@ngrx/store";

import { PolicySearch } from "../../models/policy-search";

import * as policySearchActions from "./policy-search.actions";

export interface State {
  searchPolicy: PolicySearch[] | null;
  errorMessage: string | null;
}

export const initialState: State = {
  searchPolicy: null,
  errorMessage: null,
};

const policySearchReducer = createReducer(
  initialState,
  on(policySearchActions.searchPolicyClearRequest, (state, action) => ({
    ...initialState,
  })),
  on(
    policySearchActions.searchPolicyByPolicyNumberResponse,
    (state, action) => ({
      ...state,
      searchPolicy: action.policySearchResults,
      errorMessage: null,
    })
  ),
  on(policySearchActions.searchPolicyErrorResponse, (state, action) => ({
    ...state,
    searchPolicy: null,
    errorMessage: action.errorMessage,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return policySearchReducer(state, action);
}
