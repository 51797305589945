import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ApiErrorResponse } from "@modules/core";
import { coreSharedActions, NotificationType } from "@modules/shared/redux";
import { of } from "rxjs";
import { catchError, switchMap, concatMap } from "rxjs/operators";

import { UwMemoLandingService } from "../../services/uw-memo-landing.service";

import * as underwritingMemoLandingActions from "./uw-memo-landing.actions";

@Injectable()
export class UwMemoLandingEffects {
  detectUnderwritingMemosRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        underwritingMemoLandingActions.onUnderwritingMemosByClaimNumberRequested
      ),
      switchMap((action) =>
        this.uwMemoLandingService.getUnderwritingMemos(action.claimNumber).pipe(
          switchMap((response) => {
            return of(
              underwritingMemoLandingActions.onUnderwritingMemosByClaimNumberReceived(
                { memos: response }
              )
            );
          }),
          catchError((error) => this.onError(error, false, true))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private uwMemoLandingService: UwMemoLandingService,
    private router: Router
  ) {}

  private mapErrorMessage = (
    error: any,
    responseMessageMap: { [key: string]: string } = {}
  ): string => {
    if (error.status === 400 && error.error !== null) {
      const apiError = error.error as ApiErrorResponse;

      if (apiError.errorCode && responseMessageMap[apiError.errorCode]) {
        return responseMessageMap[apiError.errorCode];
      }

      return apiError.attributeErrors.length
        ? apiError.attributeErrors[0].attributeDisplayMessage
        : apiError.developerMessage;
    }

    return "An unknown error occurred. Please try again later.";
  };

  private onError = (
    error: any,
    claimRetrievalError = false,
    memoRetrievalError = false,
    responseMessageMap: { [key: string]: string } = {}
  ): Action[] => {
    if (memoRetrievalError) {
      return [
        coreSharedActions.onNotificationReq({
          message: "An error occurred loading Underwriting Memos.",
          notificationType: NotificationType.ERROR,
        }),
      ];
    }
    switch (error.status) {
      case 400:
      case 404: {
        const errorMessage = this.mapErrorMessage(error, responseMessageMap);
        if (claimRetrievalError) {
          this.router.navigate(["notfound"]);
          return [];
        }
        return [
          coreSharedActions.onNotificationReq({
            message: errorMessage,
            notificationType: NotificationType.ERROR,
          }),
        ];
      }
      case 500: {
        const errorMessage = this.mapErrorMessage(error, responseMessageMap);

        if (claimRetrievalError) {
          this.router.navigate(["notfound"]);
          return [];
        }
        return [
          coreSharedActions.onNotificationReq({
            message: errorMessage,
            notificationType: NotificationType.ERROR,
          }),
        ];
      }
      default: {
        return [coreSharedActions.onServerError(error)];
      }
    }
  };
}
