export const pdfMimeTypes = [
  "application/acrobat",
  "application/pdf",
  'application/pdf; version="1.0"',
  'application/pdf; version="1.1"',
  'application/pdf; version="1.2"',
  'application/pdf; version="1.3"',
  'application/pdf; version="1.4"',
  'application/pdf; version="1.5"',
  'application/pdf; version="1.6"',
  'application/pdf; version="1.7"',
  'application/pdf; version="1a"',
  'application/pdf; version="1b"',
  'application/pdf; version="2a"',
  'application/pdf; version="2b"',
  'application/pdf; version="2u"',
  'application/pdf; version="3a"',
  'application/pdf; version="3b"',
  'application/pdf; version="3u"',
  "application/vnd.cups-pdf",
  "application/vnd.pdf",
  "application/vnd.sealedmedia.softseal.pdf",
  "application/x-pdf",
  "text/pdf",
  "text/x-pdf",
];
