import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { FormControl } from "@angular/forms";
import { IContentSummaryItem } from "@modules/electronic-file-folder/models";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";

@UntilDestroy()
@Component({
  selector: "cla-filters-pane",
  templateUrl: "filters-pane.component.html",
  styleUrls: ["./filters-pane.component.scss"],
})
export class FiltersPaneComponent implements OnInit {
  public maxDate: FormControl<DateTime | null> =
    new FormControl<DateTime | null>(null);
  public minDate: FormControl<DateTime | null> =
    new FormControl<DateTime | null>(null);
  public originDateTime: any;
  public viewModel$: Observable<any> = combineLatest([
    this.contentSummaryStoreService.getFilters(),
    this.contentSummaryStoreService.getFilterableColumns(),
  ]).pipe(map(([filters, columns]) => ({ filters, columns })));

  constructor(
    private contentSummaryStoreService: ContentSummaryStoreService,
    private announcer: LiveAnnouncer,
    public sideSheetService: SideSheetService
  ) {}

  public filterCountIsHidden(filterState: {
    appliedFilters: string;
    maxDate: number;
    minDate: number;
  }): boolean {
    let result = true;
    if (filterState.appliedFilters && filterState.appliedFilters.length > 0) {
      result = false;
    } else if (filterState.minDate || filterState.maxDate) {
      result = false;
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getErrorMessage(errors: any): string {
    if (!errors) return "";
    if (errors.matDatepickerParse) return "Please format as MM/DD/YYYY.";
    if (errors.matDatepickerMin) return "Enter date later than start date.";
    if (errors.matDatepickerMax) return "Enter date earlier than end date.";
    return "";
  }

  public getFilterCount(filterState: {
    appliedFilters: string;
    maxDate: number;
    minDate: number;
  }): string {
    let result = 0;
    if (filterState.appliedFilters) {
      result = filterState.appliedFilters.length;
    } else if (filterState.minDate || filterState.maxDate) {
      result = (filterState.maxDate ? 1 : 0) + (filterState.minDate ? 1 : 0);
    }

    return result.toString();
  }

  public handleClose(): void {
    const sideSheet = this.sideSheetService.get("EFFMain");

    if (sideSheet) {
      sideSheet.close();
    }
  }

  public ngOnInit(): void {
    this.viewModel$
      .pipe(
        map((viewModel) => viewModel.filters.originDateTime),
        untilDestroyed(this)
      )
      .subscribe((originDateTime) => {
        this.originDateTime = originDateTime;
        if (
          originDateTime.minDate !==
          (this.minDate.value ? this.minDate.value.toUTC().toISO() : undefined)
        ) {
          this.minDate.setValue(
            originDateTime.minDate
              ? DateTime.fromISO(originDateTime.minDate)
              : null
          );
        }
        if (
          originDateTime.maxDate !==
          (this.maxDate.value ? this.maxDate.value.toUTC().toISO() : undefined)
        ) {
          this.maxDate.setValue(
            originDateTime.maxDate
              ? DateTime.fromISO(originDateTime.maxDate)
              : null
          );
        }
      });

    this.contentSummaryStoreService.onContentSummaryPreviewItem({
      contentItem: {} as IContentSummaryItem,
    });
  }

  public onResetFilters(): void {
    this.minDate.setValue(null);
    this.maxDate.setValue(null);
    this.contentSummaryStoreService.onResetFilters();
  }

  public onToggleColumnFilters(
    columnId: string,
    showAllFilters: boolean,
    isChecked: boolean
  ): void {
    this.announcer.announce(
      `All checkbox ${isChecked ? "checked" : "not checked"}`
    );
    this.contentSummaryStoreService.onToggleColumnFilters({
      columnId,
      showAllFilters,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onToggleFilter(columnId: string, filterOption: any): void {
    this.announcer.announce(
      `${filterOption.filterDisplayText} checkbox ${
        filterOption.enabled ? "not checked" : "checked"
      }`
    );
    this.contentSummaryStoreService.onToggleFilter({
      columnId,
      filterText: filterOption.filterText,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public trackById = (index: number, column: any): string => column.id;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public trackByText = (index: number, filterOption: any): string =>
    filterOption.filterDisplayText;

  public updateMaxDate(): void {
    const value = this.maxDate.value
      ? this.maxDate.value.toUTC().toISO()
      : undefined;
    if (this.originDateTime.maxDate !== value) {
      this.contentSummaryStoreService.onToggleDateFilter({
        columnId: "originDateTime",
        dateFilter: {
          maxDate: value,
        },
      });
    }
  }

  public updateMinDate(): void {
    const value = this.minDate.value
      ? this.minDate.value.toUTC().toISO()
      : undefined;
    if (this.originDateTime.minDate !== value) {
      this.contentSummaryStoreService.onToggleDateFilter({
        columnId: "originDateTime",
        dateFilter: {
          minDate: value,
        },
      });
    }
  }
}
