import { CodeTableCode } from "@shared/models";

export function displayDescription(code: string, codeArray: CodeTableCode[]) {
  if (!code) {
    return "";
  }
  const matchingCode = findCodeInArray(
    codeArray,
    matchCode(code),
    (codes) => codes.description
  );

  return matchingCode.length > 0 ? matchingCode : code;
}

export function matchCode<T>(
  valueToMatch: string
): (value: CodeTableCode, index: number, obj: T[]) => boolean {
  return (codes) => codes.code.toLowerCase() === valueToMatch.toLowerCase();
}

export function findCodeInArray<T>(
  codeArray: T[],
  matchPredicate: (value: T, index: number, obj: T[]) => boolean,
  displayFunc: (value: T) => string
) {
  if (!codeArray) {
    return "";
  }

  const foundCode = codeArray.find(matchPredicate);
  return foundCode ? displayFunc(foundCode) : "";
}

export function getLiabilityLimitsText(value: string): string {
  switch (value) {
    case "Y":
      return "Yes";
    case "N":
      return "No";
    default:
      return "Unknown";
  }
}
