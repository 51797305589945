import { Pipe, PipeTransform } from "@angular/core";
import { dateDisplayFormat } from "@modules/shared/material.module";
import { DateTime } from "luxon";

@Pipe({
  name: "claTimezoneDate",
})
export class TimezoneDatePipe implements PipeTransform {
  transform(
    value: Date | string | number,
    format: string = dateDisplayFormat.luxon,
    timeZone?: string
  ): string {
    if (!value) {
      return "";
    }

    timeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    return typeof value === "string"
      ? DateTime.fromISO(value).setZone(timeZone).toFormat(format)
      : typeof value === "number"
      ? DateTime.fromMillis(value).setZone(timeZone).toFormat(format)
      : DateTime.fromJSDate(value).setZone(timeZone).toFormat(format);
  }
}
