/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable functional/immutable-data */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from "@angular/core";
import { loggingConstants } from "@core/services/default-error/default-error.service";
import { AuthenticationService } from "@modules/core/services/authentication";
import { UserContextService } from "@modules/core/services/user-context";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import {
  ConfigurationInfo,
  ConfigurationService,
} from "@pgr-cla/cla-configuration";
import { LoggedMessageLevel } from "@pgr-cla/cla-logging";
import { SplunkService } from "@pgr-cla/cla-splunk";
import { WindowService } from "@pgr-cla/cla-window";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { first, tap, withLatestFrom } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { SplunkBuilder } from "../splunk-builder";

@Injectable()
export class TNCLossViewerSplunkLoggingService {
  config$: Observable<ClaimsSummaryConfigInfo>;
  token$: Observable<string>;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly configService: ConfigurationService,
    private readonly userContextService: UserContextService,
    private readonly _windowService: WindowService,
    private readonly splunkService: SplunkService,
    private readonly splunkBuilder: SplunkBuilder
  ) {
    this.token$ = this.authService.token$.pipe(
      first((token: string) => !!token)
    );

    this.config$ = this.configService.configuration$.pipe(
      first((config: ClaimsSummaryConfigInfo) => !!config)
    );
  }

  log(response: any): void {
    this.configService.configuration$
      .pipe(
        withLatestFrom(this.config$, this.token$),
        tap(
          ([info, config, token]: readonly [
            ConfigurationInfo,
            ClaimsSummaryConfigInfo,
            string
          ]) => {
            const entry = this.populateLogEntry(response, config.environment);
            if (config.isSplunkEnabled) {
              this.splunkService.log(entry, token);
            }
            if (config.environment === "Development") {
              // eslint-disable-next-line no-console
              console.log(`Splunk error: `, entry);
            }
          }
        )
      )
      .subscribe();
  }

  logInfo(msgTxt: string, msgDtlTxt: string): void {
    this.configService.configuration$
      .pipe(
        withLatestFrom(this.config$, this.token$),
        tap(
          ([info, config, token]: readonly [
            ConfigurationInfo,
            ClaimsSummaryConfigInfo,
            string
          ]) => {
            const entry = {
              // get all the meta from the error entry
              ...this.populateLogEntry(null, config.environment),
              // override shared values for info message
              ...this.splunkBuilder.buildInfo(msgTxt, msgDtlTxt),
            };
            if (config.isSplunkEnabled) {
              this.splunkService.log(entry, token);
            }
            if (config.environment === "Development") {
              // eslint-disable-next-line no-console
              console.log(`Splunk info: `, entry);
            }
          }
        )
      )
      .subscribe();
  }

  populateLogEntry(response: any, environment: string): any {
    const logEntry = {
      InstrumentationLogDateTime: DateTime.utc().toISO(),
      unqMsgId: uuidv4(),
      appnam: "TNC LossViewer Web",
      cmnSchmVerNbr: "V0301",
      LoggingComputerSystemName: "ClientDevice",
      appEnvrnNam: environment,
      errCd: loggingConstants.genericErrCd,
      msgTxt: loggingConstants.genericMsgTxt,
      msgDtlTxt: "",
      stkTrcTxt: "",
      URLPthTxt: this._windowService.url,
      userId: this.userContextService.getUserProfile()?.uid ?? "",
      logLvlNam: LoggedMessageLevel.Error,
    };

    if (response) {
      logEntry.errCd =
        response.error?.errorCode ?? loggingConstants.genericErrCd;
      logEntry.msgTxt = response.attributeErrors?.length
        ? response.attributeErrors[0].attributeDisplayMessage
        : response.error?.baseErrorMessage ??
          response.error?.developerMessage ??
          loggingConstants.genericMsgTxt;
      logEntry.msgDtlTxt = response.message ?? "";
      logEntry.stkTrcTxt = response.error?.stackTrace ?? "";
    }

    return logEntry;
  }
}
