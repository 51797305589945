/* eslint-disable @typescript-eslint/typedef */
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { first } from "rxjs/operators";
import { DefaultErrorService } from "../default-error/default-error.service";
import { TNCLossViewerSplunkLoggingService } from "../logging";
import { NetworkErrorService } from "../network-error/network-error.service";

@Injectable()
export class TNCLossViewerErrorHandler implements ErrorHandler {
  private config: ClaimsSummaryConfigInfo;

  constructor(
    private configService: ConfigurationService,
    private router: Router,
    private networkErrorService: NetworkErrorService,
    private defaultErrorService: DefaultErrorService,
    private tncLossViewerSplunkLoggingService: TNCLossViewerSplunkLoggingService
  ) {
    this.configService.configuration$
      .pipe(
        first(
          (config: ClaimsSummaryConfigInfo) =>
            config !== undefined && config !== null
        )
      )
      .subscribe((config: ClaimsSummaryConfigInfo) => {
        this.config = config;
      });
  }

  handleError(error: Error): void {
    let errorHandled = false;
    const isDev = this.config?.environment === "Development";
    if (isDev) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    this.tncLossViewerSplunkLoggingService.log(error);

    if (error instanceof HttpErrorResponse) {
      if (this.config.techDiffUnhandledExceptions) {
        this.router.navigate(["/techdiff"]);
      }
    } else {
      [
        (x: Error | undefined) => this.networkErrorService.handle(x),
        (x: Error | undefined) => this.defaultErrorService.handle(x),
      ].forEach((handler) => {
        if (!errorHandled) {
          const handlerResult = handler(error);
          errorHandled = !handlerResult;
        }
      });
    }
  }
}
