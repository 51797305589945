<button
  mat-icon-button
  class="mr-bl-1"
  aria-label="refresh"
  (click)="refreshContentItems()"
  data-pgr-id="btnRefreshContentItems"
  [cuiInteractionEvent]="
    contentSummaryEventService.clickAction(actionNames.refresh)
  "
  color="primary"
  data-pgr-id="btnRefreshEff"
>
  <mat-icon>refresh</mat-icon>
</button>
