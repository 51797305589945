import { Feature } from "@modules/feature/models/feature";
import { Action, createReducer, on } from "@ngrx/store";

import { ClaimInfo } from "../../models/feature/claim-info";
import { FeatureListForm } from "../../models/feature/feature-list";
import { listActions } from "../actions";

export interface State {
  claimInfo: ClaimInfo | null;
  features: FeatureListForm[] | null | Feature[];
  claimNumber: string | null;
}

export const initialState: State = {
  claimInfo: null,
  features: null,
  claimNumber: null,
};

const listReducer = createReducer(
  initialState,
  on(listActions.getClaimFeaturesWithPreReqSuccess, (state, action) => ({
    ...state,
    claimNumber: action.claimNumber,
    claimInfo: action.claimInfo,
    features: action.features,
  })),
  on(listActions.clearReq, () => ({
    ...initialState,
  })),
  on(listActions.refreshClaimInfoAndFeatures, (state, action) => ({
    ...state,
    claimInfo: action.claimInfo,
    features: action.features,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return listReducer(state, action);
}
