import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { PolicyVehicle } from "../../models/policy-vehicle";
import { Vehicle } from "../../models/vehicle";

@Component({
  selector: "cla-vehicle",
  templateUrl: "./vehicle.component.html",
  styleUrls: ["./vehicle.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleComponent {
  @Input() set vehicle(vehicle: PolicyVehicle) {
    this.name = this.getVehicleName(vehicle);
    this.vinNumber = vehicle.vin;
  }
  name = "";
  vinNumber = "";

  private currentYear = parseInt(
    new Date().getFullYear().toString().substr(-2),
    10
  );

  getVehicleName(vehicle: Vehicle): string {
    let make = vehicle.make;
    let model = vehicle.model;
    if (!make) {
      make = "Unknown";
    }
    if (!model) {
      model = "Unknown";
    }
    return `${this.formatVehicleYear(vehicle.year)} ${make} ${model}`;
  }

  formatVehicleYear(year: string): number {
    const vehicleYear = parseInt(year, 10);
    let formattedYear = vehicleYear;
    if (vehicleYear < 1900) {
      if (vehicleYear <= this.currentYear + 1) {
        formattedYear = vehicleYear + 2000;
      } else {
        formattedYear = vehicleYear + 1900;
      }
    }
    return formattedYear;
  }
}
