export const actions: { [key: string]: string } = {
  getRepSummaries: "[Core UI Adapter API] Get Rep Summaries",
  onGetNewRepSummariesOnlyRequest:
    "[Core UI Adapter API] Get New Rep Summaries Only",
  getRepSummariesSuccess: "[Core UI Adapter API] Get Rep Summaries Success",
  getRepSummariesFailure: "[Core UI Adapter API] Get Rep Summaries Failure",
  retryGetRepSummaries: "[Core UI Adapter API] Retry Get Rep Summaries",
  getPartySummaries: "[Core UI Adapter API] Get Party Summaries",
  getPartySummariesSuccess: "[Core UI Adapter API] Get Party Summaries Success",
  getPartySummariesFailure: "[Core UI Adapter API] Get Party Summaries Failure",
  getRepairShopSummary:
    "[Claims Core UI Components Adapter API] Get Repair Shop Summary",
  getRepairShopSummaries:
    "[Claims Core UI Components Adapter API] Get Repair Shop Summaries",
  getRepairShopSummariesCanceled:
    "[Claims Core UI Components Adapter API] Get Repair Shop Summaries Canceled",
  getRepairShopSummariesSuccess:
    "[Claims Core UI Components Adapter API] Get Repair Shop Summaries Success",
  getRepairShopSummariesFailure:
    "[Claims Core UI Components Adapter API] Get Repair Shop Summaries Failure",
  retryGetRepairShopSummaries:
    "[Claims Core UI Components Adapter API] Retry Get Repair Shop Summaries",
};
