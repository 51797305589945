import { Action, createReducer, on } from "@ngrx/store";

import { SubrogationSummaryCodes } from "@modules/subrogation-summary/models/subrogation-summary-codes.interface";
import * as subrogationSummaryCodesActions from "../actions/subrogation-summary-codes.actions";

export type SubrogationSummaryCodesState = SubrogationSummaryCodes;

const initialState: SubrogationSummaryCodesState = {
  referralTypes: [],
  fileTypes: [],
  claimantCarriers: [],
  deductibleStatusCodes: [],
  countryCodes: [],
  states: [],
  otherPhoneTypes: [],
  partySex: [],
  preferredContact: [],
  liabilityDecisionType: [],
  collectibleOverrideReasonCodes: [],
  outOfPocketExpenseType: [],
};

export function reducer(state: SubrogationSummaryCodesState, action: Action) {
  return myReducer(state, action);
}

const myReducer = createReducer(
  initialState,
  on(
    subrogationSummaryCodesActions.loadSubrogationSummaryCodesSucceeded,
    (state, action) => ({ ...action.subrogationSummaryCodes })
  ),
  on(
    subrogationSummaryCodesActions.loadSubrogationSummaryCodesFailed,
    () => initialState
  )
);
