import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";

import { TableRowSelectHandlerComponent } from "@modules/shared/components/table-row-select-handler";
import { TableDataService } from "@modules/shared/services";
import { AppState } from "@store";
import { PolicyDriver } from "../../models/policy-driver";
import * as PolicyActions from "../../state/policy/policy.actions";

@Component({
  selector: "cla-policy-drivers",
  templateUrl: "./policy-drivers.component.html",
  styleUrls: ["./policy-drivers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TableDataService],
})
export class PolicyDriversComponent
  extends TableRowSelectHandlerComponent
  implements OnChanges
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public dataSource: MatTableDataSource<any>;
  readonly displayedColumns: string[] = [
    "driverName",
    "relation",
    "dateOfBirth",
    "gender",
    "licenseNumber",
    "licenseState",
    "status",
    "filing",
  ];

  @Input() policyDrivers: PolicyDriver[];
  @Input() claimNumber: string;
  @Input() shouldHideMasking = false;

  policyDriversHeader = "0 Policy Drivers";

  selection = new SelectionModel<PolicyDriver>(true, []);

  private _row: any;
  isDlnLoading: boolean = false;

  constructor(
    public tableDataService: TableDataService,
    private store$: Store<AppState>
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.policyDrivers && this.policyDrivers.length > 0) {
      if (this.policyDrivers.length === 1) {
        this.policyDriversHeader = "1 Policy Driver";
      } else {
        this.policyDriversHeader = `${this.policyDrivers.length} Policy Drivers`;
      }

      this.setupDataSource();
    }
  }

  setupDataSource() {
    this.tableDataService.initDataSource(
      new MatTableDataSource<PolicyDriver>(),
      undefined,
      this.paginator
    );

    this.tableDataService.dataSource.data = this.policyDrivers;
  }

  getFormattedDriverName(driver: PolicyDriver) {
    if (driver.partyName) {
      let driverName = driver.partyName;
      if (!driver.businessIndicator) {
        const partyName = driver.partyName;
        const firstName = partyName.substring(20, 35).trim();
        let middleInitial = partyName.substring(35, 36).trim();
        const lastName = partyName.substring(0, 20).trim();
        const suffix = partyName.substring(36, 39).trim();
        if (middleInitial) {
          middleInitial = middleInitial.includes(".")
            ? `${middleInitial}`
            : `${middleInitial}`;
        }
        if (suffix) {
          driverName = `${lastName}, ${firstName} ${middleInitial}, ${suffix}`;
        } else {
          driverName = `${lastName}, ${firstName} ${middleInitial}`;
        }
      }
      return driverName.trim();
    }
    return "";
  }
}
