import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

function getLocalStorage(): Storage {
  return localStorage;
}

@Injectable({ providedIn: "root" })
export class LocalStorageRefService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  get localStorage(): Storage {
    if (isPlatformBrowser(this.platformId)) {
      return getLocalStorage();
    } else {
      // eslint-disable-next-line functional/no-throw-statements
      throw new Error("Local storage is not available in the current platform");
    }
  }
}
