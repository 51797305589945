import { Pipe, PipeTransform } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppPathService } from "@modules/core";
import { EmbeddedParameter } from "@modules/shared";
import { WindowService } from "@pgr-cla/cla-window";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
  name: "claEmbeddedParameters",
})
export class EmbeddedParametersPipe implements PipeTransform {
  constructor(
    private activatedRoute: ActivatedRoute,
    private appPathService: AppPathService,
    private windowService: WindowService
  ) {}
  public transform(path$: Observable<string>): Observable<string> {
    return path$.pipe(map((path: string) => this.appendFlag(path)));
  }

  private appendFlag(path: string): string {
    const currentRoute = this.activatedRoute?.snapshot?.url?.join("");
    let params = this.activatedRoute.snapshot.data["params"];
    const state = this.windowService.window.history.state.params;
    if (!params && state) {
      params = state as EmbeddedParameter[];
    }
    if (currentRoute && currentRoute.indexOf("salvage") != -1) {
      if (!params) {
        const storedParams = localStorage.getItem(currentRoute + "Params");
        if (storedParams) {
          params = JSON.parse(storedParams);
        }
      } else {
        localStorage.setItem(currentRoute + "Params", JSON.stringify(params));
      }
    }
    return this.appPathService.appendSearchParams(path, params);
  }
}
