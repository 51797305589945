import { Injectable } from "@angular/core";
import { AdapterApiService } from "@modules/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { ClaimMgmtConfigInfo } from "../../models/core/claim-mgmt-config-info";

@Injectable()
export class CoreUiAdapterApiService {
  constructor(
    private api: AdapterApiService,
    private config: ConfigurationService
  ) {}

  public get<T>(path: string): Observable<T> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimMgmtConfigInfo) => config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.api.get<T>(config.coreUiAdapterApiUrl, path);
      })
    );
  }
}
