import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";
import { AppPathService } from "@core/services";
import {
  ActivityWebEventService,
  LeftRailResizeService,
  RightRailResizeService,
} from "@modules/claims-landing/services";
import { WindowManagementService } from "@pgr-cla/cla-window";

import { claimSubHeaderAnimations } from "./claim-sub-header.animations";

@Component({
  selector: "cla-claim-sub-header",
  templateUrl: "claim-sub-header.component.html",
  styleUrls: ["./claim-sub-header.component.scss"],
  animations: [claimSubHeaderAnimations.transform],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimSubHeaderComponent {
  private readonly claimNotesListChildWindowId = "claim-notes-list-child";
  public isActivityFeedPilot: boolean;

  @Output() toggleLeftRail: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleNotes: EventEmitter<boolean> = new EventEmitter();
  @Output() notesOpenedInNew: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public appPathService: AppPathService,
    public leftRailResizeService: LeftRailResizeService,
    public rightRailResizeService: RightRailResizeService,
    private windowManagementService: WindowManagementService,
    private activityWebEventService: ActivityWebEventService
  ) {}

  openNotesInNew(path: string): void {
    this.windowManagementService.openChildWindow(
      this.claimNotesListChildWindowId,
      path
    );
    this.activityWebEventService.notesListWindowOpened(
      this.claimNotesListChildWindowId
    );
    this.notesOpenedInNew.emit();
  }

  onNotesToggle(): void {
    if (
      this.windowManagementService.isChildWindowOpen(
        this.claimNotesListChildWindowId
      )
    ) {
      this.windowManagementService.focusChildWindow(
        this.claimNotesListChildWindowId
      );
    } else {
      this.toggleNotes.emit();
    }
  }
}
