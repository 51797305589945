import { Injectable } from "@angular/core";
import { WorkItemsApiService } from "@modules/core";
import { PaymentDetailsLambdaInfo } from "@modules/payments/models";
import { Observable } from "rxjs";

@Injectable()
export class PaymentsApiService {
  constructor(private workItemsApiService: WorkItemsApiService) {}

  public getPaymentDetailsTable(
    claimNumber: string
  ): Observable<PaymentDetailsLambdaInfo[]> {
    return this.workItemsApiService.get<PaymentDetailsLambdaInfo[]>(
      `/lossviewerapi/v1/payments/${claimNumber}/getpaymentdetails`
    );
  }
}
