export enum ClaimStatus {
  LIST = "1",
  LSTCLS = "2",
  READY = "3",
  OPEN = "4",
  ACTIVE = "5",
  INACTV = "6",
  ARCHIV = "7",
  CANCEL = "8",
  REOPEN = "9",
  PRELIMINARY = "0",
}

export const claimStatusDesc = new Map<string, string>()
  .set(ClaimStatus.LIST, "READY TO ASSIGN")
  .set(ClaimStatus.LSTCLS, "CLOSED WITHOUT ASSIGNMENT")
  .set(ClaimStatus.READY, "READY TO ASSIGN")
  .set(ClaimStatus.OPEN, "OPEN")
  .set(ClaimStatus.ACTIVE, "ACTIVE")
  .set(ClaimStatus.INACTV, "CLOSED")
  .set(ClaimStatus.ARCHIV, "ARCHIVED")
  .set(ClaimStatus.CANCEL, "CANCELLED")
  .set(ClaimStatus.REOPEN, "OPEN")
  .set(ClaimStatus.PRELIMINARY, "PRELIMINARY");
