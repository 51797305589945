import { pickBy } from "lodash";
import {
  Filter,
  FilterOption,
  PaymentDetailsLambdaInfo,
  PaymentsState,
} from "../models";
import { FilterType } from "../models/filter-type";

export enum SPINNERID {
  ERROR = "error",
}

const filterableProperties: string[] = [
  "name",
  "exposure",
  "invoiceAmount",
  "invoiceNumber",
  "invoiceType",
  "invoiceStatus",
  "paymentAmount",
  "paymentNumber",
  "paymentType",
  "paymentStatus",
  "paymentIssued",
];

export function getFilteredPayments({
  payments,
  filters,
}: PaymentsState): PaymentDetailsLambdaInfo[] | undefined {
  const filteredPayments: PaymentDetailsLambdaInfo[] = (
    deepCopy(payments) || []
  ).filter((item: PaymentDetailsLambdaInfo) => {
    let allFiltersMatch = true;

    filterableProperties.forEach((columnKey) => {
      if (filters[columnKey]) {
        const { appliedFilters } = filters[columnKey];
        const columnContents = [item[columnKey]];
        if (
          appliedFilters &&
          appliedFilters.length &&
          columnContents.every(
            (cellItem) => appliedFilters.indexOf(cellItem) === -1
          )
        ) {
          allFiltersMatch = false;
        }
      }
    });

    return allFiltersMatch;
  });

  return filteredPayments;
}

export function getFilters(
  { columns, filters }: PaymentsState,
  payload?: PaymentDetailsLambdaInfo[] | null
): any {
  const newFilters = deepCopy(filters);
  if (payload) {
    columns.forEach((column) => {
      if (!column.isFilterable) {
        return;
      }
      const scopedFilters = pickBy(
        filters,
        (value: any, key: string) => key !== column.id
      );

      const scopedContentItems: PaymentDetailsLambdaInfo[] =
        getFilteredPayments({
          payments: payload,
          filters: scopedFilters,
        } as PaymentsState) || [];

      if (newFilters[column.id].filterOptions) {
        // eslint-disable-next-line functional/immutable-data
        newFilters[column.id].scopedTotal = scopedContentItems.length;
        // eslint-disable-next-line functional/immutable-data
        newFilters[column.id].filterOptions = getFilterOptions(
          scopedContentItems,
          column.id,
          filters[column.id]
        );
      }
    });
  }
  return newFilters;
}

export function getFilterOptions(
  paymentsList: PaymentDetailsLambdaInfo[],
  columnId: string,
  filter: Filter
): FilterOption[] {
  const filterOptions: any = {};
  const appliedFilters: FilterOption[] = filter.appliedFilters;

  paymentsList.forEach((payment: PaymentDetailsLambdaInfo) => {
    const filterableContent: string[] = [payment[columnId]].map(
      (column: string) => column
    );
    const displayText: string[] = [payment[columnId] as string];
    filterableContent.forEach((filterText: string, idx: number) => {
      if (filterOptions[filterText]) {
        // eslint-disable-next-line functional/immutable-data
        filterOptions[filterText].count++;
      } else {
        // eslint-disable-next-line functional/immutable-data
        filterOptions[filterText] = buildFilterOption(
          filterText,
          undefined,
          displayText && displayText[idx]
        );
      }
    });
  });

  appliedFilters.forEach((filter: any) => {
    if (!filterOptions[filter]) {
      // eslint-disable-next-line functional/immutable-data
      filterOptions[filter] = buildFilterOption(filter, 0);
    }
  });

  const result: FilterOption[] = Object.keys(filterOptions)
    .map((key: string) => filterOptions[key])
    .sort((a: any, b: any) => {
      const result: number = compare(
        typeof a.filterText === "string"
          ? a.filterText.toUpperCase()
          : a.filterText,
        typeof b.filterText === "string"
          ? b.filterText.toUpperCase()
          : b.filterText,
        true
      );
      return result;
    });

  return result;
}

export function buildFilterOption(
  filterText: string,
  count: number = 1,
  displayText?: string | null
): any {
  const filterDisplayText: string = displayText || filterText || "(Blank)";
  return { filterDisplayText, filterText, count };
}

export function filterItems(state: PaymentsState): any {
  const filters = deepCopy(state.filters);
  const newFilters = getFilters({ ...state, filters }, state.payments);
  const paymentsList = getFilteredPayments({
    ...state,
    filters,
  });

  return {
    paymentsList,
    filters: newFilters,
  };
}

export function deepCopy<T>(value: T): T {
  if (value === undefined) return value;
  return JSON.parse(JSON.stringify(value));
}

export function compare(
  a: number | string | null,
  b: number | string | null,
  isAsc: boolean
): number {
  // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
  // one value exists while the other doesn't. In this case, existing value should come first.
  // This avoids inconsistent results when comparing values to undefined/null.
  // If neither value exists, return 0 (equal).
  let comparatorResult: number = 0;
  if (a != null && b != null) {
    // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
    if (a > b) {
      comparatorResult = 1;
    } else if (a < b) {
      comparatorResult = -1;
    }
  } else if (a != null) {
    comparatorResult = 1;
  } else if (b != null) {
    comparatorResult = -1;
  }

  return comparatorResult * (isAsc ? 1 : -1);
}

export const filterStateDefaults: Map<FilterType, any> = new Map([
  [
    FilterType.NumberRange,
    { min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER },
  ],
  [FilterType.Select, {}],
]);

export function createFilter(type: FilterType): Filter {
  const newFilter: Filter = {
    scopedTotal: 0,
    appliedFilters: [],
    filterOptions: [],
    filterType: type,
    state: filterStateDefaults.get(type),
  };

  return newFilter;
}
