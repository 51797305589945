import { HttpErrorResponse } from "@angular/common/http";
import {
  ClaimDetails,
  ClaimSummary,
  PartyPropertyDetail,
  PolicyDriversRequest,
} from "@modules/core/models";
import { PolicyDriver } from "@modules/core/models/policy-driver.type";
import { createAction, props } from "@ngrx/store";
import { AppIntegrationType } from "./models";

export const onSetAppIntegrationType = createAction(
  "[Claims Landing] Set App Integration Type",
  props<{ appIntegrationType: AppIntegrationType }>()
);

export const onPartyPropertyDetailsRequest = createAction(
  "[Claims Landing] Get Party Property Details",
  props<{ readonly claimNumber: string }>()
);

export const onPartyPropertyDetailsReceipt = createAction(
  "[Claims Landing] Get Party Property Details Success",
  props<{ readonly payload: PartyPropertyDetail[] }>()
);

export const onPartyPropertyDetailsError = createAction(
  "[Claims Landing] Get Party Property Details Error",
  props<{ readonly error: HttpErrorResponse }>()
);

export const onClaimDetailsRequest = createAction(
  "[Claim Details] Get Claim Details",
  props<{
    readonly claimNumber: string;
    readonly redirectToClaim?: boolean;
    readonly fromClaimSearch?: boolean;
  }>()
);

export const onClaimDetailsReceipt = createAction(
  "[Claim Details] Get Claim Details Success",
  props<{ readonly payload: ClaimDetails }>()
);

export const onClaimDetailsError = createAction(
  "[Claim Details] Get Claim Details Error",
  props<{ readonly error: HttpErrorResponse }>()
);

export const onClaimSummaryRequest = createAction(
  "[Claim Summary] Get Claim Summary",
  props<{
    readonly claimNumber: string;
  }>()
);

export const onClaimSummaryReceipt = createAction(
  "[Claim Summary] Get Claim Summary Success",
  props<{ readonly payload: ClaimSummary }>()
);

export const onClaimSummaryError = createAction(
  "[Claim Summary] Get Claim Summary Error",
  props<{ readonly error: HttpErrorResponse }>()
);

export const onGetPolicyDriversRequest = createAction(
  "[Policy Details API] Get Policy Drivers",
  props<{
    readonly payload: PolicyDriversRequest;
  }>()
);

export const onGetPolicyDriversResponse = createAction(
  "[Policy Details API] Get Policy Drivers Success",
  props<{
    readonly payload: PolicyDriver[];
  }>()
);

export const onGetPolicyDriversError = createAction(
  "[Policy Details API] Get Policy Drivers Error",
  props<{
    readonly error: HttpErrorResponse;
  }>()
);

export const setClaimNumber = createAction(
  "[Claims Landing] Set Claim Number",
  props<{ readonly claimNumber: string }>()
);

export const setSuccessBannerMessage = createAction(
  "[Claim Details] Set Success Banner Message",
  props<{ message: string }>()
);

export const setErrorBannerMessages = createAction(
  "[Claim Details] Set Error Banner Messages",
  props<{ messages: string[] }>()
);

export const setWarningBannerMessage = createAction(
  "[Claim Details] Set Warning Banner Message",
  props<{ message: string; isDismissable: boolean }>()
);

export const onClaimReportedViaRequest = createAction(
  "[Claim Details] Get Claim Reported VIA",
  props<{
    readonly claimNumber: string;
    readonly claimReportedDate: string;
  }>()
);

export const onClaimReportedViaReceipt = createAction(
  "[Claim Details] Get Claim Reported VIA Success",
  props<{ readonly payload: string }>()
);

export const onClaimReportedViaError = createAction(
  "[Claim Details] Get Claim Reported VIA Error",
  props<{ readonly error: HttpErrorResponse }>()
);

export const setPageScrollingEnabled = createAction(
  "[Claims Landing] Page Scrolling Enabled",
  props<{ isEnabled: boolean }>()
);
