import { Component, Input } from "@angular/core";
import {
  displayDescription,
  getLiabilityLimitsText,
} from "@modules/subrogation-summary/utilities/display-codes.utilities";
import {
  AdverseParty,
  SubrogationSummaryCodes,
} from "@subrogation-summary/models";

@Component({
  selector: "cla-adverse-party-details",
  templateUrl: "./adverse-party-details.component.html",
  styleUrls: ["./adverse-party-details.component.scss"],
})
export class AdversePartyDetailsComponent {
  @Input() public adverseParty: AdverseParty;
  @Input() public detailCodes: SubrogationSummaryCodes;
  @Input() public claimNumber: number;

  displayDescription = displayDescription;
  getLiabilityLimitsText = getLiabilityLimitsText;
}
