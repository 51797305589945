import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { initialState } from "../constants/coverage-store.constants";
import { CoverageState } from "../models/coverage-state.interface";

import { CVQ } from "../models/cvq.interface";

import * as actions from "./coverage.action";

const COVERAGE_REDUCER: ActionReducer<CoverageState, Action> = createReducer(
  initialState,
  on(actions.onClaimLevelDataReceived, (state: CoverageState, action) => ({
    ...state,
    claimLevel: action.claimData,
  })),
  on(actions.onCVQRequested, (state: CoverageState) => ({
    ...state,
    isRequestInFlight: true,
    cvqRequestError: false,
  })),
  on(actions.onCVQRequestedError, (state: CoverageState) => ({
    ...state,
    isRequestInFlight: false,
    cvqRequestError: true,
  })),
  on(actions.onCVQReceived, (state: CoverageState, action) => ({
    ...state,
    cvqs: action.CVQList,
    isRequestInFlight: false,
    policyProductCategoryCode: undefined,
    policyStateCode: undefined,
    lossDateTime: undefined,
  })),
  on(actions.onResolveCVQsRequested, (state: CoverageState) => ({
    ...state,
    cvqResolve: {
      ...state.cvqResolve,
      isSuccess: undefined,
      requestInFlight: true,
    },
  })),
  on(actions.onResolveCVQsReceived, (state: CoverageState, action) => ({
    ...state,
    cvqs: state.cvqs!.map((cvq: CVQ) => {
      const foundCVQs = action.cvqs.filter(
        (newCvq: CVQ) => newCvq.id === cvq.id
      );
      return foundCVQs.length > 0 ? foundCVQs[0] : cvq;
    }),
    cvqResolve: {
      ...state.cvqResolve,
      isSuccess: true,
      requestInFlight: false,
      errorMessage: undefined,
    },
  })),
  on(actions.onResolveCVQsFailed, (state: CoverageState, action) => ({
    ...state,
    cvqResolve: {
      ...state.cvqAdd,
      isSuccess: false,
      requestInFlight: false,
      errorMessage: action.errorMessage,
    },
  })),
  on(actions.onResolveCVQsCompleted, (state: CoverageState) => ({
    ...state,
    cvqResolve: {
      ...state.cvqResolve,
      isSuccess: undefined,
      requestInFlight: false,
      errorMessage: undefined,
    },
  })),
  on(
    actions.onUnderwritingMemoCategoryTypeReceived,
    (state: CoverageState, action) => ({
      ...state,
      uwMemoCategory: action.categorySelected,
      uwMemoType: action.typeSelected,
      isRequestInFlight: false,
    })
  ),
  on(
    actions.onUnderwritingMemoSubmitReceived,
    (state: CoverageState, action) => ({
      ...state,
      isRequestInFlight: false,
      isMemoSubmitted: true,
    })
  ),
  on(
    actions.onUnderwritingMemoSubmitRequested,
    (state: CoverageState, action) => ({
      ...state,
      isRequestInFlight: true,
    })
  ),
  on(actions.onCVQAddRequested, (state: CoverageState) => ({
    ...state,
    cvqAdd: {
      ...state.cvqAdd,
      isSuccess: undefined,
      requestInFlight: true,
    },
  })),
  on(actions.onCVQAddReceived, (state: CoverageState, action) => ({
    ...state,
    cvqs: [action.cvq, ...state.cvqs!],
    cvqAdd: {
      ...state.cvqAdd,
      isSuccess: true,
      requestInFlight: false,
      errorMessage: undefined,
    },
  })),
  on(actions.onCVQAddFailed, (state: CoverageState, action) => ({
    ...state,
    cvqAdd: {
      ...state.cvqAdd,
      isSuccess: false,
      requestInFlight: false,
      errorMessage: action.errorMessage,
    },
  })),
  on(actions.onCVQAddCompleted, (state: CoverageState) => ({
    ...state,
    cvqAdd: {
      ...state.cvqAdd,
      isSuccess: undefined,
      requestInFlight: false,
      errorMessage: undefined,
    },
  })),
  on(actions.onRepReceived, (state: CoverageState, action) => ({
    ...state,
    rep: action.rep,
  })),
  on(actions.onUWMemoClaimPolicyRecieved, (state: CoverageState, action) => ({
    ...state,
    isPolicyAttached: action.claimPolicy.isPolicyAttached,
    policyNumber: action.claimPolicy.policyNumber,
    policyProductCategoryCode: action.claimPolicy.productCategoryCode,
    isRequestInFlight: false,
  })),
  on(actions.onUWMemoClaimPolicyRequested, (state: CoverageState) => ({
    ...state,
    isRequestInFlight: true,
  })),
  on(actions.onUWMemoDataRecieved, (state: CoverageState, action) => ({
    ...state,
    policyProductCategoryCode: action.uwMemoData.productStateCode.productCode,
    policyStateCode: action.uwMemoData.productStateCode.stateCode,
    policyNormalizedProductCategoryCode:
      action.uwMemoData.productStateCode.normalizedProductCategoryCode,
    lossDateTime: action.uwMemoData.lossDate,
    policyVehicles: action.uwMemoData.policyVehicles,
    claimVehicles: action.uwMemoData.claimVehicles,
    policyDrivers: action.uwMemoData.policyDrivers,
    unlistedDrivers: action.uwMemoData.unlistedDrivers,
    excludedDrivers: [],
    isRequestInFlight: false,
  })),
  on(actions.onUWMemoDataRequested, (state: CoverageState) => ({
    ...state,
    isRequestInFlight: true,
  })),
  on(actions.onCvqAddNoteRequested, (state: CoverageState) => ({
    ...state,
    isRequestInFlight: true,
  })),
  on(actions.onCvqAddNoteReceived, (state: CoverageState, action) => ({
    ...state,
    isRequestInFlight: false,
  }))
);

export function coverageReducer(state: CoverageState, action: any) {
  return COVERAGE_REDUCER(state, action);
}
