export const formatPartyName = (
  firstName: string | undefined,
  middleInitial: string | undefined,
  lastName: string | undefined,
  suffix?: string | undefined
) => {
  let formattedPartyName = ``;
  if (!firstName && !middleInitial && !lastName && !suffix) {
    return `-`;
  }
  if (middleInitial) {
    middleInitial = middleInitial.includes(".")
      ? `${middleInitial}`
      : `${middleInitial}`;
  }
  if (suffix) {
    formattedPartyName = `${lastName}, ${firstName} ${middleInitial}, ${suffix}`;
  } else {
    formattedPartyName = `${lastName}, ${firstName} ${middleInitial}`;
  }
  return formattedPartyName.trim();
};
