import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claEmptyDash",
})
export class EmptyDashPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) {
      return "—";
    }
    return value;
  }
}
