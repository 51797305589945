import { NgModule } from "@angular/core";
import { SharedModule } from "@modules/shared";

import { AttorneyRoutingModule } from "./attorney.routing.module";
import {
  DeleteAttorneyDialogComponent,
  InlineAlertComponent,
} from "./components";
import { AttorneyService } from "./services";

@NgModule({
  imports: [SharedModule, AttorneyRoutingModule],
  declarations: [InlineAlertComponent, DeleteAttorneyDialogComponent],
  providers: [AttorneyService],
})
export class AttorneyModule {}
