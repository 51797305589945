export const mp3MimeTypes = [
  "audio/mp3",
  "audio/mpeg",
  "audio/mpeg3",
  "audio/mpg",
  "audio/x-mp3",
  "audio/x-mpeg",
  "audio/x-mpeg3",
  "audio/x-mpegaudio",
  "audio/x-mpg",
];
