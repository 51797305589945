import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input } from "@angular/core";

@Component({
  selector: "cla-server-error-banner",
  templateUrl: "server-error-banner.component.html",
})
export class ServerErrorBannerComponent {
  @Input() serverError: HttpErrorResponse | undefined;
  @Input() hasBottomBorder: boolean;
  @Input() showInlineErrorMessage: boolean;
}
