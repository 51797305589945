import { OutOfPocketsEffects } from "./out-of-pockets.effects";
import { RecoveryHistoryEffects } from "./recovery-history.effects";
import { SubrogationSummaryCodesEffects } from "./subrogation-summary-codes.effects";
import { SubrogationSummaryEffects } from "./subrogation-summary.effects";
import { ClaimFeatureEffects } from "./claim-feature.effects";

export const subrogationSummaryEffects = [
  OutOfPocketsEffects,
  SubrogationSummaryCodesEffects,
  SubrogationSummaryEffects,
  RecoveryHistoryEffects,
  ClaimFeatureEffects,
];
