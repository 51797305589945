import { RouterStateSnapshot } from "@angular/router";
import * as fromRouter from "@ngrx/router-store";
import { RouterStateUrl } from "./router-state-url.interface";

export class CustomRouteSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let data = {};
    const paramMap = new Map<string, string | null>([]);

    // eslint-disable-next-line no-loops/no-loops
    while (route.firstChild) {
      route = route.firstChild;
      if (route.data) data = { ...data, ...route.data };
      route.paramMap.keys.forEach((key) => {
        paramMap.set(key, route.paramMap.get(key));
      });
    }

    let params: object = {};

    Array.from(paramMap).forEach((prop) => {
      params = {
        ...params,
        [prop[0]]: prop[1],
      };
    });

    const {
      url,
      root: { queryParams },
    } = routerState;
    return { url, params, queryParams, data };
  }
}
