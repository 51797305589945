import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NotesStateService } from "@notes/state";

@Component({
  selector: "cla-note-list-subheader",
  templateUrl: "./note-list-subheader.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteListSubheaderComponent {
  constructor(public notesStateService: NotesStateService) {}

  public refreshNotes(): void {
    this.notesStateService.getNotes();
    this.notesStateService.getJournals();
  }

  public sortNotes(): void {
    this.notesStateService.toggleSortDirection();
  }
}
