import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ClaimAlert } from "@pgr-cla/core-ui-components";
import { AlertsState } from "./alerts-state.interface";

export const selectAlertsState = createFeatureSelector<AlertsState>("alerts");

export const selectAlerts = createSelector(
  selectAlertsState,
  (state: AlertsState) => state.alerts
);

export const selectNewAlerts = createSelector(
  selectAlerts,
  (alerts: ClaimAlert[]) => alerts.filter((alert) => !alert.viewed)
);
