import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { fromEvent } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
@Directive({
  selector: "[cla-click-handler]",
})
export class ClickHandlerDirective {
  @Input() public childBlacklist: string[] = [];
  @Output() public doubleClickHandled: EventEmitter<void> = new EventEmitter();
  @Output() public singleClickHandled: EventEmitter<void> = new EventEmitter();
  @Input() public threshold = 300;
  private _rowClicks = 0;

  constructor(private _el: ElementRef) {
    fromEvent(this._el.nativeElement, "click")
      .pipe(
        tap((event: Event) => {
          this.setClickCount(event);
        }),
        debounceTime(this.threshold)
      )
      .subscribe((event: Event) => {
        const composedPath = (event as any).propagationPath();
        const isChildEvent = !!composedPath.find((x: Node) => {
          return this.childBlacklist.includes((x as Node).nodeName);
        });

        if (!isChildEvent) {
          this._rowClicks === 1
            ? this.singleClickHandled.emit()
            : this.doubleClickHandled.emit();
        }

        this._rowClicks = 0;
      });
  }

  private setClickCount(event: Event) {
    const composedPath = (event as any).propagationPath();
    const isChildEvent = !!composedPath.find((x: Node) => {
      return this.childBlacklist.includes((x as Node).nodeName);
    });

    if (!isChildEvent && this._rowClicks === 1) {
      this._rowClicks = 0;
    } else {
      this._rowClicks = 1;
    }
  }
}
