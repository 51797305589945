import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";
import { ClaimFeature } from "@modules/subrogation-summary/models/";

import * as claimFeatureActions from "@modules/subrogation-summary/state/actions/claim-feature.actions";

export type ClaimFeatureState = EntityState<ClaimFeature>;

export const claimFeatureAdapter: EntityAdapter<ClaimFeature> =
  createEntityAdapter<ClaimFeature>({
    selectId: (claimFeature) => claimFeature.featureId,
  });

const initialState = claimFeatureAdapter.getInitialState();

export function reducer(state: ClaimFeatureState, action: Action) {
  return claimFeatureReducer(state, action);
}

const claimFeatureReducer = createReducer(
  initialState,
  on(
    claimFeatureActions.loadClaimFeaturesSucceeded,
    (state, { claimFeatures }) =>
      claimFeatureAdapter.setAll(claimFeatures, state)
  )
);
