import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
} from "@angular/router";
import { PermissionRouterData } from "@modules/core/models";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UserContextService } from "@modules/core/services/user-context";

@Injectable()
export class PermissionsGuardService implements CanActivate, CanLoad {
  constructor(
    private userContextService: UserContextService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    return this.hasPermissions(route);
  }

  canLoad(route: Route): boolean | Observable<boolean> {
    return this.hasPermissions(route);
  }

  private hasPermissions(route: ActivatedRouteSnapshot | Route) {
    const permissions =
      !!route && route.data
        ? (route.data["permissions"] as PermissionRouterData)
        : {};

    if (permissions.only) {
      return this.userContextService.hasPermission(permissions.only).pipe(
        tap((isAllowed) => {
          if (!isAllowed) {
            this.router.navigate(["missing-ldap"]);
          }
        })
      );
    }

    return true;
  }
}
