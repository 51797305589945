import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";

import {
  IApiErrorResponse,
  IAppState,
  IStoreEntity,
  IStoreEntityGetProps,
  IStoreEntityGettable,
  IStoreEntityMeta,
  IStoreEntityService,
  IDocSetDetailsResponse,
} from "../../../models";
import { DocSetDetailsActions } from "../../../actions";

import * as DetailsSelectors from "../../../selectors";
import { Observable } from "rxjs";

@Injectable()
export class DocSetDetailsStoreService
  implements
    IStoreEntityGettable<IDocSetDetailsResponse>,
    IStoreEntityService<IDocSetDetailsResponse>
{
  constructor(private _store$: Store<IAppState>) {}
  public getEntity(): Observable<IDocSetDetailsResponse | null> {
    return this._store$.pipe(select(DetailsSelectors.selectDetails));
  }

  public getMeta(): Observable<IStoreEntityMeta | null> {
    return this._store$.pipe(select(DetailsSelectors.selectDetailsMeta));
  }

  public getStoreEntity(): Observable<IStoreEntity<IDocSetDetailsResponse>> {
    return this._store$.pipe(select(DetailsSelectors.selectDetailsEntity));
  }

  public isInFlight(): Observable<boolean> {
    return this._store$.pipe(select(DetailsSelectors.selectDetailsInFlight));
  }

  public onError(error: IApiErrorResponse): void {
    this._store$.dispatch(DocSetDetailsActions.onError({ payload: error }));
  }
  public onGetReceived(
    getResponse: HttpResponse<IDocSetDetailsResponse>
  ): void {
    this._store$.dispatch(
      DocSetDetailsActions.onGetReceived({ payload: getResponse })
    );
  }
  public onGetRequested(props: IStoreEntityGetProps): void {
    this._store$.dispatch(DocSetDetailsActions.onGetRequested(props));
  }
}
