import { TitleCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { AgreementLookup } from "@modules/policy/models/agreement-lookup-response/agreement-lookup";
import { PolicyDetail } from "@modules/policy/models/policy-detail";

@Component({
  selector: "cla-policy-name-insured",
  templateUrl: "./policy-name-insured.component.html",
  styleUrls: ["./policy-name-insured.component.scss"],
})
export class PolicyNameInsuredComponent {
  @Input() set policyDetail(policyDetail: PolicyDetail | null) {
    if (policyDetail) {
      this.isBusiness = policyDetail.partyInfo.businessIndicator;
      if (this.isBusiness) {
        this.businessName = policyDetail.partyInfo.name;
      }
      this.cityStateZip = this.cityStateZipFormat(policyDetail);
      this.streetName = this.streetNameFormat(policyDetail);
      this.secondStreetName = this.secondStreetNameFormat(policyDetail);
      this.setPartyName(policyDetail);
      this.dbaName = this.dbaNameFormat(policyDetail);
      this.formattedPhoneNumbers = this.createPhoneNumbersList(policyDetail);
    }
  }
  @Input() set agreementLookupInput(agreementLookup: AgreementLookup | null) {
    this.formattedEmails = this.createEmailList(agreementLookup);
  }

  isBusiness = false;
  cityStateZip = "";
  streetName = "";
  secondStreetName = "";
  firstName = "";
  middleInitial = "";
  lastName = "";
  suffix = "";
  businessName = "";
  dbaName = "";
  formattedPhoneNumbers: string[];
  formattedEmails: string[];

  constructor(private titleCasePipe: TitleCasePipe) {}

  cityStateZipFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.streetName !== null &&
      policyDetail.partyInfo.streetName !== ""
    ) {
      const cityName = this.titleCasePipe.transform(
        policyDetail.partyInfo.cityName
      );
      return `${cityName}, ${policyDetail.partyInfo.stateCode} ${policyDetail.partyInfo.zipCode}`;
    }
    return "";
  }

  streetNameFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.streetName !== null &&
      policyDetail.partyInfo.streetName !== ""
    ) {
      const streetName = this.titleCasePipe.transform(
        policyDetail.partyInfo.streetName
      );
      const regexp = /[0-9]+Th/;
      const formattedStreetName = regexp.test(streetName)
        ? streetName.replace("Th", "th")
        : streetName;
      return `${formattedStreetName}<br/>`;
    }
    return "";
  }

  secondStreetNameFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.secondStreetName !== null &&
      policyDetail.partyInfo.secondStreetName !== ""
    ) {
      const secondStreetName = this.titleCasePipe.transform(
        policyDetail.partyInfo.secondStreetName
      );
      const regexp = /[0-9]+Th/;
      const formattedSecondStreetName = regexp.test(secondStreetName)
        ? secondStreetName.replace("Th", "th")
        : secondStreetName;
      return `${formattedSecondStreetName}<br/>`;
    }
    return "";
  }

  dbaNameFormat(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.dbaName !== null &&
      policyDetail.partyInfo.dbaName !== ""
    ) {
      const dbaName = this.titleCasePipe.transform(
        policyDetail.partyInfo.dbaName
      );
      return dbaName;
    }
    return "";
  }

  private setPartyName(policyDetail: PolicyDetail) {
    if (
      policyDetail.partyInfo.name !== null &&
      policyDetail!.partyInfo.name !== ""
    ) {
      this.firstName = policyDetail!.partyInfo.name.substring(20, 35).trim();
      this.middleInitial = policyDetail!.partyInfo.name
        .substring(35, 36)
        .trim();
      this.lastName = policyDetail!.partyInfo.name.substring(0, 20).trim();
      this.suffix = policyDetail!.partyInfo.name.substring(36, 39).trim();
    }
  }

  createPhoneNumbersList(policyDetail: PolicyDetail) {
    const phoneNumbers: string[] = [
      policyDetail.partyInfo.homePhoneNumber,
      policyDetail.partyInfo.businessPhoneNumber,
      policyDetail.partyInfo.otherPhoneNumber1,
      policyDetail.partyInfo.otherPhoneNumber2,
    ];
    const formattedPhoneNumbers = [];
    for (const phoneNumber of phoneNumbers) {
      if (phoneNumber !== "") {
        // eslint-disable-next-line functional/immutable-data
        formattedPhoneNumbers.push(phoneNumber);
      }
    }
    return formattedPhoneNumbers;
  }

  createEmailList(agreementLookup: AgreementLookup | null) {
    const formattedEmails = [];

    if (agreementLookup && agreementLookup.customerList) {
      const customer = agreementLookup.customerList.find(
        (c) => c.relationshipToNamedInsured === "Self"
      );
      if (customer && customer.emailList) {
        for (const email of customer.emailList) {
          if (email.emailAddress !== "") {
            // eslint-disable-next-line functional/immutable-data
            formattedEmails.push(email.emailAddress);
          }
        }
      }
    }

    return formattedEmails;
  }

  findIndex(phoneNumber: string) {
    return this.formattedPhoneNumbers.indexOf(phoneNumber) + 1;
  }

  findEmailIndex(email: string) {
    return this.formattedEmails.indexOf(email) + 1;
  }
}
