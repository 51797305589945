import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { OutOfPocket } from "@modules/subrogation-summary/models/out-of-pocket.interface";
import { SubrogationSummaryState } from "@modules/subrogation-summary/state/reducers";
import { Store, select } from "@ngrx/store";
import {
  selectFailedToLoadOutOfPockets,
  selectOutOfPockets,
} from "@subrogation-summary/state/reducers";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "cla-out-of-pockets",
  templateUrl: "./out-of-pockets.component.html",
  styleUrls: ["./out-of-pockets.component.scss"],
})
export class OutOfPocketsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() claimNumber: string;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  displayedColumns: string[] = ["date", "expenseType", "comments", "amount"];
  dataSource: MatTableDataSource<OutOfPocket> =
    new MatTableDataSource<OutOfPocket>();
  loading: boolean = false;
  showServerError: boolean = false;

  constructor(
    private store$: Store<SubrogationSummaryState>,
    private _liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.showServerError = false;
  }

  ngAfterViewInit() {
    this.store$
      .select(selectFailedToLoadOutOfPockets)
      .subscribe((failedToLoadOutOfPockets: boolean) => {
        this.showServerError = failedToLoadOutOfPockets;
      });

    this.store$
      .pipe(select(selectOutOfPockets), takeUntil(this.unsubscribeSubject$))
      .subscribe((outOfPockets: OutOfPocket[]) => {
        this.dataSource.data = outOfPockets;
        if (this.dataSource.data.length > 0) {
          this._liveAnnouncer.announce(
            `${this.dataSource.data.length} out of pocket expenses found`
          );
        }
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
