export class PolicyResourceConstant {
  static errorMsg = {
    error500:
      "An error has occurred, retry previous operation. If issue persists, contact the support center.",
  };
  static regex = {
    removeSpecialChar: /[^\w ]+/g,
  };
  static defaultErrorMessage =
    "An unexpected error occurred. Please try again.";
}
