import { moveItemInArray } from "@angular/cdk/drag-drop";
import { createReducer, on } from "@ngrx/store";

import { tassign } from "tassign";

import { deepCopy } from "../../helpers/shared";

import { ContentSummaryActions } from "../../actions/content-summary";
import { initialState } from "../../constants/content-summary";
import {
  IContentSummaryItem,
  IContentSummaryState,
} from "../../models/content-summary";

import { ContentSummaryMappers } from "../../utilities/content-summary/content-summary-mappers/content-summary-mappers";

export const contentSummaryReducer = createReducer(
  initialState,
  on(
    ContentSummaryActions.onContentDetailsError,
    (state: IContentSummaryState, action: any) => {
      const nextState: IContentSummaryState = {
        ...state,
        contentDetails: {
          error: action.error,
          inFlight: false,
          response: null,
          meta: {
            ...state.contentDetails.meta,
          },
        },
      };
      return tassign(state, nextState);
    }
  ),
  on(ContentSummaryActions.onContentDetailsReceived, (state, action) => {
    const newFilters = ContentSummaryMappers.getFilters(
      state,
      action.response.contentItems
    );
    let newContentItems = action.response.contentItems;
    if (action.response.partyPropertyInfo) {
      newContentItems = action.response.contentItems.map((x) => {
        const partyOrPropertyId = action.response.partyPropertyInfo
          ? action.response.partyPropertyInfo.partyOrPropertyId
          : undefined;
        return {
          ...x,
          partyOrPropertyName: action.response.partyPropertyInfo
            ? action.response.partyPropertyInfo.partyOrPropertyName
            : undefined,
          partyOrPropertyId: partyOrPropertyId
            ? partyOrPropertyId.toString()
            : undefined,
        };
      });
    }

    return tassign(state, {
      ...state,
      filters: newFilters,
      contentDetails: {
        ...state.contentDetails,
        response: {
          ...action.response,
          contentItems: newContentItems,
        },
        inFlight: false,
        error: null,
      },
      filteredContentItems: deepCopy(newContentItems),
      partyPropertyName: action.response.partyPropertyInfo
        ? action.response.partyPropertyInfo.partyOrPropertyName
        : undefined,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onContentDetailsRequested, (state) => {
    return tassign(state, {
      ...state,
      contentDetails: {
        ...state.contentDetails,
        inFlight: true,
      },
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onContentDetailsRefreshReceived, (state, action) => {
    const newFilters = ContentSummaryMappers.getFilters(
      state,
      action.response.contentItems
    );
    let newContentItems = action.response.contentItems;
    if (action.response.partyPropertyInfo) {
      newContentItems = action.response.contentItems.map((x) => {
        const partyOrPropertyId = action.response.partyPropertyInfo
          ? action.response.partyPropertyInfo.partyOrPropertyId
          : undefined;
        return {
          ...x,
          partyOrPropertyName: action.response.partyPropertyInfo
            ? action.response.partyPropertyInfo.partyOrPropertyName
            : undefined,
          partyOrPropertyId: partyOrPropertyId
            ? partyOrPropertyId.toString()
            : undefined,
        };
      });
    }

    const intermediateState = {
      ...state,
      filteredContentItems: deepCopy(newContentItems),
      partyPropertyName: action.response.partyPropertyInfo
        ? action.response.partyPropertyInfo.partyOrPropertyName
        : undefined,
      contentDetails: {
        ...state.contentDetails,
        error: null,
        inFlight: false,
        response: {
          ...action.response,
          contentItems: deepCopy(newContentItems),
        },
      },
      refreshInProgress: false,
      filters: newFilters,
      selectedItems: [],
    };
    const filteredItems = ContentSummaryMappers.filterItems(intermediateState);
    const newState = {
      ...intermediateState,
      filteredContentItems: filteredItems.filteredContentItems,
    };

    return tassign(state, newState as IContentSummaryState);
  }),
  on(ContentSummaryActions.onContentDetailsRefreshRequested, (state) => {
    return tassign(state, {
      ...state,
      contentDetails: {
        ...state["contentDetails"],
        inFlight: true,
      },
      refreshInProgress: true,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onContentSummaryApiError, (state, action) => {
    switch (action.requestThatErrored) {
      case "ContentSummary":
        return tassign(state, {
          ...state,
          contentDetails: {
            ...state["contentDetails"],
            inFlight: false,
          },
        } as IContentSummaryState);
      default:
        return state;
    }
  }),
  on(ContentSummaryActions.onContentSummaryFilter, (state, action) => {
    return tassign(state, {
      ...state,
      filteredContentItems: action.filteredContentItems,
      filters: action.filters,
      isLoading: false,
      filterResetInProgress: false,
    });
  }),
  on(ContentSummaryActions.onContentSummaryPreviewItem, (state, action) => {
    return tassign(state, {
      ...state,
      previewedItem: action.contentItem
        ? {
            ...action.contentItem,
          }
        : undefined,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onReorderColumns, (state, action) => {
    const reOrderedColumns = deepCopy(state.columns);
    moveItemInArray(
      reOrderedColumns,
      action.previousIndex,
      action.currentIndex
    );
    return tassign(state, {
      ...state,
      columns: reOrderedColumns,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onResetColumns, (state) => {
    const columns: any[] = deepCopy(initialState.columns);
    return tassign(state, {
      ...state,
      columns: state.contentSummaryGridFilter.isPartyPropertyView
        ? columns.filter((val) => val.title !== "Party/Property")
        : columns,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onResetFilters, (state) => {
    const filters = deepCopy(state.filters);
    Object.keys(filters).forEach((key: string) => {
      if (filters[key].appliedFilters) {
        // eslint-disable-next-line functional/immutable-data
        filters[key].appliedFilters = [];
      } else {
        // eslint-disable-next-line functional/immutable-data
        filters[key] = {};
      }
    });

    return tassign(state, {
      ...state,
      filters,
      isLoading: true,
      filterResetInProgress: true,
    });
  }),
  on(ContentSummaryActions.onContentSummarySearchRequested, (state, action) => {
    return tassign(state, {
      ...state,
      searchTerm: action.searchTerm,
      searchType: action.searchType,
      isLoading: true,
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onContentSummarySearchReceived, (state, action) => {
    // Search clears filters
    const filters = deepCopy(state.filters);
    Object.keys(filters).forEach((key: string) => {
      if (filters[key].appliedFilters) {
        // eslint-disable-next-line functional/immutable-data
        filters[key].appliedFilters = [];
      } else {
        // eslint-disable-next-line functional/immutable-data
        filters[key] = {};
      }
    });

    return tassign(state, {
      ...state,
      filters,
      searchResults: action.searchResults,
    } as IContentSummaryState);
  }),
  // set must be able to handle stale data in user settings, such as when a new column is added to grid
  // or when a column is removed / column properties change
  on(ContentSummaryActions.onSetColumns, (state, action) => {
    const initialColumns = action.isPartyPropertyView
      ? initialState.columns.filter(
          (val: any) => val.title !== "Party/Property"
        )
      : initialState.columns;
    let defaultColumns = deepCopy(initialColumns);
    let defaultColumnIds = deepCopy(initialColumns).map(
      (column: any) => column.id
    );
    const filteredColumns = action.isPartyPropertyView
      ? action.columns.filter((val) => val.title !== "Party/Property")
      : action.columns;
    const columns: any[] = filteredColumns
      .map((column: any) => {
        const defaultColumnIndex = defaultColumnIds.indexOf(column.id);
        if (defaultColumnIndex === -1) {
          return undefined;
        }
        const mergedColumn = {
          ...defaultColumns[defaultColumnIndex],
          ...column,
        };
        defaultColumns = defaultColumns.filter(
          (_, i) => i !== defaultColumnIndex
        );
        defaultColumnIds = defaultColumnIds.filter(
          (_, i) => i !== defaultColumnIndex
        );
        return mergedColumn;
      })
      .filter((column) => !!column);

    return tassign(state, {
      ...state,
      columns: [...columns, ...defaultColumns],
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onSetGridFilter, (state, action) => {
    const isPartyPropertyView = !!(action.partyId || action.propertyId);
    return tassign(state, {
      ...state,
      columns: isPartyPropertyView
        ? state.columns.filter((val: any) => val.title !== "Party/Property")
        : state.columns,
      contentSummaryGridFilter: {
        isPartyPropertyView,
        claimNumber: action.claimNumber,
        partyId: action.partyId,
        propertyId: action.propertyId,
      },
    } as IContentSummaryState);
  }),
  on(
    ContentSummaryActions.onContentSummaryToggleAllSelectedItems,
    (state, action) => {
      let newSelectedItems: string[] = deepCopy(state.selectedItems);
      (state.filteredContentItems || []).forEach(
        (item: IContentSummaryItem) => {
          const selectedItemIndex = newSelectedItems.indexOf(item.id);
          if (action.selectAllContentItems && selectedItemIndex === -1) {
            newSelectedItems = [...newSelectedItems, item.id];
          } else if (
            !action.selectAllContentItems &&
            selectedItemIndex !== -1
          ) {
            newSelectedItems = newSelectedItems.filter(
              (_, i) => i !== selectedItemIndex
            );
          }
        }
      );
      return tassign(state, {
        ...state,
        selectedItems: newSelectedItems,
      } as IContentSummaryState);
    }
  ),
  on(
    ContentSummaryActions.onContentSummaryDeselectAllSelectedItems,
    (state) => {
      return tassign(state, {
        ...state,
        selectedItems: [],
      } as IContentSummaryState);
    }
  ),
  on(ContentSummaryActions.onToggleColumn, (state, action) => {
    return tassign(state, {
      ...state,
      columns: state.columns.map((column: any) => {
        if (column.title === action.column) {
          return tassign(column, {
            ...column,
            visible: !column.visible,
          });
        }

        return column;
      }),
    } as IContentSummaryState);
  }),
  on(ContentSummaryActions.onToggleColumnFilters, (state, action) => {
    const filters = deepCopy(state.filters);
    // eslint-disable-next-line functional/immutable-data
    filters[action.columnId].appliedFilters = [];

    return tassign(state, {
      ...state,
      filters,
      isLoading: true,
    });
  }),
  on(ContentSummaryActions.onToggleDateFilter, (state, action) => {
    const filters = deepCopy(state.filters);
    // eslint-disable-next-line functional/immutable-data
    filters[action.columnId] = {
      ...filters[action.columnId],
      ...action.dateFilter,
    };

    return tassign(state, {
      ...state,
      filters,
      isLoading: true,
    });
  }),
  on(ContentSummaryActions.onToggleFilter, (state, action) => {
    let columnFilters = [...state.filters[action.columnId].appliedFilters];
    const toggledFilterIndex = columnFilters.indexOf(action.filterText);

    if (toggledFilterIndex === -1) {
      columnFilters = [...columnFilters, action.filterText];
    } else {
      columnFilters = columnFilters.filter((_, i) => i !== toggledFilterIndex);
    }

    const filters = deepCopy(state.filters);
    // eslint-disable-next-line functional/immutable-data
    filters[action.columnId].appliedFilters = columnFilters;

    return tassign(state, {
      ...state,
      filters,
      isLoading: true,
    } as IContentSummaryState);
  }),
  on(
    ContentSummaryActions.onContentSummaryToggleAllSelectedItemsPaginated,
    (state, action) => {
      let selectedItemResult: string[] | Set<string>;
      if (action.selectAllContentItems) {
        selectedItemResult = new Set([
          ...state.selectedItems,
          ...action.contentItemIds,
        ]);
      } else {
        selectedItemResult = state.selectedItems.filter(
          (x) => !action.contentItemIds.includes(x)
        );
      }

      return tassign(state, {
        ...state,
        selectedItems: [...selectedItemResult],
      } as IContentSummaryState);
    }
  ),
  on(
    ContentSummaryActions.onContentSummaryToggleSelectedItem,
    (state, action) => {
      let newSelectedItems = deepCopy(state.selectedItems);
      const itemIndex = state.selectedItems.indexOf(action.contentItem.id);
      if (itemIndex === -1) {
        newSelectedItems = [...newSelectedItems, action.contentItem.id];
      } else {
        newSelectedItems = [
          ...newSelectedItems.slice(0, itemIndex),
          ...newSelectedItems.slice(itemIndex + 1),
        ];
      }
      return tassign(state, {
        ...state,
        selectedItems: newSelectedItems,
      } as IContentSummaryState);
    }
  )
);
