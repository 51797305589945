import { createAction, props } from "@ngrx/store";

import { NotificationType } from "@pgr-cla/core-ui-components";

export class CoreActions {
  public static featuresUpdated = createAction(
    "[Feature Management API] All Features Loaded"
  );

  public static onNotificationRequest = createAction(
    "@pgr/core/NOTIFICATION_REQUESTED",
    props<{
      message: string | string[];
      notificationType: NotificationType;
    }>()
  );

  public static serverError = createAction(
    "@pgr/core/SERVER_ERROR",
    (actionProps: { error: Error; navigateTechDiff?: boolean }) => {
      return {
        error: actionProps.error,
        navigateTechDiff: !!actionProps.navigateTechDiff,
      };
    }
  );
}
