<div class="container-div min-w-6xl">
  <mat-card class="p-0" [class.pb-xxs]="requestInFlight$ | async">
    <mat-card-content>
      <div *ngIf="(requestInFlight$ | async) === false">
        <cui-notification-banner
          *ngIf="hasCvqRequestError"
          [actionable]="true"
          (action)="refreshCVQs()"
          actionButtonText="Try Again"
          type="error"
        >
          {{ defaultErrorMessage }}
        </cui-notification-banner>
        <div
          class="py-bl-2"
          *ngIf="
            !hasCvqRequestError &&
            (this.dataSource$ | async) &&
            (this.dataSource$ | async)!.length == 0
          "
        >
          <div class="flex items-center">
            <p class="pl-xxs">There are currently no CVQs.</p>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          (this.dataSource$ | async) && (this.dataSource$ | async)!.length !== 0
        "
        data-pgr-id="cvqDisplay"
      >
        <mat-divider class="actions-bar-divider"></mat-divider>
        <div>
          <mat-card class="p-0 cvq-display-card">
            <mat-card-content>
              <cla-list-component
                [dataSource]="dataSource$ | async"
              ></cla-list-component>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
