export const _rtfMimeTypes = [
  "application/rtf",
  'application/rtf; version="0"',
  'application/rtf; version="1.0-1.4"',
  'application/rtf; version="1.5-1.6"',
  'application/rtf; version="1.7"',
  'application/rtf; version="1.8"',
  'application/rtf; version="1.9"',
  "text/rtf",
  'text/rtf; version="1.0-1.4"',
  'text/rtf; version="1.5-1.6"',
  'text/rtf; version="1.7"',
  'text/rtf; version="1.8"',
  'text/rtf; version="1.9"',
];
