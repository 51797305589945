import { Injectable } from "@angular/core";
import { merge, of, fromEvent, Observable } from "rxjs";
import { map, mapTo } from "rxjs/operators";
import { WindowService } from "@pgr-cla/cla-window";

@Injectable({
  providedIn: "root",
})
export class ConnectivityService {
  public changes$: Observable<string>;

  public get status(): string {
    return this._windowService.window.navigator.onLine ? "online" : "offline";
  }

  public get online(): boolean {
    return this._windowService.window.navigator.onLine;
  }

  public online$: Observable<boolean>;
  public offline$: Observable<boolean>;

  private _isOnline$ = merge(
    of(null),
    fromEvent(this._windowService.window, "online"),
    fromEvent(this._windowService.window, "offline")
  ).pipe(map(() => this._windowService.window.navigator.onLine));

  constructor(private _windowService: WindowService) {
    this.changes$ = this._isOnline$.pipe(
      map((isOnline: boolean) => {
        return isOnline ? "online" : "offline";
      })
    );

    this.offline$ = this._isOnline$.pipe(
      mapTo(!this._windowService.window.navigator.onLine)
    );

    this.online$ = this._isOnline$.pipe(
      mapTo(this._windowService.window.navigator.onLine)
    );
  }
}
