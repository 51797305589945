import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from "@angular/core";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ScreenSizeService } from "@modules/claims-landing/services";
import { PaymentDetailsLambdaInfo } from "@modules/payments/models";
import { PaymentsStateService } from "@modules/payments/services";

@UntilDestroy()
@Component({
  selector: "cla-payments-table",
  templateUrl: "./payments-table.component.html",
  styleUrls: ["./payments-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentsTableComponent implements AfterViewInit, AfterViewChecked {
  public headerGroupColumns: string[] = [
    "header-row-id-group",
    "header-row-invoice-group",
    "header-row-payment-group",
  ];

  public displayedColumns: string[] = [
    "name",
    "exposure",
    "invoiceAmount",
    "invoiceNumber",
    "invoiceType",
    "invoiceStatus",
    "paymentAmount",
    "paymentNumber",
    "paymentType",
    "paymentStatus",
    "paymentIssuedDate",
  ];
  public dataSource: MatTableDataSource<PaymentDetailsLambdaInfo> =
    new MatTableDataSource<PaymentDetailsLambdaInfo>();

  @ViewChild(MatTable) table: MatTable<PaymentDetailsLambdaInfo>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public paymentsStateService: PaymentsStateService,
    public screenSizeService: ScreenSizeService
  ) {}

  ngAfterViewInit(): void {
    this.paymentsStateService.paymentsTableData$
      .pipe(untilDestroyed(this))
      .subscribe((payments: PaymentDetailsLambdaInfo[]) => {
        this.dataSource.data = payments;
        this.dataSource.sort = this.sort;
        if (payments) {
          this._liveAnnouncer.announce(`${payments.length} payments found`);
        }
      });
  }

  ngAfterViewChecked(): void {
    if (this.table) {
      this.table.updateStickyHeaderRowStyles();
      this.table.updateStickyFooterRowStyles();
      this.table.updateStickyColumnStyles();
    }
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }
}
