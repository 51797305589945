import { Pipe, PipeTransform } from "@angular/core";

import { PayerName } from "../models";

@Pipe({
  name: "claPayersNamePipe",
})
export class PayersNamePipe implements PipeTransform {
  transform(value: PayerName): string {
    if (value.business) {
      return value.business.trim();
    }

    if (this.personNotEmpty(value)) {
      const person = value.payerPersonName;
      return `${person?.lastName}, ${person?.firstName} ${person?.middleInitial} ${person?.suffix}`.trim();
    }

    return "";
  }

  private personNotEmpty(name: PayerName) {
    const person = name.payerPersonName;
    if (!person) {
      return false;
    }
    return (
      !!person.lastName ||
      !!person.firstName ||
      !!person.middleInitial ||
      person.suffix
    );
  }
}
