import {
  Action,
  ActionReducer,
  INIT as INIT_ACTION,
  MetaReducer,
  UPDATE as UPDATE_ACTION,
} from "@ngrx/store";
import { ClaimAlert } from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";
import { defaultMergeReducer, localStorageSync } from "ngrx-store-localstorage";
import { AppState } from "./app-state.interface";

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return localStorageSync({
    keys: ["alerts"],
    rehydrate: true,
    mergeReducer: (state, rehydratedState, action) => {
      if (
        (action.type !== INIT_ACTION && action.type !== UPDATE_ACTION) ||
        !rehydratedState?.alerts
      ) {
        return defaultMergeReducer(state, rehydratedState, action);
      }
      return defaultMergeReducer(
        state,
        {
          ...rehydratedState,
          alerts: {
            ...rehydratedState.alerts,
            alerts: rehydratedState.alerts.alerts.filter(
              (alert: ClaimAlert) =>
                DateTime.now().diff(
                  DateTime.fromISO(alert.timeStamp),
                  "minutes"
                ).minutes <=
                60 * 24
            ),
          },
        },
        action
      );
    },
  })(reducer);
}

export const metaReducers: Array<MetaReducer<AppState, Action>> = [
  localStorageSyncReducer,
];
