import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ClaimSyncSaveRequest,
  FeatureDetail,
  LiabilityTile,
} from "@claimssummaryshared/models";
import {
  ClaimDetails,
  ClaimReportedVia,
  ClaimSummary,
  PartyPropertyDetail,
} from "@modules/core/models";
import {
  AdapterApiService,
  ClaimManagementApiService,
} from "@modules/core/services";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";
import { Observable, throwError } from "rxjs";
import { catchError, first, map, shareReplay, switchMap } from "rxjs/operators";

@Injectable()
export class ClaimDetailsService {
  constructor(
    private adapterApiService: AdapterApiService,
    private claimManagementApiService: ClaimManagementApiService,
    private config: ConfigurationService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getClaimDetails(claimNumber: string): Observable<ClaimDetails> {
    return this.claimManagementApiService
      .get<ClaimDetails>(`/lossviewerapi/v1/claims/${claimNumber}/details`)
      .pipe(
        map((claimDetails: ClaimDetails) =>
          this.mapLossLocationDisplay(claimDetails)
        ),
        catchError((error: unknown) => {
          return this.errorMasking(
            error as HttpErrorResponse,
            `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
          );
        }),
        shareReplay(1)
      );
  }

  getClaimInfo(claimNumber: string): Observable<ClaimInfo> {
    return this.claimManagementApiService
      .get<ClaimInfo>(`/lossviewerapi/v1/claims/${claimNumber}/details`)
      .pipe(
        catchError((error: unknown) => {
          return this.errorMasking(
            error as HttpErrorResponse,
            `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
          );
        }),
        shareReplay(1)
      );
  }

  getClaimSummary(claimNumber: string): Observable<ClaimSummary> {
    return this.claimManagementApiService
      .get<ClaimSummary>(
        `/lossviewerapi/v1/claims/${claimNumber}/notes-summary`
      )
      .pipe(
        catchError((error) => {
          return this.errorMasking(
            error,
            `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
          );
        })
      );
  }

  getClaimReportedVia(claimNumber: string): Observable<ClaimReportedVia> {
    return this.claimManagementApiService
      .get<ClaimReportedVia>(
        `/lossviewerapi/v1/claims/${claimNumber}/reportedvia`
      )
      .pipe(
        catchError((error) => {
          return this.errorMasking(
            error,
            `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
          );
        })
      );
  }

  getPartyPropertyDetails(
    claimNumber: string
  ): Observable<PartyPropertyDetail[]> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.adapterApiService
          .get<PartyPropertyDetail[]>(
            config.adapterApiRouteUrl,
            `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
              claimNumber,
              "full"
            )}/party-property-details`
          )
          .pipe(
            catchError((error) => {
              return this.errorMasking(
                error,
                `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
              );
            })
          );
      })
    );
  }

  getFeatures(claimNumber: string): Observable<FeatureDetail[]> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.adapterApiService
          .get<FeatureDetail[]>(
            config.adapterApiRouteUrl,
            `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
              claimNumber,
              "full"
            )}/features`
          )
          .pipe(
            catchError((error) => {
              return this.errorMasking(
                error,
                `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
              );
            })
          );
      })
    );
  }

  getLiability(
    claimNumber: string,
    claimInvestigativeStatus: string
  ): Observable<LiabilityTile> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.adapterApiService
          .get<LiabilityTile>(
            config.adapterApiRouteUrl,
            `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
              claimNumber,
              "full"
            )}/liability?claimInvestigativeStatus=${claimInvestigativeStatus}`
          )
          .pipe(
            catchError((error) => {
              return this.errorMasking(
                error,
                `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
              );
            })
          );
      })
    );
  }

  postClaimSaveTrigger(payload: ClaimSyncSaveRequest): Observable<unknown> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.adapterApiService
          .post(
            config.adapterApiRouteUrl,
            `/lossviewerapi/v1/claims/claim-save-sync`,
            payload
          )
          .pipe(
            catchError((error) => {
              return this.errorMasking(
                error,
                `ClaimSave Failed for ${this.claimNumberPipe.transform(
                  payload.claimNumber
                )}`
              );
            })
          );
      })
    );
  }

  private mapLossLocationDisplay(claimDetails: ClaimDetails): ClaimDetails {
    return {
      ...claimDetails,
      lossLocationDisplayLine1: `${claimDetails.accidentLocation}`.trim(),
      lossLocationDisplayLine2: "",
      lossLocationDisplayLine3: `${claimDetails.accidentLocationCityName}${
        claimDetails.accidentLocationCityName ? ", " : ""
      }${claimDetails.accidentLocationState?.name}`.trim(),
      lossLocationDisplayLine4: `${claimDetails.accidentLocationCountyName}${
        claimDetails.accidentLocationCountyName &&
        claimDetails.accidentLocationCountry?.name
          ? ", "
          : ""
      }${claimDetails.accidentLocationCountry?.name || ""}`.trim(),
    };
  }

  private errorMasking(error: HttpErrorResponse, message: string) {
    if (error.status === 404) {
      return throwError(
        () => ({ ...error, statusText: message } as HttpErrorResponse)
      );
    }
    return throwError(() => error);
  }

  private mapFeatureTooltips(features: FeatureDetail[]): FeatureDetail[] {
    return features.map((feature: FeatureDetail) => ({
      ...feature,
      inspectionTooltip: this.mapInspectionTooltip(feature),
    }));
  }

  private mapInspectionTooltip({
    inspection,
    isTotalLoss,
  }: FeatureDetail): string[] | undefined {
    if (inspection) {
      const damageDisposition = `Damage Disposition: ${
        isTotalLoss === null
          ? "Unknown"
          : isTotalLoss
          ? "Total Loss"
          : "Repairable"
      }`;

      if (inspection.location.code) {
        if (inspection.location.code === "N") {
          return ["No Inspection", damageDisposition];
        }

        const location = `Location: ${inspection.location.description}`;

        if (inspection.completedDateTime) {
          const date = `Date: ${DateTime.fromISO(
            inspection.completedDateTime
          ).toFormat("MM-dd-yyyy")}`;
          const time = `Time: ${DateTime.fromISO(
            inspection.completedDateTime
          ).toFormat("hh:mm a")} ${inspection.completedTimeZone?.code}`.trim();
          return [location, date, time, damageDisposition];
        }

        return [location, damageDisposition];
      }

      return ["Inspection Pending", damageDisposition];
    }

    return;
  }
}
