import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claCreditAdvicePipe",
})
export class CreditAdvicePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return `${value.substring(0, 2)}-${value.substring(
        2,
        4
      )}-${value.substring(4, 7)}-${value.substring(7)}`;
    }

    return value;
  }
}
