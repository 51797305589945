import { createSelector } from "@ngrx/store";

import { IAppState } from "../../../models/core";
import { IDocSetState } from "../../../models/doc-set";

export const selectDocSet: (state: IAppState) => IDocSetState = (
  state: IAppState
) => state.docSet;
export const selectDetails = createSelector(
  selectDocSet,
  (state: IDocSetState) => state.details.response
);
export const selectDetailsInFlight = createSelector(
  selectDocSet,
  (state: IDocSetState) => state.details.inFlight
);
export const selectDetailsEntity = createSelector(
  selectDocSet,
  (state: IDocSetState) => state.details
);
export const selectDetailsMeta = createSelector(
  selectDocSet,
  (state: IDocSetState) => state.details.meta
);
