import { formatPartyName } from "@modules/shared/helpers/party-name-format";

import { ClaimInfo } from "@modules/shared/models/claim-info";
import { claimStatusDesc } from "@modules/shared/models/claim-status";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";

export class ClaimInfoDto {
  static toClaimInfo(claimData: any, claimNumber: string): ClaimInfo {
    const name = claimData.isPartyBusiness
      ? claimData.namedInsured.businessName
      : formatPartyName(
          claimData.namedInsured.partyFirstName,
          claimData.namedInsured.partyMiddleInitial,
          claimData.namedInsured.partyLastName,
          claimData.namedInsured.partyLastNameSuffix
        );

    const claimInfo = {
      claimNumber: new ClaimNumberPipe().transform(claimNumber, "full") || "",
      dateOfLoss: claimData.claimLossDate,
      reportDate: claimData.claimReportDate,
      namedInsured: name,
      claimStatus: claimData.claimStatus,
      policyNumber: claimData.policyNumber,
      productCode: claimData.productCategory.name,
      claimOwningRepCode: claimData.claimOwningRepCode,
      claimOwningOrg: claimData.originatingOrg,
      policyState: claimData.policyState,
      progressiveCompanyCode: claimData.progCompany.name,
    } as ClaimInfo;

    if (claimInfo.claimStatus !== null) {
      // eslint-disable-next-line functional/immutable-data
      claimInfo.claimStatus.description = claimStatusDesc.get(
        claimData.claimStatus.name
      );
    }
    return claimInfo;
  }
}
