import { Injectable } from "@angular/core";
import { ClaimManagementApiService } from "@core/services";
import { PolicyDetailsApiService } from "@modules/core/services/policy-details-api";
import { Observable, shareReplay } from "rxjs";

import { CVQ } from "../models/cvq.interface";
import { Rep } from "../models/rep.interface";
import { ClaimLevel } from "../models/uw-memo-claim-level.interface";
import { ClaimPolicy } from "../models/uw-memo-claim-policy.interface";
import { UWMemoData } from "../models/uw-memo-response/uw-memo-response.interface";

@Injectable()
export class CoverageService {
  constructor(
    private claimManagementApiService: ClaimManagementApiService,
    private policyDetailsApiService: PolicyDetailsApiService
  ) {}

  getClaimPolicy(claimNumber: string): Observable<ClaimPolicy> {
    return this.policyDetailsApiService.get<ClaimPolicy>(
      `/lossviewerapi/v1/claims/${claimNumber}/claimpolicy`
    );
  }

  getCVQs(claimNumber: string): Observable<CVQ[]> {
    return this.policyDetailsApiService.get<CVQ[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/cvqs`
    );
  }

  getUWMemoData(claimNumber: string): Observable<UWMemoData> {
    return this.policyDetailsApiService.get<any>(
      `/lossviewerapi/v1/claims/${claimNumber}/uw-memo/details/`
    );
  }

  getClaimLevelData(claimNumber: string): Observable<ClaimLevel> {
    return this.claimManagementApiService
      .get<ClaimLevel>(`/lossviewerapi/v1/claims/${claimNumber}/details`)
      .pipe(shareReplay(1));
  }

  getRep(repCode: string, isManagerRequired: boolean = false) {
    return this.policyDetailsApiService.get<Rep>(
      `/lossviewerapi/v1/reps/${repCode}?managerRequired=${isManagerRequired}`
    );
  }
}
