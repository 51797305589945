<cui-notification-banner
  *ngIf="serverError || showInlineErrorMessage"
  type="error"
  [hasBottomBorder]="hasBottomBorder"
>
  <ng-container *ngIf="serverError">
    <ng-container
      *ngIf="serverError.error?.attributeErrors; else baseErrorMessage"
    >
      <div class="mb-bl-1" data-pgr-id="attribute-error-message">
        Please correct the following errors:
      </div>
      <ul class="error-list" data-pgr-id="attribute-errors">
        <li *ngFor="let attributeError of serverError.error.attributeErrors">
          {{ attributeError.attributeName }}
          {{ attributeError.attributeDisplayMessage }}
        </li>
      </ul>
    </ng-container>
    <ng-template #statusTextError>
      <ng-container *ngIf="serverError.statusText; else defaultErrorMessage">
        <div class="mb-bl-1" data-pgr-id="status-text-message">
          The following errors occurred, please try again:
        </div>

        <ul class="error-list">
          <li data-pgr-id="server-error-status-text">
            {{ serverError.statusText }}
          </li>
        </ul>
      </ng-container>
    </ng-template>
    <ng-template #baseErrorMessage>
      <ng-container
        *ngIf="serverError?.error?.baseErrorMessage; else statusTextError"
      >
        <div class="mb-bl-1" data-pgr-id="status-text-message">
          The following errors occurred, please try again:
        </div>

        <ul class="error-list">
          <li data-pgr-id="server-error-status-text">
            {{ serverError.error.baseErrorMessage }}
          </li>
        </ul>
      </ng-container>
    </ng-template>
    <ng-template #defaultErrorMessage>
      <span data-pgr-id="default-server-banner-message"
        >An unexpected error occurred. Please try again.</span
      >
    </ng-template>
  </ng-container>
  <ng-container *ngIf="showInlineErrorMessage">
    <span data-pgr-id="inline-error-message"
      >Please correct the errors below to continue.</span
    >
  </ng-container>
</cui-notification-banner>
