<mat-card appearance="outlined">
  <div class="adverse-party-details">
    <div class="details-wrapper">
      <div class="detail-fields container-elevated">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <span class="label whitespace-no-wrap">Subro File Type</span>
            <p
              *ngIf="adverseParty.subroFileTypeCode; else emptyContentItemPtag"
            >
              {{
                displayDescription(
                  adverseParty.subroFileTypeCode,
                  detailCodes?.fileTypeCodes
                ).toLowerCase()
              }}
            </p>
          </div>
          <div data-pgr-id="ap-judgement-entered" class="col-md-3 col-sm-6">
            <span class="label">Judgment Entered</span>
            <p
              *ngIf="
                adverseParty.judgmentEnteredIndicator;
                else emptyContentItemPtag
              "
            >
              {{ adverseParty.judgmentEnteredIndicator }}
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <span class="label">Claimant Carrier Liability Decision</span>
            <p
              *ngIf="
                adverseParty.insuranceCarrierInfo?.liabilityDecision;
                else emptyContentItemPtag
              "
            >
              {{
                displayDescription(
                  adverseParty.insuranceCarrierInfo?.liabilityDecision,
                  detailCodes?.liabilityDecisionTypeCodes
                )
              }}
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <span class="label">
              Claimant Carrier Liability Limits Sufficient
            </span>
            <p
              *ngIf="
                adverseParty.insuranceCarrierInfo?.liabilityLimitsSufficient;
                else emptyContentItemPtag
              "
            >
              {{
                getLiabilityLimitsText(
                  adverseParty.insuranceCarrierInfo?.liabilityLimitsSufficient
                )
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <span class="label">Deductible Status</span>
            <p
              *ngIf="
                adverseParty.deductibleStatusCode;
                else emptyContentItemPtag
              "
            >
              {{
                displayDescription(
                  adverseParty.deductibleStatusCode,
                  detailCodes?.deductibleStatusCodes
                ).toLowerCase()
              }}
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <span class="label whitespace-no-wrap">Agency / Attorney</span>
            <p *ngIf="adverseParty.agency; else emptyContentItemPtag">
              {{
                displayDescription(
                  adverseParty.agency,
                  detailCodes?.attorneyNicknames
                ).toLowerCase()
              }}
            </p>
          </div>
          <div data-pgr-id="ap-percent-disputed" class="col-md-3 col-sm-6">
            <span class="label">% Disputed</span>
            <p
              *ngIf="
                adverseParty.insuranceCarrierInfo?.liabilityDisputedAmount;
                else emptyContentItemPtag
              "
            >
              {{ adverseParty.insuranceCarrierInfo?.liabilityDisputedAmount }}
            </p>
          </div>
          <div class="col-md-3 col-sm-6">
            <!-- Empty for styling purposes -->
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
<cla-subro-features
  [adversePartyName]="adverseParty | claPartyName"
  [partyId]="adverseParty.partyId"
  [partySequenceNumber]="adverseParty.partySequenceNumber"
  [subroObligationId]="adverseParty.subroObligationId"
  [liabilityPercentage]="adverseParty.liabilityPercentage"
  [claimNumber]="claimNumber"
  [detailCodes]="detailCodes"
></cla-subro-features>
<ng-template #emptyContentItemPtag>
  <p>&mdash;</p>
</ng-template>
