import { Injectable } from "@angular/core";
import { AdapterApiService } from "@modules/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable, of } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { EFFConstants } from "../../../constants";
import {
  IContentSummaryConfigInfo,
  IContentSummaryItem,
  IDocSetItemContentUrlResponse,
  IDocSetThumbnailResponse,
} from "../../../models";
import { ActionsValidationUtility } from "../../../utilities";
import { BlobApiService } from "../../core/blob-api/blob-api.service";

@Injectable()
export class DocSetService {
  constructor(
    private blobApiService: BlobApiService,
    private actionsValidationUtility: ActionsValidationUtility,
    private api: AdapterApiService,
    private config: ConfigurationService
  ) {}

  public getThumbnail(
    docSetId: string,
    docSetVersionNbr: number
  ): Observable<IDocSetThumbnailResponse> {
    return this.get<IDocSetThumbnailResponse>(
      `/lossviewerapi/v1/docsets/${docSetId}/versions/${docSetVersionNbr}/thumbnails`
    );
  }

  public getContentUrl(
    docSetId: string,
    docSetVersionNbr: number
  ): Observable<IDocSetItemContentUrlResponse> {
    return this.get<IDocSetItemContentUrlResponse>(
      `/lossviewerapi/v1/docsets/${docSetId}/versions/${docSetVersionNbr}/contentUrl`
    );
  }

  public getThumbnailImage(thumbnailUrl: string): Observable<Blob> {
    return this.blobApiService.get(of(thumbnailUrl));
  }

  public isEstimateId(docSetId: string): boolean {
    return (
      docSetId.startsWith(EFFConstants.viewer.committedEstimatePrefix) ||
      docSetId.startsWith(EFFConstants.viewer.preliminaryEstimatePrefix)
    );
  }

  public isValidToOpen(contentItems: IContentSummaryItem[]): boolean {
    const validItemsToOpen =
      this.actionsValidationUtility.validateItemsToOpen(contentItems);
    if (validItemsToOpen.length > 0) {
      return true;
    }
    return false;
  }

  public get<T>(path: string): Observable<T> {
    return this.config.configuration$.pipe(
      first(
        (config: IContentSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        return this.api.get<T>(config.claimsContentViewerApiPath, path);
      })
    );
  }
}
