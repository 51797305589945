import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of, combineLatest } from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import * as diaryActions from "@diary/actions";
import * as sharedActions from "@core/state/claim-details/claim-details.actions";
import * as coreUiExtensionActions from "@modules/core-ui-extensions/actions";
import { DiaryApiService, DiaryStateService } from "@diary/services";
import { RepSummary } from "@claimssummaryshared/models";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { Diary, DiaryCalenderCountData, DiaryTableData } from "../models";
import { UserStateService } from "@modules/core/state";
import { ClaimsSummaryAdapterApiService } from "@modules/core";

@Injectable()
export class DiaryEffects {
  retrieveCurrentClaimDiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diaryActions.retrieveCurrentClaimDiaries),
      withLatestFrom(this.diaryStateService.selectedClaimNumber$),
      map(([diaryAction, claimNumber]) =>
        diaryActions.retrieveClaimDiaries({
          claimNumber,
          keepExistingDiaries: diaryAction.keepExistingDiaries,
        })
      )
    )
  );

  retrieveDiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diaryActions.retrieveClaimDiaries),
      switchMap(({ claimNumber }) =>
        this.diaryApiService.getClaimDiariesWithHistory(claimNumber).pipe(
          switchMap((diaries) =>
            from([
              diaryActions.retrieveClaimDiariesSuccess({
                diaries,
              }),
              coreUiExtensionActions.CoreUiExtensionsActions.onGetRepSummariesRequest(
                {
                  codes: [
                    ...new Set(
                      diaries.flatMap((x) =>
                        [
                          x.ownerRepCode,
                          x.enteredRepCode,
                          x.deletedByRepCode,
                        ].filter((x) => x)
                      )
                    ),
                  ],
                }
              ),
            ])
          ),
          catchError((error) =>
            of(diaryActions.retrieveClaimDiariesError({ error }))
          )
        )
      )
    )
  );

  mapDiariesToTable$ = createEffect(() =>
    combineLatest([
      this.diaryStateService.diaries$,
      this.coreUiExtensionsStateService.repSummaries$,
    ]).pipe(
      map(
        ([diaries, repSummaries]) =>
          diaries?.map((diary: Diary) => {
            return {
              ...diary,
              enteredRepSummary: repSummaries[diary.enteredRepCode],
              ownerRepSummary: repSummaries[diary.ownerRepCode],
              deletedByRepSummary: repSummaries[diary.deletedByRepCode],
              enteredRepTableDisplay:
                repSummaries[diary.enteredRepCode]?.name ||
                diary.enteredRepCode,
              ownerRepTableDisplay:
                repSummaries[diary.ownerRepCode]?.name || diary.ownerRepCode,
              deletedByRepTableDisplay:
                repSummaries[diary.deletedByRepCode]?.name ||
                diary.deletedByRepCode,
            };
          }) || []
      ),
      map((diaryTableData: DiaryTableData[]) =>
        diaryActions.setMappedTableData({ diaryTableData })
      )
    )
  );

  getDiaryCountData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(diaryActions.getClaimDiaryCountData),
      withLatestFrom(this.userStateService.repCode$),
      switchMap(([, repCode]) =>
        this.diaryApiService.getDiaryCountData(repCode).pipe(
          map((diaryCalenderCountData: DiaryCalenderCountData) =>
            diaryActions.getClaimDiaryCountDataSuccess({
              diaryCalenderCountData,
            })
          ),
          catchError((error) =>
            of(diaryActions.getClaimDiaryCountDataError({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private diaryApiService: DiaryApiService,
    private diaryStateService: DiaryStateService,
    private userStateService: UserStateService,
    private claimsSummaryAdapterApiService: ClaimsSummaryAdapterApiService,
    public coreUiExtensionsStateService: CoreUiExtensionsStateService
  ) {}
}
