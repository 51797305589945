import { TitleCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { PolicyResourceConstant } from "@modules/policy/helpers";
import { AgentInfo } from "@modules/policy/models/agent-info";
import { PolicyDetail } from "@modules/policy/models/policy-detail";
import { ValueOrNAPipe } from "@modules/shared/pipes";

@Component({
  selector: "cla-policy-agent-info",
  templateUrl: "./policy-agent-info.component.html",
  styleUrls: ["./policy-agent-info.component.scss"],
})
export class PolicyAgentInfoComponent {
  agentInfoPopulated = false;

  agentCode = "";
  agentPreferredName = "";
  agentLegalName = "";
  agentAddress = "";
  agentPhone = "";

  constructor(
    private valueOrNAPipe: ValueOrNAPipe,
    private titleCasePipe: TitleCasePipe
  ) {}

  @Input() policyDetail: PolicyDetail;

  @Input() set agentInfo(agentInfo: AgentInfo | null) {
    if (agentInfo && agentInfo !== null) {
      this.agentInfoPopulated = true;
      this.agentPreferredName = this.nameFormat(agentInfo.preferredName);
      this.agentLegalName = this.nameFormat(agentInfo.legalName);
      this.agentCode = this.codeFormat();
      this.agentAddress = this.addressFormat(agentInfo);
      this.agentPhone = this.phoneFormat(agentInfo);
    }
  }

  nameFormat(name: string) {
    if (name !== "") {
      const agentName = this.titleCasePipe.transform(
        this.valueOrNAPipe.transform(name)
      );
      return `${agentName}`;
    }
    return "-";
  }

  codeFormat() {
    if (
      this.policyDetail.agentPrefixCode.replace(
        PolicyResourceConstant.regex.removeSpecialChar,
        ""
      ) !== "" &&
      this.policyDetail.agentNumber.replace(
        PolicyResourceConstant.regex.removeSpecialChar,
        ""
      ) !== ""
    ) {
      const agentCode = `${this.policyDetail.agentPrefixCode}-${this.policyDetail.agentNumber}`;
      return agentCode;
    }
    return "-";
  }

  addressFormat(agentInfo: AgentInfo) {
    if (agentInfo.address !== "") {
      const agentAddress = this.titleCasePipe.transform(
        this.valueOrNAPipe.transform(agentInfo.address)
      );
      const agentCity = this.titleCasePipe.transform(
        this.valueOrNAPipe.transform(agentInfo.city)
      );
      const agentState = this.valueOrNAPipe.transform(agentInfo.state);
      const agentZip = this.valueOrNAPipe.transform(agentInfo.postalCode);
      return `${agentAddress}, ${agentCity}, ${agentState} ${agentZip}`;
    }
    return "-";
  }

  phoneFormat(agentInfo: AgentInfo) {
    if (agentInfo.phoneNumber !== "") {
      const agentPhone = agentInfo.phoneNumber;
      const areaCode = `${agentPhone[0]}${agentPhone[1]}${agentPhone[2]}`;
      const exchange = `${agentPhone[3]}${agentPhone[4]}${agentPhone[5]}`;
      const suffix = `${agentPhone[6]}${agentPhone[7]}${agentPhone[8]}${agentPhone[9]}`;
      return `(${areaCode}) ${exchange}-${suffix}`;
    }
    return "-";
  }
}
