export const loggingConstants = Object.freeze({
  appName: "TNC LossViewer Web",
  domain: "Claims",
  angularUnknownError: "Angular Error",
  angularErrorWithoutStackTrace: "Angular Error - Missing Stack Trace",
  angularNonErrorThrown: "Angular Error - Received object instead of error",
  angularUnknownErrorCode: "50000",
  angularErrorWithoutStackTraceCode: "51000",
  angularNonErrorThrownCode: "52000",
  awsError: "AWS Error",
  awsErrorCode: "20000",
});
