import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props, union } from "@ngrx/store";

export const errorOccurred = createAction(
  "[FR] Error Occurred",
  props<{ error: HttpErrorResponse }>()
);

export const doNothing = createAction("[FR] Do Nothing");

const all = union({
  errorOccurred,
  doNothing,
});

export type SharedActionsUnion = typeof all;
