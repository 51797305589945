import { Injectable } from "@angular/core";
import * as coreUiExtensionActions from "@modules/core-ui-extensions/actions";
import { CoreUiAdapterService } from "@modules/core-ui-extensions/services";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { Observable, asyncScheduler, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  observeOn,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { filterItems } from "../../helpers/util";
import { AppState } from "../../models/core/app-state.interface";
import { FeatureService } from "../../services/feature/feature.service";
import { historyActions, sharedActions } from "../actions";
import { FeatureRevisionState } from "../reducers";

@Injectable()
export class HistoryEffects {
  getFeatureRevisionHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.getHistory),
      concatMap((action) =>
        this.featureService.getFeatureRevisionHistory(action.claimNumber).pipe(
          switchMap((data) => [
            coreUiExtensionActions.CoreUiExtensionsActions.onGetRepSummariesRequest(
              {
                codes: [
                  ...new Set(
                    data.flatMap((history) =>
                      [history.repCode].filter(
                        (createdByUserId) => createdByUserId
                      )
                    )
                  ),
                ],
              }
            ),
            historyActions.getHistorySuccess({
              featureHistory: data,
              features: action.features,
            }),
          ]),
          catchError(() => {
            return of(historyActions.getHistoryError());
          })
        )
      )
    )
  );

  filterRevisionHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        historyActions.filterRevisionHistory,
        historyActions.resetHistoryFilter,
        historyActions.resetColumnFilters
      ),
      observeOn(asyncScheduler),
      withLatestFrom(this.getHistoryState()),
      map(([, state]) => filterItems(state.history)),
      concatMap((filterResult) =>
        of(
          historyActions.applyFilters({
            filteredRevisionHistory: filterResult.filteredHistoryList,
            filters: filterResult.filters,
          })
        )
      )
    )
  );

  closeFeatureRevisionHistoryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(historyActions.closeHistoryFilter),
      switchMap(() => {
        const sideSheet = this.sideSheetService.get("FeatureRevisionMain");

        if (sideSheet) {
          sideSheet.close();
        }
        return of(sharedActions.doNothing());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private featureService: FeatureService,
    private sideSheetService: SideSheetService,
    private coreUiAdapterService: CoreUiAdapterService
  ) {}

  get sideSheet() {
    return this.sideSheetService.get("sideSheet");
  }

  private getHistoryState(): Observable<FeatureRevisionState> {
    return this.store$.select("featureRevisionState");
  }
}
