import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";

import * as adversePartiesActions from "../actions/adverse-parties.actions";
import { AdverseParty } from "@subrogation-summary/models";

export type AdversePartiesState = EntityState<AdverseParty>;

export const adapter: EntityAdapter<AdverseParty> =
  createEntityAdapter<AdverseParty>({
    selectId: (adverseParty) => adverseParty.subroObligationId,
  });

const initialState: AdversePartiesState = adapter.getInitialState();

export function reducer(state: AdversePartiesState, action: Action) {
  return adversePartiesReducer(state, action);
}

const adversePartiesReducer = createReducer(
  initialState,
  on(
    adversePartiesActions.loadAdversePartiesSucceeded,
    (state, { adverseParties }) => adapter.setAll(adverseParties, state)
  )
);
