import { Pipe, PipeTransform } from "@angular/core";

import { SubroFeaStatCodes } from "@modules/subrogation-summary/constants/subrogation.constants";

@Pipe({
  name: "claOnHoldDashPipe",
})
export class OnHoldDashPipe implements PipeTransform {
  transform(value: string = "$0.00", subroFeaStatCode: string): string {
    return subroFeaStatCode === SubroFeaStatCodes.OnHold ? "\u2014" : value;
  }
}
