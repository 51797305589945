<main role="main" data-pgr-id="tleElectronicFileFolder">
  <mat-sidenav-container>
    <mat-sidenav
      position="end"
      mode="side"
      [autoFocus]="true"
      #sideSheet
      role="complementary"
    >
      <div
        *ngIf="sideSheet.opened"
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="side-sheet"
      >
        <router-outlet name="sideSheet"></router-outlet>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="flex flex-col mb-h">
        <div class="px-xs py-xxs w-full block">
          <div class="flex items-center">
            <div class="mr-auto">
              <h1
                class="cui-h1"
                id="main-content-header"
                tabindex="-1"
                data-pgr-id="lblElectronicFileFolder"
              >
                Electronic File Folder
              </h1>
            </div>
            <cla-content-summary-primary-actions
              class="flex-shrink-0"
            ></cla-content-summary-primary-actions>
          </div>
        </div>
        <mat-card
          appearance="outlined"
          class="p-0 mx-xs mb-xxs overflow-hidden flex-grow flex flex-col"
        >
          <div class="px-xs pt-xs">
            <div class="flex">
              <div class="flex flex-grow flex-shrink-0">
                <cla-content-summary-search></cla-content-summary-search>
              </div>
              <div class="flex justify-end">
                <div
                  [class]="
                    'side-sheet-actions ' +
                    (sideSheet.opened ? 'open' : 'closed')
                  "
                >
                  <button
                    mat-button
                    class="mr-bl-1"
                    queryParamsHandling="preserve"
                    [routerLink]="[{ outlets: { sideSheet: ['columns'] } }]"
                    (click)="toggleDrawer('columns')"
                    aria-label="Columns"
                    [matTooltip]="mediaQuery.matches ? 'Columns' : null"
                    data-pgr-id="btnOpenColumns"
                    [cuiInteractionEvent]="
                      contentSummaryEventService.clickAction(
                        actionNames.columns
                      )
                    "
                    skipLocationChange
                    color="primary"
                  >
                    <mat-icon>view_column</mat-icon>
                    <span class="side-sheet-action-label"> Columns </span>
                  </button>
                  <button
                    mat-button
                    queryParamsHandling="preserve"
                    [routerLink]="[{ outlets: { sideSheet: ['filters'] } }]"
                    (click)="toggleDrawer('filters')"
                    aria-label="Filters"
                    [matTooltip]="mediaQuery.matches ? 'Filters' : null"
                    data-pgr-id="btnOpenFilters"
                    [cuiInteractionEvent]="
                      contentSummaryEventService.clickAction(actionNames.filter)
                    "
                    skipLocationChange
                    color="primary"
                  >
                    <mat-icon>filter_list</mat-icon>
                    <span class="side-sheet-action-label"> Filters </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap items-center justify-between">
              <div class="flex">
                <cla-content-summary-secondary-actions></cla-content-summary-secondary-actions>
                <div class="flex flex-wrap ml-xxs">
                  <span class="flex items-center mr-bl-2">
                    <ng-container *ngIf="(isSearching$ | async) !== false">
                      <span data-pgr-id="contentSummarySearching"
                        >Searching...</span
                      >
                    </ng-container>
                    <ng-container *ngIf="(isSearching$ | async) === false">
                      <strong data-pgr-id="contentSummaryResultsTotal">
                        {{ contentSummaryViewService.filteredTotal$ | async }}
                      </strong>
                      &nbsp;
                      <span data-pgr-id="contentSummaryResultsTotalLabel">
                        {{
                          (contentSummaryViewService.filteredTotal$ | async) ===
                          1
                            ? "Result"
                            : "Results"
                        }}
                      </span>
                    </ng-container>
                  </span>
                  <div class="flex flex-wrap items-center">
                    <div class="mr-bl-2">
                      <strong
                        data-pgr-id="contentSummarySelectedTotal"
                        class="mr-bl-1"
                      >
                        {{ contentSummaryViewService.selectedTotal$ | async }}
                      </strong>
                      <span data-pgr-id="contentSummarySelectedTotalLabel">
                        {{
                          (contentSummaryViewService.selectedTotal$ | async) ===
                          1
                            ? "Row"
                            : "Rows"
                        }}
                        Selected
                      </span>
                    </div>

                    <div
                      *ngIf="
                        (contentSummaryViewService.selectedTotal$ | async) !== 0
                      "
                    >
                      <button
                        class="cui-link deselect-link"
                        data-pgr-id="btnDeselectAll"
                        (click)="
                          contentSummaryViewService.deselectAllSelectedItems()
                        "
                      >
                        Deselect All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-grow flex justify-end">
                <mat-paginator
                  [pageSize]="150"
                  showFirstLastButtons
                ></mat-paginator>
              </div>
            </div>
            <mat-divider class="actions-bar-divider"></mat-divider>
          </div>
          <router-outlet></router-outlet>
        </mat-card>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <cui-spinner
    data-pgr-id="content-summary-view-spinner"
    *ngIf="isLoading$ | async"
  >
  </cui-spinner>
</main>
