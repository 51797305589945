/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
import { SelectionModel } from "@angular/cdk/collections";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import {
  CvqNoteComponent,
  CvqNoteProps,
} from "@modules/coverage/components/cvq-note/cvq-note.component";
import { CVQ, CVQStatus } from "@modules/coverage/models/cvq.interface";
import { Store } from "@ngrx/store";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { AppState } from "@store/app-state.interface";
import { Observable, Subject } from "rxjs";
import { first } from "rxjs/operators";
import { isRequestInFlight } from "../../../../store/coverage.selectors";

@Component({
  selector: "cla-list-component",
  templateUrl: "./list-component.component.html",
  styleUrls: ["./list-component.component.scss"],
})
export class CVQListComponent implements OnInit {
  selection = new SelectionModel<CVQ>(true, []);

  @Input() dataSource: CVQ[];

  claimNumber: string;
  categories: string[];
  isRequestInFlight$: Observable<boolean>;
  dynamicCVQViewFlag = false;

  private destroySubject$: Subject<void> = new Subject<void>();
  public dialogRef: any;

  constructor(
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isRequestInFlight$ = this.store$.select(isRequestInFlight);

    this.route.params.pipe(first()).subscribe((params) => {
      this.claimNumber = params["claimNumber"];
    });
    this.route.data.pipe(first()).subscribe((data) => {
      this.categories = [data.title];
    });
  }

  getIndicatorForCVQStatus(status: string) {
    switch (status) {
      case CVQStatus.Resolved:
        return "active";
      case CVQStatus.Pending:
        return "warn";
      default:
        return "alert";
    }
  }

  generateCVQlabel(cvq: CVQ) {
    return `CVQ Status ${cvq.status}. Description ${cvq.description}. Level ${
      cvq.level
    }.
        Verification ${cvq.verification ? cvq.verification : "Not Required"}.
        ${cvq.creationTypeText}. Impacts ${cvq.impacts}.`;
  }

  openCvqNoteForm(cvq: CVQ) {
    this.dialogRef = this.dialog.open(CvqNoteComponent, {
      panelClass: "cui-dialog",
      width: CUI_DIALOG_WIDTH.SMALL,
      minWidth: "30rem",
      minHeight: "15rem",
      data: {
        cvq,
        claimNumber: this.claimNumber,
        categories: this.categories,
      } as unknown as CvqNoteProps,
    });
  }

  setCvqNoteButtonAriaLabel(itemCount: number): string {
    let ariaLabel = "";
    switch (itemCount) {
      case 0:
        ariaLabel = "No CVQ notes";
        break;
      case 1:
        ariaLabel = "View 1 CVQ note";
        break;
      default:
        ariaLabel = "View " + itemCount.toString() + " CVQ notes";
    }
    return ariaLabel;
  }

  @HostListener("window:beforeunload", ["$event"])
  public unloadNotification($event: BeforeUnloadEvent): void {
    if (this.selection.selected.length > 0) {
      // eslint-disable-next-line functional/immutable-data
      ($event as BeforeUnloadEvent).preventDefault();
      // eslint-disable-next-line functional/immutable-data
      ($event as BeforeUnloadEvent).returnValue = true;
    }
  }
}
