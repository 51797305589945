import { createAction, props } from "@ngrx/store";

import { CvqAddNoteRequest } from "../models/cvq-add-note-request.interface";
import { CvqAddRequest } from "../models/cvq-add-request.interface";
import { CvqNote } from "../models/cvq-note.interface";
import { CvqResolveRequest } from "../models/cvq-resolve-request.interface";
import { CVQ } from "../models/cvq.interface";
import { Rep } from "../models/rep.interface";
import { ClaimLevel } from "../models/uw-memo-claim-level.interface";
import { ClaimPolicy } from "../models/uw-memo-claim-policy.interface";
import { UwMemoCreate } from "../models/uw-memo-create.interface";
import { UWMemoData } from "../models/uw-memo-response/uw-memo-response.interface";

export const onCVQRequested = createAction(
  "[Coverage] CVQS Requested",
  props<{ claimNumber: string }>()
);

export const onCVQRequestedError = createAction(
  "[Coverage] CVQ Requested Error",
  props<{ error: any }>()
);

export const onCVQReceived = createAction(
  "[Coverage] CVQS Received",
  props<{ CVQList: CVQ[] }>()
);

export const onResolveCVQsRequested = createAction(
  "[Coverage] Resolve CVQs Requested",
  props<{ resolveRequest: CvqResolveRequest }>()
);

export const onResolveCVQsReceived = createAction(
  "[Coverage] Resolve CVQs Received",
  props<{ cvqs: CVQ[] }>()
);

export const onResolveCVQsCompleted = createAction(
  "[Coverage] Resolve CVQs Completed"
);

export const onResolveCVQsFailed = createAction(
  "[Coverage] Resolve CVQs Failed",
  props<{ errorMessage: string }>()
);

export const onUnderwritingMemoSubmitRequested = createAction(
  "[Coverage] Underwriting Memo Submit Requested",
  props<{ payload: UwMemoCreate }>()
);

export const onUnderwritingMemoSubmitReceived = createAction(
  "[Coverage] Underwriting Memo Submit Received"
);

export const onUnderwritingMemoCategoryTypeRequest = createAction(
  "[Coverage] UnderWriting Memo Category/Type Requested",
  props<{ categorySelected: string; typeSelected: string }>()
);

export const onUnderwritingMemoCategoryTypeReceived = createAction(
  "[Coverage] UnderWriting Memo Category/Type Received",
  props<{ categorySelected: string; typeSelected: string }>()
);

export const onCVQAddRequested = createAction(
  "[Coverage] CVQ Add Requested",
  props<{ cvqAddRequest: CvqAddRequest }>()
);

export const onCVQAddReceived = createAction(
  "[Coverage] CVQ Add Received",
  props<{ cvq: CVQ }>()
);

export const onCVQAddFailed = createAction(
  "[Coverage] CVQ Add Failed",
  props<{ errorMessage: string }>()
);

export const onCVQAddCompleted = createAction("[Coverage] CVQ Add Completed");

export const onRepRequested = createAction(
  "[Coverage] Rep Requested",
  props<{ repCode: string }>()
);

export const onRepReceived = createAction(
  "[Coverage] Rep Received",
  props<{ rep: Rep }>()
);

export const onUWMemoClaimPolicyRequested = createAction(
  "[Coverage] UW Memo Claim Policy Requested",
  props<{ claimNumber: string }>()
);

export const onUWMemoClaimPolicyRecieved = createAction(
  "[Coverage] UW Memo Claim Policy Recieved",
  props<{ claimPolicy: ClaimPolicy }>()
);

export const onUWMemoDataRequested = createAction(
  "[Coverage] UW Memo Data Requested",
  props<{ claimNumber: string }>()
);

export const onUWMemoDataRecieved = createAction(
  "[Coverage] UW Memo Data Recieved",
  props<{ uwMemoData: UWMemoData }>()
);

export const onClaimLevelDataReceived = createAction(
  "[Coverage] Claim Level Data Received",
  props<{ claimData: ClaimLevel }>()
);

export const onCvqAddNoteRequested = createAction(
  "[Coverage] CVQ Add Note Requested",
  props<{ cvqAddNote: CvqAddNoteRequest }>()
);

export const onCvqAddNoteReceived = createAction(
  "[Coverage] CVQ Add Note Data Received",
  props<{ cvqNoteData: CvqNote }>()
);

export const onCvqAddNoteFailed = createAction(
  "[Coverage] CVQ Add Note Failed",
  props<{ errorMessage: string }>()
);
