<mat-card class="p-0 overflow-x-auto out-of-pockets-table-container">
  <mat-card-content>
    <table
      mat-table
      [dataSource]="dataSource"
      data-pgr-id="oop-table"
      class="cui-table out-of-pockets-table"
      aria-labelledby="oop-caption"
    >
      <caption id="oop-caption" class="screen-reader-only">
        Out of Pocket Expenses
      </caption>
      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="cui-cell-12"
          scope="col"
          id="oop-date-header"
        >
          Date
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-12"
          headers="oop-date-header"
        >
          {{ element.expenseDate | date: "MM/dd/yyyy" }}
        </td>
      </ng-container>

      <!-- expenseType Column -->
      <ng-container matColumnDef="expenseType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="cui-cell-12"
          scope="col"
          id="oop-expense-type-header"
        >
          Expense Type
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-12"
          headers="oop-expense-type-header"
        >
          {{ element.expenseTypeDescription }}
        </td>
      </ng-container>

      <!-- comments Column -->
      <ng-container matColumnDef="comments">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="cui-cell-18"
          scope="col"
          id="oop-comments-header"
        >
          Comments
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-18"
          headers="oop-comments-header"
        >
          {{ element.comments }}
        </td>
      </ng-container>

      <!-- amount Column -->
      <ng-container matColumnDef="amount">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="cui-cell-12 text-right"
          scope="col"
          id="oop-amount-header"
        >
          Amount
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cui-cell-12 text-right"
          headers="oop-amount-header"
        >
          {{ element.expenseAmount | currency }}
        </td>
      </ng-container>

      <!-- Empty State -->
      <ng-container matColumnDef="empty">
        <td mat-footer-cell *matFooterCellDef class="p-0" colspan="4">
          <cui-empty-state
            *ngIf="!dataSource.data.length && !loading && !showServerError"
            [announceOnAppear]="true"
            icon="money_off"
            primaryText="No out-of-pockets have been added."
            data-pgr-id="no-oops-added-msg"
          >
          </cui-empty-state>
          <cui-empty-state
            *ngIf="!dataSource.data.length && loading && !showServerError"
            icon="spacer"
            primaryText="Loading..."
          >
          </cui-empty-state>
          <cui-notification-banner
            *ngIf="showServerError"
            type="error"
            data-pgr-id="oop-error-alert"
            [dismissable]="false"
          >
            There was an error retrieving out of pockets.
          </cui-notification-banner>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        data-pgr-id="oopTableRow"
      ></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="['empty']"
        [hidden]="dataSource.data.length"
      ></tr>
    </table>
    <cui-spinner
      *ngIf="loading"
      ariaLabel="Loading out of pockets"
    ></cui-spinner>
  </mat-card-content>
</mat-card>
