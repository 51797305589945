import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiOptions } from "@modules/core/models";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable, of } from "rxjs";
import { first, map, mergeMap } from "rxjs/operators";

import { ApiService } from "../api";

@Injectable()
export class AdapterApiService {
  constructor(private api: ApiService, private config: ConfigurationService) {}

  get<T>(
    url: string,
    path: string,
    headers?: HttpHeaders,
    options?: ApiOptions
  ): Observable<T> {
    return this.buildHeaders(headers).pipe(
      first(),
      mergeMap((hdrs: HttpHeaders) =>
        this.api.get<T>(this.buildUrl(url, path), hdrs, options)
      )
    );
  }

  post<T, R>(
    url: string,
    path: string,
    payload: R,
    headers?: HttpHeaders,
    options?: ApiOptions
  ): Observable<T> {
    return this.buildHeaders(headers).pipe(
      first(),
      mergeMap((hdrs: HttpHeaders) =>
        this.api.post<T, R>(this.buildUrl(url, path), payload, hdrs, options)
      )
    );
  }

  private buildUrl(url: string, path: string): Observable<string> {
    return of(`${url}${path}`);
  }

  private buildHeaders(
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<HttpHeaders> {
    let header = headers;
    return this.config.configuration$.pipe(
      map((configuration: ClaimsSummaryConfigInfo) => {
        if (!header) {
          header = new HttpHeaders();
        }
        header = header
          .append("Pragma", "no-cache")
          .append("Cache-Control", "no-cache")
          .append("Expires", "0")
          .append("X-PGR-APPNAME", configuration.applicationName)
          .append("X-PGR-APPVERSION", configuration.applicationVersion);
        return header;
      })
    );
  }
}
