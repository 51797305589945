import { Injectable } from "@angular/core";
import { ApiErrorResponse } from "@modules/core";
import { coreSharedActions, NotificationType } from "@modules/shared/redux";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, concatMap, switchMap } from "rxjs/operators";

import { CVQ } from "../models/cvq.interface";
import { Rep } from "../models/rep.interface";
import { ClaimLevel } from "../models/uw-memo-claim-level.interface";
import { ClaimPolicy } from "../models/uw-memo-claim-policy.interface";
import { UWMemoData } from "../models/uw-memo-response/uw-memo-response.interface";
import { CoverageService } from "../services/coverage.service";

import * as actions from "./coverage.action";

@Injectable()
export class CoverageEffects {
  detectCVQRequested$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onCVQRequested),
      switchMap((action) =>
        this.coverageService.getCVQs(action.claimNumber).pipe(
          switchMap((data: CVQ[]) =>
            of(
              actions.onCVQReceived({
                CVQList: [...data].sort((a, b) => b.id - a.id),
              })
            )
          ),
          catchError((err: any) => [
            actions.onCVQRequestedError({ error: err }),
          ])
        )
      )
    )
  );

  detectUWMemoClaimPolicyRequested$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onUWMemoClaimPolicyRequested),
      switchMap((action) => {
        return this.coverageService.getClaimPolicy(action.claimNumber).pipe(
          switchMap((claimPolicy: ClaimPolicy) => {
            return of(actions.onUWMemoClaimPolicyRecieved({ claimPolicy }));
          }),
          catchError((error) => this.onError(error))
        );
      })
    )
  );

  detectUWMemoDataRequested$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onUWMemoDataRequested),
      switchMap((action) => {
        return this.coverageService.getUWMemoData(action.claimNumber).pipe(
          switchMap((uwMemoData: UWMemoData) => {
            return of(actions.onUWMemoDataRecieved({ uwMemoData }));
          }),
          catchError((error) => this.onError(error))
        );
      })
    )
  );

  detectClaimLevelDataRequested$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onUWMemoClaimPolicyRequested),
      concatMap((action) => {
        return this.coverageService.getClaimLevelData(action.claimNumber).pipe(
          concatMap((claimLevel: ClaimLevel) => {
            return of(
              actions.onClaimLevelDataReceived({ claimData: claimLevel })
            );
          }),
          catchError((error) => this.onError(error))
        );
      })
    )
  );

  detectCategoryTypeSelection$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onUnderwritingMemoCategoryTypeRequest),
      switchMap((action) => {
        return of(
          actions.onUnderwritingMemoCategoryTypeReceived({
            categorySelected: action.categorySelected,
            typeSelected: action.typeSelected,
          })
        );
      })
    )
  );

  detectRepRequested$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.onRepRequested),
      switchMap((action) =>
        this.coverageService.getRep(action.repCode, true).pipe(
          switchMap((data: Rep) => of(actions.onRepReceived({ rep: data }))),
          catchError((err: any) => this.onError(err))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private coverageService: CoverageService
  ) {}

  private mapErrorMessage = (
    error: any,
    responseMessageMap: { [key: string]: string } = {}
  ): string => {
    if (error.status === 400) {
      const apiError = error.error as ApiErrorResponse;

      if (apiError.errorCode && responseMessageMap[apiError.errorCode]) {
        return responseMessageMap[apiError.errorCode];
      }

      return apiError.attributeErrors.length
        ? apiError.attributeErrors[0].attributeDisplayMessage
        : apiError.developerMessage;
    }

    return "An unknown error occurred. Please try again later.";
  };

  private onError = (
    error: any,
    responseMessageMap: { [key: string]: string } = {}
  ): Action[] => {
    switch (error.status) {
      case 400:
      case 404:
      case 500: {
        const errorMessage = this.mapErrorMessage(error, responseMessageMap);

        return [
          coreSharedActions.onNotificationReq({
            message: errorMessage,
            notificationType: NotificationType.ERROR,
          }),
        ];
      }
      default: {
        return [coreSharedActions.onServerError(error)];
      }
    }
  };
}
