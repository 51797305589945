import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

import * as fromPolicySearch from "./policy-search/policy-search.reducers";
import * as fromPolicy from "./policy/policy.reducers";
import * as fromSpinner from "./spinner/spinner.reducers";
import * as fromUnderwritingMemoLanding from "./uw-memo-landing/uw-memo-landing.reducers";

export interface PolicyState {
  policy: fromPolicy.State;
  spinner: fromSpinner.State;
  policySearch: fromPolicySearch.State;
  uwMemoLanding: fromUnderwritingMemoLanding.State;
}

export const reducers: ActionReducerMap<PolicyState, any> = {
  policy: fromPolicy.reducer,
  spinner: fromSpinner.reducer,
  policySearch: fromPolicySearch.reducer,
  uwMemoLanding: fromUnderwritingMemoLanding.reducer,
};

export const selectPolicyState =
  createFeatureSelector<PolicyState>("policyState");
