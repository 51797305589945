import { createAction, props } from "@ngrx/store";

import {
  ITagsErrorResponse,
  ITagsRecommendRestrictResponse,
} from "../../models/tags";

export class TagsActions {
  public static onRecResTagsRequest = createAction(
    "[Content Tags API] Get Recommend Restrict Tags"
  );

  public static onRecResTagsRequestFailure = createAction(
    "[Content Tags API] Get Recommend Restrict Tags Failure",
    props<{ errorInfo: ITagsErrorResponse }>()
  );

  public static onRecResTagsRequestSuccess = createAction(
    "[Content Tags API] Get Recommend Restrict Tags Success",
    props<{ tags: ITagsRecommendRestrictResponse }>()
  );
}
