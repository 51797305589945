import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { IStoreEntity } from "@modules/electronic-file-folder/models";
import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";
import { ClaimsContentViewerService } from "@modules/electronic-file-folder/services/core/claims-content-viewer/claims-content-viewer.service";
import { WindowManagerService } from "@modules/electronic-file-folder/services/core/window-manager/window-manager.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { EFFConstants } from "../../../constants";
import {
  IContentDetailsGetResponse,
  IContentSummaryItem,
} from "../../../models/content-summary";
import { ActionsValidationUtility } from "../../../utilities/core";
import { SimpleDialogComponent } from "../../shared";

@UntilDestroy()
@Component({
  selector: "cla-content-summary-secondary-actions",
  templateUrl: "./secondary-actions.component.html",
  styleUrls: ["./secondary-actions.component.scss"],
})
export class SecondaryActionsComponent implements OnInit {
  public actionNames = EFFConstants.clickActionNames;
  public selectedItemsCount = 0;

  constructor(
    public contentSummaryViewService: ContentSummaryViewService,
    public contentSummaryStoreService: ContentSummaryStoreService,
    public windowManagerService: WindowManagerService,
    public actionsValidationUtility: ActionsValidationUtility,
    public contentSummaryEventService: ContentSummaryEventService,
    private claimsContentViewerService: ClaimsContentViewerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.contentSummaryStoreService.selectedItems$
      .pipe(untilDestroyed(this))
      .subscribe((x) => (this.selectedItemsCount = x.length));
  }

  public getSelectedItems$(): Observable<IContentSummaryItem[]> {
    const items$ = this.contentSummaryStoreService.selectedItems$.pipe(
      withLatestFrom(this.contentSummaryStoreService.getContentDetails()),
      map(([selectedItems, contentDetails]) => {
        const items: IContentSummaryItem[] = (
          contentDetails?.response?.contentItems || []
        ).filter((item) => selectedItems.includes(item.id));
        return items;
      })
    );
    return items$;
  }

  public launchViewerWindow(): void {
    this.contentSummaryStoreService.selectedItems$
      .pipe(withLatestFrom(this.contentSummaryStoreService.getContentDetails()))
      .subscribe(
        ([selectedItems, contentDetails]: [
          string[],
          IStoreEntity<IContentDetailsGetResponse>
        ]) => {
          const source = contentDetails.response
            ? contentDetails.response.contentItems
            : [];

          const itemsToOpen = source.filter((item) =>
            selectedItems.includes(item.id)
          );

          const openResult =
            this.claimsContentViewerService.processOpenViewer(itemsToOpen);

          if (openResult.error) {
            this.dialog.open(SimpleDialogComponent, {
              data: openResult.error,
              width: CUI_DIALOG_WIDTH.SMALL,
            });
          }
        }
      )
      .unsubscribe();
  }
}
