import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claYesNo",
})
export class YesNoPipe implements PipeTransform {
  public transform(value: string): string {
    let result = value;

    if (value === "Y") {
      result = "Yes";
    } else if (value === "N") {
      result = "No";
    }

    return result;
  }
}
