import { Injectable } from "@angular/core";
import { PolicyDriversRequest, PolicyDriver } from "../../models";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { PolicyDetailsApiService } from "../policy-details-api";

@Injectable()
export class PolicyDetailsService {
  constructor(private policyDetailsApiService: PolicyDetailsApiService) {}

  getListedDrivers(request: PolicyDriversRequest): Observable<PolicyDriver[]> {
    return this.policyDetailsApiService
      .get<PolicyDriver[]>(
        `/v1/policy/${request.policyNumber}/drivers?renewalSuffixNumber=${request.policyRenewalNumber}&dateOfLoss=${request.lossDateTime}`
      )
      .pipe(
        catchError((error) => {
          return this.errorMasking(
            error,
            "Policy Drivers request was unsuccessful"
          );
        })
      );
  }

  private errorMasking(error: HttpErrorResponse, message: string) {
    if (error.status === 404) {
      return throwError(
        () => ({ ...error, statusText: message } as HttpErrorResponse)
      );
    }
    return throwError(() => error);
  }
}
