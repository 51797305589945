<cui-spinner
  *ngIf="
    (notesStateService.isLoading$ | async) ||
    (coreUiStateService.isLoadingRepSummaries$ | async)
  "
></cui-spinner>
<section class="flex flex-col h-full">
  <cla-claim-summary-header
    *ngIf="(notesStateService.isPopout$ | async) === true"
  ></cla-claim-summary-header>
  <cui-notification-banner
    *ngIf="notesStateService.isErroredJournals$ | async"
    (action)="notesStateService.getJournals()"
    actionButtonText="Try Again"
    type="error"
    [actionable]="true"
    [hasBottomBorder]="true"
    class="mb-bl-2"
  >
    An unexpected error occurred while loading PD Suite journals. Please try
    again.
  </cui-notification-banner>
  <cui-notification-banner
    *ngIf="coreUiStateService.isErroredRepSummaries$ | async"
    (action)="coreUiStateService.retryGetRepSummaries()"
    actionButtonText="Try Again"
    type="info"
    [actionable]="true"
    [hasBottomBorder]="true"
    class="mb-bl-2"
  >
    An unexpected error occurred while loading author rep information. Please
    try again.
  </cui-notification-banner>
  <cui-notification-banner
    *ngIf="coreUiStateService.isErroredRepairShopSummaries$ | async"
    (action)="coreUiStateService.retryGetRepairShopSummaries()"
    actionButtonText="Try Again"
    type="info"
    [actionable]="true"
    [hasBottomBorder]="true"
    class="mb-bl-2"
  >
    An unexpected error occurred while loading author repair shop information.
    Please try again.
  </cui-notification-banner>
  <div
    class="relative z-10"
    [ngClass]="{
      'notes-list-centered': (notesStateService.isPopout$ | async) === true
    }"
  >
    <mat-card
      class="py-0 px-bl-2"
      [ngClass]="{
        'mt-bl-2': (notesStateService.isPopout$ | async) === true,
        'ml-bl-1': (notesStateService.isPopout$ | async) === true,
        'rounded-none': (notesStateService.isPopout$ | async) === false,
        'card-borders-sidebar': (notesStateService.isPopout$ | async) === false
      }"
    >
      <cla-note-list-filter></cla-note-list-filter>
      <mat-divider
        *ngIf="(notesStateService.isToDoNotesTabSelected$ | async) === false"
      ></mat-divider>
      <cla-note-list-subheader> </cla-note-list-subheader>
    </mat-card>
  </div>
  <div
    *ngIf="notesStateService.emptyState$ | async as emptyState"
    [ngClass]="{
      'notes-list-centered': (notesStateService.isPopout$ | async) === true
    }"
  >
    <mat-card
      class="mt-bl-2"
      [ngClass]="{
        'ml-bl-1': (notesStateService.isPopout$ | async) === true,
        'rounded-none': (notesStateService.isPopout$ | async) === false
      }"
    >
      <cui-empty-state
        [icon]="emptyState.icon"
        [primaryText]="emptyState.primaryText"
        [secondaryText]="emptyState.secondaryText"
        [actionText]="emptyState.actionText"
        (actionTriggered)="onEmptyStateAction(emptyState.actionText)"
      >
      </cui-empty-state>
    </mat-card>
  </div>
  <ul
    class="flex flex-col overflow-y-auto mt-bl-1 flex-grow"
    [ngClass]="{
      'notes-list-centered': (notesStateService.isPopout$ | async) === true
    }"
    data-pgr-id="objNotesList"
    #notesList
  >
    <li
      *ngFor="
        let note of displayedNotes$ | async | slice: 0:sliceEnd;
        trackBy: trackByFn;
        let i = index
      "
      class="note mat-elevation-z1 mr-bl-1"
      [ngClass]="{
        'embedded-note-card': (notesStateService.isPopout$ | async) === false,
        'non-embedded-note-card': (notesStateService.isPopout$ | async) === true
      }"
      [attr.aria-labelledby]="'note' + (i + 1) + 'header'"
      [attr.data-pgr-id]="'objNote' + (i + 1)"
    >
      <h2 class="screen-reader-only" [attr.id]="'note' + (i + 1) + 'header'">
        Note {{ i + 1 }}
      </h2>
      <div
        class="flex justify-between leading-relaxed"
        [ngClass]="{
          'mb-bl-2': (notesStateService.isPopout$ | async) === true
        }"
      >
        <div class="flex items-baseline">
          <div class="cui-h3" data-pgr-id="txtCategory">
            <span class="screen-reader-only">Category: </span>
            <span
              cla-highlight-search
              [content]="note.category.description"
              [searchTerm]="searchTerm$ | async"
            ></span>
          </div>
          <div class="cui-label pl-bl-2" *ngIf="!!note.subCategoryDescription">
            <span class="screen-reader-only">Sub Category: </span>
            <span
              cla-highlight-search
              [content]="note.subCategoryDescription"
              [searchTerm]="searchTerm$ | async"
            ></span>
          </div>
          <mat-icon
            class="restricted leading-normal ml-bl-2"
            *ngIf="note.access.isRestricted"
            >lock_outline</mat-icon
          >
        </div>
      </div>
      <div class="flex flex-wrap text-sm leading-normal">
        <cui-popover
          *ngIf="
            (coreUiStateService.repSummaries$ | async)[
              note.authoredUserId
            ] as repSummary;
            else elseRepairShop
          "
          [align]="
            (notesStateService.isPopout$ | async) ? 'rightBottom' : 'bottomLeft'
          "
        >
          <button
            class="cui-popover-link by-line font-extra-bold mr-bl-2"
            data-pgr-id="btnOpenClaimRepContactCard"
            cui-popover-trigger
          >
            <span
              cla-highlight-search
              [content]="
                note.authoredUserId
                  | claRepName
                    : repSummary
                    : (notesStateService.isPopout$ | async) === true
              "
              [searchTerm]="searchTerm$ | async"
            ></span>
          </button>
          <cla-claim-rep-contact-card-redacted
            [rep]="repSummary"
            cui-popover-content
          ></cla-claim-rep-contact-card-redacted>
        </cui-popover>
        <ng-template #elseRepairShop>
          <cui-popover
            *ngIf="
              (coreUiStateService.repairShopSummaries$ | async)[
                note.authoredUserId
              ] as repairShopSummary;
              else elseLoading
            "
          >
            <button
              class="cui-popover-link by-line font-extra-bold mr-bl-2"
              data-pgr-id="btnOpenClaimRepairShopContactCard"
              cui-popover-trigger
            >
              <span
                cla-highlight-search
                [content]="repairShopSummary.name"
                [searchTerm]="searchTerm$ | async"
              ></span>
            </button>
            <cui-claim-repair-shop-contact-card
              [repairShop]="repairShopSummary"
              cui-popover-content
            >
            </cui-claim-repair-shop-contact-card>
          </cui-popover>
          <ng-template #elseLoading>
            <div
              *ngIf="
                (coreUiStateService.isLoadingRepSummaries$ | async) ||
                  (coreUiStateService.isLoadingRepairShopSummaries$ | async);
                else elseBlock
              "
              class="ghost-line h-xxs mt-0 w-20 mr-bl-2"
            ></div>
            <ng-template #elseBlock>
              <span
                class="by-line font-extra-bold mr-bl-2"
                cla-highlight-search
                [content]="note.authoredUserId"
                [searchTerm]="searchTerm$ | async"
              ></span>
            </ng-template>
          </ng-template>
        </ng-template>
        <div class="inline-block">
          <time
            class="cui-label"
            data-pgr-id="txtCreatedDate"
            [attr.datetime]="note.createdDate"
            cla-highlight-search
            [content]="
              ' ' +
              (note.createdDate
                | claTimezoneDate: dateDisplayFormat.luxon:'America/New_York')
            "
            [searchTerm]="searchTerm$ | async"
          ></time>
          <ng-container
            *ngIf="
              claimDetailsStateService.claimSummary$ | async as claimSummary
            "
          >
            <span
              class="cui-label"
              data-pgr-id="txtTimeAfterReport"
              cla-highlight-search
              [content]="
                '&nbsp;&nbsp;|&nbsp;&nbsp;' +
                (claimSummary.reportDate | claTimeSince: note.createdDate)
              "
              [searchTerm]="searchTerm$ | async"
            ></span>
          </ng-container>
        </div>
      </div>
      <p
        class="text-base leading-normal pr-m whitespace-pre-wrap break-words"
        cla-highlight-search
        [content]="note | claNoteText"
        [searchTerm]="searchTerm$ | async"
      ></p>
    </li>
  </ul>
</section>
