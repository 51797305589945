import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CoreUiExtensionsActions } from "@modules/core-ui-extensions/actions";
import {
  ClaimRepContactCardInfoRedacted,
  CoreUiExtensionsState,
  RepLoadingState,
} from "@modules/core-ui-extensions/models";
import {
  selectErroredRepairShopCodes,
  selectErroredRepCodes,
  selectIsErroredRepairShopSummaries,
  selectIsErroredRepSummaries,
  selectIsLoadingRepairShopSummaries,
  selectIsLoadingRepSummaries,
  selectNotFoundRepairShopCodes,
  selectNotFoundRepCodes,
  selectPartyErrorById,
  selectPartyIsLoadingById,
  selectPartySummariesById,
  selectRepairShopSummaries,
  selectRepLoadingStates,
  selectRepSummaries,
  selectRequestedRepCodes,
} from "@modules/core-ui-extensions/selectors";
import { Dictionary } from "@ngrx/entity";
import { Store } from "@ngrx/store";
import {
  ClaimPartyContactCardInfo,
  ClaimRepairShopContactCardInfo,
} from "@pgr-cla/core-ui-components";
import { map, Observable } from "rxjs";

@Injectable()
export class CoreUiExtensionsStateService {
  public repSummaries$: Observable<
    Record<string, ClaimRepContactCardInfoRedacted>
  > = this.store$.select(selectRepSummaries);
  public repLoadingStates$: Observable<Record<string, RepLoadingState>> =
    this.store$.select(selectRepLoadingStates);
  public requestedRepCodes$: Observable<string[]> = this.store$.select(
    selectRequestedRepCodes
  );
  public isLoadingRepSummaries$: Observable<boolean> = this.store$.select(
    selectIsLoadingRepSummaries
  );
  public isErroredRepSummaries$: Observable<boolean> = this.store$.select(
    selectIsErroredRepSummaries
  );
  public erroredRepCodes$: Observable<string[]> = this.store$.select(
    selectErroredRepCodes
  );
  public notFoundRepCodes$: Observable<string[]> = this.store$.select(
    selectNotFoundRepCodes
  );

  public selectPartyIsLoadingById(partyId: string): Observable<boolean> {
    return this.store$.select(selectPartyIsLoadingById(partyId));
  }

  public selectPartyErrorById(
    partyId: string
  ): Observable<HttpErrorResponse | undefined> {
    return this.store$.select(selectPartyErrorById(partyId));
  }

  public selectPartyContactCardById(
    partyId: string
  ): Observable<ClaimPartyContactCardInfo> {
    return this.store$.select(selectPartySummariesById(partyId));
  }

  public repSummaryByCode(
    code: string
  ): Observable<ClaimRepContactCardInfoRedacted | undefined> {
    return this.repSummaries$.pipe(map((x) => x[code]));
  }

  public repairShopSummaries$: Observable<
    Dictionary<ClaimRepairShopContactCardInfo>
  > = this.store$.select(selectRepairShopSummaries);
  public isLoadingRepairShopSummaries$: Observable<boolean> =
    this.store$.select(selectIsLoadingRepairShopSummaries);
  public isErroredRepairShopSummaries$: Observable<boolean> =
    this.store$.select(selectIsErroredRepairShopSummaries);
  public erroredRepairShopCodes$: Observable<string[]> = this.store$.select(
    selectErroredRepairShopCodes
  );
  public notFoundRepairShopCodes$: Observable<string[]> = this.store$.select(
    selectNotFoundRepairShopCodes
  );

  constructor(private store$: Store<CoreUiExtensionsState>) {}

  public getRepSummaries(codes: string[]): void {
    this.store$.dispatch(
      CoreUiExtensionsActions.onGetRepSummariesRequest({ codes })
    );
  }

  public retryGetRepSummaries(): void {
    this.store$.dispatch(
      CoreUiExtensionsActions.onRetryGetRepSummariesRequest()
    );
  }

  public getPartySummaries(partyId: string): void {
    this.store$.dispatch(
      CoreUiExtensionsActions.onGetPartySummariesRequest({ partyId })
    );
  }

  public getRepairShopSummaries(codes: string[]): void {
    this.store$.dispatch(
      CoreUiExtensionsActions.onGetRepairShopSummariesRequest({ codes })
    );
  }

  public retryGetRepairShopSummaries(): void {
    this.store$.dispatch(
      CoreUiExtensionsActions.onRetryGetRepairShopSummariesRequest()
    );
  }
}
