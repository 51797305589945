import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ClaimSummaryStateService } from "@modules/claims-summary/services";
import { ClaimsSummaryEventService } from "@modules/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EventDispatchService, EventModel } from "@pgr-cla/cla-analytics";
import { filter, take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "cla-claim-summary",
  templateUrl: "claim-summary.component.html",
  styleUrls: ["./claim-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimSummaryComponent {
  constructor(
    public claimSummaryStateService: ClaimSummaryStateService,
    private claimsSummaryEventService: ClaimsSummaryEventService,
    private eventDispatchService: EventDispatchService
  ) {
    claimSummaryStateService.onFeaturesRequest();
    claimSummaryStateService.onLiabilityRequest();
    claimSummaryStateService.onCoveragesRequest();

    this.claimSummaryStateService.isFeaturesRequestInFlight$
      .pipe(
        filter((inFlight: boolean) => !inFlight),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const pageView: EventModel =
          this.claimsSummaryEventService.summaryPageView();
        this.eventDispatchService.trackPageView(pageView);
      });
  }
}
