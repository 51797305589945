/* eslint-disable @typescript-eslint/typedef */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private injector: Injector) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 403) {
            this.router.navigate(["forbidden"]);
          }
          if (response.status === 401) {
            this.router.navigate(["unauthenticated"]);
          }
          if (response.status === 500) {
            const loggingService: LoggingService =
              this.injector.get(LoggingService);
            loggingService.log(
              response.message,
              LoggedMessageLevel.Error,
              "20000"
            );
          }
        }
      })
    );
  }
}
