/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store";
import { Observable } from "rxjs";
import {
  selectNavigationId,
  selectRouterData,
  selectRouterParameters,
  selectRouterQueryParams,
  selectRouterUrl,
} from "./router.selectors";

@Injectable()
export class RouterStateService {
  constructor(private store$: Store<AppState>) {}

  // TODO: inspect this since Angular recommends NavigationCancel instead of selectNavigationId: https://v13.angular.io/guide/update-to-latest-version#:~:text=PR%C2%A0%2343496-,The,-router%20no%20longer
  navigation$: Observable<number> = this.store$.select(selectNavigationId);
  routerUrl$: Observable<any> = this.store$.select(selectRouterUrl);
  routeParams$: Observable<any> = this.store$.select(selectRouterParameters);
  queryParams$: Observable<any> = this.store$.select(selectRouterQueryParams);
  routeData$: Observable<any> = this.store$.select(selectRouterData);
}
