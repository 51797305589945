import { ActionReducerMap } from "@ngrx/store";
import * as fromList from "./list.reducers";
import * as fromHistory from "./history.reducers";
import * as fromSpinner from "./spinner.reducers";

export interface FeatureRevisionState {
  list: fromList.State;
  history: fromHistory.RevisionHistoryState;
  spinner: fromSpinner.State;
}

export const reducers: ActionReducerMap<FeatureRevisionState, any> = {
  list: fromList.reducer,
  history: fromHistory.reducer,
  spinner: fromSpinner.reducer,
};
