import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from "@angular/core";
import { MatTabNav } from "@angular/material/tabs";
import {
  LeftRailResizeService,
  RightRailResizeService,
} from "@modules/claims-landing/services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { delay } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "cla-alerts-bar",
  templateUrl: "alerts-bar.component.html",
  styleUrls: ["./alerts-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsBarComponent implements AfterViewInit {
  @ViewChild("tabBar") tabBar: MatTabNav;

  constructor(
    private leftRailResizeService: LeftRailResizeService,
    private rightRailResizeService: RightRailResizeService
  ) {}

  ngAfterViewInit(): void {
    this.leftRailResizeService.opened$
      .pipe(untilDestroyed(this), delay(180))
      .subscribe(() => this.tabBar.updatePagination());
    this.rightRailResizeService.opened$
      .pipe(untilDestroyed(this), delay(180))
      .subscribe(() => this.tabBar.updatePagination());
    this.rightRailResizeService.widthMinMax$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.tabBar.updatePagination());
    this.leftRailResizeService.widthMinMax$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.tabBar.updatePagination());
  }
}
