<mat-icon
  class="surcharge-indicator material-icons-outlined text-mediumGray"
  [attr.aria-label]="
    'Massachusetts Surcharge Code:\n' + (surchargeDescription$ | async)
  "
  aria-roledescription="tooltip"
  [attr.aria-hidden]="false"
  [matTooltip]="'MA Surcharge Code:\n' + (surchargeDescription$ | async)"
  matTooltipClass="surcharge-tooltip"
  tabindex="0"
  >info</mat-icon
>
