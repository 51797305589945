import { ClaimSummaryState } from "@modules/claims-summary/models";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ClaimDetailsState } from "../claim-details/models";
import { UserState } from "./user.reducer";

export const selectUserState = createFeatureSelector<UserState>("user");

export const claimDetailsState =
  createFeatureSelector<ClaimDetailsState>("claimDetails");

export const claimSummaryState =
  createFeatureSelector<ClaimSummaryState>("claimSummaryState");

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user ?? null
);
export const selectUserLoading = createSelector(
  selectUserState,
  (state: UserState) => state.isLoading
);
export const selectUserError = createSelector(
  selectUserState,
  (state: UserState) => state.error
);
export const selectRepCode = createSelector(
  selectUserState,
  (state: UserState) => state.user?.repCode ?? null
);
export const selectOrgCode = createSelector(
  selectUserState,
  (state: UserState) => state.user?.orgCode ?? null
);
export const selectIsManager = createSelector(
  selectUserState,
  (state: UserState) => state.user?.isManager ?? null
);
export const selectIsPipAuthorized = createSelector(
  selectUserState,
  (state: UserState) => state.user?.isPipAuthorized ?? null
);
export const selectPhysicalDamagePaymentAuthority = createSelector(
  selectUserState,
  (state: UserState) => state.user?.physicalDamageAuthorityAmount ?? null
);
export const selectCanViewPrivateFields = createSelector(
  selectUserState,
  claimDetailsState,
  claimSummaryState,
  (
    userState: UserState,
    claimDetails: ClaimDetailsState,
    claimSummary: ClaimSummaryState
  ) => {
    let result = false;
    const repCode = userState.user?.repCode;
    if (userState.user?.isManager) {
      result = true;
    } else if (claimDetails?.claimDetails?.claimOwningRepCode === repCode) {
      result = true;
    } else {
      const featureList = claimSummary?.features;
      featureList?.forEach((feature) => {
        if (
          feature.owningRepCode === repCode ||
          feature.assistingRepCode === repCode
        ) {
          result = true;
        }
      });
    }

    return result;
  }
);
