import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute } from "@angular/router";
import { ClaimDetailsStateService } from "@modules/core/state";
import { AppState } from "@modules/feature-revision-history/models/core/app-state.interface";
import { Feature } from "@modules/feature-revision-history/models/feature-revision";
import { Store, select } from "@ngrx/store";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { FeatureListForm } from "../../models/feature-revision/feature-list";
import { historyActions, listActions } from "../../state/actions";
import { listSelectors, spinnerSelectors } from "../../state/selectors";

@Component({
  selector: "cla-feature-history",
  templateUrl: "./feature-history.component.html",
  styleUrls: ["./feature-history.component.scss"],
})
export class FeatureHistoryComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild(MatSidenav, { static: true }) public sideSheet: MatSidenav;

  canOpenHistoryFilter = false;
  isLoading$: Observable<boolean>;
  claimNumber$: Observable<any>;
  features$: Observable<FeatureListForm[] | null | Feature[]>;
  claimNumber = "";

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private sideSheetService: SideSheetService,
    private store$: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {}

  ngOnInit(): void {
    this.init();
    this.sideSheetService.register("FeatureRevisionMain", this.sideSheet);
    this.claimDetailsStateService.onSetPageScrollingEnabled(false);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    this.store$.dispatch(listActions.clearReq());
    this.claimDetailsStateService.onSetPageScrollingEnabled(true);
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  onEscapeOrBackDropClick() {
    this.store$.dispatch(historyActions.closeHistoryFilter());
  }

  private init() {
    this.isLoading$ = this.store$.pipe(
      select(spinnerSelectors.selectIsSpinnerActive)
    );

    this.claimNumber$ = this.route.params.pipe(
      filter(
        (params) =>
          params["claimNumber"] !== undefined && params["claimNumber"] !== ""
      ),
      map((params) => params["claimNumber"])
    );

    this.claimNumber$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((claimNumber) => {
        this.claimNumber = claimNumber;
        this.store$.dispatch(
          listActions.getClaimFeaturesWithPreReq({ claimNumber })
        );
      });

    this.features$ = this.store$.pipe(select(listSelectors.selectFeatures));
  }

  onOpenFilter() {
    if (this.canOpenHistoryFilter) {
      this.store$.dispatch(historyActions.closeHistoryFilter());
    } else {
      const sideSheet = this.sideSheetService.get("FeatureRevisionMain");

      if (sideSheet) {
        sideSheet.open();
      }
    }
  }
}
