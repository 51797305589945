import { createAction, props } from "@ngrx/store";

import { CodeTable } from "@claimssummaryshared/models";

export const loadCodes = createAction(
  "[Codes] Load Codes",
  props<{ tableNames: string[] }>()
);

export const retrieveCodes = createAction(
  "[Codes API] Retrieve Codes",
  props<{ tableNames: string[] }>()
);

export const retrieveCodesSuccess = createAction(
  "[Codes API] Retrieve Codes Success",
  props<{ codes: CodeTable[] }>()
);
