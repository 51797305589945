import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  ClaimsUiModule,
  CoreUiModule,
  ThemePickerModule,
} from "@pgr-cla/core-ui-components";
import { HighlightSearchDirective } from "@shared/directives";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

import { MaterialModule } from "./material.module";
import {
  ClaimProEmbeddedHeaderPipe,
  ClaimProEmbeddedPipe,
  ClaimProLoadingMessagePipe,
  ClaimStatusPipe,
  DigitReaderPipe,
  EmbeddedParametersPipe,
  EmptyDashPipe,
  MaskAriaLabelPipe,
  NoCommaPipe,
  NullDefaultPipe,
  PartyNamePipe,
  PaymentEventTypePipe,
  PhysicalDamageAuthorityEventAuthorPipe,
  RepNamePipe,
  SafeUrlPipe,
  TimeSincePipe,
  TimezoneDatePipe,
  TrimPipe,
  TruncationPipe,
  ValueOrNAPipe,
  ValueOrNaFormatterDirective,
  YesNoPipe,
} from "./pipes";
import {
  ClaimDetailsService,
  ClaimSearchService,
  CodesService,
  RepAdminService,
} from "./services";

import { CdkCopyToClipboard, ClipboardModule } from "@angular/cdk/clipboard";
import { ResizableModule } from "angular-resizable-element";
import {
  AuthCallbackComponent,
  ButtonToggleInputComponent,
  ChsDataDetectionDialogComponent,
  ClaimRepContactCardRedactedComponent,
  ClaimSummaryHeaderComponent,
  ClaimSummaryHeaderDataComponent,
  ConfirmDialogComponent,
  ContactPreferenceDetectionDialogComponent,
  CopyButtonComponent,
  ObfuscateAutocompleteComponent,
  PingLandingComponent,
  ServerErrorBannerComponent,
  TableHeaderLabelComponent,
} from "./components";
import { ClaPageLoadingSpinnerComponent } from "./components/cla-large-loading-spinner/cla-page-loading-spinner/cla-page-loading-spinner.component";
import { ContactCardComponent } from "./components/contact-card";
import { GoogleMapsAddressComponent } from "./components/google-maps-address/google-maps-address.component";
import { MailToComponent } from "./components/mail-to/mail-to.component";
import {
  OnDestroyMoveFocusDirective,
  PreventCutCopyDirective,
} from "./directives";
import { DialogService } from "./services/dialog";

@NgModule({
  imports: [
    ClaimsUiModule,
    ClipboardModule,
    CommonModule,
    CoreUiModule,
    FormsModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    ResizableModule,
    ThemePickerModule,
  ],
  providers: [
    provideNgxMask(),
    CdkCopyToClipboard,
    ClaimDetailsService,
    ClaimSearchService,
    ClaimProEmbeddedPipe,
    ClaimProLoadingMessagePipe,
    ClaimProEmbeddedHeaderPipe,
    CodesService,
    CurrencyPipe,
    DatePipe,
    DialogService,
    EmbeddedParametersPipe,
    EmptyDashPipe,
    PartyNamePipe,
    PaymentEventTypePipe,
    PhysicalDamageAuthorityEventAuthorPipe,
    RepAdminService,
    TrimPipe,
    ValueOrNAPipe,
    DigitReaderPipe,
    NoCommaPipe,
    TruncationPipe,
    ValueOrNaFormatterDirective,
    YesNoPipe,
    TimezoneDatePipe,
    TimeSincePipe,
    RepNamePipe,
  ],
  declarations: [
    HighlightSearchDirective,
    AuthCallbackComponent,
    PingLandingComponent,
    ButtonToggleInputComponent,
    ChsDataDetectionDialogComponent,
    ClaPageLoadingSpinnerComponent,
    ClaimProEmbeddedPipe,
    ClaimProLoadingMessagePipe,
    ClaimProEmbeddedHeaderPipe,
    ClaimStatusPipe,
    ConfirmDialogComponent,
    ContactPreferenceDetectionDialogComponent,
    ClaimRepContactCardRedactedComponent,
    ContactCardComponent,
    EmbeddedParametersPipe,
    EmptyDashPipe,
    GoogleMapsAddressComponent,
    MailToComponent,
    MaskAriaLabelPipe,
    ObfuscateAutocompleteComponent,
    PartyNamePipe,
    PaymentEventTypePipe,
    PhysicalDamageAuthorityEventAuthorPipe,
    SafeUrlPipe,
    ServerErrorBannerComponent,
    TrimPipe,
    YesNoPipe,
    ValueOrNAPipe,
    NullDefaultPipe,
    DigitReaderPipe,
    NoCommaPipe,
    TruncationPipe,
    TimezoneDatePipe,
    TimeSincePipe,
    RepNamePipe,
    ValueOrNaFormatterDirective,
    PreventCutCopyDirective,
    OnDestroyMoveFocusDirective,
    TableHeaderLabelComponent,
    CopyButtonComponent,
    ClaimSummaryHeaderComponent,
    ClaimSummaryHeaderDataComponent,
  ],
  exports: [
    HighlightSearchDirective,
    AuthCallbackComponent,
    PingLandingComponent,
    ButtonToggleInputComponent,
    ClaPageLoadingSpinnerComponent,
    ClaimProEmbeddedPipe,
    ClaimProLoadingMessagePipe,
    ClaimProEmbeddedHeaderPipe,
    ClaimStatusPipe,
    ClaimsUiModule,
    ClaimRepContactCardRedactedComponent,
    ContactCardComponent,
    CommonModule,
    CoreUiModule,
    CurrencyPipe,
    DatePipe,
    EmbeddedParametersPipe,
    EmptyDashPipe,
    FormsModule,
    GoogleMapsAddressComponent,
    MailToComponent,
    MaskAriaLabelPipe,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ObfuscateAutocompleteComponent,
    PartyNamePipe,
    PaymentEventTypePipe,
    PhysicalDamageAuthorityEventAuthorPipe,
    ReactiveFormsModule,
    ResizableModule,
    SafeUrlPipe,
    ServerErrorBannerComponent,
    ThemePickerModule,
    TrimPipe,
    YesNoPipe,
    ValueOrNAPipe,
    NullDefaultPipe,
    DigitReaderPipe,
    NoCommaPipe,
    TruncationPipe,
    TimezoneDatePipe,
    TimeSincePipe,
    RepNamePipe,
    ValueOrNaFormatterDirective,
    PreventCutCopyDirective,
    OnDestroyMoveFocusDirective,
    TableHeaderLabelComponent,
    CopyButtonComponent,
    ClaimSummaryHeaderComponent,
    ClaimSummaryHeaderDataComponent,
  ],
})
export class SharedModule {}
