<cui-data-list class="table" density="condensed">
  <cui-data-list-row label="Renewal Counter">
    <div class="policyRow">
      {{ policyDetail?.policyNumberSuffix }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Membership">
    <div class="policyRow">
      {{ agreementLookup?.loyaltyLevelName | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Product">
    <div class="policyRow">
      {{ policyDetail?.productCategoryDesc | titlecase | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Policy State">
    <div class="policyRow">
      {{ policyDetail?.policyStateCode | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row labelSpan="10rem" label="Company">
    <cui-copy-button
      ariaLabel="copy company name"
      [dataToCopy]="policyDetail?.companyNameLong50"
    >
      <div class="company-container policyRow">
        {{ policyDetail?.companyNameLong50 | claValueOrNA }}
      </div>
    </cui-copy-button>
  </cui-data-list-row>
  <cui-data-list-row label="Company Code">
    <cui-copy-button
      ariaLabel="copy company code"
      [dataToCopy]="policyDetail?.programCompanyCode"
    >
      <div class="company-container policyRow">
        {{ policyDetail?.programCompanyCode | claValueOrNA }}
      </div>
    </cui-copy-button>
  </cui-data-list-row>
  <cui-data-list-row label="Form">
    <div class="policyRow">
      {{ policyDetail?.policyFormNumber | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Edition">
    <div class="policyRow">
      {{ edition | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Effective Date">
    <div class="policyRow">
      {{ policyDetail?.startDate | date: "MM/dd/yyyy" | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Expiration Date">
    <div class="policyRow">
      {{ policyDetail?.endDate | date: "MM/dd/yyyy" | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Cancellation Date">
    <div class="policyRow">
      {{ policyDetail?.cancelDate | date: "MM/dd/yyyy" | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="Customer Since">
    <div class="policyRow">
      {{ customerSinceDate | date: "MM/dd/yyyy" | claValueOrNA }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="PUC Filing">
    <div class="policyRow">
      {{ policyFillIndicator }}
    </div>
  </cui-data-list-row>
  <cui-data-list-row label="PIP Opted Out #" *ngIf="isPIPOptOutCount">
    <div class="policyRow">
      {{ policyDetail.pipOptOutCount }}
    </div>
  </cui-data-list-row>
</cui-data-list>
