import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { SubroFeaStatCodes } from "@modules/subrogation-summary/constants";
import { SubrogationSummaryCodes } from "@modules/subrogation-summary/models/subrogation-summary-codes.interface";
import { select, Store } from "@ngrx/store";
import { SubroFeature } from "@subrogation-summary/models";
import {
  selectPartyPropertyInfo,
  selectSubroFeatures,
  SubrogationSummaryState,
} from "@subrogation-summary/state/reducers";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Component({
  selector: "cla-subro-features",
  templateUrl: "./subro-features.component.html",
  styleUrls: ["./subro-features.component.scss"],
})
export class SubroFeaturesComponent implements OnInit {
  @Input() adversePartyName: string;
  @Input() partyId: number;
  @Input() partySequenceNumber: number;
  @Input() subroObligationId: number;
  @Input() claimNumber: number;
  @Input() liabilityPercentage: number;
  @Input() public detailCodes: SubrogationSummaryCodes;

  @ViewChild(MatTable) table: MatTable<any>;

  @Output() featureUpdated: EventEmitter<any> = new EventEmitter();

  hasDeductibleInfo: boolean;
  dataSource: MatTableDataSource<SubroFeature>;
  subroFeatures$: Observable<SubroFeature[]>;

  SubroFeaStatCodes = SubroFeaStatCodes;

  closeFeatureMapping = { "=1": "Close Feature", other: "Close Features" };

  displayedColumns: string[] = [
    "subroStatus",
    "featureSequenceNumber",
    "partyProperty",
    "lineCoverage",
    "collectingOrgId",
    "collectingRepId",
    "statuteOfLimitations",
    "featurePaymentAmount",
    "deductibleWithheldAmount",
    "salvageAmount",
    "calculatedFeatureCollectibleForParty",
    "featureCollectibleAmountForParty",
    "grossRecoveryAmount",
    "lossCashRecovery",
    "balanceDue",
    "featureWriteOffAmount",
    "featureNetSubroRecovery",
  ];

  totals: { [key: string]: number } = {
    featurePaymentAmount: 0,
    deductibleWithheldAmount: 0,
    salvageAmount: 0,
    calculatedFeatureCollectibleForParty: 0,
    featureCollectibleAmountForParty: 0,
    grossRecoveryAmount: 0,
    lossCashRecovery: 0,
    balanceDue: 0,
    featureWriteOffAmount: 0,
    featureNetSubroRecovery: 0,
  };

  constructor(
    private store$: Store<SubrogationSummaryState>,
    public coreUiStateService: CoreUiExtensionsStateService
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<SubroFeature>();
    this.subroFeatures$ = this.store$.pipe(
      select(selectSubroFeatures, {
        subroObligationId: this.subroObligationId,
      }),
      map((subroFeatures) =>
        subroFeatures.filter((sf) => this.isActiveFeature(sf))
      ),
      tap((subroFeatures) => {
        this.dataSource.data = subroFeatures;
        this.totals = this.calculateTotals(this.totals, subroFeatures);
        this.hasDeductibleInfo = subroFeatures.some(
          (element) =>
            element.deductibleWithheldAmount > 0 &&
            element.subroFeaStatCode !== SubroFeaStatCodes.OnHold
        );
      })
    );
  }

  getPartyPropertyInfo(featureId: number) {
    return this.store$.pipe(select(selectPartyPropertyInfo, { featureId }));
  }

  trackBySubroFeatureId(index: number, subroFeature: SubroFeature) {
    return subroFeature.subroFeatureId;
  }

  // shouldShowMasterCheckbox() {
  //   return false;
  // }

  isActiveFeature(fea: SubroFeature): boolean {
    return (
      (fea.subroFeatureCloseReasonCode !== "16" &&
        fea.subroFeatureCloseReasonCode !== "06") ||
      fea.subroFeaStatCode !== SubroFeaStatCodes.Closed
    );
  }

  scrollIntoView(el: HTMLElement) {
    const rightMostStickyHeader = el
      .closest(".mat-table")
      ?.querySelector(".mat-column-lineCoverage");
    const scroller = el.closest(".fcd-table-scroller");
    if (rightMostStickyHeader && scroller) {
      const leftDiff =
        rightMostStickyHeader.getBoundingClientRect().right -
        el.getBoundingClientRect().left;
      const rightDiff =
        scroller.getBoundingClientRect().right -
        el.getBoundingClientRect().right;

      if (leftDiff > 0) {
        // eslint-disable-next-line functional/immutable-data
        scroller.scrollLeft -= leftDiff;
      } else if (rightDiff < 0) {
        // eslint-disable-next-line functional/immutable-data
        scroller.scrollLeft += Math.abs(rightDiff) + el.offsetWidth - 61; // accounts for size of element and size of edit button on right side
      }
    }
  }

  public calculateTotals(
    totals: { [key: string]: number },
    subroFeatures: SubroFeature[]
  ): { [key: string]: number } {
    const featuresWithoutOnHold = subroFeatures.filter(
      (fea) => fea.subroFeaStatCode !== SubroFeaStatCodes.OnHold
    );

    const newTotals = { ...totals };
    Object.keys(this.totals).forEach((key) => {
      // eslint-disable-next-line functional/immutable-data
      newTotals[key] =
        featuresWithoutOnHold
          .map((sf: any) => sf[key])
          .reduce((acc: number, value: number) => acc + value, 0) || 0;
    });
    return newTotals;
  }
}
