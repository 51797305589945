import { Injectable } from "@angular/core";
import { SubrogationSummaryApiService } from "@subrogation-summary/services";
import { Observable, of } from "rxjs";
import {
  AdverseParty,
  OutOfPocket,
  RecoveryHistoryItem,
  SubrogationSummaryCodes,
  ClaimFeature,
} from "@modules/subrogation-summary/models";

@Injectable()
export class SubrogationSummaryService {
  constructor(
    private subrogationSummaryApiService: SubrogationSummaryApiService
  ) {}

  public getSubrogationSummaryCodes(
    claimNumber: string
  ): Observable<SubrogationSummaryCodes> {
    return this.subrogationSummaryApiService.get<SubrogationSummaryCodes>(
      `/lossviewerapi/v1/claims/${claimNumber}/subrogation/subro-codes
      `
    );
  }

  public getAdverseParties(claimNumber: string): Observable<AdverseParty[]> {
    return this.subrogationSummaryApiService.get<AdverseParty[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/subrogation/adverse-parties`
    );
  }

  public getOutOfPockets(claimNumber: string): Observable<OutOfPocket[]> {
    return this.subrogationSummaryApiService.get<OutOfPocket[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/subrogation/outofpockets`
    );
  }

  public getRecoveryHistory(
    claimNumber: string,
    partySequenceNumber: number
  ): Observable<RecoveryHistoryItem[]> {
    return this.subrogationSummaryApiService.get<RecoveryHistoryItem[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/recoveries?partySequenceNumber=${partySequenceNumber}`
    );
  }

  public getClaimFeature(claimNumber: string): Observable<ClaimFeature[]> {
    // let temp : ClaimFeature[];
    // temp = [{"featureId":90529757,"featureSequenceNumber":1,"featureTypeCode":2,"lineCoverage":"2209","coverageShortDescription":"TNC COLL 3","partyBusinessInd":"","partyName":{"first":"","middleInitial":"","last":"","suffix":""},"businessName":"","claimPartyId":0,"propertyClaimTypeCode":"V","vehicle":{"typeCode":"V","year":16,"make":"KIA","model":"FORTE","id":12053247},"fixedProperty":null,"featureDeductibleWithheld":0.00,"featureTotalPaymentAmount":0.00,"featureCollectibleAmount":0.00,"featureStatusCode":"4","featureOpenDate":"20220610","featureStatus":"OPEN"},{"featureId":90529758,"featureSequenceNumber":2,"featureTypeCode":2,"lineCoverage":"2053","coverageShortDescription":"TNC PD 3","partyBusinessInd":"","partyName":{"first":"","middleInitial":"","last":"","suffix":""},"businessName":"","claimPartyId":0,"propertyClaimTypeCode":"V","vehicle":{"typeCode":"V","year":35,"make":"UNKNOWN","model":"UNKNOWN","id":12053248},"fixedProperty":null,"featureDeductibleWithheld":0.00,"featureTotalPaymentAmount":0.00,"featureCollectibleAmount":0.00,"featureStatusCode":"4","featureOpenDate":"20220610","featureStatus":"OPEN"},{"featureId":90529759,"featureSequenceNumber":3,"featureTypeCode":1,"lineCoverage":"1953","coverageShortDescription":"TNC BI 3","partyBusinessInd":"N","partyName":{"first":"ANDY","middleInitial":"","last":"BERNARD","suffix":""},"businessName":"","claimPartyId":142208536,"propertyClaimTypeCode":"","vehicle":null,"fixedProperty":null,"featureDeductibleWithheld":0.00,"featureTotalPaymentAmount":0.00,"featureCollectibleAmount":0.00,"featureStatusCode":"4","featureOpenDate":"20220610","featureStatus":"OPEN"}];
    // return of(temp);
    return this.subrogationSummaryApiService.get<ClaimFeature[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/subrogation/subro-features`
    );
  }
}
