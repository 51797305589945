import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CoreUiExtensionsActions } from "@modules/core-ui-extensions/actions";
import {
  ClaimDetails,
  ClaimReportedVia,
  ClaimSummary,
  PartyPropertyDetail,
  PolicyDriver,
} from "@modules/core/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { ClaimDetailsService } from "@shared/services/claim-details";
import { AppState } from "@store";
import { DateTime } from "luxon";
import { Observable, from, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import * as sharedActions from "../../redux/helper/core-shared.actions";
import { PolicyDetailsService } from "../../services/policy-details";
import * as claimDetailsActions from "./claim-details.actions";
import { selectClaimNumber } from "./claim-details.selectors";

@Injectable()
export class ClaimDetailsEffects {
  private claimNumber$: Observable<string> =
    this.store$.select(selectClaimNumber);
  private cControlDate = DateTime.fromJSDate(new Date(2006, 6, 1));
  private cClruCode = "CLRU";
  private cUnknown = "UNKNOWN";

  detectClaimDetailsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDetailsActions.onClaimDetailsRequest),
      mergeMap((action) =>
        this.claimDetailsService.getClaimDetails(action.claimNumber).pipe(
          switchMap((data: ClaimDetails) => {
            let actions: Action[] = [
              claimDetailsActions.onClaimDetailsReceipt({ payload: data }),
              claimDetailsActions.onClaimReportedViaRequest({
                claimNumber: action.claimNumber,
                claimReportedDate: data.claimReportDate,
              }),
            ];
            if (data.claimOwningRepCode) {
              actions = [
                CoreUiExtensionsActions.onGetRepSummariesRequest({
                  codes: [data.claimOwningRepCode],
                }),
                ...actions,
              ];
            }
            if (data.claimNumber && action.redirectToClaim) {
              this.router.navigate([`${data.claimNumber}`]);
            }
            return from(actions);
          }),
          catchError((error) =>
            this.onClaimDetailsError(error, action.fromClaimSearch)
          )
        )
      )
    )
  );

  detectGetClaimSummaryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDetailsActions.onClaimSummaryRequest),
      mergeMap((action) =>
        this.claimDetailsService.getClaimSummary(action.claimNumber).pipe(
          switchMap((data: ClaimSummary) => {
            return of(
              claimDetailsActions.onClaimSummaryReceipt({
                payload: data,
              })
            );
          }),
          catchError((error) =>
            of(claimDetailsActions.onClaimSummaryError({ error }))
          )
        )
      )
    )
  );

  detectClaimReportedViaRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDetailsActions.onClaimReportedViaRequest),
      mergeMap((action) =>
        this.claimDetailsService.getClaimReportedVia(action.claimNumber).pipe(
          switchMap((data: ClaimReportedVia) => {
            let reportedViaValue = data.reportedVia ? data.reportedVia : "";
            const claimReportDate = DateTime.fromISO(action.claimReportedDate);
            if (reportedViaValue === "") {
              if (claimReportDate < this.cControlDate) {
                reportedViaValue = this.cClruCode;
              } else {
                reportedViaValue = this.cUnknown;
              }
            }
            return of(
              claimDetailsActions.onClaimReportedViaReceipt({
                payload: reportedViaValue,
              })
            );
          }),
          catchError((error) =>
            of(claimDetailsActions.onClaimReportedViaError({ error }))
          )
        )
      )
    )
  );

  detectPolicyDriversRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDetailsActions.onGetPolicyDriversRequest),
      mergeMap((action) =>
        this.policyDetailsService.getListedDrivers(action.payload).pipe(
          switchMap((data: PolicyDriver[]) => {
            return of(
              claimDetailsActions.onGetPolicyDriversResponse({
                payload: data,
              })
            );
          }),
          catchError((error) =>
            of(claimDetailsActions.onGetPolicyDriversError({ error }))
          )
        )
      )
    )
  );

  detectPartyPropertyDetailsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDetailsActions.onPartyPropertyDetailsRequest),
      mergeMap((action) =>
        this.claimDetailsService
          .getPartyPropertyDetails(action.claimNumber)
          .pipe(
            map((data: PartyPropertyDetail[]) =>
              claimDetailsActions.onPartyPropertyDetailsReceipt({
                payload: data,
              })
            ),
            catchError((error) =>
              of(claimDetailsActions.onPartyPropertyDetailsError({ error }))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private claimDetailsService: ClaimDetailsService,
    private policyDetailsService: PolicyDetailsService,
    private logging: LoggingService,
    private router: Router,
    private store$: Store<AppState>
  ) {}

  private onClaimDetailsError = (
    error: HttpErrorResponse,
    fromClaimSearch?: boolean
  ) => {
    let actions: Action[] = [
      claimDetailsActions.onClaimDetailsError({ error }),
    ];
    if (error.status === 404 && !fromClaimSearch) {
      this.logging.log(error.error, LoggedMessageLevel.Error);
      this.router.navigate(["claim-not-found"]);
    } else if (error.status === 404 && fromClaimSearch) {
      return from(actions);
    } else {
      actions = [sharedActions.onServerError({ error }), ...actions];
    }

    return from(actions);
  };
}
