<main role="main" data-pgr-id="tleCoverage" class="h-full">
  <div class="lg-container">
    <div class="flex justify-between">
      <h1 class="cui-h1 mb-xxs" id="main-content-header" tabindex="-1">
        Coverage
      </h1>
    </div>
    <mat-expansion-panel
      class="mb-bl-2 rounded-lg flex-wrap"
      role="region"
      aria-labelledby="cvqs"
      data-pgr-id="cvqPanel"
    >
      <mat-expansion-panel-header
        class="h64"
        data-pgr-id="cvqExpandArrow"
        aria-label="expand cvq"
      >
        <mat-panel-title>
          <div>
            <span class="cui-h2" id="cvqs">CVQ</span>
            <h5 class="cui-h5" data-pgr-id="resolvedCvqText">
              {{ resolvedCvqCount$ | async }} out of
              {{ (cvqs$ | async)?.length }} Resolved
            </h5>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <cla-cvq-tab> </cla-cvq-tab>
      </div>
    </mat-expansion-panel>
    <cla-uw-memo-landing [claimNumber]="claimNumber" [userId]="userId">
    </cla-uw-memo-landing>
  </div>
  <hr size="2px" width="94%" color="lightgrey" />
  <div data-pgr-id="policyPanel" class="lg-container">
    <div class="flex justify-between mb-xs items-center">
      <h1 class="cui-h1 mb-xxs">Policy</h1>
      <span
        *ngIf="isLoading$ | async; else lossDateLoaded"
        class="ghost-line w-10"
      ></span>
      <ng-template #lossDateLoaded>
        <span *ngIf="claimInfo$ | async as claimInfo"
          ><b>As of Loss Date: </b
          >{{ zoneParsedDateOfLoss | claValueOrNA }}</span
        >
      </ng-template>
    </div>
    <div class="mt-bl-2">
      <div *ngIf="isLoading$ | async; else policyLoaded">
        <cla-coverage-policy-container-ghost-loader></cla-coverage-policy-container-ghost-loader>
      </div>
      <ng-template #policyLoaded></ng-template>
      <mat-card
        *ngIf="claimPolicy$ | async as claimPolicy"
        role="region"
        aria-labelledby="policyHeader"
        class="policy-card"
      >
        <ng-container
          *ngIf="claimPolicy?.isPolicyAttached; then policy; else nopolicy"
        ></ng-container>
        <ng-template #policy>
          <div class="flex justify-between">
            <div class="flex items-center">
              <mat-icon class="veh-svg" svgIcon="{{ svgImage }}"></mat-icon>
              <div>
                <div class="flex justify-between">
                  <h2
                    class="cui-h2"
                    data-pgr-id="policyTitle"
                    id="policyHeader"
                  >
                    {{ claimPolicy.productDesc | titlecase }}
                    <span
                      *ngIf="
                        claimPolicy.productDesc && claimPolicy.policyNumber
                      "
                      >-</span
                    >
                    #{{ claimPolicy.policyNumber | titlecase
                    }}<span
                      *ngIf="
                        claimPolicy.policyNumber &&
                        claimPolicy.renewalSuffixNumber
                      "
                      >-</span
                    >{{
                      claimPolicy.renewalSuffixNumber
                        | titlecase
                        | number: "3.0"
                    }}
                    <span *ngIf="claimInfo$ | async as claimInfo">
                      - {{ claimInfo.policyState.description | titlecase }}
                    </span>
                  </h2>
                </div>
                <div class="flex">
                  <cui-status-indicator
                    *ngIf="policyStatus$ | async as policyStatus"
                    [status]="
                      !!statusIndicators[policyStatus?.code]
                        ? statusIndicators[policyStatus?.code]
                        : 'alert'
                    "
                    [background]="false"
                    data-pgr-id="policyStatusIndicator"
                  >
                    <span class="screen-reader-only">Status</span
                    >{{ policyStatus?.description }}
                  </cui-status-indicator>
                  <div *ngIf="isThirdPartyIndicator">
                    <cui-status-indicator
                      status="alert"
                      [background]="false"
                      data-pgr-id="thirdPartyIndicator"
                    >
                      Third Party Designee
                    </cui-status-indicator>
                    <mat-icon
                      class="ml-bl-2 text-gray-600 align-middle"
                      aria-hidden="false"
                      aria-label="Third Party Designee exists. Please go to Policy to view details."
                      matTooltip="Third Party Designee exists. Please go to Policy to view details."
                    >
                      help
                    </mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <cui-data-list [border]="false">
              <cui-data-list-row label="Effective">
                {{
                  claimPolicy?.policyStartDate
                    | date: "MM/dd/yyyy"
                    | claValueOrNA
                }}
                -
                {{
                  claimPolicy?.policyEndDate | date: "MM/dd/yyyy" | claValueOrNA
                }}
              </cui-data-list-row>
              <ng-container
                *ngIf="isBusiness; then company; else person"
              ></ng-container>
              <ng-template #company>
                <cui-data-list-row label="Company">
                  {{ businessName | titlecase | claValueOrNA }}
                </cui-data-list-row>
              </ng-template>
              <ng-template #person>
                <cui-data-list-row label="Named Insured">
                  {{ namedInsured | titlecase | claValueOrNA }}
                </cui-data-list-row>
              </ng-template>
              <cui-data-list-row label="Cancellation Date">
                {{ cancelDate | date: "MM/dd/yyyy" | claValueOrNA }}
              </cui-data-list-row>
            </cui-data-list>
          </div>
          <div
            class="card-body"
            *ngIf="claimCoverages$ | async as claimCoverages"
          >
            <cla-claim-coverage
              [claimCoverages]="claimCoverages"
              [policyAttachVehicle]="policyAttachVehicle$ | async"
              [policyTitle]="policyTitle"
              [dateOfLoss]="dateOfLoss"
              [lossVehicles]="policyLossVehicles$ | async"
              [partyDrivers]="partyDrivers$ | async"
            >
            </cla-claim-coverage>
          </div>
          <mat-tab-group
            class="mt-bl-2"
            mat-align-tabs="start"
            mat-stretch-tabs="false"
          >
            <mat-tab label="Insured">
              <ng-container
                *ngIf="
                  policyDetail$ | async;
                  then policyDetail;
                  else policyDetailError
                "
              ></ng-container>
              <ng-template #policyDetail>
                <div
                  class="flex flex-wrap items-start p-bl-1"
                  *ngIf="policyDetail$ | async as policyDetail"
                >
                  <div
                    class="card min-w-sm max-w-sm mr-xxs mb-xxs"
                    role="region"
                    aria-labelledby="policyDetails"
                  >
                    <div class="card-header">
                      <h3 class="cui-body" id="policyDetails">
                        <b>Details</b>
                      </h3>
                    </div>
                    <div class="card-body">
                      <cla-policy-info
                        [policyDetailInput]="policyDetail"
                        [agreementLookupInput]="agreementLookup$ | async"
                      >
                      </cla-policy-info>
                    </div>
                  </div>
                  <div
                    class="card mr-xxs min-w-sm mb-xxs"
                    role="region"
                    aria-labelledby="policyDetails"
                  >
                    <div class="card-header">
                      <h3 class="cui-body" id="policyNameInsured">
                        <b>Named Insured</b>
                      </h3>
                    </div>
                    <div class="card-body">
                      <cla-policy-name-insured
                        [policyDetail]="policyDetail"
                        [agreementLookupInput]="agreementLookup$ | async"
                      >
                      </cla-policy-name-insured>
                    </div>
                    <div class="ani">
                      <cla-policy-additional-name-insured
                        [policyDetail]="policyDetail"
                      >
                      </cla-policy-additional-name-insured>
                    </div>
                  </div>
                  <div
                    class="card mr-xxs min-w-sm"
                    role="region"
                    aria-labelledby="policyDetails"
                  >
                    <div class="card-header">
                      <h3 class="cui-body" id="policyAgent"><b>Agent</b></h3>
                    </div>
                    <div class="card-body">
                      <cla-policy-agent-info
                        [policyDetail]="policyDetail"
                        [agentInfo]="agentInfo$ | async"
                      >
                      </cla-policy-agent-info>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template #policyDetailError>
                <cui-empty-state
                  icon="people"
                  primaryText="INSURED"
                  secondaryText="Currently unable to load insured information for this policy."
                >
                </cui-empty-state>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="cui-tab-counter">
                  {{
                    (policyAttachVehicle$ | async)
                      ? (policyAttachVehicle$ | async)?.length
                      : 0
                  }}
                </div>
                Vehicles
              </ng-template>
              <ng-container
                *ngIf="
                  (isPolicyVehicleLoading$ | async) == true;
                  then policyVehiceLoading;
                  else policyVehiceNotLoading
                "
              >
              </ng-container>
              <ng-template #policyVehiceLoading>
                <cui-empty-state icon="search" primaryText="Loading Vehicles">
                </cui-empty-state>
              </ng-template>
              <ng-template #policyVehiceNotLoading>
                <ng-container
                  *ngIf="
                    (policyAttachVehicle$ | async)?.length;
                    then validPolicyAttachVehicles;
                    else noPolicyAttachVehicles
                  "
                >
                </ng-container>
                <ng-template #validPolicyAttachVehicles>
                  <cla-vehicles-container></cla-vehicles-container>
                </ng-template>
                <ng-template #noPolicyAttachVehicles>
                  <cui-empty-state
                    icon="search_off"
                    primaryText="No Vehicles Found"
                  >
                  </cui-empty-state>
                </ng-template>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="cui-tab-counter">
                  {{
                    (policyDrivers$ | async)
                      ? (policyDrivers$ | async)?.length
                      : 0
                  }}
                </div>
                Drivers
              </ng-template>
              <ng-container
                *ngIf="
                  (isPolicyDriversLoading$ | async) == true;
                  then policyDriverLoading;
                  else policyDriverNotLoading
                "
              >
              </ng-container>
              <ng-template #policyDriverLoading>
                <cui-empty-state icon="search" primaryText="Loading Drivers">
                </cui-empty-state>
              </ng-template>
              <ng-template #policyDriverNotLoading>
                <ng-container
                  *ngIf="
                    (policyDrivers$ | async)?.length;
                    then validPolicyDrivers;
                    else noPolicyDrivers
                  "
                >
                </ng-container>
                <ng-template #validPolicyDrivers>
                  <cla-policy-drivers
                    [claimNumber]="claimNumber"
                    [policyDrivers]="policyDrivers$ | async"
                  >
                  </cla-policy-drivers>
                </ng-template>
                <ng-template #noPolicyDrivers>
                  <cui-empty-state
                    icon="search_off"
                    primaryText="No Drivers Found"
                  >
                  </cui-empty-state>
                </ng-template>
              </ng-template>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="cui-tab-counter">
                  {{
                    (policyEndorsements$ | async)
                      ? (policyEndorsements$ | async)?.length
                      : 0
                  }}
                </div>
                Endorsements
              </ng-template>
              <ng-container
                *ngIf="
                  (isEndorsementsLoading$ | async) == true;
                  then policyEndorsementsLoading;
                  else policyEndorsementsNotLoading
                "
              >
              </ng-container>
              <ng-template #policyEndorsementsLoading>
                <cui-empty-state
                  icon="search"
                  primaryText="Loading Endorsements"
                >
                </cui-empty-state>
              </ng-template>
              <ng-template #policyEndorsementsNotLoading>
                <ng-container
                  *ngIf="
                    (policyEndorsements$ | async)?.length;
                    then validEndorsements;
                    else noEndorsements
                  "
                >
                </ng-container>
                <ng-template #validEndorsements>
                  <cla-policy-endorsements
                    [policyEndorsements]="policyEndorsements$ | async"
                  ></cla-policy-endorsements>
                </ng-template>
                <ng-template #noEndorsements>
                  <cui-empty-state
                    icon="search_off"
                    primaryText="No Endorsements Found"
                  >
                  </cui-empty-state>
                </ng-template>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </ng-template>
      </mat-card>
      <ng-template #nopolicy>
        <cui-empty-state
          data-pgr-id="no-policy-attached"
          icon="search"
          primaryText="No policy attached."
          actionText=""
        >
        </cui-empty-state>
      </ng-template>
    </div>
  </div>
</main>
