import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
@Injectable()
export class PreliminaryClaimMessageResolver implements Resolve<string> {
  constructor(
    private claimNumberPipe: ClaimNumberPipe,
    private details: ClaimDetailsStateService
  ) {}
  resolve(): Observable<string> {
    return this.details.claimNumber$.pipe(
      first(),
      map((claimnumber) => {
        if (claimnumber) {
          return `The requested claim (${this.claimNumberPipe.transform(
            claimnumber
          )}) is currently in Preliminary status.`;
        }
        return "The requested claim is currently in Preliminary status.";
      })
    );
  }
}
