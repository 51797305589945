export const jpgJpegMimeTypes = [
  "application/jpg",
  "application/x-jpg",
  "image/jpeg",
  'image/jpeg; version="1.0"',
  'image/jpeg; version="1.00"',
  'image/jpeg; version="1.01"',
  'image/jpeg; version="1.02"',
  'image/jpeg; version="2.0"',
  'image/jpeg; version="2.1"',
  'image/jpeg; version="2.2"',
  'image/jpeg; version="2.2.1"',
];
