import { Injectable } from "@angular/core";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { BusinessApiService } from "@modules/core/services/business-api";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";

@Injectable({ providedIn: "root" })
export class SubrogationSummaryApiService {
  constructor(
    private api: BusinessApiService,
    private config: ConfigurationService
  ) {}

  get<T>(path: string): Observable<T> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) =>
        this.api.get<T>(config.subrogationSummaryApiUrl, path)
      )
    );
  }
}
