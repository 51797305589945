<mat-accordion class="p-0">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header
      [collapsedHeight]="'60px'"
      [expandedHeight]="'60px'"
    >
      <mat-panel-title>
        <h2 class="cui-h2 p-0" data-pgr-id="lblFeatureTileHeader">
          <span
            [ngClass]="isLoading ? 'cui-ghost-line' : ''"
            color="primary"
            class="features-count mr-bl-1 features-count-color-inactive"
            >{{ !isLoading ? features?.length : "" }}</span
          >
          Feature<span *ngIf="features?.length !== 1">s </span>
          <span class="pl-bl-2">
            <button
              mat-icon-button
              aria-label="navigate to feature"
              class="align-middle"
              color="primary"
              (click)="navigateToFeature()"
              (keydown.enter)="navigateToFeature()"
              (keydown.space)="navigateToFeature()"
            >
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </span>
        </h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <ng-container *ngIf="!isLoading && featuresError">
      <div class="overflow-x-auto">
        <cui-notification-banner
          *ngIf="!isLoading && featuresError"
          [actionable]="true"
          (action)="fetchFeaturesData.emit()"
          actionButtonText="Try Again"
          type="error"
        >
          An unexpected error occurred. Please try again.
        </cui-notification-banner>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoading; else loadedContent">
      <div class="feature-row" *ngFor="let ghostRow of ghostRows">
        <div class="feature-list-container">
          <div role="list" class="feature-list-details-container">
            <div class="feature-item">
              <div class="cui-ghost-line"></div>
            </div>
            <div
              class="feature-item"
              role="listitem"
              class="feature-description-container"
            >
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
            <div class="feature-item" role="listitem">
              <div class="cui-ghost-line"></div>
            </div>
          </div>
          <div class="" style="margin-top: 1.2rem; gap: 0 !important">
            <div class="col-span-1"></div>
            <div class="col-span-7" role="listitem">
              <div class="flex" style="gap: 1.2rem">
                <div class="feature-item">
                  <div
                    class="cui-ghost-line w-8"
                    style="border-radius: 1rem"
                  ></div>
                </div>
                <div class="feature-item">
                  <div
                    class="cui-ghost-line w-12"
                    style="border-radius: 1rem"
                  ></div>
                </div>
                <div class="feature-item">
                  <div
                    class="cui-ghost-line w-8"
                    style="border-radius: 1rem"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #loadedContent>
      <ng-container role="list" *ngFor="let feature of features; let i = index">
        <div role="listitem" *ngIf="i <= 4 || expanded">
          <div class="feature-row">
            <div class="feature-list-container">
              <div role="list" class="feature-list-details-container">
                <div
                  class="justify-self-start feature-item"
                  attr.data-pgr-id="{{ 'lblFeatureSequenceNumber.' + i }}"
                >
                  <span class="font-bold">
                    {{ feature.sequenceNumber.toString() | number: "3.0" }}
                  </span>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblFeatureColumn.' + i }}"
                  class="feature-description-container"
                >
                  <span class="font-bold">
                    {{ feature.coverageShortDescription }} -
                    {{ feature.featureDescription }}
                  </span>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblStatusColumn.' + i }}"
                >
                  <cui-status-indicator
                    [background]="true"
                    [status]="STATUS_INDICATORS[feature.statusCode]"
                  >
                    {{ feature.statusDescription }}
                  </cui-status-indicator>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblFeatureOwnerColumn.' + i }}"
                >
                  <div *ngIf="feature.owningRepCode">
                    <div class="font-bold">Owner:</div>
                    <cla-contact-card
                      [repInfo]="feature.ownerRepSummary"
                      [tpxId]="feature.owningRepCode"
                      [isLoadingRepInfo]="
                        coreUiStateService.isLoadingRepSummaries$ | async
                      "
                    ></cla-contact-card>
                  </div>

                  <span *ngIf="!feature.owningRepCode">
                    <div class="font-bold">Owner:</div>
                    <div>Unassigned</div>
                  </span>
                </div>
                <div
                  class="feature-item feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblTotalLoss.' + i }}"
                >
                  <div class="font-bold">Total Loss:</div>
                  <div>
                    {{ feature.isTotalLoss | claEmptyDash }}
                  </div>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblReserveColumn.' + i }}"
                >
                  <div class="font-bold">Reserve:</div>
                  <div>
                    {{
                      feature.owningRepCode
                        ? feature.statusDescription == "CLSD" ||
                          feature.statusDescription == "CWP"
                          ? ("" | claEmptyDash)
                          : (feature.adjusterCaseReserveAmount
                            | currency: "USD")
                        : (0 | currency: "USD")
                    }}
                  </div>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblPaidColumn.' + i }}"
                >
                  <div class="font-bold">Paid:</div>
                  <div>
                    {{ feature.featureTotalPaymentAmount | currency: "USD" }}
                  </div>
                </div>
                <div
                  class="feature-item"
                  role="listitem"
                  attr.data-pgr-id="{{ 'lblOrgCodeColumn.' + i }}"
                >
                  <div class="font-bold">Org:</div>
                  <div>
                    {{ feature.owningOrgCode }}
                  </div>
                </div>
              </div>
            </div>
            <div class="feature-list-details-container">
              <div class="col-span-1"></div>
              <div class="col-span-7 justify-self-start">
                <ng-container *ngFor="let chip of feature.tileChips">
                  <span class="feature-chip">
                    <button
                      *ngIf="isIpcChip(chip); else nonIpcChip"
                      [attr.data-pgr-id]="'btnToolChipIpc'"
                      [matTooltip]="
                        'Click here to view or edit IPC information'
                      "
                      matTooltipClass="line-broken-tooltip"
                      class="cui-chip-link mr-bl-2"
                      (click)="
                        ipcWindowService.open(
                          feature.partySequenceNumber,
                          feature.sequenceNumber
                        )
                      "
                    >
                      IPC
                    </button>
                    <ng-template #nonIpcChip>
                      <a
                        [attr.data-pgr-id]="'lnkToolChip.' + chip.type"
                        class="cui-chip-link mr-bl-2"
                        [matTooltip]="chip | claFeatureTileChip"
                        matTooltipClass="line-broken-tooltip"
                        [routerLink]="getChipURL(chip)"
                        [state]="getChipState(chip)"
                      >
                        {{ chip.type }}
                      </a>
                    </ng-template>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </ng-template>
    <div
      *ngIf="!isLoading && features && features.length > 5"
      class="p-xxs flex justify-center"
    >
      <button
        mat-flat-button
        color="primary"
        class="view-all-expand"
        data-pgr-id="btnViewAllFeatures"
        (click)="toggleExpanded()"
      >
        {{ expanded ? "View Less" : "View All" }}
        <mat-icon iconPositionEnd>{{
          expanded ? "expand_less" : "expand_more"
        }}</mat-icon>
      </button>
    </div>
    <mat-paginator
      class="hidden"
      [attr.aria-hidden]="true"
      [pageSize]="pageSize"
    ></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>
