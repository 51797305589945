import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { CodesService } from "@modules/shared/services/codes";
import { concatMap, filter, map, withLatestFrom } from "rxjs/operators";

import * as codesActions from "./codes.actions";
import { CodesState } from "./codes.reducer";
import { isCodesLoaded, selectCodeTableIds } from "./codes.selectors";

@Injectable()
export class CodesEffects {
  loadCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(codesActions.loadCodes),
      withLatestFrom(
        this.store$.select(selectCodeTableIds),
        this.store$.select(isCodesLoaded)
      ),
      filter(([{ tableNames }, currTableNames, isLoaded]) =>
        this.shouldRetrieve(tableNames, currTableNames as string[], isLoaded)
      ),
      map(([{ tableNames }]) => codesActions.retrieveCodes({ tableNames }))
    )
  );

  retrieveCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(codesActions.retrieveCodes),
      concatMap(({ tableNames }) =>
        this.codesService.getCodeTables(tableNames)
      ),
      map((codes) => codesActions.retrieveCodesSuccess({ codes }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<CodesState>,
    private codesService: CodesService
  ) {}

  shouldRetrieve(
    tableNames: string[],
    currTableNames: string[],
    isLoaded: boolean
  ): boolean {
    const hasNewName = tableNames.some(
      (name) => !currTableNames.includes(name)
    );
    return hasNewName || tableNames.length > currTableNames.length || !isLoaded;
  }
}
