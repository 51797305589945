import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChangeDetectionService } from "@modules/core/services/change-detection/change-detection.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ClaimPartyContactCardInfo } from "@pgr-cla/core-ui-components";
import _ from "lodash";
import { Observable, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";
import { CoreUiExtensionsActions } from "../actions";
import { RepairShopSummariesResponse, RepSummariesResponse } from "../models";
import {
  CoreUiAdapterService,
  CoreUiExtensionsStateService,
} from "../services";

@Injectable()
export class CoreUiExtensionsEffects {
  public detectGetRepSummariesRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreUiExtensionsActions.onGetRepSummariesRequest),
      withLatestFrom(this.coreUiStateService.requestedRepCodes$),
      map(([action, previouslyRequestedCodes]) =>
        _.difference(action.codes, previouslyRequestedCodes)
      ),
      filter((newCodesOnly) => newCodesOnly.length > 0),
      map((newCodesOnly) =>
        CoreUiExtensionsActions.onGetNewRepSummariesOnlyRequest({
          codes: newCodesOnly,
        })
      )
    )
  );

  public detectNewRepSummariesRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreUiExtensionsActions.onGetNewRepSummariesOnlyRequest),
      mergeMap((action) =>
        this.coreUiAdapterService.getRepSummaries(action.codes).pipe(
          map((response: RepSummariesResponse) =>
            CoreUiExtensionsActions.onGetRepSummariesRequestSuccess({
              repSummaries: response.list,
            })
          ),
          catchError(() => {
            return of(
              CoreUiExtensionsActions.onGetRepSummariesRequestFailure()
            );
          })
        )
      )
    )
  );

  public detectRetryGetRepSummariesRequest$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreUiExtensionsActions.onRetryGetRepSummariesRequest),
        withLatestFrom(this.coreUiStateService.requestedRepCodes$),
        map(([, previouslyRequestedCodes]) =>
          CoreUiExtensionsActions.onGetRepSummariesRequest({
            codes: previouslyRequestedCodes,
          })
        )
      )
  );

  public detectGetPartySummariesRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreUiExtensionsActions.onGetPartySummariesRequest),
      mergeMap((action) =>
        this.coreUiAdapterService.getPartySummary(action.partyId).pipe(
          map((ClaimPartyContactCardInfo: ClaimPartyContactCardInfo) =>
            CoreUiExtensionsActions.onGetPartySummariesRequestSuccess({
              ClaimPartyContactCardInfo,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CoreUiExtensionsActions.onGetPartySummariesRequestFailure({
                error,
                partyId: action.partyId,
              })
            )
          )
        )
      )
    )
  );

  public detectGetRepairShopSummaryRequest$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreUiExtensionsActions.onGetRepairShopSummaryRequest),
        map((action) =>
          CoreUiExtensionsActions.onGetRepairShopSummariesRequest({
            codes: [action.code],
          })
        )
      )
  );

  public detectGetRepairShopSummariesRequest$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreUiExtensionsActions.onGetRepairShopSummariesRequest),
        withLatestFrom(
          this.coreUiStateService.repairShopSummaries$,
          this.coreUiStateService.notFoundRepairShopCodes$
        ),
        map(([action, repairShopSummaries, notFoundRepairShopCodes]) => [
          action,
          !!_.difference(action.codes, [
            ...Object.keys(repairShopSummaries),
            ...notFoundRepairShopCodes,
          ]).length,
        ]),
        mergeMap(([action, isRequestRequired]: [any, boolean]) =>
          isRequestRequired
            ? this.coreUiAdapterService
                .getRepairShopSummaries(action.codes)
                .pipe(
                  map((response: RepairShopSummariesResponse) =>
                    CoreUiExtensionsActions.onGetRepairShopSummariesRequestSuccess(
                      {
                        response,
                      }
                    )
                  ),
                  catchError(() => {
                    return of(
                      CoreUiExtensionsActions.onGetRepairShopSummariesRequestFailure(
                        {
                          codes: action.codes,
                        }
                      )
                    );
                  })
                )
            : of(
                CoreUiExtensionsActions.onGetRepairShopSummariesRequestCanceled()
              )
        )
      )
  );

  public detectGetRepairShopSummariesChangeDetectionEvent$: Observable<any> =
    createEffect(
      () =>
        this.actions$.pipe(
          ofType(
            CoreUiExtensionsActions.onGetRepairShopSummariesRequest,
            CoreUiExtensionsActions.onGetRepairShopSummariesRequestSuccess,
            CoreUiExtensionsActions.onGetRepairShopSummariesRequestFailure,
            CoreUiExtensionsActions.onGetRepairShopSummariesRequestCanceled
          ),
          tap(() => this.changeDetectionService.emitDetectChangesEvent())
        ),
      { dispatch: false }
    );

  public detectRetryGetRepairShopSummariesRequest$: Observable<any> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(CoreUiExtensionsActions.onRetryGetRepairShopSummariesRequest),
        withLatestFrom(this.coreUiStateService.erroredRepairShopCodes$),
        filter((erroredRepairShopCodes) => !!erroredRepairShopCodes.length),
        map(([, erroredRepairShopCodes]) =>
          CoreUiExtensionsActions.onGetRepairShopSummariesRequest({
            codes: erroredRepairShopCodes,
          })
        )
      )
    );

  constructor(
    private actions$: Actions,
    private coreUiAdapterService: CoreUiAdapterService,
    private coreUiStateService: CoreUiExtensionsStateService,
    private changeDetectionService: ChangeDetectionService
  ) {}
}
