import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const EXPANSION_PANEL_ANIMATION_TIMING =
  "225ms cubic-bezier(0.4,0.0,0.2,1)";

export const lossDetailsAnimations: {
  readonly indicatorRotate: AnimationTriggerMetadata;
  readonly additionalDetailsExpansion: AnimationTriggerMetadata;
} = {
  indicatorRotate: trigger("indicatorRotate", [
    state("collapsed, void", style({ transform: "rotate(0deg)" })),
    state("expanded", style({ transform: "rotate(180deg)" })),
    transition(
      "expanded <=> collapsed, void => collapsed",
      animate(EXPANSION_PANEL_ANIMATION_TIMING)
    ),
  ]),

  additionalDetailsExpansion: trigger("additionalDetailsExpansion", [
    state(
      "collapsed, void",
      style({ overflow: "hidden", height: "0px", visibility: "hidden" })
    ),
    state(
      "expanded",
      style({ overflow: "hidden", height: "*", visibility: "visible" })
    ),
    transition(
      "expanded <=> collapsed, void => collapsed",
      animate(EXPANSION_PANEL_ANIMATION_TIMING)
    ),
  ]),
};
