import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { formatPartyName } from "../../../shared/helpers/party-name-format/party-name-format";
import { PartyDetail } from "../../models";
import { ClaimCoverage } from "../../models/claim-coverage";
import { LossVehicle } from "../../models/loss-vehicle";
import { PolicyVehicle } from "../../models/policy-vehicle";

@Component({
  selector: "cla-claim-coverage",
  templateUrl: "./claim-coverage.component.html",
  styleUrls: ["./claim-coverage.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimCoverageComponent {
  @Input() claimCoverages: ClaimCoverage[];
  @Input() policyAttachVehicle: PolicyVehicle[];
  @Input() lossVehicles: LossVehicle[];
  @Input() policyTitle: string;
  @Input() dateOfLoss: Date;
  @Input() isLoading: boolean;
  @Input() partyDrivers: PartyDetail[];

  getLossDriver(propertyId?: number) {
    let formattedLossDriver = "";

    if (this.partyDrivers && propertyId) {
      this.partyDrivers.forEach((lossDrivers) => {
        if (
          lossDrivers.associatedVehicle &&
          lossDrivers.associatedVehicle.id === propertyId &&
          (lossDrivers.associatedVehicle.partyRelationToVehicle === "D" ||
            lossDrivers.associatedVehicle.partyRelationToVehicle === "U")
        ) {
          if (lossDrivers.partyName) {
            formattedLossDriver +=
              formatPartyName(
                lossDrivers.partyName.first,
                lossDrivers.partyName.middleInitial,
                lossDrivers.partyName.last,
                lossDrivers.partyName.suffix
                  ? "(" + lossDrivers.partyName.suffix + ")"
                  : undefined
              ) + "\n";
          }
        }
      });
    }

    return formattedLossDriver.trimEnd();
  }
}
