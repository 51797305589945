import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Rep } from "@claimssummaryshared/models";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { ClaimDetails } from "@modules/core/models";
import {
  AppPathService,
  AuthenticationService,
  TNCLossViewerSplunkLoggingService,
} from "@modules/core/services";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import {
  ClaimsSummaryConfigInfo,
  ConfirmDialogOptions,
} from "@modules/shared/models";
import { DialogService } from "@modules/shared/services/dialog/dialog.service";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";

@Component({
  selector: "cla-claim-header",
  templateUrl: "claim-header.component.html",
  styleUrls: ["./claim-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimHeaderComponent implements OnInit, OnDestroy {
  @Input() showData: boolean = true;

  headerData: { [key: string]: string | boolean | object };
  ownerRepCode: string;
  ownerRepSummary?: ClaimRepContactCardInfoRedacted;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  environment: string;
  subtitle: string;
  readonly reportTimeZoneMap: Record<string, string> = {
    AT: "America/Glace_Bay",
    ET: "America/New_York",
    CT: "America/Chicago",
    MT: "America/Denver",
    PT: "America/Los_Angeles",
    AL: "America/Anchorage",
    HT: "Pacific/Honolulu",
  };
  constructor(
    private claimNumberPipe: ClaimNumberPipe,
    public claimDetailsStateService: ClaimDetailsStateService,
    public coreUiStateService: CoreUiExtensionsStateService,
    private config: ConfigurationService,
    public appPathService: AppPathService,
    private authService: AuthenticationService,
    private tncLossViewerSplunkLoggingService: TNCLossViewerSplunkLoggingService,
    private readonly _dialogService: DialogService
  ) {
    this.claimDetailsStateService.claimDetails$
      .pipe(
        filter((claimDetails: ClaimDetails | undefined) => !!claimDetails),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((claimDetails: ClaimDetails) => {
        this.ownerRepCode = claimDetails.claimOwningRepCode;
        this.headerData = this.formatHeaderData(claimDetails);
      });
    this.coreUiStateService.repSummaries$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(
        (repSummaries: Record<string, ClaimRepContactCardInfoRedacted>) =>
          (this.ownerRepSummary = repSummaries[this.ownerRepCode])
      );
    this.config.configuration$
      .pipe(
        first(
          (configInfo: ClaimsSummaryConfigInfo) =>
            configInfo !== null && config !== undefined
        )
      )
      .subscribe(
        (configInfo: ClaimsSummaryConfigInfo) =>
          (this.environment = configInfo.environment)
      );
  }

  ngOnInit(): void {
    this.getSubtitle(this.environment);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  formatHeaderData({
    claimNumber,
    isPartyBusiness,
    namedInsured,
    claimLossDate,
    claimReportDate,
    claimReportTimeZone,
  }: ClaimDetails): { [key: string]: string | boolean | object } {
    const reportDate = DateTime.fromISO(claimReportDate).setZone(
      this.reportTimeZoneMap[claimReportTimeZone]
    );
    const currentDate = DateTime.now();
    const sinceReport = currentDate.diff(reportDate, [
      "days",
      "hours",
      "minutes",
    ]);
    return {
      claimNumber: this.claimNumberPipe.transform(claimNumber) || "",
      insured: isPartyBusiness
        ? namedInsured.businessName
        : this.formatNamedInsured(namedInsured),
      dateOfLoss: DateTime.fromISO(claimLossDate, {
        setZone: true,
      }).toFormat("MM/dd/yyyy"),
      timeSinceReport:
        sinceReport.days === 0 ? "<1 day" : `${sinceReport.days} days`,
    };
  }

  formatFileOwner({
    repCode,
    firstName,
    middleInitial,
    lastName,
  }: Rep): string {
    this.ownerRepCode = repCode;
    const middleInitialFormatted = middleInitial ? ` ${middleInitial}` : "";
    return `${firstName}${middleInitialFormatted} ${lastName}`;
  }

  private formatNamedInsured({
    partyFirstName,
    partyLastName,
    partyLastNameSuffix,
    partyMiddleInitial,
  }: ClaimDetails["namedInsured"]): string {
    const suffix = partyLastNameSuffix ? `, ${partyLastNameSuffix}` : "";
    const middleInitial = partyMiddleInitial ? ` ${partyMiddleInitial}` : "";
    const lastName = partyLastName ? partyLastName : "";
    const firstName = partyFirstName ? partyFirstName : "";
    const fullName = `${lastName}, ${firstName}${middleInitial}${suffix}`;
    return fullName === ", " ? "—" : fullName;
  }

  public getSubtitle(environment: string): string {
    if (environment === "Development") {
      this.subtitle = "Dev";
    }
    if (environment === "Test") {
      this.subtitle = "QA";
    }
    if (environment === "Training") {
      this.subtitle = "Training";
    }
    return this.subtitle;
  }

  public async showLogoutConfirmation(): Promise<void> {
    const options: ConfirmDialogOptions = {
      title: "Are you sure you want to logout?",
      message: "",
      cancelFn: () => {
        this._dialogService.closeDialog(false);
      },
      continueFn: () => {
        this._dialogService.closeDialog(true);
        this.logout();
      },
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    };
    return await this._dialogService.openConfirmDialog(options);
  }

  public async logout(): Promise<void> {
    this.tncLossViewerSplunkLoggingService.logInfo(
      "Logout",
      "Clicking Logout Button"
    );
    await this.authService.signOut();
    // Enable this line if we see issues of the app not redirecting on logout
    // this.router.navigateByUrl('/');
  }
}
