import { Pipe, PipeTransform } from "@angular/core";

// based on http://www.angulartutorial.net/2017/04/date-filtering-and-formatting-using.html
@Pipe({
  name: "claDateFormatPipe",
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string | null) {
    return formatDate(value);
  }
}

export function formatDate(value: string | Date | null): string {
  if (!value) {
    return "";
  }
  return new Date(value)
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/[^\d/]/g, "");
}
