import { HttpResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

import {
  IApiErrorResponse,
  IStoreEntityGetProps,
  IDocSetDetailsResponse,
} from "../../../models";

export class DocSetDetailsActions {
  public static onError = createAction(
    "[DocSet/Details] Error",
    props<{ payload: IApiErrorResponse }>()
  );

  public static onGetReceived = createAction(
    "[DocSet/Details] Get Received",
    props<{ payload: HttpResponse<IDocSetDetailsResponse> }>()
  );

  public static onGetRequested = createAction(
    "[DocSet/Details] Get Requested",
    props<IStoreEntityGetProps>()
  );
}
