<div class="pt-xs px-bl-2 pb-bl-2">
  <mat-card class="p-0 overflow-x-auto policy-tab-card">
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      class="cui-table endorsement-table"
      matSortActive="formNumber"
      matSortDirection="asc"
      matSortDisableClear
    >
      <!-- Form Number Column -->
      <ng-container
        matColumnDef="formNumber"
        aria-label="click to re-sort dates"
      >
        <th mat-header-cell colspan="1" *matHeaderCellDef mat-sort-header>
          Form Number
        </th>
        <td mat-cell colspan="1" *matCellDef="let element">
          {{ element.formNumber }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell colspan="3" *matHeaderCellDef>Name</th>
        <td mat-cell colspan="3" *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #pager
      [pageSize]="[10]"
      hidePageSize
      showFirstLastButtons
      [hidden]="1 > pager.getNumberOfPages()"
    ></mat-paginator>
  </mat-card>
</div>
