import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NoteListComponent } from "@notes/modules/note-list/components";
import { NoteListResolverService } from "@notes/modules/note-list/note-list.resolver.service";
import { ChildWindowGuard } from "@pgr-cla/cla-window";

const notesListRoutes: Routes = [
  {
    path: "",
    component: NoteListComponent,
    canActivate: [ChildWindowGuard],
    data: {
      title: "Claim Notes",
      childWindowGuardRedirect: "claim-notes-forbidden",
    },
    resolve: { NoteListResolverService },
  },
];

@NgModule({
  imports: [RouterModule.forChild(notesListRoutes)],
  exports: [RouterModule],
})
export class NoteListRoutingModule {}
