import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoteTextPipe } from "@notes/modules/note-list/pipes";
import { NotesComponent } from "@notes/notes.component";
import { NotesRoutingModule } from "@notes/notes.routing.module";
import { NotesResolverService, NotesService } from "@notes/services";
import { NotesStateService } from "@notes/state/notes.state.service";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { RepNamePipe, TimeSincePipe, TimezoneDatePipe } from "@shared/pipes";

@NgModule({
  imports: [CommonModule, NotesRoutingModule],
  declarations: [NotesComponent],
  providers: [
    ClaimNumberPipe,
    TimeSincePipe,
    TimezoneDatePipe,
    DatePipe,
    RepNamePipe,
    NoteTextPipe,
    NotesService,
    NotesResolverService,
    NotesStateService,
  ],
})
export class NotesModule {}
