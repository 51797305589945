import { createSelector } from "@ngrx/store";

import { IContentSummaryState } from "../../models/content-summary";
import { IAppState } from "../../models/core";

export const selectContent: (state: IAppState) => IContentSummaryState = (
  state: IAppState
) => state.contentSummary;

export const columns = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.columns
);

export const contentDetails = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.contentDetails
);

export const contentSummaryGridFilter = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.contentSummaryGridFilter
);

export const displayedColumns = createSelector(
  selectContent,
  (state: IContentSummaryState) =>
    state.columns
      .filter((column) => {
        // we always want to show the selection checkboxes, even if somehow the visible field on this column got set to false
        // you can't do this through the UI, but if there was a direct API call, the field could get set incorrectly
        // this happened once & broke a lot of E2E tests for a QAU user
        if (column.id === "isSelected") {
          return true;
        }

        return column.visible;
      })
      .map((column) => column.title)
);

export const filterableColumns = createSelector(
  selectContent,
  (state: IContentSummaryState) =>
    state.columns.filter((column) => column.isFilterable)
);

export const filteredContentItems = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.filteredContentItems
);

export const filters = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.filters
);

export const isLoading = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.isLoading
);

export const isFilterResetInProgress = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.filterResetInProgress
);

export const partyPropertyName = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.partyPropertyName
);

export const previewedItem = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.previewedItem
);

export const refreshInProgress = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.refreshInProgress
);

export const selectedItems = createSelector(
  selectContent,
  (state: IContentSummaryState) => state.selectedItems
);

export const toggleableColumns = createSelector(
  selectContent,
  (state: IContentSummaryState) =>
    state.columns.filter((column) => column.isToggleable)
);
