import { HttpErrorResponse } from "@angular/common/http";
import { createFilter } from "../helpers/util";
import { PaymentDetailsLambdaInfo } from "./";
import { Column } from "./column.interface";
import { FilterType } from "./filter-type";
import { FilterSet } from "./filter.interface";

export interface PaymentsState {
  columns: Column[];
  filters: FilterSet;
  payments: PaymentDetailsLambdaInfo[] | undefined;
  paymentsTableData: PaymentDetailsLambdaInfo[];
  paymentsRequestInFlight: boolean;
  paymentsError: HttpErrorResponse | undefined;
  paymentsCalenderCountData: undefined;
  paymentsCalenderDataRequestInFlight: boolean;
  paymentsCalenderDataRequestError: HttpErrorResponse | undefined;
}

export const paymentsInitialState: PaymentsState = {
  columns: [
    {
      id: "name",
      title: "Pay to the Order of",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "exposure",
      title: "Exposure",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "invoiceAmount",
      title: "Invoice Amount",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "invoiceNumber",
      title: "Invoice Number",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "invoiceType",
      title: "Invoice Type",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "invoiceStatus",
      title: "Invoice Status",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "paymentAmount",
      title: "Payment Amount",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "paymentNumber",
      title: "Payment Number",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "paymentType",
      title: "Payment Type",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "paymentStatus",
      title: "Payment Status",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "paymentIssued",
      title: "Payment Issued",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
  ],
  filters: {
    name: createFilter(FilterType.Select),
    exposure: createFilter(FilterType.Select),
    invoiceAmount: createFilter(FilterType.NumberRange),
    invoiceNumber: createFilter(FilterType.Select),
    invoiceType: createFilter(FilterType.Select),
    invoiceStatus: createFilter(FilterType.Select),
    paymentAmount: createFilter(FilterType.NumberRange),
    paymentNumber: createFilter(FilterType.Select),
    paymentType: createFilter(FilterType.Select),
    paymentStatus: createFilter(FilterType.Select),
    paymentIssued: createFilter(FilterType.Select),
  },
  payments: undefined,
  paymentsTableData: [],
  paymentsRequestInFlight: false,
  paymentsError: undefined,
  paymentsCalenderCountData: undefined,
  paymentsCalenderDataRequestInFlight: false,
  paymentsCalenderDataRequestError: undefined,
};
