import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ChangeDetectionService } from "@core/services";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { ClaimDetailsStateService } from "@modules/core/state";
import { dateDisplayFormat } from "@modules/shared/material.module";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FilterCriteria, Journal, Note } from "@notes/models";
import { NotesStateService } from "@notes/state/notes.state.service";
import { PluralizePipe } from "@pgr-cla/core-ui-components";
import { ScrollUtil } from "@shared/utilities/scroll/scroll.util";
import {
  Observable,
  combineLatest,
  map,
  of,
  skip,
  switchMap,
  withLatestFrom,
} from "rxjs";

@UntilDestroy()
@Component({
  selector: "cla-note-list",
  templateUrl: "./note-list.component.html",
  styleUrls: ["./note-list.component.scss"],
})
export class NoteListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("notesList", { read: ElementRef }) notesList: ElementRef;

  public dateDisplayFormat: typeof dateDisplayFormat = dateDisplayFormat;
  public sliceEnd: number = 50;
  private maxSliceEnd: number = -1;
  private hasBeenFilteredOnce: boolean = false;

  public displayedNotes$: Observable<(Note | Journal)[]>;
  public searchTerm$: Observable<string | null>;

  constructor(
    public changeDetector: ChangeDetectorRef,
    private changeDetectionService: ChangeDetectionService,
    public claimDetailsStateService: ClaimDetailsStateService,
    public notesStateService: NotesStateService,
    public coreUiStateService: CoreUiExtensionsStateService,
    private liveAnnouncer: LiveAnnouncer,
    private pluralizePipe: PluralizePipe
  ) {}

  ngOnInit(): void {
    this.displayedNotes$ = combineLatest([
      this.notesStateService.filteredNotes$,
      this.notesStateService.toDoNotes$,
      this.notesStateService.isToDoNotesTabSelected$,
    ]).pipe(
      map(
        ([filteredNotes, toDoNotes, isToDoNotesTabSelected]: [
          Note[],
          Note[],
          boolean
        ]) => (isToDoNotesTabSelected ? toDoNotes : filteredNotes)
      )
    );

    this.searchTerm$ = combineLatest([
      this.notesStateService.filterCriteria$,
      this.notesStateService.isToDoNotesTabSelected$,
    ]).pipe(
      map(
        ([filterCriteria, isToDoNotesTabSelected]: [FilterCriteria, boolean]) =>
          isToDoNotesTabSelected ? null : filterCriteria?.searchTerm
      )
    );

    this.notesStateService.getNotes();
    this.notesStateService.getJournals();
    this.notesStateService.getCategories();

    this.notesStateService.notesAndJournals$
      .pipe(untilDestroyed(this))
      .subscribe((notes: (Note | Journal)[]) => {
        if (this.maxSliceEnd > -1 && notes.length - this.maxSliceEnd === 1) {
          this.sliceEnd++;
        }
        this.maxSliceEnd = notes.length;
      });

    this.notesStateService.sortDirection$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(() => ScrollUtil.scrollToTop(this.notesList));

    this.notesStateService.filterCriteria$
      .pipe(
        skip(1),
        withLatestFrom(this.notesStateService.isSearchedOrFiltered$),
        switchMap((vals: [FilterCriteria, boolean]) =>
          of(vals[1] ? "smooth" : "instant")
        ),
        untilDestroyed(this)
      )
      .subscribe((scrollBehavior: "smooth" | "instant") => {
        ScrollUtil.scrollToTop(this.notesList, scrollBehavior);
        this.sliceEnd = 50;
      });

    combineLatest([
      this.coreUiStateService.isLoadingRepSummaries$,
      this.notesStateService.isLoading$,
      this.notesStateService.isSearchedOrFiltered$,
      this.notesStateService.filteredNotes$,
      this.notesStateService.notesAndJournals$,
    ]).subscribe(
      ([
        isLoadingRepSummaries,
        isLoading,
        isSearchedOrFiltered,
        filteredNotes,
        notesAndJournals,
      ]: [boolean, boolean, boolean, Note[], (Note | Journal)[]]) => {
        if (
          (this.hasBeenFilteredOnce || isSearchedOrFiltered) &&
          !isLoadingRepSummaries &&
          !isLoading
        ) {
          this.liveAnnouncer.announce(
            `${isSearchedOrFiltered ? filteredNotes.length + " of " : ""} ${
              notesAndJournals.length
            } ${this.pluralizePipe.transform(
              "note",
              notesAndJournals.length
            )} found`
          );
          this.hasBeenFilteredOnce = true;
        }
      }
    );

    this.changeDetectionService.detectChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.changeDetector.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.notesList.nativeElement.addEventListener(
      "scroll",
      this.onScroll.bind(this)
    );
  }

  ngOnDestroy(): void {
    this.notesList.nativeElement.removeEventListener(
      "scroll",
      this.onScroll.bind(this)
    );
  }

  public refreshNotes(): void {
    this.notesStateService.getNotes();
    this.notesStateService.getJournals();
  }

  public resetFilters(): void {
    this.notesStateService.resetFilters();
  }

  public trackByFn(_index: number, note: Note): string {
    return note.id;
  }

  public onEmptyStateAction(action: string): void {
    action === "try again"
      ? this.refreshNotes()
      : action === "view all notes"
      ? this.notesStateService.setNoteListSelectedTabIndex(0)
      : this.resetFilters();
  }

  private onScroll(event: Event): void {
    const element: HTMLElement = event.target as HTMLElement;
    const remainingPixFromBottom: number =
      element.scrollHeight - element.scrollTop - element.clientHeight;
    if (remainingPixFromBottom < 1 && this.sliceEnd < this.maxSliceEnd) {
      this.sliceEnd +=
        Math.floor(this.sliceEnd / 4) > 100
          ? 100
          : Math.floor(this.sliceEnd / 4);
      this.changeDetector.detectChanges();
    }
  }
}
