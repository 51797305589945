import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "@modules/shared/components/confirm-dialog/confirm-dialog.component";
import { DateTime } from "luxon";
import { Subject, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";

import { Rep } from "../../models/rep.interface";
import { CoverageService } from "../../services/coverage.service";

@Component({
  selector: "cla-cvq-diary",
  templateUrl: "./cvq-diary.component.html",
  styleUrls: ["./cvq-diary.component.scss"],
})
export class CvqDiaryComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;

  minDate = DateTime.now().startOf("day");

  public confirmationDialogRef: MatDialogRef<ConfirmDialogComponent>;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    public confirmationDialog: MatDialog,
    private coverageService: CoverageService
  ) {}

  public isValid() {
    return this.isDiaryDateValid();
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  ngOnInit() {
    const assignToControl = this.formGroup.get(
      "diaryAssignTo"
    ) as UntypedFormControl;
    if (assignToControl) {
      assignToControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          debounceTime(500),
          takeUntil(this.unsubscribeSubject$)
        )
        .subscribe((detectedChange) => {
          if (detectedChange) {
            assignToControl.markAsTouched();
            this.searchRep(detectedChange);
          }
        });
    }
  }

  private isDiaryDateValid() {
    const dueDate = this.formGroup.get("diaryDueDate")!.value;
    const dateDiff = DateTime.fromISO(dueDate).diff(DateTime.now(), "years");

    if (dateDiff.years > 1) {
      this.confirmationDialogRef = this.confirmationDialog.open(
        ConfirmDialogComponent,
        {
          panelClass: "cui-dialog",
          data: {
            title: "Warning",
            message:
              "Diary due date is one year or more in the future. Do you want to proceed?",
            confirmBtnTitle: "Yes",
            cancelBtnTitle: "No",
          },
        }
      );

      return this.confirmationDialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribeSubject$));
    }

    return of(true);
  }

  private searchRep(searched: string): void {
    const assignToControl = this.formGroup.get(
      "diaryAssignTo"
    ) as UntypedFormControl;
    this.coverageService
      .getRep(searched)
      .pipe(
        take(1),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe({
        next: (rep: Rep) => {
          assignToControl.setErrors(
            !rep || !rep.isActive
              ? { inactiveRep: true, invalidRep: false }
              : null
          );
        },
        error: () => {
          assignToControl.setErrors({ invalidRep: true });
        },
      });
  }
}
