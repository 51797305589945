import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { AppState } from "@store/app-state.interface";
import { Observable } from "rxjs";

import { AttachedPolicyVehicle } from "../../models/attach-policy-vehicle";
import { LossVehicle } from "../../models/loss-vehicle";
import { PolicyVehicle } from "../../models/policy-vehicle";
import {
  PolicyVehicleInfoComponent,
  PolicyVehicleInfoProps,
} from "../policy-vehicle-info/policy-vehicle-info.component";

import * as PolicySelectors from "./../../state/policy/policy.selectors";
import * as SpinnerSelectors from "../../state/spinner/spinner.selectors";
import { ClaimPolicy } from "../../models/claim-policy";

export interface PolicyAttachVehicleProps {
  policyVehicles: AttachedPolicyVehicle[];
  policyTitle: string;
  lossVehicle: LossVehicle;
  lossVehicles: LossVehicle[];
  dateOfLoss: Date;
  policy$: Observable<ClaimPolicy | null>;
  claim$: Observable<ClaimInfo | null>;
}

@Component({
  selector: "cla-policy-vehicle",
  templateUrl: "./policy-vehicle.component.html",
  styleUrls: ["./policy-vehicle.component.scss"],
})
export class PolicyVehicleComponent {
  dialogRef: any;
  claimPolicy$: Observable<ClaimPolicy | null>;
  claimInfo$: Observable<ClaimInfo | null>;
  isLoading$: Observable<boolean>;

  @Input() policyVehicle: PolicyVehicle | null;
  @Input() policyVehicleInfo: PolicyVehicle | null;
  @Input() policyAttachVehicle: PolicyVehicle[] | null;
  @Input() policyTitle: string;
  @Input() lossVehicle: LossVehicle;
  @Input() lossVehicles: LossVehicle[];
  @Input() dateOfLoss: Date;

  constructor(private store$: Store<AppState>, private dialog: MatDialog) {
    this.claimPolicy$ = this.store$.select(PolicySelectors.selectClaimPolicy);
    this.claimInfo$ = this.store$.select(PolicySelectors.selectClaimInfo);
    this.isLoading$ = this.store$.select(
      SpinnerSelectors.selectIsSpinnerActive
    );
  }

  // openPolicyVehicleInfo(): void {
  //   this.dialogRef = this.dialog.open(PolicyVehicleInfoComponent, {
  //     width: CUI_DIALOG_WIDTH.MEDIUM,
  //     minWidth: '35rem',
  //     minHeight: '20rem',
  //     panelClass: 'cui-dialog',
  //     data: { vehicle: this.policyVehicle } as PolicyVehicleInfoProps
  //   });

  //   this.dialogRef.afterClosed(true);
  // }
}
