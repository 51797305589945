import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FeatureRevisionStoreService } from "../../services/feature-revision-store/feature-revision-store.service";

@Component({
  selector: "cla-revision-history-filter",
  templateUrl: "./revision-history-filter.component.html",
  styleUrls: ["./revision-history-filter.component.scss"],
})
export class RevisionHistoryFilterComponent {
  public historyFilters$: Observable<any> = combineLatest([
    this.featureRevisionStoreService.filters$,
    this.featureRevisionStoreService.filterableColumns$,
  ]).pipe(map(([filters, columns]: any) => ({ filters, columns })));

  constructor(
    private featureRevisionStoreService: FeatureRevisionStoreService,
    private announcer: LiveAnnouncer
  ) {}

  closeFilter() {
    this.featureRevisionStoreService.closeHistoryFilters();
  }

  resetFilters() {
    this.featureRevisionStoreService.resetHistoryFilters();
    this.announcer.announce("Filters reset. All history shown.");
  }

  resetColumnFilters(columnId: string): void {
    this.featureRevisionStoreService.resetColumnFilters({
      columnId,
    });
  }

  public getFilterCount(filterState: { appliedFilters: string[] }): string {
    let result = 0;
    if (filterState.appliedFilters) {
      result = filterState.appliedFilters.length;
    }

    return result.toString();
  }

  public filterCountIsHidden(filterState: {
    appliedFilters: string[];
  }): boolean {
    let result = true;
    if (filterState.appliedFilters && filterState.appliedFilters.length > 0) {
      result = false;
    }

    return result;
  }

  public onToggleFilter(columnId: string, filterOption: any): void {
    this.featureRevisionStoreService.filterFeatureRevisionHistory({
      columnId,
      filterText: filterOption.filterText,
    });
  }

  public showFilterPanel = (filter: any): boolean => filter.filterOptions;

  public trackById = (index: number, column: any): string => column.id;

  public trackByText = (index: number, filterOption: any): string =>
    filterOption.filterDisplayText;
}
