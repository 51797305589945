import { Injectable } from "@angular/core";
import {
  createApiResponseEvent,
  createButtonClickEvent,
  createPageViewEvent,
  EventModel,
} from "@pgr-cla/cla-analytics";
import { AppContextService } from "@modules/core/services/app-context";
import { ClaimsSummaryContextService } from "@modules/core/services/claims-summary-context";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ClaimsSummaryEventService {
  constructor(
    private appContextService: AppContextService,
    private claimsSummaryContextService: ClaimsSummaryContextService
  ) {}

  public buttonClickAction = (
    action: string,
    otherContext: Observable<unknown>[] = []
  ): EventModel =>
    createButtonClickEvent(action, [
      this.appContextService.context$,
      this.claimsSummaryContextService.context$,
      ...otherContext,
    ]);

  public summaryPageView = (): EventModel =>
    createPageViewEvent("Claims Summary Page View", [
      this.appContextService.context$,
      this.claimsSummaryContextService.context$,
    ]);

  public apiResponseAction = (
    action: string,
    otherContext: Observable<unknown>[] = []
  ): EventModel =>
    createApiResponseEvent(action, [
      this.appContextService.context$,
      this.claimsSummaryContextService.context$,
      ...otherContext,
    ]);
}
