import { CallState } from "@modules/shared";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";

import { CodeTable } from "@claimssummaryshared/models";

import * as codesActions from "./codes.actions";

export const codesAdapter = createEntityAdapter<CodeTable>({
  selectId: (table) => table.name,
});

export interface CodesState extends EntityState<CodeTable> {
  callState: CallState;
}

export const initialState: CodesState = codesAdapter.getInitialState({
  callState: CallState.INIT,
});

const codesReducer = createReducer(
  initialState,
  on(codesActions.retrieveCodes, (state) => ({
    ...state,
    callState: CallState.LOADING,
  })),
  on(codesActions.retrieveCodesSuccess, (state, { codes }) => ({
    ...codesAdapter.addMany(codes, state),
    callState: CallState.LOADED,
  }))
);

export function reducer(
  state: CodesState | undefined,
  action: Action
): CodesState {
  return codesReducer(state, action);
}
