import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claNoComma",
})
export class NoCommaPipe implements PipeTransform {
  transform(val: number | undefined): string {
    if (val !== undefined && val !== null) {
      return val.toString().replace(/,/g, "");
    } else {
      return "";
    }
  }
}
