import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claClaimStatus",
})
export class ClaimStatusPipe implements PipeTransform {
  readonly CLAIM_STATUS_MAP: { [key: string]: string } = {
    0: "inactive",
    1: "warn",
    2: "inactive",
    3: "warn",
    4: "active",
    5: "active",
    6: "inactive",
    7: "inactive",
    8: "alert",
    9: "active",
  };

  public transform(code?: string | number | null): string {
    if (code !== null && code !== undefined) {
      return this.CLAIM_STATUS_MAP[code];
    }

    return "";
  }
}
