<div
  class="flex flex-col relative"
  [ngClass]="
    (screenSizeService.isMediumLargeScreen$ | async)
      ? 'desktop-table-size'
      : 'mobile-table-size'
  "
  style="overflow: auto"
>
  <div class="diary-horizontal-scroll-container">
    <table mat-table [dataSource]="dataSource" matSort class="cui-table">
      <!-- dueDate Column -->
      <ng-container matColumnDef="diaryDueDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="cui-cell-12"
        >
          Due Date
        </th>
        <td mat-cell *matCellDef="let element" class="cui-cell-12">
          {{ element.diaryDueDate | date: "MM/dd/yyyy" | claEmptyDash }}
        </td>
      </ng-container>

      <!-- text Column -->
      <ng-container matColumnDef="diaryText">
        <th mat-header-cell colspan="2" *matHeaderCellDef mat-sort-header>
          Text
        </th>
        <td mat-cell colspan="2" *matCellDef="let element">
          {{ element.diaryText | claEmptyDash }}
        </td>
      </ng-container>

      <!-- owner Column -->
      <ng-container matColumnDef="ownerRepTableDisplay" class="name-column">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="name-column"
        >
          Owner
        </th>
        <td mat-cell *matCellDef="let element">
          <cla-contact-card
            [repInfo]="element.ownerRepSummary"
            [tpxId]="element.ownerRepCode"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>

      <!-- author Column -->
      <ng-container matColumnDef="enteredRepTableDisplay" class="name-column">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="name-column"
        >
          Author
        </th>
        <td mat-cell *matCellDef="let element">
          <cla-contact-card
            [repInfo]="element.enteredRepSummary"
            [tpxId]="element.enteredRepCode"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>
      <!-- deletedBy Column -->
      <ng-container matColumnDef="deletedByDate">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="name-column"
        >
          Deleted Date
        </th>
        <td mat-cell *matCellDef="let element" class="name-column">
          {{ element.deletedByDate | date: "MM/dd/yyyy" | claEmptyDash }}
        </td>
      </ng-container>

      <!-- deletedByRepCode Column -->
      <ng-container matColumnDef="deletedByRepTableDisplay">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="name-column"
        >
          Deleted By
        </th>
        <td mat-cell *matCellDef="let element">
          <cla-contact-card
            [repInfo]="element.deletedByRepSummary"
            [tpxId]="element.deletedByRepCode"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="cui-row-selectable"
      ></tr>
    </table>
    <cui-empty-state
      *ngIf="
        (diaryStateService.diaries$ | async) &&
        dataSource?.filteredData.length === 0
      "
      icon="no_doc_found"
      primaryText="No Claim Diaries"
      class="flex h-full w-full inset-0 absolute justify-center"
      data-pgr-id="emptyDiaryState"
      [isSvgIcon]="true"
    ></cui-empty-state>
    <cui-spinner
      *ngIf="diaryStateService.diaryRequestInFlight$ | async"
    ></cui-spinner>
  </div>
</div>
<mat-paginator
  #diaryPaginator
  [pageSizeOptions]="[250, 500, 1000]"
  [hidden]="
    1 >= diaryPaginator.getNumberOfPages() && 250 > dataSource?.data.length
  "
  showFirstLastButtons
  class="footer-top-border"
></mat-paginator>
<ng-template #emptyState>{{ "" | claEmptyDash }}</ng-template>
