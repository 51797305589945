import { NotesState } from "@notes/models/notes-state.interface";

export const initialState: NotesState = {
  noteId: "",
  categories: [],
  allowedCategoryCodes: "",
  note: null,
  notes: [],
  journals: [],
  filteredNoteIds: [],
  filterCriteria: {
    categoryCode: null,
    authoredRepCode: null,
    createdDateRange: {
      start: null,
      end: null,
    },
    searchTerm: null,
  },
  sortDirection: "desc",
  isLoadingNotes: false,
  isLoadingJournals: false,
  isLoadingCategories: false,
  shouldCloseNoteWindow: false,
  noteListSelectedTabIndex: 0,
  isErroredJournals: false,
  error: { message: "" },
  isPopout: false,
};

export const actions = {
  setAllowedCategoryCodes: "[Notes Resolver] Set Allowed Category Codes",
  setNoteId: "[Notes Resolver] Set Note Id",
  getNotes: "[Activity Adapter API] Get Notes",
  getNotesSuccess: "[Activity Adapter API] Get Notes Success",
  getNotesFailure: "[Activity Adapter API] Get Notes Failure",
  getNote: "[Activity Adapter API] Get Note",
  getNoteSuccess: "[Activity Adapter API] Get Note Success",
  getNoteFailure: "[Activity Adapter API] Get Note Failure",
  getJournals: "[Activity Adapter API] Get Journals",
  getJournalsSuccess: "[Activity Adapter API] Get Journals Success",
  getJournalsFailure: "[Activity Adapter API] Get Journals Failure",
  getCategories: "[Activity Adapter API] Get Categories",
  getCategoriesSuccess: "[Activity Adapter API] Get Categories Success",
  getCategoriesFailure: "[Activity Adapter API] Get Categories Failure",
  setFilterCriteria: "[Note List Filter Component] Set Filter Criteria",
  setFilteredNotes: "[Note List Filter Component] Set Filtered Notes",
  setFilteredNotesSuccess:
    "[Note List Filter Component] Set Filtered Notes Success",
  toggleSortDirection: "[Note List Subheader Component] Toggle Sort Direction",
  toggleSortDirectionSuccess:
    "[Note List Subheader Component] Toggle Sort Direction Success",
  closeNoteWindow: "[Note Component] Close Window",
  handleExternalWindowAction: "[Note Component] Handle External Window Action",
  redirectRequest: "[Router] Redirect Request",
  setNoteListSelectedTabIndex:
    "[Note List Header Component] Set Note List Selected Tab Index",
  setIsPopout: "[Note Component] Set Is Popout",
};
