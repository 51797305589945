import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@modules/shared";
import { ClaimsSummaryStateModule } from "./claims-summary.state.module";
import { ClaimSummaryRoutingModule } from "./claims-summary.routing.module";
import {
  ClaimSummaryComponent,
  CoveragesTileComponent,
  CoveragesVehicleDescriptionsComponent,
  FeaturesTileComponent,
  LiabilityTileComponent,
} from "./components";
import { FeatureTileChipsPipe } from "./pipes";
import { IpcWindowService } from "./services";

@NgModule({
  declarations: [
    FeaturesTileComponent,
    ClaimSummaryComponent,
    CoveragesTileComponent,
    CoveragesVehicleDescriptionsComponent,
    LiabilityTileComponent,
    FeatureTileChipsPipe,
  ],
  imports: [
    CommonModule,
    ClaimSummaryRoutingModule,
    ClaimsSummaryStateModule,
    SharedModule,
  ],
  providers: [FeatureTileChipsPipe, IpcWindowService],
})
export class ClaimsSummaryViewModule {}
