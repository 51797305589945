import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuardService
  implements CanDeactivate<CanComponentDeactivate>
{
  canDeactivate(
    component: CanComponentDeactivate
  ): Observable<boolean> | boolean {
    if (component.canDeactivate) {
      return component.canDeactivate().pipe(
        map((value) => {
          if (!value) {
            return false;
          }
          return true;
        })
      );
    } else {
      return true;
    }
  }
}
