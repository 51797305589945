import { Injectable } from "@angular/core";
import { PartyDetail } from "@modules/policy/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { PartyDetailService } from "../../services/party-detail.service";
import * as partyDetailsActions from "./party-details.actions";

@Injectable()
export class PartyDetailsEffects {
  constructor(
    private actions$: Actions,
    private partyDetailService: PartyDetailService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getPartyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(partyDetailsActions.getPartyDetails),
      switchMap((action) => {
        return this.partyDetailService
          .getPartyDetail(
            this.claimNumberPipe.transform(action.claimNumber, "full") || ""
          )
          .pipe(
            map((partyDetailList) => {
              const partyDetails = partyDetailList;

              return partyDetailsActions.getPartyDetailsSuccess({
                partyDetails: partyDetails as PartyDetail[],
              });
            }),
            catchError((error) => {
              return of(partyDetailsActions.partyErrorOccurred({ error }));
            })
          );
      })
    )
  );
}
