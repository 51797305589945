<mat-accordion class="p-0">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header
      [collapsedHeight]="'60px'"
      [expandedHeight]="'60px'"
    >
      <mat-panel-title>
        <div class="coverage-header-container">
          <div class="coverage-count-container">
            <h2 class="cui-h2 p-0" data-pgr-id="lblCoverageTileHeader">
              <span class="align-middle"> Coverage &amp; Policy </span>
              <span class="pl-bl-2">
                <button
                  mat-icon-button
                  aria-label="navigate to coverage"
                  class="align-middle"
                  color="primary"
                  (click)="navigateCoverage()"
                  (keydown.enter)="navigateCoverage()"
                  (keydown.space)="navigateCoverage()"
                >
                  <mat-icon>arrow_forward</mat-icon>
                </button>
              </span>
            </h2>
          </div>
          <div
            class="coverage-status-container"
            data-pgr-id="tleCoverageStatus"
          >
            <cui-status-indicator
              [background]="true"
              [status]="
                !isLoading ? STATUS_INDICATORS[coverages?.cvqStatus] : undefined
              "
            >
              {{ !isLoading ? coverages?.cvqStatus : "" }}
              <div
                *ngIf="isLoading"
                class="cui-ghost-line w-8"
                style="border-radius: 2rem"
              ></div>
            </cui-status-indicator>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider class="w-full"></mat-divider>
    <cui-notification-banner
      *ngIf="coveragesError"
      [actionable]="true"
      (action)="fetchCoverageData.emit()"
      actionButtonText="Try Again"
      type="error"
    >
      An unexpected error occurred. Please try again.
    </cui-notification-banner>
    <div class="px-xs py-xxs" *ngIf="isLoading">
      <div class="flex pb-xxs">
        <span class="cui-ghost-line w-2/3"></span>
      </div>
      <div class="flex pb-xxs">
        <span class="cui-ghost-line w-3/4"></span>
      </div>
      <div class="flex pb-xxs">
        <span class="cui-ghost-line w-full"></span>
      </div>
      <div class="flex pb-xxs" style="gap: 1.2rem">
        <span class="cui-ghost-line w-8" style="border-radius: 1rem"></span>
        <span class="cui-ghost-line w-8" style="border-radius: 1rem"></span>
      </div>
      <div class="flex pb-xxs">
        <span class="cui-ghost-line w-3/4"></span>
      </div>
      <div class="flex pb-xxs">
        <span class="cui-ghost-line w-3/4"></span>
      </div>
    </div>
    <div
      class="px-xs py-xxs"
      *ngIf="
        !isLoading && coverages?.vehicleCoverageDetail.length > 0;
        else nopolicy
      "
    >
      <div class="overflow-x-auto pb-bl-2">
        <span class="font-bold">Policy: </span>
        <cui-copy-button
          *ngIf="coverages?.policyNumber"
          [ariaLabel]="
            'copy policy ' +
            (coverages?.policyNumber && coverages?.policyNumberSuffix
              ? coverages?.policyNumber + '-' + coverages?.policyNumberSuffix
              : coverages?.policyNumber
              ? coverages?.policyNumber
              : '-')
          "
          [dataToCopy]="
            coverages?.policyNumber && coverages?.policyNumberSuffix
              ? coverages?.policyNumber + '-' + coverages?.policyNumberSuffix
              : coverages?.policyNumber
              ? coverages?.policyNumber
              : '-'
          "
        >
          <span
            class="copy-container"
            [attr.data-pgr-id]="'lblPolicyNumberDecPage'"
          >
            {{ coverages?.policyNumber }}-{{ coverages?.policyNumberSuffix }}
          </span>
        </cui-copy-button>
        <span class="font-bold" *ngIf="!coverages?.policyNumber">-</span>
        <span *ngIf="coverages?.policyDecPage"
          >|
          {{ coverages?.policyDecPage }}
        </span>
      </div>
      <div class="overflow-x-auto pb-bl-2">
        <span class="font-bold">Company: </span>
        <cui-copy-button
          *ngIf="coverages?.underwritingCompanyName"
          [ariaLabel]="'copy company ' + coverages?.underwritingCompanyName"
          [dataToCopy]="
            coverages?.underwritingCompanyName
              ? coverages?.underwritingCompanyName
              : '-'
          "
        >
          <span
            class="copy-container"
            [attr.data-pgr-id]="'lblPolicyUnderwritingCompany'"
          >
            {{
              coverages?.underwritingCompanyName
                ? coverages?.underwritingCompanyName
                : "-"
            }}
          </span>
        </cui-copy-button>
        <span *ngIf="!coverages?.underwritingCompanyName" class="font-bold"
          >-</span
        >
      </div>
      <div
        class="overflow-x-auto pb-xxs cui-label"
        [attr.data-pgr-id]="'lblProductDateCancel'"
      >
        Product: {{ coverages?.productCategoryCode }} | Effective :
        {{ coverages?.startDate }} - {{ coverages?.endDate }} |
        <span *ngIf="coverages?.cancelDate"> Cancel: Y </span>
        <span *ngIf="!coverages?.cancelDate"> Cancel: N </span>
      </div>

      <div class="mb-xxs">
        <mat-chip-set
          aria-label="Policy Status and Loss Info"
          cuiMatChipListAccessibility
          [shouldAutoFocus]="false"
          [selectable]="false"
          role="list"
        >
          <ng-container>
            <mat-chip
              *ngIf="coverages?.policyStatusDesc?.length > 0"
              cuiMatChipAccessibility
              [disableRipple]="true"
              [selectable]="false"
              [removable]="false"
              [attr.data-pgr-id]="'lblPolicyStatus'"
              role="listitem"
            >
              Policy {{ coverages?.policyStatusDesc }}
            </mat-chip>
          </ng-container>
        </mat-chip-set>
      </div>

      <cla-coverages-vehicle-descriptions
        class="w-full mr-s"
        [vehicleCoverages]="coverages?.vehicleCoverageDetail"
        [viewAll]="expanded"
      >
      </cla-coverages-vehicle-descriptions>
      <div *ngIf="coverages && coverages?.vehicleCoverageDetail.length > 1">
        <mat-divider
          style="
            overflow: hidden;
            width: calc(100% + 4.8rem);
            margin-left: -2.4rem;
          "
        ></mat-divider>
        <div class="w-full flex justify-center mt-xxs">
          <button
            mat-button
            color="primary"
            data-pgr-id="btnViewAllCoverages"
            (click)="toggleExpanded()"
          >
            {{ expanded ? "View Less" : "View All" }}
            <mat-icon matSuffix>{{
              expanded ? "expand_less" : "expand_more"
            }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <mat-paginator
      class="hidden"
      [attr.aria-hidden]="true"
      [pageSize]="pageSize"
    ></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #nopolicy>
  <div
    *ngIf="
      !isLoading && coverages?.policyNumber.length > 0;
      else noPolicyNoButton
    "
  >
    <cui-empty-state
      data-pgr-id="errNoPolicyVehicleAttached"
      icon="search_off"
      primaryText="No policy vehicle coverages have been selected."
    >
    </cui-empty-state>
  </div>
</ng-template>
<ng-template #noPolicyNoButton>
  <div *ngIf="!isLoading && !coveragesError">
    <cui-empty-state
      data-pgr-id="errNoPolicyAttached"
      icon="search_off"
      primaryText="No policy attached."
    >
    </cui-empty-state>
  </div>
</ng-template>
