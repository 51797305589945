import { createAction, props, union } from "@ngrx/store";

export const showSpinner = createAction(
  "[FR Spinner] Show",
  props<{ spinnerId: string; actionType: string }>()
);
export const hideSpinner = createAction(
  "[FR Spinner] Hide",
  props<{ spinnerId: string; actionType: string }>()
);

const all = union({
  showSpinner,
  hideSpinner,
});

export type ProgressActionsUnion = typeof all;
