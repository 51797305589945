<cui-spinner *ngIf="isLoading"></cui-spinner>
<div
  class="
    flex
    justify-between
    items-center
    section-heading-container
    left-rail-section-header
  "
>
  <cla-left-rail-section-heading> Loss Details </cla-left-rail-section-heading>
</div>
<div class="p-xxs loss-details-container" data-pgr-id="tleLossDetails">
  <div id="factsOfLossLabel" class="text-sm loss-details-label mb-bl-2">
    Facts of Loss
  </div>
  <p
    role="status"
    aria-labelledby="factsOfLossLabel"
    class="text-sm mb-bl-2 break-words"
  >
    <span *ngIf="isInitialLoad" class="ghost-line w-3/4"></span>
    <span *ngIf="!isInitialLoad">
      {{ claimDetails?.factsOfLoss1 }}
      <span *ngIf="claimDetails?.factsOfLoss2" class="block mt-bl-1">
        {{ claimDetails?.factsOfLoss2 }}
      </span>
      <ng-container
        *ngIf="
          !isInitialLoad &&
          !claimDetails?.factsOfLoss1 &&
          !claimDetails?.factsOfLoss2
        "
      >
        -
      </ng-container>
    </span>
  </p>
  <cui-data-list density="condensed" [border]="false">
    <cui-data-list-row labelSpan="8rem">
      <span list-item-label class="loss-details-label"> Claim Status </span>
      <div class="smallerChip">
        <cla-claim-status-indicator
          [claimDetails]="claimDetails"
          [isInitialLoad]="isInitialLoad"
        ></cla-claim-status-indicator>
      </div>
    </cui-data-list-row>
    <cui-data-list-row labelSpan="8rem">
      <span *ngIf="isInitialLoad" class="ghost-line"></span>
    </cui-data-list-row>
    <cui-data-list-row labelSpan="8rem">
      <span list-item-label class="loss-details-label">
        {{ claimDetails?.companyName == "LYFT" ? "Lyft ID" : "Reference #" }}
      </span>
      <span *ngIf="isInitialLoad" class="ghost-line w-2/3"></span>
      <ng-container *ngIf="!isInitialLoad">
        <ng-container
          *ngIf="
            claimDetails?.companyName == 'UBER' ||
            claimDetails?.companyName == 'LYFT'
          "
        >
          {{
            (claimDetails?.referenceNumber != null
              ? claimDetails?.referenceNumber
              : "-"
            ) | claEmptyDash
          }}
        </ng-container>
      </ng-container>
    </cui-data-list-row>
    <cui-data-list-row
      labelSpan="8rem"
      *ngIf="
        claimDetails?.dualLossClaimCentury &&
        claimDetails?.dualLossClaimYear &&
        claimDetails?.dualLossClaimNumber
      "
    >
      <span list-item-label class="loss-details-label"> Dual Loss </span>
      <span *ngIf="isInitialLoad" class="ghost-line"></span>
      <ng-container *ngIf="!isInitialLoad">
        <cui-copy-button
          [ariaLabel]="
            'copy claim number ' +
            claimDetails?.dualLossClaimYear +
            '-' +
            claimDetails?.dualLossClaimNumber
          "
          [dataToCopy]="
            claimDetails?.dualLossClaimYear +
            '-' +
            claimDetails?.dualLossClaimNumber
          "
        >
          <span>
            {{ claimDetails?.dualLossClaimYear }}-{{
              claimDetails?.dualLossClaimNumber
            }}
          </span>
        </cui-copy-button>
      </ng-container>
    </cui-data-list-row>
    <cui-data-list-row labelSpan="8rem">
      <span list-item-label class="loss-details-label"> Loss State </span>
      <span *ngIf="isInitialLoad" class="ghost-line"></span>
      <ng-container *ngIf="!isInitialLoad">
        {{ claimDetails?.accidentLocationState.name | claEmptyDash }}
      </ng-container>
    </cui-data-list-row>
    <cui-data-list-row labelSpan="8rem">
      <span list-item-label class="loss-details-label"> Policy State </span>
      <span *ngIf="isInitialLoad" class="ghost-line w-2/3"></span>
      <ng-container *ngIf="!isInitialLoad">
        <ng-container *ngIf="claimDetails?.policyNumber">
          {{ claimDetails?.policyState?.name | claEmptyDash }}
          <cla-surcharge-indicator
            *ngIf="isMassachusettsPolicyState"
            [surchargeCode]="claimDetails?.surchargeCode"
          ></cla-surcharge-indicator>
        </ng-container>
        <ng-container *ngIf="!claimDetails?.policyNumber"> — </ng-container>
      </ng-container>
    </cui-data-list-row>
  </cui-data-list>
  <div [@additionalDetailsExpansion]="expanded ? 'expanded' : 'collapsed'">
    <cui-data-list density="condensed" [border]="false">
      <cui-data-list-row labelSpan="8rem">
        <span list-item-label class="loss-details-label"> Loss Date </span>
        <span *ngIf="isInitialLoad" class="ghost-line"></span>
        <ng-container *ngIf="!isInitialLoad">
          <ng-container *ngIf="claimDetails?.claimLossDate">
            {{ claimDetails?.claimLossDate | date: "MM/dd/yyyy, hh:mm a" }}
            {{ claimDetails?.claimLossTimeZone }}
          </ng-container>
          <ng-container *ngIf="!claimDetails?.claimLossDate"> — </ng-container>
        </ng-container>
      </cui-data-list-row>
      <cui-data-list-row labelSpan="8rem">
        <span list-item-label class="loss-details-label"> Reported on </span>
        <span *ngIf="isInitialLoad" class="ghost-line w-1/2"></span>
        <ng-container *ngIf="!isInitialLoad">
          <ng-container *ngIf="claimDetails?.claimReportDate">
            {{ claimDetails?.claimReportDate | date: "MM/dd/yyyy, hh:mm a" }}
            {{ claimDetails?.claimReportTimeZone }}
          </ng-container>
          <ng-container *ngIf="!claimDetails?.claimReportDate">
            -
          </ng-container>
        </ng-container>
      </cui-data-list-row>
      <cui-data-list-row labelSpan="8rem">
        <span list-item-label class="loss-details-label"> Reported by </span>
        <span *ngIf="isInitialLoad" class="ghost-line w-2/3"></span>
        <ng-container *ngIf="!isInitialLoad">
          {{ claimDetails?.claimReportedBy?.description | claEmptyDash }}
        </ng-container>
      </cui-data-list-row>
      <cui-data-list-row labelSpan="8rem">
        <span list-item-label class="loss-details-label"> Reported via </span>
        <span *ngIf="isInitialLoad" class="ghost-line w-2/3"></span>
        <ng-container *ngIf="!isInitialLoad">
          {{ claimDetails?.claimReportedVia | claEmptyDash }}
        </ng-container>
      </cui-data-list-row>
    </cui-data-list>
    <mat-divider class="my-xxs"></mat-divider>
    <div id="lossLocationLabel" class="text-sm loss-details-label mb-bl-2">
      Loss Location
    </div>
    <p aria-labelledby="lossLocationLabel">
      <span *ngIf="isInitialLoad" class="ghost-line w-3/4"></span>
      <span *ngIf="!isInitialLoad">
        <cla-google-maps-address
          [address]="
            claimDetails?.lossLocationDisplayLine1 +
            ' ' +
            claimDetails?.lossLocationDisplayLine2 +
            ' ' +
            claimDetails?.lossLocationDisplayLine3 +
            ' ' +
            claimDetails?.lossLocationDisplayLine4
          "
        ></cla-google-maps-address>
      </span>
      <ng-container
        *ngIf="
          !isInitialLoad &&
          !claimDetails?.accidentLocation &&
          !claimDetails?.accidentLocationCityName &&
          !claimDetails?.accidentLocationState
        "
      >
        -
      </ng-container>
    </p>
  </div>
</div>
<button class="expand-toggle" (click)="toggleExpanded()">
  <span>{{ expanded ? "Less" : "More" }}</span>
  <mat-icon>{{ expanded ? "expand_less" : "expand_more" }}</mat-icon>
</button>
