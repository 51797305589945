import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DeleteAttorneyDialogComponent } from "./components";

export const attorneyRoutes: Routes = [
  {
    path: "delete/:partyId",
    component: DeleteAttorneyDialogComponent,
    data: { title: "Delete Attorney" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(attorneyRoutes)],
  exports: [RouterModule],
})
export class AttorneyRoutingModule {}
