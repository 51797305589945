import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AttorneyService {
  private _isErrorSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isError$: Observable<boolean> = this._isErrorSubject$.asObservable();

  private _isInFlightSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isInFlight$: Observable<boolean> =
    this._isInFlightSubject$.asObservable();

  private _isSuccessSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isSuccess$: Observable<boolean> =
    this._isSuccessSubject$.asObservable();
}
