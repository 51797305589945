import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ClaimSearchService } from "@modules/shared/services/claim-search/claim-search.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
  ClaimHeaderSearchComponent,
  ClaimNumberPipe,
  CUI_DIALOG_WIDTH,
} from "@pgr-cla/core-ui-components";
import { catchError, of, take } from "rxjs";
import { ClaimHeaderSearchDialogComponent } from "../claim-header-search-dialog";

@UntilDestroy()
@Component({
  selector: "cla-claim-header-search-wrapper",
  templateUrl: "claim-header-search-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ClaimHeaderSearchWrapperComponent {
  dialogRef: MatDialogRef<ClaimHeaderSearchDialogComponent>;
  @ViewChild(ClaimHeaderSearchComponent)
  searchComponent: ClaimHeaderSearchComponent;
  isLoading: boolean = false;

  constructor(
    private claimNumberPipe: ClaimNumberPipe,
    private dialog: MatDialog,
    public claimSearchService: ClaimSearchService,
    private router: Router
  ) {}

  searchClaim(claimNumber: string): void {
    const formattedClaimNumber: string | null = this.claimNumberPipe.transform(
      claimNumber,
      "full"
    );
    if (!this.isLoading && formattedClaimNumber) {
      this.claimSearchService
        .getClaimSearch(formattedClaimNumber)
        .pipe(
          take(1),
          catchError(() => {
            this.openDialog(claimNumber);
            this.isLoading = false;
            return of();
          })
        )
        .subscribe(() => {
          this.router.navigate(["/", formattedClaimNumber]).then(() => {
            this.reloadPage();
            this.isLoading = false;
          });
        });
    }
  }

  openDialog(claimNumber: string): void {
    this.dialogRef = this.dialog.open(ClaimHeaderSearchDialogComponent, {
      width: CUI_DIALOG_WIDTH.SMALL,
      disableClose: false,
      data: { claimNumber },
    });
  }

  reloadPage(): void {
    window.location.reload();
  }
}
