import { createSelector } from "@ngrx/store";

import { selectPartyDetailsState } from "./index";

export const selectParty = createSelector(selectPartyDetailsState, (state) =>
  state ? state.party : undefined
);

export const selectPartyDetail = createSelector(selectParty, (state) =>
  state ? state.partyDetails : undefined
);
