import { Injectable } from "@angular/core";
import {
  CoverageDetailInfo,
  FeatureDetail,
  LiabilityTile,
} from "@claimssummaryshared/models";
import { FeatureTableData } from "@modules/claims-summary/models/feature-table-data.interface";
import * as coreUiExtensionActions from "@modules/core-ui-extensions/actions";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { ClaimsSummaryAdapterApiService } from "@modules/core/services/claims-summary-adapter-api";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ClaimDetailsService } from "@shared/services";
import { combineLatest, from, of } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import * as claimSummaryActions from "../actions";
import { ClaimSummaryStateService } from "../services";

@Injectable()
export class ClaimSummaryEffects {
  detectFeaturesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimSummaryActions.onFeaturesRequest),
      withLatestFrom(this.claimDetailsStateService.claimNumber$),
      mergeMap(([, claimNumber]) =>
        this.claimDetailsService.getFeatures(claimNumber).pipe(
          switchMap((data: FeatureDetail[]) =>
            from([
              claimSummaryActions.onFeaturesReceipt({ payload: data }),
              coreUiExtensionActions.CoreUiExtensionsActions.onGetRepSummariesRequest(
                {
                  codes: [
                    ...new Set(
                      data.flatMap((x) =>
                        [x.owningRepCode, x.assistingRepCode].filter((x) => x)
                      )
                    ),
                  ],
                }
              ),
            ])
          ),
          catchError((error) => of(claimSummaryActions.onFeaturesError(error)))
        )
      )
    )
  );

  mapFeaturesToTable$ = createEffect(() =>
    combineLatest([
      this.claimSummaryStateService.features$,
      this.coreUiExtensionsStateService.repSummaries$,
    ]).pipe(
      map(
        ([features, repSummaries]) =>
          features?.map((feature: FeatureDetail) => {
            return {
              ...feature,
              ownerRepSummary: repSummaries[feature.owningRepCode],
              assistingRepSummary: repSummaries[feature.assistingRepCode],
            };
          }) || []
      ),
      map((featuresTableData: FeatureTableData[]) =>
        claimSummaryActions.setFeatureMappedTableData({ featuresTableData })
      )
    )
  );

  detectCoveragesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimSummaryActions.onCoveragesRequest),
      withLatestFrom(this.claimDetailsStateService.claimDetails$),
      mergeMap(([, claimDetails]) =>
        this.claimsSummaryAdapterApiService
          .getCoverages(
            claimDetails?.claimNumber ? claimDetails?.claimNumber : "",
            claimDetails?.policyNumber ? claimDetails?.policyNumber : "",
            claimDetails?.policyRenewalSuffixNumber
              ? claimDetails?.policyRenewalSuffixNumber
              : "",
            claimDetails?.productCategory.name
              ? claimDetails?.productCategory.name
              : "",
            claimDetails?.claimLossDate ? claimDetails?.claimLossDate : "",
            claimDetails?.isVerified
              ? claimDetails?.isVerified.toString()
              : "false"
          )
          .pipe(
            switchMap((data: CoverageDetailInfo) =>
              of(
                claimSummaryActions.onCoveragesReceipt({
                  payload: data,
                })
              )
            ),
            catchError((error) =>
              of(claimSummaryActions.onCoveragesError(error))
            )
          )
      )
    )
  );

  detectLiabilityRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimSummaryActions.onLiabilityRequest),
      withLatestFrom(this.claimDetailsStateService.claimDetails$),
      mergeMap(([, claimDetails]) =>
        this.claimDetailsService
          .getLiability(
            claimDetails?.claimNumber ? claimDetails.claimNumber : "",
            claimDetails?.claimInvestigativeStatus
              ? claimDetails.claimInvestigativeStatus.name
              : "X"
          )
          .pipe(
            switchMap((data: LiabilityTile) =>
              from([claimSummaryActions.onLiabilityReceipt({ payload: data })])
            ),
            catchError((error) =>
              of(claimSummaryActions.onLiabilityError(error))
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private claimDetailsStateService: ClaimDetailsStateService,
    private claimDetailsService: ClaimDetailsService,
    private claimSummaryStateService: ClaimSummaryStateService,
    private claimsSummaryAdapterApiService: ClaimsSummaryAdapterApiService,
    public coreUiExtensionsStateService: CoreUiExtensionsStateService
  ) {}
}
