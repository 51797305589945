import { Injectable } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

@Injectable()
export class TableDataService {
  public dataSource: MatTableDataSource<any>;

  initDataSource(
    dataSource: MatTableDataSource<any>,
    sort?: MatSort,
    paginator?: MatPaginator,
    filterFn?: (data: any, filterStr: string) => boolean
  ) {
    this.dataSource = dataSource;
    if (sort) {
      this.dataSource.sort = sort;
    }
    if (paginator) {
      this.dataSource.paginator = paginator;
    }
    if (filterFn) {
      this.dataSource.filterPredicate = filterFn;
    }
  }

  getDataLength(): number {
    return this.dataSource.data ? this.dataSource.filteredData.length : 0;
  }

  filterData(filters: string) {
    if (this.dataSource.data) {
      this.dataSource.filter = filters;
    }
  }
}
