import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claTrim",
})
export class TrimPipe implements PipeTransform {
  public transform(
    value: string | null | undefined
  ): string | null | undefined {
    if (typeof value === "string") {
      return value.trim();
    }
    return value;
  }
}
