<div *ngIf="claimPolicy$ | async; else noPolicyFoundInAPH">
  <div
    *ngIf="
      !!lossVehicle?.policyPosition && policyVehicleInfo;
      else noVehicleAttached
    "
    class="flex justify-between"
    style="align-items: center"
  >
    <div class="flex" style="align-items: center">
      <div class="cui-h3">Policy Vehicle:</div>
      <span class="screen-reader-only">Policy Vehicle</span>
      <cla-vehicle class="vehicle" [vehicle]="policyVehicleInfo"></cla-vehicle>
    </div>
  </div>
</div>

<ng-template #noVehicleAttached>
  <cui-empty-state
    *ngIf="(isLoading$ | async) === false"
    data-pgr-id="no-policy-vehicle"
    icon="search"
    primaryText="No policy vehicle coverages have been selected."
  >
  </cui-empty-state>
</ng-template>

<ng-template #noPolicyFoundInAPH>
  <cui-empty-state
    data-pgr-id="no-policy-found"
    icon="search"
    primaryText="An active policy was not found for the date of loss."
  >
  </cui-empty-state>
</ng-template>
