<cui-dialog
  closeButtonLabel="Close Dialog"
  [showClose]="true"
  (close)="onClose()"
  class="modal-dialog"
  data-pgr-id="cvq-note-dialog"
>
  <div mat-dialog-title dialog-header color="primary">CVQ Notes</div>
  <div mat-dialog-content dialog-content class="pb-xs">
    <cui-notification-banner type="error" *ngIf="!!errorMessage">
      {{ errorMessage }}
    </cui-notification-banner>
    <h2 class="my-xxs">{{ data.cvq.description }}</h2>
    <ng-container *ngIf="cvq$ | async as cvq">
      <hr *ngIf="cvq.notes.length" />
      <div
        *ngFor="let note of cvq.notes; index as noteIndex"
        [attr.data-pgr-id]="'note' + noteIndex"
      >
        <div class="w-full whitespace-normal mt-xxs font-bold">
          {{ note.authorName }} ({{ note.authorId }})
          <span class="text-grey-700">{{ getNoteDateFormat(note) }}</span>
          <mat-chip-list class="inline-block mx-xxs">
            <mat-chip
              cuiMatChipAccessibility
              [disableRipple]="true"
              [selectable]="false"
              [removable]="false"
            >
              {{ note.category }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="mb-xs">
          <p
            *ngFor="let noteLine of note.noteText; index as noteTextLineNumber"
            [class.mt-bl-2]="noteTextLineNumber === 0"
          >
            {{ noteLine }}
          </p>
        </div>
        <hr *ngIf="noteIndex < cvq.notes.length - 1" />
      </div>
    </ng-container>
  </div>
</cui-dialog>
