import { Pipe, PipeTransform } from "@angular/core";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";

@Pipe({
  name: "claRepName",
})
export class RepNamePipe implements PipeTransform {
  transform(
    code: string,
    repSummary: ClaimRepContactCardInfoRedacted | undefined | null,
    longFormat = false
  ): string {
    if (!repSummary) {
      return code;
    }
    if (longFormat) {
      return repSummary.name ? `${repSummary.name} (${code})` : code;
    }
    return repSummary.shortName ? repSummary.shortName : code;
  }
}
