import { Injectable } from "@angular/core";
import { Permission, UserContext, UserProfile } from "@modules/core/models";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, first, switchMap } from "rxjs/operators";

@Injectable()
export class UserContextService {
  public userContextSource$: Observable<UserContext>;
  private _userContextSourceSubject$: BehaviorSubject<UserContext>;
  private userContext: UserContext;

  constructor(
    private cookieService: CookieService,
    private config: ConfigurationService
  ) {
    const savedUserContext = this.cookieService.get("user_context");
    const savedContext = savedUserContext
      ? JSON.parse(savedUserContext)
      : ({} as UserContext);
    this._userContextSourceSubject$ = new BehaviorSubject(savedContext);
    this.userContext = savedContext;
    this.userContextSource$ = this._userContextSourceSubject$.asObservable();
  }

  public flushUserContext(): void {
    this.cookieService.delete("user_context");
    this._userContextSourceSubject$.next({} as UserContext);
    this.userContext = {} as UserContext;
  }

  public loadUserContext(user: UserContext): Observable<boolean> {
    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        const expirationDate = new Date(Date.now());
        expirationDate.setHours(
          expirationDate.getHours() + config.accessTokenExpiration
        );
        this.cookieService.set(
          "user_context",
          JSON.stringify(user),
          expirationDate,
          "/",
          undefined,
          undefined,
          "Strict"
        );
        this._userContextSourceSubject$.next(user);
        this.userContext = user;
        return of(true);
      })
    );
  }

  public hasPermission(
    permission: string | readonly string[]
  ): Observable<boolean> {
    if (!permission || (Array.isArray(permission) && permission.length === 0)) {
      return of(false);
    }

    const perm = Array.isArray(permission) ? permission : [permission];
    return this.hasArrayPermission(perm);
  }

  public getPermission(name: string): Permission | undefined {
    return this.userContext.permissions.find(
      (permission) => permission.name === name
    );
  }

  public getPermissions(): readonly Permission[] {
    return this.userContext.permissions
      ? this.userContext.permissions
      : ([] as readonly Permission[]);
  }

  public getUserProfile(): UserProfile {
    return this.userContext.profile;
  }

  public getAsyncUserProfile(): Observable<UserProfile> {
    return of(this.userContext.profile);
  }

  public hasArrayPermission(permissions: string[]): Observable<boolean> {
    return this.userContextSource$.pipe(
      filter(this.hasValidContext.bind(this)),
      switchMap(() => of(permissions.some((key) => !!this.getPermission(key))))
    );
  }

  public hasValidContext(context: UserContext): boolean {
    return !!context.profile && !!context.permissions;
  }
}
