import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { AlertsState } from "./alerts-state.interface";
import * as alertsActions from "./alerts.actions";

export const initialState: AlertsState = {
  alerts: [],
};

const alertsReducer: ActionReducer<AlertsState, Action> = createReducer(
  initialState,
  on(alertsActions.onClaimAlertsViewedSync, (state, action) => ({
    ...state,
    alerts: state.alerts.map((alert) => ({
      ...alert,
      viewed:
        alert.viewed ||
        action.pgrTransactionIds.includes(alert.pgrTransactionId),
    })),
  })),
  on(alertsActions.onAllClaimAlertsViewedSync, (state) => ({
    ...state,
    alerts: state.alerts.map((alert) => ({
      ...alert,
      viewed: true,
    })),
  }))
);

export function reducer(state: AlertsState, action: Action): AlertsState {
  return alertsReducer(state, action);
}
