import { Action, createReducer, on } from "@ngrx/store";
import { ClaimSummaryState } from "../models/claim-summary-state.interface";
import { initialState } from "../models/claim-summary.initial-state";
import * as claimSummaryActions from "../actions";

const claimSummaryReducer = createReducer(
  initialState,
  on(
    claimSummaryActions.onFeaturesRequest,
    (state: ClaimSummaryState) =>
      ({
        ...state,
        isFeaturesRequestInFlight: true,
        featuresError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.setFeatureMappedTableData,
    (state: ClaimSummaryState, { featuresTableData }) =>
      ({
        ...state,
        featuresTableData: featuresTableData,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onFeaturesReceipt,
    (state: ClaimSummaryState, { payload }) =>
      ({
        ...state,
        features: payload,
        isFeaturesRequestInFlight: false,
        featuresError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onFeaturesError,
    (state: ClaimSummaryState, { error }) => ({
      ...state,
      isFeaturesRequestInFlight: false,
      featuresError: error,
    })
  ),
  on(
    claimSummaryActions.onCoveragesRequest,
    (state: ClaimSummaryState) =>
      ({
        ...state,
        isCoveragesRequestInFlight: true,
        coveragesError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onCoveragesReceipt,
    (state: ClaimSummaryState, { payload }) =>
      ({
        ...state,
        coverages: payload,
        isCoveragesRequestInFlight: false,
        coverageError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onCoveragesError,
    (state: ClaimSummaryState, { error }) => ({
      ...state,
      isCoveragesRequestInFlight: false,
      coveragesError: error,
    })
  ),
  on(
    claimSummaryActions.onLiabilityRequest,
    (state: ClaimSummaryState) =>
      ({
        ...state,
        isLiabilityRequestInFlight: true,
        liablityError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onLiabilityReceipt,
    (state: ClaimSummaryState, { payload }) =>
      ({
        ...state,
        liabilityData: payload,
        isLiabilityRequestInFlight: false,
        liabilityError: undefined,
      } as ClaimSummaryState)
  ),
  on(
    claimSummaryActions.onLiabilityError,
    (state: ClaimSummaryState, { error }) => ({
      ...state,
      isLiabilityRequestInFlight: false,
      liabilityError: error,
    })
  )
);

export function reducer(
  state: ClaimSummaryState | undefined,
  action: Action
): ClaimSummaryState {
  return claimSummaryReducer(state, action);
}
