import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import * as detectBrowser from "detect-browser";
import { DetectBrowserService } from "../detect-browser/detect-browser.service";

@Injectable({
  providedIn: "root",
})
export class BrowserGuard implements CanActivate {
  private browser: detectBrowser.BrowserInfo;

  constructor(
    private router: Router,
    private detectBrowserService: DetectBrowserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.browser =
      this.detectBrowserService.detect() as detectBrowser.BrowserInfo;

    const validBrowsers: string[] = route.data?.validBrowsers || [
      "chrome",
      "edge-chromium",
    ];

    if (!validBrowsers.find((b: string) => b === this.browser?.name)) {
      this.router.navigate(
        [route.data.browserGuardRedirect || "unsupported-browser"],
        { queryParams: { browser: this.browser?.name } }
      );
      return false;
    }

    return true;
  }
}
