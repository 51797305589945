<ng-container *ngIf="agentInfoPopulated; else agentInfoNotFound">
  <div class="table">
    <cui-data-list density="condensed">
      <cui-data-list-row label="Preferred Name">
        {{ agentPreferredName }}
      </cui-data-list-row>
      <cui-data-list-row label="Legal Name">
        {{ agentLegalName }}
      </cui-data-list-row>
      <cui-data-list-row label="Agent Code">
        {{ agentCode }}
      </cui-data-list-row>
      <cui-data-list-row label="Address">
        {{ agentAddress }}
      </cui-data-list-row>
      <cui-data-list-row label="Phone">
        {{ agentPhone }}
      </cui-data-list-row>
    </cui-data-list>
  </div>
</ng-container>

<ng-template #agentInfoNotFound>
  <div class="block">
    <span class="icon-design flex justify-center">
      <mat-icon class="icon" aria-hidden="true">no_sim</mat-icon>
    </span>
    <div class="flex justify-center message">Agent Information Not Found</div>
  </div>
</ng-template>
