<div class="flex justify-between items-center left-rail-section-header">
  <cla-left-rail-section-heading>
    {{ propertyCount }} {{ propertyCount === 1 ? "Property" : "Properties" }}
    <span class="mx-bl-2">|</span> {{ partyCount }}
    {{ partyCount === 1 ? "Party" : "Parties" }}
  </cla-left-rail-section-heading>
</div>
<ul class="skeleton-loader" *ngIf="isLoading">
  <li>
    <div class="tree-item list-item">
      <div class="ghost-line w-16"></div>
    </div>
  </li>
  <li>
    <div class="tree-item list-item">
      <div class="ghost-line w-24"></div>
    </div>
  </li>
  <li>
    <div class="tree-item list-item">
      <div class="ghost-line w-20"></div>
    </div>
  </li>
</ul>
<ul *ngIf="!isLoading" data-pgr-id="claimPropList">
  <li class="property" *ngFor="let property of data; let i = index">
    <div
      *ngIf="property.propertyType"
      attr.data-pgr-id="claimProp-{{ property.displayName }}-{{ property.id }}"
      class="tree-item list-item"
    >
      <span
        class="list-item-label font-extra-bold"
        [matTooltip]="property.displayName"
        [matTooltipShowDelay]="500"
        [attr.aria-hidden]="true"
      >
        {{ property.displayName }}
      </span>
      <div
        class="party-property-relations"
        [matTooltip]="property.relationsKey | cuiTooltipLineBreaks"
        [attr.aria-hidden]="true"
      >
        {{ property.relations.join(" ") }}
      </div>
      <span class="screen-reader-only">
        {{ property.displayName }} details {{ property.relationsKey.join(" ") }}
      </span>
    </div>
    <div class="tree-item list-item" *ngIf="!property.propertyType">
      <span class="list-item-label font-extra-bold">
        {{ property.displayName }}
      </span>
    </div>
    <ul
      *ngIf="
        property.associatedPartyProperties &&
        property.associatedPartyProperties.length
      "
    >
      <li
        *ngFor="let associatedParty of property.associatedPartyProperties"
        class="tree-item tree-party"
      >
        <div class="list-item pr-0">
          <span
            class="list-item-label mr-auto"
            [matTooltip]="associatedParty.displayName"
            [matTooltipShowDelay]="500"
            [attr.aria-hidden]="true"
          >
            <div *ngIf="associatedParty.hasAttorney" class="icon">
              <mat-icon svgIcon="attorney"></mat-icon>
            </div>
            <div
              *ngIf="
                !associatedParty.hasAttorney && associatedParty.hasBeenContacted
              "
              class="icon"
            >
              <mat-icon svgIcon="contacted"></mat-icon>
            </div>
            <div
              *ngIf="
                !associatedParty.hasAttorney &&
                !associatedParty.hasBeenContacted
              "
              class="icon"
            >
              <mat-icon svgIcon="contact-pending"></mat-icon>
            </div>
            <div class="associated-party-label">
              {{ associatedParty.displayName }}
            </div>
          </span>
          <div
            class="party-property-relations"
            [matTooltip]="associatedParty.relationsKey | cuiTooltipLineBreaks"
            [attr.aria-hidden]="true"
          >
            {{ associatedParty.relations.join(" ") }}
          </div>
          <span class="screen-reader-only">
            {{ associatedParty.displayName }} details
            {{ associatedParty.hasAttorney ? "(Attorney)" : "" }}
            {{
              associatedParty.hasBeenContacted
                ? "(Contacted)"
                : "(Contact Pending)"
            }}
            {{ associatedParty.relationsKey.join(" ") }}
          </span>
        </div>
        <cla-tree-party-contact-card
          [partyId]="associatedParty.id"
          [partyName]="associatedParty.displayName"
          [hasAttorney]="associatedParty.hasAttorney"
        ></cla-tree-party-contact-card>
      </li>
    </ul>
  </li>
</ul>
