<ng-container *ngIf="aniPopulated">
  <div class="card-header">
    <h3 class="cui-body" id="policyAdditionalNamedInsured">
      <b>Secondary Named Insured</b>
    </h3>
  </div>
  <cui-data-list class="table" density="condensed">
    <cui-data-list-row label="First">
      {{ aniFirstName | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row>
      <span list-item-label>
        <span class="screen-reader-only">Middle</span>
        <span aria-hidden="true">Middle</span>
      </span>
      {{ aniMiddleInitial | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row label="Last">
      {{ aniLastName | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row label="Suffix">
      {{ aniSuffix | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row label="Address">
      <span [innerHTML]="streetName | claValueOrNA"></span>
      <span
        *ngIf="secondStreetName !== ''"
        [innerHtml]="secondStreetName"
      ></span>
      <span *ngIf="cityStateZip !== ''" [innerHTML]="cityStateZip"></span>
    </cui-data-list-row>
  </cui-data-list>
</ng-container>
