import { createAction, props, union } from "@ngrx/store";
import { Feature } from "../../models/feature-revision";
import { FeatureHistory } from "../../models/feature-revision/feature-history";

export const getHistory = createAction(
  "[FRH] Get Revision History",
  props<{
    claimNumber: string;
    features: Feature[];
  }>()
);

export const getHistorySuccess = createAction(
  "[FRH] Get Revision History Success",
  props<{
    featureHistory: FeatureHistory[];
    features: Feature[];
  }>()
);

export const filterRevisionHistory = createAction(
  "[FRH] Filter Revision History",
  props<{ columnId: string; filterText: string }>()
);

export const applyFilters = createAction(
  "[FRH] Apply Filter Revision History",
  props<{
    filteredRevisionHistory: FeatureHistory[];
    filters: { [key: string]: any };
  }>()
);

export const getHistoryError = createAction("[FRH] Get Revision History Error");

export const closeHistoryFilter = createAction(
  "[FRH] Close Revision History Filter"
);

export const resetHistoryFilter = createAction(
  "[FRH] Reset Revision History Filter"
);

export const resetColumnFilters = createAction(
  "[FRH] Reset Column Filters",
  props<{ columnId: string }>()
);

const all = union({
  getHistory,
  getHistorySuccess,
  getHistoryError,
  filterRevisionHistory,
  applyFilters,
  closeHistoryFilter,
  resetHistoryFilter,
  resetColumnFilters,
});

export type HistoryActionsUnion = typeof all;
