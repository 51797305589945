<div
  [ngClass]="
    noBackground
      ? 'custom-radius p-xxs mb-xxs no-bg-border'
      : 'coverage-vehicle-desc custom-radius p-xxs mb-xxs'
  "
>
  <span *ngIf="(isLoading$ | async) && vehicleLimitsAndCoverages?.length == 0">
    Coverages Loading...
  </span>
  <span
    *ngIf="
      vehicleLimitsAndCoverages?.length == 0 && (isLoading$ | async) === false
    "
  >
    No coverages added for vehicle.
  </span>
  <div
    [ngClass]="
      vehicleLimitsAndCoverages?.length > 1 && multiColumn
        ? 'limits-and-coverages display-columns'
        : 'limits-and-coverages'
    "
  >
    <cui-data-list
      class="coverages-no-wrap"
      [border]="false"
      *ngIf="vehicleLimitsAndCoverages != null"
    >
      <cui-data-list-row
        *ngFor="let claimCoverage of vehicleLimitsAndCoverages"
        [label]="claimCoverage | claGetCoverageLabel"
      >
        <div id="wrapper">
          <div id="description">
            {{ claimCoverage | claGetCoverageValue }}
          </div>
          <div id="arrow-popover">
            <cui-popover [align]="bottom" #popover>
              <button
                cui-popover-trigger
                id="arrow-button"
                class="arrow-button"
                aria-label="open additional information panel"
              >
                <mat-icon>{{
                  popover.expanded ? "arrow_drop_up" : "arrow_drop_down"
                }}</mat-icon>
              </button>
              <div id="popover-content" cui-popover-content>
                <h2 class="screen-reader-only" cuiPopoverTitle>
                  {{ claimCoverage | claGetCoverageLabel }} coverage and limit
                  details
                </h2>
                <div id="line-coverage">
                  <span>Line Coverage</span>
                  <cui-copy-button
                    [dataToCopy]="
                      'Line Coverage: ' +
                      claimCoverage.businessLineCode +
                      claimCoverage.coverageCode
                    "
                    aria-label="copy line coverage"
                  >
                  </cui-copy-button>
                  <br />
                  {{
                    claimCoverage.businessLineCode + claimCoverage.coverageCode
                  }}
                </div>
                <div>
                  <span>Limit Code</span>
                  <cui-copy-button
                    [dataToCopy]="'Limit Code: ' + claimCoverage.limitCode"
                    aria-label="copy limit code"
                  >
                  </cui-copy-button>
                  <br />
                  {{ claimCoverage.limitCode }}
                </div>
              </div>
            </cui-popover>
          </div>
        </div>
      </cui-data-list-row>
    </cui-data-list>
  </div>
</div>
