<cui-dialog
  [showFooter]="false"
  [showFooterShadow]="false"
  [showClose]="true"
  (close)="closeDialog()"
  closeButtonLabel="Close Recovery History for {{ data.partyName }} Modal"
>
  <span dialog-header mat-dialog-title class="truncate">
    Recovery History for {{ data.partyName }}</span
  >
  <div
    dialog-content
    mat-dialog-content
    cuiScrollIndicator
    class="scroll-shadow-y p-0 py-bl-1"
  >
    <table
      data-pgr-id="tblRecoveryHistory"
      class="cui-table"
      [dataSource]="dataSource"
      mat-table
      matSort
    >
      <ng-container matColumnDef="creditAdviceNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Credit Advice</th>
        <td mat-cell *matCellDef="let element">
          {{ element.creditAdviceNumber | claCreditAdvicePipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="checkNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Check Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element.checkNumber | claEmptyDashPipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="checkReceiptDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Check Receipt Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.checkReceiptDate | date: "MM/dd/yyyy" | claEmptyDashPipe }}
        </td>
      </ng-container>
      <ng-container matColumnDef="grossRecoveryAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Gross Recovery
        </th>
        <td mat-cell *matCellDef="let element">
          {{
            element.grossRecoveryAmount
              | currency: "USD":"symbol":"1.2-2"
              | claEmptyDashPipe
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="netRecoveryAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Net Recovery</th>
        <td mat-cell *matCellDef="let element">
          {{
            element.netRecoveryAmount
              | currency: "USD":"symbol":"1.2-2"
              | claEmptyDashPipe
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="payerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payer Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.payerName | claPayersNamePipe | claEmptyDashPipe }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        tabindex="0"
      ></tr>
    </table>
    <p
      [hidden]="hideNoPaymentsMessage"
      data-pgr-id="no-results-message"
      aria-live="polite"
      aria-atomic="true"
      role="status"
      class="py-l text-center"
    >
      No Payments Available
    </p>
  </div>
</cui-dialog>
