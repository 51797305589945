import { createAction, props, union } from "@ngrx/store";

import { PolicySearch } from "../../models/policy-search";

export const searchPolicyByPolicyNumberRequest = createAction(
  "[Policy] Search Policy by Policy Number Request",
  props<{ policyNumber: string; renewalSuffix: string; dateOfLoss: Date }>()
);

export const searchPolicyByPolicyNumberResponse = createAction(
  "[Policy] Search Policy by Policy Number Response",
  props<{ policySearchResults: PolicySearch[] | null }>()
);

export const searchPolicyClearRequest = createAction(
  "[Policy] Search Policy Clear Request"
);

export const searchPolicyErrorResponse = createAction(
  "[Policy] Search Policy Error Occurred",
  props<{ errorMessage: string }>()
);

const all = union({
  searchPolicyByPolicyNumberRequest,
  searchPolicyByPolicyNumberResponse,
  searchPolicyClearRequest,
  searchPolicyErrorResponse,
});

export type PolicySearchActionsUnion = typeof all;
