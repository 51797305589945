import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UntilDestroy } from "@ngneat/until-destroy";

import { VersionSelectorComponent } from "@modules/electronic-file-folder/components";
import { IContentSummaryItem } from "@modules/electronic-file-folder/models";
import { ClaimsContentViewerService } from "../../core/claims-content-viewer/claims-content-viewer.service";

@UntilDestroy()
@Injectable()
export class TableActionService {
  public constructor(
    private _dialog: MatDialog,
    private _claimsContentViewerService: ClaimsContentViewerService
  ) {}

  public openVersionSelector(item: IContentSummaryItem): void {
    this._dialog.open(VersionSelectorComponent, {
      panelClass: "version-selector-container",
      width: "70rem",
      restoreFocus: true,
      data: {
        item,
        versionData: item.versions,
      },
    });
  }

  public launchViewer(contentItemId: string, version: number): void {
    this._claimsContentViewerService.openContent(contentItemId, version);
  }
}
