import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claValueOrNA",
})
export class ValueOrNAPipe implements PipeTransform {
  transform(value: string | null) {
    if (value === undefined || value === null || value!.trim() === "") {
      return "-";
    }
    return value;
  }
}
