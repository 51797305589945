<div *ngFor="let claimCoverage of claimCoverages" style="padding-bottom: 10px">
  <mat-card class="coverage-card">
    <table data-pgr-id="tblClaimCoverage">
      <tbody class="align-top">
        <tr>
          <div class="flex loss-veh">
            <div class="flex">
              <b class="cui-h3">Loss Vehicle:</b>
              <span class="screen-reader-only">Loss Vehicle</span>
              <cla-vehicle
                class="vehicle"
                [vehicle]="claimCoverage.lossVehicle"
              ></cla-vehicle>
            </div>
            <div class="flex">
              <b class="cui-h3" id="loss-drvr-label">Loss Driver: </b>
              <div class="loss-drvr-name">
                <b>{{
                  getLossDriver(claimCoverage.lossVehicle.propertyId)
                    | claValueOrNA
                }}</b>
              </div>
            </div>
          </div>
        </tr>
        <tr>
          <div class="pol-veh border-t">
            <cla-policy-vehicle
              [lossVehicle]="claimCoverage.lossVehicle"
              [lossVehicles]="lossVehicles"
              [policyVehicle]="claimCoverage.policyVehicle"
              [policyVehicleInfo]="claimCoverage.policyVehicleInfo"
              [policyAttachVehicle]="policyAttachVehicle"
              [policyTitle]="policyTitle"
              [dateOfLoss]="dateOfLoss"
            >
            </cla-policy-vehicle>
          </div>
        </tr>
        <tr *ngIf="!!claimCoverage.lossVehicle?.policyPosition">
          <td colspan="3">
            <cla-coverage-descriptions
              [multiColumn]="true"
              [vehicleLimitsAndCoverages]="
                claimCoverage.vehicleLimitsAndCoverages
              "
            >
            </cla-coverage-descriptions>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
</div>
