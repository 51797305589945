import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { first, map, mergeMap, timeout, withLatestFrom } from "rxjs/operators";

import { ConfigurationService } from "@pgr-cla/cla-configuration";

import { AuthenticationService } from "@modules/core";
import { IApiOptions } from "../../../models";

@Injectable()
export class BlobApiService {
  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private config: ConfigurationService
  ) {}

  public get(
    url$: Observable<string>,
    headers?: HttpHeaders,
    options: IApiOptions = {}
  ): Observable<Blob> {
    return url$.pipe(
      withLatestFrom(this.config.configuration$, this.buildHeaders(headers)),
      first(([, config]) => config !== null && config !== undefined),
      mergeMap(([url, config, header]) => {
        return this.httpClient
          .get(url, { headers: header, responseType: "blob" })
          .pipe(timeout(this.getHttpTimeout(options, config.httpCallTimeout)));
      })
    );
  }

  public getHttpTimeout(options: IApiOptions, to: number): number {
    return options.timeout || to;
  }

  private buildHeaders(headers?: HttpHeaders): Observable<HttpHeaders> {
    const hdrs = headers || new HttpHeaders();
    return this.authService.token$.pipe(
      map((token) => {
        return hdrs.set("Authorization", `IGuard ${token}`);
      })
    );
  }
}
