import { Directive, HostListener } from "@angular/core";
import { RightRailResizeService } from "../services";

@Directive({
  selector: "[claRightAccessibleResizeHandle]",
})
export class RightAccessibleResizeHandleDirective {
  private isFocused: boolean;

  constructor(private rightResizeService: RightRailResizeService) {}

  @HostListener("window:keydown", ["$event"])
  onKeyPress(event: KeyboardEvent): void {
    if (!this.isFocused) return;
    if (event.key === "ArrowRight") this.rightResizeService.IncrementWidth();
    if (event.key === "ArrowLeft") this.rightResizeService.DecrementWidth();
  }

  @HostListener("focus", ["$event"]) onFocus(): void {
    this.isFocused = true;
  }
  @HostListener("blur", ["$event"]) onblur(): void {
    this.isFocused = false;
  }
}
