import { Pipe, PipeTransform } from "@angular/core";
import { Note } from "@notes/models";

@Pipe({
  name: "claNoteText",
})
export class NoteTextPipe implements PipeTransform {
  transform(note: Note): string {
    if (note.access.isRestricted) {
      return "[Note Restricted]";
    }
    return note.text;
  }
}
