<mat-list
  [ngClass]="
    dataSource.length > 5 ? 'max-h-3xl min-w-3xl overflow-auto' : 'min-w-3xl'
  "
>
  <ng-template ngFor let-item [ngForOf]="dataSource" let-i="index">
    <mat-list-item
      [class.bg-lightestGray]="selection.isSelected(item)"
      class="h-auto"
      data-pgr-id="cvqList"
    >
      <div class="flex flex-grow py-xxs items-start">
        <div
          *ngIf="(isRequestInFlight$ | async) === true; else cvqListLoaded"
          class="ghost-line w-100"
          data-pgr-id="dln-ghost-loader"
        ></div>
        <ng-template #cvqListLoaded>
          <div class="flex items-center flex-shrink-0 w-16">
            <cui-status-indicator
              class="min-w-56"
              status="{{ getIndicatorForCVQStatus(item.status) }}"
            >
              {{ item.status }}
            </cui-status-indicator>
          </div>
          <div class="flex-grow">
            <div class="flex justify-between">
              <div class="w-auto text-black">
                <p class="font-bold whitespace-normal mb-bl-1" mat-line>
                  {{ item.description }}
                </p>
                <div class="w-full whitespace-normal" mat-line>
                  <p class="inline-block">Level {{ item.level }}</p>
                  <wbr />
                  <span class="inline-block">
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                  </span>
                  <wbr />
                  <p class="inline-block">
                    Verification: {{ item.verification }}
                  </p>
                  <wbr />
                  <span class="inline-block">
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                  </span>
                  <wbr />
                  <p class="inline-block">
                    {{ item.creationTypeText }}
                  </p>
                  <wbr />
                  <span class="inline-block">
                    &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                  </span>
                  <wbr />
                  <p class="inline-block">Impacts {{ item.impacts }}</p>
                </div>
              </div>
              <div class="note-container flex flex-shrink-0 pl-bl-2 text-black">
                <div class="w-8">
                  <button
                    mat-stroked-button
                    color="primary"
                    data-pgr-id="claim-note-icon"
                    attr.aria-label="{{
                      setCvqNoteButtonAriaLabel(item.notes.length)
                    }}"
                    (click)="openCvqNoteForm(item)"
                  >
                    <mat-icon class="mr-bl-2">note</mat-icon>
                    {{ item.notes.length }}
                    <span class="screen-reader-only">Show Claim Note</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <mat-divider
            *ngIf="dataSource.length > 1"
            class="divider"
          ></mat-divider>
        </ng-template>
      </div>
    </mat-list-item>
  </ng-template>
</mat-list>
