import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CoreUiExtensionsEffects } from "./effects";
import { coreUiExtensionsReducer } from "./reducers";
import { CoreUiAdapterService, CoreUiExtensionsStateService } from "./services";

@NgModule({
  imports: [
    EffectsModule.forFeature([CoreUiExtensionsEffects]),
    StoreModule.forFeature("coreUiExtensions", coreUiExtensionsReducer),
  ],
  providers: [CoreUiAdapterService, CoreUiExtensionsStateService],
})
export class CoreUiExtensionsServiceModule {}
