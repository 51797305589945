import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { SplunkBuilder } from "@modules/core";

import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";

import { loggingConstants } from "../../../constants/shared";

@Injectable()
export class ContentSummaryErrorHandler implements ErrorHandler {
  constructor(
    private loggingService: LoggingService,
    private splunkBuilder: SplunkBuilder
  ) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public handleError(error: any): void {
    if (!(error instanceof HttpErrorResponse)) {
      const message = this.splunkBuilder.buildError(
        error,
        loggingConstants.angularUnknownError,
        loggingConstants.angularUnknownErrorCode
      );
      this.loggingService.log(message, LoggedMessageLevel.Error);
    }
  }
}
