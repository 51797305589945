import { HttpErrorResponse } from "@angular/common/http";
import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";
import { PaymentDetailsLambdaInfo, PaymentsState } from "../models";
import { Column } from "../models/column.interface";

export const selectPaymentsState: MemoizedSelector<never, PaymentsState> =
  createFeatureSelector<PaymentsState>("payments");

export const selectPayments: MemoizedSelector<
  never,
  PaymentDetailsLambdaInfo[] | undefined
> = createSelector(
  selectPaymentsState,
  (state: PaymentsState) => state.payments
);

export const selectPaymentsTableData: MemoizedSelector<
  never,
  PaymentDetailsLambdaInfo[] | undefined
> = createSelector(
  selectPaymentsState,
  (state: PaymentsState) => state.paymentsTableData
);

export const selectPaymentsRequestInFlight: MemoizedSelector<never, boolean> =
  createSelector(
    selectPaymentsState,
    (state: PaymentsState) => state.paymentsRequestInFlight
  );

export const selectPaymentsError: MemoizedSelector<
  never,
  HttpErrorResponse | undefined
> = createSelector(
  selectPaymentsState,
  (state: PaymentsState) => state.paymentsError
);

export const selectFilterableColumns: MemoizedSelector<
  never,
  Column[],
  DefaultProjectorFn<Column[]>
> = createSelector(selectPaymentsState, (state: PaymentsState) =>
  state.columns.filter((column: Column) => column.isFilterable)
);

export const selectPaymentsFilters: MemoizedSelector<
  never,
  { [key: string]: any }
> = createSelector(
  selectPaymentsState,
  (state: PaymentsState) => state.filters
);
