import { Directive, HostListener } from "@angular/core";
import { LeftRailResizeService } from "../services";

@Directive({
  selector: "[claLeftAccessibleResizeHandle]",
})
export class LeftAccessibleResizeHandleDirective {
  private isFocused: boolean;

  constructor(private leftResizeService: LeftRailResizeService) {}

  @HostListener("window:keydown", ["$event"])
  onKeyPress(event: KeyboardEvent): void {
    if (!this.isFocused) return;
    if (event.key === "ArrowRight") this.leftResizeService.IncrementWidth();
    if (event.key === "ArrowLeft") this.leftResizeService.DecrementWidth();
  }

  @HostListener("focus", ["$event"]) onFocus(): void {
    this.isFocused = true;
  }
  @HostListener("blur", ["$event"]) onblur(): void {
    this.isFocused = false;
  }
}
