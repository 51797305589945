import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CombinedClaimGuard } from "@modules/core/services";

import { PolicyContainerComponent } from "./policy-container.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        canActivate: [CombinedClaimGuard],
        component: PolicyContainerComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PolicyRoutingModule {}
