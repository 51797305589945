import { createAction, props } from "@ngrx/store";

import { RecoveryHistoryItem } from "../../models";

export const loadRecoveryHistory = createAction(
  "[recovery-history] load recovery history",
  props<{ subroObligationId: number; partySequenceNumber: number }>()
);

export const loadRecoveryHistorySucceeded = createAction(
  "[recovery-history] successfully loaded recovery history",
  props<{
    subroObligationId: number;
    recoveryHistoryItems: RecoveryHistoryItem[];
  }>()
);

export const loadRecoveryHistoryFailed = createAction(
  "[recovery-history] failed to load recovery history",
  props<{ subroObligationId: number; errorMsg: string }>()
);
