import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { Observable } from "rxjs";

import { selectMemos } from "../../state/uw-memo-landing/uw-memo-landing.selectors";

import { UwMemo } from "./models/uw-memo";
import { isRequestInFlight } from "@modules/coverage/store/coverage.selectors";

import * as SpinnerSelectors from "../../state/spinner/spinner.selectors";

@Component({
  selector: "cla-uw-memo-landing",
  templateUrl: "./uw-memo-landing.component.html",
  styleUrls: ["./uw-memo-landing.component.scss"],
})
export class UwMemoLandingComponent implements OnInit {
  @Input() claimNumber: string;
  @Input() isInUnderwritingMemoPilot: boolean;
  @Input() userId: string;
  isLoading$: Observable<boolean>;

  underwritingMemos$: Observable<UwMemo[]>;

  requestInFlight$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(
      SpinnerSelectors.selectIsSpinnerActive
    );
    this.underwritingMemos$ = this.store$.select(selectMemos);
    this.requestInFlight$ = this.store$.select(isRequestInFlight);
  }
}
