<div class="flex items-center">
  <span class="header-name">
    {{ headerName }}
  </span>
  <mat-icon
    *ngIf="filterActive"
    class="text-lg h-xxs text-blue ml-bl-1 filter-active-indicator"
    [attr.data-pgr-id]="headerName + ' Grid Indicator'"
  >
    fiber_manual_record
  </mat-icon>
  <span *ngIf="filterActive" class="screen-reader-only" role="alert">
    {{ headerName }} filtered
  </span>
</div>
