/* eslint-disable functional/immutable-data */
import { deepCopy } from "@modules/electronic-file-folder/helpers";
import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { tassign } from "tassign";
import * as paymentsActions from "../actions";
import { createFilter, getFilteredPayments, getFilters } from "../helpers/util";
import {
  NumberRangeState,
  PaymentDetailsLambdaInfo,
  paymentsInitialState,
  PaymentsState,
} from "../models";
import { FilterType } from "../models/filter-type";
import { FilterOption, FilterSet } from "../models/filter.interface";

const paymentsReducer: ActionReducer<any> = createReducer(
  paymentsInitialState,
  on(
    paymentsActions.retrieveClaimPayments,
    (state: PaymentsState, { keepExistingPayments }) =>
      ({
        ...state,
        payments: keepExistingPayments ? state.payments : undefined,
        paymentsRequestInFlight: true,
        paymentsError: undefined,
      } as PaymentsState)
  ),

  on(
    paymentsActions.retrieveClaimPaymentsSuccess,
    (state: PaymentsState, { payments }) => {
      const filters = getFilters(state, payments);
      return tassign(state, {
        ...state,
        filters: filters,
        payments: payments,
        paymentsTableData: sortPayments(payments),
        paymentsRequestInFlight: false,
        paymentsError: undefined,
      } as PaymentsState);
    }
  ),

  on(
    paymentsActions.retrieveClaimPaymentsError,
    (state: PaymentsState, { error }) =>
      ({
        ...state,
        paymentsRequestInFlight: false,
        paymentsError: error,
      } as PaymentsState)
  ),

  on(
    paymentsActions.setMappedTableData,
    (state: PaymentsState, { finalizedPaymentInfo }) =>
      ({
        ...state,
        paymentTableData: finalizedPaymentInfo,
      } as PaymentsState)
  ),

  on(
    paymentsActions.filterPaymentsSelect,
    (state, { columnId, filterText }) => {
      const appliedFilters = [...state.filters[columnId].appliedFilters];
      const toggledFilterIndex = appliedFilters.indexOf(filterText);

      if (toggledFilterIndex === -1) {
        appliedFilters.push(filterText);
      } else {
        appliedFilters.splice(toggledFilterIndex, 1);
      }

      return getFilteredPaymentsState(state, columnId, appliedFilters);
    }
  ),

  on(
    paymentsActions.filterPaymentsNumberRange,
    (state, { columnId, minText, maxText }) => {
      const oldFilterState: NumberRangeState = state.filters[columnId].state;
      const newFilterState: NumberRangeState = {
        min: getNewNumber(minText, oldFilterState.min),
        max: getNewNumber(maxText, oldFilterState.max),
      };

      if (
        newFilterState.min === undefined &&
        newFilterState.max === undefined
      ) {
        return getFilteredPaymentsState(state, columnId, [], newFilterState);
      }

      const appliedFilters: string[] = [""];
      const minValue: number = newFilterState.min ?? Number.MIN_SAFE_INTEGER;
      const maxValue: number = newFilterState.max ?? Number.MAX_SAFE_INTEGER;

      state.filters[columnId].filterOptions.forEach((opt: FilterOption) => {
        const value: number = +opt.filterText;
        if (value >= minValue && value <= maxValue) {
          appliedFilters.push(opt.filterText);
        }
      });

      return getFilteredPaymentsState(
        state,
        columnId,
        appliedFilters,
        newFilterState
      );
    }
  ),

  on(
    paymentsActions.applyFilters,
    (state: PaymentsState, { filteredPayments, filters }) => {
      return tassign(state, {
        ...state,
        paymentsTableData: filteredPayments,
        filters: filters,
      });
    }
  ),

  on(paymentsActions.resetPaymentsFilters, (state: PaymentsState) => {
    const filters = deepCopy(state.filters);
    Object.keys(filters).forEach((key: string) => {
      if (filters[key].appliedFilters) {
        filters[key].appliedFilters = [];
      } else {
        filters[key] = createFilter(FilterType.Select);
      }
    });
    return tassign(state, {
      ...state,
      paymentsTableData: sortPayments(state.payments ? state.payments : []),
      filters,
    });
  }),

  on(
    paymentsActions.resetColumnFilters,
    (state: PaymentsState, { columnId }) => {
      return tassign(state, {
        ...state,
        filters: {
          ...state.filters,
          [columnId]: {
            ...state.filters[columnId],
            appliedFilters: [],
          },
        },
      });
    }
  )
);

const sortPayments = (payments: PaymentDetailsLambdaInfo[]) => {
  return payments;
};

export function reducer(
  state: PaymentsState | undefined,
  action: Action
): PaymentsState {
  return paymentsReducer(state, action);
}

function getFilteredPaymentsState(
  state: PaymentsState,
  columnId: string,
  appliedFilters: any[],
  filterState?: any
): PaymentsState {
  const newFilters: FilterSet = {
    ...state.filters,
    [columnId]: {
      ...state.filters[columnId],
      appliedFilters,
      state: filterState ?? {},
    },
  };

  const filteredPayments: PaymentDetailsLambdaInfo[] | undefined =
    getFilteredPayments({
      ...state,
      payments: state.payments,
      filters: newFilters,
    });

  return tassign(state, {
    ...state,
    filters: newFilters,
    paymentsTableData: filteredPayments,
  } as PaymentsState);
}

function getNewNumber(
  input: string | undefined,
  oldValue: number | undefined
): number | undefined {
  if (input?.trim() === "") return undefined;
  if (input) return +input;
  return oldValue;
}
