<main role="main" class="h-full">
  <mat-toolbar class="screen-header screen-header-full-width" tabindex="-1">
    <mat-toolbar-row class="screen-title">
      <h1 class="cui-h1 pr-xxxl" id="main-content-header" tabindex="-1">
        Subrogation Summary
      </h1>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-tab-group
    animationDuration="0ms"
    class="screen-container summary-tile"
    mat-align-tabs="start"
    mat-stretch-tabs="false"
  >
    <mat-tab label="Adverse Parties">
      <cla-adverse-parties-summary tabindex="0"></cla-adverse-parties-summary>
    </mat-tab>
    <mat-tab label="Out Of Pockets">
      <cla-out-of-pockets
        [claimNumber]="claimNumber"
        tabindex="0"
      ></cla-out-of-pockets>
    </mat-tab>
  </mat-tab-group>
</main>
