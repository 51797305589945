import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";

@Component({
  selector: "cla-left-rail",
  templateUrl: "left-rail.component.html",
  styleUrls: ["./left-rail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftRailComponent {
  constructor(public claimDetailsStateService: ClaimDetailsStateService) {}
}
