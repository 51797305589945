import { Pipe, PipeTransform } from "@angular/core";

import { IContentSummaryItem } from "../../../models";

@Pipe({ name: "claRowClass" })
export class RowClassPipe implements PipeTransform {
  public transform(
    selectedIds: string[] | null,
    rowId: string,
    previewedItem: IContentSummaryItem | null
  ): string {
    let rowClass = "cui-row-selectable";
    if (previewedItem) {
      rowClass += rowId === previewedItem.id ? " previewed" : "";
    }

    if (selectedIds && selectedIds.some((selectedId) => selectedId === rowId)) {
      rowClass += " selected";
    }
    return rowClass;
  }
}
