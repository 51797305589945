import { HttpErrorResponse } from "@angular/common/http";
import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";
import { ClaimPartyContactCardInfo } from "@pgr-cla/core-ui-components";
import {
  ClaimRepContactCardInfoRedacted,
  CoreUiExtensionsState,
} from "../models";
import { PartyContactCardEntityState } from "../models/party-contact-card-entity-state";

export const coreUiExtensionsState =
  createFeatureSelector<CoreUiExtensionsState>("coreUiExtensions");
export const selectRepSummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.repSummaries
);

export const selectRepLoadingStates = createSelector(
  coreUiExtensionsState,
  (state) => state.repLoadingStates
);

export const selectRequestedRepCodes = createSelector(
  coreUiExtensionsState,
  (state) => state.requestedRepCodes
);

export const selectIsLoadingRepSummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.isLoadingRepSummaries
);

export const selectIsErroredRepSummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.isErroredRepSummaries
);

export const selectErroredRepCodes = createSelector(
  coreUiExtensionsState,
  (state) => state.reps.erroredIds
);
export const selectNotFoundRepCodes = createSelector(
  coreUiExtensionsState,
  (state) => state.reps.notFoundIds
);

export const selectPartySummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.partyContactCards
);

export const selectPartySummariesById = (
  partyId: string
): MemoizedSelector<
  object,
  ClaimPartyContactCardInfo,
  DefaultProjectorFn<ClaimPartyContactCardInfo>
> =>
  createSelector(
    selectPartySummaries,
    (partyContactCards: Record<string, ClaimPartyContactCardInfo>) =>
      partyContactCards[partyId]
  );

export const selectPartyIsLoadingById = (
  partyId: string
): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(
    selectPartySummaries,
    (partyContactCards: Record<string, PartyContactCardEntityState>) =>
      partyContactCards[partyId]?.isLoading
  );

export const selectPartyErrorById = (
  partyId: string
): MemoizedSelector<
  object,
  HttpErrorResponse | undefined,
  DefaultProjectorFn<HttpErrorResponse | undefined>
> =>
  createSelector(
    selectPartySummaries,
    (partyContactCards: Record<string, PartyContactCardEntityState>) =>
      partyContactCards[partyId]?.error
  );

export const selectRepSummary: (
  repCode: string
) => MemoizedSelector<
  object,
  ClaimRepContactCardInfoRedacted,
  DefaultProjectorFn<ClaimRepContactCardInfoRedacted>
> = (repCode: string) =>
  createSelector(selectRepSummaries, (summaries) => summaries[repCode]);

export const selectRepLoadingState: (
  repCode: string
) => MemoizedSelector<
  object,
  ClaimRepContactCardInfoRedacted,
  DefaultProjectorFn<ClaimRepContactCardInfoRedacted>
> = (repCode: string) =>
  createSelector(selectRepSummaries, (summaries) => summaries[repCode]);

export const selectRepairShopSummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.repairShops.entities
);
export const selectIsLoadingRepairShopSummaries = createSelector(
  coreUiExtensionsState,
  (state) => state.repairShops.isLoadingRepairShopSummaries
);
export const selectIsErroredRepairShopSummaries = createSelector(
  coreUiExtensionsState,
  (state) => !!state.repairShops.erroredIds.length
);
export const selectErroredRepairShopCodes = createSelector(
  coreUiExtensionsState,
  (state) => state.repairShops.erroredIds
);
export const selectNotFoundRepairShopCodes = createSelector(
  coreUiExtensionsState,
  (state) => state.repairShops.notFoundIds
);
