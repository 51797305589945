import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { createButtonClickEvent, EventModel } from "@pgr-cla/cla-analytics";

@Injectable()
export class AnalyticsWrapperService {
  public createButtonClickEvent(
    actionName: string,
    eventContext: Observable<any>[]
  ): EventModel {
    return createButtonClickEvent(actionName, eventContext);
  }
}
