import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MatPaginator } from "@angular/material/paginator";
import { Subject, Observable } from "rxjs";

@UntilDestroy()
@Injectable()
export class PaginatorService {
  public matPaginator$: Observable<MatPaginator>;
  private _matPaginatorSubject$: Subject<MatPaginator> = new Subject();
  public constructor() {
    this.matPaginator$ = this._matPaginatorSubject$.asObservable();
  }

  public setMatPaginator(paginator: MatPaginator): void {
    this._matPaginatorSubject$.next(paginator);
  }
}
