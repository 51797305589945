import { Pipe, PipeTransform } from "@angular/core";
import { VehicleLimitsAndCoverages } from "../../models/policy-vehicle";

@Pipe({
  name: "claGetCoverageLabel",
})
export class GetCoverageLabelPipe implements PipeTransform {
  transform(value: VehicleLimitsAndCoverages): string {
    if (value.description === "") {
      return "NO DESCRIPTION";
    }
    return value.description;
  }
}
