import { Injectable } from "@angular/core";
import { ClaimManagementApiService } from "@core/services";
import { Feature } from "@modules/feature/models/feature";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable } from "rxjs";

@Injectable()
export class FeatureService {
  constructor(
    private claimManagementApiService: ClaimManagementApiService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getFeatures(claimNumber: string): Observable<Feature[]> {
    return this.claimManagementApiService.get<Feature[]>(
      `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
        claimNumber,
        "full"
      )}/features`
    );
  }
}
