import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { Feature } from "../../models/feature/feature";

const sortHeaderAccessorMap: { [key: string]: string } = {
  featureNumber: "sequenceNumber",
  status: "statusDescription",
  lineCoverage: "coverageShortDescription",
  partyProperty: "featureDescription",
  totalLoss: "isTotalLoss",
  caseReserve: "adjusterCaseReserveAmount",
  owner: "owner",
};

@Component({
  selector: "cla-feature-table",
  templateUrl: "./feature-table.component.html",
  styleUrls: ["./feature-table.component.scss"],
})
export class FeatureTableComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(public coreUiStateService: CoreUiExtensionsStateService) {}

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: MatTableDataSource<any>;
  public sortHeaderAccessorMapKeys: string[];

  @Input()
  features: Feature[];

  ngOnInit(): void {
    this.sortHeaderAccessorMapKeys = Object.keys(sortHeaderAccessorMap);
  }

  ngAfterViewInit() {
    this.setupDataSource();
  }

  ngOnChanges() {
    this.setupDataSource();
  }

  setupDataSource() {
    this.dataSource = new MatTableDataSource(this.features);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
      data[sortHeaderAccessorMap[sortHeaderId]];
  }

  STATUS_INDICATORS: { [key: number]: string } = {
    0: "inactive",
    1: "warn",
    2: "warn",
    3: "warn",
    4: "active",
    5: "inactive",
    6: "alert",
    7: "inactive",
    8: "active",
  };
}
