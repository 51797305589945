import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TechDiffMessageResolver implements Resolve<SafeHtml | string> {
  constructor(private domSanitizer: DomSanitizer) {}

  resolve(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    switch (state.url) {
      case "/unsupported-browser":
        return `
          Your current browser is not supported.
          <strong class="error-screen-help">
            If the problem persists, please reach out to your Progressive contact.
          </strong>
        `;

      case "/invalid-party":
        return `
          The party id you requested is not valid - party ids are all numbers.
          <strong class="error-screen-help">
            If the problem persists, please reach out to your Progressive contact.
          </strong>
        `;

      case "/missing-ldap":
        return `
          Please confirm you have the correct security role for your job title.
          <strong class="error-screen-help">
            If the problem persists, please reach out to your Progressive contact.
          </strong>
        `;

      default:
        if (state.url.endsWith("/techdiff")) {
          return this.domSanitizer.bypassSecurityTrustHtml(`
            <button class="cui-link" onclick="history.back()">Click here</button> to go back.
            <strong class="error-screen-help">
              If the problem persists, please reach out to your Progressive contact.
            </strong>
          `);
        }
        return `
          <strong class="error-screen-help">
            If the problem persists, please reach out to your Progressive contact.
          </strong>
        `;
    }
  }
}
