import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claTwoOrMoreDigitsPipe",
})
export class TwoOrMoreDigitsPipe implements PipeTransform {
  transform(value: string): string {
    return value.length === 1 ? `0${value}` : value;
  }
}
