import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute /*, Router*/, Params } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { DateTime } from "luxon";
import { Observable, Subject, combineLatest } from "rxjs";
import { filter, first, map, switchMap, takeUntil, tap } from "rxjs/operators";

import { ClaimInfo } from "@modules/shared/models/claim-info";
import { CVQ } from "../coverage/models/cvq.interface";
import {
  cvqRequestError,
  cvqs,
  resolvedCvqCount,
} from "../coverage/store/coverage.selectors";

import { UserContext, UserContextService } from "../core";
import * as spinnerSelectors from "../policy/state/spinner/spinner.selectors";
import { PolicyHelperFunctions } from "./helpers";
import { AgentInfo } from "./models/agent-info";
import { AgreementLookup } from "./models/agreement-lookup-response/agreement-lookup";
import { ClaimCoverage } from "./models/claim-coverage";
import { ClaimPolicy } from "./models/claim-policy";
import { CodeDescription } from "./models/code-description";
import { LossVehicle } from "./models/loss-vehicle";
import { PartyDetail } from "./models/party-detail";
import { PolicyDetail } from "./models/policy-detail";
import { PolicyDriver } from "./models/policy-driver";
import { PolicyEndorsement } from "./models/policy-endorsement.interface";
import { PolicyUrlResponse } from "./models/policy-url";
import { PolicyVehicle } from "./models/policy-vehicle";
import * as PartyDetailsActions from "./state/party-details-state/party-details.actions";
import * as PartyDetailsSelectors from "./state/party-details-state/party-details.selectors";
import * as PolicyActions from "./state/policy/policy.actions";
import * as PolicySelectors from "./state/policy/policy.selectors";
import { onUnderwritingMemosByClaimNumberRequested } from "./state/uw-memo-landing/uw-memo-landing.actions";

import { LiveAnnouncer } from "@angular/cdk/a11y";
import { PolicyLoss } from "./models/loss-history";
@Component({
  selector: "cla-coverage-policy-container",
  templateUrl: "./policy-container.component.html",
  styleUrls: ["./policy-container.component.scss"],
})
export class PolicyContainerComponent implements OnInit, OnDestroy {
  policyUrlResponse$: Observable<PolicyUrlResponse | null>;
  claimPolicy$: Observable<ClaimPolicy | null>;
  claimInfo$: Observable<ClaimInfo | null>;
  userId: string;
  agreementLookup$: Observable<AgreementLookup | null>;
  policyDetail$: Observable<PolicyDetail | null>;
  policyLossVehicles$: Observable<LossVehicle[]>;
  claimCoverages$: Observable<ClaimCoverage[] | null>;
  partyDrivers$: Observable<PartyDetail[] | undefined>;
  policyAttachVehicle$: Observable<PolicyVehicle[] | null>;
  policyStatus$: Observable<CodeDescription | undefined>;
  policyDrivers$: Observable<PolicyDriver[]>;
  policyLosses$: Observable<PolicyLoss[] | null>;
  policyEndorsements$: Observable<PolicyEndorsement[]>;
  isInUnderwritingMemoPilot: boolean;
  claimNumber: string;
  cvqs$: Observable<CVQ[] | undefined>;
  resolvedCvqCount$: Observable<number>;
  dateOfLoss: Date | string;
  zoneParsedDateOfLoss: string;
  dateOfLossRetrieved: boolean;
  policyDriversRetrieved: boolean;
  claimParty: string;
  agentInfo$: Observable<AgentInfo | null>;
  showSearchBar$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  policyTitle: string = "";
  hasCvqs: boolean = false;
  statusIndicators: Record<string, string> = {
    "0003": "active",
    "00102": "active",
    "0017": "active",
  };
  cvqRequestError$: Observable<boolean>;
  partyName: string;
  firstName: string = "";
  lastName: string = "";
  namedInsured: string = "";
  svgImage: string = "";
  isBusiness: boolean = false;
  businessName: string = "";
  cancelDate: Date | null;
  isThirdPartyIndicator: boolean = false;
  isPolicyDriversLoading$: Observable<boolean>;
  isPolicyVehicleLoading$: Observable<boolean>;
  isEndorsementsLoading$: Observable<boolean>;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  policyNumber: string;

  constructor(
    private store$: Store<AppState>,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private liveAnnouncer: LiveAnnouncer
  ) {}

  ngOnInit(): void {
    this.init();
    this.cvqs$ = this.store$.select(cvqs);
    this.cvqRequestError$ = this.store$.select(cvqRequestError);
    this.resolvedCvqCount$ = this.store$.select(resolvedCvqCount);
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    this.store$.dispatch(PolicyActions.clearPolicy());
  }

  private init() {
    this.isLoading$ = this.store$.pipe(
      select(spinnerSelectors.selectIsSpinnerActive),
      tap((isSpinnerActive: boolean) => {
        this.liveAnnouncer.announce(
          isSpinnerActive
            ? "Loading Policy Information"
            : "Policy Information Loaded"
        );
      })
    );
    this.policyAttachVehicle$ = this.store$.select(
      PolicySelectors.selectAttachPolicyVehicles
    );
    this.isPolicyDriversLoading$ = this.store$.pipe(
      select(PolicySelectors.selectIsPolicyDriversLoading)
    );
    this.isPolicyVehicleLoading$ = this.store$.pipe(
      select(PolicySelectors.selectIsPolicyVehicleLoading)
    );
    this.isEndorsementsLoading$ = this.store$.pipe(
      select(PolicySelectors.selectIsEndorsementsLoading)
    );
    this.userContextService.userContextSource$
      .pipe(
        first((context: UserContext) => context.profile !== undefined),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((context: UserContext) => {
        this.userId = context.profile.uid;
      });
    this.claimPolicy$ = this.store$
      .select(PolicySelectors.selectClaimPolicy)
      .pipe(
        tap((claimPolicy: ClaimPolicy) => {
          const title: string = "Policy";
          if (claimPolicy) {
            this.policyNumber = claimPolicy.policyNumber;
            this.policyTitle =
              claimPolicy.policyNumber !== ""
                ? `${title}: ${claimPolicy.policyNumber} (${claimPolicy.productDesc})`
                : title;
            this.svgImage = PolicyHelperFunctions.getSVGImage(
              claimPolicy.productCategoryCode
            );
            if (
              this.dateOfLossRetrieved &&
              claimPolicy?.policyNumber !== null &&
              claimPolicy?.policyNumber !== ""
            ) {
              if (!this.policyDriversRetrieved) {
                this.store$.dispatch(
                  PolicyActions.getPolicyDrivers({
                    policyNumber: claimPolicy.policyNumber,
                    renewalSuffixNumber: Number(
                      claimPolicy.renewalSuffixNumber
                    ),
                    dateOfLoss: this.dateOfLoss,
                    productCode: claimPolicy.productCategoryCode,
                  })
                );
              }
              this.store$.dispatch(
                PolicyActions.getPolicyEndorsements({
                  policyNumber: claimPolicy.policyNumber,
                  dateOfLoss: this.dateOfLoss,
                })
              );
            }
            this.claimInfo$ = this.store$
              .select(PolicySelectors.selectClaimInfo)
              .pipe(
                tap((claimInfo: ClaimInfo) => {
                  if (claimInfo) {
                    this.namedInsured = claimInfo.namedInsured;
                    this.dateOfLoss = claimInfo.dateOfLoss;
                    this.zoneParsedDateOfLoss =
                      typeof claimInfo.dateOfLoss === "string"
                        ? DateTime.fromISO(claimInfo.dateOfLoss).toFormat(
                            "MM/dd/yyyy"
                          )
                        : DateTime.fromJSDate(claimInfo.dateOfLoss).toFormat(
                            "MM/dd/yyyy"
                          );

                    this.store$.dispatch(
                      PolicyActions.getPolicyDetails({
                        isPolicyAttached: claimPolicy.isPolicyAttached,
                        policyNumber: claimPolicy.policyNumber,
                        renewalSuffixNumber: claimPolicy.renewalSuffixNumber,
                        dateOfLoss: this.dateOfLoss,
                      })
                    );
                  }
                })
              );
          }
        })
      );
    this.policyDetail$ = this.store$
      .select(PolicySelectors.selectPolicyDetail)
      .pipe(
        tap((policyDetail: PolicyDetail) => {
          if (policyDetail) {
            if (policyDetail.partyInfo) {
              if (
                policyDetail.partyInfo.name !== null &&
                policyDetail.partyInfo.name !== ""
              ) {
                this.firstName = policyDetail.partyInfo.name
                  .substring(20, 35)
                  .trim();
                this.lastName = policyDetail.partyInfo.name
                  .substring(0, 20)
                  .trim();
              }
              this.isBusiness = policyDetail.partyInfo.businessIndicator;
              if (this.isBusiness) {
                this.businessName = policyDetail.partyInfo.name;
              }
              this.cancelDate = policyDetail.cancelDate;
              this.isThirdPartyIndicator = policyDetail.thirdPartyIndicator;
            }
            this.cancelDate = policyDetail.cancelDate;
          }
        })
      );
    this.policyStatus$ = this.store$.select(PolicySelectors.selectPolicyStatus);
    this.policyDrivers$ = this.store$
      .select(PolicySelectors.selectPolicyDrivers)
      .pipe(
        tap((policyDrivers: PolicyDriver[]) => {
          if (policyDrivers) {
            this.policyDriversRetrieved = true;
          }
        })
      );

    this.policyEndorsements$ = this.store$.select(
      PolicySelectors.selectPolicyEndorsementsWithDescriptions
    );
    this.agentInfo$ = this.store$.select(PolicySelectors.selectAgentInfo);
    this.store$
      .select(PolicySelectors.selectPolicyAttachVehicleInfo)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((attachInfo) => {
        if (attachInfo) {
          this.store$.dispatch(
            PolicyActions.getPolicyAttachVehicles({
              policyNumber: attachInfo.policyNumber,
              policyNumberSuffix: attachInfo.renewalSuffixNumber,
              dateOfLoss: attachInfo.dateOfLoss,
              policyStateCode: attachInfo.policyStateCode,
            })
          );
        }
      });
    this.policyDetail$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((policyDetail: PolicyDetail | null) => {
        if (policyDetail) {
          const partyName: string = policyDetail.partyInfo?.name;
          this.store$.dispatch(
            PolicyActions.getAgentInfo({
              agentNumber: policyDetail.agentNumber,
            })
          );
          this.partyName = partyName?.substring(0, 20).trim();
        }
      });

    this.claimCoverages$ = this.store$.select(
      PolicySelectors.selectClaimCoverage
    );

    combineLatest([
      this.store$
        .select(PolicySelectors.selectLossVehicles)
        .pipe(filter((x: LossVehicle[]) => x.length !== 0)),
    ])
      .pipe(
        map(([lossVehicles]: [LossVehicle[]]) => {
          if (lossVehicles && lossVehicles.length) {
            const vins: string[] = [];
            lossVehicles.forEach((lossVehicle: LossVehicle) => {
              if (lossVehicle.vin) {
                // eslint-disable-next-line functional/immutable-data
                vins.push(lossVehicle.vin);
              }
            });
            if (vins.length !== 0) {
              //TODO: remove policyNumber
              this.store$.dispatch(
                PolicyActions.getVehicleLosses({
                  policyNumber: "999999",
                  vins: vins,
                })
              );
            }
          }
        })
      )
      .subscribe();

    const claimNumber$: Observable<string> = this.route.params.pipe(
      filter(
        (params: Params) =>
          params["claimNumber"] !== undefined && params["claimNumber"] !== ""
      ),
      map((params: Params) => params["claimNumber"])
    );

    claimNumber$
      .pipe(
        switchMap((claimNumber: string) => {
          this.claimNumber = claimNumber;

          this.store$.dispatch(
            PolicyActions.getClaimPolicyWithPreReq({ claimNumber })
          );
          this.store$.dispatch(
            PolicyActions.getClaimLossVehiclesWithPreReq({ claimNumber })
          );
          this.store$.dispatch(
            PartyDetailsActions.getPartyDetails({ claimNumber })
          );

          this.partyDrivers$ = this.store$
            .select(PartyDetailsSelectors.selectPartyDetail)
            .pipe(filter((x: PartyDetail[] | undefined) => x?.length !== 0));

          this.policyLossVehicles$ = this.store$
            .select(PolicySelectors.selectLossVehicles)
            .pipe(filter((x: LossVehicle[]) => x.length !== 0));

          return combineLatest([
            this.store$
              .select(PolicySelectors.selectClaimInfo)
              .pipe(filter((x: ClaimInfo | null) => x !== null)),
            this.store$
              .select(PolicySelectors.selectClaimPolicy)
              .pipe(filter((x: ClaimPolicy | null) => x !== null)),
            this.policyLossVehicles$,
          ]);
        })
      )
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe(
        ([claimInfo, claimPolicy, lossVehicles]: [
          ClaimInfo,
          ClaimPolicy,
          LossVehicle[]
        ]) => {
          this.dispatchPolicyVehicle(claimInfo, claimPolicy, lossVehicles);
          this.dateOfLoss = claimInfo.dateOfLoss;
          this.dateOfLossRetrieved = true;
          this.claimParty = claimInfo.namedInsured.trim();
        }
      );
    this.store$.dispatch(
      onUnderwritingMemosByClaimNumberRequested({
        claimNumber: this.claimNumber,
      })
    );
  }

  private dispatchPolicyVehicle(
    claimInfo: ClaimInfo,
    claimPolicy: ClaimPolicy,
    lossVehicles: LossVehicle[]
  ) {
    const positions: string[] = [];
    lossVehicles.forEach((lVehicle: LossVehicle) => {
      if (lVehicle.policyPosition !== 0) {
        // eslint-disable-next-line functional/immutable-data
        positions.push(lVehicle.policyPosition.toString());
      }
    });
    if (positions.length > 0) {
      this.store$.dispatch(
        PolicyActions.getClaimPolicyVehicles({
          positions,
          policyNumber: claimInfo.policyNumber,
          renewalSuffixNumber: claimPolicy.renewalSuffixNumber,
          dateOfLoss: claimInfo.dateOfLoss as Date,
          productCode: claimInfo.productCode,
        })
      );
    }
  }
}
