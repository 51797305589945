import { Injectable } from "@angular/core";
import { AdapterApiService } from "@core/services";

import { UwMemoApiService } from "@modules/core/services/uw-memo-api";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { UwMemo } from "../components/uw-memo-landing/models/uw-memo";

@Injectable({
  providedIn: "root",
})
export class UwMemoLandingService {
  private uwMemoTextArr: any[];
  constructor(
    private adapterApiService: AdapterApiService,
    private uwMemoApiService: UwMemoApiService
  ) {}

  getUnderwritingMemos(claimNumber: string): Observable<UwMemo[]> {
    return this.uwMemoApiService.get<UwMemo[]>(
      `/lossviewerapi/v1/claims/${claimNumber}/memos`
    );
  }

  formatQuestions(uwMemo: UwMemo, uwMemoQuestionXref: any[]): any[] {
    this.uwMemoTextArr = [];
    const uwMemoContent = uwMemo.content;
    const createdOnKey = "createdUtcDateTime";
    const createdByKey = "createdByUserId";
    const localCreatedOnDate = DateTime.fromJSDate(
      new Date(uwMemo[createdOnKey].toString())
    ).toFormat("MM/dd/yyyy");
    this.addQuestionToTextArr("Created on:", createdOnKey, localCreatedOnDate);
    this.addQuestionToTextArr(
      "Created by:",
      createdByKey,
      uwMemo[createdByKey]
    );

    for (const prop in uwMemoContent) {
      if (Object.prototype.hasOwnProperty.call(uwMemoContent, prop)) {
        switch (uwMemo.categoryDesc) {
          case "Apworkup": {
            this.formatApworkupQuestions(
              uwMemo.categoryDesc,
              uwMemo.templateVersion,
              uwMemoContent,
              prop,
              uwMemoQuestionXref
            );
            break;
          }
          case "Misrep": {
            this.formatMisrepQuestions(
              uwMemo.categoryDesc,
              uwMemo.templateVersion,
              uwMemoContent,
              prop,
              uwMemoQuestionXref
            );
            break;
          }
          case "Policy Changes": {
            this.formatPolicyChangesQuestions(
              uwMemo.categoryDesc,
              uwMemo.templateVersion,
              uwMemoContent,
              prop,
              uwMemoQuestionXref
            );
            break;
          }
          case "Policy Investigation": {
            this.formatPolicyInvestigationQuestions(
              uwMemo.categoryDesc,
              uwMemo.templateVersion,
              uwMemoContent,
              prop,
              uwMemoQuestionXref
            );
            break;
          }
          default: {
            break;
          }
        }
      }
    }
    return this.uwMemoTextArr;
  }

  private addQuestionToTextArr(
    question: string,
    varName: string,
    varValue: string
  ) {
    this.uwMemoTextArr.push({
      questionText: question,
      variableName: varName,
      variableValue: varValue,
    });
  }

  private searchXref(
    category: string,
    nameKey: string,
    templateVersion: number,
    xref: any[]
  ): string {
    return xref
      .find(
        (x) =>
          x.categoryCode === category &&
          x.fieldName === nameKey &&
          x.templateVersion === templateVersion
      )
      ?.questionText.toString();
  }

  private formatApworkupQuestions(
    category: string,
    templateVersion: number,
    uwMemoContent: any,
    prop: string,
    uwMemoQuestionXref: any[]
  ) {
    let question = this.searchXref(
      category,
      prop,
      templateVersion,
      uwMemoQuestionXref
    );

    if (
      prop === "vehicleImpacted" ||
      prop === "vehiclesImpacted" ||
      prop === "vehicleToBeReplaced" ||
      prop === "vehicleWorkup"
    ) {
      this.formatComplexQuestions(uwMemoContent[prop], prop, question);
    } else if (question === undefined) {
      question = `*** Not Found: ${prop}`;
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    } else {
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    }
  }

  private formatMisrepQuestions(
    category: string,
    templateVersion: number,
    uwMemoContent: any,
    prop: string,
    uwMemoQuestionXref: any[]
  ) {
    let question = this.searchXref(
      category,
      prop,
      templateVersion,
      uwMemoQuestionXref
    );

    if (question === undefined) {
      question = `*** Not Found: ${prop}`;
    }

    this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
  }

  private formatPolicyChangesQuestions(
    category: string,
    templateVersion: number,
    uwMemoContent: any,
    prop: string,
    uwMemoQuestionXref: any[]
  ) {
    let question = this.searchXref(
      category,
      prop,
      templateVersion,
      uwMemoQuestionXref
    );

    if (
      prop === "driverOnThePolicyInvolvedInClaim" ||
      prop === "driverThatNeedsUpdatedInformation" ||
      prop === "removePIPMedicalExclusionFor" ||
      prop === "selectDriverRequiringUpdate" ||
      prop === "selectThePolicyDriverToBeDeleted" ||
      prop === "selectVehicleToChangeLienholderInfo" ||
      prop === "vehiclesGaragedAtDiffLocation" ||
      prop === "vehicleInfo" ||
      prop === "vehicleSpecified" ||
      prop === "vehiclesUsedAsRideshare" ||
      prop === "vehicleThatIsAnORSTotalLoss" ||
      prop === "vehicleToBeAdded" ||
      prop === "vehicleToBeDeleted" ||
      prop === "vehicleToBeUpdated"
    ) {
      this.formatComplexQuestions(uwMemoContent[prop], prop, question);
    } else if (prop === "phoneCorrections") {
      this.formatComplexQuestions(
        uwMemoContent[prop],
        prop,
        "Phone # Correction"
      );
    } else if (prop === "corrections") {
      this.formatCorrections(uwMemoContent[prop], prop);
    } else if (question === undefined) {
      question = `*** Not Found: ${prop}`;
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    } else {
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    }
  }

  private formatPolicyInvestigationQuestions(
    category: string,
    templateVersion: number,
    uwMemoContent: any,
    prop: string,
    uwMemoQuestionXref: any[]
  ) {
    let question = this.searchXref(
      category,
      prop,
      templateVersion,
      uwMemoQuestionXref
    );

    if (
      prop === "whichVehicleRequiresUseInvestigation" ||
      prop === "whichVehiclesAreGaragedAtDifferentLocation"
    ) {
      this.formatComplexQuestions(uwMemoContent[prop], prop, question);
    } else if (question === undefined) {
      question = `*** Not Found: ${prop}`;
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    } else {
      this.addQuestionToTextArr(question, prop, uwMemoContent[prop]);
    }
  }

  private formatCorrections(questionArray: any[], varName: string) {
    this.addQuestionToTextArr("Corrections", varName, "");

    questionArray.forEach((question) => {
      if (question["driverInfoToCorrect"]) {
        this.addQuestionToTextArr(
          question["driverInfoToCorrect"].concat(":"),
          question["driverInfoToCorrect"],
          question["correctedInfo"]
        );
      }
      if (question["vehicleInfoToCorrect"]) {
        this.addQuestionToTextArr(
          question["vehicleInfoToCorrect"].concat(":"),
          question["vehicleInfoToCorrect"],
          question["correctedInfo"]
        );
      }
    });
  }

  private formatComplexQuestions(
    questionArray: any[],
    varName: string,
    rootQuestion: string
  ) {
    this.addQuestionToTextArr(rootQuestion, varName, "");

    if (typeof questionArray === "string") return;

    questionArray.forEach((question) => {
      for (const key of Object.keys(question)) {
        let result = key.replace(/([A-Z])/g, " $1");
        result = result.charAt(0).toUpperCase() + result.slice(1);
        result = result.concat(":");
        this.addQuestionToTextArr(result, key, question[key]);
      }
    });
  }
}
