export enum NoteEvent {
  NOTE_ADD_SUCCESS = "NOTE_ADD_SUCCESS",
  NOTE_ADD_FAILURE = "NOTE_ADD_FAILURE",
  NOTE_ADD_ALREADY_OPEN = "NOTE_ADD_ALREADY_OPEN",
  NOTE_ADD_SUCCESS_DIARY_SUCCESS = "NOTE_ADD_SUCCESS_DIARY_SUCCESS",
  NOTE_ADD_SUCCESS_DIARY_FAILURE = "NOTE_SUCCESS_DIARY_FAILURE",
  NOTE_EDIT_SUCCESS = "NOTE_EDIT_SUCCESS",
  NOTE_EDIT_FAILURE = "NOTE_EDIT_FAILURE",
  NOTE_EDIT_ALREADY_OPEN = "NOTE_EDIT_ALREADY_OPEN",
  NOTE_EDIT_SUCCESS_DIARY_SUCCESS = "NOTE_EDIT_SUCCESS_DIARY_SUCCESS",
  NOTE_EDIT_SUCCESS_DIARY_FAILURE = "NOTE_EDIT_SUCCESS_DIARY_FAILURE",
  DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS",
  UNEXPECTED_FAILURE = "UNEXPECTED_FAILURE",
}

export const noteMessages: { readonly [k: string]: string } = Object.freeze({
  [NoteEvent.NOTE_ADD_SUCCESS]: "Note has been added.",
  [NoteEvent.NOTE_ADD_FAILURE]:
    "An error occurred while attempting to add the note.",
  [NoteEvent.NOTE_ADD_ALREADY_OPEN]:
    "An add note window is already open for this claim. Please switch to it to continue working on your note.",
  [NoteEvent.NOTE_ADD_SUCCESS_DIARY_SUCCESS]: "Note and diary have been added.",
  [NoteEvent.NOTE_ADD_SUCCESS_DIARY_FAILURE]:
    "Note has been added, but diaries have not been added.",
  [NoteEvent.NOTE_EDIT_SUCCESS]: "Note has been updated.",
  [NoteEvent.NOTE_EDIT_FAILURE]:
    "An error occurred while attempting to update the note.",
  [NoteEvent.NOTE_EDIT_ALREADY_OPEN]:
    "An edit window is already open for this note. Please switch to it to continue working on your note.",
  [NoteEvent.NOTE_EDIT_SUCCESS_DIARY_SUCCESS]:
    "Note has been updated and diary has been added.",
  [NoteEvent.NOTE_EDIT_SUCCESS_DIARY_FAILURE]:
    "Note has been updated, but diaries have not been added.",
  [NoteEvent.DOWNLOAD_SUCCESS]: "Notes have been downloaded.",
  [NoteEvent.UNEXPECTED_FAILURE]:
    "An unexpected error occurred. Please try again.",
});

export const unknownCategoryCode = "XX";
export const toDoCategoryCode = "TD";
export const charactersPerLine = 78;
export const numberingRegex = /[0-9]+\./;

export const keyCodes: { readonly [k: string]: string } = Object.freeze({
  Enter: "Enter",
});
export const arrowKeyCodes = [
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Left",
  "Right",
  "Up",
  "Down",
];
export const invalidCharactersRegex =
  // eslint-disable-next-line no-control-regex
  /[\u0000-\u0008\u000B-\u000C\u000E-\u001F\u007F\u0080-\u009F]/g;
export const replaceableCharacters = [
  { invalid: /[\u2033\u201C\u201D]/g, valid: '"' },
  { invalid: /[\u2032\u201B\u2018\u2019]/g, valid: "'" },
];
export const pageTitleSeparator = " | ";
export const repairShopCodeRegex = /^\d+$/;
