import { PartyDisplayOrder } from "@modules/core/models";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import {
  AppIntegrationType,
  ClaimDetailsState,
} from "./models/claim-details-state.interface";

export const claimDetailsState =
  createFeatureSelector<ClaimDetailsState>("claimDetails");

export const selectIsInitialized = createSelector(
  claimDetailsState,
  (state) => state.isInitialized
);
export const selectAppIntegrationType = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType
);
export const selectIsClaimProEmbedded = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType === AppIntegrationType.CLAIMPRO_EMBEDDED
);
export const selectIsClaimProChild = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType === AppIntegrationType.CLAIMPRO_CHILD
);
export const selectIsClaimStationEmbedded = createSelector(
  claimDetailsState,
  (state) =>
    state.appIntegrationType === AppIntegrationType.CLAIMSTATION_EMBEDDED
);
export const selectIsClaimStationChild = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType === AppIntegrationType.CLAIMSTATION_CHILD
);
export const selectIsStandalone = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType === AppIntegrationType.STANDALONE
);
export const selectIsStandaloneChild = createSelector(
  claimDetailsState,
  (state) => state.appIntegrationType === AppIntegrationType.STANDALONE_CHILD
);

export const selectIsClaimDetailsRequestInFlight = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.isClaimDetailsRequestInFlight
);

export const selectClaimNumber = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimNumber
);

export const selectClaimDetails = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimDetails
);

export const selectClaimSummary = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimSummary
);

export const selectClaimDetailsError = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimDetailsError
);

export const selectClaimSummaryError = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimSummaryError
);

export const selectLossDateTime = createSelector(
  selectClaimDetails,
  (claimDetails) => claimDetails?.claimLossDate
);

export const selectIndividualPolicyDrivers = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) =>
    state.associatedPolicyDrivers.data?.filter((d) => !d.isBusiness)
);

export const selectReportDateTime = createSelector(
  selectClaimDetails,
  (claimDetails) => claimDetails?.claimReportDate
);

export const selectIsSiuInvolved = createSelector(
  selectClaimDetails,
  (claimDetails) => claimDetails?.isSiuInvolved
);

export const selectIsPartyPropertyDetailsRequestInFlight = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.isPartyPropertyDetailsRequestInFlight
);

export const selectPartyPropertyDetails = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.partyPropertyDetails
);

export const selectSuccessBannerMessage = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.successBannerMessage
);

export const selectErrorBannerMessages = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.errorBannerMessages
);

export const selectWarningBannerMessage = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.warningBannerMessage
);

export const selectWarningBannerIsDismissable = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.warningBannerIsDismissable
);

export const selectPartyTreeDisplayOrder = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => {
    let flattenedTree: PartyDisplayOrder[] = [];
    if (state.partyPropertyDetails) {
      state.partyPropertyDetails.forEach((property) => {
        property.associatedPartyProperties.forEach((party) => {
          flattenedTree = [
            ...flattenedTree,
            {
              id: party.id,
              displayOrder: flattenedTree.length + 1,
            },
          ];
        });
      });
    }
    return flattenedTree;
  }
);

export const selectPolicyState = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimDetails?.policyState.name
);

export const selectPolicyNumber = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimDetails?.policyNumber
);

export const selectIncidentState = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.claimDetails?.accidentLocationState.name
);

export const selectIsPageScrollingEnabled = createSelector(
  claimDetailsState,
  (state: ClaimDetailsState) => state.isPageScrollingEnabled
);
