export const mpgMpegMimeTypes = [
  "video/mpeg",
  "video/mpv",
  "video/mp2t",
  "video/mp1s",
  "video/mp2p ",
  "video/mpg",
  "video/x-mpg",
  "video/mpeg2",
  "application/x-pn-mpg",
  "video/x-mpeg",
  "video/x-mpeg2a",
  "video/mp4",
  "application/mp4",
  "application/mpeg4-iod",
  "application/mpeg4-iod-xmt",
  "video/mp4v-es",
  "video/mpeg4-generic",
];
