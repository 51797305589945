import { DiaryState } from "./diary-state.interface";

export const diaryInitialState: DiaryState = {
  diaries: undefined,
  diaryTableData: [],
  diaryRequestInFlight: false,
  diaryError: undefined,
  diaryCalenderCountData: undefined,
  diaryCalenderDataRequestInFlight: false,
  diaryCalenderDataRequestError: undefined,
};
