import { createSelector } from "@ngrx/store";
import * as sharedSelectors from "./shared.selectors";

export const selectList = createSelector(
  sharedSelectors.selectFeatureState,
  (state) => state.list
);

export const selectClaimInfo = createSelector(
  selectList,
  (state) => state.claimInfo
);

export const selectFeatures = createSelector(
  selectList,
  (state) => state.features
);

export const selectClaimNumber = createSelector(
  selectList,
  (state) => state.claimNumber
);
