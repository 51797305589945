import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";

import * as outOfPocketsActions from "@subrogation-summary/state/actions/out-of-pockets.actions";
import { OutOfPocket } from "@subrogation-summary/models/out-of-pocket.interface";

interface OutOfPocketsErrorsState {
  failedToLoadOutOfPockets: boolean;
}

export interface OutOfPocketsState {
  outOfPockets: EntityState<OutOfPocket>;
  errors: OutOfPocketsErrorsState;
}

export function selectOopId(oop: OutOfPocket): string {
  return `${oop.claimNumber}-${oop.addTimestamp}`;
}

export const outOfPocketsAdapter: EntityAdapter<OutOfPocket> =
  createEntityAdapter<OutOfPocket>({
    selectId: selectOopId,
  });

export const selectors = outOfPocketsAdapter.getSelectors();

const initialState: OutOfPocketsState = {
  outOfPockets: outOfPocketsAdapter.getInitialState(),
  errors: {
    failedToLoadOutOfPockets: false,
  },
};

export function reducer(state: OutOfPocketsState, action: Action) {
  return outOfPocketsReducer(state, action);
}

const outOfPocketsReducer = createReducer(
  initialState,
  on(
    outOfPocketsActions.loadOutOfPocketsSucceeded,
    (state, { outOfPockets }) => ({
      errors: { ...state.errors, failedToLoadOutOfPockets: false },
      outOfPockets: outOfPocketsAdapter.setAll(
        outOfPockets,
        state.outOfPockets
      ),
    })
  ),
  on(outOfPocketsActions.loadOutOfPocketsFailed, (state) => ({
    ...state,
    errors: { ...state.errors, failedToLoadOutOfPockets: true },
  }))
);
