/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AdapterApiService, ClaimManagementApiService } from "@modules/core";
import { CodeTableApiService } from "@modules/core/services/code-table-api";
import { CustomerInfoAdapterApiService } from "@modules/core/services/customer-info-adapter-api";
import { PolicyDetailsApiService } from "@modules/core/services/policy-details-api";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { CodeTableCode } from "@modules/shared/models/code-table-code.interface";
import { CodeTable } from "@modules/shared/models/code-table.interface";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { PolicyHelperFunctions } from "../helpers/policy-helper.functions";
import { AttachedPolicyVehicle } from "../models";
import { Auto } from "../models/auto";
import { ClaimPolicy } from "../models/claim-policy";
import { PolicyLoss, VehicleLoss } from "../models/loss-history";
import { MotorHome } from "../models/motor-home";
import { Motorcycle } from "../models/motorcycle";
import {
  PolicyConfirm,
  PolicyConfirmDriver,
  PolicyConfirmVehicle,
} from "../models/policy-confirm";
import { PolicyDetail } from "../models/policy-detail";
import { PolicyDriver } from "../models/policy-driver";
import { PolicyDriverLicense } from "../models/policy-driver-license";
import { PolicyEndorsement } from "../models/policy-endorsement.interface";
import { PolicySearch } from "../models/policy-search";
import { PolicyUrlResponse } from "../models/policy-url";
import { PolicyVehicle } from "../models/policy-vehicle";
import { ProductCode } from "../models/productcode-description";
import { StateCode } from "../models/statecode-description";
import { TravelTrailer } from "../models/travel-trailer";

@Injectable()
export class PolicyService {
  constructor(
    private policyDetailsApiService: PolicyDetailsApiService,
    private claimManagementApiService: ClaimManagementApiService,
    private codeTableApiService: CodeTableApiService,
    private adapterApiService: AdapterApiService,
    private customerAdapterApiService: CustomerInfoAdapterApiService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getPolicyUrlResponse(
    policyNumber: string,
    policyRenewalNumber: number
  ): Observable<PolicyUrlResponse | null> {
    return this.policyDetailsApiService.get<PolicyUrlResponse>(
      `/lossviewerapi/v1/policy/${policyNumber}/policy-url?policyRenewalNumber=${policyRenewalNumber}`
    );
  }

  getClaimPolicy(claimNumber: string): Observable<ClaimPolicy> {
    return this.policyDetailsApiService
      .get<ClaimPolicy>(`/lossviewerapi/v1/claims/${claimNumber}/claimpolicy`)
      .pipe(
        catchError((error: any) => {
          return this.errorMasking(
            error,
            `Claim ${this.claimNumberPipe.transform(
              claimNumber,
              "full"
            )} Not Found`
          );
        })
      );
  }

  getPolicyDetail(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | string | null,
    viewDate: Date | null = null
  ): Observable<PolicyDetail[] | null> {
    return this.policyDetailsApiService
      .get<PolicyDetail[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/policy-level?renewalSuffixNumber=${renewalSuffixNumber}&dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyDrivers(
    policyNumber: string,
    renewalSuffixNumber: number,
    dateOfLoss: Date | string | null,
    productCode: string,
    viewDate: Date | null = null
  ): Observable<PolicyDriver[] | null> {
    return this.policyDetailsApiService
      .get<PolicyDriver[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/policy-drivers?renewalSuffixNumber=${renewalSuffixNumber}&dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&productCode=${productCode}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyLosses(policyNumber: string): Observable<PolicyLoss[] | null> {
    return this.policyDetailsApiService
      .get<PolicyLoss[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/policy-losses`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getVehicleLosses(
    policyNumber: string,
    vins: string[]
  ): Observable<VehicleLoss[] | null> {
    let params = new HttpParams();
    for (const vin of vins) {
      params = params.append("vins", vin);
    }
    return this.policyDetailsApiService
      .get<VehicleLoss[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicle-losses?${params.toString()}`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleAuto(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/auto?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        map((data: Auto[]) =>
          data.map((auto: Auto) => {
            return {
              ...auto,
              suspensionToDate1: new Date(auto.suspensionToDate1),
              suspensionFromDate1: new Date(auto.suspensionFromDate1),
              suspensionToDate2: new Date(auto.suspensionToDate2),
              suspensionFromDate2: new Date(auto.suspensionFromDate2),
            };
          })
        ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleCommercial(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/commercial?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleMotorcycle(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/motorcycle?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        map((data: Motorcycle[]) =>
          data.map((moto: Motorcycle) => {
            return {
              ...moto,
              suspensionToDate1: new Date(moto.suspensionToDate1),
              suspensionFromDate1: new Date(moto.suspensionFromDate1),
              suspensionToDate2: new Date(moto.suspensionToDate2),
              suspensionFromDate2: new Date(moto.suspensionFromDate2),
            };
          })
        ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleBoat(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/boat?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleMotorHome(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/motor-home?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        map((data: MotorHome[]) =>
          data.map((motorHome: MotorHome) => {
            return {
              ...motorHome,
              suspensionToDate1: new Date(motorHome.suspensionToDate1),
              suspensionFromDate1: new Date(motorHome.suspensionFromDate1),
              suspensionToDate2: new Date(motorHome.suspensionToDate2),
              suspensionFromDate2: new Date(motorHome.suspensionFromDate2),
            };
          })
        ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleTravelTrailer(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/travel-trailer?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        map((data: TravelTrailer[]) =>
          data.map((travelTrailer: TravelTrailer) => {
            return {
              ...travelTrailer,
              suspensionToDate1: new Date(travelTrailer.suspensionToDate1),
              suspensionFromDate1: new Date(travelTrailer.suspensionFromDate1),
              suspensionToDate2: new Date(travelTrailer.suspensionToDate2),
              suspensionFromDate2: new Date(travelTrailer.suspensionFromDate2),
            };
          })
        ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleManufacturedHome(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/manufactured-home?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  getPolicyVehicleManual(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date | null,
    positions: string[],
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    let params = new HttpParams().set(
      "renewalSuffixNumber",
      renewalSuffixNumber
    );
    for (const position of positions) {
      params = params.append("policyPositions", position);
    }
    return this.policyDetailsApiService
      .get<PolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/manual?dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&${params.toString()}&viewDate=${viewDate ?? ""}`
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // TODO: un-comment testing and replace its spy with the right spy once this is uncommented
  getPolicySearchByPolicyNumber(
    policyNumber: string,
    policySuffixNumber: string,
    dateOfLoss: Date
  ): Observable<PolicySearch[] | null> {
    const tmep: PolicySearch[] = [];
    return of(tmep);
    // return this.policyDetailsApiService
    //   .post<PolicySearch[], PolicyNumberSearchRequest>(
    //     '/v1/policies', {
    //       policyNumber,
    //       dateOfLoss: PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss),
    //       searchType: 'policynumber',
    //       PolicyNumberSuffix: policySuffixNumber || null,
    //     })
    //   .pipe(
    //     catchError((error: any) => {
    //       if (error.status === 404) {
    //         return of([]);
    //       }
    //       return throwError(error);
    //     }));
  }

  getPolicyConfirm(
    policyNumber: string,
    renewalSuffixNumber: string,
    dateOfLoss: Date
  ): Observable<PolicyConfirm | null> {
    return this.adapterApiService
      .get<PolicyConfirm>(
        `/lossviewerapi/v1/policy/${policyNumber}/policy-confirm-details`,
        `?renewalSuffixNumber=${renewalSuffixNumber}&dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyConfirmDrivers(
    policyNumber: string,
    policySuffixNumber: string,
    dateOfLoss: Date
  ): Observable<PolicyConfirmDriver[] | null> {
    return this.policyDetailsApiService
      .get<PolicyConfirmDriver[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/policy-drivers?dateOfLoss=${PolicyHelperFunctions.getISODateWithoutTimeOrZone(
          dateOfLoss
        )}&renewalSuffixNumber=${policySuffixNumber}`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyConfirmVehicles(
    policyNumber: string,
    PolicyNumberSuffix: string,
    dateOfLoss: Date
  ): Observable<PolicyConfirmVehicle[] | null> {
    return this.policyDetailsApiService
      .get<PolicyConfirmVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/all?dateOfLoss=${PolicyHelperFunctions.getISODateWithoutTimeOrZone(
          dateOfLoss
        )}&renewalSuffixNumber=${PolicyNumberSuffix}`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyAttachVehicles(
    policyNumber: string,
    PolicyNumberSuffix: string,
    dateOfLoss: Date,
    policyStateCode: string,
    viewDate: Date | null = null
  ): Observable<PolicyVehicle[] | null> {
    return this.policyDetailsApiService
      .get<AttachedPolicyVehicle[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/vehicles/all?dateOfLoss=${PolicyHelperFunctions.getISODateWithoutTimeOrZone(
          dateOfLoss
        )}&renewalSuffixNumber=${PolicyNumberSuffix}&policyStateCode=${policyStateCode}&viewDate=${viewDate}`
      )
      .pipe(
        map((data: AttachedPolicyVehicle[]) =>
          data.map((attachedPolicyVehicle: AttachedPolicyVehicle) => {
            return {
              ...attachedPolicyVehicle,
              suspensionToDate1: new Date(
                attachedPolicyVehicle.suspensionToDate1
              ),
              suspensionFromDate1: new Date(
                attachedPolicyVehicle.suspensionFromDate1
              ),
              suspensionToDate2: new Date(
                attachedPolicyVehicle.suspensionToDate2
              ),
              suspensionFromDate2: new Date(
                attachedPolicyVehicle.suspensionFromDate2
              ),
            };
          })
        ),
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  getPolicyEndorsements(
    policyNumber: string,
    dateOfLoss: Date | string
  ): Observable<PolicyEndorsement[] | null> {
    return this.policyDetailsApiService
      .get<PolicyEndorsement[]>(
        `/lossviewerapi/v1/policy/${policyNumber}/attachments?dateOfLoss=${PolicyHelperFunctions.getISODateWithoutTimeOrZone(
          dateOfLoss
        )}`
      )
      .pipe(
        catchError((err: any) => {
          if (err.status === 404) {
            return of(null);
          }
          return throwError(err);
        })
      );
  }

  getClaimInfo(claimNumber: string): Observable<ClaimInfo> {
    return this.claimManagementApiService
      .get<ClaimInfo>(
        `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
          claimNumber,
          "full"
        )}/details`
      )
      .pipe(
        catchError((error: unknown) => {
          return this.errorMasking(
            error as HttpErrorResponse,
            `Claim ${this.claimNumberPipe.transform(
              claimNumber,
              "full"
            )} Not Found`
          );
        }),
        shareReplay(1)
      );
  }

  getDriverLicenseNumberForPolicyDriver(
    policyNumber: string | undefined,
    renewalSuffixNumber: number | undefined,
    dateOfLoss: Date | string | null,
    policyPosition: string,
    claimNumber: string | undefined
  ): Observable<PolicyDriverLicense | null> {
    return this.policyDetailsApiService
      .get<PolicyDriverLicense>(
        `/lossviewerapi/v1/policy/${policyNumber}/dln?renewalSuffixNumber=${renewalSuffixNumber}&dateOfLoss=${
          dateOfLoss !== null
            ? PolicyHelperFunctions.getISODateWithoutTimeOrZone(dateOfLoss)
            : dateOfLoss
        }&policyPosition=${policyPosition}&claimNumber=${claimNumber}`
      )
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }

  private errorMasking(error: any, message: string) {
    if (error.status === 404) {
      return throwError({ ...error, statusText: message });
    }
    return throwError(error);
  }

  getStateCodeTable(): Observable<CodeTable<StateCode>[] | null> {
    return this.getCodeTables("states");
  }

  getProductCodeTable(): Observable<CodeTable<ProductCode>[] | null> {
    return this.getCodeTables("products");
  }

  getEndorsementCodeTable(): Observable<CodeTable<CodeTableCode>[] | null> {
    const mock: CodeTable<CodeTableCode>[] = [
      {
        schema: "dbo",
        name: "attachments",
        values: [
          {
            code: "0001ED02",
            description: "North Carolina Personal Auto Policy",
            isRetired: "N",
          },
          {
            code: "0001ED04",
            description: "North Carolina Personal Auto Policy",
            isRetired: "N",
          },
        ],
      },
    ];
    return of(mock);
    // return this.getCodeTables("attachments");
  }

  getCodeTables(codeTableNames: string): Observable<CodeTable<any>[] | null> {
    return this.codeTableApiService
      .get<any[]>(`/lossviewerapi/v1/codeTables?tableNames=${codeTableNames}`)
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }
}
