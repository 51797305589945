<main role="main" data-pgr-id="tleFeature">
  <div class="w-full">
    <div class="mt-xxs mx-xs relative">
      <div class="flex flex-wrap justify-between">
        <div class="flex justify-between">
          <h1 class="cui-h1 mb-xxs" id="main-content-header" tabindex="-1">
            Feature
          </h1>
        </div>
      </div>
      <div class="mb-s overflow-auto relative p-0 grid-row-2 flex flex-col">
        <cla-feature-table
          *ngIf="features$ | async as features"
          [features]="features"
          (featureRevSelected)="onFeatureSelected($event)"
        >
        </cla-feature-table>
      </div>
    </div>
  </div>
</main>
