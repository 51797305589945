import { Pipe, PipeTransform } from "@angular/core";
import { DateTime } from "luxon";

@Pipe({
  name: "claTimeSince",
})
export class TimeSincePipe implements PipeTransform {
  transform(
    startDate: Date | string | undefined,
    endDate?: Date | string
  ): string {
    if (startDate) {
      return this.getTimeSince(
        typeof startDate === "string"
          ? DateTime.fromISO(startDate)
          : DateTime.fromJSDate(startDate),
        endDate
          ? typeof endDate === "string"
            ? DateTime.fromISO(endDate)
            : DateTime.fromJSDate(endDate)
          : DateTime.now()
      );
    }
    return "";
  }

  public getTimeSince(startDate: DateTime, endDate: DateTime): string {
    const sinceReport = endDate.diff(startDate, ["days", "hours", "minutes"]);
    const daysSinceReport = sinceReport.days;
    const hoursSinceReport = sinceReport.hours;
    const minutesSinceReport = sinceReport.minutes;
    const timeSinceReport = `${
      hoursSinceReport < 10 ? "0" : ""
    }${hoursSinceReport}:${
      minutesSinceReport < 10 ? "0" : ""
    }${minutesSinceReport}`;

    return daysSinceReport === 0 ? timeSinceReport : `${daysSinceReport} days`;
  }
}
