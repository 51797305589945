<div class="mt-xs flex p-bl-1 mb-xxs">
  <div class="w-100">
    <mat-card
      class="p-0 overflow-x-auto table w-full policy-tab-card"
      role="region"
      aria-labelledby="policyDrivers"
    >
      <div
        [hidden]="
          !tableDataService.dataSource ||
          !tableDataService.dataSource.data ||
          tableDataService.dataSource.data.length === 0
        "
      >
        <table
          mat-table
          multiTemplateDataRows
          class="cui-table"
          [dataSource]="tableDataService.dataSource"
        >
          <ng-container matColumnDef="driverName">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-1">
              Driver Name
            </th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-1"
            >
              {{ getFormattedDriverName(driver) | titlecase | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="relation">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-2">
              Relation
            </th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-2"
            >
              {{ driver.relation | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-3">
              Date of Birth
            </th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-3"
            >
              {{ driver.dateOfBirth | date: "MM/dd/yyyy" | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-4">Gender</th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-4"
            >
              {{ driver.gender | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="licenseNumber">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-5">
              License No.
            </th>
            <td
              mat-cell
              *matCellDef="let driver; let row"
              class="align-top pt-xxs mat-cell-5"
            >
              <div class="flex justify-between" style="align-items: center">
                <div id="dln-aria">
                  <div
                    *ngIf="
                      selection.isSelected(row.policyPosition) &&
                        isDlnLoading &&
                        !driver.driversLicenseNumber;
                      else dlnLoaded
                    "
                    class="ghost-line w-10"
                    data-pgr-id="dln-ghost-loader"
                  ></div>
                  <ng-template #dlnLoaded>
                    <div
                      *ngIf="
                        selection.isSelected(row.policyPosition);
                        else hiddenDLN
                      "
                      data-pgr-id="dln-masked"
                    >
                      <span style="word-break: break-all">{{
                        driver.driversLicenseNumber | claValueOrNA
                      }}</span>
                      <span
                        *ngIf="
                          selection.isSelected(row.policyPosition) &&
                          !driver.driversLicenseNumber
                        "
                        aria-label="No license info"
                      ></span>
                    </div>
                    <ng-template #hiddenDLN>
                      <span data-pgr-id="dln-placeholder">
                        **************
                      </span>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="licenseState">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-7">
              License State
            </th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-7"
            >
              {{ driver.driversLicenseStateCode | titlecase | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-8">Status</th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-8"
            >
              {{ driver.driverStatus | claValueOrNA }}
            </td>
          </ng-container>
          <ng-container matColumnDef="filing">
            <th mat-header-cell *matHeaderCellDef class="mat-cell-9">Filing</th>
            <td
              mat-cell
              *matCellDef="let driver"
              class="align-top pt-xxs mat-cell-9"
            >
              {{ driver.financialResponsibilityFiling | claValueOrNA }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator #pager hidePageSize showFirstLastButtons pageSize="25"
          >pager.getNumberOfPages()</mat-paginator
        >
      </div>
    </mat-card>
  </div>
</div>
