<div [formGroup]="formGroup">
  <div>
    <mat-form-field appearance="outline" class="w-1/3 pr-xs">
      <mat-label>Claim Number</mat-label>
      <input matInput formControlName="claimNumber" required />
      <mat-error
        data-pgr-id="matErrorInvalidClaimNumber"
        *ngIf="formGroup.get('claimNumber')?.hasError('required')"
      >
        This field is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-1/3 pr-xs">
      <mat-label>Assign to</mat-label>
      <input matInput formControlName="diaryAssignTo" required />
      <mat-error
        data-pgr-id="matErrorInvalidDiaryAssignTo"
        *ngIf="formGroup.get('diaryAssignTo')?.hasError('required')"
      >
        This field is required.
      </mat-error>
      <mat-error
        data-pgr-id="matErrorInactiveRepDiaryAssignTo"
        *ngIf="formGroup.get('diaryAssignTo')?.hasError('inactiveRep')"
      >
        This rep is inactive.
      </mat-error>
      <mat-error
        data-pgr-id="matErrorInvalidRepDiaryAssignTo"
        *ngIf="formGroup.get('diaryAssignTo')?.hasError('invalidRep')"
      >
        This is not a valid rep.
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-1/3">
      <mat-label>Due date</mat-label>
      <input
        matInput
        placeholder="MM/DD/YYYY"
        [matDatepicker]="minDatePicker"
        formControlName="diaryDueDate"
        [min]="minDate"
        #dateInput
        required
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="minDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #minDatePicker></mat-datepicker>
      <mat-error
        *ngIf="formGroup.get('diaryDueDate')?.hasError('matDatepickerMin')"
      >
        Please enter a date on or after today.
      </mat-error>
      <mat-error
        *ngIf="
          formGroup.get('diaryDueDate')?.hasError('required') &&
          !formGroup.get('diaryDueDate')?.valid &&
          !!dateInput.value
        "
      >
        Please enter a valid date.
      </mat-error>
      <mat-error
        data-pgr-id="matErrorInvalidDiaryAssignTo"
        *ngIf="
          formGroup.get('diaryDueDate')?.hasError('required') &&
          !dateInput.value
        "
      >
        This field is required.
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Description</mat-label>
      <input
        matInput
        formControlName="diaryDescription"
        maxlength="30"
        required
      />
      <mat-hint
        >{{ formGroup.get("diaryDescription")?.value?.length || 0 }} / 30
        maximum characters</mat-hint
      >
      <mat-error
        data-pgr-id="matErrorInvalidDiaryDescription"
        *ngIf="formGroup.get('diaryDescription')?.hasError('required')"
      >
        This field is required.
      </mat-error>
      <mat-error
        data-pgr-id="matErrorInvalidFormatDiaryDescription"
        *ngIf="
          formGroup.get('diaryDescription')?.hasError('maxlength') ||
          formGroup.get('diaryDescription')?.errors?.pattern
        "
      >
        Description format is invalid.
      </mat-error>
    </mat-form-field>
  </div>
</div>
