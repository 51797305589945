import { Component, OnInit } from "@angular/core";
import { distinctUntilKeyChanged, filter } from "rxjs/operators";

import { ActivatedRoute, Router } from "@angular/router";
import { EFFConstants } from "@modules/electronic-file-folder/constants";
import { IContentSummaryItem } from "@modules/electronic-file-folder/models";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SideSheetService } from "@pgr-cla/core-ui-components";

@UntilDestroy()
@Component({
  selector: "cla-preview-pane",
  templateUrl: "preview-pane.component.html",
  styleUrls: ["./preview-pane.component.scss"],
})
export class PreviewPaneComponent implements OnInit {
  public previewedItem: IContentSummaryItem;

  get isContentTypePackage(): boolean {
    return this.previewedItem.contentType === EFFConstants.packageDocumentType;
  }

  constructor(
    public contentSummaryStoreService: ContentSummaryStoreService,
    public sideSheetService: SideSheetService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public handleClose(): void {
    this.router.navigate(["../"], {
      queryParamsHandling: "preserve",
      relativeTo: this.route,
    });

    const sideSheet = this.sideSheetService.get("EFFMain");

    if (sideSheet) {
      sideSheet.close();
    }

    this.contentSummaryStoreService.onContentSummaryPreviewItem({
      contentItem: {} as IContentSummaryItem,
    });
  }
  public ngOnInit(): void {
    this.contentSummaryStoreService
      .getPreviewedItem()
      .pipe(
        filter((x: IContentSummaryItem) => x !== undefined),
        distinctUntilKeyChanged("tags"),
        untilDestroyed(this)
      )
      .subscribe((contentItem) => {
        this.previewedItem = contentItem as IContentSummaryItem;
      });
  }
}
