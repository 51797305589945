<div class="flex flex-col">
  <mat-card
    appearance="outlined"
    class="thumbnail-wrapper flex flex-col justify-center items-center"
  >
    <mat-spinner
      *ngIf="thumbnailLoading"
      data-pgr-id="docset-thumbnail-spinner"
    >
    </mat-spinner>
    <img
      *ngIf="thumbnailImage && thumbnailLoading === false"
      class="thumbnail-image"
      [src]="thumbnailImage"
      data-pgr-id="docset-thumbnail-image"
      alt="Uploaded document thumbnail"
      tabindex="0"
    />
    <mat-icon
      *ngIf="thumbnailIcon && thumbnailLoading === false"
      class="thumbnail-icon"
      data-pgr-id="docset-thumbnail-icon"
      aria-label="doc set thumbnail icon"
      aria-hidden="false"
      tabindex="0"
    >
      {{ thumbnailIcon }}
    </mat-icon>
  </mat-card>
</div>
