import { createSelector } from "@ngrx/store";

import * as sharedSelectors from "./shared.selectors";

export const selectHistory = createSelector(
  sharedSelectors.selectFeatureRevisionState,
  (state) => state.history
);

export const selectFeatureHistory = createSelector(
  selectHistory,
  (state) => state.filteredRevisionHistory
);

export const selectFeatureHistoryLoading = createSelector(
  selectHistory,
  (state) => state.getHistoryInFlight
);

export const selectGetHistoryError = createSelector(
  selectHistory,
  (state) => state.hasGetHistoryError
);

export const selectFilterableColumns = createSelector(selectHistory, (state) =>
  state.columns.filter((column) => column.isFilterable)
);

export const selectFeatureHistoryFilters = createSelector(
  selectHistory,
  (state) => state.filters
);
