import { Component, Input } from "@angular/core";

@Component({
  selector: "cla-inline-alert",
  templateUrl: "./inline-alert.component.html",
  styleUrls: ["./inline-alert.component.scss"],
})
export class InlineAlertComponent {
  @Input() public alertType: "success" | "info" | "warning" | "error";
  @Input() public backgroundFill = false;

  public get iconName(): string {
    switch (this.alertType) {
      case "success":
        return "check_circle";
      case "warning":
        return "warning";
      case "error":
        return "error";
      default:
      case "info":
        return "info";
    }
  }

  public get classList(): string[] {
    const classes = this.backgroundFill ? ["colored-bg"] : [];
    return [...classes, this.alertType];
  }
}
