import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterStateService } from "@core/state/router";
import {
  ClaimDetails,
  PartyDetail,
  PartyPropertyDetail,
} from "@modules/core/models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable, combineLatest } from "rxjs";
import { filter, map } from "rxjs/operators";
import { ClaimDetailsStateService } from "../../state/claim-details/claim-details-state.service";

@UntilDestroy()
@Injectable()
export class PageTitleService {
  activePartyProperty$: Observable<
    PartyPropertyDetail | PartyDetail | undefined
  >;

  public title$: Observable<string>;

  constructor(
    private routerStateService: RouterStateService,
    private claimNumberPipe: ClaimNumberPipe,
    private claimDetailsStateService: ClaimDetailsStateService,
    private titleService: Title
  ) {}

  init(): void {
    this.activePartyProperty$ = combineLatest([
      this.routerStateService.routeParams$,
      this.claimDetailsStateService.partyPropertyDetails$,
    ]).pipe(
      map(([param, partyPropertyTree]) => {
        if (param?.partyId) {
          return partyPropertyTree
            ?.find((prop: PartyPropertyDetail) =>
              prop?.associatedPartyProperties?.find(
                (party: PartyDetail) => party.id == param?.partyId
              )
            )
            ?.associatedPartyProperties.find(
              (party: PartyDetail) => party.id == param?.partyId
            );
        }
        if (param?.propertyId) {
          return partyPropertyTree?.find(
            (prop: PartyPropertyDetail) => prop?.id == param?.propertyId
          );
        }
      })
    );

    this.title$ = combineLatest([
      this.routerStateService?.routeData$,
      this.routerStateService?.routeParams$,
      this.claimDetailsStateService?.claimDetails$,
      this.activePartyProperty$,
    ]).pipe(
      map(([routerData, routerParams, claimDetails, activePPD]) => {
        if (routerData?.useTitleService) {
          let title = this.FormatNameInsured(claimDetails);
          title += this.FormatClaimNumber(routerParams?.claimNumber);
          title += this.useTitleOrProperty(routerData, activePPD);
          return title;
        } else {
          return routerData?.title;
        }
      }),
      filter((title) => !!title)
    ) as Observable<string>;
    this.title$
      .pipe(untilDestroyed(this))
      .subscribe((title: string) => this.titleService.setTitle(title));
  }

  private FormatFixedProp(
    activePPD: PartyPropertyDetail | PartyDetail | undefined
  ) {
    if (activePPD?.displayName) return ` | ${activePPD?.displayName}`;
    return "";
  }

  private FormatVehicle(
    activePPD: PartyPropertyDetail | PartyDetail | undefined
  ) {
    const twoDigitYearVehicle = activePPD?.displayName?.substr(2);
    if (twoDigitYearVehicle) return ` | ${twoDigitYearVehicle}`;
    return "";
  }

  private FormatParty(activePPD: PartyDetail | undefined) {
    const displayLastname = activePPD?.displayLastName;
    const relationships = activePPD?.relations?.join("");

    if (displayLastname && relationships)
      return ` | ${displayLastname} (${relationships})`;
    return "";
  }

  private useTitleOrProperty(
    routerData: {
      [key: string]: boolean | string;
    },
    activePPD: PartyPropertyDetail | PartyDetail | undefined
  ) {
    if (
      routerData?.showPropertyInTitle &&
      (activePPD as PartyPropertyDetail)?.propertyType === "vehicle"
    )
      return this.FormatVehicle(activePPD);
    if (
      routerData?.showPropertyInTitle &&
      (activePPD as PartyPropertyDetail)?.propertyType === "fixedprop"
    )
      return this.FormatFixedProp(activePPD);
    if (routerData?.showPartyNameInTitle) return this.FormatParty(activePPD);
    return this.FormatTitle(routerData?.title as string);
  }

  private FormatTitle(title: string) {
    if (title) return ` | ${title}`;
  }

  private FormatNameInsured(claimDetails: ClaimDetails | undefined): string {
    if (claimDetails) {
      if (claimDetails?.namedInsured?.partyLastName) {
        return `${claimDetails.namedInsured.partyLastName} | `;
      }
      if (claimDetails?.namedInsured?.businessName) {
        return `${claimDetails.namedInsured.businessName} | `;
      } else return "No Policy | ";
    }
    return "";
  }

  private FormatClaimNumber(claimNumber: string): string {
    if (claimNumber) return this.claimNumberPipe.transform(claimNumber) || "";
    return "";
  }
}
