export class OpenValidationConstants {
  public static get validation(): { [key: string]: any } {
    return {
      invalidOpenTypes: [
        "Content Package",
        "Photo Estimate Invitation",
        "Photo Estimate Invitation - Service Center",
        "Content Sharing Invitation",
        "Content Submission Invitation",
      ],
      invalidStatuses: ["Failed", "Cancelled", "Draft"],
    };
  }
}
