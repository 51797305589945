import {
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { ClaimDetails, ClaimSummary } from "@modules/core";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";
import { Dictionary } from "@ngrx/entity";
import {
  ClaimRepairShopContactCardInfo,
  CodeDescriptionPair,
} from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";
import { FilterCriteria, Journal, Note } from "../models";

export const allowedCategoryCodes: string = "DG";

export const categories: CodeDescriptionPair[] = [
  { code: "DG", description: "Damages" },
  { code: "TD", description: "To Do" },
];

export const claimDetails: ClaimDetails = {
  claimId: 40308574,
  claimNumber: "20214000036",
  claimOwningRepCode: "QAU8007",
  claimLossDate: "2020-06-12T00:00:00-07:00",
  claimLossTimeZone: "PT",
  claimReportDate: "2021-01-11T13:07:00-05:00",
  claimReportTimeZone: "ET",
  claimOpenDate: "2021-01-11T00:00:00",
  claimCloseDate: "0001-01-01T00:00:00",
  factsOfLoss1: "ACCIDENT | PARKED CROOKED | INSURED OPENED DOOR INTO CV",
  factsOfLoss2: "",
  accidentLocation: "",
  accidentLocationCityName: "",
  accidentLocationCountyName: "",
  originatingOrg: "30070",
  pendIngTransferOrg: "",
  policyNumber: "16471434",
  policyRenewalSuffixNumber: "",
  dualLossIndicator: null,
  dualLossClaimCentury: "20",
  dualLossClaimYear: "20",
  dualLossClaimNumber: "7300817",
  isWorkmansCompClaim: false,
  isArchived: false,
  isClaimRestricted: false,
  isSiuInvolved: false,
  isSubrogationOpen: true,
  isSalvageOpen: true,
  isLitigationOpen: true,
  isVerified: true,
  isDualLoss: true,
  isPartyBusiness: true,
  namedInsured: {
    partyId: 141088912,
    partyBusinessIndicator: null,
    partyRecordSequenceNumber: "2",
    partyLastName: "",
    partyFirstName: "",
    partyMiddleInitial: "",
    partyLastNameSuffix: "",
    businessName: "UBER TECH INCP23",
  },
  claimStatus: {
    name: "4",
    description: "OPEN",
  },
  claimReportedBy: {
    name: "I",
    description: "INSURED",
  },
  accidentLocationState: {
    name: "CA",
    description: "CALIFORNIA",
    extenders: {
      code: "04",
    },
  },
  policyState: {
    name: "CA",
    description: "CALIFORNIA",
    extenders: {
      code: "04",
    },
  },
  accidentLocationCountry: {
    name: "USA",
    description: "UNITED STATES OF AMERICA",
  },
  businessDivision: {
    name: "A",
    description: "AGENCY",
  },
  progCompany: {
    name: "18",
    description: "BLUE HILL SPECIALTY INS CO",
  },
  productCategory: {
    name: "CA",
    description: "COMMERCIAL AUTO",
  },
  claimInvestigativeStatus: {
    name: "C",
    description: "",
  },
  surchargeCode: "",
  incidentLocationCode: "",
  incidentLocationCodeText: "",
  productClassCode: "  ",
  fsgProduct: "",
  isNonAuto: false,
  claimReportedVia: "TNCLV",
  lossLocationDisplayLine1: "",
  lossLocationDisplayLine2: "",
  lossLocationDisplayLine3: "",
  lossLocationDisplayLine4: "",
};

export const claimSummary: ClaimSummary = {
  fileOwnerCode: "X012857",
  insured: "John Default",
  insuredShortName: "J. Default",
  lossDate: new Date("2022-10-01T09:35:09.000-04:00"),
  reportDate: new Date("2022-10-02T09:35:09.000-04:00"),
};

export const claimNumber: string = "20214000036";

export const error: Error = new Error("mock error");

export const filterCriteria: FilterCriteria = {
  categoryCode: "C",
  authoredRepCode: "X012857",
  createdDateRange: {
    start: DateTime.now().toISO(),
    end: DateTime.now().toISO(),
  },
  searchTerm: "search term",
};

export const httpErrorResponse: HttpErrorResponse = {
  error: {},
  headers: {} as unknown as HttpHeaders,
  message:
    "Http failure response for http://localhost:3003/lossviewerapi/v1/rep-summaries?codes=QAU8007: 502 Bad Gateway",
  name: "HttpErrorResponse",
  ok: false,
  status: 502,
  statusText: "Bad Gateway",
  type: HttpEventType.Response,
  url: "http://localhost:3003/lossviewerapi/v1/rep-summaries?codes=QAU8007",
};

export const noteId: string = "633ed99046339c1f8d785196";

export const note: Note = {
  id: noteId,
  text: "NEW FIXED PROPERTY ADDED TO CLAIM: STACYS MAILBOX",
  access: {
    isReadonly: true,
    isRestricted: false,
    restrictionReason: "",
  },
  category: categories[0],
  authoredUserId: "X012857",
  createdUserId: "SYSTEM",
  lastUpdatedUserId: "ZAPIG01Q",
  createdDate: "2022-10-01T09:35:09.000-04:00",
  lastUpdatedDate: "2022-10-01T09:35:12.000-04:00",
};

export const note2: Note = {
  ...note,
  id: "630cf52ba915dfcc81ffcb25",
  category: categories[1],
  authoredUserId: "1010101",
  createdDate: "2022-10-02T09:36:09.000-04:00",
};

export const notes: Note[] = [note, note2];

export const filteredNotes: Note[] = [note];

export const toDoNotes: Note[] = [note2];

export const journal: Journal = {
  ...note,
  id: "633ed99046339c1f8d785177",
  subCategoryDescription: "Subcategory Description",
  createdDate: "2022-10-03T09:37:09.000-04:00",
};

export const journal2: Journal = {
  ...journal,
  id: "633ed99046339c1f8d785188",
  createdDate: "2022-10-04T09:38:09.000-04:00",
};

export const journals: Journal[] = [journal, journal2];

export const notesAndJournals: Note[] = [...notes, ...journals];

export const repairShopSummary: ClaimRepairShopContactCardInfo = {
  code: "1010101",
  name: "Acme Repairs",
  address: {
    streetAddress1: "1 Acme Rd",
    streetAddress2: "",
    city: "Toontown",
    stateCode: "VA",
    zipCode: "12345",
    countryCode: "US",
  },
};

export const repairShopSummaries: Dictionary<ClaimRepairShopContactCardInfo> = {
  "1010101": repairShopSummary,
};

export const repSummary: ClaimRepContactCardInfoRedacted = {
  code: "C",
  name: "Joe Default",
  shortName: "Joe",
  manager: undefined,
};

export const repSummaries: Record<string, ClaimRepContactCardInfoRedacted> = {
  X012857: repSummary,
  X012858: { ...repSummary, name: "Rob Default", shortName: "Rob" },
};
