import { HttpErrorResponse } from "@angular/common/http";
import { User } from "@modules/core/models";
import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import * as userActions from "./user.actions";

export interface UserState {
  user: User | undefined;
  isLoading: boolean;
  error: HttpErrorResponse | undefined;
}

export const initialState: UserState = {
  user: undefined,
  isLoading: false,
  error: undefined,
};

const userReducer: ActionReducer<UserState, Action> = createReducer(
  initialState,
  on(
    userActions.getCurrentUser,
    (state: UserState) => ({ ...state, isLoading: true } as UserState)
  ),
  on(
    userActions.getCurrentUserSuccess,
    (state: UserState, { user }) =>
      ({ ...state, user, isLoading: false } as UserState)
  ),
  on(
    userActions.getCurrentUserError,
    (state: UserState, { error }) =>
      ({ ...state, error, isLoading: false } as UserState)
  ),
  on(
    userActions.getCurrentUserRepAuthoritySuccess,
    (state: UserState, { physicalDamageAuthorityAmount }) =>
      ({
        ...state,
        user: {
          ...state.user,
          physicalDamageAuthorityAmount: physicalDamageAuthorityAmount,
        },
      } as UserState)
  ),
  on(
    userActions.getCurrentUserRepAuthorityError,
    (state: UserState, { error }) =>
      ({
        ...state,
        user: {
          ...state.user,
          physicalDamageAuthorityAmount: 0,
        },
      } as UserState)
  )
);

export function reducer(state: UserState, action: Action): UserState {
  return userReducer(state, action);
}
