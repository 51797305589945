<div *ngIf="adverseParties$ | async as adverseParties; else loading">
  <div *ngIf="adverseParties.length === 0">
    <h2 class="text-center" data-pgr-id="noAdversePartiesMessage">
      Subrogation has not been opened on an adverse party.
    </h2>
  </div>
  <div *ngIf="adverseParties.length > 0" data-pgr-id="adversePartiesList">
    <div
      class="
        mat-elevation-z2
        mb-xs
        p-xs
        adverse-party-card
        overflow-auto
        card-border-radius
      "
      *ngFor="
        let party of adverseParties;
        trackBy: trackBySubroObligationId;
        let i = index
      "
    >
      <cla-adverse-party-header
        [claimNumber]="claimNumber"
        [adverseParty]="party"
        [detailCodes]="subrogationSummaryCodes$ | async"
        [partyNumber]="i + 1"
        [totalNumberOfParties]="adverseParties.length"
      >
      </cla-adverse-party-header>
      <cla-adverse-party-details
        [adverseParty]="party"
        [detailCodes]="subrogationSummaryCodes$ | async"
        [claimNumber]="claimNumber"
      ></cla-adverse-party-details>
    </div>
  </div>
</div>
<ng-template #loading>
  <h2
    class="text-center"
    *ngIf="showError"
    data-pgr-id="AdversePartiesErrorMessage"
  >
    An error has occured while retrieving adverse parties.
  </h2>
</ng-template>
