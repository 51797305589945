import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppPathService } from "@modules/core";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { ClaimDetailsStateService } from "@modules/core/state";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WindowManagementService } from "@pgr-cla/cla-window";
import {
  EMPTY,
  Observable,
  from,
  map,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs";
import * as claimLandingActions from "../actions";

@Injectable()
export class ClaimsLandingEffects {
  actionItemRouteEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimLandingActions.actionItemNavigation),
        withLatestFrom(this.claimDetailsStateService.claimNumber$),
        map(([{ event }, claimNumber]) => {
          let url = "";
          let partyPropertyDocumentsPath$: Observable<string> | undefined;
          switch (event.messageType) {
            case "ShowCorrespondenceIssue":
              url = `${claimNumber}/claim-summary`;
              partyPropertyDocumentsPath$ =
                this.appPathService.contentSummaryPath$;
              break;
            case "ShowActiveInvoiceIssue":
              url = `${claimNumber}/invoice/${event.id}?startPage=issues`;
              break;
            case "ShowClaimsText":
              url = `${claimNumber}/claim-text?partyId=${event.partyNumber}`;
              break;
            case "ShowInvoiceHistory":
              url = `${claimNumber}/invoice/${event.id}?startPage=history`;
              break;
            default:
              url = `${claimNumber}/claim-summary`;
              break;
          }

          if (!url) return;

          const navPromise = this.router.navigateByUrl(url);

          if (!partyPropertyDocumentsPath$) return;

          from(navPromise)
            .pipe(
              take(1),
              switchMap((navigated) => {
                if (navigated && partyPropertyDocumentsPath$) {
                  return partyPropertyDocumentsPath$.pipe(take(1));
                }
                return EMPTY;
              })
            )
            .subscribe((url) =>
              this.windowManagementService.openChildWindow(
                "content-summary",
                url
              )
            );
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private claimDetailsStateService: ClaimDetailsStateService,
    private router: Router,
    private appPathService: AppPathService,
    private windowManagementService: WindowManagementService,
    public coreUiExtensionsStateService: CoreUiExtensionsStateService
  ) {}
}
