import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { LegacyAppSessionState } from "./legacy-app-session.type";

@Injectable()
export class LegacyAppSessionService {
  private readonly sessionKeyName: string = "legacyAppSessionInformation";
  private legacyAppSessionStateSubject$: Subject<
    LegacyAppSessionState | undefined
  > = new BehaviorSubject(undefined);

  public sessionId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.sessionId));

  public liabilityProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.liabilityProcessId));

  public paymentsProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.paymentsProcessId));

  public injurySummaryProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.injurySummaryProcessId));

  public medicalProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.medicalProcessId));

  public actionItemsProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.actionItemsProcessId));

  public claimTextProcessId$: Observable<string | undefined> =
    this.legacyAppSessionStateSubject$
      .asObservable()
      .pipe(map((s: LegacyAppSessionState) => s?.claimTextProcessId));

  constructor() {
    this.checkForExistingSessions();
    this.loadExistingSession();
  }

  public sessionExists(): boolean {
    return !!sessionStorage.getItem(this.sessionKeyName);
  }

  public createSession(): void {
    const newLegacyAppSessionState: LegacyAppSessionState = {
      sessionId: uuidv4(),
      liabilityProcessId: uuidv4(),
      paymentsProcessId: uuidv4(),
      injurySummaryProcessId: uuidv4(),
      medicalProcessId: uuidv4(),
      actionItemsProcessId: uuidv4(),
      claimTextProcessId: uuidv4(),
    };
    sessionStorage.setItem(
      this.sessionKeyName,
      JSON.stringify(newLegacyAppSessionState)
    );
  }

  public loadExistingSession(): void {
    const localsessioninfo = sessionStorage.getItem(
      this.sessionKeyName
    ) as string;
    const parsedSessionInfo: LegacyAppSessionState =
      JSON.parse(localsessioninfo);
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.actionItemsProcessId = uuidv4();
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.paymentsProcessId = uuidv4();
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.claimTextProcessId = uuidv4();
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.injurySummaryProcessId = uuidv4();
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.medicalProcessId = uuidv4();
    // eslint-disable-next-line functional/immutable-data
    parsedSessionInfo.liabilityProcessId = uuidv4();
    this.legacyAppSessionStateSubject$.next(parsedSessionInfo);
  }

  public checkForExistingSessions(): void {
    if (!this.sessionExists()) {
      this.createSession();
    }
  }
}
