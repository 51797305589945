import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { ClaimAlert } from "@pgr-cla/core-ui-components";
import { AppState } from "@store";
import { Observable } from "rxjs";
import { onAllClaimAlertsViewed, onClaimAlertsViewed } from "./alerts.actions";
import { selectAlerts, selectNewAlerts } from "./alerts.selectors";

@Injectable()
export class AlertsStateService {
  constructor(private store$: Store<AppState>) {}

  alerts$: Observable<ClaimAlert[]> = this.store$.select(selectAlerts);
  newAlerts$: Observable<ClaimAlert[]> = this.store$.select(selectNewAlerts);

  dispatch(action: Action): void {
    this.store$.dispatch(action);
  }

  setAlertsAsViewed(pgrTransactionIds: string[]): void {
    this.store$.dispatch(onClaimAlertsViewed({ pgrTransactionIds }));
  }

  setAllAlertsAsViewed(): void {
    this.store$.dispatch(onAllClaimAlertsViewed());
  }
}
