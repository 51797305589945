import { Injectable } from "@angular/core";
import { ExpenseTypeCode, RepAuthority } from "@claimssummaryshared/models";
import { User } from "@modules/core/models/user.interface";
import { PaymentsService } from "@modules/core/services/payments";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, take } from "rxjs/operators";

import * as userActions from "./user.actions";

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUser),
      map(() => {
        const user: User = {
          repCode: "",
          orgCode: "",
          isPipAuthorized: false,
          isManager: false,
          physicalDamageAuthorityAmount: 0,
        };
        let actions: Action[];
        return userActions.getCurrentUserSuccess({ user });
      })
    )
  );

  getRepAuthority$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUserRepAuthority),
      mergeMap(() => {
        return this.paymentsService
          .getRepAuthority(ExpenseTypeCode.PhysicalDamage)
          .pipe(
            take(1),
            map((data: RepAuthority) => {
              let physicalDamageAuthorityAmount = 0;
              if (data.expenseTypeCode === ExpenseTypeCode.PhysicalDamage) {
                physicalDamageAuthorityAmount = data.paymentAuthorityAmount;
              }
              return userActions.getCurrentUserRepAuthoritySuccess({
                physicalDamageAuthorityAmount,
              });
            }),
            catchError((error) =>
              of(userActions.getCurrentUserRepAuthorityError({ error }))
            )
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private paymentsService: PaymentsService
  ) {}
}
