export interface CVQ {
  id: number;
  level: string;
  notes: Note[];
  help: string[];
  impacts: string;
  status: string;
  description: string;
  creationTypeText: string;
  verification?: VerificationStatus;
  guidance: string[];
  ruleLinks: Links[];
}

export interface Links {
  link: string;
  displayName: string;
}

export interface Note {
  date: Date;
  category: string;
  authorId: string;
  authorName: string;
  noteText: string[];
  dateTimeZone: string;
  eventType: CVQStatus;
}

export enum VerificationStatus {
  Pending = "Pending",
  Complete = "Complete",
  NotRequired = "Not Required",
}

export enum CVQStatus {
  Unresolved = "Unresolved",
  Resolved = "Resolved",
  Pending = "Pending",
}
