<h1 mat-dialog-title data-pgr-id="simpleDialog">{{ data.title }}</h1>
<div *ngIf="hasMessage()" mat-dialog-content>
  <p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions align="end" class="mt-s">
  <button
    *ngIf="data.confirm"
    data-pgr-id="btnDialogConfirm"
    mat-flat-button
    color="primary"
    class="dialog-confirm"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ data.confirm }}
  </button>
  <button
    *ngIf="data.cancel"
    class="ml-xxs"
    data-pgr-id="btnDialogCancel"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="false"
  >
    {{ data.cancel }}
  </button>
</div>
