import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";

import {
  AdverseParty,
  SubrogationSummaryCodes,
} from "@subrogation-summary/models";

import { MatDialog } from "@angular/material/dialog";
import { PartyNamePipe } from "@modules/subrogation-summary/pipes/";
import { loadRecoveryHistory } from "@modules/subrogation-summary/state/actions/recovery-history.actions";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { SubrogationSummaryState } from "@subrogation-summary/state/reducers";
import { RecoveryHistoryModalComponent } from "../recovery-history-modal";

@Component({
  selector: "cla-adverse-party-header",
  templateUrl: "./adverse-party-header.component.html",
  styleUrls: ["./adverse-party-header.component.scss"],
})
export class AdversePartyHeaderComponent implements OnDestroy, OnInit {
  @Input() public claimNumber: string;
  @Input() public partyNumber: number;
  @Input() public totalNumberOfParties: number;
  @Input() public adverseParty: AdverseParty;
  @Input() public detailCodes: SubrogationSummaryCodes;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store<SubrogationSummaryState>,
    public dialog: MatDialog,
    private partyNamePipe: PartyNamePipe
  ) {}

  ngOnInit() {
    this.store$.dispatch(
      loadRecoveryHistory({
        subroObligationId: this.adverseParty.subroObligationId,
        partySequenceNumber: this.adverseParty.partySequenceNumber,
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  openRecoveryHistoryModal() {
    this.dialog.open(RecoveryHistoryModalComponent, {
      data: {
        subroObligationId: this.adverseParty.subroObligationId,
        partyName: this.partyNamePipe.transform(this.adverseParty),
      },
      width: CUI_DIALOG_WIDTH.LARGE,
    });
  }
}
