import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import * as subrogationSummaryActions from "./state/actions/subrogation-summary.actions";
import { SubrogationSummaryState } from "./state/reducers";

@Component({
  selector: "cla-subrogation-summary",
  templateUrl: "./subrogation-summary.component.html",
  styleUrls: ["./subrogation-summary.component.scss"],
})
export class SubrogationSummaryComponent {
  claimNumber: string;

  constructor(
    private store$: Store<SubrogationSummaryState>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.claimNumber = this.activatedRoute.snapshot.params["claimNumber"];
    this.store$.dispatch(
      subrogationSummaryActions.subrogationSummaryStart({
        claimNumber: this.claimNumber,
      })
    );
  }

  goToReferral() {
    this.router.navigate(["../referral"], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: "preserve",
    });
  }
}
