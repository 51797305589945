import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { filter } from "rxjs/operators";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { findIndex } from "lodash";

import { LiveAnnouncer } from "@angular/cdk/a11y";
import { UserContextService } from "@modules/core/services";
import { initialState } from "@modules/electronic-file-folder/constants";
import {
  A11yDragDirective,
  A11yDropListDirective,
} from "@modules/electronic-file-folder/directives/a11y-drop-list/a11y-drop-list.directive";
import { deepCopy } from "@modules/electronic-file-folder/helpers";
import {
  IContentSummaryColumn,
  IContentSummaryItem,
  IUserContext,
} from "@modules/electronic-file-folder/models";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";

@UntilDestroy()
@Component({
  selector: "cla-columns-pane",
  templateUrl: "columns-pane.component.html",
  styleUrls: ["./columns-pane.component.scss"],
})
export class ColumnsPaneComponent implements OnInit {
  @ViewChildren("a11yColumn", {
    read: A11yDragDirective,
  })
  public a11yColumns: QueryList<A11yDragDirective>;
  @ViewChild("a11yList", {
    read: A11yDropListDirective,
  })
  public a11yDropList: A11yDropListDirective;
  public a11yModeActive = false;
  public columns: IContentSummaryColumn[] = [];
  public columnsClone: IContentSummaryColumn[] = [];
  public columnsHaveChanged: boolean;
  public settingsId: string;
  public userId: string;

  constructor(
    private contentSummaryStoreService: ContentSummaryStoreService,
    private userContextService: UserContextService,
    public contentSummaryViewService: ContentSummaryViewService,
    public sideSheetService: SideSheetService,
    private announcer: LiveAnnouncer
  ) {}

  public handleClose(): void {
    const sideSheet = this.sideSheetService.get("EFFMain");

    if (sideSheet) {
      sideSheet.close();
    }
  }

  public ngOnInit(): void {
    this.contentSummaryStoreService
      .getToggleableColumns()
      .subscribe((columns) => {
        this.columns = deepCopy(columns);
      })
      .unsubscribe();

    this.contentSummaryStoreService
      .getColumns()
      .pipe(untilDestroyed(this))
      .subscribe((columns) => {
        this.columnsClone = deepCopy(columns);
      });

    this.userContextService.userContextSource$
      .pipe(
        filter((x: IUserContext) => {
          return this.userContextService.hasValidContext(x);
        }),
        untilDestroyed(this)
      )
      .subscribe((userContext: IUserContext) => {
        this.userId = userContext.profile.uid || userContext.profile.networkID;
      })
      .unsubscribe();

    this.contentSummaryStoreService.onContentSummaryPreviewItem({
      contentItem: {} as IContentSummaryItem,
    });
  }

  public onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.contentSummaryStoreService.onReorderColumns({
      previousIndex: event.previousIndex + 1,
      currentIndex: event.currentIndex + 1,
    }); // to account for checkbox column
    this.columnsHaveChanged = true;
  }

  public onReorder(event: CdkDragDrop<string[]>): void {
    this.columnsHaveChanged = true;
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  public onToggle(id: string): void {
    const sanitizedColumnsClone = this.columnsClone.filter((x) => {
      const result = x.id !== "isSelected";

      return result;
    });
    const oldIndex = findIndex(
      sanitizedColumnsClone,
      (x: IContentSummaryColumn) => {
        return x.id === id;
      }
    );
    const newIndex = findIndex(this.columns, (x: IContentSummaryColumn) => {
      return x.id === id;
    });

    this.contentSummaryStoreService.onReorderColumns({
      previousIndex: oldIndex + 1,
      currentIndex: newIndex + 1,
    }); // to account for checkbox column
  }

  public resetColumns(): void {
    const columns = deepCopy(initialState.columns).filter(
      (column) => column.isToggleable
    );
    this.contentSummaryStoreService.onResetColumns();

    this.contentSummaryViewService.isPartyPropertyView$
      .subscribe((isPartyPropertyView: boolean) => {
        this.columns = isPartyPropertyView
          ? columns.filter((column: any) => column.title !== "Party/Property")
          : columns;
      })
      .unsubscribe();

    this.columnsHaveChanged = true;
    this.a11yDropList.activeItem = undefined;
    this.announcer.announce("Columns reset. All columns shown.");
  }

  public toggleColumn(column: string): void {
    this.contentSummaryStoreService.onToggleColumn({ column });
    this.columnsHaveChanged = true;
  }
}
