import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { ClaimFeature } from "@modules/subrogation-summary/models";
import { SubrogationSummaryService } from "@modules/subrogation-summary/services";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as claimFeatureActions from "../actions/claim-feature.actions";

@Injectable()
export class ClaimFeatureEffects {
  constructor(
    private action$: Actions,
    private subrogationSummaryService: SubrogationSummaryService,
    private loggingService: LoggingService
  ) {}

  // Currently, this effect will never dispatch a loadClaimFeaturesFailed action. This is because
  // getClaimFeatures is not implementing the redux pattern at this time.
  loadClaimFeaturesOnLoadClaimFeatures$ = createEffect(() =>
    this.action$.pipe(
      ofType(claimFeatureActions.loadClaimFeatures),
      switchMap((originalAction) =>
        this.subrogationSummaryService
          .getClaimFeature(originalAction.claimNumber)
          .pipe(
            map((claimFeatures: ClaimFeature[]) =>
              claimFeatureActions.loadClaimFeaturesSucceeded({ claimFeatures })
            ),
            catchError((error) => {
              this.loggingService.log(error, LoggedMessageLevel.Error);
              return of(
                claimFeatureActions.loadClaimFeaturesFailed({
                  errorMsg: error.message,
                })
              );
            })
          )
      )
    )
  );
}
