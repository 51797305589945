import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import {
  TileChip,
  tileChipConstants,
  TileChipTypeCode,
} from "@claimssummaryshared/models/claims-landing/tilechip.interface";
import { FeatureTableData } from "@modules/claims-summary/models/feature-table-data.interface";
import { IpcWindowService } from "@modules/claims-summary/services";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { RouterStateService } from "@modules/core/state/router";
import { EmbeddedParameter } from "@modules/shared";
import { take } from "rxjs";

const maxItemsWhenCollapsed = 5;

@Component({
  selector: "cla-features-tile",
  templateUrl: "features-tile.component.html",
  styleUrls: ["./features-tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturesTileComponent {
  claimNumber: string;
  constructor(
    private router: Router,
    private routerStateService: RouterStateService,
    public coreUiStateService: CoreUiExtensionsStateService,
    public ipcWindowService: IpcWindowService
  ) {
    this.routerStateService.routeParams$
      .pipe(take(1))
      .subscribe((routeParams) => {
        if (routeParams && routeParams.claimNumber) {
          this.claimNumber = routeParams.claimNumber;
        }
      });
  }
  STATUS_INDICATORS: { [key: number]: string } = {
    0: "inactive",
    1: "warn",
    2: "warn",
    3: "warn",
    4: "active",
    5: "inactive",
    6: "alert",
    7: "inactive",
    8: "active",
  };

  displayedColumns: string[] = [
    "featureSequenceNumber",
    "feature",
    "status",
    "featureOwner",
    "featureAssist",
    "reserve",
    "paid",
    "orgCode",
    "spacer2",
  ];

  features: FeatureTableData[];
  dataSource: MatTableDataSource<FeatureTableData>;
  pageSize = maxItemsWhenCollapsed;
  expanded = false;
  @Input() set featuresTableData(featuresTableData: FeatureTableData[]) {
    this.features = featuresTableData;
    this.dataSource = new MatTableDataSource(featuresTableData);
    this.dataSource.paginator = this.paginator;
  }
  @Input() isLoading: boolean;
  @Input() featuresError: HttpErrorResponse | undefined;
  @Output() fetchFeaturesData: EventEmitter<void> = new EventEmitter();
  @ViewChild("tableContainer", { static: false }) tableContainer: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;

  public ghostRows = Array(3);

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.paginator._changePageSize(Number.MAX_SAFE_INTEGER);
    } else {
      this.paginator._changePageSize(maxItemsWhenCollapsed);
    }
  }

  getChipURL(tileChip: TileChip): string | undefined {
    switch (tileChip.type) {
      case TileChipTypeCode.CVQ: {
        return `../../coverage`;
      }
      case TileChipTypeCode.StatedAmount: {
        return `../../coverage`;
      }
      case TileChipTypeCode.TotalLoss: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        const propertyVehOrFP =
          tileChip.parameters[tileChipConstants.propertyVehOrFP];
        return `../../property/${propertyVehOrFP}/${propertyid}/details`;
      }
      case TileChipTypeCode.Driveable: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        return `../../property/vehicle/${propertyid}/details`;
      }
      case TileChipTypeCode.NonDriveable: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        return `../../property/vehicle/${propertyid}/details`;
      }
      case TileChipTypeCode.InspectionPending: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        const propertyVehOrFP =
          tileChip.parameters[tileChipConstants.propertyVehOrFP];
        return `../../property/${propertyVehOrFP}/${propertyid}/details`;
      }
      case TileChipTypeCode.InspectionComplete: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        const propertyVehOrFP =
          tileChip.parameters[tileChipConstants.propertyVehOrFP];
        return `../../property/${propertyVehOrFP}/${propertyid}/details`;
      }
      case TileChipTypeCode.InspectionNone: {
        const propertyid =
          tileChip.parameters[tileChipConstants.propertyNumber];
        const propertyVehOrFP =
          tileChip.parameters[tileChipConstants.propertyVehOrFP];
        return `../../property/${propertyVehOrFP}/${propertyid}/details`;
      }
      case TileChipTypeCode.Litigation: {
        return `../../litigation`;
      }
      case TileChipTypeCode.Salvage: {
        return `../../salvage/details`;
      }
      case TileChipTypeCode.Subrogation: {
        return `../../subrogation-referral`;
      }
      case TileChipTypeCode.SIU: {
        return `../../siu`;
      }
      default: {
        break;
      }
    }
    return "";
  }

  isIpcChip(tileChip: TileChip) {
    return tileChip.type === TileChipTypeCode.IPC;
  }

  getChipState(tileChip: TileChip): any {
    if (tileChip.type === TileChipTypeCode.Salvage) {
      const salvageRefId = tileChip.parameters[tileChipConstants.salvageId];
      const salvagePropSeqNbr =
        tileChip.parameters[tileChipConstants.propertySeqNumber];
      const params = [
        {
          key: "propertyNumber",
          value: salvagePropSeqNbr,
        },
        {
          key: "salvageItemReferenceId",
          value: salvageRefId,
        },
      ] as EmbeddedParameter[];

      return { params: params };
    }
  }
  navigateToFeature() {
    this.router.navigate([`${this.claimNumber}/feature`]);
  }
}
