import { Component } from "@angular/core";
import { AttorneyService } from "../../services";

@Component({
  selector: "cla-delete-attorney-dialog",
  templateUrl: "./delete-attorney-dialog.component.html",
  styleUrls: ["./delete-attorney-dialog.component.scss"],
})
export class DeleteAttorneyDialogComponent {
  constructor(public readonly attorneyService: AttorneyService) {}
}
