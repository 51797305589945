import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ClaimGuardService } from "../claim-guard";
import { ClaimStatusGuardService } from "../claim-status-guard";

@Injectable()
export class CombinedClaimGuard implements CanActivate {
  constructor(
    private claimGuard: ClaimGuardService,
    private claimStatusGuard: ClaimStatusGuardService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.claimGuard.canActivate(route).pipe(
      mergeMap((firstGuardSuccessful: boolean) => {
        if (firstGuardSuccessful) {
          return this.claimStatusGuard.canActivate(route);
        }
        return of(this.router.parseUrl("/"));
      })
    );
  }
}
