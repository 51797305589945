<div class="adverse-party-header flex flex-wrap justify-between">
  <h2 class="header-title">
    <span class="party-count"
      >Adverse Party {{ partyNumber }} of {{ totalNumberOfParties }}</span
    >
    {{ adverseParty | claPartyName }}
  </h2>
  <div class="header-buttons">
    <button
      mat-stroked-button
      color="primary"
      data-pgr-id="recoveryHistoryLink"
      (click)="openRecoveryHistoryModal()"
    >
      Recovery History
    </button>
  </div>
  <p class="w-full party-liab">
    Adverse Party Liability - {{ adverseParty.liabilityPercentage }}%
  </p>
</div>
