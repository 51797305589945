export class TableRowSelectHandlerComponent {
  longPressHandlerWasSet: boolean;
  longPressHandler: any;
  displayedColumns: string[] = [];
  private sortHeaderAccessorMap: { [key: string]: string } = {};

  setLongPressHandler() {
    // need this for JAWS + IE (JAWS changes keydown enter to click event with no mousedown)
    this.longPressHandlerWasSet = true;
    this.longPressHandler = setTimeout(
      this.clearLongPressHandler.bind(this),
      500
    );
  }

  clearLongPressHandler() {
    this.longPressHandler = null;
  }

  ignoreEvent(event: any) {
    // for use when event shouldn't trigger select but should be allowed to propagate
    // eslint-disable-next-line functional/immutable-data
    event.ignore = true;
  }

  selectIfNotLongPress(event: any, row: any) {
    if (this.longPressHandler || !this.longPressHandlerWasSet) {
      this.longPressHandlerWasSet = false;
      this.selectIfNotIgnored(event, row);
    }
  }

  selectIfNotIgnored(event: any, row: any): void {
    if (!event.ignore) {
      this.onSelect(row);
    }
  }

  onSelect(row: any): void {
    return row;
  }

  setDisplayedColumns(columns: string[]) {
    this.displayedColumns = columns;
  }

  setSortingAccessorMap(map: { [key: string]: string }) {
    this.sortHeaderAccessorMap = map;
  }

  sortingAccessor(data: any, sortHeaderId: string): any {
    return data[this.sortHeaderAccessorMap[sortHeaderId]];
  }
}
