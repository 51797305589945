import { RouterState } from "@modules/core/state/router/router-state.type";
import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";

export const selectRouterState = createFeatureSelector<RouterState>("router");

export const selectRouteUrl = createSelector(
  selectRouterState,
  (state) => state && state?.state?.url
);

export const selectRouteParams = createSelector(
  selectRouterState,
  (state) => state && state?.state?.params
);

export const selectRouteQueryParams = createSelector(
  selectRouterState,
  (state) => state && state?.state?.queryParams
);

export const selectRouteData = createSelector(
  selectRouterState,
  (state) => state && state?.state?.data
);

export const selectRouteParam = (
  id: string
): MemoizedSelector<object, string, DefaultProjectorFn<string>> =>
  createSelector(selectRouteParams, (params) =>
    params ? params[id] : undefined
  );

export const selectRouteQueryParam = (
  id: string
): MemoizedSelector<object, string, DefaultProjectorFn<string>> =>
  createSelector(selectRouteQueryParams, (queryParams) =>
    queryParams ? queryParams[id] : undefined
  );

export const selectRouteDataItem = (
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
  createSelector(selectRouteData, (data) => (data ? data[id] : undefined));
