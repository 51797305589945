import { HttpErrorResponse } from "@angular/common/http";
import { User } from "@modules/core/models";
import { createAction, props } from "@ngrx/store";

export const getCurrentUser = createAction("[User] Get Current User");

export const getCurrentUserSuccess = createAction(
  "[User] Get Current User Success",
  props<{ user: User }>()
);

export const getCurrentUserError = createAction(
  "[User] Get Current User Error",
  props<{ error: HttpErrorResponse }>()
);

export const getCurrentUserRepAuthority = createAction(
  "[User] Get Current User Rep Authority"
);

export const getCurrentUserRepAuthoritySuccess = createAction(
  "[User] Get Current User Rep Authority Success",
  props<{ physicalDamageAuthorityAmount: number }>()
);

export const getCurrentUserRepAuthorityError = createAction(
  "[User] Get Current User Rep Authority Error",
  props<{ error: HttpErrorResponse }>()
);
