/* eslint-disable @typescript-eslint/typedef */
//import { DatePipe } from '@angular/common';
import { Injectable } from "@angular/core";
import { ClaimInfoDto } from "@modules/shared/helpers/dto/claim-info-dto";
import { Actions, createEffect, ofType } from "@ngrx/effects";
//import { ResourceConstant } from '@modules/feature-revision/helpers/resource.constant';
import { PolicyResourceConstant } from "@modules/policy/helpers";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { CodeTableCode } from "@modules/shared/models/code-table-code.interface";
import { CodeTable } from "@modules/shared/models/code-table.interface";
import { coreSharedActions, NotificationType } from "@modules/shared/redux";
import { ClaimDetailsService } from "@modules/shared/services/claim-details";
import { Store } from "@ngrx/store";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { AppState } from "@store";
import { forkJoin, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { PolicyHelperFunctions } from "../../helpers";
import { POLICY_VEHICLE_TYPE } from "../../helpers/policy-helper.constant";
import { ClaimPolicy } from "../../models/claim-policy";
import { PolicyLoss, VehicleLoss } from "../../models/loss-history";
import { PolicyConfirm } from "../../models/policy-confirm";
import { PolicyDriver } from "../../models/policy-driver";
import { PolicyEndorsement } from "../../models/policy-endorsement.interface";
import { PolicyVehicle } from "../../models/policy-vehicle";
import { ProductCode } from "../../models/productcode-description";
import { StateCode } from "../../models/statecode-description";
import { AgentService } from "../../services/agent.services";
import { PolicyService } from "../../services/policy.services";
import { PropertyService } from "../../services/property.service";
import * as policyActions from "./policy.actions";
import { selectClaimInfo, selectClaimPolicy } from "./policy.selectors";

@Injectable()
export class PolicyEffects {
  getClaimPolicyWithPreReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getClaimPolicyWithPreReq),
      switchMap((action) => {
        let claimNumber = "";
        // eslint-disable-next-line functional/no-try-statements
        try {
          claimNumber =
            this.claimNumberPipe.transform(action.claimNumber, "full") || "";
        } catch (error) {
          return of(policyActions.policyErrorOccurred({ error }));
        }
        return forkJoin([
          this.claimDetailsService.getClaimInfo(claimNumber!).pipe(
            map((claimInfoData) => {
              return ClaimInfoDto.toClaimInfo(claimInfoData, claimNumber!);
            })
          ),
          this.policyService.getClaimPolicy(claimNumber),
        ]).pipe(
          switchMap(([claimInfoRes, claimPolicyRes]) => {
            return forkJoin([
              of(claimInfoRes),
              of(claimPolicyRes),
              !claimPolicyRes.isPolicyAttached
                ? this.policyService.getStateCodeTable()
                : of(null),
              !claimPolicyRes.isPolicyAttached
                ? this.policyService.getProductCodeTable()
                : of(null),
              this.policyService.getEndorsementCodeTable(),
            ]).pipe(
              map(
                ([
                  claimInfo,
                  claimPolicy,
                  stateCodeTableInfo,
                  productCodeTableInfo,
                  endorsementCodeTable,
                ]: [
                  ClaimInfo,
                  ClaimPolicy,
                  CodeTable<StateCode>[],
                  CodeTable<ProductCode>[],
                  CodeTable<CodeTableCode>[]
                ]) => {
                  return policyActions.getClaimPolicyWithPreReqSuccess({
                    claimInfo,
                    claimPolicy,
                    stateCodeTableInfo,
                    productCodeTableInfo,
                    endorsementCodeTable,
                  });
                }
              )
            );
          }),
          catchError((error) => {
            return of(policyActions.policyErrorOccurred({ error }));
          })
        );
      })
    )
  );

  getPolicyDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyDetails),
      mergeMap((action) => {
        return (
          action.isPolicyAttached
            ? this.policyService.getPolicyDetail(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss
              )
            : of(null)
        ).pipe(
          map((policyDetailList) => {
            const policyDetail = policyDetailList ? policyDetailList[0] : null;
            return policyActions.getPolicyDetailsSuccess({
              policyDetail: policyDetail,
            });
          }),
          catchError((error) => {
            return of(policyActions.policyErrorOccurred({ error }));
          })
        );
      })
    )
  );

  getClaimLossVehiclesWithPreReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getClaimLossVehiclesWithPreReq),
      switchMap((action) => {
        let claimNumber = "";
        // eslint-disable-next-line functional/no-try-statements
        try {
          claimNumber =
            this.claimNumberPipe.transform(action.claimNumber, "full") || "";
        } catch (error) {
          return of(policyActions.policyErrorOccurred({ error }));
        }

        return this.propertyService.getClaimVehicles(claimNumber).pipe(
          map((vehicles) => {
            return policyActions.getClaimLossVehiclesWithPreReqSuccess({
              vehicles,
            });
          }),
          catchError((error) => {
            return of(policyActions.policyErrorOccurred({ error }));
          })
        );
      })
    )
  );

  getClaimPolicyVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getClaimPolicyVehicles),
      switchMap((action) => {
        switch (action.productCode) {
          case POLICY_VEHICLE_TYPE.AUTO: {
            return this.policyService
              .getPolicyVehicleAuto(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.COMMERCIAL_AUTO: {
            return this.policyService
              .getPolicyVehicleCommercial(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MOTORCYCLE: {
            return this.policyService
              .getPolicyVehicleMotorcycle(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.BOAT: {
            return this.policyService
              .getPolicyVehicleBoat(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MOTOR_HOME: {
            return this.policyService
              .getPolicyVehicleMotorHome(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.TRAVEL_TRAILER: {
            return this.policyService
              .getPolicyVehicleTravelTrailer(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MANUFACTURED_HOME: {
            return this.policyService
              .getPolicyVehicleManufacturedHome(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MANUAL: {
            return this.policyService
              .getPolicyVehicleManual(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getClaimPolicyVehiclesSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          default: {
            return of(policyActions.unsupportedVehicleType());
          }
        }
      })
    )
  );

  getPolicyUrlResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyUrlResponse),
      switchMap((action) => {
        return this.policyService
          .getPolicyUrlResponse(action.policyNumber, action.policyRenewalNumber)
          .pipe(
            map((policyUrlResponse) => {
              return policyActions.getPolicyUrlResponseSuccess({
                policyUrlResponse,
              });
            }),
            catchError((error) => {
              return of(policyActions.policyErrorOccurred({ error }));
            })
          );
      })
    )
  );

  getAgentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getAgentInfo),
      mergeMap((action) => {
        return this.agentService.getAgentInfo(action.agentNumber).pipe(
          map((agentInfo) => {
            return policyActions.getAgentInfoSuccess({ agentInfo });
          }),
          catchError((error) => {
            return of(policyActions.policyErrorOccurred({ error }));
          })
        );
      })
    )
  );

  getPolicyDrivers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyDrivers),
      mergeMap((action) => {
        return this.policyService
          .getPolicyDrivers(
            action.policyNumber,
            action.renewalSuffixNumber,
            action.dateOfLoss,
            action.productCode
          )
          .pipe(
            map((policyDrivers) => {
              return policyActions.getPolicyDriversSuccess({
                policyDrivers: policyDrivers as PolicyDriver[],
              });
            }),
            catchError((error) => {
              return of(policyActions.policyErrorOccurred({ error }));
            })
          );
      })
    )
  );

  getPolicyLosses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyLosses),
      switchMap((action) => {
        return this.policyService.getPolicyLosses(action.policyNumber).pipe(
          map((policyLosses) => {
            return policyActions.getPolicyLossesSuccess({
              policyLosses: policyLosses as PolicyLoss[],
            });
          }),
          catchError(() => {
            return of(
              policyActions.getPolicyLossesFail({ policyLosses: null })
            );
          })
        );
      })
    )
  );

  //TODO: remove policyNumber
  getVehicleLosses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getVehicleLosses),
      switchMap((action) => {
        return this.policyService
          .getVehicleLosses(action.policyNumber, action.vins)
          .pipe(
            map((vehicleLosses) => {
              return policyActions.getVehicleLossesSuccess({
                vehicleLosses: vehicleLosses as VehicleLoss[],
              });
            }),
            catchError(() => {
              return of(
                policyActions.getVehicleLossesFail({ vehicleLosses: null })
              );
            })
          );
      })
    )
  );

  getPolicyDriversLicenseNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyDriversLicenseNumber),
      withLatestFrom(
        this.store$.select(selectClaimInfo),
        this.store$.select(selectClaimPolicy)
      ),
      concatMap(([action, claimInfo, claimPolicy]) => {
        return this.policyService
          .getDriverLicenseNumberForPolicyDriver(
            claimPolicy?.policyNumber,
            Number(claimPolicy?.renewalSuffixNumber),
            claimInfo?.dateOfLoss || null,
            action.policyPosition,
            claimInfo?.claimNumber
              ? this.claimNumberPipe.transform(claimInfo.claimNumber, "full") ||
                  ""
              : claimInfo?.claimNumber
          )
          .pipe(
            switchMap((driverLicense) => {
              return of(
                policyActions.getPolicyDriversLicenseNumberSuccess({
                  driverLicense: driverLicense
                    ? driverLicense.driverLicense
                    : "",
                  protectedDataAccessLevel: driverLicense
                    ? driverLicense.protectedDataAccessLevel
                    : "",
                  policyPosition: action.policyPosition,
                })
              );
            }),
            catchError((error) => {
              if (error.status === 403) {
                return of(
                  coreSharedActions.onNotificationReq({
                    message:
                      "You are not authorized to access this information.",
                    notificationType: NotificationType.ERROR,
                  })
                );
              } else {
                return of(
                  coreSharedActions.onNotificationReq({
                    message: PolicyResourceConstant.defaultErrorMessage,
                    notificationType: NotificationType.ERROR,
                  })
                );
              }
            })
          );
      })
    )
  );

  getPolicyConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.policyConfirm),
      switchMap((action) => {
        return this.policyService
          .getPolicyConfirm(
            action.policyNumber,
            action.policyNumberSuffix,
            action.dateOfLoss
          )
          .pipe(
            switchMap((response: PolicyConfirm) => {
              return of(
                policyActions.policyConfirmSuccess({ policyConfirm: response })
              );
            }),
            catchError(() => {
              return of(
                policyActions.policyConfirmError({
                  errorMessage: PolicyResourceConstant.defaultErrorMessage,
                })
              );
            })
          );
      })
    )
  );

  getPolicyAttachVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyAttachVehicles),
      switchMap((action) => {
        return this.policyService
          .getPolicyAttachVehicles(
            action.policyNumber,
            action.policyNumberSuffix,
            action.dateOfLoss!,
            action.policyStateCode
          )
          .pipe(
            switchMap((response: PolicyVehicle[]) => {
              return of(
                policyActions.getPolicyAttachVehiclesSuccess({
                  policyAttachVehicles: response,
                })
              );
            }),
            catchError((error) => {
              return of(policyActions.policyErrorOccurred({ error }));
            })
          );
      })
    )
  );

  getVehicleInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getVehicleInfo),
      switchMap((action) => {
        switch (action.productCode) {
          case POLICY_VEHICLE_TYPE.AUTO: {
            return this.policyService
              .getPolicyVehicleAuto(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.COMMERCIAL_AUTO: {
            return this.policyService
              .getPolicyVehicleCommercial(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MOTORCYCLE: {
            return this.policyService
              .getPolicyVehicleMotorcycle(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.BOAT: {
            return this.policyService
              .getPolicyVehicleBoat(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MOTOR_HOME: {
            return this.policyService
              .getPolicyVehicleMotorHome(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.TRAVEL_TRAILER: {
            return this.policyService
              .getPolicyVehicleTravelTrailer(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MANUFACTURED_HOME: {
            return this.policyService
              .getPolicyVehicleManufacturedHome(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          case POLICY_VEHICLE_TYPE.MANUAL: {
            return this.policyService
              .getPolicyVehicleManual(
                action.policyNumber,
                action.renewalSuffixNumber,
                action.dateOfLoss,
                action.positions
              )
              .pipe(
                map((vehicles) => {
                  return policyActions.getVehicleInfoSuccess({
                    policyVehicles: vehicles as PolicyVehicle[],
                  });
                }),
                catchError((error) => {
                  return of(policyActions.policyErrorOccurred({ error }));
                })
              );
          }
          default: {
            return of(policyActions.unsupportedVehicleType());
          }
        }
      })
    )
  );

  getPolicyEndorsements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.getPolicyEndorsements),
      mergeMap((action) => {
        return this.policyService
          .getPolicyEndorsements(action.policyNumber, action.dateOfLoss)
          .pipe(
            map((response: PolicyEndorsement[]) => {
              return policyActions.getPolicyEndorsementsSuccess({
                policyEndorsements: response,
              });
            }),
            catchError((error) => {
              return of(policyActions.policyErrorOccurred({ error }));
            })
          );
      })
    )
  );

  policyErrorOccurred$ = createEffect(() =>
    this.actions$.pipe(
      ofType(policyActions.policyErrorOccurred),
      map((action) => {
        return PolicyHelperFunctions.handleError(action.error);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private agentService: AgentService,
    private policyService: PolicyService,
    private propertyService: PropertyService,
    private claimDetailsService: ClaimDetailsService,
    private store$: Store<AppState>,
    private claimNumberPipe: ClaimNumberPipe
  ) {}
}
