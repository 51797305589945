import { Injectable } from "@angular/core";
import { AppState } from "@modules/feature-revision-history/models/core/app-state.interface";
import { Feature } from "@modules/feature-revision-history/models/feature-revision";
import { historySelectors } from "@modules/feature-revision-history/state/selectors";
import { select, Store } from "@ngrx/store";
import * as FeatureHistoryActions from "../../state/actions/history.actions";

@Injectable()
export class FeatureRevisionStoreService {
  public featureRevisionHistory$ = this.store$.pipe(
    select(historySelectors.selectFeatureHistory)
  );

  public filters$ = this.store$.pipe(
    select(historySelectors.selectFeatureHistoryFilters)
  );

  public filterableColumns$ = this.store$.pipe(
    select(historySelectors.selectFilterableColumns)
  );

  constructor(private store$: Store<AppState>) {}

  public getFeatureRevisionHistory(props: {
    claimNumber: string;
    features: Feature[];
  }): void {
    this.store$.dispatch(FeatureHistoryActions.getHistory(props));
  }

  public filterFeatureRevisionHistory(props: {
    columnId: string;
    filterText: string;
  }): void {
    this.store$.dispatch(FeatureHistoryActions.filterRevisionHistory(props));
  }

  public resetHistoryFilters(): void {
    this.store$.dispatch(FeatureHistoryActions.resetHistoryFilter());
  }

  public closeHistoryFilters(): void {
    this.store$.dispatch(FeatureHistoryActions.closeHistoryFilter());
  }

  public resetColumnFilters(props: { columnId: string }): void {
    this.store$.dispatch(FeatureHistoryActions.resetColumnFilters(props));
  }
}
