import { ClaimInfo } from "@modules/shared/models/claim-info";
import { Action, createReducer, on } from "@ngrx/store";

import { AgentInfo } from "../../models/agent-info";
import { ClaimPolicy } from "../../models/claim-policy";
import { LossVehicle } from "../../models/loss-vehicle";
import {
  PolicyConfirm,
  PolicyConfirmDriver,
  PolicyConfirmVehicle,
} from "../../models/policy-confirm";
import { PolicyDetail } from "../../models/policy-detail";
import { PolicyDriver } from "../../models/policy-driver";
import { PolicyEndorsement } from "../../models/policy-endorsement.interface";
import { PolicyVehicle } from "../../models/policy-vehicle";

import { CodeTableCode } from "@modules/shared/models/code-table-code.interface";
import { CodeTable } from "@modules/shared/models/code-table.interface";
import { PolicyLoss, VehicleLoss } from "../../models/loss-history";
import { PolicyUrlResponse } from "../../models/policy-url";
import { ProductCode } from "../../models/productcode-description";
import { StateCode } from "../../models/statecode-description";
import * as policyActions from "./policy.actions";

export interface State {
  policyUrlResponse: PolicyUrlResponse | null;
  lossVehicle: LossVehicle[];
  policyVehicle: PolicyVehicle[];
  selectedVehicleInfo: PolicyVehicle | null;
  policyAttachVehicle: PolicyVehicle[];
  claimInfo: ClaimInfo | null;
  claimPolicy: ClaimPolicy | null;
  policyDetail: PolicyDetail | null;
  agentInfo: AgentInfo | null;
  policyDrivers: PolicyDriver[];
  policyLosses: PolicyLoss[] | null;
  vehicleLosses: VehicleLoss[] | null;
  stateCodeTableInfo: CodeTable<StateCode>[] | null;
  productCodeTableInfo: CodeTable<ProductCode>[] | null;
  endorsementCodeTable: CodeTable<CodeTableCode>[] | null;
  policyConfirm: PolicyConfirm | null;
  policyConfirmDrivers: PolicyConfirmDriver[] | null;
  policyConfirmVehicles: PolicyConfirmVehicle[] | null;
  policyAttached: string | null;
  policyEndorsements: PolicyEndorsement[];
  errorMessage: string | null;
  isPolicyVehicleLoading: boolean;
  isEndorsementsLoading: boolean;
  isPolicyDriversLoading: boolean;
}

export const initialState: State = {
  policyUrlResponse: null,
  lossVehicle: [],
  policyVehicle: [],
  selectedVehicleInfo: null,
  policyAttachVehicle: [],
  claimInfo: null,
  claimPolicy: null,
  policyDetail: null,
  agentInfo: null,
  policyDrivers: [],
  policyLosses: [],
  vehicleLosses: [],
  stateCodeTableInfo: null,
  productCodeTableInfo: null,
  endorsementCodeTable: null,
  policyConfirm: null,
  policyConfirmDrivers: null,
  policyConfirmVehicles: null,
  policyAttached: null,
  policyEndorsements: [],
  errorMessage: null,
  isPolicyVehicleLoading: false,
  isEndorsementsLoading: false,
  isPolicyDriversLoading: false,
};

const policyReducer = createReducer(
  initialState,
  on(policyActions.getAgentInfoSuccess, (state, action) => ({
    ...state,
    agentInfo: action.agentInfo,
  })),
  on(policyActions.getPolicyUrlResponseSuccess, (state, action) => ({
    ...state,
    policyUrlResponse: action.policyUrlResponse,
  })),
  on(policyActions.getClaimPolicyWithPreReqSuccess, (state, action) => ({
    ...state,
    claimInfo: action.claimInfo,
    claimPolicy: action.claimPolicy,
    stateCodeTableInfo: action.stateCodeTableInfo,
    productCodeTableInfo: action.productCodeTableInfo,
    endorsementCodeTable: action.endorsementCodeTable,
  })),
  on(policyActions.getPolicyDetailsSuccess, (state, action) => ({
    ...state,
    policyDetail: action.policyDetail,
  })),
  on(policyActions.getClaimLossVehiclesWithPreReqSuccess, (state, action) => ({
    ...state,
    lossVehicle: action.vehicles,
  })),
  on(policyActions.getClaimPolicyVehiclesSuccess, (state, action) => ({
    ...state,
    policyVehicle: action.policyVehicles,
  })),
  on(policyActions.getPolicyDrivers, (state, action) => ({
    ...state,
    isPolicyDriversLoading: true,
  })),
  on(policyActions.getPolicyDriversSuccess, (state, action) => ({
    ...state,
    policyDrivers: action.policyDrivers,
    isPolicyDriversLoading: false,
  })),
  on(policyActions.getPolicyLossesSuccess, (state, action) => ({
    ...state,
    policyLosses: action.policyLosses,
  })),
  on(policyActions.getPolicyLossesFail, (state, action) => ({
    ...state,
    policyLosses: action.policyLosses,
  })),
  on(policyActions.getVehicleLossesSuccess, (state, action) => ({
    ...state,
    vehicleLosses: action.vehicleLosses,
  })),
  on(policyActions.getVehicleLossesFail, (state, action) => ({
    ...state,
    vehicleLosses: action.vehicleLosses,
  })),
  on(policyActions.getPolicyAttachVehicles, (state, action) => ({
    ...state,
    isPolicyVehicleLoading: true,
  })),
  on(policyActions.getPolicyAttachVehiclesSuccess, (state, action) => ({
    ...state,
    policyAttachVehicle: action.policyAttachVehicles,
    isPolicyVehicleLoading: false,
  })),
  on(policyActions.getVehicleInfoSuccess, (state, action) => ({
    ...state,
    selectedVehicleInfo: action.policyVehicles
      ? action.policyVehicles[0]
      : null,
  })),
  on(policyActions.clearVehicleInfo, (state) => ({
    ...state,
    selectedVehicleInfo: null,
  })),
  on(policyActions.clearPolicy, () => ({
    ...initialState,
  })),
  on(policyActions.policyConfirmSuccess, (state, action) => ({
    ...state,
    policyConfirm: action.policyConfirm ? action.policyConfirm : null,
    errorMessage: null,
  })),
  on(policyActions.policyConfirmError, (state, action) => ({
    ...state,
    errorMessage: action.errorMessage,
  })),
  on(policyActions.confirmationClear, (state) => ({
    ...state,
    policyConfirm: null,
    errorMessage: null,
  })),
  on(policyActions.getPolicyEndorsements, (state, action) => ({
    ...state,
    isEndorsementsLoading: true,
  })),
  on(
    policyActions.getPolicyEndorsementsSuccess,
    (state, { policyEndorsements }) => ({
      ...state,
      policyEndorsements,
      isEndorsementsLoading: false,
    })
  ),
  on(policyActions.policyErrorOccurred, (state) => ({
    ...state,
    isPolicyDriversLoading: false,
    isEndorsementsLoading: false,
    isPolicyVehicleLoading: false,
  })),
  on(policyActions.getPolicyDriversLicenseNumberSuccess, (state, action) => ({
    ...state,
    policyDrivers: state.policyDrivers.map((driver: PolicyDriver) => {
      if (
        driver.policyPosition.toString() === action.policyPosition?.toString()
      ) {
        if (action.driverLicense) {
          return {
            ...driver,
            driversLicenseNumber: action.driverLicense,
            protectedDataAccessLevel: action.protectedDataAccessLevel || "",
          };
        }
      }
      return driver;
    }),
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return policyReducer(state, action);
}
