import { Action, createReducer, on } from "@ngrx/store";
import { tassign } from "tassign";
import * as Util from "../../helpers/util";
import { deepCopy, getFilters } from "../../helpers/util";
import { FeatureHistory } from "../../models/feature-revision/feature-history";
import { historyActions } from "../actions";

export interface RevisionHistoryState {
  revisionHistory: FeatureHistory[] | [];
  filteredRevisionHistory: FeatureHistory[] | [];
  getHistoryInFlight: boolean;
  hasGetHistoryError: boolean;
  filters: { [key: string]: any };
  columns: any[];
}

export const initialState: RevisionHistoryState = {
  revisionHistory: [],
  filteredRevisionHistory: [],
  getHistoryInFlight: false,
  hasGetHistoryError: false,
  filters: {
    repCode: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    featureNumber: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    coverageShortDescription: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    partyProperty: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    changeReason: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
  },
  columns: [
    {
      id: "transactionDate",
      title: "Date & Time",
      visible: true,
      isToggleable: false,
      isFilterable: false,
    },
    {
      id: "repCode",
      title: "Updated By Rep",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "featureNumber",
      title: "Feature #",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "coverageShortDescription",
      title: "Line Coverage",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "partyProperty",
      title: "Party/Property",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "changeReason",
      title: "Change",
      visible: true,
      isToggleable: false,
      isFilterable: true,
    },
    {
      id: "valueBefore",
      title: "From",
      visible: true,
      isToggleable: false,
      isFilterable: false,
    },
    {
      id: "valueAfter",
      title: "To",
      visible: true,
      isToggleable: false,
      isFilterable: false,
    },
  ],
};

const historyReducer = createReducer(
  initialState,
  on(historyActions.getHistory, (state) => ({
    ...state,
    revisionHistory: [],
    getHistoryInFlight: true,
    hasGetHistoryError: false,
  })),

  on(historyActions.getHistorySuccess, (state, action) => {
    const updatedHistory: FeatureHistory[] =
      Util.HelperFunctions.setHistoryDisplayInfo(
        action.featureHistory,
        action.features
      );
    const filters = getFilters(state, updatedHistory);
    const orderedHistory = updatedHistory
      .slice()
      .sort((a, b) => (a.transactionDate > b.transactionDate ? 1 : -1));
    const newState: RevisionHistoryState = {
      ...state,
      revisionHistory: orderedHistory,
      filteredRevisionHistory: orderedHistory,
      filters,
      hasGetHistoryError: false,
    };

    return tassign(state, {
      ...newState,
    } as RevisionHistoryState);
  }),

  on(historyActions.getHistoryError, (state) => ({
    ...state,
    getHistoryInFlight: false,
    hasGetHistoryError: true,
  })),

  on(
    historyActions.filterRevisionHistory,
    (state, { columnId, filterText }) => {
      const appliedFilters = [...state.filters[columnId].appliedFilters];
      const toggledFilterIndex = appliedFilters.indexOf(filterText);

      if (toggledFilterIndex === -1) {
        // eslint-disable-next-line functional/immutable-data
        appliedFilters.push(filterText);
      } else {
        // eslint-disable-next-line functional/immutable-data
        appliedFilters.splice(toggledFilterIndex, 1);
      }

      return tassign(state, {
        ...state,
        filters: {
          ...state.filters,
          [columnId]: {
            ...state.filters[columnId],
            appliedFilters,
          },
        },
      } as RevisionHistoryState);
    }
  ),

  on(
    historyActions.applyFilters,
    (state, { filteredRevisionHistory, filters }) => ({
      ...state,
      filteredRevisionHistory,
      filters,
    })
  ),

  on(historyActions.resetHistoryFilter, (state) => {
    const filters = deepCopy(state.filters);
    Object.keys(filters).forEach((key: string) => {
      if (filters[key].appliedFilters) {
        // eslint-disable-next-line functional/immutable-data
        filters[key].appliedFilters = [];
      } else {
        // eslint-disable-next-line functional/immutable-data
        filters[key] = {};
      }
    });
    return tassign(state, {
      ...state,
      filteredRevisionHistory: state.revisionHistory,
      filters,
    });
  }),

  on(historyActions.resetColumnFilters, (state, { columnId }) => {
    return tassign(state, {
      ...state,
      filters: {
        ...state.filters,
        [columnId]: {
          ...state.filters[columnId],
          appliedFilters: [],
        },
      },
    });
  })
);

export function reducer(
  state: RevisionHistoryState | undefined,
  action: Action
) {
  return historyReducer(state, action);
}
