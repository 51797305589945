import { Injectable } from "@angular/core";
import { PropertyApiService } from "@modules/core/services/property-api";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { PolicyVehicle } from "../models/policy-vehicle";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  constructor(private propertyApiService: PropertyApiService) {}

  getClaimVehicles(claimNumber: string): Observable<PolicyVehicle[]> {
    return this.propertyApiService
      .get(`/lossviewerapi/v1/claims/${claimNumber}/vehicles`)
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      ) as Observable<PolicyVehicle[]>;
  }
}
