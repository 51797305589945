import { Pipe, PipeTransform } from "@angular/core";
import {
  PaymentEventType,
  PhysicalDamageAuthorityEvent,
} from "@modules/core/models";

@Pipe({
  name: "claPhysicalDamageAuthorityEventAuthor",
})
export class PhysicalDamageAuthorityEventAuthorPipe implements PipeTransform {
  public transform(value: PhysicalDamageAuthorityEvent): string {
    if (value.paymentEventTypeCode === PaymentEventType.Request) {
      return value.requestedRepCode;
    }
    return value.authorityRepCode;
  }
}
