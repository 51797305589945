<main
  role="main"
  data-pgr-id="tleClaimSummary"
  class="px-xs pb-xs pt-xxs h-full w-full"
>
  <div class="flex justify-between">
    <h1
      class="cui-h1 mb-xxs"
      id="main-content-header"
      tabindex="-1"
      data-pgr-id="lblClaimSummary"
    >
      Claim Summary
    </h1>
  </div>
  <div class="features-container">
    <cla-features-tile
      [featuresTableData]="claimSummaryStateService.featuresTableData$ | async"
      [featuresError]="claimSummaryStateService.featuresError$ | async"
      [isLoading]="claimSummaryStateService.isFeaturesRequestInFlight$ | async"
      (fetchFeaturesData)="claimSummaryStateService.onFeaturesRequest()"
    >
    </cla-features-tile>
  </div>
  <div class="coverage-and-liability-container">
    <cla-coverages-tile
      [coverages]="claimSummaryStateService.coverages$ | async"
      [coveragesError]="claimSummaryStateService.coveragesError$ | async"
      [isLoading]="claimSummaryStateService.isCoveragesRequestInFlight$ | async"
      (fetchCoverageData)="claimSummaryStateService.onCoveragesRequest()"
    >
    </cla-coverages-tile>
    <cla-liability-tile
      class="h-full"
      [liabilityTableData]="
        (claimSummaryStateService.liability$ | async)?.parties
      "
      [liabilityError]="claimSummaryStateService.liabilityError$ | async"
      [isLoading]="claimSummaryStateService.isLiabilityRequestInFlight$ | async"
      [liabilityStatus]="(claimSummaryStateService.liability$ | async)?.status"
      (fetchLiabilityData)="claimSummaryStateService.onLiabilityRequest()"
    ></cla-liability-tile>
  </div>
</main>
