import { HttpErrorResponse } from "@angular/common/http";
import {
  CoverageDetailInfo,
  FeatureDetail,
  LiabilityTile,
} from "@claimssummaryshared/models";
import { FeatureTableData } from "@modules/claims-summary/models/feature-table-data.interface";
import { createAction, props } from "@ngrx/store";

export const onFeaturesRequest = createAction("[Claim Summary] Get Features");

export const onFeaturesReceipt = createAction(
  "[Claim Summary] Get Features Success",
  props<{ readonly payload: FeatureDetail[] }>()
);

export const setFeatureMappedTableData = createAction(
  "[Claim Summary] Set Feature Mapped Table Data",
  props<{ featuresTableData: FeatureTableData[] }>()
);

export const onFeaturesError = createAction(
  "[Claim Summary] Get Features Error",
  props<{ readonly error: HttpErrorResponse | undefined }>()
);

export const onCoveragesRequest = createAction("[Claim Summary] Get Coverages");

export const onCoveragesReceipt = createAction(
  "[Claim Summary] Get Coverages Success",
  props<{ readonly payload: CoverageDetailInfo }>()
);

export const onCoveragesError = createAction(
  "[Claim Summary] Get Coverages Error",
  props<{ readonly error: HttpErrorResponse | undefined }>()
);

export const onLiabilityRequest = createAction("[Claim Summary] Get Liability");

export const onLiabilityReceipt = createAction(
  "[Claim Summary] Get Liability Success",
  props<{ readonly payload: LiabilityTile }>()
);

export const onLiabilityError = createAction(
  "[Claim Summary] Get Liability Error",
  props<{ readonly error: HttpErrorResponse | undefined }>()
);
