import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Column, Filter, paymentsInitialState } from "@modules/payments/models";
import { FilterType } from "@modules/payments/models/filter-type";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentsStateService } from "../../services";

@Component({
  selector: "cla-payments-filter",
  templateUrl: "./payments-filter.component.html",
  styleUrls: ["./payments-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentsFilterComponent implements OnInit {
  public paymentsFilters$: Observable<any>;

  public numberRangeMin: { [key: string]: FormControl<string | null> } = {};
  public numberRangeMax: { [key: string]: FormControl<string | null> } = {};

  constructor(
    private elementRef: ElementRef,
    private paymentsStateService: PaymentsStateService
  ) {}

  ngOnInit(): void {
    this.paymentsFilters$ = combineLatest([
      this.paymentsStateService.filters$,
      this.paymentsStateService.filterableColumns$,
    ]).pipe(map(([filters, columns]: any) => ({ filters, columns })));

    this.resetFilters();
    paymentsInitialState.columns
      .map((column: Column) => column.id)
      .forEach((columnId: string) => {
        if (
          paymentsInitialState.filters[columnId].filterType ===
          FilterType.NumberRange
        ) {
          this.numberRangeMin[columnId] = new FormControl("");
          this.numberRangeMax[columnId] = new FormControl("");
        }
      });
  }

  closeFilter() {
    this.paymentsStateService.closePaymentFilters();
  }

  resetFilters() {
    this.paymentsStateService.resetPaymentFilters();
    const dom: HTMLElement = this.elementRef.nativeElement;
    const inputs: NodeListOf<HTMLInputElement> = dom.querySelectorAll("input");
    inputs.forEach((input: HTMLInputElement) => {
      // eslint-disable-next-line functional/immutable-data
      input.value = "";
    });
  }

  resetColumnFilters(columnId: string): void {
    this.paymentsStateService.resetColumnFilters({
      columnId,
    });
  }

  public getFilterCount(filterState: { appliedFilters: string[] }): string {
    let result: number = 0;
    if (filterState.appliedFilters) {
      result = filterState.appliedFilters.length;
    }

    return result.toString();
  }

  public filterCountIsHidden(filter: Filter): boolean {
    let result: boolean = true;
    if (
      filter.appliedFilters &&
      filter.appliedFilters.length > 0 &&
      filter.filterType === FilterType.Select
    ) {
      result = false;
    }

    return result;
  }

  public onToggleFilter(columnId: string, filterOption: any): void {
    this.paymentsStateService.filterPayments({
      columnId,
      filterText: filterOption.filterText,
    });
  }

  public updateMinNumberRange(event: Event, columnId: string) {
    if (this.numberRangeMin[columnId].valid) {
      this.paymentsStateService.filterPaymentsNumberRange({
        columnId,
        minText: this.numberRangeMin[columnId].value?.toString() ?? "",
        maxText: undefined,
      });
    }
  }

  public updateMaxNumberRange(event: Event, columnId: string) {
    if (this.numberRangeMax[columnId].valid) {
      this.paymentsStateService.filterPaymentsNumberRange({
        columnId,
        minText: undefined,
        maxText: this.numberRangeMax[columnId].value?.toString() ?? "",
      });
    }
  }

  public showFilterPanel = (filter: any): boolean => filter.filterOptions;

  public trackById = (index: number, column: any): string => column.id;

  public trackByText = (index: number, filterOption: any): string =>
    filterOption.filterDisplayText;
}
