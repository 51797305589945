import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";

import * as outOfPocketActions from "../actions/out-of-pockets.actions";
import * as subrogationSummaryCodesActions from "../actions/subrogation-summary-codes.actions";
import * as subrogationSummaryActions from "../actions/subrogation-summary.actions";
import * as claimFeatureActions from "../actions/claim-feature.actions";

@Injectable()
export class SubrogationSummaryEffects {
  constructor(private action$: Actions) {}

  loadOutOfPocketsOnSummaryStart$ = createEffect(() =>
    this.action$.pipe(
      ofType(subrogationSummaryActions.subrogationSummaryStart),
      map((action) =>
        outOfPocketActions.loadOutOfPockets({ claimNumber: action.claimNumber })
      )
    )
  );

  loadSummaryCodesOnSummaryStart$ = createEffect(() =>
    this.action$.pipe(
      ofType(subrogationSummaryActions.subrogationSummaryStart),
      map((action) =>
        subrogationSummaryCodesActions.loadSubrogationSummaryCodes({
          claimNumber: action.claimNumber,
        })
      )
    )
  );

  loadClaimPartiesOnSummaryStart$ = createEffect(() =>
    this.action$.pipe(
      ofType(subrogationSummaryActions.subrogationSummaryStart),
      map((action) =>
        claimFeatureActions.loadClaimFeatures({
          claimNumber: action.claimNumber,
        })
      )
    )
  );
}
