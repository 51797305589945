import { Component, Input } from "@angular/core";

@Component({
  selector: "cla-table-header-label",
  templateUrl: "./table-header-label.component.html",
  styleUrls: ["./table-header-label.component.scss"],
})
export class TableHeaderLabelComponent {
  @Input() public filterActive = false;
  @Input() public headerName: string;
}
