import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { first } from "rxjs/operators";

import { AppContext, UserContext } from "../../models";
import { UserContextService } from "../user-context";

@Injectable({ providedIn: "root" })
export class AppContextService {
  public context$: Observable<AppContext>;
  private contextSubject$: BehaviorSubject<AppContext>;

  constructor(private userContextService: UserContextService) {
    this.contextSubject$ = new BehaviorSubject({} as AppContext);
    this.context$ = this.contextSubject$.asObservable();
    this.mapUserProfile();
  }

  mapUserProfile(): void {
    this.userContextService.userContextSource$
      .pipe(
        first(
          (context: UserContext) =>
            context !== null &&
            context !== undefined &&
            context.profile !== undefined
        )
      )
      .subscribe((context: UserContext) => {
        this.contextSubject$.next({
          user: {
            networkID: context.profile.networkID,
            uid: context.profile.uid,
            fullName: context.profile.fullName,
            title: context.profile.title,
            permissions: context.permissions.map((p) => p.name).join(","),
          },
        });
      });
  }
}
