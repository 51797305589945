<h2 mat-dialog-title data-pgr-id="confirm-dialog-title">{{ data.title }}</h2>
<cla-server-error-banner
  *ngIf="data.error$ | async"
  data-pgr-id="confirm-dialog-error"
  [serverError]="data.error$ | async"
>
</cla-server-error-banner>
<div class="relative" [class.mt-s]="data.error$ | async">
  <cui-spinner
    *ngIf="data.isLoading$ | async"
    data-pgr-id="confirm-dialog-spinner"
  ></cui-spinner>
  <div
    mat-dialog-content
    data-pgr-id="confirm-dialog-message"
    [innerHTML]="data.message"
  ></div>
  <div mat-dialog-actions align="end" class="mt-s">
    <button
      mat-flat-button
      #submitButton
      color="primary"
      class="mr-xxs dialog-confirm"
      data-pgr-id="btn-dialog-continue"
      [attr.aria-label]="data.confirmButtonText"
      [disabled]="data.isLoading$ | async"
      (click)="continuePressed.emit()"
    >
      {{ data.confirmButtonText }}
    </button>
    <button
      mat-stroked-button
      #cancelButton
      color="primary"
      data-pgr-id="btn-dialog-cancel"
      [attr.aria-label]="data.cancelButtonText"
      [disabled]="data.isLoading$ | async"
      (click)="cancelPressed.emit()"
    >
      {{ data.cancelButtonText }}
    </button>
  </div>
</div>
