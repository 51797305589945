import { Injectable } from "@angular/core";
import { AppContextService } from "@modules/core/services/app-context";
import { ClaimsSummaryContextService } from "@modules/core/services/claims-summary-context";
import { createPageViewEvent, EventModel } from "@pgr-cla/cla-analytics";

@Injectable()
export class ClaimSummaryAnalyticsEventService {
  constructor(
    private _appContextService: AppContextService,
    private _claimsSummaryContextService: ClaimsSummaryContextService
  ) {}

  public vehicleDetailsPageView = (): EventModel =>
    createPageViewEvent("Vehicle Details Page View", [
      this._appContextService.context$,
      this._claimsSummaryContextService.context$,
    ]);

  public fixedPropertyDetailsPageView = (): EventModel =>
    createPageViewEvent("Fixed Property Details Page View", [
      this._appContextService.context$,
      this._claimsSummaryContextService.context$,
    ]);

  public diaryDetailsPageView = (): EventModel =>
    createPageViewEvent("Diary Details Page View", [
      this._appContextService.context$,
      this._claimsSummaryContextService.context$,
    ]);
}
