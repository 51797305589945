import { Directive, ElementRef, OnInit } from "@angular/core";

import { ValueOrNAPipe } from "./value-or-na-pipe";

@Directive({
  selector: "[claValueOrNaFormatter]",
})
export class ValueOrNaFormatterDirective implements OnInit {
  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private valueOrNAPipe: ValueOrNAPipe
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.valueOrNAPipe.transform(this.el.value);
  }
}
