import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
  createPerformanceEvent,
  EventDispatchService,
} from "@pgr-cla/cla-analytics";

@Injectable({ providedIn: "root" })
export class PerformanceEventService {
  constructor(private eventDispatchService: EventDispatchService) {}

  public trackPerformanceEvent = (
    label: string,
    loadTime: number,

    context: Observable<any>[]
  ): void => {
    this.eventDispatchService.trackPerformanceEvent(
      createPerformanceEvent(label, context, loadTime)
    );
  };
}
