import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs";
import { catchError, concatMap, share, takeUntil, tap } from "rxjs/operators";

import {
  AdverseParty,
  SubrogationSummaryCodes,
} from "@subrogation-summary/models";
import * as adversePartiesActions from "@subrogation-summary/state/actions/adverse-parties.actions";

import { CoreUiExtensionsActions } from "@modules/core-ui-extensions/actions";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { SubrogationSummaryService } from "@subrogation-summary/services";
import {
  selectAllSubrogationSummaryCodes,
  SubrogationSummaryState,
} from "@subrogation-summary/state/reducers";

@Component({
  selector: "cla-adverse-parties-summary",
  templateUrl: "./adverse-parties-summary.component.html",
  styleUrls: ["./adverse-parties-summary.component.scss"],
})
export class AdversePartiesSummaryComponent implements OnInit, OnDestroy {
  claimNumber: string;
  adverseParties$: Observable<AdverseParty[]>;
  subrogationSummaryCodes$: Observable<SubrogationSummaryCodes>;
  showError = false;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  // since we're not using NGRX (or some sort of global store)
  // we'd like to maintain a single subscription that can effectively be
  // refreshed when needed. In this case, it should be refreshed
  // on this.onDataUpdated() called.
  private getAdversePartiesTriggerSubject$ = new BehaviorSubject(null as null);

  constructor(
    private subrogationSummaryService: SubrogationSummaryService,
    private store$: Store<SubrogationSummaryState>,
    private activatedRoute: ActivatedRoute,
    public coreUiStateService: CoreUiExtensionsStateService
  ) {}

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  ngOnInit() {
    this.claimNumber = this.activatedRoute.snapshot.params["claimNumber"];

    this.subrogationSummaryCodes$ = this.store$.select(
      selectAllSubrogationSummaryCodes
    );
    this.adverseParties$ = this.getAdversePartiesTriggerSubject$.pipe(
      concatMap(() =>
        this.subrogationSummaryService.getAdverseParties(this.claimNumber)
      ),
      tap((adverseParties) => {
        this.store$.dispatch(
          adversePartiesActions.loadAdversePartiesSucceeded({ adverseParties })
        );
        this.store$.dispatch(
          CoreUiExtensionsActions.onGetRepSummariesRequest({
            codes: [
              ...new Set(
                adverseParties.flatMap((adverseParty) =>
                  adverseParty.subroFeatures.flatMap((subroFeature) =>
                    [subroFeature.collectingRepId].filter((repId) => repId)
                  )
                )
              ),
            ],
          })
        );
      }),
      share(),
      catchError(() => {
        this.showError = true;
        return EMPTY;
      }),
      takeUntil(this.unsubscribeSubject$)
    );
  }

  onDataUpdated() {
    this.getAdversePartiesTriggerSubject$.next(null);
  }

  trackBySubroObligationId(index: number, adverseParty: AdverseParty) {
    return adverseParty.subroObligationId;
  }
}
