import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "@modules/core/services/authentication";
import { urlConstants } from "@modules/shared/constants";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable, combineLatest } from "rxjs";
import { filter, switchMap } from "rxjs/operators";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private configurationService: ConfigurationService
  ) {}

  public intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (
      req.url.includes(urlConstants.config) ||
      !(
        req.url.includes("adapterApiRouteUrl") ||
        req.url.includes("partyBusinessApiUrl")
      )
    ) {
      return next.handle(req);
    }

    return combineLatest([
      this.configurationService.configuration$,
      this.authService.token$,
    ]).pipe(
      filter(
        ([config, token]) =>
          !!token !== null && config !== undefined && config !== null
      ),
      switchMap(([config, token]: [ClaimsSummaryConfigInfo, string]) => {
        let { headers } = req;
        headers = headers
          .set("Content-Type", "application/json")
          .set("Authorization", `Bearer ${token}`)
          .set("Pragma", "no-cache")
          .set("Cache-Control", "no-cache")
          .set("Expires", "0")
          .set("X-PGR-APPNAME", config.applicationName)
          .set("X-PGR-APPVERSION", config.applicationVersion);
        const newReq = req.clone({ headers });

        return next.handle(newReq);
      })
    );
  }
}
