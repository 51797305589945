<cui-side-sheet>
  <cui-side-sheet-header (close)="closeFilter()">
    Filters
  </cui-side-sheet-header>
  <cui-side-sheet-content
    data-pgr-id="filters"
    aria-relevant="text"
    class="has-header"
  >
    <div class="flex items-center justify-between pb-s">
      <button
        mat-stroked-button
        color="primary"
        (click)="resetFilters()"
        data-pgr-id="btnResetFilters"
        class="ml-auto"
      >
        Reset
      </button>
    </div>
    <mat-accordion
      multi="true"
      *ngIf="historyFilters$ | async as vm"
      data-pgr-id="historyFilters"
    >
      <ng-container *ngFor="let column of vm.columns; trackBy: trackById">
        <mat-expansion-panel
          *ngIf="showFilterPanel(vm.filters[column.id])"
          [attr.data-pgr-id]="column.title + ' Filter Panel'"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p
                class="expansion-title"
                [matBadge]="getFilterCount(vm.filters[column.id])"
                [matBadgeHidden]="filterCountIsHidden(vm.filters[column.id])"
                [matBadgeOverlap]="false"
                matBadgeSize="small"
                [attr.aria-label]="
                  column.title +
                  ' ' +
                  getFilterCount(vm.filters[column.id]) +
                  ' active'
                "
              >
                {{ column.title }}
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngIf="vm.filters[column.id].filterOptions">
            <mat-checkbox
              [disabled]="!vm.filters[column.id].appliedFilters.length"
              [checked]="!vm.filters[column.id].appliedFilters.length"
              (change)="resetColumnFilters(column.id)"
              [attr.data-pgr-id]="column.title + ' Checkbox-All'"
            >
              All <strong>({{ vm.filters[column.id].scopedTotal }})</strong>
            </mat-checkbox>
            <mat-checkbox
              *ngFor="
                let filterOption of vm.filters[column.id].filterOptions;
                let i = index;
                trackBy: trackByText
              "
              [checked]="
                vm.filters[column.id].appliedFilters.indexOf(
                  filterOption.filterText
                ) !== -1
              "
              (change)="onToggleFilter(column.id, filterOption)"
              [attr.data-pgr-id]="column.title + ' Checkbox-' + i"
              class="block"
            >
              <span [class]="filterOption.count ? '' : 'color-gray'"
                >{{ filterOption.filterDisplayText }}
                <strong>({{ filterOption.count }})</strong>
              </span>
            </mat-checkbox>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </cui-side-sheet-content>
</cui-side-sheet>
