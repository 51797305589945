import { Action } from "@modules/shared/redux";
import { createReducer, on } from "@ngrx/store";
import { PartyDetail } from "../../models/party-detail";

import * as partyDetailsActions from "./party-details.actions";

export interface PartyDetailsState {
  partyDetails: PartyDetail[] | undefined;
}

export const initialState: PartyDetailsState = {
  partyDetails: undefined, //[],
};

const partyDetailsReducers = createReducer(
  initialState,
  on(partyDetailsActions.getPartyDetailsSuccess, (state, { partyDetails }) => ({
    ...state,
    partyDetails: [...partyDetails],
  }))
);

export function reducer(state: PartyDetailsState | undefined, action: Action) {
  return partyDetailsReducers(state, action);
}
