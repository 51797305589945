export enum SPINNERID {
  ERROR = "error",
}

export enum POLICY_VEHICLE_TYPE {
  AUTO = "AU",
  COMMERCIAL_AUTO = "CA",
  MOTORCYCLE = "MC",
  BOAT = "BT",
  MANUFACTURED_HOME = "MH",
  MOTOR_HOME = "MT",
  TRAVEL_TRAILER = "TT",
  MANUAL = "MN",
}

export enum REFERENCE_TABLE {
  POLICYSTATUS = "policystatus",
  UWMEMOSTATUSCODE = "UwMemoStatusCode",
  UWMEMOQUESTIONXREF = "UwMemoQuestionXref",
}

export const POLICY_VEHICLE_SVG_MAP: Record<string, string> = {
  AU: "auto",
  AA: "auto",
  CA: "commercial-lines",
  MC: "motorcycle",
  BT: "boat",
  MH: "manufactured-home",
  MT: "travel-trailer",
  TT: "travel-trailer",
  MN: "auto",
  HO: "pha",
  RT: "renters",
  SM: "snowmobile",
};
