import { Pipe, PipeTransform } from "@angular/core";
import { unknownCategoryCode } from "@notes/constants";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";

@Pipe({
  name: "claNoteCategory",
})
export class NoteCategoryPipe implements PipeTransform {
  transform(category: CodeDescriptionPair): string {
    return `${category.description}${
      category.code !== unknownCategoryCode ? ` (${category.code})` : ""
    }`;
  }
}
