import { Injectable, NgZone } from "@angular/core";

import { HttpErrorResponse } from "@angular/common/http";
import { NotificationService } from "@pgr-cla/core-ui-components";
import { ConnectivityService } from "../connectivity";
import { MessageBusService } from "../message-bus";
import { ErrorService } from "../../models";

@Injectable({
  providedIn: "root",
})
export class NetworkErrorService implements ErrorService {
  constructor(
    private readonly _messageBus: MessageBusService,
    private readonly _connectivityService: ConnectivityService,
    private readonly _notificationService: NotificationService,
    private readonly _zone: NgZone
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handle(error: any | undefined): any | undefined {
    let result = error || undefined;
    if (error instanceof HttpErrorResponse) {
      result = this._handleNetworkError(error);
    } else if (error.error instanceof HttpErrorResponse) {
      result = this._handleNetworkError(error.error);
    }

    return result;
  }

  private _handleNetworkError(
    error: HttpErrorResponse
  ): HttpErrorResponse | undefined {
    let response;
    if (error.status === 0 && !this._connectivityService.online) {
      this._messageBus.dispatch({
        type: "offlineRequest",
      });
    } else if (error.status === 0) {
      this._zone.run(() => {
        this._notificationService.error(
          "A network error occurred. Please try again."
        );
      });

      // we actually want to pass this to the default error handler so it logs to splunk
      response = error;
    }

    return response;
  }
}
