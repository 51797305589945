import { Injectable } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { MainContentFocusService } from "@modules/core";
import { selectRouteParam } from "@modules/shared";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATED,
  RouterCancelAction,
  RouterErrorAction,
  RouterNavigatedAction,
} from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { AppState } from "@store/app-state.interface";
import { delay, filter, map, take, tap } from "rxjs/operators";

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class RouterEffects {
  // TO DO pull Property ID off ROUTE & Fixed Property ID off route..

  claimNumber$ = this.store$.select(selectRouteParam("claimNumber")).pipe(
    filter((claimNumber) => !!claimNumber),
    map((claimNumber) =>
      this.claimNumberPipe.transform(claimNumber.toString())
    ),
    take(1)
  );

  navigationEnd$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map((action: RouterNavigatedAction) => action.payload.event)
      ),
    { dispatch: false }
  );

  navigationCancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_CANCEL),
        map((action: RouterCancelAction<any>) => action.payload.event)
      ),
    { dispatch: false }
  );

  navigationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_ERROR),
        map((action: RouterErrorAction<any>) => action.payload.event)
      ),
    { dispatch: false }
  );

  navigationFocusEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map((action: RouterNavigatedAction) => action.payload.event),
        filter((event: NavigationEnd) => event.id > 1),
        delay(100),
        tap(() => this.mainContentFocus.focusOnContent())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private mainContentFocus: MainContentFocusService,
    private store$: Store<AppState>,
    private claimNumberPipe: ClaimNumberPipe
  ) {}
}
