import { Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";
import { WindowService } from "@pgr-cla/cla-window";
import { CookieService } from "ngx-cookie-service";
import { Observable, ReplaySubject } from "rxjs";

import { AuthenticationConstants } from "@core/constants";

@Injectable({
  providedIn: "root",
})
export class CookieTokenService extends CookieService {
  private readonly tokenSubject$: ReplaySubject<string | null> =
    new ReplaySubject<string | null>(1);
  private readonly tokenExpiresDateSubject$: ReplaySubject<string | null> =
    new ReplaySubject<string | null>(1);
  private readonly tokenKey: string = AuthenticationConstants.tokenKey;
  private readonly expiresKey: string = "expires=";

  constructor(
    windowProvider: WindowService,
    @Inject(PLATFORM_ID) platformId: InjectionToken<object>
  ) {
    super(windowProvider.document, platformId);
  }

  get token$(): Observable<string | null> {
    return this.tokenSubject$.asObservable();
  }

  get tokenExpiresDate$(): Observable<string | null> {
    return this.tokenExpiresDateSubject$.asObservable();
  }

  requestToken(): void {
    if (this.check(this.tokenKey)) {
      const tokenValue: string = this.get(this.tokenKey);
      if (tokenValue.includes(this.expiresKey)) {
        const tokenArray: string[] = this.get(this.tokenKey).split(";");
        const bearerToken: string = tokenArray[0];
        const expiresDate: string = tokenArray[1].split(this.expiresKey)[1];
        this.tokenSubject$.next(bearerToken);
        this.tokenExpiresDateSubject$.next(expiresDate);
      } else {
        this.tokenSubject$.next(tokenValue);
        this.tokenExpiresDateSubject$.next(null);
      }
    } else {
      this.tokenSubject$.next(null);
      this.tokenExpiresDateSubject$.next(null);
    }
  }

  clearToken(): void {
    this.delete(this.tokenKey, "/");
    this.tokenSubject$.next(null);
    this.tokenExpiresDateSubject$.next(null);
  }

  setToken(
    token: string,
    expires: Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite: "Lax" | "Strict" = "Strict"
  ): void {
    const tokenWithExpires: string = `${token};${
      this.expiresKey
    }${expires.toUTCString()}`;
    this.set(
      this.tokenKey,
      tokenWithExpires,
      expires,
      path,
      domain,
      secure,
      sameSite
    );
    this.requestToken();
  }

  get isCookieTokenExpired(): boolean {
    return this.get(this.tokenKey) === "" ? true : false;
  }
}
