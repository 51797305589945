<!-- <div class="maps-link-container">
  <span *ngIf="!googleMapsUrl" [innerHtml]="address | claEmptyDash"></span>
  <a
    class="cui-link"
    *ngIf="googleMapsUrl"
    target="_blank"
    rel="noopener noreferrer"
    [href]="googleMapsUrl"
    [innerHtml]="address"
  ></a>
</div> -->
<ng-container *ngIf="googleMapsUrl; else noGoogleMapsUrl">
  <div class="maps-link-container flex justify-between items-center">
    <a
      class="cui-link"
      target="_blank"
      rel="noopener noreferrer"
      [href]="googleMapsUrl"
      [innerHtml]="address"
      data-pgr-id="googleMapsUrl"
    ></a>
    <cui-copy-button
      [ariaLabel]="'Copy Address ' + addressToCopy + ' to clipboard'"
      [dataToCopy]="addressToCopy"
      class="copy-button-icon-only"
      data-pgr-id="copyButton"
    >
    </cui-copy-button>
  </div>
</ng-container>
<ng-template #noGoogleMapsUrl>
  <span data-pgr-id="noGoogleMapsUrl"> — </span>
</ng-template>
