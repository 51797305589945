import {
  AppIntegrationType,
  ClaimDetailsState,
} from "./claim-details-state.interface";
import { EntityDataState, PolicyDriver } from "@modules/core/models";

export const associatedPolicyDriversInitialState: EntityDataState<
  PolicyDriver[]
> = {
  data: null,
  isLoading: false,
  error: null,
};

export const initialState: ClaimDetailsState = {
  isInitialized: false,
  appIntegrationType: AppIntegrationType.STANDALONE,
  claimNumber: "",
  isClaimDetailsRequestInFlight: true,
  isClaimReportedViaRequestInFlight: false,
  isPartyPropertyDetailsRequestInFlight: true,
  isFileOwnerDetailsRequestInFlight: false,
  isEditLossDetailsRequestInFlight: false,
  claimDetails: undefined,
  claimDetailsError: undefined,
  claimSummary: undefined,
  claimSummaryError: undefined,
  partyPropertyDetails: undefined,
  editLossDetailsError: undefined,
  successBannerMessage: "",
  errorBannerMessages: [],
  warningBannerMessage: "",
  warningBannerIsDismissable: false,
  associatedPolicyDrivers: associatedPolicyDriversInitialState,
  claimSyncSaveDetails: {
    isCreateReferralSaveInFlight: false,
    createReferralClaimSyncSaveError: undefined,
    createReferralClaimSyncDetails: undefined,
    isPDSuiteSaveInFlight: false,
    pdSuiteClaimSyncSaveError: undefined,
    pdSuiteClaimSyncDetails: undefined,
    claimSyncSaveErrorMessage: undefined,
  },
  isPageScrollingEnabled: true,
};
