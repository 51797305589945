<div *ngIf="previewedItem" class="pt-5">
  <div class="flex flex-row pt-4">
    <div class="flex flex-col">
      <div class="primary-typeface-uppercase font-bold">Latest version</div>
      <button
        data-pgr-id="btnLatestVersion"
        class="cui-link mat-button version-link pt-2"
        (click)="openVersionSelector()"
      >
        {{ getLatestVersion().createdDateTimeFormatted }}
      </button>
    </div>
  </div>
  <div class="flex flex-row pt-4">
    <div class="flex flex-col">
      <div class="primary-typeface-uppercase font-bold">Created by</div>
      <div class="pt-2">
        <cla-contact-card
          [repInfo]="
            (coreUiStateService.repSummaries$ | async)[
              getLatestVersion().createdByUserId
            ]
          "
          [tpxId]="getLatestVersion().createdByUserId"
          [isLoadingRepInfo]="coreUiStateService.isLoadingRepSummaries$ | async"
        ></cla-contact-card>
      </div>
    </div>
  </div>
  <div class="flex flex-row pt-4">
    <div class="flex flex-col">
      <div class="primary-typeface-uppercase font-bold">Category</div>
      <div class="pt-2">{{ previewedItem.contentCategoryDesc }}</div>
    </div>
  </div>
  <div class="flex flex-row pt-4">
    <div class="flex flex-col">
      <div class="primary-typeface-uppercase font-bold">Type</div>
      <div class="pt-2">{{ previewedItem.contentTypeDesc }}</div>
    </div>
  </div>
</div>
