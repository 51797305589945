import { NgModule, Type } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TechnicalDifficultyComponent } from "@pgr-cla/core-ui-components";

import { ClaimsSummaryViewModule } from "@modules/claims-summary/claims-summary.view.module";
import { CombinedClaimGuard } from "@modules/core/services";
import { ContentSummaryModule } from "@modules/electronic-file-folder/content-summary.module";
import { FeatureRevisionModule } from "@modules/feature-revision-history/feature-revision.module";
import { FeatureModule } from "@modules/feature/feature.module";
import { PaymentsModule } from "@modules/payments/payments.module";
import { PolicyModule } from "@modules/policy/policy.module";
import { SubrogationSummaryModule } from "@subrogation-summary/subrogation-summary.module";

import { TechDiffMessageResolver } from "src/app/tech-diff-message.resolver";
import { ClaimsLandingComponent } from "./claims-landing.component";
import { SearchableClaimNotFoundComponent } from "./components";

const claimsLandingRoutes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "welcome",
  },
  {
    path: "welcome",
    component: SearchableClaimNotFoundComponent,
    data: {
      title: "Welcome To Progressive Loss Viewer",
      message: "Please enter your claim number.",
    },
  },
  {
    path: "claim-not-found",
    component: SearchableClaimNotFoundComponent,
    data: {
      title: "Claim Not Found",
      message:
        "The claim you are looking for could not be found. Please try a different claim number.",
    },
  },
  {
    path: ":claimNumber",
    canActivate: [CombinedClaimGuard],
    component: ClaimsLandingComponent,
    data: { useTitleService: true },
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "claim-summary",
      },
      // {
      //   path: 'diary',
      //   loadChildren: (): Type<DiaryModule> => DiaryModule,
      // },
      {
        path: "content-summary",
        loadChildren: (): Type<ContentSummaryModule> => ContentSummaryModule,
      },
      {
        path: "payments",
        loadChildren: (): Type<PaymentsModule> => PaymentsModule,
      },
      {
        path: "feature",
        loadChildren: (): Type<FeatureModule> => FeatureModule,
      },
      {
        path: "feature-history",
        loadChildren: (): Type<FeatureRevisionModule> => FeatureRevisionModule,
      },
      {
        path: "claim-summary",
        loadChildren: (): Type<ClaimsSummaryViewModule> =>
          ClaimsSummaryViewModule,
      },
      {
        path: "coverage",
        loadChildren: (): Type<PolicyModule> => PolicyModule,
      },
      {
        path: "subrogation",
        loadChildren: (): Type<SubrogationSummaryModule> =>
          SubrogationSummaryModule,
      },
      {
        path: "techdiff",
        component: TechnicalDifficultyComponent,
        resolve: {
          message: TechDiffMessageResolver,
        },
        data: {
          title: "Looks like something went wrong.",
          helpDeskNumber: "",
        },
      },
      {
        path: "**",
        component: SearchableClaimNotFoundComponent,
        data: {
          title: "Page Not Found",
          message:
            "The page you are looking for could not be found. Please try going back or selecting a different tab.",
          hideHeader: true,
          hideForm: true,
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "welcome",
  },
];

@NgModule({
  imports: [RouterModule.forChild(claimsLandingRoutes)],
  exports: [RouterModule],
})
export class ClaimsLandingRoutingModule {}
