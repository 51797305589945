import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DiaryCalenderDatesData, DiaryState } from "@diary/models";
import { CellData } from "@pgr-cla/core-ui-components/lib/core/components/complex-datepicker/models";

export const selectDiaryState = createFeatureSelector<DiaryState>("diary");

export const selectDiaries = createSelector(
  selectDiaryState,
  (state: DiaryState) => state.diaries
);

export const selectDiaryTableData = createSelector(
  selectDiaryState,
  (state: DiaryState) => state.diaryTableData
);

export const selectDiaryRequestInFlight = createSelector(
  selectDiaryState,
  (state: DiaryState) => state.diaryRequestInFlight
);

export const selectDiaryError = createSelector(
  selectDiaryState,
  (state: DiaryState) => state.diaryError
);

export const selectDiaryCountData = createSelector(
  selectDiaryState,
  (state: DiaryState) =>
    state.diaryCalenderCountData?.diaryDatesData.map(
      (diaryCalenderDatesData: DiaryCalenderDatesData) => {
        return {
          date: diaryCalenderDatesData.diaryDate,
          value: diaryCalenderDatesData.dateCount.toString(),
        } as CellData;
      }
    )
);
