<meta
  http-equiv="Cache-Control"
  content="no-cache, no-store, must-revalidate"
/>
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />
<div [class.hidden]="!isPreloaderVisible" class="preloader">
  <img class="logo" src="../assets/pgr-p.svg" alt="loading spinner" />
  <div class="preloader-1"></div>
  <div class="spin-all">
    <div class="preloader-2"></div>
    <div class="preloader-3"></div>
    <div class="preloader-4"></div>
    <div class="preloader-5"></div>
  </div>
</div>
<div id="cla-claims-summary-main" class="min-w-full h-full">
  <router-outlet
    #outlet="outlet"
    (activate)="onRouteChange(outlet)"
  ></router-outlet>
</div>
