import { Injectable, OnDestroy } from "@angular/core";
import { UserContextService } from "@modules/core";
import { BehaviorSubject, Observable } from "rxjs";
import { first } from "rxjs/operators";

import { IEFFContext, IUserContext } from "../../../models";

@Injectable()
export class AppContextService implements OnDestroy {
  public context$: Observable<IEFFContext>;
  private _contextSubject$: BehaviorSubject<IEFFContext>;

  constructor(private userContextService: UserContextService) {
    this._contextSubject$ = new BehaviorSubject({} as IEFFContext);
    this.context$ = this._contextSubject$.asObservable();
    this.mapUserProfile();
  }

  public ngOnDestroy(): void {
    this._contextSubject$.complete();
  }

  private mapUserProfile() {
    this.userContextService.userContextSource$
      .pipe(
        first(
          (context: IUserContext) =>
            context !== null &&
            context !== undefined &&
            context.profile !== undefined
        )
      )
      .subscribe((context: IUserContext) => {
        this._contextSubject$.next({
          user: {
            networkID: context.profile.networkID,
            uid: context.profile.uid,
            fullName: context.profile.fullName,
            title: context.profile.title,
            permissions: context.permissions.map((p) => p.name).join(", "),
          },
        } as IEFFContext);
      });
  }
}
