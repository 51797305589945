<div class="tag-section-container">
  <mat-chip-set
    class="pt-2"
    aria-label="tag list"
    cuiMatChipListAccessibility
    role="list"
  >
    <ng-container *ngFor="let tag of tags">
      <mat-chip
        cuiMatChipAccessibility
        data-pgr-id="tag-chip"
        [disableRipple]="true"
        [removable]="false"
        [selectable]="false"
        class="h-full tag-chip"
        role="listitem"
      >
        {{ tag.tagName }}
      </mat-chip>
    </ng-container>
  </mat-chip-set>
</div>
