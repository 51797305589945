import { Action, createReducer, on } from "@ngrx/store";

import * as recoveryHistoryActions from "../actions/recovery-history.actions";
import { RecoveryHistoryItem } from "../../models";

export interface RecoveryHistory {
  recoveryHistoryItems: RecoveryHistoryItem[];
  failedToLoadRecoveryHistory: boolean;
}

export interface RecoveryHistoryState {
  [subroObligationId: number]: RecoveryHistory;
}

const initialState: RecoveryHistoryState = {};

export function reducer(state: RecoveryHistoryState, action: Action) {
  return RecoveryHistoryReducer(state, action);
}

const RecoveryHistoryReducer = createReducer(
  initialState,
  on(
    recoveryHistoryActions.loadRecoveryHistorySucceeded,
    (state, { subroObligationId, recoveryHistoryItems }) => ({
      ...state,
      [subroObligationId]: {
        recoveryHistoryItems,
        failedToLoadRecoveryHistory: false,
      },
    })
  ),
  on(
    recoveryHistoryActions.loadRecoveryHistoryFailed,
    (state, { subroObligationId }) => ({
      ...state,
      [subroObligationId]: {
        recoveryHistoryItems: [],
        failedToLoadRecoveryHistory: true,
      },
    })
  )
);
