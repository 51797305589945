<div>
  <cla-claim-header-logo></cla-claim-header-logo>
</div>
<cla-claim-header-data
  *ngIf="showData"
  [headerData]="headerData"
  [ownerRepCode]="ownerRepCode"
  [ownerRepSummary]="ownerRepSummary"
  [isLoading]="claimDetailsStateService.isClaimDetailsRequestInFlight$ | async"
  [isFileOwnerLoading]="
    (claimDetailsStateService.isClaimDetailsRequestInFlight$ | async) ||
    (coreUiStateService.isLoadingRepSummaries$ | async)
  "
></cla-claim-header-data>
<div class="text-right ml-bl-2 flex justify-end flex-grow items-center">
  <cui-theme-picker id="themePicker"></cui-theme-picker>
  <cla-claim-header-search-wrapper
    *ngIf="showData"
  ></cla-claim-header-search-wrapper>
  <button
    class="ml-bl-2"
    mat-flat-button
    color="primary"
    (click)="showLogoutConfirmation()"
    data-pgr-id="btnLogout"
  >
    Logout
  </button>
</div>
