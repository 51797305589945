import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";

import { RecoveryHistoryItem } from "../../../models";
import { PayersNamePipe } from "../../../pipes";
import {
  selectRecoveryHistoryBySubroObligationId,
  SubrogationSummaryState,
} from "../../../state/reducers";

export interface RecoveryHistoryModalData {
  subroObligationId: number;
  partyName: string;
}

@Component({
  templateUrl: "./recovery-history-modal.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./recovery-history-modal.component.scss"],
})
export class RecoveryHistoryModalComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  hideNoPaymentsMessage = false;
  dataSource = new MatTableDataSource<RecoveryHistoryItem>();
  displayedColumns = [
    "creditAdviceNumber",
    "checkNumber",
    "checkReceiptDate",
    "grossRecoveryAmount",
    "netRecoveryAmount",
    "payerName",
  ];

  constructor(
    private payerNamePipe: PayersNamePipe,
    private store$: Store<SubrogationSummaryState>,
    public dialogRef: MatDialogRef<RecoveryHistoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecoveryHistoryModalData
  ) {}

  ngOnInit() {
    this.store$
      .pipe(
        select(selectRecoveryHistoryBySubroObligationId, {
          subroObligationId: this.data.subroObligationId,
        }),
        map((recoveryHistoryItems) => {
          this.dataSource.data = recoveryHistoryItems;
          this.hideNoPaymentsMessage = recoveryHistoryItems.length > 0;
        })
      )
      .subscribe();
    this.dataSource.sortingDataAccessor = (
      item,
      property: keyof typeof item
    ) => {
      switch (property) {
        case "payerName":
          return this.payerNamePipe.transform(item.payerName);
        case "checkNumber":
          return Number.parseInt(item.checkNumber, 10) || 0;
        default:
          return item[property];
      }
    };
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
