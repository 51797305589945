<span
  *ngIf="isLoadingRepInfo; else repSection"
  class="ghost-line block w-full"
></span>
<ng-template #repSection>
  <cui-popover #pop align="bottomLeft" *ngIf="repInfo; else justTpx">
    <button class="cui-popover-link" cui-popover-trigger>
      <span class="font-bold text-gray-900 mr-bl-1" *ngIf="cardLabel"
        >{{ cardLabel }}
      </span>
      <span
        class="rep-name rep-link"
        [ngClass]="breakWord ? 'break-word' : 'do-not-break-word'"
        >{{
          showLongName
            ? repInfo.name ?? repInfo.shortName
            : repInfo.shortName ?? repInfo.name
        }}{{ showRepId ? tpxIdInParentheses : "" }}</span
      >
    </button>
    <cla-claim-rep-contact-card-redacted
      [rep]="repInfo"
      cui-popover-content
    ></cla-claim-rep-contact-card-redacted>
  </cui-popover>
  <ng-template #justTpx>{{ tpxId }}</ng-template>
</ng-template>
