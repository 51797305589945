import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Data, Router } from "@angular/router";
import {
  ClaimNumberPipe,
  DialogService,
  claimNumberWithSuffixValidator,
} from "@pgr-cla/core-ui-components";
import { of } from "rxjs";
import { catchError, take } from "rxjs/operators";

import { ClaimHeaderSearchDialogComponent } from "@modules/claims-landing/components/claim-header/components/claim-header-search-dialog";
import { EnterClaimOnErrorService } from "@modules/core/services";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { ClaimSearchService } from "@modules/shared";

@Component({
  selector: "cla-searchable-claim-not-found",
  templateUrl: "./searchable-claim-not-found.component.html",
  styleUrls: ["./searchable-claim-not-found.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SearchableClaimNotFoundComponent implements OnInit {
  @ViewChild("claimNumberField") claimNumberField: ElementRef<HTMLInputElement>;
  claimNotFoundForm: FormGroup = new FormGroup({
    claimNumber: new FormControl("", [claimNumberWithSuffixValidator]),
  });
  get claimNumber(): FormControl {
    return this.claimNotFoundForm?.get("claimNumber") as FormControl;
  }
  title: string = "Claim Not Found";
  message: string =
    "The claim you are looking for could not be found. Please try a different claim number.";
  hideHeader: boolean = false;
  hideForm: boolean = false;
  isLoading: boolean = false;

  constructor(
    private dialogService: DialogService,
    private route: ActivatedRoute,
    private router: Router,
    public enterClaimOnErrorService: EnterClaimOnErrorService,
    public claimDetailsStateService: ClaimDetailsStateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: Data,
    private claimNumberPipe: ClaimNumberPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private claimSearchService: ClaimSearchService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(take(1)).subscribe((routeData: Data) => {
      if (this.dialogData && this.dialogData.title && this.dialogData.message) {
        this.title = this.dialogData.title;
        this.message = this.dialogData.message;
      } else if (routeData && routeData.title && routeData.message) {
        this.title = routeData.title;
        this.message = routeData.message;
        this.hideHeader = routeData.hideHeader;
        this.hideForm = routeData.hideForm;
      }
    });
  }

  searchClaim(): void {
    if (this.claimNotFoundForm.valid) {
      this.isLoading = true;
      const formattedClaimNumber: string | null =
        this.claimNumberPipe.transform(this.claimNumber.value, "full") || "";
      this.claimSearchService
        .getClaimSearch(formattedClaimNumber)
        .pipe(
          take(1),
          catchError(() => {
            this.openDialog(formattedClaimNumber);
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
            return of();
          })
        )
        .subscribe(() => {
          this.router.navigateByUrl(formattedClaimNumber);
          this.isLoading = false;
        });
    }
  }

  openDialog(claimNumber: string): void {
    const dialogRef: MatDialogRef<ClaimHeaderSearchDialogComponent> =
      this.dialogService.openSmallDialog(ClaimHeaderSearchDialogComponent, {
        data: { claimNumber },
        role: "alertdialog",
        ariaLabelledBy: "claim-not-found-header",
        ariaDescribedBy: "claim-not-found-description",
        restoreFocus: false,
      });

    dialogRef.afterClosed().subscribe(() => {
      this.claimNumberField.nativeElement.focus();
    });
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData: DataTransfer | null = event?.clipboardData;
    const pastedData: string | undefined = clipboardData?.getData("Text");

    if (pastedData) {
      this.claimNumber.valueChanges
        .pipe(take(1))
        .subscribe(() => this.formatSearchValue());
    }
  }

  formatSearchValue(): void {
    this.claimNumber.setValue(
      (this.claimNumberPipe.transform(this.claimNumber.value) || "").trim()
    );
  }
}
