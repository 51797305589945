import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ClaimPolicy } from "@modules/policy/models/claim-policy";
import {
  PolicyVehicle,
  VehicleLimitsAndCoverages,
} from "@modules/policy/models/policy-vehicle";
import { ClaimInfo } from "@modules/shared/models/claim-info";
import { Store } from "@ngrx/store";
import { AppState } from "@store";
import { Subject } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import * as PolicyActions from "../../../state/policy/policy.actions";
import {
  selectAttachPolicyVehicles,
  selectClaimInfo,
  selectClaimPolicy,
  selectSelectedVehicleInfo,
} from "../../../state/policy/policy.selectors";

export interface VehicleTableRow {
  vehicleName: string;
  vin: string;
  statedAmount: number | null;
  use: string;
  coverages: VehicleLimitsAndCoverages[];
}

// const lienHolderTypes = [{code: "010", description: "Loss Payee"}, {code: "011", description: "Additional Insured"}];

@Component({
  selector: "cla-vehicles-table",
  templateUrl: "./vehicles-table.component.html",
  styleUrls: ["./vehicles-table.component.scss"],
})
export class VehiclesTableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() policyAttachVehicles: PolicyVehicle[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["vehicleName", "vin", "statedAmount", "use"];
  dataSource = new MatTableDataSource<PolicyVehicle>();
  policy: ClaimPolicy | null;
  claim: ClaimInfo | null;

  private unsubscribeSubject$ = new Subject<boolean>();
  shouldOpenDialog = false;
  constructor(private store$: Store<AppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store$
      .select(selectAttachPolicyVehicles)
      .pipe(
        map((vehicles) =>
          vehicles.map((vehicle) => ({
            ...vehicle,
            vehicleName: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
          }))
        ),
        tap((vehicles) => (this.dataSource.data = vehicles)),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe();
    this.store$
      .select(selectClaimPolicy)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((policy) => (this.policy = policy));
    this.store$
      .select(selectClaimInfo)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((claim) => (this.claim = claim));
    this.store$
      .select(selectSelectedVehicleInfo)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((selectedVehicle) => {
        if (this.shouldOpenDialog) {
          this.shouldOpenDialog = false;
          // this.dialog.open(PolicyVehicleInfoComponent, {
          //   width: CUI_DIALOG_WIDTH.MEDIUM,
          //   panelClass: 'cui-dialog',
          //   data: { vehicle: selectedVehicle } as PolicyVehicleInfoProps,
          // });
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next(true);
    this.unsubscribeSubject$.complete();
  }

  openVehicleInfoDialog(vehicle: PolicyVehicle) {
    this.shouldOpenDialog = true;
    this.setVehicleInfo(vehicle);
  }

  setVehicleInfo(vehicle: PolicyVehicle) {
    this.store$.dispatch(
      PolicyActions.getVehicleInfo({
        policyNumber: this.policy!.policyNumber,
        renewalSuffixNumber: this.policy!.renewalSuffixNumber.toString(),
        dateOfLoss: new Date(this.claim!.dateOfLoss),
        positions: [vehicle.policyPosition.toString()],
        productCode: this.claim!.productCode,
      })
    );
  }
}
