<form
  [ngClass]="{
    hidden: (notesStateService.isToDoNotesTabSelected$ | async) === true
  }"
  [formGroup]="form"
  class="subheader flex w-full condensed flex-col"
>
  <div *ngIf="isEmbedded">
    <mat-divider *ngIf="isExpanded"></mat-divider>
    <button
      data-pgr-id="btnToggleFilters"
      class="expand-toggle"
      (click)="toggleExpanded()"
    >
      <span class="ml-bl-2 w-full">{{
        isExpanded ? "Hide Search & Filters" : "Show Search & Filters"
      }}</span>
      <mat-icon>{{ isExpanded ? "expand_less" : "expand_more" }}</mat-icon>
    </button>
  </div>
  <div
    [@panelExpansion]="isExpanded ? 'expanded' : 'collapsed'"
    class="flex w-full px-bl-2 flex-col"
    [ngClass]="{
      'py-bl-2': !isEmbedded,
      'py-0': isEmbedded
    }"
  >
    <div
      class="flex flex-col flex-1"
      [ngClass]="{
        'ie-flex-fix': isIE,
        'pt-bl-2': isEmbedded,
        'sm:flex-row': !isEmbedded
      }"
    >
      <div
        class="flex my-bl-2"
        [ngClass]="{
          'sm:w-1/2': !isEmbedded
        }"
      >
        <mat-form-field
          class="flex-1 mr-bl-2"
          appearance="outline"
          id="category"
        >
          <mat-label>Category </mat-label>
          <mat-select
            aria-label="Category"
            data-pgr-id="ddlCategory"
            formControlName="categoryCode"
            name="category"
            aria-expanded="false"
            aria-controls="ddlCategory"
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let category of categories"
              [value]="category.code"
            >
              {{ category.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="flex-1"
          [ngClass]="{
            'sm:mr-bl-2': !isEmbedded
          }"
          appearance="outline"
          id="author"
        >
          <mat-label>Author </mat-label>
          <mat-select
            aria-label="Author"
            data-pgr-id="ddlAuthor"
            formControlName="authoredRepCode"
            name="author"
            aria-expanded="false"
            aria-controls="ddlAuthor"
          >
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let author of authors" [value]="author.code">
              {{ author.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="flex my-bl-2"
        [ngClass]="{
          'sm:w-1/2': !isEmbedded
        }"
      >
        <mat-form-field
          class="flex-1 mr-bl-2"
          appearance="outline"
          id="startDate"
        >
          <mat-label>Start Date</mat-label>
          <input
            matInput
            placeholder="MM/DD/YYYY"
            [matDatepicker]="startDatePicker"
            formControlName="startDate"
            data-pgr-id="dtStartDate"
            [min]="createdDateRangeOptions.min"
            [max]="maxStartDate"
            name="startDate"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="flex-1" appearance="outline" id="endDate">
          <mat-label>End Date</mat-label>
          <input
            matInput
            placeholder="MM/DD/YYYY"
            [matDatepicker]="endDatePicker"
            formControlName="endDate"
            data-pgr-id="dtEndDate"
            [min]="minEndDate"
            [max]="createdDateRangeOptions.max"
            name="endDate"
            (keydown.enter)="$event.preventDefault()"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="endDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div
      class="flex flex-row flex-1 my-bl-2"
      [ngClass]="{
        'ie-flex-fix': isIE,
        'pb-bl-2': isEmbedded
      }"
    >
      <div class="flex flex-row mr-bl-2 flex-1 sm:mr-0 sm:w-1/2 sm:flex-none">
        <mat-form-field
          class="flex-1 sm:mr-bl-2"
          appearance="outline"
          id="searchTerm"
        >
          <mat-label>Search</mat-label>
          <input
            aria-label="Search"
            formControlName="searchTerm"
            matInput
            data-pgr-id="txtSearchTerm"
            name="searchTerm"
            (keydown.enter)="applyFilters($event)"
            maxlength="1000"
            autocomplete="off"
          />
          <mat-icon
            class="placeholder"
            matSuffix
            *ngIf="!form.controls.searchTerm.value"
            >search</mat-icon
          >
          <button
            mat-icon-button
            *ngIf="form.controls.searchTerm.value"
            class="small"
            data-pgr-id="btnClearSearch"
            (click)="clearSearch()"
            matSuffix
            aria-label="Clear Search"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="flex flex-1">
        <button
          mat-flat-button
          color="primary"
          class="mr-bl-2 self-center notes-apply-filters"
          data-pgr-id="btnApply"
          (click)="applyFilters()"
          aria-label="Apply filters"
        >
          Apply
        </button>
        <button
          mat-stroked-button
          color="primary"
          (click)="resetFilters()"
          class="self-center"
          data-pgr-id="btnReset"
          aria-label="Reset filters"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</form>
