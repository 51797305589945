import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  CoverageDetailInfo,
  FeatureDetail,
  LiabilityTile,
} from "@claimssummaryshared/models";
import { FeatureTableData } from "@modules/claims-summary/models/feature-table-data.interface";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as claimSummaryActions from "../../actions";
import { ClaimSummaryState } from "../../models";
import {
  selectCoverages,
  selectCoveragesError,
  selectFeatures,
  selectFeaturesError,
  selectFeaturesTableData,
  selectIsCoveragesRequestInFlight,
  selectIsFeaturesRequestInFlight,
  selectIsLiabilityRequestInFlight,
  selectLiability,
  selectLiabilityError,
} from "../../selectors";

@Injectable()
export class ClaimSummaryStateService {
  constructor(private store$: Store<ClaimSummaryState>) {}

  features$: Observable<FeatureDetail[]> = this.store$.select(selectFeatures);
  featuresTableData$: Observable<FeatureTableData[]> = this.store$.select(
    selectFeaturesTableData
  );
  featuresError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectFeaturesError);
  isFeaturesRequestInFlight$: Observable<boolean> = this.store$.select(
    selectIsFeaturesRequestInFlight
  );
  liability$: Observable<LiabilityTile | undefined> =
    this.store$.select(selectLiability);
  liabilityError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectLiabilityError);
  isLiabilityRequestInFlight$: Observable<boolean> = this.store$.select(
    selectIsLiabilityRequestInFlight
  );

  coverages$: Observable<CoverageDetailInfo | undefined> =
    this.store$.select(selectCoverages);

  coveragesError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectCoveragesError);
  isCoveragesRequestInFlight$: Observable<boolean> = this.store$.select(
    selectIsCoveragesRequestInFlight
  );

  onFeaturesRequest = (): void => {
    this.store$.dispatch(claimSummaryActions.onFeaturesRequest());
  };

  onCoveragesRequest = (): void => {
    this.store$.dispatch(claimSummaryActions.onCoveragesRequest());
  };

  onLiabilityRequest = (): void => {
    this.store$.dispatch(claimSummaryActions.onLiabilityRequest());
  };
}
