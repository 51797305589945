import { Injectable } from "@angular/core";
import { ResizeEvent } from "angular-resizable-element";
import { BehaviorSubject, Observable } from "rxjs";
import { auditTime, map, take } from "rxjs/operators";

@Injectable()
export class RightRailResizeService {
  private openedSubject$: BehaviorSubject<boolean>;
  private isResizingSubject$: BehaviorSubject<boolean>;
  private widthSubject$: BehaviorSubject<number>;

  public isResizing$: Observable<boolean>;
  public opened$: Observable<boolean>;
  public widthpx$: Observable<string>;
  public widthMinMax$: Observable<string>;
  public widthMinMaxDebounce$: Observable<string>;
  public rightRailDesiredWidthPercentage: number;

  readonly startingPercentageWidth: number = 20;
  readonly maximumPercentageWidth: string = "37%";
  readonly maximumPercentageWidthNumber: number = 37;
  readonly minimumPercentageWidthNumber: number = 15;
  readonly incrementStepSize: number = 2;

  readonly minimumFixedWidth: string = "25em";
  readonly maximumFixedWidth: string = "80em";

  private disabledIframes: HTMLIFrameElement[] = [];

  constructor() {
    this.openedSubject$ = new BehaviorSubject(false);
    this.isResizingSubject$ = new BehaviorSubject(false);
    this.widthSubject$ = new BehaviorSubject(this.startingPercentageWidth);
    this.opened$ = this.openedSubject$.asObservable();
    this.isResizing$ = this.isResizingSubject$.asObservable();
    this.widthMinMax$ = this.widthSubject$.pipe(
      map((width: number) => {
        this.rightRailDesiredWidthPercentage = Math.min(
          Math.max(width, this.minimumPercentageWidthNumber),
          this.maximumPercentageWidthNumber
        );
        return `min( max( ${width}%, ${this.minimumFixedWidth}), min( ${this.maximumFixedWidth}, ${this.maximumPercentageWidth}))`;
      })
    );
    this.widthMinMaxDebounce$ = this.widthMinMax$.pipe(auditTime(3));
  }
  private disableIframePointerEvents(): void {
    this.disabledIframes = Array.from(document.getElementsByTagName("iframe"));
    this.disabledIframes.forEach((iframe: HTMLIFrameElement) => {
      iframe.style.setProperty("pointer-events", "none");
    });
  }

  private enableIframePointerEvents(): void {
    this.disabledIframes.forEach((iframe: HTMLIFrameElement) => {
      iframe.style.setProperty("pointer-events", null);
    });
  }

  public onResizeStart(event: ResizeEvent): void {
    this.disableIframePointerEvents();
    this.onResize(event);
    this.isResizingSubject$.next(true);
  }

  public onResizeEnd(event: ResizeEvent): void {
    this.enableIframePointerEvents();
    this.onResize(event);
    this.isResizingSubject$.next(false);
  }

  public IncrementWidth(): void {
    this.widthSubject$.pipe(take(1)).subscribe((oldWidth: number) => {
      if (oldWidth > this.maximumPercentageWidthNumber) {
        oldWidth = this.maximumPercentageWidthNumber;
      }
      const nextSize: number = oldWidth - this.incrementStepSize;
      if (nextSize > this.minimumPercentageWidthNumber) {
        this.widthSubject$.next(nextSize);
      }
    });
  }

  public DecrementWidth(): void {
    this.widthSubject$.pipe(take(1)).subscribe((oldWidth: number) => {
      if (oldWidth < this.minimumPercentageWidthNumber) {
        oldWidth = this.minimumPercentageWidthNumber;
      }
      const nextSize: number = oldWidth + this.incrementStepSize;
      if (nextSize < this.maximumPercentageWidthNumber) {
        this.widthSubject$.next(nextSize);
      }
    });
  }

  public onResize(event: ResizeEvent): void {
    if (event.rectangle.width)
      this.widthSubject$.next(
        (event.rectangle.width / document.documentElement.clientWidth) * 100
      );
  }

  public setDrawerOpen(): void {
    this.openedSubject$.next(true);
  }

  public setDrawerClosed(): void {
    this.openedSubject$.next(false);
  }
}
