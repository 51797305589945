/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from "@angular/core";
import { ErrorService } from "@core/models";
import { SplunkBuilder } from "@core/services/splunk-builder/splunk-builder";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";

export const loggingConstants = Object.freeze({
  appName: "TNC LossViewer Web",
  domain: "Claims",
  genericMsgTxt: "Angular Error",
  genericErrCd: "50000",
});

@Injectable({
  providedIn: "root",
})
export class DefaultErrorService implements ErrorService {
  constructor(
    private loggingService: LoggingService,
    private splunkBuilder: SplunkBuilder
  ) {}

  public handle(error: Error | undefined): Error | undefined {
    let result;

    if (error) {
      const message = this.splunkBuilder.buildError(
        error,
        loggingConstants.genericMsgTxt,
        loggingConstants.genericErrCd
      );
      this.loggingService.log(message, LoggedMessageLevel.Error);

      result = error;
    }

    return result;
  }
}
