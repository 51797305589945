import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  StyleManagerService,
  ThemeStorageService,
} from "@pgr-cla/core-ui-components";

@UntilDestroy()
@Injectable()
export class ThemeManagerService {
  public readonly defaultTheme: string = "light-blue.css";

  private fileManifest: Record<string, string> | null = null;

  constructor(
    private styleManagerService: StyleManagerService,
    private themeStorage: ThemeStorageService
  ) {}

  public initalizeTheming(): void {
    this.initializeDefaultTheme();
    this.listenForThemeUpdates();
  }

  public initializeNotesPopoutTheme(): void {
    this.updateNotesTheme();
    this.listenForNotesThemeUpdates();
  }

  private initializeDefaultTheme(): void {
    this.styleManagerService
      .setStyle(ThemeStorageService.storageKey, this.defaultTheme)
      .subscribe();
  }

  private listenForThemeUpdates(): void {
    this.themeStorage.theme$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateTheme());
  }

  private updateNotesTheme(): void {
    const themeName: string | null = localStorage.getItem(
      ThemeStorageService.storageKey
    );
    if (!themeName) return;
    this.styleManagerService
      .setStyle(ThemeStorageService.storageKey, `${themeName}.css`)
      .subscribe();
  }

  private listenForNotesThemeUpdates(): void {
    this.themeStorage.theme$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateNotesTheme());
  }

  private getThemeWithCheckSum(themeName: string): string | null {
    const noHashFileName: string = `${themeName}.css`;
    if (!this.fileManifest) return null;
    return this.fileManifest[noHashFileName];
  }

  private updateTheme(): void {
    const themeName: string | null = localStorage.getItem(
      ThemeStorageService.storageKey
    );
    if (!themeName) return;
    const checkSumTheme: string | null = this.getThemeWithCheckSum(themeName);
    if (!checkSumTheme) return;
    this.styleManagerService
      .setStyle(ThemeStorageService.storageKey, checkSumTheme)
      .subscribe();
    this.prioritizeHashedTheme(themeName, checkSumTheme);
  }

  private prioritizeHashedTheme(themeName: string, checkSumTheme: string) {
    const newTheme: Element | null = document.head.querySelector(
      `[href="${checkSumTheme}"]`
    );
    const oldTheme: Element | null = document.head.querySelector(
      `[href="${themeName}.css"]`
    );
    if (newTheme && oldTheme) newTheme.before(oldTheme);
  }
}
