<main role="main" class="overflow-y-auto h-full w-full">
  <cui-notification-banner
    *ngIf="diaryStateService.diaryError$ | async; let errorData"
    [actionable]="true"
    (action)="diaryStateService.retrieveCurrentClaimDiaries()"
    actionButtonText="Try Again"
    type="error"
    class="flex grow"
  >
    {{
      errorData?.statusText &&
      errorData?.statusText !== "Unknown Error" &&
      errorData.statusText !== "OK"
        ? errorData.statusText
        : "An unexpected error occurred. Please try again."
    }}
  </cui-notification-banner>
  <div class="px-xs pb-xs pt-xxs">
    <div class="w-full mb-xxs flex align-center">
      <h1 class="cui-h1" id="main-content-header" tabindex="-1">
        Claim Diaries
      </h1>
      <div class="grow"></div>
    </div>
    <mat-card class="p-0">
      <mat-card-header class="w-full flex items-center py-xs">
        <mat-checkbox #historyCheckbox data-pgr-id="history-checkbox"
          >Include History</mat-checkbox
        >
        <div class="grow"></div>
        <h2 class="cui-h3 px-xs" data-pgr-id="diarycountlabel">
          {{ diaryTable.dataSource?.filteredData.length }}
          {{
            diaryTable.dataSource?.filteredData.length === 1
              ? "Diary"
              : "Diaries"
          }}
        </h2>
      </mat-card-header>
      <mat-card-content class="overflow-x-auto table-header-top-border">
        <cla-diary-table
          #diaryTable
          [historyChanged$]="historyCheckbox.change.asObservable()"
        ></cla-diary-table>
      </mat-card-content>
    </mat-card>
  </div>
</main>
