import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  PartyScanResult,
  navigateAwayMessaging,
} from "@modules/shared/constants";
import { CUI_DIALOG_WIDTH } from "@pgr-cla/core-ui-components";
import { lastValueFrom, of } from "rxjs";
import { filter, pairwise, takeUntil, withLatestFrom } from "rxjs/operators";
import {
  ChsDataDetectionDialogComponent,
  ConfirmDialogComponent,
} from "../../components";
import { ConfirmDialogOptions } from "../../models/confirm-dialog-options.interface";

const { title, message, cancelButtonText, confirmButtonText } =
  navigateAwayMessaging;

@Injectable()
export class DialogService {
  private _dialogRef: MatDialogRef<unknown>;

  constructor(private _dialog: MatDialog) {}

  public openConfirmDialog(options: ConfirmDialogOptions): void {
    this._dialogRef = this._dialog.open(ConfirmDialogComponent, {
      data: {
        title: options.title ?? title,
        message: options.message ?? message,
        confirmButtonText: options.confirmButtonText ?? confirmButtonText,
        cancelButtonText: options.cancelButtonText ?? cancelButtonText,
        isLoading$: options.isLoading$,
        error$: options.error$,
      },
      disableClose: false,
      width: CUI_DIALOG_WIDTH.SMALL,
    });

    const dialogComponent = this._dialogRef
      .componentInstance as ConfirmDialogComponent;

    dialogComponent.continuePressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.continueFn();
      });

    dialogComponent.cancelPressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.cancelFn();
      });

    if (!!options.isLoading$ && !!options.isLoadingEndedFn) {
      options.isLoading$
        .pipe(
          pairwise(),
          filter((isLoading) => isLoading[0] && !isLoading[1]),
          withLatestFrom(options.error$ ?? of(null)),
          filter(([, error]) => !error),
          takeUntil(this._dialogRef.afterClosed())
        )
        .subscribe(() => (options.isLoadingEndedFn as () => void)());
    }
  }

  public async openConfirmDialogAsync<D, R>(
    options: ConfirmDialogOptions,
    dialog: ComponentType<D>
  ): Promise<R> {
    this._dialogRef = this._dialog.open(dialog, {
      data: {
        title: options.title,
        message: options.message,
        confirmButtonText: options.confirmButtonText,
        cancelButtonText: options.cancelButtonText,
      },
      disableClose: false,
      width: CUI_DIALOG_WIDTH.SMALL,
    });

    const dialogComponent = this._dialogRef
      .componentInstance as ConfirmDialogComponent;

    dialogComponent.continuePressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.continueFn();
      });

    dialogComponent.cancelPressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.cancelFn();
      });

    return await lastValueFrom(this._dialogRef.afterClosed());
  }

  public async openChsDialog(
    options: ConfirmDialogOptions,
    chsWarnings: string[]
  ): Promise<PartyScanResult> {
    this._dialogRef = this._dialog.open(ChsDataDetectionDialogComponent, {
      width: CUI_DIALOG_WIDTH.SMALL,
      disableClose: true,
      data: { chsWarnings },
    });

    const dialogComponent = this._dialogRef
      .componentInstance as ChsDataDetectionDialogComponent;

    dialogComponent.continuePressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.continueFn();
      });

    dialogComponent.cancelPressed
      .pipe(takeUntil(this._dialogRef.afterClosed()))
      .subscribe(() => {
        options.cancelFn();
      });

    return await lastValueFrom(this._dialogRef.afterClosed());
  }

  public closeDialog<T>(value?: T): void {
    this._dialogRef.close(value);
  }
}
