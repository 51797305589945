<cui-dialog [showClose]="true" [showFooter]="false" (close)="onClose()">
  <div
    mat-dialog-title
    dialog-header
    class="mb-0"
    data-pgr-id="headerVersionSelector"
  >
    Version History
  </div>
  <div mat-dialog-content cuiScrollIndicator dialog-content class="pb-4">
    <table mat-table [dataSource]="contentVersions" class="w-full">
      <ng-container matColumnDef="versionNumber">
        <th mat-header-cell class="version-num-cell p-0" *matHeaderCellDef>
          Version
        </th>
        <td
          mat-cell
          *matCellDef="let version; let i = index"
          class="text-center pl-0 version-num-cell"
        >
          {{ displayVersion(i) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dateModified">
        <th mat-header-cell class="version-link-cell" *matHeaderCellDef>
          Date Modified
        </th>
        <td mat-cell *matCellDef="let version" class="version-link-cell">
          <button
            data-pgr-id="btnVersionDateModified"
            class="cui-link mat-button version-link p-0"
            (click)="openVersion(version.versionNbr)"
          >
            {{ version.createdDateTimeFormatted }}
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="modifiedBy">
        <th mat-header-cell *matHeaderCellDef>Modified by</th>
        <td mat-cell *matCellDef="let version">
          <cla-contact-card
            [repInfo]="
              (coreUiStateService.repSummaries$ | async)[
                version.createdByUserId
              ]
            "
            [tpxId]="version.createdByUserId"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</cui-dialog>
