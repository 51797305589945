import { Injectable } from "@angular/core";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

@Injectable()
export class EnterClaimOnErrorService {
  public canEnterClaimOnError$: Observable<boolean>;

  constructor(private configService: ConfigurationService) {
    this.canEnterClaimOnError$ = this.configService.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== undefined && config !== null
      ),
      map((config: ClaimsSummaryConfigInfo) => config.canEnterClaimOnError)
    );
  }
}
