import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { UserState } from "./user.reducer";
import * as userActions from "./user.actions";
import { Observable } from "rxjs";
import {
  selectCanViewPrivateFields,
  selectIsManager,
  selectIsPipAuthorized,
  selectOrgCode,
  selectPhysicalDamagePaymentAuthority,
  selectRepCode,
  selectUser,
  selectUserError,
  selectUserLoading,
} from "./user.selectors";
import { HttpErrorResponse } from "@angular/common/http";
import { User } from "@modules/core/models";

@Injectable()
export class UserStateService {
  constructor(private store$: Store<UserState>) {}

  public user$: Observable<User | null> = this.store$.select(selectUser);
  public isUserLoading$: Observable<boolean> =
    this.store$.select(selectUserLoading);
  public userError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(selectUserError);

  public repCode$: Observable<string | null> =
    this.store$.select(selectRepCode);
  public orgCode$: Observable<string | null> =
    this.store$.select(selectOrgCode);
  public isManager$: Observable<boolean | null> =
    this.store$.select(selectIsManager);
  public isPipAuthorized$: Observable<boolean | null> = this.store$.select(
    selectIsPipAuthorized
  );
  public physicalDamagePaymentAuthority$: Observable<number | null> =
    this.store$.select(selectPhysicalDamagePaymentAuthority);
  public canViewPrivateFields$: Observable<boolean> = this.store$.select(
    selectCanViewPrivateFields
  );

  public getCurrentUser(): void {
    this.store$.dispatch(userActions.getCurrentUser());
  }
}
