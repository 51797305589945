import { Pipe, PipeTransform } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { IContentSummaryItem } from "../../../models/content-summary";

@Pipe({
  name: "claCheckboxContentItemAriaLabel",
})
export class CheckboxContentItemAriaLabelPipe implements PipeTransform {
  // The first paramter in this method is showing as not being used, however it is necessary to trigger change detection on change of the selections.
  // If we do not pass in a different primitive value here to kick off change detection, the pipe will never update because the references to the selection
  // model would not change.
  public transform(
    selectedItemsLength: number,
    selectionModel: SelectionModel<string>,
    row: IContentSummaryItem,
    rowIndex: number
  ): string {
    return `${selectionModel.isSelected(row.id) ? "deselect" : "select"} row ${
      Number.isInteger(rowIndex) ? rowIndex + 1 : ""
    }`;
  }
}
