<div class="searchable-table" id="table-container">
  <p id="clickableTableRowDescription" class="screen-reader-only">
    Press Enter on a table row to open preview
  </p>
  <table
    mat-table
    [dataSource]="dataSource"
    class="cui-table"
    data-pgr-id="tblContentSummary"
    matSortActive="Date and Time Received"
    matSortDirection="desc"
    matSortStart="desc"
    matSort
  >
    <!-- Select Column -->
    <ng-container matColumnDef="Is Selected" sticky>
      <th
        mat-header-cell
        class="mat-cell-extra-thin cui-cell-checkbox"
        *matHeaderCellDef
      >
        <div class="mat-cell-content">
          <mat-checkbox
            class="master-toggle-checkbox"
            (keydown.enter)="onCheckboxEnter($event)"
            (change)="$event ? masterToggle() : null"
            [checked]="isAllSelected$ | async"
            [indeterminate]="isSelectAllIndeterminate$ | async"
            [aria-label]="
              selection.selected.length
                | claContentItemAllAriaLabel: currentPageData:selection
            "
          >
          </mat-checkbox>
        </div>
      </th>
      <td
        mat-cell
        class="mat-cell-extra-thin cui-cell-checkbox"
        *matCellDef="let row; let i = index"
      >
        <mat-checkbox
          (keydown.enter)="onCheckboxEnter($event)"
          (click)="$event.stopPropagation()"
          (change)="handleChange(row)"
          [checked]="
            selection.selected.length
              | claCheckboxContentItemIsSelected: selection:row
          "
          [aria-label]="
            selection.selected.length
              | claCheckboxContentItemAriaLabel: selection:row:i
          "
        >
        </mat-checkbox>
        <div class="mat-cell-content"></div>
      </td>
    </ng-container>

    <!-- Date and Time Received Column -->
    <ng-container matColumnDef="Date and Time Received">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-wide"
        *matHeaderCellDef
      >
        <cla-table-header-label
          headerName="Date and Time Received"
          [filterActive]="
            filters['originDateTime'].minDate ||
            filters['originDateTime'].maxDate
          "
        ></cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide" *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.originDateTimeFormatted || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Versions Column -->
    <ng-container matColumnDef="Versions">
      <th mat-header-cell class="mat-cell-thin" *matHeaderCellDef>
        <cla-table-header-label
          headerName="Versions"
          [filterActive]="filters['versions'].appliedFilters.length"
        >
        </cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-thin" *matCellDef="let element">
        <div
          class="mat-cell-content"
          *ngIf="
            element.versions[element.versions.length - 1].versionNbr === 0;
            else versionSelector
          "
        >
          {{ "-" }}
        </div>
        <ng-template #versionSelector>
          <button
            data-pgr-id="btnVersions"
            class="mat-button version-link"
            (click)="tableActionService.openVersionSelector(element)"
            [cuiInteractionEvent]="
              contentSummaryEventService.clickAction(actionNames.versions)
            "
          >
            {{ element.versions[element.versions.length - 1].versionNbr }}
          </button>
        </ng-template>
      </td>
    </ng-container>

    <!-- Party/Property Column -->
    <ng-container matColumnDef="Party/Property">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-wide"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Party/Property"
          [filterActive]="filters['partyOrPropertyName'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide" *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.partyOrPropertyName || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="Category">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        mat-sort-header
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Category"
          [filterActive]="filters['contentCategoryDesc'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.contentCategoryDesc || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="Type">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-wide"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Type"
          [filterActive]="filters['contentTypeDesc'].appliedFilters.length"
        >
        </cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide" *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.contentTypeDesc || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Delivery Method -->
    <ng-container matColumnDef="Delivery Method">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-wide"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Delivery Method"
          [filterActive]="filters['deliveryMethodDesc'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide" *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.deliveryMethodDesc || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Tags Column -->
    <ng-container matColumnDef="Tags">
      <th mat-header-cell class="mat-cell-wide" *matHeaderCellDef>
        <cla-table-header-label
          headerName="Tags"
          [filterActive]="filters['tags'].appliedFilters.length"
        >
        </cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide p-2" *matCellDef="let element">
        <div
          class="mat-cell-content"
          *ngIf="element.tags.length === 0; else tagsSelector"
        >
          {{ "-" }}
        </div>
        <ng-template #tagsSelector>
          <cla-tags-cell class="w-full" [tags]="element.tags"></cla-tags-cell>
        </ng-template>
      </td>
    </ng-container>

    <!-- Created By Column -->
    <ng-container matColumnDef="Created By">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-wide"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Created By"
          [filterActive]="filters['createdByDisplayName'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-wide" *matCellDef="let element">
        <div
          class="mat-cell-content"
          *ngIf="!element.createdByUserId; else createdByUserId"
        >
          {{ "-" }}
        </div>
        <ng-template #createdByUserId>
          <div class="mat-cell-content">
            <cla-contact-card
              (click)="$event.stopPropagation()"
              [repInfo]="
                (coreUiStateService.repSummaries$ | async)[
                  element.createdByUserId
                ]
              "
              [tpxId]="element.createdByUserId"
              [isLoadingRepInfo]="
                coreUiStateService.isLoadingRepSummaries$ | async
              "
            ></cla-contact-card>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Number of Items Column -->
    <ng-container matColumnDef="Number of Items">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-thin"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Number of Items"
          [filterActive]="filters['numberOfItems'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-thin" *matCellDef="let element">
        <div class="mat-cell-content">{{ element.numberOfItems || "-" }}</div>
      </td>
    </ng-container>

    <!-- Medical Bill Number Column -->
    <ng-container matColumnDef="Medical Bill Number">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Medical Bill Number"
          [filterActive]="filters['medicalBillNumber'].appliedFilters.length"
        ></cla-table-header-label>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="mat-cell-content">
          {{ element.medicalBillNumber || "-" }}
        </div>
      </td>
    </ng-container>

    <!-- Origin Column -->
    <ng-container matColumnDef="Origin">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Origin"
          [filterActive]="filters['originDesc'].appliedFilters.length"
        >
        </cla-table-header-label>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="mat-cell-content">{{ element.originDesc || "-" }}</div>
      </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <th
        mat-header-cell
        mat-sort-header
        class="mat-cell-thin"
        *matHeaderCellDef
        [cuiInteractionEvent]="
          contentSummaryEventService.clickAction(actionNames.sort)
        "
      >
        <cla-table-header-label
          headerName="Status"
          [filterActive]="filters['status'].appliedFilters.length"
        >
        </cla-table-header-label>
      </th>
      <td mat-cell class="mat-cell-thin" *matCellDef="let element">
        <div class="mat-cell-content">{{ element.status || "-" }}</div>
      </td>
    </ng-container>

    <!-- Empty State Footer -->
    <ng-container matColumnDef="empty">
      <mat-footer-cell *matFooterCellDef>
        <cui-empty-state
          class="m-auto"
          icon="search_off"
          primaryText="No matches for the applied filters."
          secondaryText="Try a different search/filter, or:"
          actionText="Reset Filters"
          (actionTriggered)="contentSummaryViewService.clearSearchAndFilters()"
          aria-label="No matches for the applied filters. Reset filters?"
        >
        </cui-empty-state>
      </mat-footer-cell>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      tabindex="0"
      aria-roledescription="button"
      aria-details="clickableTableRowDescription"
      (singleClickHandled)="openPreview(row); selectRow(i)"
      cla-click-handler
      [cuiInteractionEvent]="
        contentSummaryEventService.clickAction(actionNames.preview)
      "
      (doubleClickHandled)="launchViewer(row); selectRow(i)"
      (keydown.enter)="rowKeyboardHandler(row, $event); selectRow(i)"
      (keydown.space)="rowKeyboardHandler(row, $event); selectRow(i)"
      (keydown.spacebar)="rowKeyboardHandler(row, $event); selectRow(i)"
      [childBlacklist]="['MAT-CHECKBOX', 'CLA-EXPANDING-LINK', 'CUI-POPOVER']"
      [ngClass]="
        contentSummaryStoreService.selectedItems$
          | async
          | claRowClass: element.id:previewedItem
      "
      *matRowDef="
        let row;
        let element;
        let i = index;
        columns: displayedColumns
      "
    ></tr>
    <tr
      class="empty-footer"
      mat-footer-row
      *matFooterRowDef="['empty']"
      [hidden]="resultsTotal || (isContentDetailsRequestInFlight$ | async)"
    ></tr>
  </table>
</div>
