<cui-side-sheet>
  <cui-side-sheet-header
    closeButtonLabel="close columns pane"
    aria-label="Displayed Columns: Use buttons below to hide/show columns"
    (close)="handleClose()"
  >
    Columns
  </cui-side-sheet-header>
  <cui-side-sheet-content data-pgr-id="columns">
    <div class="flex items-center justify-between pb-5">
      <div class="ml-auto">
        <button
          mat-stroked-button
          color="primary"
          (click)="resetColumns()"
          data-pgr-id="btnResetColumns"
        >
          Reset
        </button>
      </div>
    </div>
    <div
      cdkDropList
      cla-a11y-drop-list
      #a11yList
      (cdkDropListDropped)="onDrop($event)"
      (a11yDropListReordered)="onReorder($event)"
      (a11yDropListDropped)="onToggle($event)"
    >
      <div
        cdkDrag
        cla-a11y-drag
        #a11yColumn
        *ngFor="let column of columns"
        class="column-slide-toggle mat-elevation-z2"
        aria-live="polite"
        [itemName]="column.title"
        [primaryKey]="column.id"
      >
        <mat-icon class="ml-2 mr-3" aria-hidden="true">drag_handle</mat-icon>
        <mat-slide-toggle
          class="w-full"
          [checked]="column.visible"
          (change)="toggleColumn(column.title)"
          [attr.data-pgr-id]="column.title + ' Toggle'"
        >
          {{ column.title }}
        </mat-slide-toggle>
      </div>
    </div>
  </cui-side-sheet-content>
</cui-side-sheet>
