import { Pipe, PipeTransform } from "@angular/core";

import { SubroFeaStatCodes } from "@modules/subrogation-summary/constants/subrogation.constants";

@Pipe({
  name: "claStatusIndicatorTextPipe",
})
export class StatusIndicatorTextPipe implements PipeTransform {
  transform(statusCode: string, closeReason: string): string {
    let result = "";
    switch (statusCode) {
      case SubroFeaStatCodes.OnHold:
        result = "On Hold";
        break;
      case SubroFeaStatCodes.Closed:
        result = closeReason === "15" ? "Needs Info" : "Closed";
        break;
      default:
        result = "Open";
        break;
    }
    return result;
  }
}
