import { LiveAnnouncer } from "@angular/cdk/a11y";
import { MediaMatcher } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import {
  ActivatedRoute,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { EventDispatchService } from "@pgr-cla/cla-analytics";
import { combineLatest, Observable } from "rxjs";
import { filter, map, skip, takeWhile, withLatestFrom } from "rxjs/operators";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SideSheetService } from "@pgr-cla/core-ui-components";

import { contentSummaryWindows, EFFConstants } from "../../../constants";
import { IContentDetailsGetRequest } from "../../../models";

import { StopwatchUtil } from "@modules/shared";

import { MatPaginator } from "@angular/material/paginator";
import { UserContextService } from "@modules/core";
import { ClaimDetailsStateService } from "@modules/core/state";
import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";
import { PaginatorService } from "@modules/electronic-file-folder/services/content-summary/paginator/paginator.service";
import { AppMessageBus } from "@modules/electronic-file-folder/services/core/app-message-bus/app-message-bus.service";
import { DocSetStoreFacadeService } from "@modules/electronic-file-folder/services/doc-set/doc-set-store-facade/doc-set-store-facade.service";
import { WindowService } from "@pgr-cla/cla-window";
import { isIE } from "../../../helpers";

@UntilDestroy()
@Component({
  templateUrl: "content-summary-view.component.html",
  styleUrls: ["./content-summary-view.component.scss"],
  providers: [PaginatorService],
})
export class ContentSummaryViewComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  public actionNames = EFFConstants.clickActionNames;
  public inPagination: boolean;
  public docSetDetailsInFlight$: Observable<boolean>;
  public isContentDetailsRequestInFlight$: Observable<boolean>;

  public isLoading$: Observable<boolean>;

  public isSearching$: Observable<boolean>;

  public mediaQuery: MediaQueryList;
  public mediaQueryListener: () => void;
  @ViewChild(MatSidenav, { static: true }) public sideSheet: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private _isFirstLoad = true;
  claimNumber: string;

  constructor(
    public contentSummaryStoreService: ContentSummaryStoreService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public sideSheetService: SideSheetService,
    private announcer: LiveAnnouncer,
    public contentSummaryViewService: ContentSummaryViewService,
    public docSetStoreFacadeService: DocSetStoreFacadeService,
    public userContextService: UserContextService,
    public router: Router,
    private windowService: WindowService,
    public contentSummaryEventService: ContentSummaryEventService,
    private appMessageBus: AppMessageBus,
    private _paginatorService: PaginatorService,
    private eventDispatchService: EventDispatchService,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {}

  public ngOnDestroy(): void {
    // I have no idea why, but removing an event listener on the mediaQuery on IE throws an error
    // for now, we're going to continue to use the deprecated method
    if (isIE()) {
      this.mediaQuery.removeListener(this.mediaQueryListener);
    } else {
      this.mediaQuery.removeEventListener("change", this.mediaQueryListener);
    }

    this.claimDetailsStateService.onSetPageScrollingEnabled(true);
  }

  public ngAfterViewInit(): void {
    this._paginatorService.setMatPaginator(this.paginator);
  }

  public ngOnInit(): void {
    this.docSetDetailsInFlight$ =
      this.docSetStoreFacadeService.docSetDetails.isInFlight();
    this.isContentDetailsRequestInFlight$ = this.contentSummaryStoreService
      .getContentDetails()
      .pipe(map((x) => x.inFlight));
    this.isLoading$ = combineLatest([
      this.isContentDetailsRequestInFlight$,
      this.contentSummaryStoreService.getIsLoading(),
      this.docSetDetailsInFlight$,
    ]).pipe(map((current: boolean[]) => current.includes(true)));

    this.isSearching$ = combineLatest([
      this.contentSummaryStoreService.getIsLoading(),
      this.isContentDetailsRequestInFlight$,
    ]).pipe(map((current: boolean[]) => current.includes(true)));

    this.isLoading$
      .pipe(
        skip(1),
        filter((x) => !x),
        withLatestFrom(this.contentSummaryViewService.filteredTotal$),
        takeWhile(() => {
          return this._isFirstLoad;
        })
      )
      .subscribe(([,]: [boolean, number]) => {
        this._isFirstLoad = false;

        const loadTime = StopwatchUtil.getElapsedSeconds(
          (this.windowService.nativeWindow as any).pageLoadStartTime
        );

        this.contentSummaryEventService.initialize(loadTime);
      });

    this.appMessageBus
      .getStream(contentSummaryWindows.move)
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        this.contentSummaryStoreService.onContentDetailsRefreshRequested()
      );

    this.appMessageBus
      .getStream(contentSummaryWindows.outboundContent)
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        this.contentSummaryStoreService.onContentDetailsRefreshRequested()
      );

    this.claimNumber = this.route.snapshot.params["claimNumber"];
    this.contentSummaryStoreService.onContentDetailsRequested({
      request: {
        claimNumber: this.claimNumber,
      } as IContentDetailsGetRequest,
    });

    this.sideSheetService.register("EFFMain", this.sideSheet);
    this.initMediaQuery();

    this.contentSummaryViewService.selectedTotal$
      .pipe(untilDestroyed(this))
      .subscribe((selectedTotal: number) => {
        this.announcer.announce(
          `${selectedTotal} ${selectedTotal === 1 ? "Result" : "Results"}`
        );
      });

    const pageView = this.contentSummaryEventService.summaryPageView();
    this.eventDispatchService.trackPageView(pageView);

    this.contentSummaryStoreService
      .getRefreshInProgress()
      .pipe(
        filter((x) => x),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.contentSummaryStoreService.onContentSummaryToggleAllSelectedItems({
          selectAllContentItems: false,
        });

        const tree: UrlTree = this.router.parseUrl(this.router.url);
        const sideSheet = this.sideSheetService.get("EFFMain");
        let isPreview = false;

        if (sideSheet && sideSheet.isOpen()) {
          isPreview = this.sidesheetIsPreview(tree);
        }

        if (isPreview && sideSheet) {
          sideSheet.close();
        }
      });

    this.claimDetailsStateService.onSetPageScrollingEnabled(false);

    combineLatest([
      this.isLoading$,
      this.contentSummaryStoreService.getIsFilterResetInProgress(),
    ]).subscribe(([isLoading, isFilterResetInProgress]: [boolean, boolean]) => {
      if (isFilterResetInProgress && !isLoading) {
        this.announcer.announce("Filters reset. All files shown.");
      }
    });
  }

  public sidesheetIsPreview(tree: UrlTree): boolean {
    const sidesheet = tree.root.children[PRIMARY_OUTLET].children["sideSheet"];
    const isPreview = sidesheet.segments.reduce(
      (accumulator: boolean, current: UrlSegment) => {
        let result = accumulator;

        if (current.path === "preview") {
          result = true;
        }

        return result;
      },
      false
    );

    return isPreview;
  }

  public toggleDrawer(path: string): void {
    const isRouteChange = this.hasRouteChanged(this.route, path);
    const sideSheetOpen = this.sideSheetIsOpen("EFFMain");
    const sideSheet = this.sideSheetService.get("EFFMain");

    if ((!isRouteChange || !sideSheetOpen) && sideSheet) {
      sideSheet.toggle();
    }
  }

  private hasRouteChanged(route: ActivatedRoute, path: string) {
    return (
      !route.snapshot.children[1] ||
      route.snapshot.children[1].url[0].path !== path
    );
  }

  private initMediaQuery(): void {
    this.mediaQuery = this.media.matchMedia("(max-width: 1200px)");
    this.mediaQueryListener = () => this.changeDetectorRef.detectChanges();

    // I have no idea why, but adding an event listener on the mediaQuery on IE throws an error
    // for now, we're going to continue to use the deprecated method
    if (isIE()) {
      this.mediaQuery.addListener(this.mediaQueryListener);
    } else {
      this.mediaQuery.addEventListener("change", this.mediaQueryListener);
    }
  }
  private sideSheetIsOpen(name: string) {
    let result = false;
    const sideSheet = this.sideSheetService.get(name);

    if (sideSheet) {
      result = sideSheet.isOpen();
    }

    return result;
  }
}
