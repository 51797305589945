import { Injectable } from "@angular/core";
import { UserContext } from "@modules/core/models";
import { ClaimManagementApiService } from "@modules/core/services/claim-management-api";
import { Observable } from "rxjs";

@Injectable()
export class UserContextApiService {
  constructor(private apiService: ClaimManagementApiService) {}

  getUserContext(): Observable<UserContext> {
    return this.apiService.get<UserContext>("/v1/permissions");
  }
}
