import { Pipe, PipeTransform } from "@angular/core";
import { CodeTable, CodeTableMappingValue } from "@claimssummaryshared/models";
import { PaymentEventTypesCodeTableValue } from "@modules/core/models";

@Pipe({
  name: "claPaymentEventType",
})
export class PaymentEventTypePipe implements PipeTransform {
  public transform(
    value: string,
    paymentEventCodes:
      | CodeTable<PaymentEventTypesCodeTableValue>
      | null
      | undefined
  ): string {
    if (!paymentEventCodes) {
      return value;
    }

    return this.flattenPaymentEventTypes(paymentEventCodes)[value] || value;
  }

  private flattenPaymentEventTypes(
    codeTable: CodeTable<PaymentEventTypesCodeTableValue>
  ): CodeTableMappingValue {
    return codeTable.values.reduce(
      (
        accumulator: CodeTableMappingValue,
        codeItem: PaymentEventTypesCodeTableValue
      ) => {
        return {
          ...accumulator,
          [`${codeItem.payEvntTypId}`]: codeItem.payEvntTypDesc as string,
        };
      },
      {}
    );
  }
}
