<div class="vehicle-container">
  <div *ngFor="let vehicleInfo of displayVehicles">
    <h3 class="cui-h3 mb-xxs" [attr.data-pgr-id]="'lblVehicleModel'">
      Policy Vehicle: {{ vehicleInfo.modelName }}
      {{
        vehicleInfo.statedAmount > 0
          ? "(Stated Amount: " +
            (vehicleInfo.statedAmount | currency: "USD") +
            ")"
          : ""
      }}
    </h3>
    <div class="coverage-vehicle-desc p-xxs mb-xxs rounded">
      <cui-data-list
        [border]="false"
        class="coverages-no-wrap"
        *ngIf="displayVehicles != null"
      >
        <cui-data-list-row
          *ngFor="let vehCoverage of vehicleInfo.coverage; let i = index"
          [label]="vehCoverage.description"
        >
          <div [attr.data-pgr-id]="'lblVehCoverageLimitDescription.' + i">
            {{ vehCoverage.limitsDescription }}
          </div>
        </cui-data-list-row>
      </cui-data-list>
    </div>
  </div>
</div>
