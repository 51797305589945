import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { INotification } from "../../../models";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  public notifications$: Observable<INotification[]>;
  private _notifications: INotification[] = [];
  private notificationsSubject$: BehaviorSubject<INotification[]> =
    new BehaviorSubject<INotification[]>([]);

  constructor() {
    this.notifications$ = this.notificationsSubject$.asObservable();
  }

  public addNotification(notification: INotification): void {
    if (this._notifications.indexOf(notification) === -1) {
      this._notifications.push(notification);
      this.notificationsSubject$.next(this._notifications);
    }
  }

  public dismissNotification(notification: INotification): void {
    this._notifications = this._notifications.filter((n) => n !== notification);
    this.notificationsSubject$.next(this._notifications);
  }
}
