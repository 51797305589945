<nav mat-tab-nav-bar #tabBar [tabPanel]="tabPanel">
  <a
    mat-tab-link
    [routerLink]="['claim-summary']"
    routerLinkActive="active"
    #alertsLinkSummary="routerLinkActive"
    [active]="alertsLinkSummary.isActive"
    [class.alerts-bar-active]="alertsLinkSummary.isActive"
  >
    Summary
    <span class="screen-reader-only">{{
      alertsLinkSummary.isActive ? "selected" : ""
    }}</span>
  </a>
  <!-- <a
    mat-tab-link
    [routerLink]="['diary']"
    routerLinkActive="active"
    #alertsLinkDiary="routerLinkActive"
    [active]="alertsLinkDiary.isActive"
  >
    Claim Diaries
    <span class="screen-reader-only">{{
      alertsLinkDiary.isActive ? 'selected' : ''
    }}</span>
  </a> -->
  <a
    mat-tab-link
    [routerLink]="['coverage']"
    routerLinkActive="active"
    #alertsLinkCoverage="routerLinkActive"
    [active]="alertsLinkCoverage.isActive"
    [class.alerts-bar-active]="alertsLinkCoverage.isActive"
  >
    Coverage & Policy
    <span class="screen-reader-only">{{
      alertsLinkCoverage.isActive ? "selected" : ""
    }}</span>
  </a>
  <a
    mat-tab-link
    [routerLink]="['content-summary']"
    routerLinkActive="active"
    #alertsLinkContentSummary="routerLinkActive"
    [active]="alertsLinkContentSummary.isActive"
    [class.alerts-bar-active]="alertsLinkContentSummary.isActive"
    aria-label="E F F"
  >
    EFF
    <span class="screen-reader-only">{{
      alertsLinkContentSummary.isActive ? "selected" : ""
    }}</span>
  </a>
  <a
    mat-tab-link
    [routerLink]="['feature']"
    routerLinkActive="active"
    #alertsLinkFeature="routerLinkActive"
    [active]="alertsLinkFeature.isActive"
    [class.alerts-bar-active]="alertsLinkFeature.isActive"
  >
    Feature
    <span class="screen-reader-only">{{
      alertsLinkFeature.isActive ? "selected" : ""
    }}</span>
  </a>
  <a
    mat-tab-link
    [routerLink]="['feature-history']"
    routerLinkActive="active"
    #alertsLinkHistory="routerLinkActive"
    [active]="alertsLinkHistory.isActive"
    [class.alerts-bar-active]="alertsLinkHistory.isActive"
  >
    History
    <span class="screen-reader-only">{{
      alertsLinkHistory.isActive ? "selected" : ""
    }}</span>
  </a>
  <a
    mat-tab-link
    [routerLink]="['payments']"
    routerLinkActive="active"
    #alertsLinkPayments="routerLinkActive"
    [active]="alertsLinkPayments.isActive"
    [class.alerts-bar-active]="alertsLinkPayments.isActive"
  >
    Payments
    <span class="screen-reader-only">{{
      alertsLinkPayments.isActive ? "selected" : ""
    }}</span>
  </a>
  <a
    mat-tab-link
    [routerLink]="['subrogation']"
    routerLinkActive="active"
    #alertsLinkSubrogation="routerLinkActive"
    [active]="alertsLinkSubrogation.isActive"
    [class.alerts-bar-active]="alertsLinkSubrogation.isActive"
  >
    Subrogation
    <span class="screen-reader-only">{{
      alertsLinkSubrogation.isActive ? "selected" : ""
    }}</span>
  </a>
</nav>
<!-- TODO: this should really wrap the router outlet, but it is many levels of depth away -->
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
