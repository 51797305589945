import { Action, createReducer, on } from "@ngrx/store";
import { initialState } from "@notes/constants";
import { NotesState, SortDirection } from "@notes/models";
import { NotesActions } from "@notes/state/notes.actions";

const _notesReducer = createReducer(
  initialState,
  on(NotesActions.onSetNoteId, (state, { noteId }) => ({ ...state, noteId })),
  on(
    NotesActions.onSetNoteListSelectedTabIndex,
    (state, { selectedTabIndex }) => ({
      ...state,
      noteListSelectedTabIndex: selectedTabIndex,
    })
  ),
  on(
    NotesActions.onSetAllowedCategoryCodes,
    (state, { allowedCategoryCodes }) => ({ ...state, allowedCategoryCodes })
  ),

  on(NotesActions.onGetCategoriesRequest, (state) => ({
    ...state,
    isLoadingCategories: true,
    error: { message: "" },
  })),
  on(NotesActions.onGetCategoriesRequestSuccess, (state, { categories }) => ({
    ...state,
    categories,
    isLoadingCategories: false,
    error: { message: "" },
  })),
  on(NotesActions.onGetCategoriesRequestFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingCategories: false,
    categories: [],
  })),

  on(NotesActions.onGetNotesRequest, (state) => ({
    ...state,
    isLoadingNotes: true,
    error: { message: "" },
  })),
  on(NotesActions.onGetNotesRequestSuccess, (state, { notes }) => ({
    ...state,
    notes,
    filteredNoteIds: [...notes, ...state.journals].map((k) => k.id),
    isLoadingNotes: false,
    error: { message: "" },
  })),
  on(NotesActions.onGetNotesRequestFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingNotes: false,
    notes: [],
  })),

  on(NotesActions.onGetNoteRequest, (state) => ({
    ...state,
    isLoadingNotes: true,
    error: { message: "" },
  })),
  on(NotesActions.onGetNoteRequestSuccess, (state, { note }) => ({
    ...state,
    note,
    isLoadingNotes: false,
    error: { message: "" },
  })),
  on(NotesActions.onGetNoteRequestFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingNotes: false,
    note: null,
  })),

  on(NotesActions.onGetJournalsRequest, (state) => ({
    ...state,
    isLoadingJournals: true,
    isErroredJournals: false,
    error: { message: "" },
  })),
  on(NotesActions.onGetJournalsRequestSuccess, (state, { journals }) => ({
    ...state,
    journals,
    filteredNoteIds: [...journals, ...state.notes].map((k) => k.id),
    isLoadingJournals: false,
    error: { message: "" },
  })),
  on(NotesActions.onGetJournalsRequestFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingJournals: false,
    isErroredJournals: true,
    journals: [],
  })),

  on(NotesActions.onSetFilterCriteria, (state, { filterCriteria }) => ({
    ...state,
    filterCriteria: filterCriteria ?? initialState.filterCriteria,
  })),
  on(NotesActions.onSetFilteredNotesRequest, (state) => ({
    ...state,
    isLoadingNotes: true,
  })),
  on(
    NotesActions.onSetFilteredNotesRequestSuccess,
    (state, { filteredNoteIds }) => ({
      ...state,
      filteredNoteIds,
      isLoadingNotes: false,
    })
  ),
  on(NotesActions.onToggleSortDirectionRequest, (state) => ({
    ...state,
    isLoadingNotes: true,
  })),
  on(NotesActions.onToggleSortDirectionRequestSuccess, (state) => ({
    ...state,
    isLoadingNotes: false,
    sortDirection:
      state.sortDirection === "asc"
        ? ("desc" as SortDirection)
        : ("asc" as SortDirection),
  })),

  on(NotesActions.onCloseNoteWindow, (state) => ({
    ...state,
    shouldCloseNoteWindow: true,
    isLoadingNotes: false,
  })),

  on(NotesActions.onSetIsPopout, (state) => ({
    ...state,
    isPopout: true,
  }))
);

export const notesReducer = (state: NotesState, action: Action): NotesState => {
  return _notesReducer(state, action);
};
