import { Pipe, PipeTransform } from "@angular/core";

import {
  PartySelection,
  AdverseParty,
  Party,
  PartyContactInfo,
  PersonName,
} from "@modules/subrogation-summary/models";

@Pipe({
  name: "claPartyNamePipe",
})
export class PartyNamePipe implements PipeTransform {
  transform(
    party: Party | PartySelection | AdverseParty | PartyContactInfo
  ): string {
    if (party?.businessName) {
      return party.businessName;
    }
    if (party?.partyName) {
      return this.buildName(party.partyName);
    }
    return "";
  }

  buildName(nameParts: PersonName) {
    const { first, last, middleInitial, suffix } = nameParts;
    let name = `${last}, ${first}`;
    name = middleInitial ? `${name} ${middleInitial}` : name;
    return suffix ? `${name}, ${suffix}` : name;
  }
}
