import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Diary, DiaryCalenderCountData, DiaryTableData } from "../models";

export const retrieveCurrentClaimDiaries = createAction(
  "[Claim Diaries API] Get Current Claim Diaries",
  props<{ keepExistingDiaries: boolean }>()
);

export const retrieveClaimDiaries = createAction(
  "[Claim Diaries API] Get Claim Diaries",
  props<{ claimNumber: string; keepExistingDiaries: boolean }>()
);

export const retrieveClaimDiariesSuccess = createAction(
  "[Claim Diaries API] Get Claim Diaries Success",
  props<{ diaries: Diary[] }>()
);

export const retrieveClaimDiariesError = createAction(
  "[Claim Diaries API] Get Claim Diaries Error",
  props<{ error: HttpErrorResponse }>()
);

export const setMappedTableData = createAction(
  "[Claim Diaries] Set Mapped Diary Table Data",
  props<{ diaryTableData: DiaryTableData[] }>()
);

export const getClaimDiaryCountData = createAction(
  "[Claim Diaries API] Get Claim Diary Count Data"
);

export const getClaimDiaryCountDataSuccess = createAction(
  "[Claim Diaries API] Get Claim Diary Count Data Success",
  props<{ diaryCalenderCountData: DiaryCalenderCountData }>()
);

export const getClaimDiaryCountDataError = createAction(
  "[Claim Diaries API] Get Claim Diary Count Data Error",
  props<{ error: HttpErrorResponse }>()
);
