import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ScreenSizeService } from "@modules/claims-landing/services";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { Diary } from "@modules/diary/models";
import { DiaryTableData } from "@modules/diary/models/diary-table-data";
import { DiaryStateService } from "@modules/diary/services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "cla-diary-table",
  templateUrl: "./diary-table.component.html",
  styleUrls: ["./diary-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiaryTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() historyChanged$: Observable<MatCheckboxChange>;

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<DiaryTableData> =
    new MatTableDataSource<DiaryTableData>([]);

  constructor(
    public diaryStateService: DiaryStateService,
    public cdr: ChangeDetectorRef,
    public coreUiStateService: CoreUiExtensionsStateService,
    public screenSizeService: ScreenSizeService
  ) {}

  public ngAfterViewInit(): void {
    this.displayActiveDiaries();
    this.handleSubscriptions();
  }

  public handleSubscriptions(): void {
    this.diaryStateService.diaryTableData$
      .pipe(untilDestroyed(this))
      .subscribe((diary: DiaryTableData[]) => {
        this.dataSource = new MatTableDataSource<DiaryTableData>(diary);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.isActiveDiary;
        this.dataSource.filter = "thisActivatesTheFilter";
        this.cdr.detectChanges();
      });

    this.historyChanged$
      .pipe(untilDestroyed(this))
      .subscribe((checkboxChange: MatCheckboxChange) => {
        this.handleHistoryCheckBoxChanges(checkboxChange);
        this.cdr.detectChanges();
      });
  }

  public isActiveDiary(diary: Diary): boolean {
    return !diary.deletedByDate;
  }

  public handleHistoryCheckBoxChanges(checkboxChange: MatCheckboxChange): void {
    if (checkboxChange.checked) {
      this.displayHistoricDiaries();
    } else {
      this.displayActiveDiaries();
    }
  }

  public filterByActiveDiaries(): void {
    this.dataSource.filterPredicate = this.isActiveDiary;
    this.dataSource.filter = "thisActivatesTheFilter";
  }

  public resetFilter(): void {
    this.dataSource.filterPredicate = () => true;
    this.dataSource.filter = "thisActivatesTheFilter";
  }

  public displayActiveDiaries(): void {
    this.displayedColumns = [
      "diaryDueDate",
      "diaryText",
      "ownerRepTableDisplay",
      "enteredRepTableDisplay",
    ];
    this.filterByActiveDiaries();
    this.resetToDefaultView();
  }

  public displayHistoricDiaries(): void {
    this.displayedColumns = [
      "diaryDueDate",
      "diaryText",
      "ownerRepTableDisplay",
      "enteredRepTableDisplay",
      "deletedByDate",
      "deletedByRepTableDisplay",
    ];
    this.resetFilter();
    this.resetToDefaultView();
  }

  public resetToDefaultView(): void {
    if (this.dataSource.sort) {
      this.dataSource.sort.sort({
        id: "",
        start: "asc",
        disableClear: false,
      });
    }
  }
}
