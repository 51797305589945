import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map } from "rxjs/operators";

import { listActions, sharedActions, spinnerActions } from "../actions";

@Injectable()
export class SpinnerEffects {
  spinnerHideActions = new Map<string, any>();

  showSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listActions.getClaimFeaturesWithPreReq),
      map((action: any) =>
        spinnerActions.showSpinner({ spinnerId: "", actionType: action.type })
      )
    )
  );

  hideSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        listActions.getClaimFeaturesWithPreReqSuccess,
        sharedActions.errorOccurred
      ),
      map((action: any) =>
        spinnerActions.hideSpinner(this.spinnerHideActions.get(action.type))
      )
    )
  );

  constructor(private actions$: Actions) {
    this.init();
  }

  private init() {
    this.spinnerHideActions.set(
      listActions.getClaimFeaturesWithPreReqSuccess.type,
      { spinnerId: "", actionType: listActions.getClaimFeaturesWithPreReq.type }
    );
    this.spinnerHideActions.set(sharedActions.errorOccurred.type, {
      spinnerId: "error",
      actionType: sharedActions.errorOccurred.type,
    });
  }
}
