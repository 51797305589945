import { createSelector } from "@ngrx/store";

import * as sharedSelectors from "./shared.selectors";

export const selectSpinner = createSelector(
  sharedSelectors.selectFeatureState,
  (state) => state.spinner
);

export const selectIsSpinnerActive = createSelector(
  selectSpinner,
  (state) => state.pageActive > 0
);
