import { Injectable } from "@angular/core";
import * as coreUiExtensionActions from "@modules/core-ui-extensions/actions";
import { ClaimInfo } from "@modules/feature/models/feature/claim-info";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { FeatureService } from "../../services/feature/feature.service";
import { listActions, sharedActions } from "../actions";

@Injectable()
export class ListEffects {
  getClaimFeaturesWithPreReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listActions.getClaimFeaturesWithPreReq),
      switchMap(({ claimNumber }) =>
        this.featureService.getFeatures(claimNumber).pipe(
          switchMap((features) =>
            from([
              listActions.getClaimFeaturesWithPreReqSuccess({
                claimNumber: claimNumber,
                claimInfo: {} as ClaimInfo,
                features: features,
              }),
              coreUiExtensionActions.CoreUiExtensionsActions.onGetRepSummariesRequest(
                {
                  codes: [
                    ...new Set(
                      features.flatMap((x) =>
                        [x?.owningRep?.owningRepCode].filter((x) => x)
                      )
                    ),
                  ],
                }
              ),
            ])
          ),
          catchError((error) => of(sharedActions.errorOccurred({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private featureService: FeatureService
  ) {}
}
