import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as outOfPocketsActions from "../actions/out-of-pockets.actions";
import { OutOfPocket } from "../../models";
import { SubrogationSummaryService } from "@subrogation-summary/services";

@Injectable()
export class OutOfPocketsEffects {
  constructor(
    private action$: Actions,
    private loggingService: LoggingService,
    private subrogationSummaryService: SubrogationSummaryService
  ) {}

  loadOutOfPockets$ = createEffect(() =>
    this.action$.pipe(
      ofType(outOfPocketsActions.loadOutOfPockets),
      switchMap((action) =>
        this.subrogationSummaryService.getOutOfPockets(action.claimNumber).pipe(
          map((outOfPockets: OutOfPocket[]) =>
            outOfPocketsActions.loadOutOfPocketsSucceeded({ outOfPockets })
          ),
          catchError((error) => {
            this.loggingService.log(error, LoggedMessageLevel.Error);
            return of(
              outOfPocketsActions.loadOutOfPocketsFailed({
                errorMsg: error.message,
              })
            );
          })
        )
      )
    )
  );
}
