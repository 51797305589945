import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { LiabilityDetailInfo } from "@claimssummaryshared/models";
import { RouterStateService } from "@modules/core/state/router";
import { take } from "rxjs";

const maxItemsWhenCollapsed = 5;

@Component({
  selector: "cla-liability-tile",
  templateUrl: "liability-tile.component.html",
  styleUrls: ["./liability-tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilityTileComponent {
  liability: LiabilityDetailInfo[];
  dataSource: MatTableDataSource<LiabilityDetailInfo>;
  pageSize = maxItemsWhenCollapsed;
  expanded = false;
  displayedColumns: string[] = ["liabilityPercentage", "liabilityPartyDetails"];
  @Input() isLoading: boolean;
  @Input() liabilityStatus: string;
  @Input() liabilityError: HttpErrorResponse | undefined;
  @Output() fetchLiabilityData: EventEmitter<void> = new EventEmitter();
  @ViewChild("tableContainer", { static: false }) tableContainer: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { read: ElementRef }) table: ElementRef;
  claimNumber: string;

  @Input() set liabilityTableData(liabilityTableData: LiabilityDetailInfo[]) {
    this.liability = liabilityTableData;
    this.dataSource = new MatTableDataSource(
      this.liability ? this.liability : []
    );
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private router: Router,
    private routerStateService: RouterStateService
  ) {
    this.routerStateService.routeParams$
      .pipe(take(1))
      .subscribe((routeParams) => {
        if (routeParams && routeParams.claimNumber) {
          this.claimNumber = routeParams.claimNumber;
        }
      });
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.paginator._changePageSize(Number.MAX_SAFE_INTEGER);
    } else {
      this.paginator._changePageSize(maxItemsWhenCollapsed);
    }
  }

  navigateLiability(): void {
    this.router.navigate([`${this.claimNumber}/liability`]);
  }
}
