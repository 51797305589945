import { Pipe, PipeTransform } from "@angular/core";
import { VehicleLimitsAndCoverages } from "../../models/policy-vehicle";

@Pipe({
  name: "claGetCoverageValue",
})
export class GetCoverageValuePipe implements PipeTransform {
  transform(value: VehicleLimitsAndCoverages): string {
    if (value.description === "") {
      return value.limitsDescription1.trim();
    }
    return (value.limitsDescription1 + "\n" + value.limitsDescription2).trim();
  }
}
