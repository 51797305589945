import { Pipe, PipeTransform } from "@angular/core";
import { Party, PartyName } from "@claimssummaryshared/models";

@Pipe({
  name: "claPartyName",
})
export class PartyNamePipe implements PipeTransform {
  public transform(value: PartyName | Party): string {
    if (!value) return "";

    if ((value as PartyName).last) {
      const partyName = value as PartyName;

      let transformedName = partyName.last;

      if (partyName.first || partyName.middleInitial) {
        transformedName += `, ${partyName.first} ${partyName.middleInitial}`;
      }

      if (partyName.suffix) {
        transformedName += `, ${partyName.suffix}`;
      }

      return transformedName.trim().replace(/\s\s+/g, " ");
    } else {
      const party = value as Party;
      if (party.isBusiness) {
        return party.businessName.trim();
      }
      return this.transform(party.partyName);
    }
  }
}
