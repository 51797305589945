import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AllContentComponent,
  ColumnsPaneComponent,
  ContentSummaryViewComponent,
  FiltersPaneComponent,
  PreviewPaneComponent,
  RetiredComponent,
} from "./components";

const contentSummaryRoutes: Routes = [
  {
    path: "",
    redirectTo: "all",
    pathMatch: "full",
  },
  {
    path: "retired",
    component: RetiredComponent,
  },
  {
    path: "all",
    component: ContentSummaryViewComponent,
    children: [
      {
        path: "",
        component: AllContentComponent,
      },
      {
        path: "columns",
        component: ColumnsPaneComponent,
        outlet: "sideSheet",
      },
      {
        path: "filters",
        component: FiltersPaneComponent,
        outlet: "sideSheet",
      },
      {
        path: "preview",
        component: PreviewPaneComponent,
        outlet: "sideSheet",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(contentSummaryRoutes)],
  exports: [RouterModule],
})
export class ContentSummaryRoutingModule {}
