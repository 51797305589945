import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { PartyDetail } from "../models/party-detail";
import { PartyApiService } from "./party-api.service";

@Injectable()
export class PartyDetailService {
  constructor(private partyApiService: PartyApiService) {}

  getPartyDetail(claimNumber: string): Observable<PartyDetail[] | null> {
    return this.partyApiService
      .get<PartyDetail[]>(`/lossviewerapi/v1/claims/${claimNumber}/parties`)
      .pipe(
        catchError((error: any) => {
          if (error.status === 404) {
            return of(null);
          }
          return throwError(error);
        })
      );
  }
}
