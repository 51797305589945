import { Action, combineReducers } from "@ngrx/store";

import { IDocSetState } from "../../models/doc-set";

import * as fromDocSetDetails from "./details";

export function reducers(state: IDocSetState, action: Action): IDocSetState {
  return combineReducers({
    details: fromDocSetDetails.reducer,
  })(state, action);
}
