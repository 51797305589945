import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ChangeDetectionService } from "@core/services";
import { CoreUiExtensionsActions } from "@modules/core-ui-extensions/actions";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { ClaimDetailsStateService } from "@modules/core/state";
import { dateDisplayFormat } from "@modules/shared/material.module";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  NoteEvent,
  actions,
  noteMessages,
  repairShopCodeRegex,
} from "@notes/constants";
import { Journal, Note } from "@notes/models";
import { NotesService } from "@notes/services";
import { NotesActions } from "@notes/state/notes.actions";
import { NotesStateService } from "@notes/state/notes.state.service";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";
import { RepNamePipe, TimeSincePipe } from "@shared/pipes";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { Observable, asyncScheduler, of } from "rxjs";
import {
  bufferCount,
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  observeOn,
  tap,
  withLatestFrom,
} from "rxjs/operators";

@Injectable()
export class NotesEffects {
  private datePipe = new DatePipe("en-US");
  private repNamePipe = new RepNamePipe();
  private timeSincePipe = new TimeSincePipe();

  public detectGetNotesRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetNotesRequest),
      withLatestFrom(
        this.claimDetailsStateService.claimNumber$,
        this.notesStateService.allowedCategoryCodes$
      ),
      mergeMap(([, claimNumber, allowedCategoryCodes]) =>
        this.notesService
          .getNotes(claimNumber as string, allowedCategoryCodes)
          .pipe(
            map((notes: Note[]) =>
              NotesActions.onGetNotesRequestSuccess({ notes })
            ),
            catchError(() =>
              of(
                NotesActions.onGetNotesRequestFailure({
                  error: {
                    message: noteMessages[NoteEvent.UNEXPECTED_FAILURE],
                  },
                })
              )
            )
          )
      )
    )
  );

  public detectGetNotesRequestSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetNotesRequestSuccess),
      withLatestFrom(this.notesStateService.isSearchedOrFiltered$),
      filter(([, isSearchedOrFiltered]) => isSearchedOrFiltered),
      concatMap(() => of(NotesActions.onSetFilteredNotesRequest()))
    )
  );

  public detectGetNotesAndJournalsRequestSuccess$: Observable<any> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(
          NotesActions.onGetNotesRequestSuccess,
          NotesActions.onGetJournalsRequestSuccess,
          NotesActions.onGetJournalsRequestFailure
        ),
        bufferCount(2, 2),
        map((actions) =>
          _.flatten(
            actions.map(
              (x) => ((x as any).notes ?? (x as any).journals) as Note[]
            )
          )
            .filter((x) => !!x)
            .map((x) => x.authoredUserId)
        ),
        mergeMap((codes) =>
          of(
            CoreUiExtensionsActions.onGetRepSummariesRequest({
              codes: codes.filter((code) => !repairShopCodeRegex.test(code)),
            }),
            CoreUiExtensionsActions.onGetRepairShopSummariesRequest({
              codes: codes.filter((code) => repairShopCodeRegex.test(code)),
            })
          )
        )
      )
    );

  public detectSetNoteId$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onSetNoteId),
      concatMap((action) =>
        of(NotesActions.onGetNoteRequest({ noteId: action.noteId }))
      )
    )
  );

  public detectGetNoteRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetNoteRequest),
      observeOn(asyncScheduler),
      withLatestFrom(this.claimDetailsStateService.claimNumber$),
      mergeMap(([action, claimNumber]) =>
        this.notesService.getNote(claimNumber as string, action.noteId).pipe(
          mergeMap((note: Note) => {
            if (note.access.isRestricted) {
              return of(
                NotesActions.onRedirectRequest({ url: "forbidden" }),
                NotesActions.onGetNoteRequestSuccess({ note })
              );
            }
            return of(NotesActions.onGetNoteRequestSuccess({ note }));
          }),
          catchError((err) =>
            of(
              NotesActions.onGetNoteRequestFailure({
                error: {
                  message: noteMessages[NoteEvent.UNEXPECTED_FAILURE],
                  code: err.status,
                },
              })
            )
          )
        )
      )
    )
  );

  public detectGetJournalsRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetJournalsRequest),
      withLatestFrom(
        this.notesStateService.isJournalsEnabled$,
        this.claimDetailsStateService.claimNumber$
      ),
      mergeMap(([, isJournalsEnabled, claimNumber]) =>
        isJournalsEnabled
          ? this.notesService.getJournals(claimNumber as string).pipe(
              map((journals: Journal[]) =>
                NotesActions.onGetJournalsRequestSuccess({ journals })
              ),
              catchError(() =>
                of(
                  NotesActions.onGetJournalsRequestFailure({
                    error: {
                      message: noteMessages[NoteEvent.UNEXPECTED_FAILURE],
                    },
                  })
                )
              )
            )
          : of(NotesActions.onGetJournalsRequestSuccess({ journals: [] }))
      )
    )
  );

  public detectGetJournalsRequestSuccess$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetJournalsRequestSuccess),
      withLatestFrom(this.notesStateService.isSearchedOrFiltered$),
      filter(([, isSearchedOrFiltered]) => isSearchedOrFiltered),
      concatMap(() => of(NotesActions.onSetFilteredNotesRequest()))
    )
  );

  public detectGetCategoriesRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onGetCategoriesRequest),
      mergeMap(() =>
        this.notesService.getCategories().pipe(
          map((categories: CodeDescriptionPair[]) =>
            NotesActions.onGetCategoriesRequestSuccess({ categories })
          ),
          catchError(() =>
            of(
              NotesActions.onGetCategoriesRequestFailure({
                error: { message: noteMessages[NoteEvent.UNEXPECTED_FAILURE] },
              })
            )
          )
        )
      )
    )
  );

  public detectSetFilterCriteria$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onSetFilterCriteria),
      concatMap(() => of(NotesActions.onSetFilteredNotesRequest()))
    )
  );

  public detectToggleSortDirectionRequest$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onToggleSortDirectionRequest),
      observeOn(asyncScheduler),
      concatMap(() => of(NotesActions.onToggleSortDirectionRequestSuccess()))
    )
  );

  public detectSetFilteredNotes$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(NotesActions.onSetFilteredNotesRequest),
      observeOn(asyncScheduler),
      withLatestFrom(
        this.notesStateService.notesAndJournals$,
        this.notesStateService.filterCriteria$,
        this.claimDetailsStateService.claimDetails$,
        this.coreUiStateService.repSummaries$,
        this.coreUiStateService.repairShopSummaries$
      ),
      concatMap(
        ([
          ,
          notes,
          filterCriteria,
          claimDetails,
          repSummaries,
          repairShopSummaries,
        ]) => {
          let filteredNotes = [...notes];
          if (filterCriteria.authoredRepCode) {
            filteredNotes = filteredNotes.filter(
              (k) => k.authoredUserId === filterCriteria.authoredRepCode
            );
          }
          if (filterCriteria.categoryCode) {
            filteredNotes = filteredNotes.filter(
              (k) => k.category.code === filterCriteria.categoryCode
            );
          }
          if (filterCriteria.createdDateRange?.start) {
            filteredNotes = filteredNotes.filter(
              (k) =>
                DateTime.fromISO(k.createdDate).startOf("day") >=
                DateTime.fromISO(
                  filterCriteria.createdDateRange.start ?? ""
                ).startOf("day")
            );
          }
          if (filterCriteria.createdDateRange?.end) {
            filteredNotes = filteredNotes.filter(
              (k) =>
                DateTime.fromISO(k.createdDate).startOf("day") <=
                DateTime.fromISO(
                  filterCriteria.createdDateRange.end ?? ""
                ).startOf("day")
            );
          }
          if (filterCriteria.searchTerm) {
            filteredNotes = filteredNotes.filter((k) =>
              [
                k.text,
                k.category.description,
                (k as Journal).subCategoryDescription,
                repairShopSummaries[k.authoredUserId]?.name ??
                  [
                    this.repNamePipe.transform(
                      k.authoredUserId,
                      repSummaries[k.authoredUserId]
                    ),
                    this.repNamePipe.transform(
                      k.authoredUserId,
                      repSummaries[k.authoredUserId],
                      true
                    ),
                  ].join(""),
                claimDetails?.claimReportDate
                  ? `${this.timeSincePipe.transform(
                      claimDetails.claimReportDate,
                      k.createdDate
                    )} After Report`
                  : "",
                this.datePipe.transform(
                  k.createdDate,
                  dateDisplayFormat.angular,
                  "+0000"
                ),
              ]
                .filter((x) => !!x)
                .map((x) => x?.toLowerCase())
                .join("")
                .includes((filterCriteria.searchTerm as string).toLowerCase())
            );
          }
          return of(
            NotesActions.onSetFilteredNotesRequestSuccess({
              filteredNoteIds: filteredNotes.map((k) => k.id),
            })
          );
        }
      )
    )
  );

  public detectChangeDetectionEvents$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotesActions.onGetNotesRequestSuccess,
          NotesActions.onGetJournalsRequestSuccess,
          NotesActions.onSetFilteredNotesRequestSuccess
        ),
        tap(() => this.changeDetectionService.emitDetectChangesEvent())
      ),
    { dispatch: false }
  );

  public detectRedirectRequest$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotesActions.onRedirectRequest),
        tap((action) => this.router.navigate([action.url]))
      ),
    { dispatch: false }
  );

  public detectRequestError$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          NotesActions.onGetNoteRequestFailure,
          NotesActions.onGetCategoriesRequestFailure
        ),
        tap((action) => {
          if (
            action.error.code === 404 &&
            action.type === actions.getNoteFailure
          ) {
            this.router.navigate(["/note-not-found"]);
          } else {
            this.router.navigate(["/techdiff"]);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notesService: NotesService,
    private notesStateService: NotesStateService,
    private router: Router,
    private coreUiStateService: CoreUiExtensionsStateService,
    private changeDetectionService: ChangeDetectionService,
    private claimDetailsStateService: ClaimDetailsStateService
  ) {}
}
