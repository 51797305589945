<div role="region" aria-label="Left Rail">
  <div class="hidden-focus-switch-button">
    <cui-hidden-focus-switch-button
      [id]="'skipToMainContent'"
      [label]="'Skip To Main Content'"
      [focusSwitchId]="'main-content-header'"
    ></cui-hidden-focus-switch-button>
  </div>
  <cla-loss-details
    [isLoading]="
      claimDetailsStateService.isClaimDetailsRequestInFlight$ | async
    "
    [isInitialLoad]="
      (claimDetailsStateService.isClaimDetailsRequestInFlight$ | async) &&
      (claimDetailsStateService.claimDetails$ | async) === undefined
    "
    [claimDetails]="claimDetailsStateService.claimDetails$ | async"
  >
  </cla-loss-details>
  <cla-left-rail-party-property
    [isLoading]="
      claimDetailsStateService.isPartyPropertyDetailsRequestInFlight$ | async
    "
    [partyPropertyData]="claimDetailsStateService.partyPropertyDetails$ | async"
  >
  </cla-left-rail-party-property>
  <div class="hidden-focus-switch-button">
    <cui-hidden-focus-switch-button
      [id]="'skipToPrimaryNav'"
      [label]="'Skip To Primary Navigation'"
      [focusSwitchId]="'mat-tab-link-0'"
    ></cui-hidden-focus-switch-button>
  </div>
</div>
