import { Injectable } from "@angular/core";

import { OpenValidationConstants, EFFConstants } from "../../../constants";
import {
  IContentSummaryItem,
  IDocSetViewerDataMap,
  ISimpleDialog,
} from "../../../models";

import { ActionsValidationUtilityConstants as constants } from "./actions-validation.utility.constants";

@Injectable()
export class ActionsValidationUtility {
  public hasRemovedStatus(status: string): boolean {
    return status.toLowerCase() === "d";
  }

  public validateItemsToOpen(
    contentItems: IContentSummaryItem[]
  ): IContentSummaryItem[] {
    const invalidOpenTypes =
      OpenValidationConstants.validation.invalidOpenTypes;
    const invalidStatuses = OpenValidationConstants.validation.invalidStatuses;
    const validItemsToOpen = contentItems.filter(
      (contentItem) =>
        !invalidOpenTypes.includes(contentItem.contentTypeDesc) &&
        !invalidStatuses.includes(contentItem.status)
    );
    return validItemsToOpen;
  }

  public validateMoreThanOneItemSelected(numberOfItems: number): ISimpleDialog {
    let result = {} as ISimpleDialog;

    if (numberOfItems > 1) {
      result = constants.tooManyItemsMessaging;
    }

    return result;
  }

  public validateViewContentSharingSummary(
    contentItem: IContentSummaryItem,
    selectedItemsCount: number
  ): ISimpleDialog {
    let result = {} as ISimpleDialog;

    if (
      contentItem.contentTypeDesc !== "Content Sharing Invitation" &&
      contentItem.contentTypeDesc !== "Content Package"
    ) {
      result = constants.viewContentSharingSummaryValidationMessaging;
    }
    if (selectedItemsCount > 1) {
      result = this.validateMoreThanOneItemSelected(selectedItemsCount);
    }

    return result;
  }

  public validateViewDetails(
    contentItem: IContentSummaryItem,
    selectedItemsCount: number
  ): ISimpleDialog {
    let result = {} as ISimpleDialog;

    if (contentItem.status.toLowerCase() === EFFConstants.contentTypes.draft) {
      result = {
        title: "The following types of content cannot be viewed:",
        message: "Any document with a status of Draft.",
      };
    } else if (this.isUltramateEstimate(contentItem.contentTypeDesc)) {
      result = {
        title: "Cannot view details.",
        message: "This content has no details.",
      };
    }
    if (selectedItemsCount > 1) {
      result = this.validateMoreThanOneItemSelected(selectedItemsCount);
    }

    return result;
  }

  private isUltramateEstimate(contentTypeDesc: string): boolean {
    return (
      contentTypeDesc.toLowerCase() ===
        EFFConstants.contentTypes.committedUltramate ||
      contentTypeDesc.toLowerCase() ===
        EFFConstants.contentTypes.preliminaryUltramate
    );
  }
}
