import { Injectable } from "@angular/core";
import { BusinessApiService } from "@modules/core/services/business-api";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";

@Injectable()
export class WorkItemsApiService {
  constructor(
    private api: BusinessApiService,
    private config: ConfigurationService
  ) {}

  get<T>(
    path: string,
    queryStringParams?: { [key: string]: string | object }
  ): Observable<T> {
    let paramString = "";

    if (queryStringParams) {
      const params = Object.getOwnPropertyNames(queryStringParams).filter(
        (param) => queryStringParams[param] !== undefined
      );

      params.forEach((param, index) => {
        const paramValue = queryStringParams[param];

        paramString += `${index ? "&" : "?"}${param}=${
          typeof paramValue === "string"
            ? paramValue
            : JSON.stringify(paramValue)
        }`;
      });
    }

    return this.config.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) =>
        this.api.get<T>(config.workItemsBusinessApiUrl, path + paramString)
      )
    );
  }
}
