import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import {
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinErrorState,
  _AbstractConstructor,
  _Constructor,
} from "@angular/material/core";
import {
  MatFormField,
  MatFormFieldControl,
  MAT_FORM_FIELD,
} from "@angular/material/form-field";
import { Subject } from "rxjs";

import { ButtonToggleInputOptions } from "./models";

declare type CanUpdateErrorStateCtor = _Constructor<CanUpdateErrorState> &
  _AbstractConstructor<CanUpdateErrorState>;

/* eslint-disable @typescript-eslint/no-explicit-any, @angular-eslint/no-conflicting-lifecycle, @typescript-eslint/explicit-module-boundary-types */

// Boilerplate for applying mixins to ButtonToggleInputComponent.
class ButtonToggleInputBase {
  constructor(
    public _parentFormGroup: FormGroupDirective,
    public _parentForm: NgForm,
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public ngControl: NgControl,
    //eslint-disable-next-line rxjs/finnish, rxjs/suffix-subjects, rxjs/no-exposed-subjects
    public stateChanges: Subject<void>
  ) {}
}

// TODO: rework this type to stop extending the deprecated Angular Material type
const ButtonToggleInputMixinBase: CanUpdateErrorStateCtor &
  typeof ButtonToggleInputBase = mixinErrorState(ButtonToggleInputBase);

@Component({
  selector: "cla-button-toggle-input",
  templateUrl: "./button-toggle-input.component.html",
  providers: [
    { provide: MatFormFieldControl, useExisting: ButtonToggleInputComponent },
  ],
})
export class ButtonToggleInputComponent
  extends ButtonToggleInputMixinBase
  implements
    CanUpdateErrorState,
    ControlValueAccessor,
    DoCheck,
    MatFormFieldControl<any>,
    OnChanges,
    OnDestroy
{
  static nextId = 0;
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  static ngAcceptInputType_required: boolean | string | null | undefined;

  buttonToggleForm: UntypedFormGroup;
  // eslint-disable-next-line rxjs/finnish, rxjs/no-exposed-subjects, rxjs/suffix-subjects
  focused = false;
  controlType = "button-toggle-input";

  @HostBinding("attr.id")
  // eslint-disable-next-line functional/immutable-data
  id = `button-toggle-input-${ButtonToggleInputComponent.nextId++}`;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("aria-label") userAriaLabel: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("aria-describedby") userAriaDescribedBy: string;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @Input() options: ButtonToggleInputOptions[] = [];

  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  @Input()
  get value(): any | null {
    return this.buttonToggleForm.value.toggle;
  }
  set value(value: any | null) {
    this.buttonToggleForm.controls.toggle.setValue(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.buttonToggleForm.get("toggle")?.disable();
    this.stateChanges.next();
  }

  get empty(): boolean {
    const value = this.buttonToggleForm.value.toggle;
    return value === "" || value === undefined || value === null;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    protected _elementRef: ElementRef<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() _parentForm: NgForm,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    formBuilder: UntypedFormBuilder,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField?: MatFormField
  ) {
    super(
      _parentFormGroup,
      _parentForm,
      _defaultErrorStateMatcher,
      ngControl,
      new Subject<void>()
    );

    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.buttonToggleForm = formBuilder.group({
      toggle: [null, Validators.required],
    });

    if (this.ngControl !== null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any | null): void => {
    return;
  };
  onTouched = (): void => {
    return;
  };

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".button-toggle-input-container"
    );
    if (controlElement) {
      controlElement.setAttribute("aria-describedby", ids.join(" "));
    }
  }

  onContainerClick(): void {
    return;
  }

  onToggleChange(event: MatButtonToggleChange): void {
    this.ngControl.control?.setValue(event.value);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
