<mat-card class="p-0 overflow-x-auto mb-h">
  <ng-container
    *ngIf="features.length > 0"
    class="feature-horizontal-scroll-container"
  >
    <table
      class="cui-table"
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortDirection="desc"
      matSortStart="desc"
      data-pgr-id="tblFeature"
    >
      <ng-container matColumnDef="featureNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">#</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="featureNumberColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            {{ element.sequenceNumber }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">Status</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="statusColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            <cui-status-indicator
              [background]="true"
              [status]="STATUS_INDICATORS[element.statusCode]"
            >
              {{ element.statusDescription | claValueOrNA }}
            </cui-status-indicator>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="lineCoverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-left">
          <span class="table-header">Line Coverage</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="lineCoverageColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            {{ element.coverageShortDescription | claValueOrNA }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="partyProperty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">Party / Property</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="partyPropertyColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            {{ element.featureDescription | claValueOrNA }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="totalLoss">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">Total Loss</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="totalLossColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            {{ element.isTotalLoss | claEmptyDash }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="caseReserve">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">Case Reserve</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="caseReserveColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <div class="mat-cell-content">
            {{ element.adjusterCaseReserveAmount | currency: "USD":"symbol" }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="table-header">Owner</span>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="ownerColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <cla-contact-card
            [repInfo]="
              (coreUiStateService.repSummaries$ | async)[element.owningRepCode]
            "
            [tpxId]="element.owningRepCode"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="sortHeaderAccessorMapKeys"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: sortHeaderAccessorMapKeys; let i = index"
      ></tr>
    </table>
    <mat-paginator
      hidePageSize
      #pager
      [pageSize]="50"
      [hidden]="2 > pager.getNumberOfPages()"
    >
    </mat-paginator>
  </ng-container>
</mat-card>

<cui-notification-banner
  [dismissable]="false"
  type="warn"
  *ngIf="features.length === 0"
>
  <span data-pgr-id="noFeaturesFoundMessage"
    >No features have been opened for this claim.</span
  >
</cui-notification-banner>
