<div *ngIf="dataSource.data.length > 0">
  <mat-card data-pgr-id="MemoDisplay" class="p-0 overflow-x-auto">
    <div
      id="table-interaction-description"
      aria-hidden="true"
      class="screen-reader-only"
    >
      Press enter to open underwriting memo
    </div>
    <div
      *ngIf="requestInFlight$ | async; else UWMemoIsLoaded"
      data-pgr-id="dln-ghost-loader"
    >
      <div class="ghost-container flex flex-col">
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
        <div class="ghost-line w-full"></div>
      </div>
    </div>
    <ng-template #UWMemoIsLoaded>
      <table mat-table [dataSource]="dataSource" class="cui-table">
        <ng-container matColumnDef="statusDesc">
          <th mat-header-cell class="cui-cell-12" *matHeaderCellDef>Status</th>
          <td
            mat-cell
            class="cui-cell-12"
            *matCellDef="let element"
            data-pgr-id="tblMemoStatus"
          >
            <cui-status-indicator [status]="uwMemoStatus[element.statusDesc]">
              {{ element.statusDesc }}
            </cui-status-indicator>
          </td>
        </ng-container>

        <ng-container matColumnDef="categoryDesc">
          <th mat-header-cell class="cui-cell-6" *matHeaderCellDef>Category</th>
          <td mat-cell class="cui-cell-6" *matCellDef="let element">
            {{ element.categoryDesc }}
          </td>
        </ng-container>

        <ng-container matColumnDef="typeDesc">
          <th mat-header-cell class="cui-cell-12" *matHeaderCellDef>Type</th>
          <td mat-cell class="cui-cell-12" *matCellDef="let element">
            {{ element.typeDesc }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdByUserId">
          <th mat-header-cell class="cui-cell-10" *matHeaderCellDef>Sent By</th>
          <td mat-cell class="cui-cell-10" *matCellDef="let element">
            {{ element.createdByUserId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdUtcDateTime">
          <th mat-header-cell class="cui-cell-6" *matHeaderCellDef>
            Date Sent
          </th>
          <td mat-cell class="cui-cell-6" *matCellDef="let element">
            {{ element.createdUtcDateTime | date: "MM/dd/y" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          tabindex="0"
          [ngClass]="
            'cui-row-selectable' +
            (selectedIndex === i ? ' cui-row-active' : '')
          "
          aria-describedby="table-interaction-description"
          (click)="selectRow(i)"
          (keydown.enter)="selectRow(i)"
          (keydown.space)="selectRow(i)"
          *matRowDef="
            let i = index;
            let element;
            let row;
            columns: displayedColumns
          "
        ></tr>
      </table>
    </ng-template>
  </mat-card>
</div>
