import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ClaimDetails } from "@modules/core/models";
import { AuthenticationService } from "@modules/core/services/authentication";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { UserStateService } from "@modules/core/state/user/user-state.service";
import {
  ClaimsSummaryConfigInfo,
  EmbeddedParameter,
} from "@modules/shared/models";
import {
  ConfigurationInfo,
  ConfigurationService,
} from "@pgr-cla/cla-configuration";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { DateTime } from "luxon";
import { Observable, combineLatest, switchMap, zip } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { LegacyAppSessionService } from "../legacy-app-session";

@Injectable()
export class AppPathService {
  actionItemsPath$: Observable<string>;
  actionItemsByClaimPath$: Observable<string>;
  archiveRetrievalPath$: Observable<string>;
  assistRequestPath$: Observable<string>;
  claimFeatureTransferPath$: Observable<string>;
  claimPaymentInquiryPath$: Observable<string>;
  contentSummaryPath$: Observable<string>;
  contentViewerPath$: Observable<string>;
  coveragePath$: Observable<string>;
  createDocumentPath$: Observable<string>;
  createInvoicePath$: Observable<string>;
  featureRevisionPath$: Observable<string>;
  featureRevisionWithTypeAndPropertyPath$: Observable<string>;
  initialClaimReportPath$: Observable<string>;
  ipcInjuryPath$: Observable<string>;
  legacyInjurySummaryPath$: Observable<string>;
  newInjurySummaryPath$: Observable<string>;
  injurySummaryPath$: Observable<string>;
  invoicePath$: Observable<string>;
  liabilityPath$: Observable<string>;
  litigationPath$: Observable<string>;
  loanPayoffSearchPath$: Observable<string>;
  medicalPath$: Observable<string>;
  notesListPath$: Observable<string>;
  referralHubPath$: Observable<string>;
  restrictClaimPath$: Observable<string>;
  salvagePath$: Observable<string>;
  salvageDetailsPath$: Observable<string>;
  siuPath$: Observable<string>;
  claimsTextWebPath$: Observable<string>;
  newClaimsTextWebPath$: Observable<string>;
  vendorReferralPath$: Observable<string>;
  subrogationReferralPath$: Observable<string>;
  underwritingMemoPath$: Observable<string>;
  searchPath$: Observable<string>;
  pdSuitePath$: Observable<string>;
  createReferralPath$: Observable<string>;
  tripViewerPath$: Observable<string>;
  lnavPath$: Observable<string>;
  private _configuration$: Observable<ConfigurationInfo>;
  private _token$: Observable<string>;

  constructor(
    claimDetailsStateService: ClaimDetailsStateService,
    sessionStateService: LegacyAppSessionService,
    configService: ConfigurationService,
    private authService: AuthenticationService,
    userStateService: UserStateService,
    claimNumberPipe: ClaimNumberPipe,
    private route: ActivatedRoute
  ) {
    this._configuration$ = configService.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      )
    );

    this.archiveRetrievalPath$ = combineLatest([
      this._configuration$,
      authService.token$,
    ]).pipe(
      switchMap(async ([config, token]: [ClaimsSummaryConfigInfo, string]) => {
        // if (this.authService.shouldRefreshToken()) {
        //   token = await this.getRefreshToken(token);
        // }
        return config.embeddedArchiveRetrievalPath.replace(
          "{0}",
          decodeURIComponent(token)
        );
      })
    );

    this.restrictClaimPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedRestrictClaimPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.subrogationReferralPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedSubrogationReferralPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.litigationPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedLitigationPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.liabilityPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.liabilityProcessId$,
      claimDetailsStateService.claimNumber$.pipe(
        first(
          (claimNumber: string) =>
            claimNumber !== null && claimNumber !== undefined
        )
      ),
    ]).pipe(
      switchMap(
        async ([config, token, sessionId, liabilityProcessId, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedLiabilityPath
            .replace("{0}", claimNumberPipe.transform(claimNumber) || "")
            .replace("{2}", sessionId)
            .replace("{3}", liabilityProcessId)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.loanPayoffSearchPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$.pipe(
        first(
          (claimNumber: string) =>
            claimNumber !== null && claimNumber !== undefined
        )
      ),
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedLoanPayoffSearchPath
            .replace("{0}", claimNumber)
            .replace("{2}", decodeURIComponent(token));
        }
      )
    );

    this.medicalPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.medicalProcessId$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, sessionId, medicalProcessId, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedMedicalPath
            .replace("{0}", "MedicalSummaryContent")
            .replace("{1}", claimNumber)
            .replace("{3}", sessionId)
            .replace("{4}", medicalProcessId)
            .replace("{5}", decodeURIComponent(token));
        }
      )
    );

    this.newInjurySummaryPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      userStateService.canViewPrivateFields$,
      claimDetailsStateService.claimDetails$.pipe(
        filter((claimDetails: ClaimDetails) => !!claimDetails)
      ),
    ]).pipe(
      switchMap(
        async ([config, token, canViewPrivateFields, claimDetails]: [
          ClaimsSummaryConfigInfo,
          string,
          boolean,
          ClaimDetails
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedNewInjurySummaryPath
            .replace(
              "{1}",
              claimDetails.namedInsured.partyRecordSequenceNumber ?? ""
            )
            .replace("{2}", canViewPrivateFields.toString())
            .replace("{3}", decodeURIComponent(token));
        }
      )
    );

    this.legacyInjurySummaryPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.injurySummaryProcessId$,
      claimDetailsStateService.claimNumber$,
      userStateService.canViewPrivateFields$,
      claimDetailsStateService.claimDetails$.pipe(
        filter((claimDetails: ClaimDetails) => !!claimDetails)
      ),
    ]).pipe(
      switchMap(
        async ([
          config,
          token,
          sessionId,
          injuryProcessId,
          claimNumber,
          canViewPrivateFields,
          claimDetails,
        ]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string,
          boolean,
          ClaimDetails
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedInjurySummaryPath
            .replace("{0}", claimNumber)
            .replace(
              "{2}",
              claimDetails.namedInsured.partyRecordSequenceNumber ?? ""
            )
            .replace("{3}", sessionId)
            .replace("{4}", injuryProcessId)
            .replace("{5}", canViewPrivateFields.toString())
            .replace("{6}", decodeURIComponent(token));
        }
      )
    );

    this.ipcInjuryPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.injurySummaryProcessId$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, sessionId, injuryProcessId, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedIPCInjuryPath
            .replace("{0}", claimNumber)
            .replace("{3}", sessionId)
            .replace("{4}", injuryProcessId)
            .replace("{6}", decodeURIComponent(token));
        }
      )
    );

    this.claimPaymentInquiryPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
      sessionStateService.sessionId$,
      sessionStateService.paymentsProcessId$,
      authService.token$,
    ]).pipe(
      switchMap(
        async ([config, claimNumber, sessionId, paymentProcessId, token]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedClaimPaymentInquiryPath
            .replace("{0}", claimNumber)
            .replace("{1}", sessionId)
            .replace("{3}", paymentProcessId)
            .replace("{2}", decodeURIComponent(token));
        }
      )
    );

    this.invoicePath$ = combineLatest([
      this._configuration$,
      this.route.params.pipe(
        switchMap(() => {
          let r = this.route;
          // eslint-disable-next-line no-loops/no-loops
          while (r.firstChild) {
            r = r.firstChild;
          }
          return zip(r.params, r.queryParams);
        })
      ),
      claimDetailsStateService.claimNumber$,
      sessionStateService.sessionId$,
      sessionStateService.paymentsProcessId$,
      authService.token$,
    ]).pipe(
      switchMap(
        async ([
          config,
          params,
          claimNumber,
          sessionId,
          paymentProcessId,
          token,
        ]: [
          ClaimsSummaryConfigInfo,
          [Params, Params],
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          let pageName: string;
          switch (params[1].startPage) {
            case "issues":
              pageName = "InvoiceIssuesContent";
              break;
            case "history":
              pageName = "InvoiceHistoryContent";
              break;
            default:
              pageName = "InvoiceLineItemDetailsContent";
              break;
          }
          return config.embeddedInvoicePath
            .replace("{0}", claimNumber)
            .replace("{1}", params[0].invoiceId)
            .replace("{2}", sessionId)
            .replace("{3}", paymentProcessId)
            .replace("{4}", decodeURIComponent(token))
            .replace("{5}", pageName);
        }
      )
    );

    this.claimFeatureTransferPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedClaimFeatureTransferPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.createDocumentPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedCreateDocumentPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.contentSummaryPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedContentSummaryPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.contentViewerPath$ = combineLatest([
      this._configuration$,
      authService.token$,
    ]).pipe(
      switchMap(async ([config, token]: [ClaimsSummaryConfigInfo, string]) => {
        // if (this.authService.shouldRefreshToken()) {
        //   token = await this.getRefreshToken(token);
        // }
        return config.embeddedContentViewerPath.replace(
          "{1}",
          decodeURIComponent(token)
        );
      })
    );

    this.referralHubPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      map(([config, claimNumber]: [ClaimsSummaryConfigInfo, string]) =>
        config.embeddedReferralHubPath.replace("{0}", claimNumber)
      )
    );

    this.featureRevisionPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedFeatureRevisionPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.assistRequestPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedAssistRequestPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );
    this.featureRevisionWithTypeAndPropertyPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedFeatureRevisionWithTypeAndPropertyPath
            .replace("{0}", claimNumber)
            .replace("{3}", decodeURIComponent(token));
        }
      )
    );

    this.notesListPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          // return `${config.activityBasePath}/{0}/notes/list#access_token={1}`
          return `${config.activityBasePath}/notes/{0}`
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.createInvoicePath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.paymentsProcessId$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, sessionId, paymentProcessId, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedCreateInvoicePath
            .replace("{0}", claimNumber)
            .replace("{1}", sessionId)
            .replace("{3}", paymentProcessId)
            .replace("{2}", decodeURIComponent(token));
        }
      )
    );

    this.coveragePath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedCoveragePath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.salvagePath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedSalvagePath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.salvageDetailsPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedSalvageDetailsPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.siuPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
      claimDetailsStateService.isSiuInvolved$.pipe(
        first((siuBoolean: boolean) => siuBoolean !== undefined)
      ),
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber, isSIU]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          boolean
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          if (isSIU) {
            return config.embeddedSiuReferralPath
              .replace("{0}", claimNumber)
              .replace("{1}", decodeURIComponent(token));
          }
          return config.embeddedSiuNoReferralPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.vendorReferralPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedVendorReferralPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.actionItemsPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      sessionStateService.sessionId$,
      sessionStateService.actionItemsProcessId$,
      claimDetailsStateService.claimNumber$.pipe(
        first(
          (claimNumber: string) =>
            claimNumber !== null && claimNumber !== undefined
        )
      ),
      userStateService.repCode$.pipe(first((repCode) => repCode != null)),
    ]).pipe(
      switchMap(
        async ([
          config,
          token,
          sessionId,
          actionItemsId,
          claimNumber,
          repCode,
        ]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          const decodedToken = decodeURIComponent(token);
          const base64Token = btoa(decodedToken);
          const actionItemsUrl = config.embeddedActionItemsPath
            .replace("{0}", sessionId)
            .replace("{1}", actionItemsId)
            .replace("{2}", repCode)
            .replace("{3}", claimNumber)
            .replace("{5}", base64Token);
          return actionItemsUrl;
        }
      )
    );

    this.actionItemsByClaimPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$.pipe(
        first(
          (claimNumber: string) =>
            claimNumber !== null && claimNumber !== undefined
        )
      ),
      authService.token$,
    ]).pipe(
      switchMap(
        async ([config, claimNumber, token]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          const decodedToken = decodeURIComponent(token);
          return config.embeddedActionItemsByClaimPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodedToken);
        }
      )
    );

    this.underwritingMemoPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.underwritingMemoPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.searchPath$ = configService.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      map((config) => config.searchPath)
    );

    this.initialClaimReportPath$ = combineLatest([
      this._configuration$,
      authService.token$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      switchMap(
        async ([config, token, claimNumber]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          return config.embeddedInitialClaimReportPath
            .replace("{0}", claimNumber)
            .replace("{1}", decodeURIComponent(token));
        }
      )
    );

    this.claimsTextWebPath$ = combineLatest([
      this._configuration$,
      userStateService.repCode$.pipe(first((repCode) => repCode != null)),
      userStateService.orgCode$.pipe(first((orgCode) => orgCode != null)),
      claimDetailsStateService.claimNumber$,
      sessionStateService.sessionId$,
      sessionStateService.claimTextProcessId$,
      authService.token$,
      this.route.queryParams.pipe(
        first(),
        map((params) => params["partyId"] ?? "001")
      ),
    ]).pipe(
      switchMap(
        async ([
          config,
          repCode,
          orgCode,
          claimNumber,
          sessionId,
          claimTextProcessId,
          token,
          partyId,
        ]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string,
          string,
          string,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          const ctwPath = config.embeddedClaimsTextWebPath
            .replace("{0}", sessionId)
            .replace("{1}", claimTextProcessId)
            .replace("{2}", repCode)
            .replace("{3}", claimNumber)
            .replace("{4}", partyId)
            .replace("{5}", orgCode)
            .replace("{6}", decodeURIComponent(token));

          return ctwPath;
        }
      )
    );

    this.newClaimsTextWebPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
      authService.token$,
    ]).pipe(
      switchMap(
        async ([config, claimNumber, token]: [
          ClaimsSummaryConfigInfo,
          string,
          string
        ]) => {
          // if (this.authService.shouldRefreshToken()) {
          //   token = await this.getRefreshToken(token);
          // }
          const ctwPath = config.embeddedNewClaimsTextWebPath
            .replace("{0}", claimNumber)
            .replace("{2}", token);
          return ctwPath;
        }
      )
    );

    this.pdSuitePath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      map(([config, claimNumber]: [ClaimsSummaryConfigInfo, string]) => {
        const pathWithClaim = `${claimNumberPipe.transform(claimNumber)}`;
        return config.pdSuiteSuffixDetailsPath.replace("{0}", pathWithClaim);
      })
    );

    this.createReferralPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
      claimDetailsStateService.policyState$,
      claimDetailsStateService.incidentState$,
    ]).pipe(
      map(
        ([config, claimNumber, policyState, incidentState]: [
          ClaimsSummaryConfigInfo,
          string,
          string,
          string
        ]) => {
          let path = config.createReferralPath;
          const claimNbr = `${claimNumberPipe.transform(claimNumber)}`;
          path = path.replace("{0}", claimNbr);
          path = path.replace("{3}", policyState ? policyState : "");
          path = path.replace("{4}", incidentState ? incidentState : "");
          return path;
        }
      )
    );

    this.tripViewerPath$ = combineLatest([
      this._configuration$,
      claimDetailsStateService.claimNumber$,
    ]).pipe(
      map(([config, claimNumber]: [ClaimsSummaryConfigInfo, string]) => {
        let path = config.tripViewerDetailsPath;
        let claimNbr = `${claimNumberPipe.transform(claimNumber)}`;
        claimNbr = claimNbr.replace("-", "");
        path = path.replace("{0}", claimNbr);
        return path;
      })
    );

    this.lnavPath$ = combineLatest([
      this._configuration$,
      authService.token$,
    ]).pipe(
      map(([config, token]: [ClaimsSummaryConfigInfo, string]) => {
        const securityLevel = "20";
        const useWcfServices = "true";

        return config.externalUrls.lnav
          .replace("{0}", token)
          .replace("{1}", securityLevel)
          .replace("{2}", useWcfServices);
      })
    );
  }

  public appendSearchParams(path: string, params: EmbeddedParameter[]): string {
    const url = this.createURLObject(path);
    if (url) {
      if (params) {
        (params as EmbeddedParameter[]).forEach((x) => {
          let value = x.value;
          // TODO: should be luxonFormat or dateFormat, but not sure where this data comes from
          if (x.momentFormat) {
            // TODO: should be luxonFormat or dateFormat, but not sure where this data comes from
            value = DateTime.now().toFormat(x.momentFormat);
          }
          url.searchParams.append(x.key, value);
        });
      }
      return url.toString();
    }
    return "";
  }

  private createURLObject(path: string): URL | undefined {
    if (this.isFullPathURL(path)) {
      return new URL(path);
    } else {
      return new URL(path, window.location.origin);
    }
  }

  private isFullPathURL(path: string): boolean {
    return path.includes("http://") || path.includes("https://");
  }

  // private async getRefreshToken(token: string): Promise<string> {
  //   const newToken = await this.authService.getRefreshToken();
  //   if (newToken) {
  //     token = newToken;
  //   }
  //   return token;
  // }
}
