/* eslint-disable functional/immutable-data */

export const useLocalHost = (current: string): string => {
  if (current && window.location.hostname.startsWith("localhost")) {
    const newConfig = current.replace(
      /https:\/\/dev-login.progressive.com/g,
      "http://localhost:3000/ping-auth"
    );
    return newConfig;
  } else {
    return current;
  }
};

export const fixUrl = (url: string): string =>
  url.replace(/([^:]\/)\/+/g, "$1");
