import { Component, Host, Input, OnInit, Optional } from "@angular/core";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";
import { PopoverComponent } from "@pgr-cla/core-ui-components";
import { HeaderType } from "@pgr-cla/core-ui-components/lib/claims/types";

/**
 * A simplified version of the Core UI Contact Card
 */
@Component({
  selector: "cla-claim-rep-contact-card-redacted",
  templateUrl: "./claim-rep-contact-card-redacted.component.html",
  styleUrls: ["./claim-rep-contact-card-redacted.component.scss"],
})
export class ClaimRepContactCardRedactedComponent implements OnInit {
  @Input() public rep: ClaimRepContactCardInfoRedacted;
  @Input() public isLoading = false;
  @Input() public headerTag: HeaderType = "H3";
  @Input() public isError = false;
  @Input() public errorMessage: string;

  public get primaryRep(): ClaimRepContactCardInfoRedacted | undefined {
    return this.rep?.name ? this.rep : undefined;
  }

  public get managerRep(): ClaimRepContactCardInfoRedacted | undefined {
    return this.primaryRep?.manager?.name ? this.primaryRep.manager : undefined;
  }

  public get isInPopover(): boolean {
    return !!this.popover;
  }

  constructor(@Host() @Optional() private popover?: PopoverComponent) {}

  public ngOnInit(): void {
    if (this.popover) {
      this.popover.contentPadding = "0";
    }
  }
}
