import { HttpErrorResponse } from "@angular/common/http";
import { coreSharedActions, NotificationType } from "@modules/shared/redux";
import { POLICY_VEHICLE_SVG_MAP } from "./policy-helper.constant";

import { DateTime } from "luxon";
import { PolicyResourceConstant } from "./policy-resource.constant";

export class PolicyHelperFunctions {
  static handleError = (error: Error | HttpErrorResponse) => {
    let returnAction: any;
    if (error instanceof Error) {
      returnAction = coreSharedActions.onNotificationReq({
        message: error as any,
        notificationType: NotificationType.ERROR,
      });
    } else {
      const errorHttp = error as HttpErrorResponse;
      let errMessage = "";
      switch (errorHttp.status) {
        case 400:
          errorHttp.error.attributeErrors.forEach((errorItem: any) => {
            errMessage += `${errorItem.attributeDisplayMessage} \n`;
          });
          returnAction = coreSharedActions.onNotificationReq({
            message: errMessage,
            notificationType: NotificationType.ERROR,
          });
          break;
        case 403:
          returnAction = coreSharedActions.onNotificationReq({
            message: errorHttp.error,
            notificationType: NotificationType.ERROR,
          });
          break;
        case 404:
          returnAction = coreSharedActions.onNotificationReq({
            message: errorHttp.statusText,
            notificationType: NotificationType.ERROR,
          });
          break;
        case 500:
        case 0:
          returnAction = coreSharedActions.onNotificationReq({
            message: PolicyResourceConstant.errorMsg.error500,
            notificationType: NotificationType.ERROR,
          });
          break;
        default:
          returnAction = coreSharedActions.onNotificationReq({
            message: errorHttp.error.developerMessage,
            notificationType: NotificationType.ERROR,
          });
      }
    }
    return returnAction;
  };

  static booleanToYesNo = (input: boolean) => {
    return input ? "Yes" : "No";
  };

  static getSVGImage = (productCategoryCode: string) => {
    return POLICY_VEHICLE_SVG_MAP[productCategoryCode] ?? "placeholder";
  };

  static pad = (str: string, max: number): string => {
    return str.length < max ? PolicyHelperFunctions.pad(`0${str}`, max) : str;
  };

  static zip = (zipCode: string, zipExtension: string): string => {
    if (zipCode === undefined || zipCode === null || zipCode!.trim() === "") {
      return "-";
    }
    if (
      zipExtension === undefined ||
      zipExtension === null ||
      zipExtension!.trim() === ""
    ) {
      return zipCode;
    }
    return `${zipCode}-${zipExtension}`;
  };

  static suspension = (from: Date, to: Date): string => {
    const emptyDate = new Date("0001-01-01T00:00:00");

    if (
      (from == null || from.getTime() === emptyDate.getTime()) &&
      (to == null || to.getTime() === emptyDate.getTime())
    ) {
      return "-";
    }
    if (from == null || from.getTime() === emptyDate.getTime()) {
      return `- ${to.toLocaleDateString("en-US")}`;
    }
    if (to == null || to.getTime() === emptyDate.getTime()) {
      return `${from.toLocaleDateString("en-US")} -`;
    }
    return `${from.toLocaleDateString("en-US")} - ${to.toLocaleDateString(
      "en-US"
    )}`;
  };

  static formatPercentage = (percent: string): string => {
    if (percent) {
      return `${parseInt(percent, 10)}%`;
    }
    return "-";
  };

  static newOrUsed = (purchased: string): string => {
    if (purchased === "U") {
      return "Used";
    }
    if (purchased === "N") {
      return "New";
    }
    return "-";
  };

  // Remove time + timezone without converting to local browser time
  // This is often needed for DOL as the time and timezone will be converted to the browser local time using luxon and date libraries.
  // However, the DOL is used to search for dates such as policy effective date which has no time/zone. Conversion to local timezones can cause it to go to the previous or next days.
  static getISODateWithoutTimeOrZone = (date: Date | string): string => {
    return typeof date === "string"
      ? DateTime.fromISO(date).toISODate() ?? ""
      : DateTime.fromJSDate(date).toISODate() ?? "";
  };
}
