<div class="flex justify-between w-full" aria-label="expand underwriting memos">
  <mat-panel-title class="flex-grow-0 flex-shrink-0">
    <h2 class="uw-memo-landing-header" id="underwritingMemoLanding">
      Underwriting Memos
    </h2>
  </mat-panel-title>
  <h5
    class="uw-memo-landing-subheader flex-grow-1"
    data-pgr-id="breakdownSummary"
  >
    {{ breakdownSummary }}
  </h5>
  <div class="flex-shrink-0 mr-s my-bl-1">
    <button
      mat-icon-button
      aria-label="refresh"
      data-pgr-id="btnRefresh"
      (click)="refreshMemos($event)"
      (keydown.enter)="refreshMemos($event)"
      class="mr-bl-2"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
