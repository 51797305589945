<cui-side-sheet>
  <cui-side-sheet-header
    closeButtonLabel="close preview pane"
    (close)="handleClose()"
    aria-label="Preview Sidesheet"
  >
    Preview
  </cui-side-sheet-header>
  <cui-side-sheet-content data-pgr-id="preview">
    <cla-preview-thumbnail></cla-preview-thumbnail>
    <cla-preview-item-details
      *ngIf="!isContentTypePackage"
    ></cla-preview-item-details>
    <cla-preview-share-package
      *ngIf="isContentTypePackage"
    ></cla-preview-share-package>
    <section class="pt-5">
      <cla-tags-section [tags]="previewedItem.tags"></cla-tags-section>
    </section>
  </cui-side-sheet-content>
</cui-side-sheet>
