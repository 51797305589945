import { Action, createReducer, on } from "@ngrx/store";

import { UwMemo } from "../../components/uw-memo-landing/models/uw-memo";

import * as underwritingMemoLandingActions from "./uw-memo-landing.actions";

export interface State {
  memos: UwMemo[];
  isRequestInFlight: boolean;
  requestFailed: boolean;
}

export const initialState: State = {
  memos: [],
  isRequestInFlight: false,
  requestFailed: false,
};

const uwMemoLandingReducer = createReducer(
  initialState,
  on(
    underwritingMemoLandingActions.onUnderwritingMemosByClaimNumberReceived,
    (state, action) => ({
      ...state,
      memos: action.memos,
      isRequestInFlight: false,
    })
  ),
  on(
    underwritingMemoLandingActions.onUnderwritingMemosByClaimNumberRequested,
    (state, action) => ({
      ...state,
      isRequestInFlight: true,
    })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return uwMemoLandingReducer(state, action);
}
