import { createSelector } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { CoverageState } from "../models/coverage-state.interface";

export const coverageState = (state: AppState) => state.coverageState;

export const cvqs = createSelector(
  coverageState,
  (state: CoverageState) => state.cvqs
);

export const isRequestInFlight = createSelector(
  coverageState,
  (state: CoverageState) => state.isRequestInFlight
);

export const isMemoSubmitted = createSelector(
  coverageState,
  (state: CoverageState) => state.isMemoSubmitted
);

export const cvqAdd = createSelector(
  coverageState,
  (state: CoverageState) => state.cvqAdd
);

export const cvqResolve = createSelector(
  coverageState,
  (state: CoverageState) => state.cvqResolve
);

export const rep = createSelector(
  coverageState,
  (state: CoverageState) => state.rep
);

export const cvqById = createSelector(
  coverageState,
  (state: CoverageState, props: any) =>
    state.cvqs?.find((cvq) => cvq.id === props.id)
);

export const policyProductCategoryAndStateCode = createSelector(
  coverageState,
  (state: CoverageState) => {
    return {
      productCode: state.policyProductCategoryCode!,
      stateCode: state.policyStateCode!,
      normalizedProductCategoryCode: state.policyNormalizedProductCategoryCode,
    };
  }
);

export const lossDatetime = createSelector(
  coverageState,
  (state: CoverageState) => state.lossDateTime
);

export const lienholders = createSelector(
  coverageState,
  (state: CoverageState) =>
    state.policyVehicles?.reduce(
      (accumulator, obj) => [...accumulator, ...obj.lienHolders!],
      []
    )
);

export const policyVehicles = createSelector(
  coverageState,
  (state: CoverageState) => state.policyVehicles
);

export const claimVehicles = createSelector(
  coverageState,
  (state: CoverageState) => state.claimVehicles
);

export const policyDrivers = createSelector(
  coverageState,
  (state: CoverageState) => state.policyDrivers
);

export const excludedDrivers = createSelector(
  coverageState,
  (state: CoverageState) => state.excludedDrivers
);

export const unlistedDrivers = createSelector(
  coverageState,
  (state: CoverageState) => state.unlistedDrivers
);

export const uwMemoCategory = createSelector(
  coverageState,
  (state: CoverageState) => state.uwMemoCategory
);

export const uwMemoType = createSelector(
  coverageState,
  (state: CoverageState) => state.uwMemoType
);

export const cvqAddNote = createSelector(
  coverageState,
  (state: CoverageState) => state.cvqAddNote
);

export const resolvedCvqCount = createSelector(cvqs, (resolvedCount: any) => {
  let resolvedCvqs = 0;
  if (resolvedCount !== undefined) {
    for (const cvq of resolvedCount) {
      if (cvq?.status === "Resolved") {
        resolvedCvqs++;
      }
    }
  }
  return resolvedCvqs;
});

export const isPolicyAttached = createSelector(
  coverageState,
  (state: CoverageState) => state.isPolicyAttached
);

export const policyNumber = createSelector(
  coverageState,
  (state: CoverageState) => state.policyNumber
);

export const uwMemoClaimData = createSelector(
  coverageState,
  (state: CoverageState) => state.claimLevel
);

export const cvqRequestError = createSelector(
  coverageState,
  (state: CoverageState) => state.cvqRequestError
);
