/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private readonly authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleRequest(request, next));
  }

  private async handleRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<any> {
    if (this.authenticationService.shouldRefreshToken()) {
      let token: string | null =
        await this.authenticationService.getRefreshToken(req, null);
      if (token) {
        token = `Bearer ${token}`;
        //HANDLE REFRESH LOGIC HERE
        req = req.clone({
          setHeaders: { Authorization: token },
        });
      }
    }
    return next.handle(req).toPromise();
  }
}
