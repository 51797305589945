<header>
  <cla-claim-header data-pgr-id="tleHeader"></cla-claim-header>
</header>
<mat-drawer-container>
  <mat-drawer
    #leftRail
    mwlResizable
    [enableGhostResize]="false"
    [style.width]="leftRailResizeService.leftRailWidth$ | async"
    (resizeStart)="leftRailResizeService.onResizeStart($event)"
    (resizing)="leftRailResizeService.onResize($event)"
    (resizeEnd)="leftRailResizeService.onResizeEnd($event)"
    [mode]="'side'"
    opened
    (openedStart)="leftRailResizeService.setDrawerOpen()"
    (closedStart)="leftRailResizeService.setDrawerClosed()"
    disableClose="true"
  >
    <div class="h-full flex">
      <cla-left-rail
        class="flex-col"
        cdkScrollable
        data-pgr-id="tleLeftNavBar"
      ></cla-left-rail>
      <div
        role="slider"
        tabindex="0"
        [attr.aria-valuemin]="
          leftRailResizeService.minimumPercentageWidthNumber
        "
        [attr.aria-valuenow]="
          leftRailResizeService.leftRailDesiredWidthPercentage
        "
        [attr.aria-valuemax]="
          leftRailResizeService.maximumPercentageWidthNumber
        "
        [attr.aria-orientation]="'horizontal'"
        [attr.aria-label]="
          'Loss Details Panel Separator. Use Left and Right Arrow Keys to Resize.'
        "
        class="flex-col resize-handle-right"
        [ngClass]="{
          'resize-handle-active': leftRailResizeService.isResizing$ | async
        }"
        claLeftAccessibleResizeHandle
        mwlResizeHandle
        [resizeEdges]="{ right: true }"
      ></div>
    </div>
  </mat-drawer>
  <mat-drawer-content
    [style.margin-left]="leftRailResizeService.widthMinMaxDebounce$ | async"
    [style.margin-right]="'0px'"
    class="disable-page-scrolling"
  >
    <div cdkTrapFocusAutoCapture>
      <cla-claim-sub-header
        #subheader
        (toggleLeftRail)="leftRail.toggle()"
        (toggleNotes)="notes.toggle()"
        (notesOpenedInNew)="notes.close()"
        cdkFocusRegionStart
      >
      </cla-claim-sub-header>
      <mat-drawer
        #notes
        position="end"
        mwlResizable
        [enableGhostResize]="false"
        [mode]="'side'"
        [style.width]="rightRailResizeService.widthMinMax$ | async"
        (resizeStart)="rightRailResizeService.onResizeStart($event)"
        (resizing)="rightRailResizeService.onResize($event)"
        (resizeEnd)="rightRailResizeService.onResizeEnd($event)"
        (openedStart)="rightRailResizeService.setDrawerOpen()"
        (closedStart)="rightRailResizeService.setDrawerClosed()"
        disableClose="true"
        class="overflow-y-hidden notes-side-drawer"
        [style.margin-top]="'4rem'"
        role="region"
        aria-label="Notes Side Panel"
        [autoFocus]="true"
      >
        <div
          role="slider"
          tabindex="0"
          [attr.aria-valuemin]="
            rightRailResizeService.minimumPercentageWidthNumber
          "
          [attr.aria-valuenow]="
            rightRailResizeService.rightRailDesiredWidthPercentage
          "
          [attr.aria-valuemax]="
            rightRailResizeService.maximumPercentageWidthNumber
          "
          [attr.aria-orientation]="'horizontal'"
          [attr.aria-label]="
            'Notes Panel Separator. Use Left and Right Arrow Keys to Resize.'
          "
          id="right-resize-rail"
          class="resize-handle-left"
          [ngClass]="{
            'resize-handle-active': rightRailResizeService.isResizing$ | async
          }"
          claRightAccessibleResizeHandle
          mwlResizeHandle
          [resizeEdges]="{ left: true }"
          cdkFocusInitial
        ></div>
        <cla-note-list class="w-full" cdkFocusRegionEnd></cla-note-list>
        <div>
          <cui-hidden-focus-switch-button
            [id]="'skipToNotesToggle'"
            [label]="'Skip To Notes Toggle'"
            [focusSwitchId]="'notes-toggle-button'"
          ></cui-hidden-focus-switch-button>
        </div>
      </mat-drawer>
    </div>
    <mat-drawer-content
      autosize
      [style.transition-duration]="'400ms'"
      [style.transition-timing-function]="'cubic-bezier(0.25, 0.8, 0.25, 1)'"
      [style.margin-left]="'0px'"
      [style.margin-right]="
        !notes.opened
          ? 0
          : (rightRailResizeService.widthMinMaxDebounce$ | async)
      "
      [ngClass]="
        (claimDetailsStateService.isPageScrollingEnabled$ | async)
          ? ''
          : 'disable-page-scrolling'
      "
    >
      <div class="sticky-banner">
        <cui-notification-banner
          *ngIf="
            claimDetailsStateService.successBannerMessage$
              | async as successBannerMessage
          "
          data-pgr-id="notificationBanner"
          type="success"
          hasBottomBorder="true"
          [dismissable]="true"
          (dismiss)="hideSuccessBanner()"
        >
          Success:
          <span data-pgr-id="successBanner">{{ successBannerMessage }}</span>
        </cui-notification-banner>
        <cui-notification-banner
          *ngIf="
            (claimDetailsStateService.errorBannerMessages$ | async)?.length > 0
          "
          data-pgr-id="notificationBanner"
          type="error"
          hasBottomBorder="true"
          [dismissable]="true"
          (dismiss)="hideErrorBanner()"
        >
          The following errors occurred, please try again:
          <ul class="cui-bulleted-list px-xxs mt-xxs">
            <li
              *ngFor="
                let error of claimDetailsStateService.errorBannerMessages$
                  | async
              "
            >
              <span data-pgr-id="errorBanner">{{ error }}</span>
            </li>
          </ul>
        </cui-notification-banner>
        <cui-notification-banner
          *ngIf="
            claimDetailsStateService.warningBannerMessage$ | async as warning
          "
          data-pgr-id="notificationBanner"
          type="warn"
          hasBottomBorder="true"
          [dismissable]="
            claimDetailsStateService.warningBannerIsDismissable$ | async
          "
          (dismiss)="hideWarningBanner()"
        >
          <span data-pgr-id="warningBanner">
            {{ warning }}
          </span>
        </cui-notification-banner>
      </div>
      <div class="h-full flex flex-col">
        <div class="grow" id="mainwindowoutlet">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-content>
  <mat-drawer-container></mat-drawer-container>
</mat-drawer-container>
