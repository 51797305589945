import { formatPolicyDriverName } from "@modules/shared/helpers";
import { Action, createReducer, on } from "@ngrx/store";
import { DateTime } from "luxon";
import * as claimDetailsActions from "./claim-details.actions";
import { ClaimDetailsState, initialState } from "./models";

const claimDetailsReducer = createReducer(
  initialState,
  on(
    claimDetailsActions.onSetAppIntegrationType,
    (state: ClaimDetailsState, { appIntegrationType }) => ({
      ...state,
      appIntegrationType,
      isInitialized: true,
    })
  ),
  on(
    claimDetailsActions.onClaimDetailsRequest,
    (state: ClaimDetailsState, { claimNumber }) => ({
      ...state,
      claimNumber,
      isClaimDetailsRequestInFlight: true,
    })
  ),
  on(
    claimDetailsActions.onClaimDetailsReceipt,
    (state: ClaimDetailsState, { payload }) =>
      ({
        ...state,
        claimDetails: {
          ...payload,
          claimLossDate: payload.claimLossDate
            ? DateTime.fromISO(payload.claimLossDate, {
                setZone: true,
              }).toISO({ includeOffset: false, suppressMilliseconds: true })
            : payload.claimLossDate,
          claimReportDate: payload.claimReportDate
            ? DateTime.fromISO(payload.claimReportDate, {
                setZone: true,
              }).toISO({ includeOffset: false, suppressMilliseconds: true })
            : payload.claimReportDate,
          policyRenewalSuffixNumber:
            payload.policyRenewalSuffixNumber &&
            payload.policyRenewalSuffixNumber != ""
              ? payload.policyRenewalSuffixNumber
              : "0",
        },
        isClaimDetailsRequestInFlight: false,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.onClaimDetailsError,
    (state: ClaimDetailsState, { error }) =>
      ({
        ...state,
        claimDetailsError: error,
        isClaimDetailsRequestInFlight: false,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.onClaimSummaryRequest,
    (state: ClaimDetailsState, { claimNumber }) => ({
      ...state,
      claimNumber,
      isClaimSummaryRequestInFlight: true,
    })
  ),
  on(
    claimDetailsActions.onClaimSummaryReceipt,
    (state: ClaimDetailsState, { payload }) =>
      ({
        ...state,
        claimSummary: payload,
        isClaimSummaryRequestInFlight: false,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.onClaimSummaryError,
    (state: ClaimDetailsState, { error }) =>
      ({
        ...state,
        claimSummaryError: error,
        isClaimSummaryRequestInFlight: false,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.onPartyPropertyDetailsRequest,
    (state: ClaimDetailsState) => ({
      ...state,
      isPartyPropertyDetailsRequestInFlight: true,
    })
  ),
  on(
    claimDetailsActions.onPartyPropertyDetailsReceipt,
    (state: ClaimDetailsState, { payload }) => {
      return {
        ...state,
        partyPropertyDetails: payload,
        isPartyPropertyDetailsRequestInFlight: false,
      } as ClaimDetailsState;
    }
  ),
  on(
    claimDetailsActions.onPartyPropertyDetailsError,
    (state: ClaimDetailsState) => ({
      ...state,
      isPartyPropertyDetailsRequestInFlight: false,
    })
  ),
  on(
    claimDetailsActions.setClaimNumber,
    (state: ClaimDetailsState, { claimNumber }) => ({
      ...state,
      claimNumber,
    })
  ),
  on(
    claimDetailsActions.setSuccessBannerMessage,
    (state: ClaimDetailsState, { message }) =>
      ({
        ...state,
        successBannerMessage: message,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.setErrorBannerMessages,
    (state: ClaimDetailsState, { messages }) =>
      ({
        ...state,
        errorBannerMessages: messages,
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.setWarningBannerMessage,
    (state: ClaimDetailsState, { message, isDismissable }) => ({
      ...state,
      warningBannerMessage: message,
      warningBannerIsDismissable: isDismissable,
    })
  ),
  on(
    claimDetailsActions.onGetPolicyDriversRequest,
    (state: ClaimDetailsState) => ({
      ...state,
      associatedPolicyDrivers: {
        isLoading: true,
        data: null,
        error: null,
      },
    })
  ),
  on(
    claimDetailsActions.onGetPolicyDriversResponse,
    (state: ClaimDetailsState, { payload }) => {
      const driversWithFormattedName = payload.map((x) => {
        return { ...x, partyName: formatPolicyDriverName(x) };
      });
      return {
        ...state,
        associatedPolicyDrivers: {
          isLoading: false,
          data: driversWithFormattedName,
          error: null,
        },
      } as ClaimDetailsState;
    }
  ),
  on(
    claimDetailsActions.onGetPolicyDriversError,
    (state: ClaimDetailsState, { error }) => ({
      ...state,
      associatedPolicyDrivers: {
        isLoading: false,
        data: null,
        error,
      },
    })
  ),

  on(
    claimDetailsActions.onClaimReportedViaRequest,
    (state: ClaimDetailsState) => ({
      ...state,
      isClaimReportedViaRequestInFlight: true,
    })
  ),
  on(
    claimDetailsActions.onClaimReportedViaReceipt,
    (state: ClaimDetailsState, { payload }) =>
      ({
        ...state,
        isClaimReportedViaRequestInFlight: false,
        claimDetails: {
          ...state.claimDetails,
          claimReportedVia: payload,
        },
      } as ClaimDetailsState)
  ),
  on(
    claimDetailsActions.setPageScrollingEnabled,
    (state: ClaimDetailsState, { isEnabled }) => ({
      ...state,
      isPageScrollingEnabled: isEnabled,
    })
  )
);

export function reducer(
  state: ClaimDetailsState | undefined,
  action: Action
): ClaimDetailsState {
  return claimDetailsReducer(state, action);
}
