import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SubrogationSummaryComponent } from "./subrogation-summary.component";

const summaryRoutes: Routes = [
  {
    path: "",
    component: SubrogationSummaryComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(summaryRoutes)],
  exports: [RouterModule],
})
export class SubrogationSummaryRoutingModule {}
