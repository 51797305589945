import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "nullDefault",
})
export class NullDefaultPipe implements PipeTransform {
  transform(value: any, defaultText: string = ""): any {
    if (value === undefined || value === null) {
      return defaultText;
    }

    return value;
  }
}
