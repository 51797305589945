import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claDigitReader",
})
export class DigitReaderPipe implements PipeTransform {
  transform(value: string | null) {
    if (value === undefined || value === null || value!.trim() === "") {
      return "";
    }
    return value.split("").join(" ");
  }
}
