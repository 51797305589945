import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, mergeMap, withLatestFrom } from "rxjs/operators";

import * as recoveryHistoryActions from "../actions/recovery-history.actions";
import { RecoveryHistoryItem } from "../../models";
import { ClaimDetailsStateService } from "@modules/core/state";
import { SubrogationSummaryService } from "@subrogation-summary/services";

@Injectable()
export class RecoveryHistoryEffects {
  loadRecoveryHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recoveryHistoryActions.loadRecoveryHistory),
      withLatestFrom(this.claimDetailsStateService.claimNumber$),
      mergeMap(([action, claimNumber]) =>
        this.subrogationSummaryService
          .getRecoveryHistory(claimNumber as string, action.partySequenceNumber)
          .pipe(
            mergeMap((recoveries: RecoveryHistoryItem[]) => {
              return of(
                recoveryHistoryActions.loadRecoveryHistorySucceeded({
                  subroObligationId: action.subroObligationId,
                  recoveryHistoryItems: recoveries,
                })
              );
            }),
            catchError((err) =>
              of(
                recoveryHistoryActions.loadRecoveryHistoryFailed({
                  subroObligationId: action.subroObligationId,
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private claimDetailsStateService: ClaimDetailsStateService,
    private subrogationSummaryService: SubrogationSummaryService
  ) {}
}
