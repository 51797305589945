<div class="flex items-center">
  <mat-form-field
    class="flex-shrink-0 w-12 mr-4"
    style="margin-right: 15px"
    appearance="outline"
  >
    <mat-label aria-label="search type">Search type</mat-label>
    <mat-select
      [ngModel]="searchTypeValues.Grid"
      (selectionChange)="clearSearch()"
      data-pgr-id="ddl-search-type"
      #searchType
    >
      <mat-option [value]="searchTypeValues.Grid" aria-label="grid">
        Grid
      </mat-option>
      <mat-option [value]="searchTypeValues.Content" aria-label="contents">
        Content
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <!-- -ms-user-select IE11 hack for focusing user input on clear; only works as inline style -->
    <input
      matInput
      placeholder="Enter search term(s)"
      #searchTerm
      (keydown.enter)="submitContentSearch()"
      aria-label="search"
      data-pgr-id="contentSummarySearchInput"
    />
    <button
      mat-icon-button
      (click)="submitContentSearch()"
      [cuiInteractionEvent]="
        contentSummaryEventService.clickAction(actionNames.search)
      "
      data-pgr-id="btnSearch"
      aria-label="Search"
      matSuffix
      color="primary"
    >
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</div>
