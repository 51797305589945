import { HttpErrorResponse } from "@angular/common/http";
import {
  ClaimDetails,
  ClaimSummary,
  ClaimSyncSaveDetails,
  EntityDataState,
  PartyPropertyDetail,
} from "@modules/core/models";
import { PolicyDriver } from "@modules/core/models/policy-driver.type";

export enum AppIntegrationType {
  CLAIMPRO_EMBEDDED,
  CLAIMPRO_CHILD,
  CLAIMSTATION_EMBEDDED,
  CLAIMSTATION_CHILD,
  STANDALONE,
  STANDALONE_CHILD,
}

export interface ClaimDetailsState {
  isInitialized: boolean;
  appIntegrationType: AppIntegrationType | null;
  claimNumber: string;
  isClaimDetailsRequestInFlight: boolean;
  isClaimReportedViaRequestInFlight: boolean;
  isFileOwnerDetailsRequestInFlight: boolean;
  isPartyPropertyDetailsRequestInFlight: boolean;
  isEditLossDetailsRequestInFlight: boolean;
  claimDetails: ClaimDetails | undefined;
  claimDetailsError: HttpErrorResponse | undefined;
  claimSummary: ClaimSummary | undefined;
  claimSummaryError: HttpErrorResponse | undefined;
  partyPropertyDetails: PartyPropertyDetail[] | undefined;
  editLossDetailsError: HttpErrorResponse | undefined;
  successBannerMessage: string;
  errorBannerMessages: string[];
  warningBannerMessage: string;
  warningBannerIsDismissable: boolean;
  associatedPolicyDrivers: EntityDataState<PolicyDriver[]>;
  claimSyncSaveDetails: ClaimSyncSaveDetails;
  isPageScrollingEnabled: boolean;
}
