import { ClaimSummaryState } from "./claim-summary-state.interface";

export const initialState: ClaimSummaryState = {
  isFeaturesRequestInFlight: false,
  features: [],
  featuresTableData: [],
  featuresError: undefined,
  isLiabilityRequestInFlight: false,
  liabilityData: undefined,
  liabilityError: undefined,
  isCoveragesRequestInFlight: false,
  coverages: undefined,
  coveragesError: undefined,
};
