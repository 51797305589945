export const navigateAwayMessaging = Object.freeze({
  title: "Did you want to leave without saving?",
  message: "Changes you made will not be saved. Cancel to return.",
  cancelButtonText: "Cancel",
  confirmButtonText: "Leave",
});

export const contactPreferenceWarningMessaging = Object.freeze({
  title: "Are you sure you would like to submit?",
  message:
    "You have entered contact info, but no Preferred Contact. Cancel to return to pick a Preferred Contact.",
  cancelButtonText: "Cancel",
  confirmButtonText: "Continue",
});

export const removeAssociatedPartyMessaging = Object.freeze({
  title: "Remove associated party?",
  message:
    "Removing this relationship will not delete {associatedPartyName} from the claim. Are you sure?",
  cancelButtonText: "Cancel",
  confirmButtonText: "Remove",
});

export const deleteAttorneyMessaging = Object.freeze({
  title: "Delete attorney {attorneyName}?",
  message: "Any information previously saved will be deleted. Are you sure?",
  cancelButtonText: "Cancel",
  confirmButtonText: "Delete",
});

export const copyAttorneyWarningMessaging = Object.freeze({
  title: "Overwrite information?",
  message:
    "This may overwrite address/phone fields you have already entered. Do you want to continue?",
  cancelButtonText: "Cancel",
  confirmButtonText: "Overwrite",
});

export const medicalWindowOpenMessaging = Object.freeze({
  title: "Medical Summary Window Already Open",
  message: "Please close the existing window before opening a new one.",
  cancelButtonText: "Cancel",
  confirmButtonText: "Show Existing Window",
});

export const attorneyFeatureMessaging = Object.freeze({
  someSelected:
    "Some features cannot be selected because they are already associated to another attorney representing this party.",
  allSelected:
    "No features can be selected because they are all already associated to another attorney representing this party.",
});

export const medicalDisabledMessaging = Object.freeze({
  pip: "PIP access is required to access Medical Summary.",
  feature:
    "An injury feature for this party is required to access Medical Summary.",
  injuryLevel:
    "The injury level for this party must be known to access Medical Summary.",
});

export const togglePartyTypeMessaging = Object.freeze({
  title: "Change party?",
  message:
    "Warning: If you proceed, data in some fields will be lost. Cancel to return.",
  cancelButtonText: "Cancel",
  confirmButtonText: "Continue",
});

export const cannotChangePartyTypeOwnerExists =
  "Party type cannot be changed to Business. Associated Property has an owner.";
export const cannotChangePartyTypeNamedInsured =
  "Named Insured party type cannot be changed.";
