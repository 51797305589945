import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppIntegrationType, ClaimDetailsStateService } from "@core/state";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models/claims-summary-config-info.interface";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { WindowService } from "@pgr-cla/cla-window";
import { first, map, Observable } from "rxjs";

@Injectable()
export class AppResolverService implements Resolve<boolean> {
  constructor(
    private claimDetailsStateService: ClaimDetailsStateService,
    private windowService: WindowService,
    private configurationService: ConfigurationService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.configurationService.configuration$.pipe(
      first((x) => !!x),
      map((configuration: ClaimsSummaryConfigInfo) => {
        const referrer = this.windowService.document.referrer;
        let type: AppIntegrationType;

        if (route.queryParamMap.get("isClaimProEmbedded")) {
          type = AppIntegrationType.CLAIMPRO_EMBEDDED;
        } else if (referrer.startsWith(configuration.claimProBaseUrl)) {
          type = AppIntegrationType.CLAIMPRO_CHILD;
        } else if (route.queryParamMap.get("isEmbedded")) {
          type = AppIntegrationType.CLAIMSTATION_EMBEDDED;
          // } else if (this.claimStationService.isClaimStation()) {
          //   type = AppIntegrationType.CLAIMSTATION_CHILD;
        } else if (
          referrer.startsWith(this.windowService.location?.origin as string)
        ) {
          type = AppIntegrationType.STANDALONE_CHILD;
        } else {
          type = AppIntegrationType.STANDALONE;
        }

        this.claimDetailsStateService.onSetAppIntegrationType(type);
        return true;
      })
    );
  }
}
