<main role="main" data-pgr-id="tleFeatureHistory" class="h-full">
  <mat-sidenav-container class="h-full">
    <mat-sidenav
      position="end"
      mode="side"
      [autoFocus]="true"
      #sideSheet
      role="complementary"
    >
      <div
        *ngIf="sideSheet.opened"
        cdkTrapFocus
        cdkTrapFocusAutoCapture
        class="side-sheet"
      ></div>
      <cla-revision-history-filter #historyFilterDrawer>
      </cla-revision-history-filter>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="w-full">
        <div class="mt-xxs mx-xs relative">
          <div class="flex flex-wrap justify-between">
            <div class="flex justify-between">
              <h1
                class="cui-h1 mb-xxs"
                id="main-content-header"
                data-pgr-id="lblFeatureHistory"
                tabindex="-1"
              >
                Feature &amp; Reserve History
              </h1>
            </div>
          </div>
          <div class="max-table-width">
            <div class="filter-btn-row">
              <button
                mat-stroked-button
                (click)="onOpenFilter()"
                color="primary"
                data-pgr-id="btnOpenFilters"
                class="z-20"
              >
                <mat-icon matPrefix>filter_list</mat-icon>
                Filter
              </button>
            </div>
            <cla-feature-revision-history
              *ngIf="features$ | async as features"
              [claimNumber]="claimNumber"
              [features]="features"
            >
            </cla-feature-revision-history>
            <cui-spinner
              *ngIf="(isLoading$ | async) === true"
              ariaLabel="Feature and Reserve History Loading"
            ></cui-spinner>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
