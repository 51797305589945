import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ClaimSummaryEffects } from "./effects";
import * as claimSummaryReducer from "./reducers";
import { ClaimSummaryStateService } from "./services";

@NgModule({
  imports: [
    StoreModule.forFeature("claimSummaryState", claimSummaryReducer.reducer),
    EffectsModule.forFeature([ClaimSummaryEffects]),
  ],
  providers: [ClaimSummaryStateService],
})
export class ClaimsSummaryStateModule {}
