import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { MessageData } from "@pgr-cla/cla-window/lib/services/window-management/models";
import * as claimLandingActions from "../../actions";

@Injectable()
export class ClaimsLandingStateService {
  constructor(private store$: Store, private ngZone: NgZone) {}

  onActionItemsRouteRequest = (event: MessageData): void => {
    this.ngZone.run(() =>
      this.store$.dispatch(claimLandingActions.actionItemNavigation({ event }))
    );
  };
}
