import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ClaimSummaryState } from "../models/claim-summary-state.interface";

export const claimSummaryState =
  createFeatureSelector<ClaimSummaryState>("claimSummaryState");

export const selectFeatures = createSelector(
  claimSummaryState,
  (state) => state.features
);

export const selectFeaturesTableData = createSelector(
  claimSummaryState,
  (state) => state.featuresTableData
);

export const selectFeaturesError = createSelector(
  claimSummaryState,
  (state) => state.featuresError
);

export const selectIsFeaturesRequestInFlight = createSelector(
  claimSummaryState,
  (state) => state.isFeaturesRequestInFlight
);

export const selectLiability = createSelector(
  claimSummaryState,
  (state) => state.liabilityData
);

export const selectLiabilityError = createSelector(
  claimSummaryState,
  (state) => state.liabilityError
);

export const selectIsLiabilityRequestInFlight = createSelector(
  claimSummaryState,
  (state) => state.isLiabilityRequestInFlight
);

export const selectCoverages = createSelector(
  claimSummaryState,
  (state) => state.coverages
);

export const selectCoveragesError = createSelector(
  claimSummaryState,
  (state) => state.coveragesError
);

export const selectIsCoveragesRequestInFlight = createSelector(
  claimSummaryState,
  (state) => state.isCoveragesRequestInFlight
);
