import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { onUnderwritingMemosByClaimNumberRequested } from "@modules/policy/state/uw-memo-landing/uw-memo-landing.actions";
import { selectRequestinFlight } from "@modules/policy/state/uw-memo-landing/uw-memo-landing.selectors";
import { Store } from "@ngrx/store";
import { WindowManagementService, WindowService } from "@pgr-cla/cla-window";
import { AppState } from "@store";
import { Observable } from "rxjs";

import { UwMemoStatusConstants } from "../../helpers/uw-memo-landing-constants";
import { UwMemo } from "../../models/uw-memo";

@Component({
  selector: "cla-uw-memo-landing-header",
  templateUrl: "./uw-memo-landing-header.component.html",
  styleUrls: ["./uw-memo-landing-header.component.scss"],
})
export class UwMemoLandingHeaderComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() claimNumber: string;
  @Input() underwritingMemos: UwMemo[];

  completedCount: number;
  completedNoChangeCount: number;
  inProgressCount: number;
  missingInfoCount: number;
  retractedCount: number;
  sentCount: number;
  breakdownSummary: string;
  breakdownCounts: any[];
  childWindowId: string = "uwmemoadd";

  requestInFlight$: Observable<boolean>;

  constructor(
    private windowService: WindowService,
    private store$: Store<AppState>,
    private windowManagementService: WindowManagementService
  ) {}

  ngOnDestroy(): void {
    if (this.windowManagementService.isChildWindowOpen(this.childWindowId)) {
      this.windowManagementService.closeChildWindow(this.childWindowId);
    }
  }

  ngOnInit(): void {
    this.requestInFlight$ = this.store$.select(selectRequestinFlight);
  }

  ngOnChanges(): void {
    this.calculateStatusCount();
    this.formatStatusSummary();
  }

  calculateStatusCount(): void {
    this.completedCount = 0;
    this.completedNoChangeCount = 0;
    this.inProgressCount = 0;
    this.missingInfoCount = 0;
    this.retractedCount = 0;
    this.sentCount = 0;

    if (this.underwritingMemos !== null) {
      this.underwritingMemos.map((memo) => {
        switch (memo.statusDesc) {
          case UwMemoStatusConstants.COMPLETED:
            this.completedCount++;
            break;
          case UwMemoStatusConstants.COMPLETED_NO_CHANGE:
            this.completedNoChangeCount++;
            break;
          case UwMemoStatusConstants.IN_PROGRESS:
            this.inProgressCount++;
            break;
          case UwMemoStatusConstants.MISSING_INFO:
            this.missingInfoCount++;
            break;
          case UwMemoStatusConstants.RETRACTED:
            this.retractedCount++;
            break;
          case UwMemoStatusConstants.SENT:
            this.sentCount++;
            break;
        }
      });
    }
  }

  assignCount(status: string, count: number) {
    const element = this.breakdownCounts.find((elem) => elem.status === status);
    // eslint-disable-next-line functional/immutable-data
    element.count = count;
  }

  formatStatusSummary(): void {
    this.breakdownCounts = Object.values(UwMemoStatusConstants).map(
      (value) => ({
        status: value,
        count: 0,
      })
    );

    this.assignCount(UwMemoStatusConstants.COMPLETED, this.completedCount);
    this.assignCount(
      UwMemoStatusConstants.COMPLETED_NO_CHANGE,
      this.completedNoChangeCount
    );
    this.assignCount(UwMemoStatusConstants.IN_PROGRESS, this.inProgressCount);
    this.assignCount(UwMemoStatusConstants.MISSING_INFO, this.missingInfoCount);
    this.assignCount(UwMemoStatusConstants.RETRACTED, this.retractedCount);
    this.assignCount(UwMemoStatusConstants.SENT, this.sentCount);

    if (this.breakdownCounts.find((elem) => elem.count > 0)) {
      this.breakdownSummary = this.breakdownCounts
        .filter((elem) => elem.count > 0)
        .map((elem) => `${elem.count} ${elem.status}`)
        .join(", ");
    } else {
      this.breakdownSummary = "0 Sent";
    }
  }

  refreshMemos(event: any) {
    event.stopPropagation();
    if (this.claimNumber !== undefined) {
      this.store$.dispatch(
        onUnderwritingMemosByClaimNumberRequested({
          claimNumber: this.claimNumber,
        })
      );
    }
  }

  openUwMemo(event: any): void {
    this.windowManagementService.openChildWindow(
      this.childWindowId,
      `${this.windowService.window.location.origin}/coverage/${this.claimNumber}/uw-memo`
    );
    event?.stopImmediatePropagation();
  }
}
