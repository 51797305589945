import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@modules/shared";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { DiaryRoutingModule } from "./diary.routing.module";
import { DiaryComponent, DiaryTableComponent } from "@diary/components";
import { DiaryEffects } from "@diary/effects";
import * as diaryReducers from "@diary/reducers";
import { DiaryApiService, DiaryStateService } from "@diary/services";
import { DialogService } from "@pgr-cla/core-ui-components";

@NgModule({
  declarations: [DiaryComponent, DiaryTableComponent],
  imports: [
    CommonModule,
    DiaryRoutingModule,
    SharedModule,
    StoreModule.forFeature("diary", diaryReducers.reducer),
    EffectsModule.forFeature([DiaryEffects]),
  ],
  providers: [DiaryApiService, DiaryStateService, DialogService],
})
export class DiaryModule {}
