import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, combineLatest, firstValueFrom } from "rxjs";
import { debounceTime, filter, take, takeUntil } from "rxjs/operators";

import { CookieTokenService } from "./cookie-token.service";
import { PingTokenService } from "./ping-token.service";

/*
  The authentication service will be responsible for retrieving auth tokens from 3 possible sources
  - cookie store
  - iframe (via routing to the auth server URL)
*/
@Injectable({
  providedIn: "root",
})
export class AuthenticationService implements OnDestroy {
  readonly token$: Observable<string | null>;
  readonly tokenExpiresDate$: Observable<string | null>;
  private tokenExpiresDate: string | null = null;
  private accessTokenExpiry: number;
  private readonly unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private readonly cookieTokenService: CookieTokenService,
    private readonly pingTokenService: PingTokenService
  ) {
    this.accessTokenExpiry = 10;
    const isNotNull = (token: string): boolean => token !== null;

    // Check if there's an existing token saved
    this.cookieTokenService.requestToken();

    this.token$ = this.cookieTokenService.token$.pipe(filter(isNotNull));
    this.tokenExpiresDate$ = this.cookieTokenService.tokenExpiresDate$.pipe(
      filter(isNotNull)
    );

    this.tokenExpiresDate$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((e: string | null) => (this.tokenExpiresDate = e));

    combineLatest([
      this.pingTokenService.token$,
      this.cookieTokenService.token$,
    ])
      .pipe(debounceTime(0), takeUntil(this.unsubscribeSubject$))
      .subscribe(([pingToken, cookieToken]) => {
        if (!cookieToken) {
          if (!pingToken) {
            // We don't have a valid token, request one from remote
            this.pingTokenService.requestToken();
          } else if (pingToken) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.setToken(pingToken!);
          }
        }
      });
  }

  setToken(token: string): void {
    const expirationDate: Date = new Date();
    expirationDate.setHours(expirationDate.getHours() + this.accessTokenExpiry);

    this.cookieTokenService.setToken(token, expirationDate, "/");
  }

  authorize = () => this.pingTokenService.authorize();

  completeAuthorizationRequest = () =>
    this.pingTokenService.completeAuthorizationRequest();

  manualLogin(): Observable<string | null> {
    this.pingTokenService.clearToken();
    this.pingTokenService.setMessageTokenSubjectError("Started");

    this.pingTokenService.requestToken();
    this.pingTokenService.token$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((token: string): void => {
        if (token) {
          this.setToken(token);
          this.reloadPage();
        }
      });
    return this.pingTokenService.remoteTokenError$;
  }

  refreshToken(): void {
    this.cookieTokenService.clearToken();
    this.pingTokenService.clearToken();
  }

  async signOut(): Promise<void> {
    await this.pingTokenService.signOut();
    this.cookieTokenService.clearToken();
  }

  async getRefreshToken(req: any, res: any): Promise<string | null> {
    const accessTokenExpiration = this.accessTokenExpiry;
    const tokenResponse$: Observable<string | null> = this.pingTokenService
      .getRefreshToken(accessTokenExpiration, req, res)
      .pipe(take(1));
    const token = await firstValueFrom(tokenResponse$);
    if (token) {
      const expirationDate: Date = new Date();
      expirationDate.setHours(
        expirationDate.getHours() + accessTokenExpiration
      );
      this.cookieTokenService.setToken(token, expirationDate, "/");
    }
    return token;
  }

  get isTokenCookieExpired(): boolean {
    return this.cookieTokenService.isCookieTokenExpired;
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  shouldRefreshToken(): boolean {
    if (this.tokenExpiresDate) {
      const currentDate: Date = new Date();
      const expirationDate: Date = new Date(this.tokenExpiresDate);
      const hoursBetween = this.getHoursBetweenDates(
        currentDate,
        expirationDate
      );
      if (hoursBetween <= 1) {
        return true;
      }
    }
    return false;
  }

  private getHoursBetweenDates(dateOne: Date, dateTwo: Date) {
    let diff = (dateTwo.getTime() - dateOne.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.round(diff);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
