import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
} from "@angular/core";

import { find, findIndex } from "lodash";

@Directive({
  selector: "[cla-a11y-drag]",
  exportAs: "claA11yDrag",
})
export class A11yDragDirective implements OnInit, OnDestroy {
  @HostBinding("ariaLabel") public get directiveAriaLabel(): string {
    return `Press ${this.activeToggle} to select ${this.itemName} for reordering`;
  }
  @HostBinding("class.isActive")
  public get isActive(): boolean {
    return this.dropList.activeItem === this.primaryKey;
  }
  @Input() public activeToggle: string = "Enter";
  @Input() public allowedKeys: string[] = ["ArrowUp", "ArrowDown"];
  @HostBinding("attr.role") public readonly directiveRole: string = "button";
  @HostBinding("tabIndex") public readonly directiveTabIndex: string = "0";
  @HostBinding("id") public id: string;

  @Input() public itemName: string;
  @Input() public primaryKey: string;

  constructor(
    public readonly dropList: A11yDropListDirective,
    public readonly el: ElementRef
  ) {}

  public handleClickOutside = (event: Event): void => {
    if (this.isActive) {
      const pathIds: string[] = (event as any)
        .propagationPath()
        .map((x: Element) => x.id);

      if (!pathIds.includes(this.el.nativeElement.id)) {
        this._handleActiveToggle();
      }
    }
  };

  @HostListener("keydown", ["$event"])
  public handleKeydown(evt: KeyboardEvent): void {
    evt.stopImmediatePropagation();

    if (evt.key === this.activeToggle) {
      this._handleActiveToggle(evt);
    } else if (this.allowedKeys.includes(evt.key) && this.isActive) {
      this._handleAllowedKey(evt);
    } else if (evt.key === "Tab" && this.isActive) {
      evt.preventDefault();
    }
  }

  public ngOnDestroy(): void {
    window.removeEventListener("click", this.handleClickOutside);
    if (this.isActive) {
      this._handleActiveToggle();
    }
  }

  public ngOnInit(): void {
    this.id = this.primaryKey;
    window.addEventListener("click", this.handleClickOutside);
  }

  private _handleActiveToggle(evt?: Event): void {
    evt?.preventDefault();

    const nextState: boolean = !this.dropList.isActive();
    this.dropList.activeItem = nextState ? this.primaryKey : undefined;

    if (!nextState) {
      this.dropList.a11yDropListDropped.emit(this.primaryKey);
    }
  }

  private _handleAllowedKey(evt: KeyboardEvent) {
    evt.preventDefault();

    this.dropList.handleSimulatedDrag(this.primaryKey, evt.key);

    setTimeout(() => this.el.nativeElement.focus());
  }
}

@Directive({
  selector: "[cla-a11y-drop-list]",
  exportAs: "claA11yDropList",
})
export class A11yDropListDirective implements AfterViewInit {
  @Output() public a11yDropListDropped: EventEmitter<string> =
    new EventEmitter();
  @Output() public a11yDropListReordered: EventEmitter<CdkDragDrop<string[]>> =
    new EventEmitter();
  public activeItem: string | undefined;
  @ContentChildren(A11yDragDirective)
  public children: QueryList<A11yDragDirective>;
  @HostBinding("attr.role") public readonly directiveRole: string =
    "application";
  @Input() public orientation: string = "vertical";

  constructor(
    public dropList: CdkDropList,
    private readonly _renderer: Renderer2,
    public _el: ElementRef
  ) {}

  public handleSimulatedDrag(itemId: string, key: string): void {
    const forward: string =
      this.orientation === "horizontal" ? "ArrowRight" : "ArrowDown";
    const backward: string =
      this.orientation === "horizontal" ? "ArrowLeft" : "ArrowUp";
    const index: number = findIndex(
      this.children.toArray(),
      (x: A11yDragDirective) => x.primaryKey === itemId
    );

    const item: CdkDrag<any> = find(
      this.children.toArray(),
      (x: A11yDragDirective) => x.primaryKey === itemId
    ) as unknown as CdkDrag;

    if (key === forward) {
      this.a11yDropListReordered.emit(this._generateDragDrop(item, index));
    } else if (key === backward) {
      this.a11yDropListReordered.emit(
        this._generateDragDrop(item, index, false)
      );
    }
  }
  public isActive(): boolean {
    return !!this.activeItem;
  }

  public ngAfterViewInit(): void {
    const testNode: HTMLElement = document.createElement("em");
    testNode.classList.add("mb-xxs");
    testNode.classList.add("text-sm");
    testNode.classList.add("block");
    // eslint-disable-next-line functional/immutable-data
    testNode.innerText =
      "To reorder columns with your keyboard, press the enter key to select or deselect an item and move with the arrow keys";
    this._renderer.insertBefore(
      this._el.nativeElement,
      testNode,
      this.children.first.el.nativeElement
    );
  }

  private _generateDragDrop(
    item: CdkDrag,
    index: number,
    increment: boolean = true
  ): CdkDragDrop<any> {
    return {
      event: new TouchEvent("dragDrop"),
      item,
      previousIndex: index,
      currentIndex: increment ? index + 1 : index - 1,
      container: this.dropList,
      previousContainer: this.dropList,
      isPointerOverContainer: true,
      distance: {
        x: 0,
        y: 0,
      },
      dropPoint: { x: 0, y: 0 },
    };
  }
}
