<mat-card class="p-0 overflow-x-auto policy-tab-card">
  <table
    mat-table
    data-prg-id="vehicleInfoTable"
    multiTemplateDataRows
    [dataSource]="dataSource"
    class="cui-table"
  >
    <!-- Vehicle Name Column -->
    <ng-container matColumnDef="vehicleName">
      <th mat-header-cell class="cui-cell-4" *matHeaderCellDef>Vehicle Name</th>
      <td
        mat-cell
        class="cui-cell-4 bottom-borderless"
        *matCellDef="let element"
      >
        {{ element.vehicleName | titlecase }}
      </td>
    </ng-container>

    <!-- VIN Column -->
    <ng-container matColumnDef="vin">
      <th mat-header-cell class="cui-cell-4" *matHeaderCellDef>VIN</th>
      <td
        mat-cell
        class="cui-cell-4 bottom-borderless"
        *matCellDef="let element"
      >
        <cui-copy-button ariaLabel="copy vin" [dataToCopy]="element.vin">
          <span>
            {{ element.vin }}
          </span>
          &nbsp;
        </cui-copy-button>
      </td>
    </ng-container>

    <!-- Stated Amount Column -->
    <ng-container matColumnDef="statedAmount">
      <th mat-header-cell class="cui-cell-4" *matHeaderCellDef>
        Stated Amount
      </th>
      <td
        mat-cell
        class="cui-cell-4 bottom-borderless"
        *matCellDef="let element"
      >
        {{ element.statedAmount | currency | nullDefault: "-" }}
      </td>
    </ng-container>

    <!-- Use Column -->
    <ng-container matColumnDef="use">
      <th mat-header-cell class="cui-cell-4" *matHeaderCellDef>Use</th>
      <td
        mat-cell
        class="cui-cell-4 bottom-borderless"
        *matCellDef="let element"
      >
        {{ element.use.description | titlecase | nullDefault: "Unknown" }}
      </td>
    </ng-container>

    <!-- Coverage Column -->
    <ng-container matColumnDef="coverage">
      <th>Coverages</th>
      <td
        mat-cell
        [attr.colspan]="displayedColumns.length"
        *matCellDef="let element"
      >
        <cla-coverage-descriptions
          [multiColumn]="true"
          [vehicleLimitsAndCoverages]="element.vehicleLimitsAndCoverages"
        >
        </cla-coverage-descriptions>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: ['coverage']"></tr>
  </table>
  <mat-paginator #pager hidePageSize pageSize="25" showFirstLastButtons>
    pager.getNumberOfPages()</mat-paginator
  >
</mat-card>
