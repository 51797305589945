import { CurrencyPipe } from "@angular/common";
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import {
  MatSort,
  MatSortable,
  Sort,
  SortDirection,
} from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { HelperFunctions } from "@modules/feature-revision-history/helpers/util";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AppState } from "../../models/core/app-state.interface";
import { Feature } from "../../models/feature-revision";
import { FeatureHistory } from "../../models/feature-revision/feature-history";
import { FeatureRevisionStoreService } from "../../services/feature-revision-store/feature-revision-store.service";
import { TableDataService } from "../../services/table-data";
import { spinnerSelectors } from "../../state/selectors";
import { selectGetHistoryError } from "../../state/selectors/history.selectors";

interface MatSortableAnimation extends MatSortable {
  _setAnimationTransitionState: () => void;
}

@Component({
  selector: "cla-feature-revision-history",
  providers: [TableDataService],
  templateUrl: "./feature-revision-history.component.html",
  styleUrls: ["./feature-revision-history.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeatureRevisionHistoryComponent implements OnInit, OnDestroy {
  @Input() claimNumber: string;
  @Input() features: Feature[];

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isLoading$: Observable<boolean>;
  hasGetHistoryError$: Observable<boolean>;
  featureRevisionHistory$: Observable<FeatureHistory[] | []>;
  filters: { [key: string]: any };
  revisionHistoryColumns: string[] = [
    "dateTime",
    "updatedByRep",
    "featureNum",
    "lineCoverage",
    "partyProperty",
    "change",
    "from",
    "arrow",
    "to",
  ];

  private sortHeaderAccessorMap: { [key: string]: string } = {
    dateTime: "transactionDate",
    updatedByRep: "repDisplayName",
    featureNum: "featureNumber",
    lineCoverage: "coverageShortDescription",
    partyProperty: "partyProperty",
    change: "changeReason",
    from: "valueBefore",
    to: "valueAfter",
  };

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private store$: Store<AppState>,
    public tableDataService: TableDataService,
    private featureRevisionStoreService: FeatureRevisionStoreService,
    public coreUiStateService: CoreUiExtensionsStateService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.pipe(
      select(spinnerSelectors.selectIsSpinnerActive)
    );
    this.hasGetHistoryError$ = this.store$.pipe(select(selectGetHistoryError));
    this.featureRevisionHistory$ =
      this.featureRevisionStoreService.featureRevisionHistory$;

    this.getFeatureRevisionHistory();

    this.featureRevisionHistory$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((history: [] | FeatureHistory[]) => {
        this.tableDataService.initDataSource(
          new MatTableDataSource<FeatureHistory>(),
          this.sort,
          this.paginator
        );
        this.tableDataService.dataSource.data = history.map(
          (historyItem: FeatureHistory) => {
            return {
              ...historyItem,
              valueAfter: HelperFunctions.getChangeValue(
                historyItem.changeReason,
                historyItem.valueAfter,
                this.currencyPipe
              ),
              valueBefore: HelperFunctions.getChangeValue(
                historyItem.changeReason,
                historyItem.valueBefore,
                this.currencyPipe
              ),
            };
          }
        );
        // required so that human readable aria labels are generated
        this.tableDataService.dataSource.sortingDataAccessor =
          this.sortingAccessor.bind(this);
        if (this.tableDataService.dataSource.data.length) {
          this.triggerSort(this.sort.active, this.sort.direction || "desc");
        }
      });

    this.featureRevisionStoreService.filters$
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((filters: { [key: string]: any }) => {
        this.filters = filters;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  getFeatureRevisionHistory() {
    this.featureRevisionStoreService.getFeatureRevisionHistory({
      claimNumber: this.claimNumber,
      features: this.features,
    });
  }

  triggerSort(sortColumn: string, sortDirection: SortDirection): void {
    // workaround for https://github.com/angular/material2/issues/10242

    if (sortColumn) {
      if (
        this.sort.active !== sortColumn ||
        this.sort.direction !== sortDirection
      ) {
        const defaultSortHeader: MatSortableAnimation = this.sort.sortables.get(
          sortColumn
        ) as MatSortableAnimation;

        if (defaultSortHeader) {
          const animationFunction: (animationInfo: {
            fromState: SortDirection;
            toState: string;
          }) => void =
            defaultSortHeader["_setAnimationTransitionState"].bind(
              defaultSortHeader
            );
          animationFunction({
            fromState: sortDirection,
            toState: "active",
          });
        }
      }

      this.sort.start = "desc";
      this.sort.active = sortColumn;
      this.sort.direction = sortDirection;
      this.sort.sortChange.emit({
        active: sortColumn,
        direction: sortDirection,
      } as Sort);
    }
  }

  sortingAccessor(data: any, sortHeaderId: string): any {
    return data[this.sortHeaderAccessorMap[sortHeaderId]];
  }
}
