/* eslint-disable no-loops/no-loops */
import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from "@angular/core";
import * as _ from "lodash";

@Directive({
  selector: "[cla-highlight-search]",
})
export class HighlightSearchDirective implements OnChanges {
  @Input() content: string;
  @Input() searchTerm: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(): void {
    if (this.content) {
      const regex = new RegExp(_.escapeRegExp(this.searchTerm), "gi");
      if (this.searchTerm) {
        const matchingText = this.content.match(regex);
        const surroundingText = this.content.split(regex);
        this.renderer.setProperty(this.el.nativeElement, "innerHTML", "");
        if (matchingText && matchingText.length) {
          for (let i = 0; i < surroundingText.length; i++) {
            this.renderer.appendChild(
              this.el.nativeElement,
              this.renderer.createText(surroundingText[i])
            );
            if (i <= matchingText.length - 1) {
              const mark = this.renderer.createElement("mark");
              // eslint-disable-next-line functional/immutable-data
              mark.innerText = matchingText[i];
              this.renderer.appendChild(this.el.nativeElement, mark);
            }
          }
        } else {
          this.renderer.setProperty(
            this.el.nativeElement,
            "innerText",
            this.content
          );
        }
      } else {
        this.renderer.setProperty(
          this.el.nativeElement,
          "innerText",
          this.content
        );
      }
    }
  }
}
