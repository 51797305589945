import { Injectable } from "@angular/core";
import { Rep } from "@claimssummaryshared/models";
import { AdminApiService } from "@modules/core/services/admin-api";
import { Observable } from "rxjs";

@Injectable()
export class RepAdminService {
  constructor(private adminApiService: AdminApiService) {}

  getRep(repCode: string): Observable<Rep> {
    return this.adminApiService.get<Rep>(`/v1/reps/${repCode}`);
  }
}
