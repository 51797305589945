import { CommonModule, TitleCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { NullDefaultPipe } from "@modules/shared/pipes/null-default-pipe";
import { ValueOrNAPipe } from "@modules/shared/pipes/value-or-na-pipe";
import { TableDataService } from "@modules/shared/services/table-data";
import { SharedModule } from "@modules/shared/shared.module";
import { CoverageModule } from "../coverage";

import {
  AutoDetailsComponent,
  BoatDetailsComponent,
  ClaimCoverageComponent,
  CommercialDetailsComponent,
  CoverageDescriptionsComponent,
  ManualDetailsComponent,
  ManufacturedHomeDetailsComponent,
  MotorHomeDetailsComponent,
  MotorcycleDetailsComponent,
  PolicyAdditionalNameInsuredComponent,
  PolicyAgentInfoComponent,
  PolicyConfirmComponent,
  PolicyDriversComponent,
  PolicyEndorsementsComponent,
  PolicyInfoComponent,
  PolicyNameInsuredComponent,
  PolicyVehicleComponent,
  PolicyVehicleInfoComponent,
  TravelTrailerDetailsComponent,
  UwMemoLandingComponent,
  UwMemoLandingHeaderComponent,
  UwMemoLandingOverviewComponent,
  VehicleComponent,
  VehiclesContainerComponent,
  VehiclesTableComponent,
} from "./components";

import { ClaNumberDirective } from "./helpers";

import { GetCoverageLabelPipe, GetCoverageValuePipe } from "./pipes";

import {
  AgentService,
  PartyApiService,
  PartyDetailService,
  PolicyService,
  PropertyService,
  UwMemoLandingService,
} from "./services";

import { PolicyContainerGhostLoaderComponent } from "./policy-container-ghost-loader.component";
import { PolicyContainerComponent } from "./policy-container.component";
import { PolicyRoutingModule } from "./policy-routing.module";
import { reducers } from "./state";
import { partyDetailsReducers } from "./state/party-details-state";
import { PartyDetailsEffects } from "./state/party-details-state/party-details.effects";
import { PolicySearchEffects } from "./state/policy-search/policy-search.effects";
import { PolicyEffects } from "./state/policy/policy.effects";
import { SpinnerEffects } from "./state/spinner/spinner.effects";
import { UwMemoLandingEffects } from "./state/uw-memo-landing/uw-memo-landing.effects";

@NgModule({
  imports: [
    PolicyRoutingModule,
    CommonModule,
    SharedModule,
    CoverageModule,
    ReactiveFormsModule,
    StoreModule.forFeature("policyState", reducers),
    StoreModule.forFeature("partyDetailsState", partyDetailsReducers),
    EffectsModule.forFeature([
      SpinnerEffects,
      PolicyEffects,
      PolicySearchEffects,
      UwMemoLandingEffects,
      PartyDetailsEffects,
    ]),
  ],
  declarations: [
    AutoDetailsComponent,
    BoatDetailsComponent,
    ClaimCoverageComponent,
    CommercialDetailsComponent,
    CoverageDescriptionsComponent,
    ManualDetailsComponent,
    ManufacturedHomeDetailsComponent,
    MotorHomeDetailsComponent,
    MotorcycleDetailsComponent,
    PolicyAdditionalNameInsuredComponent,
    PolicyAgentInfoComponent,
    PolicyConfirmComponent,
    PolicyDriversComponent,
    PolicyEndorsementsComponent,
    PolicyInfoComponent,
    PolicyNameInsuredComponent,
    PolicyVehicleComponent,
    PolicyVehicleInfoComponent,
    TravelTrailerDetailsComponent,
    UwMemoLandingComponent,
    UwMemoLandingHeaderComponent,
    UwMemoLandingOverviewComponent,
    VehicleComponent,
    VehiclesContainerComponent,
    VehiclesTableComponent,
    ClaNumberDirective,
    GetCoverageLabelPipe,
    GetCoverageValuePipe,
    PolicyContainerComponent,
    PolicyContainerGhostLoaderComponent,
  ],
  providers: [
    GetCoverageLabelPipe,
    GetCoverageValuePipe,
    AgentService,
    PolicyService,
    PropertyService,
    UwMemoLandingService,
    NullDefaultPipe,
    ValueOrNAPipe,
    TitleCasePipe,
    TableDataService,
    PartyApiService,
    PartyDetailService,
  ],
  exports: [],
})
export class PolicyModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "auto",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/auto.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "commercial-lines",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/commercial-lines.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "motorcycle",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/motorcycle.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "boat",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/boat.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "manufactured-home",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/manufactured-home.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "travel-trailer",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/travel-trailer.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "home",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/home.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "pha",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/pha.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "renters",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/renters.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "snowmobile",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/snowmobile.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "placeholder",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../../../assets/placeholder.svg"
      )
    );
  }
}
