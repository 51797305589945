import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { NotesStateService } from "@notes/state/notes.state.service";
import { Observable, of } from "rxjs";

@Injectable()
export class NotesResolverService implements Resolve<boolean> {
  constructor(private notesStateService: NotesStateService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const claimNumber = route.paramMap.get("claimNumber");
    const categoryCodes = route.queryParamMap.get("categories") ?? "";

    if (claimNumber) {
      this.notesStateService.getCategories();
      this.notesStateService.setAllowedCategoryCodes(categoryCodes);
    }

    return of(true);
  }
}
