import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CombinedClaimGuard } from "@modules/core/services";

import { CLACoverageComponent } from "./coverage.component";

const coverageRoutes: Routes = [
  {
    path: ":claimNumber",
    component: CLACoverageComponent,
    canActivate: [CombinedClaimGuard],
    data: {
      title: "Coverage CVQ",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(coverageRoutes)],
  exports: [RouterModule],
})
export class CoverageRoutingModule {}
