import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import {
  NoteListComponent,
  NoteListFilterComponent,
  NoteListSubheaderComponent,
} from "@notes/modules/note-list/components";
import { NoteListResolverService } from "@notes/modules/note-list/note-list.resolver.service";
import { NoteListRoutingModule } from "@notes/modules/note-list/note-list.routing.module";
import { NoteTextPipe } from "@notes/modules/note-list/pipes";
import { NoteSharedModule } from "@notes/modules/note-shared/note-shared.module";
import { NotesEffects } from "@notes/state/notes.effects";
import { notesReducer } from "@notes/state/notes.reducer";
import { SharedModule } from "@shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NoteSharedModule,
    NoteListRoutingModule,
    StoreModule.forFeature("notes", notesReducer),
    EffectsModule.forFeature([NotesEffects]),
  ],
  declarations: [
    NoteListComponent,
    NoteListSubheaderComponent,
    NoteListFilterComponent,
    NoteTextPipe,
  ],
  providers: [NoteListResolverService, DatePipe],
  exports: [NoteListComponent],
})
export class NoteListModule {}
