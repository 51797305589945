import { Pipe, PipeTransform } from "@angular/core";

import {
  SubrogationAmountFeature,
  PartyPropertyInfo,
} from "@modules/subrogation-summary/models";

@Pipe({
  name: "claPartyPropertyDisplayPipe",
})
export class PartyPropertyDisplayPipe implements PipeTransform {
  transform(
    partyPropertyInfo: PartyPropertyInfo | SubrogationAmountFeature
  ): string {
    const {
      partyFirstName: first,
      partyMiddleInitial: middle,
      partyLastName: last,
      partyLastNameSuffix: suffix,
    } = partyPropertyInfo;
    let partyName = "";
    partyName = last && first ? `${last}, ${first}` : partyName;
    partyName = middle ? `${partyName} ${middle}` : partyName;
    partyName = suffix ? `${partyName}, ${suffix}` : partyName; // last, first middle, suffix (Johnson, Jim J, Jr)

    const {
      partyBusinessInd: isBusinessParty,
      fixedPropertyDescription,
      businessName,
    } = partyPropertyInfo;

    const isInjuryFeature = partyPropertyInfo.featureTypeCode === 1;
    const isFixedPropertyFeature =
      partyPropertyInfo.propertyClaimTypeCode !== "V";

    if (isInjuryFeature) {
      return partyName;
    }
    if (isFixedPropertyFeature) {
      const name = isBusinessParty === "Y" ? businessName : partyName;
      return name
        ? `${name} - ${fixedPropertyDescription}`
        : fixedPropertyDescription;
    }
    // else isVehicleFeature
    const {
      vehicleModelYear: year,
      vehicleManufacturer: manufacturer,
      vehicleModelName: model,
    } = partyPropertyInfo;
    const paddedYear = year < 10 ? `0${year}` : year;
    return `${paddedYear} ${manufacturer} ${model}`;
  }
}
