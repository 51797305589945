import { createAction, props } from "@ngrx/store";
import { ClaimFeature } from "@modules/subrogation-summary/models/";

export const loadClaimFeatures = createAction(
  "[claim-feature] load the ClaimFeatures.",
  props<{ claimNumber: string }>()
);

export const loadClaimFeaturesSucceeded = createAction(
  "[claim-feature] successfully loaded the claim features",
  props<{ claimFeatures: ClaimFeature[] }>()
);

export const loadClaimFeaturesFailed = createAction(
  "[claim-feature] failed to load the claim features",
  props<{ errorMsg: string }>()
);
