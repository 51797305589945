import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../shared/shared.module";
import { FeatureTableComponent } from "./components/feature-table/feature-table.component";
import { FeatureClaimComponent } from "./containers/feature-claim/feature-claim.component";
import { FeatureRoutingModule } from "./feature-routing.module";
import { CoreUiAdapterApiService } from "./services/core-ui-adapter-api";
import { FeatureService } from "./services/feature/feature.service";
import { ListEffects, SpinnerEffects } from "./state/effects";
import { reducers } from "./state/reducers";

@NgModule({
  declarations: [FeatureTableComponent, FeatureClaimComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureRoutingModule,
    SharedModule,
    A11yModule,
    StoreModule.forFeature("featureState", reducers),
    EffectsModule.forFeature([ListEffects, SpinnerEffects]),
  ],
  providers: [FeatureService, CoreUiAdapterApiService],
})
export class FeatureModule {}
