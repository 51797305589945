<cui-data-list class="table" density="condensed">
  <ng-container *ngIf="isBusiness; then company; else person"></ng-container>
  <ng-template #company>
    <cui-data-list-row label="Company">
      {{ businessName | titlecase | claValueOrNA }}
    </cui-data-list-row>
  </ng-template>
  <ng-template #person>
    <cui-data-list-row label="First">
      {{ firstName | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row>
      <span list-item-label>
        <span class="screen-reader-only">Middle</span>
        <span aria-hidden="true">Middle</span>
      </span>
      {{ middleInitial | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row label="Last">
      {{ lastName | titlecase | claValueOrNA }}
    </cui-data-list-row>
    <cui-data-list-row label="Suffix">
      {{ suffix | titlecase | claValueOrNA }}
    </cui-data-list-row>
  </ng-template>
  <cui-data-list-row label="DBA">
    {{ dbaName | titlecase | claValueOrNA }}
  </cui-data-list-row>
  <cui-data-list-row label="Address">
    <span [innerHTML]="streetName | claValueOrNA"></span>
    <span *ngIf="secondStreetName !== ''" [innerHtml]="secondStreetName"></span>
    <span *ngIf="cityStateZip !== ''" [innerHTML]="cityStateZip"></span>
  </cui-data-list-row>
  <ng-container *ngIf="formattedPhoneNumbers?.length === 0">
    <cui-data-list-row label="Phone">-</cui-data-list-row>
  </ng-container>
  <ng-container *ngIf="formattedPhoneNumbers?.length === 1">
    <cui-data-list-row label="Phone">
      {{ formattedPhoneNumbers[0] }}
    </cui-data-list-row>
  </ng-container>
  <ng-container *ngIf="formattedPhoneNumbers?.length > 1">
    <ng-container *ngFor="let phoneNumber of formattedPhoneNumbers">
      <span></span>
      <cui-data-list-row label="Phone {{ findIndex(phoneNumber) }}">
        {{ phoneNumber }}
      </cui-data-list-row>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="formattedEmails?.length === 0">
    <cui-data-list-row label="Email">-</cui-data-list-row>
  </ng-container>
  <ng-container *ngIf="formattedEmails?.length === 1">
    <cui-data-list-row label="Email">
      {{ formattedEmails[0] }}
    </cui-data-list-row>
  </ng-container>
  <ng-container *ngIf="formattedEmails?.length > 1">
    <ng-container *ngFor="let email of formattedEmails">
      <span></span>
      <cui-data-list-row label="Email {{ findEmailIndex(email) }}">
        {{ email }}
      </cui-data-list-row>
    </ng-container>
  </ng-container>
</cui-data-list>
