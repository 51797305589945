import { Injectable } from "@angular/core";
import { PolicyDetailsApiService } from "@modules/core/services/policy-details-api";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { AgentInfo } from "../models/agent-info";

@Injectable()
export class AgentService {
  constructor(private policyDetailsApiService: PolicyDetailsApiService) {}

  getAgentInfo(agentNumber: string): Observable<AgentInfo | null> {
    if (agentNumber) {
      return this.policyDetailsApiService
        .get<AgentInfo>(`/lossviewerapi/v1/Agency/${agentNumber}`)
        .pipe(
          catchError(() => {
            return of(null);
          })
        );
    }

    return of(null);
  }
}
