import {
  createFeatureSelector,
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";
import { CallState } from "@modules/shared";

import { codesAdapter, CodesState } from "./codes.reducer";
export const selectCodesState = createFeatureSelector<CodesState>("codes");

export const selectCodes = createSelector(
  selectCodesState,
  codesAdapter.getSelectors().selectAll
);

export const selectCodeTableIds = createSelector(
  selectCodesState,
  (state) => state.ids
);

export const isCodesLoaded = createSelector(
  selectCodesState,
  (state) => state.callState === CallState.LOADED
);

export const selectCodeTable = <T>(
  tableName: string
): MemoizedSelector<object, T | undefined, DefaultProjectorFn<T | undefined>> =>
  createSelector(selectCodes, (tables) =>
    tables.find((table) => table.name === tableName)
  ) as MemoizedSelector<
    object,
    T | undefined,
    DefaultProjectorFn<T | undefined>
  >;
