import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { BrowserTabAlertService } from "@pgr-cla/core-ui-components";
import { filter, fromEvent, map, tap } from "rxjs";
import * as alertsActions from "./alerts.actions";

@Injectable()
export class AlertsEffects {
  detectOnAlertsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        alertsActions.onClaimAlertsViewed,
        alertsActions.onAllClaimAlertsViewed
      ),
      map((action) => this.mapActionToSyncAction(action)),
      tap((action) => {
        window.localStorage.setItem(
          AlertsEffects.LOCAL_STORAGE_KEY,
          JSON.stringify(action)
        );
        // clear action as soon as writing, to make sure storage event fires
        window.localStorage.removeItem(AlertsEffects.LOCAL_STORAGE_KEY);
      })
    )
  );

  onAlertsViewedSync$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          alertsActions.onClaimAlertsViewedSync,
          alertsActions.onAllClaimAlertsViewedSync
        ),
        tap(() => {
          this.browserTabAlertService.clearAlert();
        })
      ),
    { dispatch: false }
  );

  // syncs viewing alerts across tabs
  onLocalStorageWrite$ = createEffect(() =>
    fromEvent<StorageEvent>(window, "storage").pipe(
      filter(
        (evt) =>
          evt.key === AlertsEffects.LOCAL_STORAGE_KEY && evt.newValue !== null
      ),
      map((evt) => JSON.parse(evt.newValue as string))
    )
  );

  private static LOCAL_STORAGE_KEY = "alerts_actions_sync";

  constructor(
    private actions$: Actions,
    private browserTabAlertService: BrowserTabAlertService
  ) {}

  private mapActionToSyncAction(action: Action): any | undefined {
    if (action.type === alertsActions.onClaimAlertsViewed.type) {
      return alertsActions.onClaimAlertsViewedSync({
        pgrTransactionIds: (action as any).pgrTransactionIds,
      });
    }

    if (action.type === alertsActions.onAllClaimAlertsViewed.type) {
      return alertsActions.onAllClaimAlertsViewedSync();
    }
  }
}
