import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PartyPropertyDetail } from "@modules/core/models";

@Component({
  selector: "cla-left-rail-party-property",
  templateUrl: "left-rail-party-property.component.html",
  styleUrls: ["./left-rail-party-property.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftRailPartyPropertyComponent {
  propertyCount: number;
  partyCount: number;
  data: PartyPropertyDetail[];

  @Input() isLoading: boolean;
  @Input() set partyPropertyData(data: PartyPropertyDetail[]) {
    this.data = data;
    this.propertyCount = !data?.length
      ? 0
      : data.filter((property: PartyPropertyDetail) => !!property.propertyType)
          .length;
    this.partyCount = !data?.length
      ? 0
      : data
          .map(({ associatedPartyProperties }: PartyPropertyDetail) =>
            associatedPartyProperties ? associatedPartyProperties.length : 0
          )
          .reduce((a: number, b: number) => a + b);
  }
}
