import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthenticationService } from "@core/services/authentication/authentication.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { catchError, first, Observable, of, switchMap, timeout } from "rxjs";

@UntilDestroy()
@Component({
  selector: "cla-ping-landing",
  templateUrl: "./ping-landing.component.html",
  styleUrls: ["./ping-landing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PingLandingComponent {
  // public authorized: boolean;

  constructor(public authService: AuthenticationService) {
    // this.authorized = false;
  }

  authorized = () => this.isAuthorized();

  private isAuthorized(): Observable<boolean> {
    return this.authService.token$.pipe(
      first((token: string) => token !== ""),
      timeout(2000),
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
