import { IContentSummaryState } from "../../models";

import { SearchTypeValues } from "./search-type-values";

export const initialState: IContentSummaryState = {
  contentDetails: {
    response: null,
    error: null,
    inFlight: false,
    meta: {
      responseCode: null,
      method: null,
    },
  },
  isLoading: false,
  filterResetInProgress: false,
  filteredContentItems: [],
  selectedItems: [],
  partyPropertyName: undefined,
  previewedItem: undefined,
  searchResults: [],
  searchTerm: "",
  searchType: SearchTypeValues.None,
  refreshInProgress: false,
  filters: {
    originDateTime: {},
    partyOrPropertyName: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    contentCategoryDesc: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    contentTypeDesc: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    tags: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    createdByDisplayName: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    deliveryMethodDesc: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    numberOfItems: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    medicalBillNumber: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    originDesc: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    status: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
    versions: {
      scopedTotal: 0,
      appliedFilters: [],
      filterOptions: [],
    },
  },
  columns: [
    {
      id: "isSelected",
      title: "Is Selected",
      visible: true,
      isToggleable: false,
      isFilterable: false,
    },
    {
      id: "originDateTime",
      title: "Date and Time Received",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "partyOrPropertyName",
      title: "Party/Property",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "contentCategoryDesc",
      title: "Category",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "contentTypeDesc",
      title: "Type",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "deliveryMethodDesc",
      title: "Delivery Method",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "tags",
      title: "Tags",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "createdByDisplayName",
      title: "Created By",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "numberOfItems",
      title: "Number of Items",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "versions",
      title: "Versions",
      visible: true,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "medicalBillNumber",
      title: "Medical Bill Number",
      visible: false,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "originDesc",
      title: "Origin",
      visible: false,
      isToggleable: true,
      isFilterable: true,
    },
    {
      id: "status",
      title: "Status",
      visible: false,
      isToggleable: true,
      isFilterable: true,
    },
  ],
  contentSummaryGridFilter: {
    claimNumber: "00000000000",
    isPartyPropertyView: false,
  },
};
