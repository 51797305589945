import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClaimSummaryComponent } from "./components";

export const claimSummaryRoutes: Routes = [
  {
    path: "",
    component: ClaimSummaryComponent,
    data: { title: "Claim Summary" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(claimSummaryRoutes)],
  exports: [RouterModule],
})
export class ClaimSummaryRoutingModule {}
