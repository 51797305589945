<span *ngIf="isInitialLoad" class="ghost-line w-1/3"></span>
<ng-container *ngIf="!isInitialLoad">
  <cui-status-indicator
    *ngIf="claimStatus?.name"
    class="my-bl-2"
    [status]="claimStatus?.name | claClaimStatus"
    [matTooltip]="toolTip"
    [attr.aria-label]="toolTip"
    [attr.tabindex]="tabIndex"
    [attr.aria-hidden]="false"
    role="status"
  >
    {{ statusLabel }}
  </cui-status-indicator>
  <ng-container *ngIf="!claimStatus?.name"> - </ng-container>
</ng-container>
