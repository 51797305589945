import { createAction, props } from "@ngrx/store";
import { actions } from "@notes/constants";
import { FilterCriteria, Journal, Note } from "@notes/models";
import { CodeDescriptionPair } from "@pgr-cla/core-ui-components/lib/claims/models";
import { Error } from "@shared/models";

export class NotesActions {
  public static onSetNoteId = createAction(
    actions.setNoteId,
    props<{ noteId: string }>()
  );

  public static onSetNoteListSelectedTabIndex = createAction(
    actions.setNoteListSelectedTabIndex,
    props<{ selectedTabIndex: number }>()
  );
  public static onSetAllowedCategoryCodes = createAction(
    actions.setAllowedCategoryCodes,
    props<{ allowedCategoryCodes: string }>()
  );

  public static onGetNotesRequest = createAction(actions.getNotes);
  public static onGetNotesRequestSuccess = createAction(
    actions.getNotesSuccess,
    props<{ notes: Note[] }>()
  );
  public static onGetNotesRequestFailure = createAction(
    actions.getNotesFailure,
    props<{ error: Error }>()
  );

  public static onGetNoteRequest = createAction(
    actions.getNote,
    props<{ noteId: string }>()
  );
  public static onGetNoteRequestSuccess = createAction(
    actions.getNoteSuccess,
    props<{ note: Note }>()
  );
  public static onGetNoteRequestFailure = createAction(
    actions.getNoteFailure,
    props<{ error: Error }>()
  );

  public static onGetJournalsRequest = createAction(actions.getJournals);
  public static onGetJournalsRequestSuccess = createAction(
    actions.getJournalsSuccess,
    props<{ journals: Journal[] }>()
  );
  public static onGetJournalsRequestFailure = createAction(
    actions.getJournalsFailure,
    props<{ error: Error }>()
  );

  public static onGetCategoriesRequest = createAction(actions.getCategories);
  public static onGetCategoriesRequestSuccess = createAction(
    actions.getCategoriesSuccess,
    props<{ categories: CodeDescriptionPair[] }>()
  );
  public static onGetCategoriesRequestFailure = createAction(
    actions.getCategoriesFailure,
    props<{ error: Error }>()
  );

  public static onSetFilterCriteria = createAction(
    actions.setFilterCriteria,
    props<{ filterCriteria?: FilterCriteria }>()
  );
  public static onSetFilteredNotesRequest = createAction(
    actions.setFilteredNotes
  );
  public static onSetFilteredNotesRequestSuccess = createAction(
    actions.setFilteredNotesSuccess,
    props<{ filteredNoteIds: string[] }>()
  );

  public static onToggleSortDirectionRequest = createAction(
    actions.toggleSortDirection
  );
  public static onToggleSortDirectionRequestSuccess = createAction(
    actions.toggleSortDirectionSuccess
  );

  public static onCloseNoteWindow = createAction(actions.closeNoteWindow);
  public static onHandleExternalWindowAction = createAction(
    actions.handleExternalWindowAction,
    props<{ externalAction: string }>()
  );
  public static onRedirectRequest = createAction(
    actions.redirectRequest,
    props<{ url: string }>()
  );

  public static onSetIsPopout = createAction(actions.setIsPopout);
}
