import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { MatSidenav } from "@angular/material/sidenav";
import { ClaimDetailsStateService } from "@modules/core/state";
import { PaymentDetailsLambdaInfo } from "@modules/payments/models";
import { PaymentsState } from "@modules/payments/models/payments-state.interface";
import { PaymentsStateService } from "@modules/payments/services";
import { Store } from "@ngrx/store";
import {
  RegisteredSideSheet,
  SideSheetService,
} from "@pgr-cla/core-ui-components";
import * as paymentsActions from "../../actions";

@UntilDestroy()
@Component({
  selector: "cla-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PaymentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav, { static: true }) public sideSheet: MatSidenav;

  canOpenPaymentsFilter: boolean = false;

  public data: PaymentDetailsLambdaInfo[];

  constructor(
    public paymentsStateService: PaymentsStateService,
    private claimDetailsStateService: ClaimDetailsStateService,
    private sideSheetService: SideSheetService,
    private store$: Store<PaymentsState>
  ) {}

  ngOnInit(): void {
    this.paymentsStateService.retrieveCurrentClaimPayments();
    this.claimDetailsStateService.onSetPageScrollingEnabled(false);
  }

  ngAfterViewInit(): void {
    this.sideSheetService.register("PaymentsMain", this.sideSheet);
  }

  public ngOnDestroy(): void {
    this.hideSuccessBanner();
    this.hideErrorBanner();
    this.claimDetailsStateService.onSetPageScrollingEnabled(true);
  }

  hideSuccessBanner(): void {
    this.claimDetailsStateService.onSetSuccessBannerMessage("");
  }

  hideErrorBanner(): void {
    this.claimDetailsStateService.onSetErrorBannerMessages([]);
  }

  public handleSubscriptions(): void {
    this.paymentsStateService.payments$
      .pipe(untilDestroyed(this))
      .subscribe((payments: PaymentDetailsLambdaInfo[]) => {
        this.data = payments;
      });
  }

  onEscapeOrBackDropClick() {
    this.store$.dispatch(paymentsActions.closePaymentsFilter());
  }

  onOpenFilter() {
    if (this.canOpenPaymentsFilter) {
      this.store$.dispatch(paymentsActions.closePaymentsFilter());
    } else {
      const sideSheet: RegisteredSideSheet | undefined =
        this.sideSheetService.get("PaymentsMain");

      if (sideSheet) {
        sideSheet.open();
      }
    }
  }
}
