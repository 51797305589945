import { Injectable } from "@angular/core";
import { ClaimManagementApiService } from "@core/services";
import { Feature } from "@modules/feature-revision-history/models/feature-revision";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable } from "rxjs";
import { FeatureHistory } from "../../models/feature-revision/feature-history";

@Injectable()
export class FeatureService {
  constructor(
    private claimManagementApiService: ClaimManagementApiService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getFeatures(claimNumber: string): Observable<Feature[]> {
    return this.claimManagementApiService.get<Feature[]>(
      `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
        claimNumber,
        "full"
      )}/features`
    );
  }

  getFeatureRevisionHistory(claimNumber: string): Observable<FeatureHistory[]> {
    return this.claimManagementApiService.get<FeatureHistory[]>(
      `/lossviewerapi/v1/claims/${this.claimNumberPipe.transform(
        claimNumber,
        "full"
      )}/featureRevisionHistory`
    );
  }
}
