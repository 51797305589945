import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

import { ContentSummaryEventService } from "@modules/electronic-file-folder/services/content-summary/analytics/content-summary-event/content-summary-event.service";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { ContentSummaryViewService } from "@modules/electronic-file-folder/services/content-summary/content-summary-view/content-summary-view.service";
import { EFFConstants } from "../../../constants";
import { SearchTypeValues } from "../../../constants/content-summary";

@UntilDestroy()
@Component({
  selector: "cla-content-summary-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements AfterViewInit, OnInit {
  public actionNames = EFFConstants.clickActionNames;
  public previousSearchQuery = "";
  @ViewChild("searchTerm") public searchTerm: ElementRef;
  @ViewChild("searchType") public searchType: MatSelect;
  public searchTypeValues = SearchTypeValues;

  constructor(
    public contentSummaryStoreService: ContentSummaryStoreService,
    private contentSummaryViewService: ContentSummaryViewService,
    public contentSummaryEventService: ContentSummaryEventService
  ) {}

  ngOnInit(): void {
    this.contentSummaryViewService.clearSearchAndFilter$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.clearSearch();
      });
  }

  public clearSearch(): void {
    if (this.searchTerm && this.searchTerm.nativeElement) {
      this.searchTerm.nativeElement.value = "";
    }
    if (this.previousSearchQuery) {
      this.contentSummaryStoreService.onContentSummarySearch({
        searchTerm: "",
        searchType: SearchTypeValues.None,
      });
      this.previousSearchQuery = "";
    }
  }

  public ngAfterViewInit(): void {
    fromEvent(this.searchTerm.nativeElement, "keyup")
      .pipe(
        debounceTime(250),
        map((event: any) => event.target.value),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((searchTerm: string) => {
        this.resetSearch(searchTerm);
      });
  }

  public resetSearch(searchTerm: string): void {
    if (
      this.searchTerm.nativeElement.value === "" &&
      searchTerm !== this.previousSearchQuery
    ) {
      this.clearSearch();
    }
  }

  public submitContentSearch(): void {
    const searchTerm = this.searchTerm.nativeElement.value;
    const searchType =
      (this.searchType.value as SearchTypeValues) || SearchTypeValues.Grid;

    if (searchTerm !== this.previousSearchQuery) {
      this.contentSummaryStoreService.onContentSummarySearch({
        searchTerm,
        searchType,
      });
      this.previousSearchQuery = searchTerm;
    }
  }
}
