import { Injectable } from "@angular/core";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { asyncScheduler, from, Observable, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  observeOn,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import * as paymentsActions from "../actions";
import { filterItems } from "../helpers/util";
import { PaymentsState } from "../models";
import { PaymentsApiService, PaymentsStateService } from "../services";

@Injectable()
export class PaymentsEffects {
  retrieveCurrentClaimPayments$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.retrieveCurrentClaimPayments),
      withLatestFrom(this.paymentsStateService.selectedClaimNumber$),
      map(([paymentsAction, claimNumber]) =>
        paymentsActions.retrieveClaimPayments({
          claimNumber,
          keepExistingPayments: paymentsAction.keepExistingPayments,
        })
      )
    )
  );

  retrievePayments$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.retrieveClaimPayments),
      switchMap(({ claimNumber }) =>
        this.paymentsApiService.getPaymentDetailsTable(claimNumber).pipe(
          switchMap((payments) =>
            from([
              paymentsActions.retrieveClaimPaymentsSuccess({
                payments,
              }),
            ])
          ),
          catchError((error) =>
            of(paymentsActions.retrieveClaimPaymentsError({ error }))
          )
        )
      )
    )
  );

  filterPayments$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        paymentsActions.filterPaymentsSelect,
        paymentsActions.filterPaymentsNumberRange,
        paymentsActions.resetPaymentsFilters,
        paymentsActions.resetColumnFilters
      ),
      observeOn(asyncScheduler),
      withLatestFrom(this.getPaymentsState()),
      // eslint-disable-next-line @typescript-eslint/typedef
      map(([, state]) => filterItems(state)),
      concatMap((filterResult) =>
        of(
          paymentsActions.applyFilters({
            filteredPayments: filterResult.paymentsList,
            filters: filterResult.filters,
          })
        )
      )
    )
  );

  closePaymentsFilter$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentsActions.closePaymentsFilter),
      switchMap(() => {
        const sideSheet = this.sideSheetService.get("PaymentsMain");

        if (sideSheet) {
          sideSheet.close();
        }
        return of(paymentsActions.doNothing());
      })
    )
  );

  constructor(
    private actions$: Actions,
    private paymentsApiService: PaymentsApiService,
    private paymentsStateService: PaymentsStateService,
    public coreUiExtensionsStateService: CoreUiExtensionsStateService,
    private sideSheetService: SideSheetService
  ) {}

  private getPaymentsState(): Observable<PaymentsState> {
    return this.paymentsStateService.state$;
  }
}
