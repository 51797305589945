import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class MainContentFocusService {
  private findMainContentLoadingImage(): HTMLImageElement | undefined | null {
    const maincontent = document.querySelector("#mainwindowoutlet");
    return maincontent?.querySelector(".logo");
  }

  private findFirstH1(): HTMLHeadingElement | null {
    return document.querySelector("h1");
  }

  private findMainContentIFrame(): HTMLIFrameElement | undefined | null {
    const maincontent = document.querySelector("#mainwindowoutlet");
    return maincontent?.querySelector("iframe");
  }

  public focusOnContent(): void {
    const loadingImage: HTMLImageElement | undefined | null =
      this.findMainContentLoadingImage();
    const firstH1: HTMLHeadingElement | null = this.findFirstH1();
    const contentIFrame: HTMLElement | undefined | null =
      this.findMainContentIFrame();

    if (loadingImage) {
      loadingImage.setAttribute("tabindex", "0");
      loadingImage.focus();
    } else if (contentIFrame) {
      contentIFrame.setAttribute("tabindex", "0");
      contentIFrame.focus();
    } else if (firstH1) {
      firstH1.setAttribute("tabindex", "-1");
      firstH1.focus();
    }
  }
}
