import { Injectable } from "@angular/core";
import { ClaimsSummaryContext } from "@modules/core/models";
import { selectRouteParam } from "@modules/shared/state/router/router.selectors";
import { Store } from "@ngrx/store";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { AppState } from "@store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ClaimsSummaryContextService {
  context$: Observable<ClaimsSummaryContext>;
  claimNumber$ = this.store$.select(selectRouteParam("claimNumber"));

  constructor(
    private store$: Store<AppState>,
    private claimNumberPipe: ClaimNumberPipe
  ) {
    this.context$ = this.claimNumber$.pipe(
      map((claimNumber) => ({
        claimNbr: this.claimNumberPipe.transform(claimNumber) || "",
      }))
    );
  }
}
