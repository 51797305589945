import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claEmptyDashPipe",
})
export class EmptyDashPipe implements PipeTransform {
  transform(value: string): string {
    return value || "—";
  }
}
