import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "cla-claim-header-data",
  templateUrl: "claim-header-data.component.html",
  styleUrls: ["./claim-header-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimHeaderDataComponent {
  @Input() isLoading: boolean;
  @Input() isFileOwnerLoading: boolean;
  @Input() headerData: { [key: string]: string };
  @Input() ownerRepCode: string;
  @Input() ownerRepSummary?: ClaimRepContactCardInfoRedacted;
  private copyClaimNumberClickedSubject$ = new BehaviorSubject(false);

  get copyNumberButtonClicked$() {
    return this.copyClaimNumberClickedSubject$.asObservable();
  }

  copyClaimNumberClick() {
    this.copyClaimNumberClickedSubject$.next(true);
    setTimeout(() => {
      this.copyClaimNumberClickedSubject$.next(false);
    }, 3000);
  }
}
