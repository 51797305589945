import { Action, createReducer, on } from "@ngrx/store";
import { Diary, diaryInitialState, DiaryState } from "@diary/models";
import * as diaryActions from "@diary/actions";

const diaryReducer = createReducer(
  diaryInitialState,
  on(
    diaryActions.retrieveClaimDiaries,
    (state: DiaryState, { keepExistingDiaries }) =>
      ({
        ...state,
        diaries: keepExistingDiaries ? state.diaries : undefined,
        diaryRequestInFlight: true,
        diaryError: undefined,
      } as DiaryState)
  ),
  on(
    diaryActions.retrieveClaimDiariesSuccess,
    (state: DiaryState, { diaries }) =>
      ({
        ...state,
        diaries: sortDiaries(diaries),
        diaryRequestInFlight: false,
        diaryError: undefined,
      } as DiaryState)
  ),
  on(
    diaryActions.retrieveClaimDiariesError,
    (state: DiaryState, { error }) =>
      ({
        ...state,
        diaryRequestInFlight: false,
        diaryError: error,
      } as DiaryState)
  ),
  on(
    diaryActions.setMappedTableData,
    (state: DiaryState, { diaryTableData }) =>
      ({
        ...state,
        diaryTableData,
      } as DiaryState)
  ),
  on(
    diaryActions.getClaimDiaryCountData,
    (state: DiaryState) =>
      ({
        ...state,
        diaryCalenderDataRequestInFlight: true,
        diaryCalenderDataRequestError: undefined,
      } as DiaryState)
  ),
  on(
    diaryActions.getClaimDiaryCountDataSuccess,
    (state: DiaryState, { diaryCalenderCountData }) =>
      ({
        ...state,
        diaryCalenderCountData,
        diaryCalenderDataRequestInFlight: false,
        diaryCalenderDataRequestError: undefined,
      } as DiaryState)
  ),
  on(
    diaryActions.getClaimDiaryCountDataError,
    (state: DiaryState, { error }) =>
      ({
        ...state,
        diaryCalenderCountData: undefined,
        diaryCalenderDataRequestInFlight: false,
        diaryCalenderDataRequestError: error,
      } as DiaryState)
  )
);

const sortDiariesByDueDate = (a: Diary, b: Diary): number => {
  if (a.diaryDueDate > b.diaryDueDate) return 1;
  if (a.diaryDueDate < b.diaryDueDate) return -1;
  return 0;
};

const isActiveDiary = (diary: Diary) => {
  return !diary.deletedByDate;
};

const sortDiaries = (diaries: Diary[]) => {
  if (!diaries) return undefined;

  const activeDiaries = diaries.filter((diary: Diary) => isActiveDiary(diary));
  const historicDiaries = diaries.filter(
    (diary: Diary) => !isActiveDiary(diary)
  );

  return [
    // eslint-disable-next-line functional/immutable-data
    ...activeDiaries.sort(sortDiariesByDueDate),
    // eslint-disable-next-line functional/immutable-data
    ...historicDiaries.sort(sortDiariesByDueDate),
  ];
};

export function reducer(
  state: DiaryState | undefined,
  action: Action
): DiaryState {
  return diaryReducer(state, action);
}
