import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ClaimManagementApiService } from "@modules/core/services";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { Observable, throwError } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";

@Injectable()
export class ClaimSearchService {
  constructor(
    private claimManagementApiService: ClaimManagementApiService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  getClaimSearch(claimNumber: string | null): Observable<any> {
    return this.claimManagementApiService
      .get<any>(`/lossviewerapi/v1/claims/${claimNumber}/search`)
      .pipe(
        catchError((error: unknown) => {
          return this.errorMasking(
            error as HttpErrorResponse,
            `Claim ${this.claimNumberPipe.transform(claimNumber)} Not Found`
          );
        }),
        shareReplay(1)
      );
  }

  private errorMasking(error: HttpErrorResponse, message: string) {
    if (error.status === 404) {
      return throwError(
        () => ({ ...error, statusText: message } as HttpErrorResponse)
      );
    }
    return throwError(() => error);
  }
}
