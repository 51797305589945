import { Injectable } from "@angular/core";
import { WindowService } from "@pgr-cla/cla-window";
@Injectable()
export class WindowResizerService {
  private _window: Window;

  constructor(private readonly _windowService: WindowService) {
    this._window = this._windowService.nativeWindow;
  }
  public initialize(): void {
    this._window.resizeTo(1038, 668);
  }
}
