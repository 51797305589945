import { createSelector } from "@ngrx/store";

import { selectPolicyState } from "..";
import { ClaimCoverage } from "../../models/claim-coverage";
import { CodeDescription } from "../../models/code-description";
import { LossHistory } from "../../models/loss-history";

export const selectPolicy = createSelector(
  selectPolicyState,
  (state) => state.policy
);

export const selectPolicyUrlResponse = createSelector(
  selectPolicy,
  (state) => state.policyUrlResponse
);

export const selectClaimPolicy = createSelector(
  selectPolicy,
  (state) => state.claimPolicy
);

export const selectClaimInfo = createSelector(
  selectPolicy,
  (state) => state.claimInfo
);

export const selectPolicyDetail = createSelector(
  selectPolicy,
  (state) => state.policyDetail
);

export const selectAgentInfo = createSelector(
  selectPolicy,
  (state) => state.agentInfo
);

export const selectLossVehicles = createSelector(
  selectPolicy,
  (state) => state.lossVehicle
);

export const selectPolicyVehicles = createSelector(
  selectPolicy,
  (state) => state.policyVehicle
);

export const selectPolicyDrivers = createSelector(
  selectPolicy,
  (state) => state.policyDrivers
);

export const selectPolicyLosses = createSelector(
  selectPolicy,
  (state) => state.policyLosses
);

export const selectVehicleLosses = createSelector(
  selectPolicy,
  (state) => state.vehicleLosses
);

export const selectCodeTableInfo = createSelector(
  selectPolicy,
  (state) => state.stateCodeTableInfo
);

export const selectProductCodeTableInfo = createSelector(
  selectPolicy,
  (state) => state.productCodeTableInfo
);

export const selectPolicyStatus = createSelector(
  selectPolicyDetail,
  (policyDetail) => {
    if (policyDetail) {
      return {
        description: policyDetail.policyStatusDesc,
        code: policyDetail.policyStatusCode,
      } as CodeDescription;
    }
  }
);

export const selectLossHistory = createSelector(
  selectPolicyLosses,
  selectVehicleLosses,
  (policyLosses, vehicleLosses) => {
    const lossHistory: LossHistory[] = [];
    if (policyLosses) {
      policyLosses.forEach((x) => {
        // eslint-disable-next-line functional/immutable-data
        lossHistory.push({
          formattedClaimYearNumber: x.formattedClaimYearNumber,
          isPolicyLoss: x.isPolicyLoss,
          isVehicleLoss: false,
          claimDateYear: x.claimDateYear,
          claimNumber: x.claimNumber,
          vehicleYMM: x.vehicleYMM,
          factsOfLoss1: x.factsOfLoss1,
          claimStatusCd: x.claimStatusCd,
          claimStatusDescription: x.claimStatusDescription,
          fullClaimNumber: x.fullClaimNumber,
          claimLossDt: x.claimLossDt,
        } as LossHistory);
      });
    }
    if (vehicleLosses) {
      vehicleLosses.forEach((x) => {
        // eslint-disable-next-line functional/immutable-data
        lossHistory.push({
          formattedClaimYearNumber: x.formattedClaimYearNumber,
          isPolicyLoss: false,
          isVehicleLoss: x.isVehicleLoss,
          claimDateYear: x.claimDateYear,
          claimNumber: x.claimNumber,
          vehicleYMM: x.vehicleYMM,
          factsOfLoss1: x.factsOfLoss1,
          claimStatusCd: x.claimStatusCd,
          claimStatusDescription: x.claimStatusDescription,
          fullClaimNumber: x.fullClaimNumber,
          claimLossDt: x.claimLossDt,
        } as LossHistory);
      });
    }

    return policyLosses || vehicleLosses ? lossHistory : null;
  }
);

export const selectClaimCoverage = createSelector(
  selectLossVehicles,
  selectPolicyVehicles,
  selectPolicy,
  (lossVehicles, policyVehicles, policy) => {
    const claimCoverageList: ClaimCoverage[] = [];
    lossVehicles = lossVehicles.filter((x) => x.isInsured);
    lossVehicles.forEach((lossVehicle) => {
      const pVehicleForCoverages = policy.policyAttachVehicle.find(
        (p) => p.policyPosition === lossVehicle.policyPosition
      );
      const pVehicle = policyVehicles.find(
        (p) => p.policyPosition === lossVehicle.policyPosition
      );
      const claimCoverage = {
        lossVehicle,
        vehicleLimitsAndCoverages: pVehicleForCoverages
          ? pVehicleForCoverages.vehicleLimitsAndCoverages
          : [],
        policyVehicleInfo:
          pVehicleForCoverages === undefined ? null : pVehicleForCoverages,
        policyVehicle: pVehicle === undefined ? null : pVehicle,
      };
      // eslint-disable-next-line functional/immutable-data
      claimCoverageList.push(claimCoverage);
    });

    return claimCoverageList;
  }
);

export const selectPolicyConfirm = createSelector(
  selectPolicy,
  (state) => state.policyConfirm
);

export const selectPolicyConfirmDrivers = createSelector(
  selectPolicy,
  (state) => state.policyConfirmDrivers
);

export const selectPolicyConfirmVehicles = createSelector(
  selectPolicy,
  (state) => state.policyConfirmVehicles
);

export const selectAttachPolicyVehicles = createSelector(
  selectPolicy,
  (state) => state.policyAttachVehicle
);

export const policyAttached = createSelector(
  selectPolicy,
  (state) => state.policyAttached
);

export const selectPolicyAttachVehicleInfo = createSelector(
  selectClaimInfo,
  selectClaimPolicy,
  (claimInfo, claimPolicy) => {
    if (!!claimInfo && !!claimPolicy) {
      if (claimPolicy.isPolicyAttached) {
        return {
          policyNumber: claimPolicy.policyNumber,
          renewalSuffixNumber: claimPolicy.renewalSuffixNumber,
          dateOfLoss: new Date(claimInfo.dateOfLoss),
          policyStateCode: claimInfo.policyState["extenders"]["code"],
        };
      }
    }
    return null;
  }
);

export const selectSelectedVehicleInfo = createSelector(
  selectPolicy,
  (state) => state.selectedVehicleInfo
);

export const selectEndorsements = createSelector(
  selectPolicy,
  (state) => state.policyEndorsements
);

export const selectEndorsementCodeTable = createSelector(
  selectPolicy,
  (state) => state.endorsementCodeTable
);

export const selectPolicyEndorsementsWithDescriptions = createSelector(
  selectEndorsements,
  selectEndorsementCodeTable,
  (endorsements, codeTable) => {
    const endorsementCodes = codeTable ? codeTable[0].values : [];
    return endorsements.map((endorsement) => {
      return {
        ...endorsement,
        description: endorsementCodes.find(
          (code) =>
            code.code === endorsement.coAttachmentFormNumber ||
            code.code ===
              `${
                endorsement.coAttachmentFormNumber
              } ${endorsement.attachmentFormDate.toString().padStart(4, "0")}`
        )?.description,
      };
    });
  }
);

export const selectErrorMessage = createSelector(
  selectPolicy,
  (state) => state.errorMessage
);

export const selectIsPolicyDriversLoading = createSelector(
  selectPolicy,
  (state) => state.isPolicyDriversLoading
);
export const selectIsEndorsementsLoading = createSelector(
  selectPolicy,
  (state) => state.isEndorsementsLoading
);
export const selectIsPolicyVehicleLoading = createSelector(
  selectPolicy,
  (state) => state.isPolicyVehicleLoading
);
