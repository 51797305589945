<cla-alerts-bar></cla-alerts-bar>
<div
  [@transform]="{
    value: (rightRailResizeService.opened$ | async) ? 'open' : 'closed',
    params: {
      width: rightRailResizeService.widthMinMax$ | async,
      minWidth: isActivityFeedPilot ? '12.5em' : '10em'
    }
  }"
  class="right-actions"
  [style.min-width]="rightRailResizeService.widthMinMax$ | async"
>
  <button
    mat-button
    [attr.aria-expanded]="rightRailResizeService.opened$ | async"
    class="h-full"
    color="primary"
    [attr.aria-label]="
      isActivityFeedPilot
        ? (rightRailResizeService.opened$ | async)
          ? 'Hide activity feed region'
          : 'Show activity feed region'
        : (rightRailResizeService.opened$ | async)
        ? 'Hide Notes Region'
        : 'Show notes region'
    "
    (click)="onNotesToggle()"
    id="notes-toggle-button"
    data-pgr-id="objNoteButton"
  >
    <mat-icon matPrefix>notes</mat-icon>
    {{ isActivityFeedPilot ? "Activity Feed" : "Notes" }}
  </button>
  <button
    *ngIf="appPathService.notesListPath$ | async as notesListPath"
    mat-icon-button
    color="primary"
    [attr.aria-label]="
      isActivityFeedPilot
        ? 'Open activity feed in new window'
        : 'Open notes in new window'
    "
    (click)="openNotesInNew(notesListPath)"
  >
    <mat-icon>open_in_new</mat-icon>
  </button>
</div>
