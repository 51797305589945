export const xlsMimeTypes = [
  "application/vnd.ms-excel",
  'application/vnd.ms-excel; version="2"',
  'application/vnd.ms-excel; version="2.x"',
  'application/vnd.ms-excel; version="3"',
  'application/vnd.ms-excel; version="3.0"',
  'application/vnd.ms-excel; version="4.0"',
  'application/vnd.ms-excel; version="4s"',
  'application/vnd.ms-excel; version="4w"',
  'application/vnd.ms-excel; version="5/95"',
  'application/vnd.ms-excel; version="8"',
  'application/vnd.ms-excel; version="8x"',
];
