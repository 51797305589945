import { Injectable } from "@angular/core";
import { ExpenseTypeCode, RepAuthority } from "@claimssummaryshared/models";
import {
  PhysicalDamageAuthorityEvent,
  PhysicalDamageAuthorityLastRequest,
} from "@modules/core/models";
import { PaymentsApiService } from "@modules/core/services/payments-api";
import { Observable } from "rxjs";

@Injectable()
export class PaymentsService {
  constructor(private paymentsApiService: PaymentsApiService) {}

  public getPhysicalDamageAuthorityEvents(
    claimNumber: string,
    propertySequenceNumber: number
  ): Observable<PhysicalDamageAuthorityEvent[]> {
    return this.paymentsApiService.get<PhysicalDamageAuthorityEvent[]>(
      `/v1/AuthorityEvents/${claimNumber}/Property/${propertySequenceNumber}/AuthorityEvents`
    );
  }

  public getPhysicalDamageAuthorityLastRequest(
    claimNumber: string,
    propertySequenceNumber: number
  ): Observable<PhysicalDamageAuthorityLastRequest> {
    return this.paymentsApiService.get<PhysicalDamageAuthorityLastRequest>(
      `/v1/AuthorityEvents/${claimNumber}/Property/${propertySequenceNumber}/LastRequest`
    );
  }

  public getRepAuthority(
    expenseTypeCode: ExpenseTypeCode
  ): Observable<RepAuthority> {
    return this.paymentsApiService.get<RepAuthority>(
      `/v1/Rep/Authority/${expenseTypeCode}`
    );
  }
}
