import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { ContentSummaryActions } from "../../../actions";
import { SearchTypeValues } from "../../../constants";
import {
  IAppState,
  IContentDetailsGetRequest,
  IContentDetailsGetResponse,
  IContentSummaryGridFilter,
  IContentSummaryItem,
  IStoreEntity,
} from "../../../models";
import * as ContentSummarySelectors from "../../../selectors/content-summary";

@Injectable()
export class ContentSummaryStoreService {
  public selectedItems$ = this._store$.pipe(
    select(ContentSummarySelectors.selectedItems)
  );

  constructor(private _store$: Store<IAppState>) {}

  public getColumns(): Observable<any[]> {
    return this._store$.pipe(select(ContentSummarySelectors.columns));
  }

  public getContentDetails(): Observable<
    IStoreEntity<IContentDetailsGetResponse>
  > {
    return this._store$.pipe(select(ContentSummarySelectors.contentDetails));
  }

  public getContentSummaryGridFilter(): Observable<IContentSummaryGridFilter> {
    return this._store$.pipe(
      select(ContentSummarySelectors.contentSummaryGridFilter)
    );
  }

  public getDisplayedColumns(): Observable<any[]> {
    return this._store$.pipe(select(ContentSummarySelectors.displayedColumns));
  }

  public getFilterableColumns(): Observable<any[]> {
    return this._store$.pipe(select(ContentSummarySelectors.filterableColumns));
  }

  public getFilteredContentItems(): Observable<
    IContentSummaryItem[] | undefined
  > {
    return this._store$.pipe(
      select(ContentSummarySelectors.filteredContentItems)
    );
  }

  public getFilters(): Observable<{ [key: string]: any }> {
    return this._store$.pipe(select(ContentSummarySelectors.filters));
  }

  public getIsLoading(): Observable<boolean> {
    return this._store$.pipe(select(ContentSummarySelectors.isLoading));
  }

  public getIsFilterResetInProgress(): Observable<boolean> {
    return this._store$.pipe(
      select(ContentSummarySelectors.isFilterResetInProgress)
    );
  }

  public getPartyPropertyName(): Observable<string | undefined> {
    return this._store$.pipe(select(ContentSummarySelectors.partyPropertyName));
  }

  public getPreviewedItem(): Observable<IContentSummaryItem | undefined> {
    return this._store$.pipe(select(ContentSummarySelectors.previewedItem));
  }

  public getRefreshInProgress(): Observable<boolean> {
    return this._store$.pipe(select(ContentSummarySelectors.refreshInProgress));
  }

  public getToggleableColumns(): Observable<any[]> {
    return this._store$.pipe(select(ContentSummarySelectors.toggleableColumns));
  }

  public onContentDetailsRefreshRequested(): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentDetailsRefreshRequested()
    );
  }

  public onContentDetailsRequested(props: {
    request: IContentDetailsGetRequest;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentDetailsRequested(props)
    );
  }

  public onContentSummaryPreviewItem(props: {
    contentItem: IContentSummaryItem;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummaryPreviewItem(props)
    );
  }

  public onContentSummarySearch(props: {
    searchTerm: string;
    searchType: SearchTypeValues;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummarySearchRequested(props)
    );
  }

  public onContentSummaryToggleAllSelectedItems(props: {
    selectAllContentItems: boolean;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummaryToggleAllSelectedItems(props)
    );
  }

  public onContentSummaryToggleAllSelectedItemsPaginated(props: {
    contentItemIds: string[];
    selectAllContentItems: boolean;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummaryToggleAllSelectedItemsPaginated(
        props
      )
    );
  }

  public onContentSummaryDeselectAllSelectedItems(): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummaryDeselectAllSelectedItems()
    );
  }

  public onContentSummaryToggleSelectedItem(props: {
    contentItem: IContentSummaryItem;
  }): void {
    this._store$.dispatch(
      ContentSummaryActions.onContentSummaryToggleSelectedItem(props)
    );
  }

  public onReorderColumns(props: {
    currentIndex: number;
    previousIndex: number;
  }): void {
    this._store$.dispatch(ContentSummaryActions.onReorderColumns(props));
  }

  public onResetColumns(): void {
    this._store$.dispatch(ContentSummaryActions.onResetColumns());
  }

  public onResetFilters(): void {
    this._store$.dispatch(ContentSummaryActions.onResetFilters());
  }

  public onSetColumns(props: {
    columns: any;
    isPartyPropertyView: boolean;
  }): void {
    this._store$.dispatch(ContentSummaryActions.onSetColumns(props));
  }

  public onSetGridFilter(props: {
    claimNumber: string;
    partyId: string;
    propertyId: string;
  }): void {
    this._store$.dispatch(ContentSummaryActions.onSetGridFilter(props));
  }

  public onToggleColumn(props: { column: string }): void {
    this._store$.dispatch(ContentSummaryActions.onToggleColumn(props));
  }

  public onToggleColumnFilters(props: {
    columnId: string;
    showAllFilters: boolean;
  }): void {
    this._store$.dispatch(ContentSummaryActions.onToggleColumnFilters(props));
  }

  public onToggleDateFilter(props: {
    columnId: string;
    dateFilter: any;
  }): void {
    this._store$.dispatch(ContentSummaryActions.onToggleDateFilter(props));
  }

  public onToggleFilter(props: { columnId: string; filterText: string }): void {
    this._store$.dispatch(ContentSummaryActions.onToggleFilter(props));
  }
}
