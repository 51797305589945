import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import {
  ActivatedRoute,
  Event,
  Params,
  Router,
  RoutesRecognized,
} from "@angular/router";
import { PartyPropertyDetail } from "@modules/core";
import { PageTitleService } from "@modules/core/services/page-title-service/page-title.service";
import { UserStateService } from "@modules/core/state";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
  LegacyWindowManagementService,
  WindowManagementService,
} from "@pgr-cla/cla-window";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { combineLatestWith, filter, take, withLatestFrom } from "rxjs";
import {
  ClaimsLandingStateService,
  LeftRailResizeService,
  RightRailResizeService,
  ScreenSizeService,
} from "./services";

@UntilDestroy()
@Component({
  templateUrl: "claims-landing.component.html",
  styleUrls: ["./claims-landing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimsLandingComponent implements OnInit, AfterViewInit {
  claimNumber: string;

  @ViewChild("notes") drawerContainer: MatDrawer;

  constructor(
    windowManagementService: WindowManagementService,
    legacyWindowManagementService: LegacyWindowManagementService,
    public claimDetailsStateService: ClaimDetailsStateService,
    public leftRailResizeService: LeftRailResizeService,
    public rightRailResizeService: RightRailResizeService,
    public screenSizeService: ScreenSizeService,
    private route: ActivatedRoute,
    private pageTitleService: PageTitleService,
    private userStateService: UserStateService,
    private router: Router,
    private claimsLandingStateService: ClaimsLandingStateService,
    private claimNumberPipe: ClaimNumberPipe
  ) {
    this.pageTitleService.init();
    // TODO: remove once all embedded apps are updated
    // to de-register themselves onBeforeUnload
    windowManagementService.initializeChildWindowCloseListener();
    legacyWindowManagementService.initializeChildWindowCloseListener();
    this.claimNumber =
      this.claimNumberPipe.transform(
        this.route.snapshot.params.claimNumber,
        "full"
      ) || "";
    claimDetailsStateService.onPartyPropertyDetailsRequest(this.claimNumber);

    this.route.queryParams
      .pipe(
        filter((params: Params) => !!params && params.actionItemNav),
        combineLatestWith(
          this.claimDetailsStateService.partyPropertyDetails$.pipe(
            filter(
              (partyPropertyDetails: PartyPropertyDetail[] | undefined) =>
                !!partyPropertyDetails
            )
          )
        ),
        take(1)
      )
      .subscribe(([params]: [Params, PartyPropertyDetail[] | undefined]) =>
        this.claimsLandingStateService.onActionItemsRouteRequest(
          JSON.parse(params.actionItemNav)
        )
      );
  }

  ngOnInit(): void {
    this.userStateService.getCurrentUser();
  }

  ngAfterViewInit(): void {
    this.removeRightRailFocusTrap();
  }

  removeRightRailFocusTrap(): void {
    this.drawerContainer["_focusTrap"]._startAnchor.remove();
    this.drawerContainer["_focusTrap"]._endAnchor.remove();
  }

  hideSuccessBanner(): void {
    this.claimDetailsStateService.onSetSuccessBannerMessage("");
  }

  hideErrorBanner(): void {
    this.claimDetailsStateService.onSetErrorBannerMessages([]);
  }

  hideWarningBanner(): void {
    this.claimDetailsStateService.onSetWarningBannerMessage("", false);
  }

  public onRouteChange(): void {
    this.router.events
      .pipe(
        filter((evt: Event) => evt instanceof RoutesRecognized),
        withLatestFrom(this.claimDetailsStateService.successBannerMessage$),
        untilDestroyed(this)
      )
      .subscribe(([, message]: [Event, string]) => {
        if (message.indexOf("Note") != -1) {
          this.hideSuccessBanner();
        }
      });
  }
}
