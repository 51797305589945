import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ClaimDetails } from "@modules/core";

import { lossDetailsAnimations } from "./loss-details.animations";

@Component({
  selector: "cla-loss-details",
  templateUrl: "loss-details.component.html",
  styleUrls: ["./loss-details.component.scss"],
  animations: [lossDetailsAnimations.additionalDetailsExpansion],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LossDetailsComponent {
  @Input() isLoading: boolean;
  @Input() isInitialLoad: boolean;
  @Input() claimDetails: ClaimDetails | undefined;
  expanded = false;

  get isMassachusettsPolicyState(): boolean {
    return this.claimDetails?.policyState.name === "MA";
  }

  constructor(private dialog: MatDialog) {}

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }
}
