<ul class="flex p-bl-1">
  <li style="white-space: nowrap">
    <span>Claim #</span>
    <span *ngIf="!isLoading" class="text-white">
      <cui-copy-button
        [ariaLabel]="'copy claim number ' + headerData?.claimNumber"
        [dataToCopy]="headerData?.claimNumber"
      >
        <div class="claim-number-container">
          {{ headerData?.claimNumber }}
        </div>
      </cui-copy-button>
    </span>
    <span *ngIf="isLoading" class="ghost-line">
      <span>Loading</span>
    </span>
  </li>
  <li>
    <span>Insured</span>
    <span
      *ngIf="!isLoading"
      class="text-white line-clamp-2"
      style="max-width: 25rem"
      >{{ headerData?.insured }}</span
    >
    <span *ngIf="isLoading" class="ghost-line w-20">
      <span>Loading</span>
    </span>
  </li>
  <li>
    <span>Loss Date</span>
    <span *ngIf="!isLoading" class="text-white">{{
      headerData?.dateOfLoss
    }}</span>
    <span *ngIf="isLoading" class="ghost-line">
      <span>Loading</span>
    </span>
  </li>
  <li>
    <span>Since Report</span>
    <span *ngIf="!isLoading" class="text-white line-clamp-1">{{
      headerData?.timeSinceReport
    }}</span>
    <span *ngIf="isLoading" class="ghost-line">
      <span>Loading</span>
    </span>
  </li>
  <li style="min-width: 85px">
    <span style="white-space: nowrap; overflow: hidden">File Owner</span>
    <span *ngIf="!isFileOwnerLoading && ownerRepSummary">
      <div class="flex flex-wrap">
        <cui-popover align="bottom">
          <button
            class="cui-popover-link header-link"
            cui-popover-trigger
            data-pgr-id="btnHeaderContactCard"
          >
            {{ ownerRepSummary.name }} ({{ ownerRepSummary.code }})
          </button>
          <cla-claim-rep-contact-card-redacted
            [rep]="ownerRepSummary"
            cui-popover-content
          ></cla-claim-rep-contact-card-redacted>
        </cui-popover>
      </div>
    </span>
    <span *ngIf="!isFileOwnerLoading && !ownerRepSummary" class="text-white">
      {{ ownerRepCode }}
    </span>
    <span *ngIf="!isFileOwnerLoading && !ownerRepCode" class="text-white">
      Unassigned
    </span>
    <span *ngIf="isFileOwnerLoading" class="ghost-line w-20">
      <span>Loading</span>
    </span>
  </li>
</ul>
