import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "cla-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent {
  @Output() public continuePressed: EventEmitter<void> = new EventEmitter();
  @Output() public cancelPressed: EventEmitter<void> = new EventEmitter();

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any },
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}
}
