import { Injectable } from "@angular/core";
import { BusMessage } from "@modules/core";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MessageBusService {
  private _sourceSubject$: Subject<BusMessage> = new Subject();

  public getStream$(type: string): Observable<BusMessage> {
    return this._sourceSubject$.pipe(filter((x) => x.type === type));
  }

  public dispatch(message: BusMessage): void {
    this._sourceSubject$.next(message);
  }
}
