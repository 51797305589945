import { Action, createReducer, on } from "@ngrx/store";
import { spinnerActions } from "../actions";

export enum SPINNERID {
  ERROR = "error",
}

export interface State {
  pageActive: number;
  pageActionInProgress: any[];
}

export const initialState: State = {
  pageActive: 0,
  pageActionInProgress: [],
};

const spinnerReducer = createReducer(
  initialState,
  on(spinnerActions.showSpinner, (state, action) => {
    const newActionsInProgress = [
      ...state.pageActionInProgress,
      action.actionType,
    ];
    const updateSpinnerState = {
      pageActionInProgress: newActionsInProgress,
      pageActive: newActionsInProgress.length,
    };
    return { ...updateSpinnerState };
  }),

  on(spinnerActions.hideSpinner, (state, action) => {
    if (action.spinnerId === SPINNERID.ERROR) {
      return { ...initialState };
    }
    {
      const newActionsInProgress = action.actionType
        ? state.pageActionInProgress.filter(
            (currentAction: any) => currentAction !== action.actionType
          )
        : state.pageActionInProgress;

      const updateSpinnerState = {
        pageActionInProgress: newActionsInProgress,
        pageActive: state.pageActive > 0 ? newActionsInProgress.length : 0,
      };
      return { ...updateSpinnerState };
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return spinnerReducer(state, action);
}
