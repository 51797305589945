import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserContextService } from "@modules/core";
import { Store } from "@ngrx/store";
import { AppState } from "@store/app-state.interface";
import { Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

import { CVQ } from "../../models/cvq.interface";
import { Rep } from "../../models/rep.interface";
import { onCVQRequested } from "../../store/coverage.action";
import {
  cvqRequestError,
  cvqs,
  isRequestInFlight,
  rep,
} from "../../store/coverage.selectors";

import { PolicyResourceConstant } from "@modules/policy/helpers/policy-resource.constant";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { CVQListComponent } from "./components/list-component/list-component.component";

@Component({
  selector: "cla-cvq-tab",
  templateUrl: "./cvq-tab.component.html",
  styleUrls: ["./cvq-tab.component.scss"],
})
export class CvqTabComponent implements OnInit, OnDestroy {
  selection = new SelectionModel<CVQ>(true, []);
  dataSource$: Observable<CVQ[] | undefined>;
  isManager$: Observable<boolean>;
  requestInFlight$: Observable<boolean>;
  claimNumber: string | undefined;
  rep: Rep | undefined;
  hasCvqRequestError = false;
  defaultErrorMessage = PolicyResourceConstant.defaultErrorMessage;

  @ViewChild(CVQListComponent)
  listComponent: CVQListComponent;
  component: { selection: any };

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    private userContextService: UserContextService,
    public store$: Store<AppState>,
    private route: ActivatedRoute,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  ngOnInit(): void {
    this.dataSource$ = this.store$.select(cvqs);
    this.requestInFlight$ = this.store$.select(isRequestInFlight);
    this.isManager$ = this.userContextService.hasPermission([
      "ClaimsCoreClaimMgr",
    ]);

    this.route.params.pipe(first()).subscribe((params) => {
      this.claimNumber =
        this.claimNumberPipe.transform(params["claimNumber"], "full") || "";
      this.refreshCVQs();
    });

    this.store$
      .select(rep)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((currentRep) => (this.rep = currentRep));

    this.store$
      .select(cvqRequestError)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((error) => (this.hasCvqRequestError = error));

    // if (this.userContextService.getUserProfile().uid !== undefined) {
    //   // this.store$.dispatch(actionOnRepRequested({ repCode: this.userContextService.getUserProfile().uid }));
    // }
  }

  refreshCVQs() {
    if (this.claimNumber !== undefined) {
      this.store$.dispatch(onCVQRequested({ claimNumber: this.claimNumber }));

      if (this.listComponent) {
        this.listComponent.selection.clear();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }
}
