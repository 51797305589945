export class EFFConstants {
  public static get validation(): { claimNumberLength: 11 } {
    return {
      claimNumberLength: 11,
    };
  }

  public static get mimeTypes(): { [key: string]: string[] } {
    return {
      imageMimeTypes: ["image/jp2", "image/jpeg", "image/png"],
      documentMimeTypes: ["image/tiff", "image/tif", "application/pdf"],
      audioVideoMimeTypes: [
        "audio/mp4",
        "audio/mpeg3",
        "video/mp4",
        "video/3gpp",
      ],
    };
  }

  public static get pdfMimeType(): string {
    return "application/pdf";
  }

  public static get codes(): { [key: string]: any } {
    return {
      origin: {
        import: "01",
        outbound: "02",
        inbound: "03",
      },
      deliveryMethod: {
        eSign: "06",
      },
      partyProperty: {
        party: "03",
        property: "02",
      },
    };
  }

  public static get contentTypes(): { [key: string]: string } {
    return {
      draft: "draft",
      committedUltramate: "committed ultramate",
      preliminaryUltramate: "preliminary ultramate",
    };
  }

  public static get display(): { [key: string]: string } {
    return {
      timeZone: "America/New_York",
    };
  }

  public static get clickActionNames(): { [key: string]: string } {
    return {
      changeStatus: "Change Status",
      contentSubmission: "Content Submission",
      columns: "Columns",
      copy: "Copy",
      copyPackageLink: "Package Copy Link",
      //create: 'Create',
      details: "Details",
      editDraft: "Edit Draft",
      envelopeContents: "Envelope Contents",
      filter: "Filter",
      merge: "Merge",
      move: "Move",
      open: "Open",
      packageData: "Package Data",
      // photoEstimate: 'Photo Estimate',
      preview: "Preview",
      refresh: "Refresh",
      remove: "Remove",
      search: "Search",
      searchContent: "Content Search",
      select: "Select",
      share: "Share",
      sharingSummary: "Sharing Summary",
      sort: "Sort",
      //upload: 'Upload',
      utilizationReview: "Utilization Review",
      versions: "Versions",
    };
  }

  public static get viewer(): { [key: string]: any } {
    return {
      committedEstimatePrefix: "99999999-9999-9999-9999-9999999999",
      preliminaryEstimatePrefix: "00000000-0000-0000-0000-0000000000",
      mergeableStatuses: ["Completed", "Duplicate", "Received"],
    };
  }

  public static get removeDocSet(): { [key: string]: any } {
    return {
      title: "Remove content from claim?",
      multipleMessage: {
        message: `This content has multiple versions, removing it will remove all versions from this claim.`,
      },
      errorMessage: `An issue occurred while removing the document. Please try again.`,
    };
  }

  public static get packageDocumentType(): string {
    return "308";
  }
}
