export const partyConstants = Object.freeze({
  vehiclePropertyType: "vehicle",
  fixedPropertyPropertyType: "fixedprop",
  unlistedDriverCode: "U",
  unlistedDriverDescription: "Driver - Unlisted",
  listedDriverCode: "D",
  listedDriverDescription: "Driver - Listed",
  guestPassengerCode: "G",
  guestPassengerDescription: "Guest Passenger",
  pedestrianCode: "P",
  pedestrianDescription: "Pedestrian",
  insuredCode: "I",
  insuredNumberCode: "003",
  internationalStateCode: "IT",
  internationalCountryCode: "INT",
  claimantCode: "C",
  claimantNumberCode: "002",
  claimantDescription: "Claimant",
  ownerCode: "O",
  maritalStatusMarriedCode: "M",
  maritalStatusMarriedDescription: "Married",
  namedInsuredCode: "N",
  namedInsuredNumberCode: "004",
  genderUnknownCode: "U",
  genderUnknownDescription: "Unknown",
  relationToClaimWitnessCode: "005",
  relationToClaimWitnessDescription: "Witness",
  businessWorkContactPreference: "W",
  textContactPreference: "T",
  homePhonePreference: "H",
  workPhonePreference: "W",
  otherPhonePreference: "O",
  emailContactPreference: "E",
  cellularPhoneType: "CL",
  homePhoneType: "HM",
  workPhoneType: "WK",
  otherClaimantMedicareCode: "035",
  relationToClaimAssociatedParty: "034",
  emailPreferredPaperless: "EMAIL",
  uspsPreferredPaperless: "USPS",
});
