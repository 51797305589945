import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { RestrictedClaim } from "@modules/core/models";
import { ClaimDetailsStateService } from "@modules/core/state/claim-details/claim-details-state.service";
import { Observable, of } from "rxjs";
import { ClaimManagementApiService } from "../claim-management-api";

@Injectable()
export class ClaimGuardService implements CanActivate {
  constructor(
    private router: Router,
    private claimDetailsState: ClaimDetailsStateService,
    private claimManagementApiService: ClaimManagementApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (Number(route.params.claimNumber)) {
      this.claimDetailsState.setClaimNumber(route.params.claimNumber);
      return this.isClaimValid(route.params.claimNumber);
    }
    return of(false);
  }

  private isClaimValid(claimNumber: string): Observable<boolean> {
    return of(true);
    // return this.claimManagementApiService
    //   .get<RestrictedClaim>(`/lossviewerapi/v1/claims/${claimNumber}/restricted`)
    //   .pipe(
    //     map((restrictedClaim: RestrictedClaim) => {
    //       if (this.userIsRestricted(restrictedClaim)) {
    //         this.router.navigateByUrl(`${claimNumber}/restricted-claim`);
    //         return false;
    //       }
    //       return true;
    //     }),
    //     catchError((error) => {
    //       this.handleError(error);
    //       return of(false);
    //     }),
    //   );
  }

  private userIsRestricted(restrictedClaim: RestrictedClaim): boolean {
    return restrictedClaim.isRestricted && !restrictedClaim.accessibleByRepCode;
  }

  private handleError(error: HttpErrorResponse) {
    let route = "";
    switch (error.status) {
      case 400:
        route = "claim-not-found";
        break;
      case 403:
        route = "unauthenticated";
        break;
      case 404:
        route = "claim-not-found";
        break;
      case 500:
        route = "techdiff";
        break;
      default:
        route = "";
    }
    this.router.navigateByUrl(`/${route}`);
  }
}
