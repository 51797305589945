import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CoverageDetailInfo } from "@claimssummaryshared/models/claims-landing";
import { FeatureTableData } from "@modules/claims-summary/models/feature-table-data.interface";
import { RouterStateService } from "@modules/core/state/router";
import { take } from "rxjs";

const maxItemsWhenCollapsed = 5;

@Component({
  selector: "cla-coverages-tile",
  templateUrl: "coverages-tile.component.html",
  styleUrls: ["./coverages-tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoveragesTileComponent {
  dataSource: MatTableDataSource<FeatureTableData>;
  pageSize = maxItemsWhenCollapsed;
  expanded = false;
  claimNumber: string;
  @Input() isLoading: boolean;
  @Input() isInitialLoad: boolean;
  @Input() coverages: CoverageDetailInfo | undefined;
  @Input() coveragesError: HttpErrorResponse | undefined;
  @Output() fetchCoverageData: EventEmitter<void> = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public height: string;

  constructor(
    private router: Router,
    private routerStateService: RouterStateService
  ) {
    this.routerStateService.routeParams$
      .pipe(take(1))
      .subscribe((routeParams) => {
        if (routeParams && routeParams.claimNumber) {
          this.claimNumber = routeParams.claimNumber;
        }
      });
  }

  STATUS_INDICATORS: { [key: string]: string } = {
    "CVQ Pending": "alert",
    "CVQ Resolved": "active",
    "No CVQs": "active",
    "Not Verified": "warn",
  };

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.paginator._changePageSize(Number.MAX_SAFE_INTEGER);
    } else {
      this.paginator._changePageSize(maxItemsWhenCollapsed);
    }
  }

  navigateCoverage(): void {
    this.router.navigate([`${this.claimNumber}/coverage`]);
  }
}
