import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  PaymentDetailsLambdaInfo,
  PaymentsState,
} from "@modules/payments/models";
import { Column } from "@modules/payments/models/column.interface";
import { selectRouteParam } from "@modules/shared";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as paymentsActions from "../../actions";
import * as paymentsSelectors from "../../selectors";

@Injectable()
export class PaymentsStateService {
  public state$: Observable<PaymentsState> = this.store$.select(
    paymentsSelectors.selectPaymentsState
  );

  public payments$: Observable<PaymentDetailsLambdaInfo[] | undefined> =
    this.store$.select(paymentsSelectors.selectPayments);

  public filters$: Observable<{ [key: string]: any }> = this.store$.select(
    paymentsSelectors.selectPaymentsFilters
  );

  public filterableColumns$: Observable<Column[] | undefined> =
    this.store$.select(paymentsSelectors.selectFilterableColumns);

  public paymentsTableData$: Observable<
    PaymentDetailsLambdaInfo[] | undefined
  > = this.store$.select(paymentsSelectors.selectPaymentsTableData);

  public paymentsRequestInFlight$: Observable<boolean> = this.store$.select(
    paymentsSelectors.selectPaymentsRequestInFlight
  );

  public paymentsError$: Observable<HttpErrorResponse | undefined> =
    this.store$.select(paymentsSelectors.selectPaymentsError);

  public selectedClaimNumber$: Observable<string> = this.store$.select(
    selectRouteParam("claimNumber")
  );

  constructor(private store$: Store<PaymentsState>) {
    this.payments$.subscribe();
    this.filters$.subscribe();
    this.paymentsTableData$.subscribe();
    this.paymentsRequestInFlight$.subscribe();
    this.paymentsError$.subscribe();
    this.selectedClaimNumber$.subscribe();
  }

  public retrieveCurrentClaimPayments(): void {
    this.store$.dispatch(
      paymentsActions.retrieveCurrentClaimPayments({
        keepExistingPayments: false,
      })
    );
  }

  public filterPayments(props: { columnId: string; filterText: any }) {
    this.store$.dispatch(paymentsActions.filterPaymentsSelect(props));
  }

  public filterPaymentsNumberRange(props: {
    columnId: string;
    minText: string | undefined;
    maxText: string | undefined;
  }) {
    this.store$.dispatch(paymentsActions.filterPaymentsNumberRange(props));
  }

  public resetColumnFilters(props: { columnId: string }) {
    this.store$.dispatch(paymentsActions.resetColumnFilters(props));
  }

  public resetPaymentFilters() {
    this.store$.dispatch(paymentsActions.resetPaymentsFilters());
  }

  public closePaymentFilters() {
    this.store$.dispatch(paymentsActions.closePaymentsFilter());
  }
}
