import { Pipe, PipeTransform } from "@angular/core";

import { SubroFeaStatCodes } from "@modules/subrogation-summary/constants/subrogation.constants";

@Pipe({
  name: "claStatusIndicatorClassPipe",
})
export class StatusIndicatorClassPipe implements PipeTransform {
  transform(statusCode: string, closeReason: string): string {
    if (statusCode === SubroFeaStatCodes.Open) {
      return "active";
    }
    if (statusCode === SubroFeaStatCodes.Closed && closeReason !== "15") {
      return "inactive";
    }
    return "warn";
  }
}
