import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "@core/services/authentication";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "cla-auth-callback",
  templateUrl: "./auth-callback.component.html",
  styleUrls: ["./auth-callback.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthCallbackComponent implements AfterViewInit {
  authCode: string = "Code not found";

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router
  ) {}

  ngAfterViewInit(): void {
    if (!window.location.hash || window.location.hash.length === 0) {
      const queryString = window.location.search.substring(1); // substring strips '?'
      const path = [window.location.pathname, queryString].join("#");
      window.location.assign(new URL(path, window.location.href).toString());
    } else if (
      new URLSearchParams(window.location.hash.substring(1)).has("code")
    ) {
      this.authenticationService
        .completeAuthorizationRequest()
        .then((token) => {
          if (token.accessToken) {
            this.authenticationService.setToken(token.accessToken);
          }
          // if (token.idToken) {
          //   this.authenticationService.setToken(token.idToken);
          // }
          this.router.navigate(["welcome"]);
        })
        .catch(() => {
          this.router.navigate(["unauthenticated"]);
        });
    } else {
      this.router.navigate(["unauthenticated"]);
    }
  }
}
