<div class="subheader flex flex-row w-full justify-between items-center p-bl-2">
  <span class="text-base font-regular" data-pgr-id="txtNotesCount">
    <ng-container
      *ngIf="
        (notesStateService.isToDoNotesTabSelected$ | async) === false;
        else toDoNotesCount
      "
    >
      {{
        (notesStateService.isSearchedOrFiltered$ | async)
          ? (notesStateService.filteredNotes$ | async).length + " of "
          : ""
      }}
      {{ (notesStateService.notesAndJournals$ | async).length || 0 }}
      {{
        "note"
          | cuiPluralize: (notesStateService.notesAndJournals$ | async).length
      }}
    </ng-container>
    <ng-template #toDoNotesCount>
      {{ (notesStateService.toDoNotes$ | async).length || 0 }}
      {{ "note" | cuiPluralize: (notesStateService.toDoNotes$ | async).length }}
    </ng-template>
    found
  </span>
  <div class="flex">
    <button
      class="small mr-bl-2"
      data-pgr-id="btnRefreshNotes"
      mat-icon-button
      aria-label="Refresh Notes"
      (click)="refreshNotes()"
      color="primary"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      class="small"
      data-pgr-id="btnSortNotes"
      mat-button
      [attr.aria-label]="
        'Sort notes in ' +
        ((notesStateService.sortDirection$ | async) === 'asc'
          ? 'descending'
          : 'ascending') +
        ' order'
      "
      (click)="sortNotes()"
    >
      <mat-icon matPrefix>{{
        (notesStateService.sortDirection$ | async) === "asc"
          ? "arrow_upward"
          : "arrow_downward"
      }}</mat-icon>
      Date Created
    </button>
  </div>
</div>
