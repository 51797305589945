import { Dictionary } from "@ngrx/entity";
import { createFeatureSelector, createSelector } from "@ngrx/store";

import { CodeTableCode } from "@modules/shared/models";
import {
  AdverseParty,
  ClaimFeature,
  PartyPropertyInfo,
} from "@subrogation-summary/models";

import * as fromAdverseParties from "./adverse-parties.reducer";
import * as fromClaimFeature from "./claim-feature.reducer";
import * as fromOutOfPockets from "./out-of-pockets.reducer";
import * as fromRecoveryHistory from "./recovery-history.reducer";
import * as fromSubrogationSummaryCodes from "./subrogation-summary-codes.reducer";

export const featureName = "summaryFeature";

const referralType = {
  insured: 1,
  uninsured: 2,
};

export interface SubrogationSummaryState {
  adverseParties: fromAdverseParties.AdversePartiesState;
  outOfPocketsBranch: fromOutOfPockets.OutOfPocketsState;
  claimFeatures: fromClaimFeature.ClaimFeatureState;
  subrogationSummaryCodes: fromSubrogationSummaryCodes.SubrogationSummaryCodesState;
  recoveryHistoryBranch: fromRecoveryHistory.RecoveryHistoryState;
}

export const reducers = {
  adverseParties: fromAdverseParties.reducer,
  outOfPocketsBranch: fromOutOfPockets.reducer,
  subrogationSummaryCodes: fromSubrogationSummaryCodes.reducer,
  recoveryHistoryBranch: fromRecoveryHistory.reducer,
  claimFeatures: fromClaimFeature.reducer,
};

/****************
 *   Selectors   *
 ****************/
export const selectSubrogationSummaryBranch =
  createFeatureSelector<SubrogationSummaryState>(featureName);
export const selectOutOfPocketsBranch = createSelector(
  selectSubrogationSummaryBranch,
  (state) => state.outOfPocketsBranch
);
export const selectSubrogationSummaryCodesBranch = createSelector(
  selectSubrogationSummaryBranch,
  (state) => state.subrogationSummaryCodes
);
export const selectRecoveryHistoryBranch = createSelector(
  selectSubrogationSummaryBranch,
  (state) => state.recoveryHistoryBranch
);
export const selectAdversePartiesBranch = createSelector(
  selectSubrogationSummaryBranch,
  (state) => state.adverseParties
);
export const selectClaimFeaturesBranch = createSelector(
  selectSubrogationSummaryBranch,
  (state) => state.claimFeatures
);

const { selectEntities: selectAdversePartiesEntities } =
  fromAdverseParties.adapter.getSelectors(selectAdversePartiesBranch);

const { selectEntities: selectClaimFeatureEntities } =
  fromClaimFeature.claimFeatureAdapter.getSelectors(selectClaimFeaturesBranch);

export const selectAdverseParties = createSelector(
  selectAdversePartiesEntities,
  (entities) => entities
);

export const selectClaimFeatures = createSelector(
  selectClaimFeatureEntities,
  (entities) => entities
);

export const selectAdverseParty = createSelector(
  selectAdverseParties,
  (entities: Dictionary<AdverseParty>, props: { subroObligationId: number }) =>
    entities[props.subroObligationId]
);

export const selectClaimFeature = createSelector(
  selectClaimFeatures,
  (entities: Dictionary<ClaimFeature>, props: { featureId: number }) =>
    entities[props.featureId]
);

export const selectSubroFeatures = createSelector(
  selectAdverseParty,
  (party: AdverseParty) => party.subroFeatures
);

export const selectLiabilityPercent = createSelector(
  selectAdverseParty,
  (party: AdverseParty) => party.liabilityPercentage || 0
);

export const { selectAll: selectClaimFeaturesArray } =
  fromClaimFeature.claimFeatureAdapter.getSelectors(selectClaimFeaturesBranch);

// export const selectFailedToLoadAdverseParties = createSelector(
//   selectErrorsBranch,
//   (errors) => errors.failedToLoadAdverseParties,
// );

// export const selectFailedToSaveLetterCode = createSelector(
//   selectErrorsBranch,
//   (errors) => errors.failedToSaveLetterCode,
// );

export const selectLetterCodeById = createSelector(
  selectAdverseParties,
  (
    adverseParties: Dictionary<AdverseParty>,
    props: { subroObligationId: number }
  ) => {
    const adverseParty = adverseParties[props.subroObligationId.toString()];
    return adverseParty ? adverseParty.letterTypeCode : "";
  }
);

export const selectRecoveryHistoryBySubroObligationId = createSelector(
  selectRecoveryHistoryBranch,
  (
    state: fromRecoveryHistory.RecoveryHistoryState,
    props: { subroObligationId: number }
  ) => {
    const recoveryHistory = state[props.subroObligationId];
    return recoveryHistory?.recoveryHistoryItems || [];
  }
);

export const selectOutOfPocketsEntityState = createSelector(
  selectOutOfPocketsBranch,
  (state) => state.outOfPockets
);

export const selectOutOfPockets = createSelector(
  selectOutOfPocketsEntityState,
  fromOutOfPockets.selectors.selectAll
);

export const selectFailedToLoadOutOfPockets = createSelector(
  selectOutOfPocketsBranch,
  (state) => state.errors.failedToLoadOutOfPockets
);

export const selectAllSubrogationSummaryCodes =
  selectSubrogationSummaryCodesBranch;

export const selectAdversePartyCodes = createSelector(
  selectSubrogationSummaryCodesBranch,
  (subrogationSummaryCodes) => ({
    fileTypeCodes: subrogationSummaryCodes.fileTypes,
    liabilityDecisionTypeCodes: subrogationSummaryCodes.liabilityDecisionType,
    deductibleStatusCodes: subrogationSummaryCodes.deductibleStatusCodes,
  })
);

export const selectFeatureCodes = createSelector(
  selectSubrogationSummaryCodesBranch,
  (subrogationSummaryCodes) => ({
    collectibleOverrideReasonCodes:
      subrogationSummaryCodes.collectibleOverrideReasonCodes,
  })
);

export const selectCarrierContactCodes = createSelector(
  selectSubrogationSummaryCodesBranch,
  (subrogationSummaryCodes) => ({
    countryCodes: subrogationSummaryCodes.countryCodes,
    // otherPhoneTypeCodes: subrogationSummaryCodes.otherPhoneTypeCodes,
    claimantCarrierCodes: subrogationSummaryCodes.claimantCarriers,
  })
);

export const selectPartyContactCodes = createSelector(
  selectSubrogationSummaryCodesBranch,
  (subrogationSummaryCodes) => ({
    partySexCodes: subrogationSummaryCodes.partySex,
    countryCodes: subrogationSummaryCodes.countryCodes,
    otherPhoneTypeCodes: subrogationSummaryCodes.otherPhoneTypes,
    stateCodes: subrogationSummaryCodes.states,
  })
);

// export const selectLetterTypeCodes = createSelector(
//   selectSummaryCodesBranch,
//   (summaryCodes) => summaryCodes.letterTypeCodes,
// );

export const selectOutOfPocketExpenseTypeCodes = createSelector(
  selectSubrogationSummaryCodesBranch,
  (subrogationSummaryCodes) => subrogationSummaryCodes.outOfPocketExpenseType
);

// export const selectReferralTypeCodes = createSelector(
//   selectSummaryCodesBranch,
//   (summaryCodes) => summaryCodes.referralTypeCodes,
// );

// export const selectReferralTypeCodeForParty = createSelector(
//   selectAdverseParty,
//   selectReferralTypeCodes,
//   (ap, codes) => {
//     const partyReferralTypeId = ap?.insuranceCarrierInfo
//       ? referralType.insured
//       : referralType.uninsured;
//     return codes.find(
//       (code) => code.referralTypeId === partyReferralTypeId,
//     ) as ReferralType;
//   },
// );

// export const selectCloseReasonCodes = createSelector(
//   selectFeatureCodes,
//   (featureCodes) => featureCodes.closeReasonCodes,
// );

// export const selectCloseReasonDescriptionByCode = createSelector(
//   selectCloseReasonCodes,
//   (reasonCodes: CodeTableCode[], props: { code: string }) =>
//     reasonCodes.find((reasonCode) => reasonCode.code === props.code)
//       ?.description,
// );

export const selectPartyPropertyInfo = createSelector(
  selectClaimFeaturesBranch,
  selectClaimFeature,
  (cf, f) =>
    ({
      featureTypeCode: f?.featureTypeCode,
      partyBusinessInd: f?.partyBusinessInd,
      partyFirstName: f?.partyName.first,
      partyMiddleInitial: f?.partyName.middleInitial,
      partyLastName: f?.partyName.last,
      partyLastNameSuffix: f?.partyName.suffix,
      propertyClaimTypeCode: f?.propertyClaimTypeCode,
      vehicleManufacturer: f?.vehicle?.make,
      vehicleModelName: f?.vehicle?.model,
      vehicleModelYear: f?.vehicle?.year,
      fixedPropertyDescription: f?.fixedProperty?.description,
      businessName: f?.businessName,
    } as PartyPropertyInfo)
);
