import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { LoggedMessageLevel, LoggingService } from "@pgr-cla/cla-logging";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as subrogationSummaryCodesActions from "../actions/subrogation-summary-codes.actions";
import { SubrogationSummaryCodes } from "../../models/subrogation-summary-codes.interface";
import { SubrogationSummaryService } from "@subrogation-summary/services";

@Injectable()
export class SubrogationSummaryCodesEffects {
  constructor(
    private action$: Actions,
    private loggingService: LoggingService,
    private subrogationSummaryService: SubrogationSummaryService
  ) {}

  loadSubrogationSummaryCodes$ = createEffect(() =>
    this.action$.pipe(
      ofType(subrogationSummaryCodesActions.loadSubrogationSummaryCodes),
      switchMap((action) =>
        this.subrogationSummaryService
          .getSubrogationSummaryCodes(action.claimNumber)
          .pipe(
            map((subrogationSummaryCodes: SubrogationSummaryCodes) =>
              subrogationSummaryCodesActions.loadSubrogationSummaryCodesSucceeded(
                { subrogationSummaryCodes }
              )
            ),
            catchError((error) => {
              this.loggingService.log(error, LoggedMessageLevel.Error);
              return of(
                subrogationSummaryCodesActions.loadSubrogationSummaryCodesFailed(
                  {
                    errorMsg: error.message,
                  }
                )
              );
            })
          )
      )
    )
  );
}
