import { Feature } from "@modules/feature/models/feature";
import { createAction, props, union } from "@ngrx/store";

import { ClaimInfo } from "../../models/feature/claim-info";
import { FeatureListForm } from "../../models/feature/feature-list";

export const getClaimFeaturesWithPreReq = createAction(
  "[FR List] Get Claim Features With Prerequisites",
  props<{ claimNumber: string; showSpinner?: true }>()
);

export const getClaimFeaturesWithPreReqSuccess = createAction(
  "[FR List] Get Claim Features With Prerequisites Success",
  props<{
    claimNumber: string;
    claimInfo: ClaimInfo | null;
    features: Feature[];
  }>()
);

export const refreshClaimInfoAndFeatures = createAction(
  "[FR List] Referesh ClaimInfo and Features",
  props<{
    claimInfo: ClaimInfo | null;
    features: FeatureListForm[];
  }>()
);

export const clearReq = createAction("[FR List] clear Req");

const all = union({
  getClaimFeaturesWithPreReq,
  getClaimFeaturesWithPreReqSuccess,
  refreshClaimInfoAndFeatures,
  clearReq,
});

export type ListActionsUnion = typeof all;
