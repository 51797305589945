import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { ClaimSummaryAnalyticsEventService } from "@modules/core";
import { ClaimDetailsStateService } from "@modules/core/state";
import { DiaryStateService } from "@modules/diary/services";
import { UntilDestroy } from "@ngneat/until-destroy";
import { EventDispatchService, EventModel } from "@pgr-cla/cla-analytics";

@UntilDestroy()
@Component({
  selector: "cla-diary",
  templateUrl: "./diary.component.html",
  styleUrls: ["./diary.component.scss"],
})
export class DiaryComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public diaryStateService: DiaryStateService,
    private claimDetailsStateService: ClaimDetailsStateService,
    private claimSummaryAnalyticsEventService: ClaimSummaryAnalyticsEventService,
    private readonly _eventDispatchService: EventDispatchService
  ) {}
  ngAfterViewInit(): void {
    const diaryView: EventModel =
      this.claimSummaryAnalyticsEventService.diaryDetailsPageView();
    this._eventDispatchService.trackPageView(diaryView);
  }

  ngOnInit(): void {
    this.diaryStateService.retrieveCurrentClaimDiaries();
    this.claimDetailsStateService.onSetPageScrollingEnabled(false);
  }

  public ngOnDestroy(): void {
    this.hideSuccessBanner();
    this.hideErrorBanner();
    this.claimDetailsStateService.onSetPageScrollingEnabled(true);
  }

  hideSuccessBanner(): void {
    this.claimDetailsStateService.onSetSuccessBannerMessage("");
  }

  hideErrorBanner(): void {
    this.claimDetailsStateService.onSetErrorBannerMessages([]);
  }
}
