import { Component, Input } from "@angular/core";
import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";

@Component({
  selector: "cla-tree-party-contact-card",
  templateUrl: "./tree-party-contact-card.component.html",
})
export class TreePartyContactCardComponent {
  constructor(
    public coreUiExtensionsStateService: CoreUiExtensionsStateService
  ) {}

  @Input() partyId: string;
  @Input() partyName: string;
  @Input() hasAttorney: boolean;

  get buttonAriaText(): string {
    return "Open party contact card for " + this.partyName;
  }

  onPopoverOpen(): void {
    this.coreUiExtensionsStateService.getPartySummaries(this.partyId);
  }
}
