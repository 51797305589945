import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiOptions } from "@modules/core/models";
import { AuthenticationService } from "@modules/core/services/authentication";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { Observable } from "rxjs";
import { first, map, mergeMap, timeout, withLatestFrom } from "rxjs/operators";

@Injectable()
export class ApiService {
  private accessOrigin = "*";
  private accessMethods = "*";
  private accessHeaders =
    "Origin, X-Requested-With, Content-Type, Accept, cache-control,expires,pragma,Authorization,X-PGR-APPNAME,X-PGR-APPVERSION";

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
    private config: ConfigurationService
  ) {}

  get<T>(
    url$: Observable<string>,
    headers?: HttpHeaders,
    options: ApiOptions = {}
  ): Observable<T> {
    return url$.pipe(
      withLatestFrom(
        this.config.configuration$,
        this.buildNoPayloadHeaders(headers)
      ),
      first(([, config]) => config !== null && config !== undefined),
      mergeMap(([url, config, header]) => {
        return this.httpClient
          .get<T>(url, { headers: header })
          .pipe(timeout(this.getHttpTimeout(options, config.httpCallTimeout)));
      })
    );
  }

  post<T, R>(
    url$: Observable<string>,
    payload: R,
    headers?: HttpHeaders,
    options: ApiOptions = {}
  ): Observable<T> {
    return url$.pipe(
      withLatestFrom(this.config.configuration$, this.buildHeaders(headers)),
      first(([, config]) => config !== null && config !== undefined),
      mergeMap(([url, config, header]) => {
        return this.httpClient
          .post<T>(url, JSON.stringify(payload), { headers: header })
          .pipe(timeout(this.getHttpTimeout(options, config.httpCallTimeout)));
      })
    );
  }

  getHttpTimeout(options: ApiOptions, to: number): number {
    return options.timeout || to;
  }

  private buildHeaders(headers?: HttpHeaders): Observable<HttpHeaders> {
    const hdrs = headers || new HttpHeaders();
    return this.authService.token$.pipe(
      map((token) => {
        return hdrs
          .set("Content-Type", "application/json")
          .set("Authorization", `Bearer ${token}`);
      })
    );
  }

  private buildNoPayloadHeaders(
    headers?: HttpHeaders
  ): Observable<HttpHeaders> {
    const hdrs = headers || new HttpHeaders();
    return this.authService.token$.pipe(
      map((token) => {
        return hdrs.set("Authorization", `Bearer ${token}`);
      })
    );
  }
}
