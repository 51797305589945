import { Injectable } from "@angular/core";
import { RepSummary } from "@claimssummaryshared/models";
import { selectRouteParam } from "@modules/shared";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Diary, DiaryState, DiaryTableData } from "@diary/models";
import * as diarySelectors from "@diary/selectors";
import * as diaryActions from "@diary/actions";
import { HttpErrorResponse } from "@angular/common/http";
import { CellData } from "@pgr-cla/core-ui-components/lib/core/components/complex-datepicker/models";

@Injectable()
export class DiaryStateService {
  selectedClaimNumber$: Observable<string> = this.store$.select(
    selectRouteParam("claimNumber")
  );

  diaries$: Observable<Diary[] | undefined> = this.store$.select(
    diarySelectors.selectDiaries
  );

  diaryTableData$: Observable<DiaryTableData[]> = this.store$.select(
    diarySelectors.selectDiaryTableData
  );

  diaryRequestInFlight$: Observable<boolean> = this.store$.select(
    diarySelectors.selectDiaryRequestInFlight
  );

  diaryError$: Observable<HttpErrorResponse | undefined> = this.store$.select(
    diarySelectors.selectDiaryError
  );

  selectDiaryCountData$: Observable<CellData[] | undefined> =
    this.store$.select(diarySelectors.selectDiaryCountData);

  public retrieveCurrentClaimDiaries(): void {
    this.store$.dispatch(
      diaryActions.retrieveCurrentClaimDiaries({ keepExistingDiaries: false })
    );
  }

  public retrieveRepDiaryCountData(): void {
    this.store$.dispatch(diaryActions.getClaimDiaryCountData());
  }

  constructor(private store$: Store<DiaryState>) {}
}
