import { Action } from "@ngrx/store";

import { IContentSummaryState } from "../../models/content-summary";

import * as fromContentSummary from "./content-summary.reducer";

export function reducers(
  state: IContentSummaryState | undefined,
  action: Action
): IContentSummaryState | undefined {
  return fromContentSummary.contentSummaryReducer(state, action);
}
