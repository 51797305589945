import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "claTruncation",
})
export class TruncationPipe implements PipeTransform {
  transform(value: string | null, length: number) {
    if (value === null) {
      return value;
    }

    if (value.length <= length) {
      return value;
    }

    return `${String(value).substring(0, length - 3)}...`;
  }
}
