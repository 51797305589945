import { createAction, props } from "@ngrx/store";

import { SearchTypeValues } from "../../constants";

import {
  IContentDetailsGetRequest,
  IContentDetailsGetResponse,
  IContentSummaryItem,
} from "../../models";

export class ContentSummaryActions {
  public static onContentDetailsError = createAction(
    "[ContentSummary/ContentDetailsError]",
    props<{ payload: any }>()
  );

  public static onContentDetailsReceived = createAction(
    "[ContentSummary/ContentDetailsReceived]",
    props<{ response: IContentDetailsGetResponse }>()
  );

  public static onContentDetailsRefreshReceived = createAction(
    "[ContentSummary/ContentDetailsRefreshReceived]",
    props<{ response: IContentDetailsGetResponse }>()
  );

  public static onContentDetailsRefreshRequested = createAction(
    "[ContentSummary/ContentDetailsRefreshRequested]"
  );

  public static onContentDetailsRequested = createAction(
    "[ContentSummary/ContentDetailsRequested]",
    props<{ request: IContentDetailsGetRequest }>()
  );

  public static onContentSummaryApiError = createAction(
    "[ContentSummary/ContentSummaryApiError]",
    props<{ requestThatErrored: RequestThatErrored }>()
  );

  public static onContentSummaryFilter = createAction(
    "[ContentSummary/ContentSummaryFilterReceived]",
    props<{
      filteredContentItems: IContentSummaryItem[] | undefined;
      filters: { [key: string]: any };
    }>()
  );

  public static onContentSummaryPreviewItem = createAction(
    "[ContentSummary/ContentSummaryPreviewItem]",
    props<{ contentItem: IContentSummaryItem }>()
  );

  public static onContentSummarySearchReceived = createAction(
    "[ContentSummary/ContentSummarySearchReceived]",
    props<{ searchResults: string[] }>()
  );

  public static onContentSummarySearchRequested = createAction(
    "[ContentSummary/ContentSummarySearchRequested]",
    props<{ searchTerm: string; searchType: SearchTypeValues }>()
  );

  public static onContentSummaryToggleAllSelectedItems = createAction(
    "[ContentSummary/ContentSummaryToggleAllSelectedItems]",
    props<{ selectAllContentItems: boolean }>()
  );

  public static onContentSummaryToggleSelectedItem = createAction(
    "[ContentSummary/ContentSummaryToggleSelectedItem]",
    props<{ contentItem: IContentSummaryItem }>()
  );

  public static onContentSummaryToggleAllSelectedItemsPaginated = createAction(
    "[ContentSummary/ContentSummaryToggleAllSelectedItemsPaginated]",
    props<{ contentItemIds: string[]; selectAllContentItems: boolean }>()
  );

  public static onContentSummaryDeselectAllSelectedItems = createAction(
    "[ContentSummary/ContentSummaryDeselectAllSelectedItems]"
  );

  public static onReorderColumns = createAction(
    "[ContentSummary/ReorderColumns]",
    props<{
      currentIndex: number;
      previousIndex: number;
    }>()
  );

  public static onResetColumns = createAction("[ContentSummary/ResetColumns]");

  public static onResetFilters = createAction("[ContentSummary/ResetFilters]");

  public static onSetColumns = createAction(
    "[ContentSummary/SetColumns]",
    props<{
      columns: any[];
      isPartyPropertyView: boolean;
    }>()
  );

  public static onSetGridFilter = createAction(
    "[ContentSummary/SetGridFilter]",
    props<{
      claimNumber: string;
      partyId?: string;
      propertyId?: string;
    }>()
  );

  public static onSetLoadingState = createAction(
    "[ContentSummary/SetLoadingState]",
    props<{ isLoading: boolean }>()
  );

  public static onToggleColumn = createAction(
    "[ContentSummary/ToggleColumn]",
    props<{ column: string }>()
  );

  public static onToggleColumnFilters = createAction(
    "[ContentSummary/ToggleColumnFilters]",
    props<{
      columnId: string;
      showAllFilters: boolean;
    }>()
  );

  public static onToggleDateFilter = createAction(
    "[ContentSummary/ToggleDateFilter]",
    props<{
      columnId: string;
      dateFilter: any;
    }>()
  );

  public static onToggleFilter = createAction(
    "[ContentSummary/ToggleFilter]",
    props<{
      columnId: string;
      filterText: string;
    }>()
  );

  public static onContentDetailsNoop = createAction(
    "[ContentSummary/ContentDetailsNoop]"
  );
}

export type RequestThatErrored = "ContentSummary";
