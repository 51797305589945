import { Injectable } from "@angular/core";
import { AnalyticsWrapperService } from "@modules/electronic-file-folder/services/core/analytics/analytics-wrapper.service";
import { AppContextService } from "@modules/electronic-file-folder/services/core/analytics/app-context.service";
import { PerformanceEventService } from "@modules/electronic-file-folder/services/shared/performance-event/performance-event.service";
import { createPageViewEvent } from "@pgr-cla/cla-analytics";
import { Observable } from "rxjs";
import { ContentSummaryContextService } from "../content-summary-context/content-summary-context.service";

@Injectable()
export class ContentSummaryEventService {
  private _eventContext$: Observable<any>[];
  constructor(
    private contentSummaryContextService: ContentSummaryContextService,
    private performanceEventService: PerformanceEventService,
    private appContextService: AppContextService,
    private analyticsWrapperService: AnalyticsWrapperService
  ) {
    this._eventContext$ = [
      this.appContextService.context$,
      this.contentSummaryContextService.context$,
    ];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public summaryPageView = () =>
    createPageViewEvent("Content Summary Page View", [
      this.appContextService.context$,
      this.contentSummaryContextService.context$,
    ]);

  public clickAction: (actionName: string) => void = (actionName: string) =>
    this.analyticsWrapperService.createButtonClickEvent(
      `Content Summary ${actionName}`,
      this._eventContext$
    );

  public contentSearch: (searchTime: number) => void = (searchTime: number) =>
    this.performanceEventService.trackPerformanceEvent(
      "Content Summary Content Search",
      searchTime,
      this._eventContext$
    );

  public initialize: (loadTime: number) => void = (loadTime: number) =>
    this.performanceEventService.trackPerformanceEvent(
      "Content Summary Initialize",
      loadTime,
      this._eventContext$
    );
}
