import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ClaimsSummaryConfigInfo } from "@modules/shared";
import { urlConstants } from "@modules/shared/constants/url-constants";
import { ConfigurationService } from "@pgr-cla/cla-configuration";
import { find } from "lodash";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  constructor(private configurationService: ConfigurationService) {}

  public intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (req.url.includes(urlConstants.config)) {
      return next.handle(req);
    }

    return this.configurationService.configuration$.pipe(
      first(
        (config: ClaimsSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) => {
        const urlPlaceholder = req.url;
        const configKey = Object.keys(config).find((key) => {
          return urlPlaceholder.toLowerCase().includes(key.toLowerCase());
        }) as string;

        if (!configKey) {
          return next.handle(req);
        }

        const rootPath = find(config, (value, key) => {
          return urlPlaceholder.toLowerCase().includes(key.toLowerCase());
        }) as string;
        const newUrl = urlPlaceholder.replace(configKey, rootPath);
        const newRequest = req.clone({ url: newUrl });
        return next.handle(newRequest);
      })
    );
  }
}
