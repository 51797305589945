import { ChangeDetectorRef, Component, NgZone, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";

import { EFFConstants } from "@modules/electronic-file-folder/constants";
import { IContentSummaryItem } from "@modules/electronic-file-folder/models";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { DocSetService } from "@modules/electronic-file-folder/services/doc-set/doc-set/doc-set.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const ICON_ERROR = "filter_none";
const THUMBNAIL_ERROR = {
  loading: false,
  image: undefined,
  icon: ICON_ERROR,
};
const THUMBNAIL_SHARE_PACKAGE = {
  loading: false,
  image: undefined,
  icon: "topic",
};

@UntilDestroy()
@Component({
  selector: "cla-preview-thumbnail",
  templateUrl: "preview-thumbnail.component.html",
  styleUrls: ["./preview-thumbnail.component.scss"],
})
export class PreviewThumbnailComponent implements OnInit {
  public thumbnailIcon: string | undefined;
  public thumbnailImage: SafeUrl | undefined;
  public thumbnailLoading = false;

  constructor(
    private ngZone: NgZone,
    private domSanitationService: DomSanitizer,
    private contentSummaryStoreService: ContentSummaryStoreService,
    private docSetService: DocSetService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.contentSummaryStoreService
      .getPreviewedItem()
      .pipe(
        filter((item) => !!item),
        distinctUntilChanged(
          (prev: IContentSummaryItem, current: IContentSummaryItem) => {
            return prev.id === current.id;
          }
        ),
        switchMap((contentItem) => {
          this.thumbnailLoading = true;
          this.thumbnailIcon = undefined;
          this.thumbnailImage = undefined;

          if (contentItem.contentType === EFFConstants.packageDocumentType) {
            return of(THUMBNAIL_SHARE_PACKAGE);
          }

          if (
            this.docSetService.isEstimateId(contentItem.id) ||
            !this.docSetService.isValidToOpen([contentItem])
          ) {
            return of(THUMBNAIL_ERROR);
          }

          // Use latest version
          const versionToThumbnail =
            contentItem.versions.slice(-1)[0].versionNbr;

          return this.docSetService
            .getThumbnail(contentItem.id, versionToThumbnail)
            .pipe(
              switchMap((thumbnailResponse) => {
                if (
                  thumbnailResponse &&
                  thumbnailResponse.items[0].thumbnailUrl
                ) {
                  if (thumbnailResponse.items[0].thumbnailUrl) {
                    return of({
                      loading: false,
                      image: thumbnailResponse.items[0].thumbnailUrl,
                      icon: undefined,
                    });
                  } else {
                    return of(THUMBNAIL_ERROR);
                  }
                }
                return of({
                  loading: false,
                  image: undefined,
                  icon: this.getIconFromMimeType(
                    thumbnailResponse && thumbnailResponse.items[0].mimeType
                  ),
                });
              }),
              catchError(() => of(THUMBNAIL_ERROR))
            );
        }),
        catchError(() => of(THUMBNAIL_ERROR)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        // FileReader reads on an external callback, re-enter
        // Angular's zone to ensure that the UI will refresh
        this.ngZone.run(() => {
          this.thumbnailLoading = result.loading;
          this.thumbnailImage = result.image;
          this.thumbnailIcon = result.icon;
          this.cdr.detectChanges();
        });
      });
  }

  private decodeJpegBlobToBase64(blob: Blob): Observable<SafeUrl> {
    return new Observable((observer) => {
      const reader = new FileReader();
      // eslint-disable-next-line functional/immutable-data
      reader.onload = () => {
        const base64 = (reader.result as string).split(",")[1];
        const image = this.domSanitationService.bypassSecurityTrustUrl(
          `data:image/jpeg;base64, ${base64}`
        );

        observer.next(image);
      };
      // eslint-disable-next-line functional/immutable-data
      reader.onerror = () => {
        observer.error("error");
      };
      reader.readAsDataURL(blob);
    });
  }

  private getIconFromMimeType(mimeType: string | null) {
    let icon = "filter_none";
    const upperCase = (mimeType || "").toUpperCase();

    if (upperCase.startsWith("AUDIO")) {
      icon = "audiotrack";
    } else if (upperCase.startsWith("VIDEO")) {
      icon = "videocam";
    }

    return icon;
  }
}
