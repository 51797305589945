import { Injectable } from "@angular/core";
import {
  RepairShopSummariesResponse,
  RepSummariesResponse,
} from "@modules/core-ui-extensions/models";
import { CoreUiAdapterApiService } from "@modules/core/services/core-ui-adapter-api";
import { ClaimPartyContactCardInfo } from "@pgr-cla/core-ui-components";
import * as _ from "lodash";
import { Observable } from "rxjs";

@Injectable()
export class CoreUiAdapterService {
  constructor(
    private readonly coreUiAdapterApiService: CoreUiAdapterApiService
  ) {}

  public getRepSummaries(codes: string[]): Observable<RepSummariesResponse> {
    return this.coreUiAdapterApiService.get(
      `/lossviewerapi/v1/rep-summaries?codes=${_.uniq(codes)
        .filter((x) => x)
        .join(",")}`
    );
  }

  public getPartySummary(
    partyId: string
  ): Observable<ClaimPartyContactCardInfo> {
    return this.coreUiAdapterApiService.get(
      `/lossviewerapi/v1/party-summaries/${partyId}`
    );
  }

  public getRepairShopSummaries(
    codes: string[]
  ): Observable<RepairShopSummariesResponse> {
    return this.coreUiAdapterApiService.get<RepairShopSummariesResponse>(
      `/lossviewerapi/v1/shop-summaries?codes=${encodeURIComponent(
        _.uniq(codes).join(",")
      )}`
    );
  }
}
