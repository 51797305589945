import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { SharedModule } from "@modules/shared/shared.module";

import { ClaimNoteComponent } from "./components/claim-note/claim-note.component";
import { CvqDiaryComponent } from "./components/cvq-diary/cvq-diary.component";
import { CvqNoteComponent } from "./components/cvq-note/cvq-note.component";
import { CVQListComponent } from "./components/cvq-tab/components/list-component/list-component.component";
import { CvqTabComponent } from "./components/cvq-tab/cvq-tab.component";

import { CLACoverageComponent } from "./coverage.component";
import { CoverageRoutingModule } from "./coverage.routing.module";
import { CoverageService } from "./services/coverage.service";
import { CoverageEffects } from "./store/coverage.effects";
import { coverageReducer } from "./store/coverage.reducer";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoverageRoutingModule,
    StoreModule.forFeature("coverageState", coverageReducer),
    EffectsModule.forFeature([CoverageEffects]),
    SharedModule,
  ],
  exports: [
    CvqTabComponent,
    CVQListComponent,

    CLACoverageComponent,
    CvqNoteComponent,

    ClaimNoteComponent,
    CvqDiaryComponent,
  ],
  declarations: [
    CvqTabComponent,
    CVQListComponent,

    CLACoverageComponent,
    CvqNoteComponent,

    ClaimNoteComponent,
    CvqDiaryComponent,
  ],
  providers: [CoverageService],
})
export class CoverageModule {}
