import { createAction, props } from "@ngrx/store";

import { SubrogationSummaryCodes } from "@modules/subrogation-summary/models/subrogation-summary-codes.interface";

export const loadSubrogationSummaryCodes = createAction(
  "[subrogation-summary-codes] load Subrogation Summary Codes",
  props<{ claimNumber: string }>()
);

export const loadSubrogationSummaryCodesSucceeded = createAction(
  "[subrogation-summary-codes] successfully loaded Subrogation Summary Codes",
  props<{ subrogationSummaryCodes: SubrogationSummaryCodes }>()
);

export const loadSubrogationSummaryCodesFailed = createAction(
  "[subrogation-summary-codes] failed to load Subrogation Summary Codes",
  props<{ errorMsg: string }>()
);
