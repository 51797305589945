import { Injectable } from "@angular/core";
import * as detectBrowser from "detect-browser";

// this service exists as a wrapper for tests because
// Jasmine cannot spy on module exports
// https://github.com/angular/angular-cli/issues/17207
@Injectable({
  providedIn: "root",
})
export class DetectBrowserService {
  detect = detectBrowser.detect;
}
