<mat-card appearance="outlined" class="feature-history-table">
  <mat-card-content>
    <table
      mat-table
      [dataSource]="tableDataService.dataSource"
      matSort
      matSortActive="Date & Time"
      matSortDirection="asc"
      matSortStart="desc"
      class="cui-table"
      data-pgr-id="tblFeatureHistory"
    >
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Date &amp; Time
        </th>
        <td
          mat-cell
          attr.data-pgr-id="updatedDateTimeColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.displayDate }} ET
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedByRep">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <cla-table-header-label
            headerName="Updated By Rep"
            [filterActive]="filters['repCode'].appliedFilters.length"
          ></cla-table-header-label>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="updatedByRepColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          <cla-contact-card
            [repInfo]="
              (coreUiStateService.repSummaries$ | async)[element.repCode]
            "
            [tpxId]="element.repCode"
            [isLoadingRepInfo]="
              coreUiStateService.isLoadingRepSummaries$ | async
            "
          ></cla-contact-card>
        </td>
      </ng-container>
      <ng-container matColumnDef="featureNum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <cla-table-header-label
            headerName="Feature #"
            [filterActive]="filters['featureNumber'].appliedFilters.length"
          ></cla-table-header-label>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="featureNumberColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.featureNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lineCoverage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <cla-table-header-label
            headerName="Line Coverage"
            [filterActive]="
              filters['coverageShortDescription'].appliedFilters.length
            "
          ></cla-table-header-label>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="lineCoverageColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.coverageShortDescription | claValueOrNA }}
        </td>
      </ng-container>
      <ng-container matColumnDef="partyProperty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <cla-table-header-label
            headerName="Party/Property"
            [filterActive]="filters['partyProperty'].appliedFilters.length"
          ></cla-table-header-label>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="partyPropertyColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.partyProperty | claValueOrNA }}
        </td>
      </ng-container>
      <ng-container matColumnDef="change">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <cla-table-header-label
            headerName="Change"
            [filterActive]="filters['changeReason'].appliedFilters.length"
          ></cla-table-header-label>
        </th>
        <td
          mat-cell
          attr.data-pgr-id="changeColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.changeReason | claValueOrNA }}
        </td>
      </ng-container>
      <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
        <td
          mat-cell
          attr.data-pgr-id="fromColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.valueBefore | claValueOrNA }}
        </td>
      </ng-container>
      <ng-container matColumnDef="arrow">
        <th
          aria-hidden="true"
          mat-header-cell
          class="cui-cell-checkbox"
          *matHeaderCellDef
        ></th>
        <td mat-cell class="cui-cell-checkbox" *matCellDef="let element">
          <mat-icon>arrow_forward</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>To</th>
        <td
          mat-cell
          attr.data-pgr-id="toColumn{{ i }}"
          *matCellDef="let element; let i = index"
        >
          {{ element.valueAfter | claValueOrNA }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="revisionHistoryColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: revisionHistoryColumns">
        <div
          *ngIf="(isLoading$ | async) === true"
          class="ghost-line w-100"
          data-pgr-id="dln-ghost-loader"
        ></div>
      </tr>
    </table>
    <cui-empty-state
      *ngIf="
        (isLoading$ | async) === false &&
        (hasGetHistoryError$ | async) === false &&
        tableDataService.dataSource.data.length === 0
      "
      icon="search_off"
      primaryText="No History"
    >
    </cui-empty-state>
    <cui-notification-banner
      *ngIf="(isLoading$ | async) === false && (hasGetHistoryError$ | async)"
      [actionable]="true"
      (action)="getFeatureRevisionHistory()"
      actionButtonText="Try Again"
      type="error"
    >
      An unexpected error occurred. Please try again.
    </cui-notification-banner>
    <mat-paginator
      #pager
      [pageSizeOptions]="[25, 50, 100]"
      showFirstLastButtons
      [hidden]="2 > pager.getNumberOfPages()"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
