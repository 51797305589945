import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../shared/shared.module";
import {
  AdversePartiesSummaryComponent,
  AdversePartyDetailsComponent,
  AdversePartyHeaderComponent,
  OutOfPocketsComponent,
  RecoveryHistoryModalComponent,
  SubroFeaturesComponent,
} from "./components";
import { subrogationSummaryEffects } from "./state/effects";
import {
  CreditAdvicePipe,
  PayersNamePipe,
  StatusIndicatorClassPipe,
  StatusIndicatorTextPipe,
  TwoOrMoreDigitsPipe,
  EmptyDashPipe,
  PartyPropertyDisplayPipe,
  DateFormatPipe,
  OnHoldDashPipe,
  PartyNamePipe,
} from "./pipes";
import { featureName, reducers } from "./state/reducers";
import {
  SubrogationSummaryApiService,
  SubrogationSummaryService,
} from "./services";
import { SubrogationSummaryComponent } from "./subrogation-summary.component";
import { SubrogationSummaryRoutingModule } from "./subrogation-summary.routing.module";

@NgModule({
  declarations: [
    SubrogationSummaryComponent,
    AdversePartiesSummaryComponent,
    AdversePartyHeaderComponent,
    AdversePartyDetailsComponent,
    SubroFeaturesComponent,
    OutOfPocketsComponent,
    CreditAdvicePipe,
    StatusIndicatorClassPipe,
    StatusIndicatorTextPipe,
    TwoOrMoreDigitsPipe,
    EmptyDashPipe,
    PartyPropertyDisplayPipe,
    PayersNamePipe,
    RecoveryHistoryModalComponent,
    DateFormatPipe,
    OnHoldDashPipe,
    PartyNamePipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    SubrogationSummaryRoutingModule,
    StoreModule.forFeature(featureName, reducers),
    EffectsModule.forFeature(subrogationSummaryEffects),
  ],
  providers: [
    CurrencyPipe,
    PartyNamePipe,
    SubrogationSummaryApiService,
    SubrogationSummaryService,
    PayersNamePipe,
  ],
})
export class SubrogationSummaryModule {}
