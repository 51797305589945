import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { NotesStateService } from "@notes/state/notes.state.service";
import { Observable, of } from "rxjs";

@Injectable()
export class NoteListResolverService implements Resolve<boolean> {
  constructor(private notesStateService: NotesStateService) {}

  resolve(): Observable<boolean> {
    this.notesStateService.getNotes();
    this.notesStateService.getJournals();
    return of(true);
  }
}
