<div class="px-m py-xs">
  <mat-card>
    <h2
      *ngIf="(attorneyService.isSuccess$ | async) === false"
      mat-dialog-title
      data-pgr-id="lblDeleteAttorney"
    >
      Delete Attorney?
    </h2>
    <div mat-dialog-content>
      <p *ngIf="(attorneyService.isSuccess$ | async) === false">
        This function removes the attorney from this party. Do you still want to
        remove this attorney?
      </p>
      <div
        mat-dialog-content
        dialog-content
        class="text-xl text-center"
        *ngIf="attorneyService.isSuccess$ | async"
      >
        <mat-icon class="success-icon mt-xxs -ml-40 text-green"
          >check_circle</mat-icon
        >
        <p>
          <strong data-pgr-id="msg-delete-success"
            >The attorney was successfully deleted.
          </strong>
        </p>
      </div>
      <div *ngIf="attorneyService.isError$ | async" class="mt-xxs">
        <cla-inline-alert [alertType]="'error'" data-pgr-id="errorAlert">
          <span
            >An error occurred while removing the attorney. If the problem
            persists, please call the Claims Help Desk at Network 555-Help or
            1-888-746-4500.</span
          >
        </cla-inline-alert>
      </div>
    </div>
    <div class="mt-s flex justify-end">
      <button
        mat-flat-button
        color="primary"
        class="mr-xxs"
        data-pgr-id="btnDialogConfirm"
        (click)="onConfirm()"
        [disabled]="attorneyService.isInFlight$ | async"
        *ngIf="(attorneyService.isSuccess$ | async) === false"
      >
        Remove
      </button>
    </div>
    <div class="mt-s flex justify-center">
      <h3 *ngIf="attorneyService.isSuccess$ | async">
        You may now close this window.
      </h3>
    </div>
  </mat-card>
</div>
