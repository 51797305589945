<div class="overflow-y-auto h-full w-full">
  <cui-notification-banner
    *ngIf="paymentsStateService.paymentsError$ | async; let errorData"
    [actionable]="true"
    (action)="paymentsStateService.retrieveCurrentClaimPayments()"
    actionButtonText="Try Again"
    type="error"
    class="flex grow"
  >
    {{
      errorData?.statusText &&
      errorData?.statusText !== "Unknown Error" &&
      errorData.statusText !== "OK"
        ? errorData.statusText
        : "An unexpected error occurred. Please try again."
    }}
  </cui-notification-banner>

  <main role="main" class="h-full" data-pgr-id="tlepayments">
    <mat-sidenav-container class="h-full">
      <mat-sidenav
        position="end"
        mode="side"
        [autoFocus]="true"
        #sideSheet
        role="complementary"
      >
        <div
          *ngIf="sideSheet.opened"
          cdkTrapFocus
          cdkTrapFocusAutoCapture
          class="side-sheet"
        ></div>
        <cla-payments-filter #paymentsFilterDrawer></cla-payments-filter>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="w-full">
          <div class="mt-xxs mx-xs relative">
            <div class="flex flex-wrap justify-between">
              <div class="flex justify-between">
                <h1
                  class="cui-h1 mb-xxs"
                  id="main-content-header"
                  tabindex="-1"
                >
                  Claim Payments
                </h1>
              </div>
              <div class="flex justify-between">
                <div>
                  <button
                    mat-stroked-button
                    (click)="onOpenFilter()"
                    color="primary"
                    data-pgr-id="btnOpenFilters"
                    class="absolute right-aligned tab-aligned z-20"
                  >
                    <mat-icon matPrefix>filter_list</mat-icon>
                    Filter
                  </button>
                </div>
              </div>
            </div>
            <div
              class="
                mt-l
                mb-s
                overflow-auto
                relative
                p-0
                grid-row-2
                flex flex-col
              "
            >
              <div class="p-0">
                <cla-payments-table #paymentsTable></cla-payments-table>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </main>
</div>
