<header>
  <cla-claim-header [showData]="false" *ngIf="!hideHeader"></cla-claim-header>
</header>
<cla-server-error-banner
  [serverError]="claimDetailsStateService.claimDetailsHasError$ | async"
  hasBottomBorder="true"
>
</cla-server-error-banner>
<div class="center-container">
  <div class="error-screen-container">
    <h1 class="mb-s error-screen-title">
      {{ title }}
    </h1>
    <div class="mb-m">{{ message }}</div>
    <div
      class="flexbox flex-row items-center"
      *ngIf="enterClaimOnErrorService.canEnterClaimOnError$ | async"
    >
      <mat-card appearance="outlined" class="m-auto" *ngIf="!hideForm">
        <form
          [formGroup]="claimNotFoundForm"
          autocomplete="off"
          (ngSubmit)="searchClaim()"
          cuiFocusInvalid
        >
          <fieldset [disabled]="isLoading">
            <mat-form-field appearance="outline" class="mr-xxs">
              <mat-label>Claim Number</mat-label>
              <input
                (paste)="onPaste($event)"
                [formControl]="claimNumber"
                matInput
                required
                data-pgr-id="txtClaimNumber"
                #claimNumberField
              />
              <mat-error *ngIf="claimNumber.hasError('required')">
                Please enter Claim Number.
              </mat-error>
              <mat-error *ngIf="claimNumber.hasError('claimNumber')">
                Claim Number is formatted incorrectly.
              </mat-error>
            </mat-form-field>
            <button
              type="submit"
              mat-flat-button
              data-pgr-id="btnSubmit"
              class="dialog-confirm"
              color="primary"
              [disabled]="claimNotFoundForm.invalid"
            >
              Go
            </button>
          </fieldset>
        </form>
      </mat-card>
    </div>
  </div>
</div>
<cui-spinner *ngIf="isLoading"></cui-spinner>
