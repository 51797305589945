import { Injectable } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { WindowManagerService } from "../window-manager/window-manager.service";

@Injectable()
export class AppMessageBus {
  public $window: Window;
  constructor(public windowManagerService: WindowManagerService) {
    this.$window = this.windowManagerService.getWindow();
  }
  public getStream(eventType: string): Observable<MessageEvent> {
    return fromEvent<MessageEvent>(this.$window, "message").pipe(
      filter((x) => x.data.type === eventType)
    );
  }

  public postMessage(data: { [key: string]: any }, propagate = true): void {
    // eslint-disable-next-line functional/no-try-statements
    try {
      if (propagate) {
        if (
          this.$window.document.referrer !== "" &&
          this.$window.opener !== null
        ) {
          this.$window.opener.postMessage(data, this.$window.document.referrer);
        }
      }

      this.$window.postMessage(data, this.$window.location.href);
    } catch (exception) {
      if (exception.message.includes("Cannot read property 'postMessage'")) {
        return;
      }
      // eslint-disable-next-line functional/no-throw-statements
      throw exception;
    }
  }
}
