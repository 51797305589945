import { Injectable } from "@angular/core";

import {
  createPerformanceEvent,
  EventDispatchService,
} from "@pgr-cla/cla-analytics";
import { WindowService } from "@pgr-cla/cla-window";

import { AppContextService } from "./app-context.service";

@Injectable()
export class DownloadPerformanceService {
  constructor(
    private windowService: WindowService,
    private eventDispatchService: EventDispatchService,
    private appContextService: AppContextService
  ) {}

  public trackDownloadPerformance(): void {
    this.windowService.window.addEventListener(
      "load",
      this.loadPerformanceEventListener.bind(this),
      { once: true }
    );
  }

  private dispatchAnalyticsForPerformanceTimings(
    type: string,
    duration: number,
    label: string
  ): void {
    const event = createPerformanceEvent(
      label,
      [this.appContextService.context$],
      duration
    );

    this.eventDispatchService.trackPerformanceEvent(event);
  }

  private loadPerformanceEventListener(): void {
    let perfEntries: any;
    let firstPerfEntry: any;
    let domCompleteTime = 0;
    let domInteractiveTime = 0;

    if (performance) {
      const t = performance.timing;
      domCompleteTime = t.domComplete - t.domLoading;
      domInteractiveTime = t.domInteractive - t.domLoading;
    }

    if (performance && performance.getEntriesByType) {
      perfEntries = performance.getEntriesByType("navigation");

      if (Array.isArray(perfEntries)) {
        firstPerfEntry = perfEntries[0];
      }
    }

    this.dispatchAnalyticsForPerformanceTimings(
      firstPerfEntry.type,
      domInteractiveTime,
      "Dom Interactive"
    );
    this.dispatchAnalyticsForPerformanceTimings(
      firstPerfEntry.type,
      domCompleteTime,
      "Dom Complete"
    );
  }
}
