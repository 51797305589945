import { Injectable } from "@angular/core";
import { Feature } from "@modules/feature-revision-history/models/feature-revision";
import { ClaimInfo } from "@modules/feature-revision-history/models/feature-revision/claim-info";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ClaimNumberPipe, SideSheetService } from "@pgr-cla/core-ui-components";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { FeatureService } from "../../services/feature/feature.service";
import { listActions, sharedActions } from "../actions";

@Injectable()
export class ListEffects {
  getClaimFeaturesWithPreReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listActions.getClaimFeaturesWithPreReq),
      switchMap((action) => {
        const claimNum =
          this.claimNumberPipe.transform(action.claimNumber, "full") || "";
        const claimInfo = {} as ClaimInfo;
        return this.featureService.getFeatures(claimNum).pipe(
          switchMap((response: Feature[]) => {
            return of(
              listActions.getClaimFeaturesWithPreReqSuccess({
                claimNumber: claimNum,
                claimInfo: claimInfo,
                features: response,
              })
            );
          }),
          catchError((error: any) => of(sharedActions.errorOccurred({ error })))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private featureService: FeatureService,
    private sideSheetService: SideSheetService,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  get sideSheet() {
    return this.sideSheetService.get("sideSheet");
  }
}
