<cui-side-sheet aria-relevant="text" *ngIf="viewModel$ | async as vm">
  <cui-side-sheet-header
    aria-label="Filters: Choose filters via below options"
    closeButtonLabel="close filters pane"
    (close)="handleClose()"
  >
    Filters
  </cui-side-sheet-header>
  <cui-side-sheet-content data-pgr-id="filters">
    <div class="flex items-center justify-between pb-5">
      <button
        mat-stroked-button
        color="primary"
        (click)="onResetFilters()"
        data-pgr-id="btnResetFilters"
        class="ml-auto"
      >
        Reset
      </button>
    </div>
    <mat-accordion multi="true">
      <mat-expansion-panel
        *ngFor="let column of vm.columns; trackBy: trackById"
        [attr.data-pgr-id]="column.title + ' Filter Panel'"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p
              [matBadge]="getFilterCount(vm.filters[column.id])"
              [matBadgeHidden]="filterCountIsHidden(vm.filters[column.id])"
              [matBadgeOverlap]="false"
              matBadgeSize="small"
              [attr.aria-label]="
                column.title +
                ' ' +
                getFilterCount(vm.filters[column.id]) +
                ' active'
              "
            >
              {{ column.title }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="vm.filters[column.id].filterOptions">
          <mat-checkbox
            [disabled]="!vm.filters[column.id].appliedFilters.length"
            [checked]="!vm.filters[column.id].appliedFilters.length"
            (change)="
              onToggleColumnFilters(
                column.id,
                true,
                !!vm.filters[column.id].appliedFilters.length
              )
            "
            [attr.data-pgr-id]="column.title + ' Checkbox-All'"
          >
            All <strong>({{ vm.filters[column.id].scopedTotal }})</strong>
          </mat-checkbox>
          <mat-checkbox
            *ngFor="
              let filterOption of vm.filters[column.id].filterOptions;
              let i = index;
              trackBy: trackByText
            "
            [checked]="
              vm.filters[column.id].appliedFilters.indexOf(
                filterOption.filterText
              ) !== -1
            "
            (change)="onToggleFilter(column.id, filterOption)"
            [attr.data-pgr-id]="column.title + ' Checkbox-' + i"
            class="block"
          >
            <span [class]="filterOption.count ? '' : 'color-gray'"
              >{{ filterOption.filterDisplayText }}
              <strong>({{ filterOption.count }})</strong></span
            >
          </mat-checkbox>
        </ng-container>
        <ng-container *ngIf="!vm.filters[column.id].filterOptions">
          <mat-form-field
            class="ml-bl-1 mb-xs"
            appearance="outline"
            color="primary"
          >
            <mat-label>Start Date</mat-label>
            <input
              matInput
              placeholder="MM/DD/YYYY"
              [matDatepicker]="minDatePicker"
              [formControl]="minDate"
              [max]="vm.filters[column.id].maxDate"
              (blur)="updateMinDate()"
              (keydown.enter)="updateMinDate()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="minDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              (closed)="updateMinDate()"
              #minDatePicker
              color="primary"
            ></mat-datepicker>
            <mat-error [hidden]="minDate.valid">
              {{ getErrorMessage(minDate.errors) }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ml-bl-1" appearance="outline" color="primary">
            <mat-label>End Date</mat-label>
            <input
              matInput
              placeholder="MM/DD/YYYY"
              [matDatepicker]="maxDatePicker"
              [formControl]="maxDate"
              [min]="vm.filters[column.id].minDate"
              (blur)="updateMaxDate()"
              (keydown.enter)="updateMaxDate()"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="maxDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              (closed)="updateMaxDate()"
              #maxDatePicker
              color="primary"
            ></mat-datepicker>
            <mat-error [hidden]="maxDate.valid">
              {{ getErrorMessage(maxDate.errors) }}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </cui-side-sheet-content>
</cui-side-sheet>
