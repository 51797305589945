<mat-card>
  <div class="flex justify-between">
    <div class="ghost-line w-100"></div>

    <cui-data-list [border]="false">
      <cui-data-list-row>
        <div class="flex justify-between">
          <span class="ghost-line w-10"></span>
          <span class="ghost-line w-10 small-left-margin"></span>
        </div>
      </cui-data-list-row>
      <cui-data-list-row>
        <div class="flex justify-between">
          <span class="ghost-line w-10 small-top-margin"></span>
          <span
            class="ghost-line w-10 small-top-margin small-left-margin"
          ></span>
        </div>
      </cui-data-list-row>
      <cui-data-list-row>
        <div class="flex justify-between">
          <span class="ghost-line w-10 small-top-margin"></span>
          <span
            class="ghost-line w-10 small-top-margin small-left-margin"
          ></span>
        </div>
      </cui-data-list-row>
    </cui-data-list>
  </div>
  <div class="flex">
    <button
      mat-stroked-button
      class="primary-actions-menu-item ghost-line w-10"
    ></button>
    <button
      mat-stroked-button
      class="primary-actions-menu-item ghost-line w-10 big-left-margin"
    ></button>
    <button
      mat-stroked-button
      class="primary-actions-menu-item ghost-line w-10 big-left-margin"
    ></button>
  </div>
  <div class="card-body">
    <div class="ghost-line w-50"></div>
    <div class="ghost-line w-50 small-top-margin"></div>
    <div class="ghost-line w-50 small-top-margin"></div>
    <div class="ghost-line w-100 small-top-margin"></div>
    <div class="ghost-line w-100"></div>
    <div class="ghost-line w-100"></div>
    <div class="ghost-line w-100"></div>
  </div>
  <mat-tab-group class="mt-bl-2">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="ghost-line w-10" data-pgr-id="dln-ghost-loader"></div>
      </ng-template>
      <div class="flex">
        <div class="card min-w-sm max-w-sm mr-xxs mb-xxs">
          <div class="card-header">
            <span class="ghost-line w-10"></span>
          </div>
          <div class="card-body">
            <span class="ghost-line w-10"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
          </div>
        </div>
        <div class="card mr-xxs min-w-sm mb-xxs">
          <div class="card-header">
            <span class="ghost-line w-10"></span>
          </div>
          <div class="card-body">
            <span class="ghost-line w-10"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
            <span class="ghost-line w-10 small-top-margin"></span>
          </div>
        </div>
        <div class="card mr-xxs min-w-sm">
          <div class="card-header">
            <span class="ghost-line w-10"></span>
          </div>
          <div class="card-body">
            <span class="ghost-line w-10"></span>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="ghost-line w-10" data-pgr-id="dln-ghost-loader"></div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="ghost-line w-10" data-pgr-id="dln-ghost-loader"></div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="ghost-line w-10" data-pgr-id="dln-ghost-loader"></div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
