import { CurrencyPipe } from "@angular/common";
import { Feature } from "@shared/models/feature";
import { pickBy } from "lodash";
import { DateTime } from "luxon";
import { FeatureRevisionConstants } from "../constants/feature-revision/feature-revision-constants";
import { FeatureHistory } from "../models/feature-revision";
import { RevisionHistoryState } from "../state/reducers/history.reducers";

export enum SPINNERID {
  ERROR = "error",
}

export class HelperFunctions {
  static setHistoryDisplayInfo(history: FeatureHistory[], features: Feature[]) {
    const updatedHistory = history.map((x) => {
      const feature = features.find(
        (y) => y.sequenceNumber === x.featureNumber
      );
      if (feature != null) {
        return {
          ...x,
          repDisplayName: x.repLastName
            ? `${x.repFirstName[0]}. ${x.repLastName} (${x.repCode})`
            : "",
          coverageShortDescription: feature.coverageShortDescription,
          displayDate: this.getDateTimeString(x.transactionDate),
        };
      } else {
        return {
          ...x,
          repDisplayName: x.repLastName
            ? `${x.repFirstName[0]}. ${x.repLastName} (${x.repCode})`
            : "",
          displayDate: this.getDateTimeString(x.transactionDate),
        };
      }
    });

    return updatedHistory;
  }

  static getDateTimeString(transactionDate: string) {
    return DateTime.fromISO(transactionDate)
      .setZone("America/New_York")
      .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
  }

  static getChangeValue(
    changeReason: string,
    changeValue: string,
    currencyPipe: CurrencyPipe
  ): string {
    let result = changeValue;

    if (
      !(
        changeReason === FeatureRevisionConstants.limitCode ||
        changeReason === FeatureRevisionConstants.causeOfLoss
      )
    ) {
      result =
        Number(changeValue) || changeValue === ".00"
          ? currencyPipe.transform(changeValue) ?? changeValue
          : changeValue;
    }

    if (changeReason === FeatureRevisionConstants.causeOfLoss) {
      result = changeValue.trim() === "" ? "NONE" : changeValue;
    }

    return result;
  }
}

const filterableProperties = [
  "repCode",
  "featureNumber",
  "coverageShortDescription",
  "partyProperty",
  "changeReason",
];

export function getFilteredHistoryItems({
  revisionHistory,
  filters,
}: RevisionHistoryState): FeatureHistory[] | undefined {
  return (deepCopy(revisionHistory) || []).filter((item: FeatureHistory) => {
    let allFiltersMatch = true;

    filterableProperties.forEach((columnKey) => {
      if (filters[columnKey]) {
        const { appliedFilters } = filters[columnKey];
        const columnContents = [item[columnKey]];
        if (
          appliedFilters &&
          appliedFilters.length &&
          columnContents.every(
            (cellItem) => appliedFilters.indexOf(cellItem) === -1
          )
        ) {
          allFiltersMatch = false;
        }
      }
    });

    return allFiltersMatch;
  });
}

export function getFilters(
  { columns, filters }: RevisionHistoryState,
  payload?: FeatureHistory[] | null
): any {
  const newFilters = deepCopy(filters);
  if (payload) {
    columns.forEach((column) => {
      if (!column.isFilterable) {
        return;
      }
      const scopedFilters = pickBy(
        filters,
        (value: any, key: string) => key !== column.id
      );

      const scopedContentItems =
        getFilteredHistoryItems({
          revisionHistory: payload,
          filters: scopedFilters,
        } as RevisionHistoryState) || [];
      if (newFilters[column.id].filterOptions) {
        // eslint-disable-next-line functional/immutable-data
        newFilters[column.id].scopedTotal = scopedContentItems.length;
        // eslint-disable-next-line functional/immutable-data
        newFilters[column.id].filterOptions = getFilterOptions(
          scopedContentItems,
          column.id,
          filters[column.id].appliedFilters
        );
      }
    });
  }
  return newFilters;
}

export function getFilterOptions(
  featureHistoryList: FeatureHistory[],
  columnId: string,
  appliedFilters: any[]
): object[] {
  const filterOptions: any = {};
  featureHistoryList.forEach((featureHistory) => {
    const filterableContent = [featureHistory[columnId]];
    const displayText =
      columnId == "repCode" ? [featureHistory.repDisplayName] : null;
    filterableContent.forEach((filterText, idx) => {
      if (filterOptions[filterText]) {
        // eslint-disable-next-line functional/immutable-data
        filterOptions[filterText].count++;
      } else {
        // eslint-disable-next-line functional/immutable-data
        filterOptions[filterText] = buildFilterOption(
          filterText,
          undefined,
          displayText && displayText[idx]
        );
      }
    });
  });
  appliedFilters.forEach((filter) => {
    if (!filterOptions[filter]) {
      // eslint-disable-next-line functional/immutable-data
      filterOptions[filter] = buildFilterOption(filter, 0);
    }
  });
  return Object.keys(filterOptions)
    .map((key: string) => filterOptions[key])
    .sort((a: any, b: any) => {
      const result = compare(
        typeof a.filterText === "string"
          ? a.filterText.toUpperCase()
          : a.filterText,
        typeof b.filterText === "string"
          ? b.filterText.toUpperCase()
          : b.filterText,
        true
      );
      return result;
    });
}

export function buildFilterOption(
  filterText: string,
  count: number = 1,
  displayText?: string | null
): any {
  const filterDisplayText = displayText || filterText || "(Blank)";
  return { filterDisplayText, filterText, count };
}

export function filterItems(state: any): any {
  const filters = deepCopy(state.filters);
  const newFilters = getFilters({ ...state, filters }, state.revisionHistory);
  const filteredHistoryList = getFilteredHistoryItems({
    ...state,
    filters,
  });

  return {
    filteredHistoryList,
    filters: newFilters,
  };
}

export function deepCopy<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export function compare(
  a: number | string | null,
  b: number | string | null,
  isAsc: boolean
): number {
  // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
  // one value exists while the other doesn't. In this case, existing value should come first.
  // This avoids inconsistent results when comparing values to undefined/null.
  // If neither value exists, return 0 (equal).
  let comparatorResult = 0;
  if (a != null && b != null) {
    // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
    if (a > b) {
      comparatorResult = 1;
    } else if (a < b) {
      comparatorResult = -1;
    }
  } else if (a != null) {
    comparatorResult = 1;
  } else if (b != null) {
    comparatorResult = -1;
  }

  return comparatorResult * (isAsc ? 1 : -1);
}
