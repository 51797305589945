import { createSelector } from "@ngrx/store";

import { selectPolicyState } from "..";

export const selectUwMemoLanding = createSelector(
  selectPolicyState,
  (state) => state.uwMemoLanding
);

export const selectMemos = createSelector(
  selectUwMemoLanding,
  (state) => state.memos
);

export const selectRequestinFlight = createSelector(
  selectUwMemoLanding,
  (state) => state.isRequestInFlight
);

export const selectRequestFailed = createSelector(
  selectUwMemoLanding,
  (state) => state.requestFailed
);
