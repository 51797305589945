import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule, Optional, SkipSelf } from "@angular/core";
import { CoreUiExtensionsServiceModule } from "@modules/core-ui-extensions/core-ui-extensions.service.module";
import { ClaimsSummaryConfigInfo } from "@modules/shared/models";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { FetchRequestor, Requestor } from "@openid/appauth";
import {
  AnalyticsLibraryModule,
  RtdsDestinationService,
} from "@pgr-cla/cla-analytics";
import {
  ClaConfigurationModule,
  ConfigurationService,
} from "@pgr-cla/cla-configuration";
import {
  ClaLoggingModule,
  LoggedMessage,
  LoggingService,
} from "@pgr-cla/cla-logging";
import { ClaSplunkModule, SplunkService } from "@pgr-cla/cla-splunk";
import { WindowManagementService } from "@pgr-cla/cla-window";
import {
  NotificationService,
  SnackBarService,
  StyleManagerService,
  ThemeServiceModule,
} from "@pgr-cla/core-ui-components";
import { CookieService } from "ngx-cookie-service";
import { Observable, of } from "rxjs";
import { catchError, first, tap, withLatestFrom } from "rxjs/operators";
import { SharedEffects } from "./redux/helper/shared.effects";
import {
  ActivityAdapterApiService,
  AdapterApiService,
  AdminApiService,
  AdminService,
  ApiService,
  AppPathService,
  AuthenticationGuardService,
  AuthenticationService,
  BrowserGuard,
  BusinessApiService,
  CanDeactivateGuardService,
  ChangeDetectionService,
  ClaimGuardService,
  ClaimManagementApiService,
  ClaimPropertyApiService,
  ClaimStatusGuardService,
  ClaimSummaryAnalyticsEventService,
  ClaimsSummaryAdapterApiService,
  CodeTableApiService,
  CombinedClaimGuard,
  CoreUiAdapterApiService,
  DefaultErrorService,
  DetectBrowserService,
  EnterClaimOnErrorService,
  FeatureManagementApiService,
  HeadersInterceptor,
  LegacyAppSessionService,
  LocalStorageRefService,
  MainContentFocusService,
  NetworkErrorService,
  NotesApiService,
  PageTitleService,
  PaymentsApiService,
  PaymentsService,
  PermissionsGuardService,
  PolicyDetailsApiService,
  PolicyDetailsService,
  RefreshTokenInterceptor,
  TNCLossViewerSplunkLoggingService,
  ThemeManagerService,
  UrlInterceptor,
  UserContextApiService,
  UserContextService,
  VerificationApiService,
  WindowResizerService,
  WorkItemsApiService,
} from "./services";
import { CookieTokenService } from "./services/authentication/cookie-token.service";
import { PingTokenService } from "./services/authentication/ping-token.service";
import { ClaimsSharedApiService } from "./services/claims-shared-api";
import { CustomerInfoAdapterApiService } from "./services/customer-info-adapter-api";
import {
  HttpErrorInterceptor,
  TNCLossViewerErrorHandler,
} from "./services/error-handling";
import { PartyApiService } from "./services/party-api";
import { PropertyApiService } from "./services/property-api";
import { SplunkBuilder } from "./services/splunk-builder/splunk-builder";
import { UwMemoApiService } from "./services/uw-memo-api";
import {
  AlertsEffects,
  AlertsStateService,
  alertsReducer,
} from "./state/alerts";
import {
  ClaimDetailsEffects,
  ClaimDetailsStateService,
  claimDetailsReducer,
} from "./state/claim-details";
import {
  CodeTableStateService,
  CodesEffects,
  codesReducer,
} from "./state/codes";
import { RouterStateService } from "./state/router/router-state.service";
import { UserEffects, UserStateService, userReducer } from "./state/user";

@NgModule({
  imports: [
    AnalyticsLibraryModule.forRoot(),
    CoreUiExtensionsServiceModule,
    ClaConfigurationModule,
    ClaLoggingModule,
    ClaSplunkModule.forRoot("TNC LossViewer Web"),
    HttpClientModule,
    ThemeServiceModule,
    EffectsModule.forFeature([
      AlertsEffects,
      SharedEffects,
      ClaimDetailsEffects,
      CodesEffects,
      UserEffects,
    ]),
    StoreModule.forFeature("claimDetails", claimDetailsReducer),
    StoreModule.forFeature("codes", codesReducer),
    StoreModule.forFeature("user", userReducer),
    StoreModule.forFeature("alerts", alertsReducer),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: TNCLossViewerErrorHandler },
    ActivityAdapterApiService,
    NotesApiService,
    AdapterApiService,
    AdminApiService,
    AdminService,
    AlertsStateService,
    ApiService,
    AppPathService,
    DetectBrowserService,
    BrowserGuard,
    AuthenticationGuardService,
    AuthenticationService,
    BusinessApiService,
    CanDeactivateGuardService,
    ChangeDetectionService,
    ClaimDetailsStateService,
    ClaimGuardService,
    ClaimManagementApiService,
    ClaimPropertyApiService,
    ClaimStatusGuardService,
    ClaimsSummaryAdapterApiService,
    CodeTableApiService,
    CodeTableStateService,
    CombinedClaimGuard,
    ConfigurationService,
    CookieService,
    CookieTokenService,
    CoreUiAdapterApiService,
    DefaultErrorService,
    EnterClaimOnErrorService,
    FeatureManagementApiService,
    LegacyAppSessionService,
    LocalStorageRefService,
    LoggingService,
    MainContentFocusService,
    NotificationService,
    PageTitleService,
    PaymentsApiService,
    PaymentsService,
    PermissionsGuardService,
    PingTokenService,
    PolicyDetailsService,
    RefreshTokenInterceptor,
    RouterStateService,
    RtdsDestinationService,
    SnackBarService,
    TNCLossViewerSplunkLoggingService,
    NetworkErrorService,
    SplunkBuilder,
    SplunkService,
    StyleManagerService,
    UserContextApiService,
    UserContextService,
    UserStateService,
    VerificationApiService,
    WindowManagementService,
    WorkItemsApiService,
    ClaimSummaryAnalyticsEventService,
    ClaimsSharedApiService,
    PartyApiService,
    PropertyApiService,
    CustomerInfoAdapterApiService,
    UwMemoApiService,
    PolicyDetailsApiService,
    WindowResizerService,
    ThemeManagerService,
    UrlInterceptor,
    HeadersInterceptor,
    { provide: Requestor, useValue: new FetchRequestor() },
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private readonly authService: AuthenticationService,
    private readonly configService: ConfigurationService,
    private readonly loggingService: LoggingService,
    private readonly splunkService: SplunkService
  ) {
    if (parentModule) {
      // eslint-disable-next-line functional/no-throw-statements
      throw new Error(
        "CoreModule has already been loaded. You should only import Core modules in the AppModule."
      );
    }

    const config$: Observable<ClaimsSummaryConfigInfo> =
      this.configService.configuration$.pipe(
        first(
          (config: ClaimsSummaryConfigInfo) =>
            config !== null && config !== undefined
        )
      );

    const token$: Observable<string> = this.authService.token$.pipe(
      first((token: string) => token !== null && token !== undefined)
    );

    this.initializeLogging(config$, token$);
  }

  private initializeLogging(
    config$: Observable<ClaimsSummaryConfigInfo>,
    token$: Observable<string>
  ) {
    this.loggingService.message$
      .pipe(
        withLatestFrom(config$, token$),
        tap(
          ([loggedEvent, config, token]: readonly [
            LoggedMessage,
            ClaimsSummaryConfigInfo,
            string
          ]) => {
            if (config.isSplunkEnabled) {
              this.splunkService.log(loggedEvent.message, token);
            }
            if (config.environment === "Development") {
              // eslint-disable-next-line no-console
              console.error(loggedEvent.message);
            }
          }
        ),
        catchError((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          return of();
        })
      )
      .subscribe();
  }
}
