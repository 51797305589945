import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorkItemsApiService } from "@modules/core";
import { Diary, DiaryCalenderCountData } from "@modules/diary/models";
import { catchError, Observable, of, throwError } from "rxjs";

@Injectable()
export class DiaryApiService {
  readonly diaryBaseRoute: string = "/v1/diaries";

  constructor(private workItemsApiService: WorkItemsApiService) {}

  // getClaimDiariesWithHistory(claimNumber: string): Observable<Diary[]> {
  //   return this.workItemsApiService.get(
  //     `${this.diaryBaseRoute}/${claimNumber}`,
  //     { includeHistory: 'true' },
  //   ) as Observable<Diary[]>;
  // }

  public getClaimDiariesWithHistory(claimNumber: string): Observable<Diary[]> {
    // Diary functionality has been disabled temporarily.
    // return this.workItemsApiService.get<Diary[]>(
    //   `/lossviewerapi/v1/claims/${claimNumber}/diaries?includeHistory=true`,
    // );
    return of([]);
  }

  getDiaryCountData(
    repCode: string | null
  ): Observable<DiaryCalenderCountData> {
    return this.workItemsApiService
      .get<DiaryCalenderCountData>(`${this.diaryBaseRoute}/${repCode}/count`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorMasking(error, "Error getting diary count data");
        })
      );
  }

  private errorMasking(error: HttpErrorResponse, message: string) {
    if (error.status === 404) {
      return throwError(
        () => ({ ...error, statusText: message } as HttpErrorResponse)
      );
    }
    return throwError(() => error);
  }
}
