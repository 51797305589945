import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  IContentSetVersion,
  IContentSummaryItem,
  IVersionSelectorInfo,
} from "@modules/electronic-file-folder/models";

import { CoreUiExtensionsStateService } from "@modules/core-ui-extensions/services";
import { TitleRepCodePipe } from "@modules/electronic-file-folder/pipes";
import { ContentSummaryStoreService } from "@modules/electronic-file-folder/services/content-summary/content-summary-store/content-summary-store.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { VersionSelectorComponent } from "../version-selector";

@UntilDestroy()
@Component({
  selector: "cla-preview-item-details",
  templateUrl: "preview-item-details.component.html",
  styleUrls: ["./preview-item-details.component.scss"],
  providers: [TitleRepCodePipe],
})
export class PreviewItemDetailsComponent implements OnInit {
  public previewedItem: IContentSummaryItem;
  private versionData: IContentSetVersion[];

  constructor(
    private contentSummaryStoreService: ContentSummaryStoreService,
    private dialog: MatDialog,
    public coreUiStateService: CoreUiExtensionsStateService
  ) {}

  public getLatestVersion(): IContentSetVersion {
    return this.previewedItem.versions[this.previewedItem.versions.length - 1];
  }

  public ngOnInit(): void {
    this.contentSummaryStoreService
      .getPreviewedItem()
      .pipe(untilDestroyed(this))
      .subscribe((previewedItem) => {
        this.previewedItem = previewedItem as IContentSummaryItem;
        this.versionData = (previewedItem as IContentSummaryItem).versions;
      });
  }

  public openVersionSelector(): void {
    const versionSelectorInfo: IVersionSelectorInfo = {
      panelClass: "version-selector-container",
      width: "70rem",
      restoreFocus: true,
      data: {
        versionData: this.versionData,
      },
    };
    this.dialog.open(VersionSelectorComponent, versionSelectorInfo);
  }
}
