import { CoreUiExtensionsState } from "../models";

export const initialState: CoreUiExtensionsState = {
  repSummaries: {},
  repLoadingStates: {},
  requestedRepCodes: [],
  isLoadingRepSummaries: false,
  isErroredRepSummaries: false,
  partyContactCards: {},
  isPartyContactCardLoading: false,
  partyContactCardError: undefined,
  reps: {
    ids: [],
    entities: {},
    notFoundIds: [],
    erroredIds: [],
    isLoadingRepSummaries: true,
  },
  repairShops: {
    ids: [],
    entities: {},
    notFoundIds: [],
    erroredIds: [],
    isLoadingRepairShopSummaries: true,
  },
};
