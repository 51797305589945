export const docMimeTypes = [
  'application/vnd.wordperfect; version="6 onwards"',
  'application/vnd.wordperfect; version="6.0"',
  "application/msword",
  'application/msword; version="1.0"',
  'application/msword; version="2.0"',
  'application/msword; version="3.0"',
  'application/msword; version="4.0"',
  'application/msword; version="5.0"',
  'application/msword; version="5.5"',
  'application/msword; version="6.0-2003"',
  'application/msword; version="6.0/95"',
  'application/msword; version="97-2003"',
  "application/vnd.ms-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
];
