export interface TileChip {
  readonly type: TileChipTypeCode;
  readonly description: string[];
  readonly parameters: Map;
}

interface Map {
  [key: string]: string;
}

export const tileChipConstants = Object.freeze({
  propertyNumber: "propertyNumber",
  propertySeqNumber: "propertySeqNumber",
  salvageId: "salvageId",
  propertyVehOrFP: "propertyVehOrFP",
});

export enum TileChipTypeCode {
  CVQ = "CVQ",
  Driveable = "Driveable",
  NonDriveable = "Non-Driveable",
  InspectionPending = "Inspection Pending",
  InspectionComplete = "Inspection Complete",
  InspectionNone = "Inspection - None",
  Litigation = "Litigation",
  Salvage = "Salvage",
  Subrogation = "Subrogation",
  SIU = "SIU",
  TotalLoss = "Total Loss",
  StatedAmount = "Stated Amount",
  AssistingRep = "Assist",
  IPC = "IPC",
}
