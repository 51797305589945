/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/typedef */
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserContextService } from "@core/services";
import { onCVQRequested } from "@modules/coverage/store/coverage.action";
import { Store } from "@ngrx/store";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { AppState } from "@store/app-state.interface";
import { DateTime } from "luxon";
import { Observable, Subject } from "rxjs";
import { CVQ, Note } from "../../models/cvq.interface";
import { cvqById } from "../../store/coverage.selectors";
import { CvqDiaryComponent } from "../cvq-diary/cvq-diary.component";

export interface CvqNoteProps {
  cvq: CVQ;
  claimNumber: string;
  categories: string[];
}

@Component({
  selector: "cla-cvq-note",
  templateUrl: "./cvq-note.component.html",
  styleUrls: ["./cvq-note.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CvqNoteComponent implements OnInit, OnDestroy {
  readonly alphaNumericSpecial =
    /^[\s\u200B\u2022a-zA-Z0-9#@/^/&/$*/(/)-_/{/}/[/]+|" ':;<>,.]+$/;
  readonly notWhitespace = /\S/;
  readonly repId = this.userContextService.getUserProfile()?.uid;
  cvq$: Observable<CVQ | undefined>;
  diaryComponent: CvqDiaryComponent;
  claimNumber =
    this.claimNumberPipe.transform(this.data.claimNumber, "full") || "";
  dialog: any;
  @ViewChild(CvqDiaryComponent) set content(content: CvqDiaryComponent) {
    if (content) {
      this.diaryComponent = content;
    }
  }

  isRequestInFlight: boolean;
  errorMessage: string | undefined;
  addNote = false;
  formattedClaimNumber: string =
    this.claimNumberPipe.transform(this.claimNumber || "", "full") || "";
  categories: string[] = this.data.categories;

  private unsubscribeSubject$: Subject<void> = new Subject<void>();

  constructor(
    public store$: Store<AppState>,
    private userContextService: UserContextService,
    private dialogRef: MatDialogRef<CvqNoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CvqNoteProps,
    private claimNumberPipe: ClaimNumberPipe
  ) {}

  ngOnInit() {
    this.cvq$ = this.store$.select(cvqById, { id: this.data.cvq.id });
  }

  toggleNote() {
    this.addNote = !this.addNote;
  }

  onClose = () => {
    this.closeDialog(true);
  };

  closeDialog = (success: boolean) => {
    this.dialogRef.close(success);
  };

  systemGenerateText(cvq: CVQ) {
    return cvq.description;
  }

  getNoteDateFormat(note: Note) {
    if (!note.date) {
      return "";
    }
    return `${DateTime.fromJSDate(new Date(note.date)).toLocaleString(
      DateTime.DATETIME_SHORT
    )} ${note.dateTimeZone || ""}`;
  }

  ngOnDestroy() {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  additionalNoteDisplay() {
    return "Additional note text";
  }

  refreshCVQs() {
    if (this.data.claimNumber !== undefined) {
      this.store$.dispatch(onCVQRequested({ claimNumber: this.claimNumber }));
    }
  }
}
