import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomRouteSerializer } from "@modules/core/state/router/custom-route-serializer";
import { RouterEffects } from "@modules/core/state/router/router.effects";
import { SharedModule } from "@modules/shared";
import { EffectsModule } from "@ngrx/effects";
import * as fromRouter from "@ngrx/router-store";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AppState } from "@store";
import { AppComponent } from "./app.component";
import { AppResolverService } from "./app.resolver.service";
import { AppRoutingModule } from "./app.routing.module";
import { CoreModule } from "./modules/core/core.module";
import { metaReducers } from "./store/store";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    StoreModule.forRoot(
      { router: fromRouter.routerReducer } as ActionReducerMap<AppState>,
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    StoreDevtoolsModule.instrument(),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer,
    }),
    EffectsModule.forRoot([RouterEffects]),
  ],
  bootstrap: [AppComponent],
  providers: [AppResolverService],
})
export class AppModule {}
