import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable()
export class ChangeDetectionService {
  public detectChanges$: Observable<void>;
  private _detectChangesSubject$: Subject<void> = new Subject();

  constructor() {
    this.detectChanges$ = this._detectChangesSubject$.asObservable();
  }

  public emitDetectChangesEvent(): void {
    this._detectChangesSubject$.next();
  }
}
