import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { Action, ActionReducer, createReducer, on } from "@ngrx/store";
import { ClaimRepairShopContactCardInfo } from "@pgr-cla/core-ui-components";
import _ from "lodash";
import { CoreUiExtensionsActions } from "../actions";
import { initialState } from "../constants/core-ui-extensions.constants";
import {
  ClaimRepContactCardInfoRedacted,
  CoreUiExtensionsState,
  RepLoadingState,
} from "../models";

export const repsEntityAdapter: EntityAdapter<ClaimRepContactCardInfoRedacted> =
  createEntityAdapter<ClaimRepContactCardInfoRedacted>({
    selectId: (model: ClaimRepContactCardInfoRedacted) => model.code,
  });

export const repairShopsEntityAdapter: EntityAdapter<ClaimRepairShopContactCardInfo> =
  createEntityAdapter<ClaimRepairShopContactCardInfo>({
    selectId: (model: ClaimRepairShopContactCardInfo) => model.code,
  });

const _coreUiExtensionsReducer: ActionReducer<CoreUiExtensionsState, Action> =
  createReducer(
    initialState,
    on(
      CoreUiExtensionsActions.onGetNewRepSummariesOnlyRequest,
      (state, { codes }) => ({
        ...state,
        requestedRepCodes: _.uniq([...state.requestedRepCodes, ...codes]),
        repLoadingStates: codes.reduce(
          (accumulator: Record<string, RepLoadingState>, current: string) => {
            // eslint-disable-next-line functional/immutable-data
            accumulator[current] = { code: current, isLoading: true };
            return accumulator;
          },
          {}
        ),
        isLoadingRepSummaries: true,
      })
    ),
    on(
      CoreUiExtensionsActions.onGetRepSummariesRequestSuccess,
      (state, { repSummaries }) => ({
        ...state,
        repSummaries: repSummaries.reduce(
          (
            accumulator: Record<string, ClaimRepContactCardInfoRedacted>,
            current: ClaimRepContactCardInfoRedacted
          ) => {
            // eslint-disable-next-line functional/immutable-data
            accumulator[current.code] = current;
            return accumulator;
          },
          { ...state.repSummaries }
        ),
        repLoadingStates: repSummaries.reduce(
          (
            accumulator: Record<string, RepLoadingState>,
            current: ClaimRepContactCardInfoRedacted
          ) => {
            // eslint-disable-next-line functional/immutable-data
            accumulator[current.code] = {
              code: current.code,
              isLoading: false,
            };
            return accumulator;
          },
          { ...state.repLoadingStates }
        ),
        isLoadingRepSummaries: false,
        isErroredRepSummaries: false,
      })
    ),
    on(CoreUiExtensionsActions.onGetRepSummariesRequestFailure, (state) => ({
      ...state,
      isLoadingRepSummaries: false,
      isErroredRepSummaries: true,
      reps: {
        ...state.reps,
        isLoadingRepSummaries: false,
        erroredIds: _.uniq([...state.reps.erroredIds]),
      },
    })),

    on(
      CoreUiExtensionsActions.onGetPartySummariesRequest,
      (state, { partyId }) => ({
        ...state,
        isPartyContactCardLoading: true,
        partyContactCards: {
          ...state.partyContactCards,
          [partyId]: {
            ...state.partyContactCards[partyId],
            isLoading: true,
            error: undefined,
          },
        },
      })
    ),

    on(
      CoreUiExtensionsActions.onGetPartySummariesRequestSuccess,
      (state, { ClaimPartyContactCardInfo }) => ({
        ...state,
        isPartyContactCardLoading: false,
        partyContactCards: {
          ...state.partyContactCards,
          [ClaimPartyContactCardInfo.id]: {
            ...ClaimPartyContactCardInfo,
            isLoading: false,
            error: undefined,
          },
        },
      })
    ),

    on(
      CoreUiExtensionsActions.onGetPartySummariesRequestFailure,
      (state, { error, partyId }) => ({
        ...state,
        isPartyContactCardLoading: false,
        partyContactCardError: error,
        partyContactCards: {
          ...state.partyContactCards,
          [partyId]: {
            ...state.partyContactCards[partyId],
            isLoading: false,
            error: error,
          },
        },
      })
    ),
    on(CoreUiExtensionsActions.onGetRepairShopSummariesRequest, (state) => ({
      ...state,
      repairShops: {
        ...state.repairShops,
        isLoadingRepairShopSummaries: true,
      },
    })),
    on(
      CoreUiExtensionsActions.onGetRepairShopSummariesRequestCanceled,
      (state) => ({
        ...state,
        repairShops: {
          ...state.repairShops,
          isLoadingRepairShopSummaries: false,
        },
      })
    ),
    on(
      CoreUiExtensionsActions.onGetRepairShopSummariesRequestSuccess,
      (state, { response }) => ({
        ...state,
        repairShops: repairShopsEntityAdapter.upsertMany(response.list, {
          ...state.repairShops,
          isLoadingRepairShopSummaries: false,
          notFoundIds: response.notFound,
          erroredIds: [],
        }),
      })
    ),
    on(
      CoreUiExtensionsActions.onGetRepairShopSummariesRequestFailure,
      (state, { codes }) => ({
        ...state,
        repairShops: {
          ...state.repairShops,
          isLoadingRepairShopSummaries: false,
          erroredIds: _.uniq([...state.repairShops.erroredIds, ...codes]),
        },
      })
    )
  );

export const coreUiExtensionsReducer = (
  state: CoreUiExtensionsState,
  action: Action
): CoreUiExtensionsState => {
  return _coreUiExtensionsReducer(state, action);
};
