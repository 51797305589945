import { Pipe, PipeTransform } from "@angular/core";
import {
  IContentSetVersion,
  IContentSummaryItem,
} from "@modules/electronic-file-folder/models";

@Pipe({
  name: "claTitleRepCode",
})
export class TitleRepCodePipe implements PipeTransform {
  public transform(value: IContentSetVersion | IContentSummaryItem): string {
    if (value.createdByUserId) {
      return value.createdByUserId;
    }

    return "";
  }
}
