import { Component, Input } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { IContentTag } from "../../../models/tags";

@UntilDestroy()
@Component({
  selector: "cla-tags-section",
  templateUrl: "tags-section.component.html",
  styleUrls: ["./tags-section.component.scss"],
})
export class TagsSectionComponent {
  @Input() public tags: IContentTag[] = [];
}
