import { createAction, props } from "@ngrx/store";

import { UwMemo } from "../../components/uw-memo-landing/models/uw-memo";
import { UwMemoStatusUpdateRequest } from "../../components/uw-memo-landing/models/uw-memo-status-update-request";

export const onUnderwritingMemosByClaimNumberRequested = createAction(
  "[Underwriting Memo Landing] Memos Requested",
  props<{ claimNumber: string }>()
);

export const onUnderwritingMemosByClaimNumberReceived = createAction(
  "[Underwriting Memo Landing] Memos Received",
  props<{ memos: UwMemo[] }>()
);
