import { Injectable } from "@angular/core";
import { Rep } from "@claimssummaryshared/models";
import { AdminApiService } from "@modules/core/services/admin-api";
import { Observable, retry } from "rxjs";

@Injectable()
export class AdminService {
  constructor(private adminApiService: AdminApiService) {}

  public getRep(repCode: string): Observable<Rep> {
    return this.adminApiService.get<Rep>(`/v1/Reps/${repCode}`).pipe(retry(2));
  }
}
