import {
  IDocSetDetailsResponse,
  IDocSetState,
  IStoreEntity,
} from "../../models";

const initialMeta = {
  responseCode: null,
  method: null,
};

const initialStoreEntity = {
  response: null,
  error: null,
  inFlight: false,
  meta: initialMeta,
};

export const changeRecipientStatusInitialState: IStoreEntity<boolean> = {
  ...initialStoreEntity,
};
export const moveInitialState: IStoreEntity<boolean> = {
  ...initialStoreEntity,
};
export const detailsInitialState: IStoreEntity<IDocSetDetailsResponse> = {
  ...initialStoreEntity,
};
export const changeStatusInitialState: IStoreEntity<boolean> = {
  ...initialStoreEntity,
};
export const removeInitialState: IStoreEntity<boolean> = {
  ...initialStoreEntity,
};
export const initialCopyState: IStoreEntity<boolean> = {
  ...initialStoreEntity,
};

export const docSetInitialState: IDocSetState = {
  details: detailsInitialState,
};
