import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SideSheetService } from "@pgr-cla/core-ui-components";
import { SharedModule } from "../shared/shared.module";
import { FeatureRevisionHistoryComponent } from "./components/feature-revision-history-table/feature-revision-history.component";
import { RevisionHistoryFilterComponent } from "./components/revision-history-filter/revision-history-filter.component";
import { FeatureHistoryComponent } from "./containers/feature-claim/feature-history.component";
import { FeatureRevisionRoutingModule } from "./feature-revision-routing.module";
import { CoreUiAdapterApiService } from "./services/core-ui-adapter-api";
import { FeatureRevisionStoreService } from "./services/feature-revision-store/feature-revision-store.service";
import { FeatureService } from "./services/feature/feature.service";
import { HistoryEffects, ListEffects, SpinnerEffects } from "./state/effects";
import { reducers } from "./state/reducers";

@NgModule({
  declarations: [
    FeatureHistoryComponent,
    FeatureRevisionHistoryComponent,
    RevisionHistoryFilterComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureRevisionRoutingModule,
    SharedModule,
    A11yModule,
    StoreModule.forFeature("featureRevisionState", reducers),
    EffectsModule.forFeature([ListEffects, HistoryEffects, SpinnerEffects]),
  ],
  providers: [
    FeatureService,
    FeatureRevisionStoreService,
    SideSheetService,
    CoreUiAdapterApiService,
  ],
})
export class FeatureRevisionModule {}
