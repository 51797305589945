<h2 mat-dialog-title id="claim-not-found-header">Claim Not Found</h2>
<mat-dialog-content id="claim-not-found-description">
  <p>
    The claim number ({{ data.claimNumber }}) you have entered could not be
    found.
  </p>
  <p>Should you require assistance - please notify your Progressive contact.</p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mt-s">
  <button
    mat-flat-button
    mat-dialog-close
    color="primary"
    class="dialog-confirm"
    data-pgr-id="btnDialogConfirm"
    (click)="onConfirm()"
  >
    OK
  </button>
</mat-dialog-actions>
