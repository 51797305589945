import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "cui-chs-data-detection-dialog",
  templateUrl: "./chs-data-detection-dialog.component.html",
})
export class ChsDataDetectionDialogComponent {
  @Output() public continuePressed: EventEmitter<void> = new EventEmitter();
  @Output() public cancelPressed: EventEmitter<void> = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { readonly chsWarnings: string[] },
    private dialogRef: MatDialogRef<ChsDataDetectionDialogComponent>
  ) {}
}
