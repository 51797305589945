import { createAction, props, union } from "@ngrx/store";

import { NotificationType } from "../models/notification-types";

export const onNotificationReq = createAction(
  "[Shared] Notification Requested",
  props<{ message: string | string[]; notificationType: NotificationType }>()
);

export const onServerError = createAction(
  "[Shared] Server Error",
  props<{ error: Error }>()
);

const all = union({
  onNotificationReq,
  onServerError,
});

export type CoreSharedActionsUnion = typeof all;
