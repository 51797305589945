import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";

import { ConfigurationService } from "@pgr-cla/cla-configuration";

import { buildQueryParams } from "../../../helpers";
import { IApiOptions, IContentSummaryConfigInfo } from "../../../models";

import { BusinessApiService } from "@modules/core";

@Injectable()
export class ContentManagementApiService {
  constructor(
    private api: BusinessApiService,
    private config: ConfigurationService
  ) {}

  public get<T>(
    path: string,
    queryStringParams?: { [key: string]: string | object },
    headers?: HttpHeaders,
    options?: IApiOptions
  ): Observable<T> {
    let paramString = "";

    if (queryStringParams) {
      paramString = buildQueryParams(queryStringParams);
    }

    return this.config.configuration$.pipe(
      first(
        (config: IContentSummaryConfigInfo) =>
          config !== null && config !== undefined
      ),
      switchMap((config) =>
        this.api.get<T>(config.contentManagementApiPath, path + paramString)
      )
    );
  }
}
