import { Component, Input } from "@angular/core";
import { ClaimRepContactCardInfoRedacted } from "@modules/core-ui-extensions/models";

@Component({
  selector: "cla-contact-card",
  templateUrl: "./contact-card.component.html",
  styleUrls: ["./contact-card.component.scss"],
})
export class ContactCardComponent {
  @Input() repInfo: ClaimRepContactCardInfoRedacted;
  @Input() tpxId: string;
  @Input() isLoadingRepInfo: boolean;
  @Input() cardLabel: string = "";
  @Input() showRepId: boolean = false;
  @Input() showLongName: boolean = false;
  @Input() breakWord: boolean = true;

  get tpxIdInParentheses() {
    return ` (${this.tpxId?.trim()})`;
  }
}
