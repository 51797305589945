import { ElementRef } from "@angular/core";

/* eslint-disable functional/immutable-data */
export class ScrollUtil {
  public static scrollToBottom(
    el: ElementRef,
    behavior: "smooth" | "instant" = "smooth"
  ): void {
    el.nativeElement.scroll({
      behavior,
      top: el.nativeElement.scrollHeight,
      left: 0,
    });
  }
  public static scrollToTop(
    el: ElementRef,
    behavior: "smooth" | "instant" = "smooth"
  ): void {
    el.nativeElement.scroll({ behavior, top: 0, left: 0 });
  }
}
