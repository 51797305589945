/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from "@angular/core";
import { UserContextService } from "@modules/core/services/user-context";
import { LoggedMessageLevel } from "@pgr-cla/cla-logging";
import { WindowService } from "@pgr-cla/cla-window";

@Injectable({
  providedIn: "root",
})
export class SplunkBuilder {
  constructor(
    private userContextService: UserContextService,
    private _windowService: WindowService
  ) {}

  public buildError(
    error: Error,
    msgTxt: string,
    errCd: string
  ): Record<string, string> {
    const msgDtlTxt = error.message ? error.message : "";
    const stkTrcTxt = error.stack ? error.stack : "";

    return {
      errCd,
      msgTxt,
      msgDtlTxt,
      stkTrcTxt,
      userId: this.userId(),
      URLPthTxt: this._windowService.url,
      logLvlNam: LoggedMessageLevel.Error,
    };
  }

  public buildInfo(msgTxt: string, msgDtlTxt: string): Record<string, string> {
    return {
      msgTxt,
      msgDtlTxt,
      userId: this.userId(),
      URLPthTxt: this._windowService.url,
      logLvlNam: LoggedMessageLevel.Info,
    };
  }

  private userId(): string {
    const userProfile = this.userContextService.getUserProfile();
    return userProfile ? userProfile.uid : "";
  }
}
