import { Action, createReducer, on } from "@ngrx/store";

import { IStoreEntity } from "../../../models/core";
import { numberify } from "../../../helpers/shared";

import { DocSetDetailsActions } from "../../../actions/doc-set";
import { detailsInitialState } from "../../../constants/doc-set";
import { IDocSetDetailsResponse } from "../../../models/doc-set";

export const detailsReducer = createReducer(
  detailsInitialState,
  on(DocSetDetailsActions.onGetRequested, () => {
    return {
      response: null,
      inFlight: true,
      error: null,
      meta: {
        responseCode: null,
        method: "GET" as HttpVerb,
      },
    };
  }),
  on(DocSetDetailsActions.onGetReceived, (state, action) => {
    return {
      error: null,
      inFlight: false,
      response: action.payload.body,
      meta: {
        responseCode: action.payload.status,
        method: "GET" as HttpVerb,
      },
    };
  }),
  on(DocSetDetailsActions.onError, (state, action) => {
    return {
      response: null,
      inFlight: false,
      error: action.payload,
      meta: {
        responseCode: numberify(action.payload.errorCode),
        method: "GET" as HttpVerb,
      },
    };
  })
);

export function reducer(
  state: IStoreEntity<IDocSetDetailsResponse>,
  action: Action
): IStoreEntity<IDocSetDetailsResponse> {
  return detailsReducer(state, action);
}
