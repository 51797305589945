import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CombinedClaimGuard } from "@modules/core/services";
import { NotesComponent } from "@notes/notes.component";
import { NotesResolverService } from "@notes/services";

const notesRoutes: Routes = [
  {
    path: ":claimNumber",
    canActivate: [CombinedClaimGuard],
    component: NotesComponent,
    resolve: { NotesResolverService },
    loadChildren: () =>
      import("./modules/note-list/note-list.module").then(
        (m) => m.NoteListModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(notesRoutes)],
  exports: [RouterModule],
})
export class NotesRoutingModule {}
