<div class="flex">
  <div class="name" data-pgr-id="vehicleName">
    <b>{{ name }}</b>
  </div>
  <cui-copy-button ariaLabel="copy vin" [dataToCopy]="vinNumber || 'x'">
    <div class="vin">
      VIN:
      <span aria-hidden="true" data-pgr-id="vinNumber">{{
        vinNumber | claValueOrNA
      }}</span>
      <span class="screen-reader-only">{{
        vinNumber | claDigitReader | claValueOrNA
      }}</span>
    </div>
    &nbsp;
  </cui-copy-button>
</div>
