import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const ANIMATION_TIMING = "225ms cubic-bezier(0.4,0.0,0.2,1)";

export const expansionAnimations: {
  readonly panelExpansion: AnimationTriggerMetadata;
  readonly iconRotation: AnimationTriggerMetadata;
  readonly iconRotation180: AnimationTriggerMetadata;
  readonly textExpansion: AnimationTriggerMetadata;
  readonly textConversationExpansion: AnimationTriggerMetadata;
} = {
  panelExpansion: trigger("panelExpansion", [
    state(
      "collapsed, void",
      style({
        overflow: "hidden",
        height: "0px",
        visibility: "hidden",
        paddingTop: 0,
      })
    ),
    state(
      "expanded",
      style({
        overflow: "*",
        height: "*",
        visibility: "visible",
        paddingTop: "1.6rem",
      })
    ),
    transition(
      "expanded <=> collapsed, void => collapsed",
      animate(ANIMATION_TIMING)
    ),
  ]),
  iconRotation: trigger("iconRotation", [
    state("default, void", style({ transform: "rotate(0deg)" })),
    state("rotated", style({ transform: "rotate(45deg)" })),
    transition(
      "rotated <=> default, void => default",
      animate(ANIMATION_TIMING)
    ),
  ]),
  iconRotation180: trigger("iconRotation180", [
    state("default, void", style({ transform: "rotate(0deg)" })),
    state("rotated", style({ transform: "rotate(180deg)" })),
    transition(
      "rotated <=> default, void => default",
      animate(ANIMATION_TIMING)
    ),
  ]),
  textExpansion: trigger("textExpansion", [
    state(
      "default, void",
      style({ overflow: "hidden", height: "*", maxHeight: "12.6rem" })
    ),
    state(
      "collapsed",
      style({ overflow: "hidden", height: "12.6rem", maxHeight: "12.6rem" })
    ),
    state("expanded", style({ overflow: "*", height: "*", maxHeight: "*" })),
    transition(
      "expanded <=> collapsed, void => default, default => collapsed",
      animate(ANIMATION_TIMING)
    ),
  ]),
  textConversationExpansion: trigger("textConversationExpansion", [
    state(
      "default, void",
      style({ overflow: "hidden", height: "*", maxHeight: "34.8rem" })
    ),
    state(
      "collapsed",
      style({ overflow: "hidden", height: "34.8rem", maxHeight: "34.8rem" })
    ),
    state("expanded", style({ overflow: "*", height: "*", maxHeight: "*" })),
    transition(
      "expanded <=> collapsed, void => default, default => collapsed",
      animate(ANIMATION_TIMING)
    ),
  ]),
};
