import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { ThemeManagerService } from "@modules/core";
import { ClaimNumberPipe } from "@pgr-cla/core-ui-components";
import { NotesStateService } from "../state";

@Component({
  selector: "cla-notes",
  templateUrl: "./notes.component.html",
  providers: [ClaimNumberPipe],
})
export class NotesComponent {
  public isPreloaderVisible = true;
  constructor(
    private readonly themeManager: ThemeManagerService,
    public notesStateService: NotesStateService
  ) {
    this.themeManager.initializeNotesPopoutTheme();
    this.notesStateService.setIsPopout();
  }

  public onRouteChange(outlet: RouterOutlet): void {
    this.isPreloaderVisible = false;
  }
}
