export enum PaymentEventType {
  Request = "1",
  Grant = "2",
  Reject = "3",
}

export interface PhysicalDamageAuthorityEvent {
  authorityRepCode: string;
  claimNumber: string;
  paymentAuthorityRequestedAmount: number;
  paymentEventCommentText: string;
  paymentEventDateTime: string;
  paymentEventId: string;
  paymentEventTypeCode: PaymentEventType;
  propertyNumber: number;
  requestedRepCode: string;
}
