import { NgModule, Type } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { TechnicalDifficultyComponent } from "@pgr-cla/core-ui-components";

import { AttorneyModule } from "@modules/attorney/attorney.module";
import { ClaimsLandingModule } from "@modules/claims-landing/claims-landing.module";
import { SearchableClaimNotFoundComponent } from "@modules/claims-landing/components";
import {
  AuthenticationGuardService,
  BrowserGuard,
} from "@modules/core/services";
import { NotesModule } from "@modules/notes/notes.module";
import { AuthCallbackComponent } from "@modules/shared/components/auth-callback/auth-callback.component";

import { AppResolverService } from "./app.resolver.service";
import { TechDiffMessageResolver } from "./tech-diff-message.resolver";

export const appRoutes: Routes = [
  {
    path: "auth/callback",
    component: AuthCallbackComponent,
    data: { title: "Login" },
  },
  {
    path: "techdiff",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Looks like something went wrong.",
      helpDeskNumber: "",
    },
  },
  {
    path: "unauthenticated",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Unauthenticated",
      helpDeskNumber: "",
    },
  },
  {
    path: "forbidden",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Access Denied",
      helpDeskNumber: "",
    },
  },
  {
    path: "missing-ldap",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Access Denied",
      helpDeskNumber: "",
    },
  },
  {
    path: "not-found",
    component: SearchableClaimNotFoundComponent,
    data: {
      title: "Page Not Found",
      message:
        "The page you are looking for could not be found. Please try going back or searching a claim number.",
    },
  },
  {
    path: "unsupported-browser",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Please use Google Chrome to access TNCLV",
      helpDeskNumber: "",
    },
  },
  {
    path: "invalid-party",
    component: TechnicalDifficultyComponent,
    resolve: {
      message: TechDiffMessageResolver,
    },
    data: {
      title: "Invalid party ID",
      helpDeskNumber: "",
    },
  },
  {
    path: "attorney",
    loadChildren: (): Type<AttorneyModule> => AttorneyModule,
  },
  {
    path: "notes",
    resolve: { AppResolverService },
    canActivate: [BrowserGuard, AuthenticationGuardService],
    loadChildren: (): Type<NotesModule> => NotesModule,
    data: {
      title: "Notes Activity",
    },
  },
  {
    path: "",
    resolve: { AppResolverService },
    canActivate: [BrowserGuard, AuthenticationGuardService],
    loadChildren: (): Type<ClaimsLandingModule> => ClaimsLandingModule,
    data: {
      title: "Claims Summary",
    },
  },
  {
    path: "**",
    component: SearchableClaimNotFoundComponent,
    data: {
      title: "Page Not Found",
      message:
        "The page you are looking for could not be found. Please try going back or searching a claim number.",
    },
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: "always",
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
      canceledNavigationResolution: "computed",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
