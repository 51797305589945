// using same timing as Angular Material sidenav animation
// https://github.com/angular/components/blob/master/src/material/sidenav/drawer-animations.ts

import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const claimSubHeaderAnimations: {
  readonly transform: AnimationTriggerMetadata;
} = {
  transform: trigger("transform", [
    state(
      "open",
      style({
        width: "{{width}}",
        minWidth: "{{width}}",
      }),
      { params: { width: "38.1rem" } }
    ),
    state("closed, void", style({ width: "*", minWidth: "{{minWidth}}" }), {
      params: { minWidth: "10em" },
    }),
    transition(
      "open <=> closed, void => closed",
      animate("400ms cubic-bezier(0.25, 0.8, 0.25, 1)")
    ),
  ]),
};
