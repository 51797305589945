import { createAction, props } from "@ngrx/store";

export const onClaimAlertsViewed = createAction(
  "[Claims Landing] Claim Alerts Viewed",
  props<{ pgrTransactionIds: string[] }>()
);

export const onClaimAlertsViewedSync = createAction(
  "[Claims Landing] Claim Alerts Viewed Sync",
  props<{ pgrTransactionIds: string[] }>()
);

export const onAllClaimAlertsViewed = createAction(
  "[Claims Landing] All Claim Alerts Viewed"
);

export const onAllClaimAlertsViewedSync = createAction(
  "[Claims Landing] All Claim Alerts Viewed Sync"
);
